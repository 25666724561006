import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import Step1 from "./step1";
import Step2 from "./step2";
import { withRouter } from "react-router-dom";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

import { createIndvidual } from "services/indvidual.service";

import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import "./individual.scss";
import { set } from "lodash";
const ProviderRegisterIndividual = ({ toggle, modal, reload, props }) => {
  const [step1Values, setStep1Values] = useState({
    legalname: "",
    shortname: "",
    gender: "",
    address: "",
    zipcode: "",
    city: null,
    state: null,
    country: null,
    mobileNumber: "",
    since: "",
    idVerification: false,
  });
  const [step2Values, setStep2Values] = useState({
    idProofs: [],
  });

  const [currentStepper, setCurrentStepper] = useState(1);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
    setStep1Values({});
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
  };
  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            {/* <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button> */}
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Indvidual created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              // onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const handleStepperBack = () => {
    setCurrentStepper(currentStepper - 1);
  };

  const onSubmit = values => {
    const data = { ...step1Values, ...values };
    console.log(data, "ddddddd");
    let formData = new FormData();
    for (const key in data) {
      if (key === "expertise") {
        formData.set(key, JSON.stringify(data[key]));
      } else if (key === "city" || key === "country" || key === "state") {
        formData.set(key, data[key]["label"]);
      } else {
        formData.set(key, data[key]);
      }
    }

    // data.idProofs?.map((ele, idx) => {
    //   if (ele.docs) {
    //     formData.set("idProofs" + idx, ele.docs);
    //   }
    // });
    // formData.set("idProofs", JSON.stringify(data.idProofs));

    createIndvidual(formData)
      .then(res => {
        setsuccess_msg(true);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  const handleSubminSteper = (values, stepperName) => {
    // console.log("====", values);
    // if (currentStepper == 1) {
    //   setStep1Values(values);
    //   let newCount = currentStepper + 1;
    //   setCurrentStepper(newCount);
    // } else if (currentStepper == 2) {
    //   setStep2Values(values);
    //   onSubmit(values);
    // }
    onSubmit(values);
    // if (currentStepper == 3) {
    //   //Submit the form Here
    //   alert("Submit form");
    // } else {
    //   let newCount = currentStepper + 1;
    //   setCurrentStepper(newCount);
    // }
  };

  // const renderSteper = () => {
  //   if (currentStepper == 1)
  //     return (
  //       <Step1
  //         step1Values={step1Values}
  //         // backStepper={handleStepperBack}
  //         handleSubmitStepper={handleSubminSteper}
  //       />
  //     );
  //   else if (currentStepper == 2)
  //     return (
  //       <Step2
  //         step2Values={step2Values}
  //         backStepper={handleStepperBack}
  //         handleSubmitStepper={handleSubminSteper}
  //       />
  //     );
  //   else if (currentStepper == 3) return <h2>Stepper 3</h2>;
  // };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
          Provider Individual Register | stylepro
        </ModalHeader>

        <ModalBody>
          <Container fluid={false}>
            {/* {renderSteper()} */}
            <Step1
              step1Values={step1Values}
              handleSubmitStepper={handleSubminSteper}
              leaveToList={leaveToList}
            />
            {success_msg ? successPopup() : null}
            {error_msg ? errorPopup() : null}
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withRouter(ProviderRegisterIndividual);
