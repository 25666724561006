import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";

import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import "./UserView.styles.scss";
import { useParams } from "react-router";
import { getUser } from "services/user.service";
import moment from "moment";
import Experience from "./Experience";

const UserView = props => {
  const { id } = useParams();
  const [userData, setUser] = useState({});
  useEffect(() => {
    getUserById();
  }, [id]);
  const getUserById = () => {
    getUser(id)
      .then(res => {
        setUser(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | stylepro</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Providers" breadcrumbItem="User View" />
          <Card className="view-users">
            <CardBody>
              <div className="d-flex justify-content-between">
                <Link to="/User-list" className="">
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link>
                <div className="text-end">
                  <Badge
                    className="font-size-12 badge-soft-info py-2 px-4"
                    color={"bg-info"}
                    pill
                  >
                    <i className="bx bx-user" /> {userData.role}
                  </Badge>
                </div>
              </div>
              <Row>
                <Col md={4} className="">
                  <div className="text-center">
                    <ProfileImage
                      image={userData?.avatar}
                      classNames="img-thumbnail profile rounded-circle"
                    />
                    <h5 className="mt-4 text-muted">{userData.firstname}</h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 user_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">Email </span>{" "}
                      <span className="text-muted">{userData.email} </span>
                    </p>
                    <p className="ps-0 ">
                      <span className="pe-3 fw-bold">User ID </span>{" "}
                      <span className="text-muted">{userData._id} </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">First Name</p>
                      <p className="text-muted">{userData.firstname}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Name</p>
                      <p className="text-muted">{userData.lastname}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Status</p>
                      {/* <p className="text-muted">Confirmed</p> */}
                      <Badge
                        className="font-size-12 badge-soft-success"
                        color={"bg-success"}
                        pill
                      >
                        {userData.status}
                      </Badge>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Mobile Number</p>
                      <p className="text-muted">{userData.mobilenumber}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Address</p>
                      <p className="text-muted">Hyderabad</p>
                    </Col>
                  </Row>

                  <div className=" mt-2 me-3 hr"> </div>
                  {userData?.experiences && userData.experiences.length > 0 && (
                    <>
                      <Row className="mx-3 my-2 pt-3">
                        <Row className="mb-2">
                          <Col md={4}>
                            <p className="fw-bold mb-1">Service Name</p>
                          </Col>
                          <Col md={4}>
                            <p className="fw-bold mb-1">From Date</p>
                          </Col>
                          <Col md={4}>
                            <p className="fw-bold mb-1">To Date</p>
                          </Col>
                        </Row>
                        {userData?.experiences?.map((exp, ind) => {
                          return (
                            <Row key={ind} className="mb-2">
                              <Col md={4}>
                                <p className="text-muted mb-1">{exp.service}</p>
                              </Col>
                              <Col md={4}>
                                <p className="text-muted mb-1">
                                  {exp.fromDate}
                                </p>
                              </Col>
                              <Col md={4}>
                                <p className="text-muted mb-1">{exp.toDate}</p>
                              </Col>
                            </Row>
                          );
                        })}
                      </Row>
                      <div className=" mt-2 me-3 hr"> </div>
                    </>
                  )}

                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">
                        {moment(userData.createdAt).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">
                        {moment(userData.updatedAt).format("MMM Do YYYY")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserView;
