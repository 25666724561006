import { call, put, takeEvery } from "redux-saga/effects"

// Crypto Redux States
import { GET_PROVIDERS, GET_PROVIDERS_FAIL, GET_PROVIDERS_SUCCESS} from "./actionTypes"
import {
getProvidersFail,
getProvidersSuccess
} from "./actions"

//Include Both Helper File with needed methods
import {getProviders} from "services/provider.service"
function* fetchProviders() {
  try {
    const response = yield call(getProviders)
    yield put(getProvidersSuccess(response))
  } catch (error) {
    yield put(getProvidersFail(error))
  }
}


function* providersSaga() {
  yield takeEvery(GET_PROVIDERS, fetchProviders)
}

export default providersSaga
