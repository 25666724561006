import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
// import UserTransactions from "./UserTransactions";

import {
  Badge,
  Modal,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Button,
  Label,
  Input,
} from "reactstrap";

import Breadcrumb from "../../components/Common/Breadcrumb";

import profileImg from "assets/images/users/avatar-3.jpg";
import "../Users/UserView.styles.scss";

const RequestView = props => {
  useEffect(() => {}, []);
  const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const modal = () => {
    return (
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-body p-0">
          <Card className="overflow-hidden mb-0">
            <div className="bg-primary bg-soft">
              <Row>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <Col className="col-7">
                  <div className="text-primary p-4">
                    <h5 className="text-primary">Reject Request</h5>
                  </div>
                </Col>
              </Row>
            </div>
            <CardBody className="pt-0">
              <div className="p-2">
                <Label for="exampleText">Text Area</Label>
                <Input id="exampleText" name="text" type="textarea" />
              </div>
              <Row>
                  <div className="text-sm-end">
<Button  className="m-2 btn-rounded  btn btn-danger">Reject</Button>
                  </div>
              </Row>
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Request | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Providers" breadcrumbItem="Request View" />
          <Card className="view-users">
            <CardBody>
              <Row>
                <div className="text-end">
                  <Badge
                    className="font-size-12 badge-soft-info py-2 px-4"
                    color={"bg-info"}
                    pill
                  >
                    <i className="bx bx-user" /> Provider
                  </Badge>
                </div>
              </Row>
              <Row>
                <Col md={4} className="">
                  <div className="text-center my-4">
                    <img
                      src={profileImg}
                      alt=""
                      className="img-thumbnail profile rounded-circle"
                    />
                    <h5 className="mt-4 text-muted">Mosh Hamadani</h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 user_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">Email </span>{" "}
                      <span className="text-muted">hamadani@gmail.com </span>
                    </p>
                    <p className="ps-0 ">
                      <span className="pe-3 fw-bold">User ID </span>{" "}
                      <span className="text-muted">143454re34 </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">User Name</p>
                      <p className="text-muted">@hamadani-mosh</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Mobile</p>
                      <p className="text-muted">+91 9876543210</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Status</p>
                      {/* <p className="text-muted">Confirmed</p> */}
                      <Badge
                        className="font-size-12 badge-soft-success"
                        color={"bg-success"}
                        pill
                      >
                        Confirmed
                      </Badge>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Type</p>
                      <p className="text-muted">-</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Address</p>
                      <p className="text-muted">Hyderabad</p>
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">12/08/2021 2:30PM IST</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Login</p>
                      <p className="text-muted">12/09/2021 8:30PM IST</p>
                    </Col>
                  </Row>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <Button className="m-2 btn-rounded  btn btn-success">
                        Approve
                      </Button>
                      <Button
                        className="m-2 btn-rounded  btn btn-danger"
                        onClick={() => {
                          tog_standard();
                        }}
                      >
                        Reject
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
      {modal()}
    </React.Fragment>
  );
};

export default RequestView;
