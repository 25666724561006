import React, { useState, useEffect } from "react";

import { Card, Col, Row, Label, Button, FormFeedback, Input } from "reactstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import ProfileImage from "../../../components/profileImage";

import "react-dual-listbox/lib/react-dual-listbox.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Country Flag with Phone number
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

//Country-State-Cities
import csc from "countries-states-cities";

const defaultStateList = [
  {
    label: "please Select Country",
    value: "please Select Country",
    isDisabled: true,
  },
];
const defaultCityList = [
  {
    label: "please Select State",
    value: "please Select State",
    isDisabled: true,
  },
];

const step1 = props => {
  const { handleSubmitStepper, step1Values, leaveToList } = props;
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState(defaultStateList);
  const [citiesList, setCitiesList] = useState(defaultCityList);

  const initialValues = step1Values;
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter Title"),
    name: Yup.string().required("Please Enter Name"),
    area: Yup.string().required("Please Enter Area"),
    city: Yup.object().required("Please Enter City"),
    state: Yup.object().required("Please Enter State"),
    country: Yup.object().required("Please Select Country"),
    mobileNumber: Yup.string().required("Please Enter Mobile Number"),
    email: Yup.string().required("Please Enter Email"),
  });

  useEffect(() => {
    //Get All Countries List
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
  }, []);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleSelectCountry(event) {
    //Get All States Under the Selected Country
    let stateArray = [];
    csc.getStatesOfCountry(event.id).forEach(ele => {
      let stateObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      stateArray.push(stateObj);
    });
    setStatesList(stateArray);
  }

  function handleSelectState(event) {
    let citiesArr = [];
    csc.getCitiesOfState(event.id).forEach(ele => {
      let cityObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      citiesArr.push(cityObj);
    });
    setCitiesList(citiesArr);
  }

  const onSubmit = values => {
    handleSubmitStepper(values, "stepper1");
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ errors, touched, setFieldValue, handleChange, values }) => {
          return (
            <Form>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col sm={8}>
                      <Label htmlFor="legalname" className=" col-form-label">
                        Title
                        <span className="text-danger">*</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Enter Your Artist Title"
                        name="title"
                        onChange={handleChange}
                        value={values.title}
                        invalid={touched.title && errors.title ? true : false}
                      />
                      {touched.title && errors.title ? (
                        <FormFeedback type="invalid">
                          {errors.title}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col sm={4}>
                      <Label htmlFor="shortname" className="col-form-label">
                        Name
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        invalid={touched.name && errors.name ? true : false}
                      />
                      {touched.name && errors.name ? (
                        <FormFeedback type="invalid">
                          {errors.name}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col sm={4} className="mt-3">
                      <Label htmlFor="country" className="col-form-label">
                        Country
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="country"
                        id="country"
                        onChange={event => {
                          setFieldValue("country", event);
                          setFieldValue("state", null);
                          setFieldValue("city", null);
                          handleSelectCountry(event);
                        }}
                        options={countriesList}
                        value={values.country}
                        classNamePrefix="select2-selection"
                      />
                    </Col>

                    <Col className="mt-3" sm={4}>
                      <Label htmlFor="state" className="col-form-label">
                        State
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="state"
                        id="state"
                        onChange={event => {
                          if (event) {
                            handleSelectState(event);
                            setFieldValue("state", event);
                            setFieldValue("city", null);
                          }
                        }}
                        options={statesList}
                        value={values.state}
                        classNamePrefix="select2-selection"
                      />
                    </Col>

                    <Col className="mt-3" sm={4}>
                      <Label htmlFor="city" className="col-form-label">
                        City
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="city"
                        id="city"
                        onChange={event => {
                          setFieldValue("city", event);
                        }}
                        options={citiesList}
                        value={values.city}
                        classNamePrefix="select2-selection"
                      />
                    </Col>

                    <Col sm={8} className="mt-3">
                      <Label htmlFor="shortname" className="col-form-label">
                        Area
                        <span className="text-danger">*</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="area"
                        placeholder="Enter Your Area"
                        name="area"
                        value={values.area}
                        onChange={handleChange}
                        invalid={touched.area && errors.area ? true : false}
                      />
                      {touched.area && errors.area ? (
                        <FormFeedback type="invalid">
                          {errors.area}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col sm={4} className="mt-3"></Col>

                    <Col sm={4} className="mt-3">
                      <Label htmlFor="mobileNumber" className="col-form-label">
                        Mobile Number
                        <span className="text-danger">*</span>
                      </Label>

                      <PhoneInput
                        className="custom_country_phoneNumber"
                        id="mobileNumber"
                        placeholder="Enter Your Mobile Number"
                        name="mobileNumber"
                        international={false}
                        defaultCountry={
                          values.country ? values.country.value : null
                        }
                        value={values.mobileNumber}
                        onChange={value => {
                          setFieldValue("mobileNumber", value);
                        }}
                        error={
                          values.mobileNumber
                            ? isValidPhoneNumber(values.mobileNumber)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />

                      {values.mobileNumber &&
                      !isValidPhoneNumber(values.mobileNumber) ? (
                        <span className="text-danger">
                          {"Please Eneter Valid Phone Number"}
                        </span>
                      ) : null}
                    </Col>

                    <Col sm={8} className="mt-3">
                      <Label htmlFor="shortname" className="col-form-label">
                        Email
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter Your email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        invalid={touched.email && errors.email ? true : false}
                      />
                      {touched.email && errors.email ? (
                        <FormFeedback type="invalid">
                          {errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col sm={12} className="mt-3">
                      <Label htmlFor="validationCustom06">Image</Label>
                      <Dropzone
                        id="validationCustom06"
                        onDrop={acceptedFiles => {
                          acceptedFiles.map(file =>
                            Object.assign(file, {
                              preview: URL.createObjectURL(file),
                              formattedSize: formatBytes(file.size),
                            })
                          );
                          setFieldValue("artistImage", acceptedFiles[0]);
                        }}
                      >
                        {({ getRootProps, getInputProps }) => (
                          <div className="dropzone image_drop">
                            <div
                              className="dz-message needsclick "
                              {...getRootProps()}
                            >
                              <input {...getInputProps()} />
                              <div className="">
                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                              </div>
                              <h6>Drop files here or click to upload.</h6>
                            </div>
                          </div>
                        )}
                      </Dropzone>
                      <Label className="statusValidation">
                        {" "}
                        {errors.artistImage ? errors.artistImage : ""}
                      </Label>
                      <div
                        className="dropzone-previews mt-3"
                        id="file-previews"
                      >
                        {values.artistImage &&
                          Object.keys(values.artistImage).length > 0 && (
                            <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <ProfileImage
                                      image={values?.artistImage}
                                      height="80"
                                      classNames="avatar-sm rounded bg-light"
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {values.artistImage.name ||
                                        values.artistImage.filename}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>
                                        {values.artistImage.formattedSize}
                                      </strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                            </Card>
                          )}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <div className="d-flex mt-4 justify-content-end">
                <Button
                  color="light"
                  className="w-md btn-sm me-2"
                  onClick={leaveToList}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="w-md btn-sm ms-2"
                  type="submit"
                  // onClick={leaveToList}
                >
                  Create
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default step1;
