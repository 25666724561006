import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
// import { NavLink } from "react-router-dom";
import {
  Col,
  Container,
  Nav,
  NavItem,
  Row,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import BasicDetails from "./BasicDetails";
import Specifications from "./Specifications";
import { VariantTab } from "./VariantTab";
import { TagsTab } from "./TagsTab";
import FaqsReviewsTab from "./FaqsTab";
import {
  empltyProductById,
  getAllSubCategory,
  getProductById,
} from "store/actions";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { set } from "lodash";
import {
  createProduct,
  updateProductFeilds,
} from "services/ecommerce/product.service";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import Filters from "../ProductCategory/filters";
import { ProductFilters } from "./ProductFilters";
import { BrandFilters } from "./BrandFilters";
const defaultValues = {
  name: "",
  description: [{ name: "", value: "" }],
  content: "",
  featuredimage: "",
  images: [],
  sku: "",
  price: "",
  discount: "",
  stockStatus: "In Stock",
  weight: "",
  length: "",
  wide: "",
  height: "",
  relatedProducts: [],
  crossSelingProducts: [],
  faqs: [{ question: "", answer: "" }],
  attributes: [],
  tags: "",
  tax: "",
  mrp: "",
  totalQuantity: 0,
  minimumOrderQuantity: 0,
  shippingCost: 0,
  labels: "",
  collections: "",
  mainCategory: "",
  parentCategory: "",
  category: "",
  brand: "",
  store: "",
  isFeatured: false,
  isServiceProduct: false,
  isEcomProduct: true,
  status: "Publish",
  type: "simple",
  discounttype: "flat",
  shippingMultiplyWithQuantity: false,
  unit: "kg",
  flatDiscount: 0,
  percentageDiscount: 0,
  properties: [{ name: "", value: "" }],
  ratings: "0",
  reviews: 0,
  keyWords: [],
  video: [{ url: "", thumbnail: "", order: "" }],
  manfsku: "",
  filters: [],
};
const ProductEditIndex = () => {
  const [values, setValues] = useState();
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [activeTab, setActiveTab] = useState("1");
  const [productType, setProductType] = useState();
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const product = useSelector(state => state?.EcomProducts?.product);
  const dispatch = useDispatch();
  const route = useParams();
  const history = useHistory();

  useEffect(() => {
    if (route?.id) {
      getProductById(dispatch, route?.id);
      setValues(product);
    }
    if (route?.catId) {
      dispatch(empltyProductById(defaultValues));
      setValues(product);
    }
  }, [dispatch]);

  const handleChangeProperties = (property, value) => {
    const tempValues = { ...values };
    if (property === "category") {
      tempValues["category"] = value?._id;
      tempValues["mainCategory"] = value?.mainCategory?._id;
      tempValues["parentCategory"] = value?.parentCategory?._id;
      tempValues["relatedProducts"] = value?.relatedProducts;
      tempValues["crossSelingProducts"] = value?.crossSelingProducts;
      setSelectedCat(value);
    } else {
      tempValues[property] = value;
    }
    setValues(tempValues);
  };

  const handleUpdateState = (property, value) => {
    const tempValues = { ...values };
    tempValues[property] = value;
    setValues(tempValues);
  };

  const handleProductType = event => {
    setProductType(event.target.value);
    handleChangeProperties("type", event.target.value);
  };

  const handleUpdateMulti = newObject => {
    setValues({ ...values, ...newObject });
  };

  const stepperActiveTab = tabNumber => {
    setActiveTab(tabNumber);
  };

  const updateValues = formData => {
    setLoading(true);
    updateProductFeilds(route?.id, formData)
      .then(res => {
        if (res?.data) {
          setsuccess_msg(true);
          setLoading(false);
        } else {
          seterror_msg(true);
          setLoading(false);
        }
      })

      .catch(e => {
        seterror_msg(true);
        setLoading(false);
        console.log(e);
      });
  };
  const create = formData => {
    setLoading(true);
    createProduct(formData)
      .then(res => {
        if (res?.success) {
          setsuccess_msg(true);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const handleSubmit = values => {
    let formData = new FormData();
    if (
      values["attributes"] &&
      values["attributes"].length > 0 &&
      values["attributes"][0]["combos"]
    ) {
      let tempAttributes = _.cloneDeep(values["attributes"]);
      let combos = _.cloneDeep(tempAttributes[0]["combos"]);
      combos = combos?.filter((combo, arrInd) => {
        let comboParentIndex =
          arrInd >= 0 && arrInd < 10 ? "0" + arrInd : arrInd;
        let imgArr = [];
        combo?.images?.forEach((ele, ind) => {
          if (typeof ele !== "string") {
            formData.set(
              "attrImages00" + "" + comboParentIndex + "" + ind,
              ele
            );
          } else {
            imgArr.push(ele);
          }
        });
        combo.images = imgArr;
        return combo;
      });

      let variants = _.cloneDeep(tempAttributes[0]["variants"]);
      variants = variants?.map((ele, Ind) => {
        ele?.selectedOptions?.map((opt, optInd) => {
          if (typeof opt?.image !== "string") {
            let varInd = Ind >= 0 && Ind < 10 ? "0" + Ind : Ind;
            let comboIndex = optInd >= 0 && optInd < 10 ? "0" + optInd : optInd;
            formData.set(
              "variantImage" + "00" + varInd + "" + comboIndex,
              opt?.image
            );
            // opt.image = "";
          }

          return opt;
        });

        return ele;
      });

      tempAttributes[0]["combos"] = combos;
      tempAttributes[0]["variants"] = variants;
      values["attributes"] = tempAttributes;
    }

    for (let key in values) {
      if (
        key === "attributes" ||
        key === "faqs" ||
        key === "video" ||
        key === "filters" ||
        key === "properties" ||
        key === "description" ||
        key === "relatedProducts" ||
        key === "crossSelingProducts"
      ) {
        formData.set(key, JSON.stringify(values[key]));
      } else if (key == "images") {
        const previousImage = [];
        values.images.forEach((e, i) => {
          if (typeof e === "string") {
            previousImage.push(e);
          }
          if (Object.keys(e).length !== 7) {
            formData.set(`image${i}`, e);
          }
        });
        formData.set(
          key,
          values[key] ? JSON.stringify(previousImage) : JSON.stringify([])
        );
      }
      // else if (key === "relatedProducts" || key === "crossSelingProducts") {
      //   const status = values[key];
      //   if (typeof status === "string") {
      //     formData.set(key, JSON.stringify(values[key]));
      //   } else {
      //     const related = [];
      //     values[key]?.map(e => {
      //       if (e) {
      //         related.push(e.value);
      //       }
      //     });
      //     formData.set(key, JSON.stringify(related));
      //   }
      // }
      else if (
        key === "brand" ||
        key === "category" ||
        key === "parentCategory" ||
        key === "mainCategory"
      ) {
        const status = values[key];
        if (typeof status === "string") {
          formData.set(key, values[key]);
        }
      } else if (key === "keyWords") {
        formData.set(key, JSON.stringify(values[key]));
      } else if (key === "stockStatus") {
        if (values?.totalQuantity > 0) {
          formData.set("stockStatus", "In Stock");
        } else {
          formData.set("stockStatus", "Out of Stock");
        }
      } else if (key === "ratings") {
        formData.set("ratings", "0");
      } else {
        formData.set(key, values[key]);
      }
    }

    if (route?.id) {
      updateValues(formData);
    } else {
      create(formData);
    }
  };
  const leaveToList = () => {
    history.push("/products-list");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    getProductById(dispatch, route?.id);
  };

  return (
    <React.Fragment>
      {/* <Spinner loading={loading} /> */}
      <div className="page-content">
        <MetaTags>
          <title>Products | Products List</title>
        </MetaTags>
        <Container fluid id="service_list">
          <div className="product-edit-page">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => setActiveTab("1")}
                  role="button"
                >
                  Basic Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => setActiveTab("2")}
                  role="button"
                >
                  Variants
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => setActiveTab("3")}
                  role="button"
                >
                  Specifications
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => setActiveTab("4")}
                  role="button"
                >
                  Filters
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => setActiveTab("5")}
                  role="button"
                >
                  Keywords
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "6" ? "active" : ""}
                  onClick={() => setActiveTab("6")}
                  role="button"
                >
                  Reviews / FAQS
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="p-2">
              <TabPane tabId="1">
                <BasicDetails
                  handleChangeProperties={handleChangeProperties}
                  handleUpdateState={handleUpdateState}
                  selectedCat={selectedCat}
                  selectedBrand={selectedBrand}
                  selectedStatus={selectedStatus}
                  defaultValues={defaultValues}
                  handleSubmit={handleSubmit}
                  stepperActiveTab={stepperActiveTab}
                />
              </TabPane>
              <TabPane tabId="2">
                <VariantTab
                  handleChangeProperties={handleChangeProperties}
                  handleUpdateState={handleUpdateState}
                  handleUpdateMulti={handleUpdateMulti}
                  handleProductType={handleProductType}
                  productType={productType}
                  defaultValues={defaultValues}
                  handleSubmit={handleSubmit}
                  stepperActiveTab={stepperActiveTab}
                />
              </TabPane>
              <TabPane tabId="3">
                <Specifications
                  handleChangeProperties={handleChangeProperties}
                  handleUpdateState={handleUpdateState}
                  defaultValues={defaultValues}
                  handleSubmit={handleSubmit}
                  stepperActiveTab={stepperActiveTab}
                />
              </TabPane>
              <TabPane tabId="4">
                <ProductFilters
                  handleChangeProperties={handleChangeProperties}
                  handleUpdateState={handleUpdateState}
                  defaultValues={defaultValues}
                  handleSubmit={handleSubmit}
                  stepperActiveTab={stepperActiveTab}
                />
                <BrandFilters
                  handleChangeProperties={handleChangeProperties}
                  handleUpdateState={handleUpdateState}
                  defaultValues={defaultValues}
                  handleSubmit={handleSubmit}
                  stepperActiveTab={stepperActiveTab}
                />
              </TabPane>
              <TabPane tabId="5">
                <TagsTab
                  handleChangeProperties={handleChangeProperties}
                  handleUpdateState={handleUpdateState}
                  defaultValues={defaultValues}
                  handleSubmit={handleSubmit}
                  stepperActiveTab={stepperActiveTab}
                />
              </TabPane>
              <TabPane tabId="6">
                <FaqsReviewsTab
                  handleChangeProperties={handleChangeProperties}
                  handleUpdateState={handleUpdateState}
                  defaultValues={defaultValues}
                  handleSubmit={handleSubmit}
                  stepperActiveTab={stepperActiveTab}
                />
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div>
      {route?.catId ? (
        <>
          {success_msg ? (
            <SuccessAlert
              msg={"Successfully Product Updated"}
              leaveToList={leaveToList}
              stayHere={stayHere}
            />
          ) : null}
          {error_msg ? (
            <ErrorAlert
              msg={"error"}
              leaveToList={leaveToList}
              stayHere={stayHere}
            />
          ) : null}
        </>
      ) : (
        <>
          {success_msg ? (
            <SuccessAlert
              msg={"Successfully Product created"}
              leaveToList={leaveToList}
              stayHere={stayHere}
            />
          ) : null}
          {error_msg ? (
            <ErrorAlert
              msg={"error"}
              leaveToList={leaveToList}
              stayHere={stayHere}
            />
          ) : null}
        </>
      )}
    </React.Fragment>
  );
};
export default ProductEditIndex;
