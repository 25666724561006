import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Row, Col, Collapse } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import classname from "classnames";

//i18n
import { withTranslation } from "react-i18next";

import { connect } from "react-redux";

const Navbar = props => {
  const [dashboard, setdashboard] = useState(false);
  const [ui, setui] = useState(false);
  const [app, setapp] = useState(false);
  const [email, setemail] = useState(false);
  const [ecommerce, setecommerce] = useState(false);
  const [crypto, setcrypto] = useState(false);
  const [project, setproject] = useState(false);
  const [task, settask] = useState(false);
  const [contact, setcontact] = useState(false);
  const [blog, setBlog] = useState(false);
  const [component, setcomponent] = useState(false);
  const [form, setform] = useState(false);
  const [table, settable] = useState(false);
  const [chart, setchart] = useState(false);
  const [users, setusers] = useState(false);
  const [providers, setproviders] = useState(false);

  const [icon, seticon] = useState(false);
  const [map, setmap] = useState(false);
  const [extra, setextra] = useState(false);
  const [invoice, setinvoice] = useState(false);
  const [auth, setauth] = useState(false);
  const [utility, setutility] = useState(false);

  useEffect(() => {
    var matchingMenuItem = null;
    var ul = document.getElementById("navigation");
    var items = ul.getElementsByTagName("a");
    for (var i = 0; i < items.length; ++i) {
      if (props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  });
  function activateParentDropdown(item) {
    item.classList.add("active");
    const parent = item.parentElement;
    if (parent) {
      parent.classList.add("active"); // li
      const parent2 = parent.parentElement;
      parent2.classList.add("active"); // li
      const parent3 = parent2.parentElement;
      if (parent3) {
        parent3.classList.add("active"); // li
        const parent4 = parent3.parentElement;
        if (parent4) {
          parent4.classList.add("active"); // li
          const parent5 = parent4.parentElement;
          if (parent5) {
            parent5.classList.add("active"); // li
            const parent6 = parent5.parentElement;
            if (parent6) {
              parent6.classList.add("active"); // li
            }
          }
        }
      }
    }
    return false;
  }

  // let isAdmin = JSON.parse(localStorage.getItem("authUser"))["_doc"]["role"] == "Admin";
  let isAdmin = true;

  return (
    <React.Fragment>
      <div className="topnav">
        <div className="container-fluid">
          <nav
            className="navbar navbar-light navbar-expand-lg topnav-menu"
            id="navigation"
          >
            <Collapse
              isOpen={props.leftMenu}
              className="navbar-collapse"
              id="topnav-menu-content"
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link to="/dashboard" className="nav-link">
                    {props.t("Dashboard")}
                  </Link>
                </li>

                {isAdmin && (
                  <li className="nav-item dropdown">
                    <Link
                      to="/#"
                      onClick={e => {
                        e.preventDefault();
                        setusers(!users);
                      }}
                      className="nav-link dropdown-toggle arrow-none"
                    >
                      <i className="bx bx-tone me-2"></i>
                      {props.t("Providers")} <div className="arrow-down"></div>
                    </Link>
                    <div
                      className={classname("dropdown-menu", { show: users })}
                    >
                      <Link to="/provider-type" className="dropdown-item">
                        {props.t("Provider Type")}
                      </Link>
                      <Link to="/provider-list" className="dropdown-item">
                        {props.t("Provider List")}
                      </Link>
                      {/* <Link to="/provider-request" className="dropdown-item">
                        {props.t("Provider Request")}
                      </Link> */}
                      <div className="dropdown">
                        <Link
                          to="/#"
                          className="dropdown-item dropdown-toggle arrow-none"
                          onClick={e => {
                            e.preventDefault();
                            setemail(!email);
                          }}
                        >
                          {props.t("Galleries")}{" "}
                          <div className="arrow-down"></div>
                        </Link>
                        <div
                          className={classname("dropdown-menu", {
                            show: email,
                          })}
                        >
                          <Link to="/gallery-list" className="dropdown-item">
                            {props.t("Galleries")}
                          </Link>
                          <Link to="/create-gallery" className="dropdown-item">
                            {props.t("Create gallery")}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </li>
                )}
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      setcomponent(!component);
                    }}
                  >
                    <i className="bx bx-collection me-2"></i>
                    {props.t("Services")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    <Link to="/Services-list" className="dropdown-item">
                      {props.t("Services List")}
                    </Link>
                    <Link to="/Services-create" className="dropdown-item">
                      {props.t("Create Services")}
                    </Link>
                    <Link to="/brands" className="dropdown-item">
                      {props.t("Brands List")}
                    </Link>
                    <Link to="/brand-create" className="dropdown-item">
                      {props.t("Create Brand")}
                    </Link>
                    <Link to="/products" className="dropdown-item">
                      {props.t("Products List")}
                    </Link>
                    <Link to="/product-create" className="dropdown-item">
                      {props.t("Create Product")}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    className="nav-link dropdown-toggle arrow-none"
                    onClick={e => {
                      e.preventDefault();
                      setcomponent(!component);
                    }}
                  >
                    <i className="bx bx-collection me-2"></i>
                    {props.t("Categories")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: component })}
                  >
                    <Link to="/parentcategory-list" className="dropdown-item">
                      {props.t("Parent Category List")}
                    </Link>
                    <Link to="/parentcategory-create" className="dropdown-item">
                      {props.t("Create Parent Category")}
                    </Link>
                    <Link to="/category-list" className="dropdown-item">
                      {props.t("SUb Category List")}
                    </Link>
                    <Link to="/category-create" className="dropdown-item">
                      {props.t("Sub Create Category")}
                    </Link>
                  </div>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setproviders(!providers);
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone me-2"></i>
                    {props.t("Users")} <div className="arrow-down"></div>
                  </Link>
                  <div
                    className={classname("dropdown-menu", { show: providers })}
                  >
                    <div>
                      <Link to="/User-list" className="dropdown-item">
                        {props.t("Users List")}
                      </Link>
                    </div>
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <Link
                    to="/#"
                    onClick={e => {
                      e.preventDefault();
                      setui(!ui);
                    }}
                    className="nav-link dropdown-toggle arrow-none"
                  >
                    <i className="bx bx-tone me-2"></i>
                    {props.t("Settings")} <div className="arrow-down"></div>
                  </Link>
                  <div className={classname("dropdown-menu", { show: ui })}>
                    <div>
                      <Link to="/global-settings" className="dropdown-item">
                        {props.t("Global Settings")}
                      </Link>
                    </div>
                  </div>
                </li>
                {/* <li className="nav-item">
                <Link to="/bookings-list"  className="nav-link">
                        {props.t("Bookings")}
                      </Link>
                </li> */}
                <li className="nav-item">
                  <Link to="/orders" className="nav-link">
                    {props.t("Orders")}
                  </Link>
                </li>
              </ul>
            </Collapse>
          </nav>
        </div>
      </div>
    </React.Fragment>
  );
};

Navbar.propTypes = {
  leftMenu: PropTypes.any,
  location: PropTypes.any,
  menuOpen: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = state => {
  const { leftMenu } = state.Layout;
  return { leftMenu };
};

export default withRouter(
  connect(mapStatetoProps, {})(withTranslation()(Navbar))
);
