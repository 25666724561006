import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Label,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";

const modelInitialValues = {
  id: "",
  name: "",
  serviceId: "",
  price: "",
  priceType: "",
  currency: "INR",
};

const EditService = ({ service, saveEditService, cancelEditService }) => {
  const [modelProps, setModelProps] = useState(modelInitialValues);
  const [selectedPriceType, setSelectedPriceType] = useState(null);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (service) {
      const obj = { label: service.priceType, value: service.priceType };
      setSelectedPriceType(obj);
      setModelProps(service);
    }
  }, []);

  const priceTypeList = [
    { label: "On Demand", value: "On Demand" },
    { label: "Fixed", value: "Fixed" },
  ];

  function handleModelPropsChange(name, value) {
    let obj = { ...modelProps };
    obj[name] = value;
    setModelProps(obj);
  }
  function handleSelectType(event) {
    handleModelPropsChange("priceType", event.value);
    setSelectedPriceType(event);
    console.log(event, "event");
  }

  const cancelModelProps = () => {
    cancelEditService();
  };

  const saveModelProps = () => {
    saveEditService(modelProps);
  };
  const toggleModel = () => cancelModelProps();

  return (
    <>
      <Modal isOpen={true} toggle={toggleModel} size="lg" centered>
        <ModalHeader toggle={toggleModel}>
          {service?.serviceId || service?.id?.serviceId} - {service?.name}
        </ModalHeader>
        <ModalBody>
          <Row className="mt-1">
            <Col md={4}>
              {" "}
              <Label htmlFor="type">Price Type</Label>
              <Select
                name="type"
                id="type"
                value={selectedPriceType}
                onChange={event => {
                  handleSelectType(event);
                }}
                options={priceTypeList}
                classNamePrefix={"select2-selection "}
              />
            </Col>
            <Col md={4}>
              <Label htmlFor="price">Price</Label>
              <Input
                type="number"
                className="form-control"
                id="price"
                placeholder="Price"
                name="price"
                onChange={event =>
                  handleModelPropsChange("price", event.target.value)
                }
                // disabled={}
                value={modelProps.price}
              />
            </Col>
            <Col md={4}>
              <Label htmlFor="currency">Currency</Label>
              <Input
                type="text"
                className="form-control"
                id="currency"
                placeholder="INR"
                name="currency"
                onChange={event =>
                  handleModelPropsChange("currency", event.target.value)
                }
                value={modelProps.currency}
                disabled
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className="w-md btn-sm me-2"
            onClick={() => cancelModelProps()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-md btn-sm me-2"
            onClick={() => saveModelProps()}
          >
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default EditService;
