import React from "react";
import { Container, Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const EarningsDetails = ({ modal, toggle, data }) => {
  console.log("data=", data);
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
          <div className="d-flex align-items-center">
            <h5 className="fw-bold text-uppercase mb-0">
              Earnings Details (#{data?.orderNumber})
            </h5>
          </div>
        </ModalHeader>
        <ModalBody className="">
          <Container fluid>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th></th>
                  <th> Earnings(%)</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Service</td>
                  <td className="">
                    {data?.serviceEarningsPercentage
                      ? data?.serviceEarningsPercentage
                      : 0}
                  </td>
                  <td>{data?.serviceAmount ? data?.serviceAmount?.toFixed(2) : 0}</td>
                </tr>
                <tr>
                  <td>Products</td>
                  <td>
                    {data?.productEarningsPercentage
                      ? data?.productEarningsPercentage
                      : 0}
                  </td>
                  <td> {data?.productsAmount ? data?.productsAmount?.toFixed(2) : 0}</td>
                </tr>
                <tr>
                  <td></td>
                  <td>Partner Earnigs</td>
                  <td> {data?.partnerEarnings ? data?.partnerEarnings?.toFixed(2) : 0}</td>
                </tr>
                <tr>
                  <td></td>
                  <th>Order Total</th>
                  <th>
                    {data?.orderAmount ? data?.orderAmount?.toFixed(2) : 0}
                  </th>
                </tr>
              </tbody>
            </Table>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EarningsDetails;
