import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { getOrders as onGetOrders } from "store/actions";

const ProviderRequest = props => {
  const dispatch = useDispatch();

  // validation

  // const { orders } = useSelector(state => ({
  //   orders: state.ecommerce.orders,
  // }));
  let orders = [
    {
      id: 1,
      name: "John Ran",
      email: "John@gmail.com",
      status: "inactive",
      requestedDate: new Date(),
    },
    {
      id: 2,
      name: "Meher San",
      email: "meher@gmail.com",
      status: "active",
      requestedDate: new Date(),
    },
    {
      id: 3,
      name: "Ali Baljis",
      email: "ali@gmail.com",
      status: "inactive",
      requestedDate: new Date(),
    },
    {
      id: 4,
      name: "Ali Baljis",
      email: "ali@gmail.com",
      status: "inactive",
      requestedDate: new Date(),
    },
    {
      id: 5,
      name: "Ali Baljis",
      email: "ali@gmail.com",
      status: "inactive",
      requestedDate: new Date().toLocaleDateString(),
    },
    {
      id: 6,
      name: "Ali Baljis",
      email: "ali@gmail.com",
      status: "inactive",
      requestedDate: new Date(),
    },
    {
      id: 7,
      name: "Ali Baljis",
      email: "ali@gmail.com",
      status: "inactive",
      requestedDate: new Date(),
    },
    {
      id: 8,
      name: "Ali Baljis",
      email: "ali@gmail.com",
      status: "inactive",
      requestedDate: new Date(),
    },
    {
      id: 9,
      name: "Ali Baljis",
      email: "ali@gmail.com",
      status: "inactive",
      requestedDate: new Date(),
    },
  ];

  //pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;
  const badgeColor = "font-size-12 badge-soft-";

  const ProvidersColumn = () => [
    {
      dataField: "id",
      text: "S.No",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.id}
        </Link>
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
   
    {
      dataField: "requestedDate",
      text: "Requested At",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.requestedDate),
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-success"
              // onClick={() => handleOrderClick(order)}
              onClick={() => alert("Work in progress")}
            >
              <i className="fa fa-check font-size-18" id="accepttooltip" />
              <UncontrolledTooltip placement="top" target="accepttooltip">
                Accept
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              // onClick={() => onClickDelete(order)}
              onClick={() => alert("Work in progress")}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
    {
        dataField: "view",
        isDummyField: true,
        text: "View Details",
        sort: true,
        // eslint-disable-next-line react/display-name
        formatter: () => (
          <Link to="/request-view">
            <Button
              type="button"
              color="bg-secondary"
              className="btn-sm btn-rounded btn-outline-primary"
            >
              View Details
            </Button>
          </Link>
        ),
      },
  ];

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders());
    }
  }, [dispatch, orders]);

  const handleOrderClicks = () => {};

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Providers" breadcrumbItem="Provider Request" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProvidersColumn()}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={ProvidersColumn()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProviderRequest.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
};

export default withRouter(ProviderRequest);
