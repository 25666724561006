import { RingLoader, PulseLoader, HashLoader } from "react-spinners";
import React, { Component, useEffect, useState } from "react";

const backStyles = {
  display: "block" /* Hidden by default */,
  position: "fixed" /* Stay in place */,
  zIndex: "999" /* Sit on top */,
  left: "0",
  top: "0",
  width: "100vw" /* Full width */,
  height: " 100vh" /* Full height */,
  overflow: "auto" /* Enable scroll if needed */,
  backgroundColor: "rgba(0, 0, 0, 0.4)" /* Black w/ opacity */,
};

let spinnerContent = {
  position: "absolute",
  top: "45%",
  left: "45%",
};

const Spinner = ({ loading }) => {
  if (loading) {
    return (
      <div style={backStyles}>
        <PulseLoader
          size="30px"
          margin="10px"
          css={spinnerContent}
          color={"#FFFFFF"}
          loading={true}
        />
        hgdfgdfghv
      </div>
    );
  } else return null;
};

export default Spinner;
