import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ProductsCreate1 from "./create1";
import ProductsCreate2 from "./create2";
import {
  createProduct,
  updateProduct,
} from "services/ecommerce/product.service";
import * as _ from "lodash";
import SweetAlert from "react-bootstrap-sweetalert";
const defaultValues = {
  name: "",
  // description: "",
  description: [{ name: "", value: "" }],
  content: "",
  featuredimage: "",
  images: [],
  sku: "",
  price: "",
  discount: "",
  stockStatus: "In Stock",
  weight: "",
  length: "",
  wide: "",
  height: "",
  relatedProducts: [],
  crossSelingProducts: [],
  faqs: [{ question: "", answer: "" }],
  attributes: [],
  tags: "",
  tax: "",
  mrp: "",
  totalQuantity: 0,
  minimumOrderQuantity: 0,
  shippingCost: 0,
  labels: "",
  collections: "",
  mainCategory: "",
  parentCategory: "",
  category: "",
  brand: "",
  store: "",
  isFeatured: false,
  isServiceProduct: false,
  isEcomProduct: true,
  status: "Publish",
  type: "simple",
  discounttype: "flat",
  shippingMultiplyWithQuantity: false,
  unit: "kg",
  flatDiscount: 0,
  percentageDiscount: 0,
  properties: [{ name: "", value: "" }],
  ratings: 0,
  reviews: 0,
  keyWords: [],
};

const ProductsIndex = ({ props, toggle, modal, product, reload }) => {
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [selectedCat, setSelectedCat] = useState(null);
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [id, setid] = useState("");
  useEffect(() => {
    if (product) {
      if (product?.category?._id) {
        let catObj = {
          label: product.category.name,
          value: product.category._id,
        };
        setSelectedCat(catObj);
      }

      product["category"] = product.category._id;
      setid(product._id);
      setValues(product);
    }
    if (product) {
      if (product?.brand?._id) {
        let brandObj = {
          label: product.brand.name,
          value: product.brand._id,
        };
        setSelectedBrand(brandObj);
      }

      product["brand"] = product.brand._id;
      setid(product._id);
      setValues(product);
    }
  }, [product]);

  const handleChangeProperties = (property, value) => {
    const tempValues = { ...values };

    if (property === "category") {
      tempValues["category"] = value?._id;
      tempValues["mainCategory"] = value?.mainCategory?._id;
      tempValues["parentCategory"] = value?.parentCategory?._id;
      tempValues["relatedProducts"] = value?.relatedProducts;
      tempValues["crossSelingProducts"] = value?.crossSelingProducts;
      setSelectedCat(value);
    } else {
      tempValues[property] = value;
    }
    setValues(tempValues);
  };
  const handleUpdateMulti = newObject => {
    setValues({ ...values, ...newObject });
  };
  const handleSubmit = () => {
    setLoading(true);
    let formData = new FormData();

    //Setting attrImges
    if (
      values["attributes"] &&
      values["attributes"].length > 0 &&
      values["attributes"][0]["combos"]
    ) {
      let tempAttributes = _.cloneDeep(values["attributes"]);
      let combos = _.cloneDeep(tempAttributes[0]["combos"]);
      combos = combos?.filter((combo, arrInd) => {
        let imgArr = [];
        combo?.images?.forEach((ele, ind) => {
          if (typeof ele !== "string") {
            formData.set("attrImages" + arrInd + "" + ind, ele);
          } else {
            imgArr.push(ele);
          }
        });
        combo.images = imgArr;
        return combo;
      });

      tempAttributes[0]["combos"] = combos;
      values["attributes"] = tempAttributes;
    }

    for (let key in values) {
      if (
        key === "attributes" ||
        key === "faqs" ||
        key === "properties" ||
        key === "description"
      ) {
        formData.set(key, JSON.stringify(values[key]));
      } else if (key == "images") {
        const previousImage = [];
        values.images.forEach((e, i) => {
          if (typeof e === "string") {
            previousImage.push(e);
          }
          if (Object.keys(e).length !== 7) {
            formData.set(`image${i}`, e);
          }
        });
        formData.set("images", JSON.stringify(previousImage));
      } else if (key === "relatedProducts" || key === "crossSelingProducts") {
        const status = values[key];
        if (typeof status === "string") {
          formData.set(key, JSON.stringify(values[key]));
        } else {
          const related = [];
          values[key]?.map(e => {
            if (e) {
              related.push(e.value);
            }
          });
          formData.set(key, JSON.stringify(related));
        }
      } else if (
        key === "brand" ||
        key === "category" ||
        key === "parentCategory" ||
        key === "mainCategory"
      ) {
        const status = values[key];
        if (typeof status === "string") {
          formData.set(key, values[key]);
        } else {
          formData.set(key, values[key]._id);
        }
      } else if (key === "keyWords") {
        formData.set(key, JSON.stringify(values[key]));
      } else {
        formData.set(key, values[key]);
      }
    }
    if (values.totalQuantity > 0) {
      formData.set("stockStatus", "In Stock");
    } else {
      formData.set("stockStatus", "Out of Stock");
    }

    if (!id) {
      create(formData);
    } else {
      updateValues(formData);
    }
  };
  const updateValues = formData => {
    updateProduct(id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        seterror_msg(true);
        setLoading(false);
      });
  };
  const create = formData => {
    createProduct(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        seterror_msg(true);
        setLoading(false);
      });
  };
  const leaveToList = () => {
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    reload();
  };
  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        {!id ? (
          <div>Successfully Product created</div>
        ) : (
          <div>Successfully Product edited</div>
        )}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {id ? "Edit Product" : "Create Product"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <Row>
              <Col md="9">
                <ProductsCreate1
                  handleChangeProperties={handleChangeProperties}
                  val={values}
                  selectedCat={selectedCat}
                  id={id}
                  handleUpdateMulti={handleUpdateMulti}
                />
              </Col>
              <Col md="3">
                <ProductsCreate2
                  handleChangeProperties={handleChangeProperties}
                  val={values}
                  selectedBrand={selectedBrand}
                  handleUpdateMulti={handleUpdateMulti}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end mb-3">
              <Button
                color="light"
                className="w-md btn-sm me-4 bg-white"
                onClick={() => {
                  leaveToList();
                }}
              >
                cancel
              </Button>
              {!id ? (
                <Button
                  color="primary"
                  type="submit"
                  className="w-md btn-sm "
                  onClick={() => handleSubmit()}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="w-md btn-sm me-4"
                  type="submit"
                  onClick={() => handleSubmit()}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Container>

          {success_msg ? successPopup() : null}
          {error_msg ? errorPopup() : null}
        </ModalBody>
      </Modal>
    </>
  );
};

export default ProductsIndex;
