import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import Spinner from "components/spinner";
import {
  createParentCategory,
  getMainCategory,
  getParentCategoryByMain,
  updateParentCategory,
} from "services/ecommerce/category.service";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import ProfileImage from "./../../../components/profileImage";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { filterOptions } from "utils/filterOptions";
import Filters from "./filters";

const defaultValues = {
  name: "",
  mainCategory: "",
  image: {},
  order: "",
  status: "active",
};

const EcomCreateParent = ({ toggle, modal, editObj = null, reloadService }) => {
  const [selectedMainCat, setSelectedMainCat] = useState(null);
  const [mainCatList, setMainCatList] = useState([]);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState([]);
  const [inputFilter, setInputFilter] = useState("");
  const [selectedType, setSelectedType] = useState(null);
  const initialValues = defaultValues;
  const { image } = values;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    mainCategory: Yup.string().required("Please Select Main Category"),
  });
  const typeList = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];
  const onSubmit = values => {
    if (editObj) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };
  useEffect(() => {
    getMainCategory()
      .then(res => {
        if (res.data) {
          let list = res?.data;
          let resArr = [];
          list.map(cat => {
            resArr.push({
              value: cat._id,
              label: cat.name,
            });
          });
          setMainCatList(resArr);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  function handleSelectMainCat(event) {
    setValues({ ...values, ["mainCategory"]: event.value });
    setSelectedMainCat(event);
  }
  function handleSelectType(event) {
    setValues({ ...values, ["status"]: event.value });
    setSelectedType(event);
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    createParentCategory(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        reloadService();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    updateParentCategory(editObj._id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        reloadService();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        {!editObj ? (
          <div>Successfully Parent-Category created</div>
        ) : (
          <div>Successfully Parent-Category edited</div>
        )}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  /* Formats the size */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {editObj
            ? "Edit Product ParentCategory"
            : "Create Product ParentCategory"}{" "}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid={false}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    setFieldTouched,
                    values,
                  }) => {
                    useEffect(() => {
                      if (editObj) {
                        setLoading(true);
                        const data = { ...editObj };
                        const fields = [
                          "name",
                          "mainCategory",
                          "image",
                          "order",
                          "status",
                        ];
                        fields.forEach(field =>
                          setFieldValue(field, data[field], false)
                        );
                        setFieldValue("status", data.status, false);
                        setSelectedType({
                          value: data.status,
                          label:
                            data.status === "active" ? "Active" : "Inactive",
                        });
                        setFieldValue(
                          "mainCategory",
                          data.mainCategory._id,
                          false
                        );
                        setSelectedMainCat({
                          value: data.mainCategory._id,
                          label: data.mainCategory.name,
                        });
                      }
                    }, []);

                    return (
                      <Form>
                        <Row className="mt-4">
                          <Col md={4} className="px-2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Name</Label>
                              <Input
                                name="name"
                                placeholder="Name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.name}
                                invalid={
                                  touched.name && errors.name ? true : false
                                }
                              />

                              {touched.name && errors.name ? (
                                <FormFeedback type="invalid">
                                  {errors.name}
                                  {touched.name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">
                                Main Category
                              </Label>
                              <Select
                                id="validationCustom05"
                                value={selectedMainCat}
                                onChange={event => {
                                  handleSelectMainCat(event);
                                  handleChange("mainCategory")(event.value);
                                }}
                                name="mainCategory"
                                options={mainCatList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.mainCategory ? errors.mainCategory : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={4} className="px-2">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Order</Label>
                              <Input
                                name="order"
                                placeholder="order"
                                type="number"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.order}
                              />
                            </FormGroup>{" "}
                          </Col>
                        </Row>
                        <Row className="">
                          <Col md={6}>
                            <div>
                              <Label>Status</Label>
                              <FormGroup className="mb-3">
                                <Select
                                  id="Status"
                                  value={selectedType}
                                  onChange={event => {
                                    handleSelectType(event);
                                    handleChange("status")(event.value);
                                  }}
                                  name="Status"
                                  options={typeList}
                                  classNamePrefix="select2-selection"
                                />
                              </FormGroup>
                            </div>
                          </Col>{" "}
                          <Col md="6" className="px-2">
                            <FormGroup className="mb-3 ">
                              <Label htmlFor="validationCustom06">Image</Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("image", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="statusValidation">
                                {" "}
                                {errors.image ? errors.image : ""}
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.image &&
                                  Object.keys(values.image).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.image}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.image.name ||
                                                values.image.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.image.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>

                        <hr></hr>
                        <div className="d-flex justify-content-end">
                          <Button
                            color="light"
                            className="w-md btn-sm me-2"
                            onClick={leaveToList}
                          >
                            cancel
                          </Button>
                          {!editObj ? (
                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm "
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              className="w-md btn-sm me-4"
                              type="submit"
                            >
                              Save Changes
                            </Button>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
            {editObj?._id && (
              <Filters
                parentCatId={editObj?._id}
                setsuccess_msg={setsuccess_msg}
                seterror_msg={seterror_msg}
                leaveToList={leaveToList}
              />
            )}
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EcomCreateParent;
