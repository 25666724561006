import React, { useState, useEffect } from "react";

import { Badge, Button, CardText, Col, Row } from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const Biography = props => {
    const {data} = props
  const [showEdit, setShowEdit] = useState(false);
  useEffect(() => {}, []);

  const handleUpdate = () => {
    setShowEdit(true);
  };

  const saveChanges = () => {
    setShowEdit(false);
  };

  const cancelChanges = () => {
    setShowEdit(false);
  };

  let ViewBiography = () => {
    return (
      <React.Fragment>
        <div className="text-end">
        </div>
        <div className="d-flex flex-wrap mt-3">
          <CardText className="mb-0">
          <div dangerouslySetInnerHTML={{__html: data}}/>
          </CardText>
        </div>
      </React.Fragment>
    );
  };

  let UpdateBiography = () => {
    return (
      <div className="mt-3">
        <Row className="mb-5">
          <Col md={12} className="">
            <label className="col-form-label form-label">Create Biography</label>
          </Col>
          <Col md={12}>
            {" "}
            <CKEditor
              editor={ClassicEditor}
              data="<p></p>"
              onReady={editor => {
                // You can store the "editor" and use when it is needed.
                console.log("Editor is ready to use!", editor);
              }}
              onChange={(event, editor) => {
                const data = editor.getData();
              }}
            />
          </Col>
        </Row>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            color="primary"
            className="w-md btn-sm me-4"
            onClick={() => saveChanges()}
          >
            Save Changes
          </Button>
          <Button
            color="light"
            className="w-md btn-sm"
            onClick={() => cancelChanges()}
          >
            cancel
          </Button>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {showEdit ? UpdateBiography() : ViewBiography()}
    </React.Fragment>
  );
};

export default Biography;
