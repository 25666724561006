import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { getOrders as onGetOrders } from "store/actions";

const BookingList = props => {
  const dispatch = useDispatch();

  // validation

  // const { orders } = useSelector(state => ({
  //   orders: state.ecommerce.orders,
  // }));
  let orders = [
    {
      id: "#123",
      service: "Post Party Cleaning",
      provider: "Cleaning Service inc.",
      customer: "Jennifer Paul",
      address: "GQ8C4Gb,Sidhardh Nagar,Pune, Maharastra, 41102, India",
      bookingStatus: "Received",
      paymentStatus: "_",
    },
    {
      id: "#1363",
      service: "Hair Makeover",
      provider: "Beauty Service inc.",
      customer: "Sam Sundhar",
      address: "Shalivana Nagar,Dilsukh Nagar, Hyderabad, 500071, India",
      bookingStatus: "Ongoing",
      paymentStatus: "_",
    },
    {
      id: "#3243",
      service: "Facial and Hair Cleaning",
      provider: "Wellness Service inc.",
      customer: "Madhavan",
      address: "4/234,Bala Nagar,Chennai, TamilaNadu, 56102, India",
      bookingStatus: "Received",
      paymentStatus: "_",
    },
    {
      id: "#763",
      service: "Post Party Cleaning",
      provider: "Cleaning Service inc.",
      customer: "John Sin",
      address: "RTED45S,Gandhi Nagar,Pune, Maharastra, 41342, India",
      bookingStatus: "Ongoing",
      paymentStatus: "_",
    },
  ];

  //pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;
  const badgeColor = "font-size-12 badge-soft-";

  const ProvidersColumn = () => [
    {
      dataField: "id",
      text: "ID",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.id}
        </Link>
      ),
    },
    {
      dataField: "service",
      text: "Service",
    },
    {
      dataField: "provider",
      text: "Provider",
    },
    {
      dataField: "customer",
      text: "Customer",
    },
    {
      dataField: "address",
      text: "Address",
      formatter: (cellContent, row) => {
        let address = row.address.split(",");
        return (
          <address>
            {address?.map(addr => {
              return <p className="m-0" key={addr}>{addr}</p>;
            })}
          </address>
        );
      }
    },
    {
      dataField: "bookingStatus",
      text: "Booking Status",
      formatter: (cellContent, row) => (
        <Badge
          className={row.bookingStatus == "Ongoing" ? "bg-warning" : "bg-info"}
          color={"bg-secondary"}
        >
          {row.bookingStatus}
        </Badge>
      ),
    },
    {
      dataField: "paymentStatus",
      text: "Payment Status",
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to="#"
              className="text-primary"
              // onClick={() => handleOrderClick(order)}
            >
              <i className="mdi mdi-eye font-size-18" id="viewtooltip" />
              <UncontrolledTooltip placement="top" target="viewtooltip">
                View
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-success"
              // onClick={() => handleOrderClick(order)}
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              // onClick={() => onClickDelete(order)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders());
    }
  }, [dispatch, orders]);

  const handleOrderClicks = () => {};

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Bookings" breadcrumbItem="Bookings List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProvidersColumn()}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={ProvidersColumn()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8"></Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

BookingList.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
};

export default withRouter(BookingList);
