import Breadcrumb from "components/Common/Breadcrumb";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { getSettlementsSummaryByCompanyId } from "services/settlements.service";
import { Link } from "react-router-dom";
import noImage from "assets/images/PicUpload.jpg";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const SettlementsSummaryCompany = () => {
  const [loading, setLoading] = useState(true);
  const [companyData, setCompanyData] = useState([]);

  useEffect(() => {
    getSettlementsSummaryByStylist();
  }, []);

  const getSettlementsSummaryByStylist = () => {
    setLoading(true);
    getSettlementsSummaryByCompanyId()
      .then(res => {
        if (res?.success === true) {
          setCompanyData(res?.data || []);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const CompanyList = () => {
    return companyData?.map((el, index) => {
      return (
        <Col key={index} xs="3">
          <Link to={`/settlements/${el?._id}?access=Company`} className="">
            <Card>
              <CardBody>
                <div className="d-flex">
                  <div className="me-3">
                    <img
                      className="rounded"
                      src={
                        !el?.company[0]?.image ||
                        el?.company[0]?.image === "undefined"
                          ? noImage
                          : el?.company[0]?.image
                      }
                      alt=""
                      width={80}
                      height={80}
                      role="button"
                    />
                  </div>
                  <div>
                    <h6 className="fw-bold mb-1 text-uppercase">
                      {el?.company[0]?.companyName
                        ? el?.company[0]?.companyName
                        : ""}
                    </h6>
                    <span> Total Orders: </span>
                    <span className="fw-bold">{el?.totalOrders}</span>
                  </div>
                </div>
                <div className="d-flex justify-content-between mt-2 align-items-center">
                  <div>
                    <span> Unsettled Orders: </span>
                    <span className="fw-bold"> {el?.unsettledOrders}</span>
                  </div>
                  <div className="text-center rounded pending_payment">
                    <span className="font-size-16 fw-bold">
                      ₹ {el?.due?.toFixed(2)}
                    </span>
                    <br />
                    <span className="font-size-10">Pending Payment</span>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Link>
        </Col>
      );
    });
  };
  return (
    <>
      <React.Fragment>
        <Spinner loading={loading} />
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb
              title="Settlements"
              breadcrumbItem="Settlements Summary"
            />
            <BreadcrumbSub
              subtile="Pending Settlemets by individual"
              SubBreadcrumbItem="Company"
            />
            <Row>
              <>{CompanyList()}</>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default SettlementsSummaryCompany;
