import { createImageUrl } from "services/imageUrl.service";

export const getImageUrl = obj => {
  console.log(obj, "obj");
  let formData = new FormData();
  const values = { ...obj };
  for (let key in values) {
    values?.image?.map(ele => {
      if (ele && typeof ele == "object") {
        formData.set("image", ele);
      }
      return ele;
    });
    // formData.set(key, values[key] ? JSON.stringify(image) : JSON.stringify([]));
  }
  let url;
  return createImageUrl(formData)
    .then(res => {
      console.log(res);
      if (res?.success) {
        url = res?.image;
        console.log(url, "get url");
        return url;
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export default getImageUrl;
