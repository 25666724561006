import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import * as _ from "lodash";
import "./info.scss";
import { Card, Col, Container, Row, CardBody, Button, Input } from "reactstrap";

import { getcompanyById, updatecompany } from "services/company.service";

//Import Breadcrumb
// import Breadcrumb from "../../../components/Common/Breadcrumb";x`
import Breadcrumb from "components/Common/Breadcrumb";
import BasicInfo from "./basicInfo";
import IdProofs from "../../individual/info/idproof";
import ZipcodeInfo from "../../individual/info/zipcodeInfo";
import MyTeam from "./myTeam";
import CompanyStatusBar from "./CompanyStatusBar";

const basicInitialValues = {
  companyName: "",
  person: "",
  address: "",
  zipcode: "",
  city: "",
  state: "",
  country: "",
  mobileNumber: "",
  note: "",
  idVerification: false,
};
const zipInitialValues = [
  {
    zipcode: "",
    address: "",
  },
  {
    zipcode: "",
    address: "",
  },
  {
    zipcode: "",
    address: "",
  },
];

const teamInitialVal = {
  team: [],
};

const initStatusInfo = {};
const CompanyInfo = props => {
  const { id } = useParams();
  //   const [isExpnded, setIsExpanded] = useState(false);
  const [basicInfo, setBasicInfo] = useState(basicInitialValues);
  const [proofInfo, setProofInfo] = useState([]);
  const [myTeamInfo, setMyTeamInfo] = useState(teamInitialVal);
  const [zipInfo, setZipInfo] = useState(zipInitialValues);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [statusInfo, setStatsInfo] = useState(initStatusInfo);

  useEffect(() => {
    getCurrentProvider();
  }, []);

  const getCurrentProvider = () => {
    getcompanyById(id)
      .then(res => {
        if (res.data) {
          let {
            companyName,
            person,
            address,
            zipcode,
            city,
            state,
            country,
            mobileNumber,
            image,
            note,
            idProofs,
            team,
            zipcodes,
            status,
            updatedAt,
            createdAt,
            comments,
          } = res.data;
          let basicObj = {
            companyName,
            person,
            address,
            zipcode,
            city,
            state,
            country,
            mobileNumber,
            image,
            note,
            idProofs,
            status,
            updatedAt,
            createdAt,
            comments,
          };
          setStatsInfo({ status, updatedAt, createdAt, comments });
          setBasicInfo(basicObj);
          if (idProofs && idProofs.length > 0) {
            setProofInfo(idProofs);
          }
          if (zipcodes && zipcodes.length > 0) {
            setZipInfo(zipcodes);
          }
          if (team && team.length > 0) {
            let teamInfo = {
              team: team,
            };
            setMyTeamInfo(teamInfo);
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const updateForm = fieldValues => {
    let formData = new FormData();

    const values = {
      ...basicInfo,
      ...myTeamInfo,
      ...fieldValues,
      ...statusInfo,
    };

    for (const key in values) {
      if (key === "zipcodes") {
        formData.set(
          key,
          values[key]
            ? JSON.stringify(values[key])
            : JSON.stringify(zipInitialValues)
        );
      } else {
        formData.set(key, values[key]);
      }
    }

    values.idProofs?.map((ele, idx) => {
      if (ele.docs && typeof ele.docs == "object") {
        formData.set("idProofs" + idx, ele.docs);
      }
    });
    formData.set("idProofs", JSON.stringify(values.idProofs));
    let tempTeam = [];

    values.team?.map((ele, ind) => {
      tempTeam.push(ele._id);
    });

    formData.set("team", JSON.stringify(tempTeam));
    updatecompany(id, formData)
      .then(res => {
        setsuccess_msg(true);
        getCurrentProvider();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    // props.history.push("/Provider-list-individul");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
    // props.history.push("/Provider-list-individul");
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Indvidual created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              // onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Provider Company Info | stylepro</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumb title="Company Provider" breadcrumbItem="Info" />
          <div className="individual_info">
            <Row>
              <Col md={12}>
                <CompanyStatusBar
                  statusInfo={statusInfo}
                  handleSubmit={updateForm}
                />
              </Col>
              <Col md={12}>
                {" "}
                <BasicInfo
                  basicInitialValues={basicInfo}
                  handleSubmit={updateForm}
                />
              </Col>
              <Col md={12}>
                {" "}
                <IdProofs
                  proofInitialValues={proofInfo}
                  handleSubmit={updateForm}
                />
              </Col>

              <Col md={12}>
                {" "}
                <MyTeam
                  myTeamInitialVal={myTeamInfo.team}
                  handleSubmit={updateForm}
                />
              </Col>
              {/* <Col md={6}>
                <ZipcodeInfo
                  zipInitialValues={zipInfo}
                  handleSubmit={updateForm}
                />
              </Col> */}
            </Row>
            {success_msg ? successPopup() : null}
            {error_msg ? errorPopup() : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CompanyInfo);
