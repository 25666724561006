import React from "react";
import noImage from "assets/images/PicUpload.jpg";
import { Card } from "reactstrap";

const EcomParentCat = ({ list, handleClick }) => {
  return (
    <div className="">
      <div>
        {!list && (
          <div className="empty_list p-3 pt-0">
            <div className="text-center">
              <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
            </div>
            <p className="text-center">Please Select Parent Category</p>
          </div>
        )}
        {list && list.length === 0 && (
          <div className="empty_list p-3 pt-0">
            <div className="text-center">
              <i className="mdi mdi-circle-off-outline" id="EmptyCatList"></i>
            </div>
            <h4>Empty List</h4>
            <p className="text-center">No Categories Found</p>
          </div>
        )}
      </div>
      {list?.map((ele, index) => (
        <Card
          className="shadow-sm bg-white rounded"
          key={index}
          onClick={() => {
            handleClick(ele, index);
          }}
        >
          <img
            alt={ele.name}
            className="header_img"
            src={(ele?.image && ele.image?.includes("http")) ? ele.image : noImage}
          />

          <div className=" text-center p-2"> {ele.name}</div>
        </Card>
      ))}
    </div>
  );
};
export default EcomParentCat;
