export const getUserCount=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/dashboard/user`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get category ${e}`))  
  }

  export const getProviderCount=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/dashboard/provider`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get category ${e}`))  
  }
  export const getServiceCount=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/dashboard/services`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get category ${e}`))  
  }
  export const getCategoriesCount=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/dashboard/categories`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get category ${e}`))  
  }