import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Input,
  InputGroup,
  InputGroupText,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

import {
  createQuickService,
  updateQuickService,
  getQuickServicesById,
} from "services/quickServices.service";

import { getBrands } from "services/brand.service";
import { getProducts } from "services/product.service";

const initialValues = {
  name: "",
  price: "",
  discountPrice: "",
  priceUnit: "",
  customQuantityUnit: "",
  duration: "",
  description: "",
  tips: "",
  banner: {},
  images: [],
  //   country:"",
  status: "",
  tagline: "",
  precautions: "",
  benfits: "",
  brands: JSON.stringify([]),
  products: JSON.stringify([]),

};

const CreateQuickService = props => {
  const { id } = useParams();
  let history = useHistory();
  const [values, setValues] = useState(initialValues);
  const [brandsList, setBrandslist] = useState([]);
  const [productsList, setProductslist] = useState([]);

  const [selectedPriceUnit, setSelectedPriceUnit] = useState(null);
  const [selectedDesc, setSelectedDesc] = useState("");
  const [selectedTagline, setSelectedTagline] = useState("");
  const [selectedbenfits, setSelectedbenfits] = useState("");
  const [selectedprecautions, setSelectedprecautions] = useState("");
  const [selectedTips, setSelectedTips] = useState("");
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);

  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addons, setaddons] = useState([
    {
      key: "",
      value: "",
    },
  ]);
  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    // country: Yup.string().required("Please Select Country"),
    price: Yup.number().required("Please Enter Price"),
    discountPrice: Yup.number().required("Please Enter Discount Price"),
    priceUnit: Yup.string().required("Please Enter Price Unit"),
    customQuantityUnit: Yup.string().required(
      "PleaseEnter Custom Quantity Unit"
    ),
    duration: Yup.string().required("Please Enter Duration"),
    description: Yup.string().required("Please Enter description"),
    tips: Yup.string().required("Please Enter Tips"),
    // banner: Yup.object(),
    // images: Yup.object().required("Please Add images"),
    status: Yup.string().required("Please select status"),
  });

  const onSubmit = values => {
    if (id) {
      EditChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const changeHandler = value => {
    setCountry(value);
  };
  const { banner, images } = values;


  const getBrandsList = () =>{
    getBrands().then(res => {
        const list = [];
        res?.data?.map(brand => {
          list.push({ label: brand.brandName, value: brand._id });
        });
        setBrandslist(list);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const getProductsList = () =>{
    getProducts().then(res => {
        const list = [];
        res?.data?.map(product => {
          list.push({ label: product.productName, value: product._id });
        });
        setProductslist(list);
      })
      .catch(err => {
        console.log(err);
      });
  }

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];


  useEffect(() => {
    getBrandsList();
    getProductsList()
  }, []);

  const goToPreviousPath = () => {
    history.goBack()
  }

  const priceUnitList = [
    { label: "Hourly", value: "hourly" },
    { label: "Day", value: "Day" },
    { label: "Monthly", value: "monthly" },
  ];

  const checkboxChange = name => event => {
    setValues({ ...values, [name]: event.target.checked });
  };

  function handleAcceptedFiles(files, field) {
    let imgArray = values["images"];
    if(imgArray.length < 7){
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      imgArray.push(files[0]);
      setValues({ ...values, [field]: imgArray });
    }
    else{
      alert("Maximum 7 Images are allowed")
    }
  }

  function handleSelectStatus(event) {
    // setValues({ ...values, ["status"]: event.value });
    setSelectedStatus(event);
  }

  function removeImage(index) {
    let imgArray = values["images"];
    imgArray.splice(index, 1);
    setValues({ ...values, ["images"]: imgArray });
  }

  function handleSelect(event, name) {
    // setValues({ ...values, [name]: event.value });
    if(name === 'priceUnit')
      setSelectedPriceUnit(event);
  }

  function handleBrands(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["brands"]: data });
    setSelectedBrands(selectedMulti);
  }

  function handleProducts(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["products"]: data });
    setSelectedProducts(selectedMulti);
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      if (key == "images") {
        let imgArr = values.images;
        if (imgArr.length > 0) {
          imgArr.forEach((img, index) => {
            formData.set("image" + index, img);
          });
        }
      }
      formData.set(key, values[key]);
    }
    formData.set("addOns", JSON.stringify(addons));
    formData.set("country", JSON.stringify(country));

    createQuickService(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
          setLoading(false);
        } else {
          seterror_msg(true);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e, "error");
        seterror_msg(true);
      });
  };

  const EditChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      if (key == "images") {
        const previousData = [];
        values.images.forEach((e, i) => {
          if (Object.keys(e).length === 4) {
            previousData.push(e);
          }
          if (Object.keys(e).length !== 4) {
            formData.set(`image${i}`, e);
          }
        });
        formData.set("country", JSON.stringify(country));

        formData.set("image", JSON.stringify(previousData));
      } else {
        // formData.set(key, values[key]);
        formData.set(
          key,
          Array.isArray(values[key])
            ? JSON.stringify(values[key])
            : values[key]
        );
      }
    }
    formData.set("addOns", JSON.stringify(addons));
    updateQuickService(id, formData)
      .then(res => {
        if (res && res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const leaveToList = () => {
    goToPreviousPath();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    goToPreviousPath();
  };

  const addNewAddon = () => {
    const feilds = [...addons];
    feilds.push({ key: "", value: "" });
    setaddons(feilds);
  };

  const removeAddon = index => {
    const feilds = [...addons];
    feilds.splice(index, 1);
    setaddons(feilds);
  };
  const handleAddOn = (event, key, index) => {
    const feilds = [...addons];
    feilds[index][key] = event.target.value;
    setaddons(feilds);
  };
  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Quick Service created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Quick Service {id ? "Update" : " Create"} | stylepro
          </title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid={false}>
          <Breadcrumb
            title="Quick Service"
            breadcrumbItem={id ? "Edit Quick Service" : "Create Quick Service"}
          />
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  values,
                }) => {
                  // console.log(errors);

                  useEffect(() => {
                    if (id) {
                      getQuickServicesById(id)
                        .then(res => {
                          const data = { ...res.data };
                          const fields = [
                            "name",
                            "price",
                            "discountPrice",
                            "priceUnit",
                            "customQuantityUnit",
                            "duration",
                            // "country",
                            "description",
                            "banner",
                            "images",
                            "tips",
                            "status",
                            "tagline",
                            "precautions",
                            "benfits",
                            "brands",
                            "products"
                          ];
                          setValues({
                            ...values,
                            banner: data["banner"],
                            images: data["images"],
                          });
                          fields.forEach(field => {
                              setFieldValue(field, data[field], false);
                          });
                          // console.log(data.addOns.length);
                          data.addOns.length > 0
                            ? setaddons(data.addOns)
                            : setaddons([{ key: "", value: "" }]);
                          setSelectedPriceUnit({
                            value: data.priceUnit,
                            label: data.priceUnit,
                          });
                          setSelectedStatus({
                            label: data.status,
                            value: data.status,
                          });
                          setCountry(data.country);
                          setSelectedDesc(data.description);
                          setSelectedTagline(data.tagline);
                          setSelectedprecautions(data.precautions);
                          setSelectedbenfits(data.benfits);
                          setSelectedTips(data.tips);
                          let brandsData = [];
                          res?.data?.brands?.map(e => {
                            let dataArray = [];
                            dataArray.push(e._id);
                            brandsData.push({ label: e.brandName, value: e._id });
                            setFieldValue("brands", dataArray);
                          });
                          let productsData = [];
                          res?.data?.products?.map(e => {
                            let dataArray = [];
                            dataArray.push(e._id);
                            productsData.push({ label: e.productName, value: e._id });
                            setFieldValue("brands", dataArray);
                          });
                          setSelectedBrands(brandsData)
                          setSelectedProducts(productsData)
                        })
                        .catch(err => {
                          console.log(err);
                          setLoading(false);
                        });
                    }
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-3">
                        <Col md={6} className="px-5">
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="name-Input"
                              className="col-form-label"
                            >
                              Name
                            </Label>
                            <Input
                              name="name"
                              placeholder="Enter Quick Service Name"
                              type="text"
                              className="form-control"
                              id="name-Input"
                              onChange={handleChange}
                              value={values.name}
                              invalid={
                                touched.name && errors.name ? true : false
                              }
                            />
                            {touched.name && errors.name ? (
                              <FormFeedback type="invalid">
                                {errors.name}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label className="col-form-label">Country</Label>
                            <Select
                              name="type"
                              id="type"
                              options={options}
                              value={country}
                              onChange={event => {
                                changeHandler(event);
                                // handleChange("country")(event);
                              }}
                              classNamePrefix="select2-selection"
                            />
                            <Label className="statusValidation">
                              {" "}
                              {errors.type ? errors.type : ""}
                            </Label>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label className="col-form-label">Price</Label>
                            <InputGroup>
                              <Input
                                name="price"
                                placeholder=""
                                type="number"
                                id="price"
                                value={values.price}
                                onChange={handleChange}
                                invalid={
                                  touched.price && errors.price ? true : false
                                }
                              />
                              <InputGroupText>$</InputGroupText>
                            </InputGroup>
                            {touched.price && errors.price ? (
                              <FormFeedback type="invalid">
                                {errors.price}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label className="col-form-label">
                              Discount Price
                            </Label>
                            <InputGroup>
                              <Input
                                type="number"
                                placeholder=""
                                name="discountPrice"
                                value={values.discountPrice}
                                onChange={handleChange}
                                invalid={
                                  touched.discountPrice && errors.discountPrice
                                    ? true
                                    : false
                                }
                              />
                              <InputGroupText>$</InputGroupText>
                            </InputGroup>
                            {touched.discountPrice && errors.discountPrice ? (
                              <FormFeedback type="invalid">
                                {errors.discountPrice}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="price-Input"
                              className="col-form-label"
                            >
                              Price Unit
                            </Label>
                            <Select
                              name="priceUnit"
                              value={selectedPriceUnit}
                              onChange={event => {
                                handleSelect(event, "priceUnit");
                                handleChange("priceUnit")(event.value);
                              }}
                              options={priceUnitList}
                              classNamePrefix="select2-selection"
                            />
                            <Label className="statusValidation">
                              {" "}
                              {errors.priceUnit ? errors.priceUnit : ""}
                            </Label>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="customQuantityUnit-Input"
                              className="col-form-label"
                            >
                              Custom quantity unit
                            </Label>
                            <Input
                              name="customQuantityUnit"
                              type="text"
                              className="form-control"
                              id="customQuantityUnit-Input"
                              onChange={handleChange}
                              value={values.customQuantityUnit}
                              invalid={
                                touched.customQuantityUnit &&
                                errors.customQuantityUnit
                                  ? true
                                  : false
                              }
                            />
                            {touched.customQuantityUnit &&
                            errors.customQuantityUnit ? (
                              <FormFeedback type="invalid">
                                {errors.customQuantityUnit}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="duration-Input"
                              className="col-form-label"
                            >
                              Duration
                            </Label>
                            <Input
                              type="text"
                              name="duration"
                              className="form-control"
                              id="duration-Input"
                              placeholder="Enter Duration "
                              onChange={handleChange}
                              value={values.duration}
                              invalid={
                                touched.duration && errors.duration
                                  ? true
                                  : false
                              }
                            />
                            {touched.duration && errors.duration ? (
                              <FormFeedback type="invalid">
                                {errors.duration}
                              </FormFeedback>
                            ) : null}
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="brands">
                              Brands
                            </Label>
                            <Select
                              id="brands"
                              value={selectedBrands}
                              isMulti={true}
                              onChange={event => {
                                handleBrands(event);
                                const data = [];
                                event.map(e => {
                                  data.push(e.value);
                                });
                                handleChange("brands")(
                                  JSON.stringify(data)
                                );
                              }}
                              options={brandsList}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="products">
                              Products
                            </Label>
                            <Select
                              id="products"
                              value={selectedProducts}
                              isMulti={true}
                              onChange={event => {
                                handleProducts(event);
                                const data = [];
                                event.map(e => {
                                  data.push(e.value);
                                });
                                handleChange("products")(
                                  JSON.stringify(data)
                                );
                              }}
                              options={productsList}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="firstname-Input"
                              className="col-form-label"
                            >
                              Banner
                            </Label>
                            <Dropzone
                              onDrop={acceptedFiles => {
                                // handleAcceptedFiles(acceptedFiles, "banner");
                                acceptedFiles.map(file =>
                                  Object.assign(file, {
                                    preview: URL.createObjectURL(file),
                                    formattedSize: formatBytes(file.size),
                                  })
                                );
                                setFieldValue("banner", acceptedFiles[0]);
                                setValues({
                                  ...values,
                                  banner: acceptedFiles[0],
                                });
                              }}
                            >
                              {({ getRootProps, getInputProps }) => (
                                <div className="dropzone image_drop">
                                  <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="">
                                      <i className="display-6 text-muted bx bxs-cloud-upload" />
                                    </div>
                                    <h6>Drop files here or click to upload.</h6>
                                  </div>
                                </div>
                              )}
                            </Dropzone>
                            <div
                              className="dropzone-previews mt-3"
                              id="file-previews"
                            >
                              {banner && Object.keys(banner).length > 0 && (
                                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                  <div className="p-2">
                                    <Row className="align-items-center">
                                      <Col className="col-auto">
                                        <ProfileImage
                                          image={banner}
                                          height="80"
                                          classNames="avatar-sm rounded bg-light"
                                        />
                                      </Col>
                                      <Col>
                                        <Link
                                          to="#"
                                          className="text-muted font-weight-bold"
                                        >
                                          {banner.name || banner.filename}
                                        </Link>
                                        <p className="mb-0">
                                          <strong>
                                            {banner.formattedSize}
                                          </strong>
                                        </p>
                                      </Col>
                                    </Row>
                                  </div>
                                </Card>
                              )}
                            </div>
                          </FormGroup>
                        </Col>

                        <Col md={6} className="px-5">
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="firstname-Input"
                              className="col-form-label"
                            >
                              Description
                            </Label>
                            <Col sm={12} className="custom_ck_editor">
                              <CKEditor
                                id="description"
                                editor={ClassicEditor}
                                data={selectedDesc}
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChange("description")(data);
                                  // setValues({ ...values, ["description"]: data });
                                }}
                              />
                            </Col>
                            <Label className="statusValidation">
                              {errors.description ? errors.description : ""}
                            </Label>
                          </FormGroup>

                          {/* Tips */}
                          <FormGroup className="mb-3">
                            <Label htmlFor="tips" className="col-form-label">
                              Tips
                            </Label>
                            <div className="custom_ck_editor">
                              <CKEditor
                                editor={ClassicEditor}
                                data={selectedTips}
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChange("tips")(data);
                                  // setValues({ ...values, ["tips"]: data });
                                }}
                              />
                            </div>
                            <Label className="statusValidation">
                              {errors.tips ? errors.tips : ""}
                            </Label>
                          </FormGroup>
                          {/* Multi Image dropdown here */}
                          <Row className="mb-3">
                            <Label htmlFor="images" className="col-form-label">
                              Images
                            </Label>
                            <Col sm={12}>
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleAcceptedFiles(acceptedFiles, "images");
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {images &&
                                  images.length > 0 &&
                                  images.map((img, index) => {
                                    return (
                                      <Card
                                        key={index}
                                        className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                      >
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={img}
                                                height="80"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {img.name || img.filename}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {img.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                        {/* <div> */}
                                        <i
                                          style={{
                                            position: "absolute",
                                            right: "3px",
                                            cursor: "pointer",
                                          }}
                                          className="mdi mdi-close-thick font-size-18 text-danger"
                                          id="removeImgtooltip"
                                          onClick={() => removeImage(index)}
                                        />
                                        {/* </div> */}
                                      </Card>
                                    );
                                  })}
                              </div>
                            </Col>
                          </Row>
                          <FormGroup className="mb-3">
                            <Row>
                              <Col md={6} className="">
                                <Button
                                  color="primary"
                                  className="w-md btn-sm me-4"
                                  onClick={() => {
                                    addNewAddon();
                                  }}
                                >
                                  Add New Addon
                                </Button>
                              </Col>
                              <Col md={6} className=""></Col>
                            </Row>
                            {addons.length > 0 &&
                              addons.map((e, i) => (
                                <Row className="mt-2" key={i}>
                                  <Col md={5} className="">
                                    <Input
                                      value={e.key}
                                      onChange={event => {
                                        handleAddOn(event, "key", i);
                                      }}
                                    />
                                  </Col>
                                  <Col md={6} className="">
                                    <Input
                                      value={e.value}
                                      onChange={event => {
                                        handleAddOn(event, "value", i);
                                      }}
                                    />
                                  </Col>
                                  <Col md={1}>
                                    <i
                                      style={{
                                        position: "absolute",
                                        right: "3px",
                                        cursor: "pointer",
                                      }}
                                      className="mdi mdi-close-thick font-size-18 text-danger"
                                      id="removeImgtooltip"
                                      onClick={() => removeAddon(i)}
                                    />
                                  </Col>
                                </Row>
                              ))}
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label className="col-form-label">Status</Label>
                            <Select
                              name="Status"
                              value={selectedStatus}
                              onChange={event => {
                                handleSelectStatus(event);
                                handleChange("status")(event.value);
                              }}
                              options={statusList}
                              classNamePrefix="select2-selection"
                            />
                            <Label className="statusValidation">
                              {" "}
                              {errors.status ? errors.status : ""}
                            </Label>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="tagline" className="col-form-label">
                              Tagline
                            </Label>
                            <Col sm={12} className="custom_ck_editor">
                              <CKEditor
                                editor={ClassicEditor}
                                data={selectedTagline}
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChange("tagline")(data);
                                  // setValues({ ...values, ["about"]: data });
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label htmlFor="benfits" className="col-form-label">
                              Benfits
                            </Label>
                            <Col sm={12} className="custom_ck_editor">
                              <CKEditor
                                editor={ClassicEditor}
                                data={selectedbenfits}
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChange("benfits")(data);
                                  // setValues({ ...values, ["about"]: data });
                                }}
                              />
                            </Col>
                          </FormGroup>
                          <FormGroup className="mb-3">
                            <Label
                              htmlFor="precautions"
                              className="col-form-label"
                            >
                              Precautions
                            </Label>
                            <Col sm={12} className="custom_ck_editor">
                              <CKEditor
                                editor={ClassicEditor}
                                data={selectedprecautions}
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChange("precautions")(data);
                                  // setValues({ ...values, ["about"]: data });
                                }}
                              />
                            </Col>
                          </FormGroup>
                        </Col>
                      </Row>
                      <hr />
                      <div className="d-flex justify-content-end">
                        {id ? (
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md btn-sm me-4"
                            // onClick={EditChanges}
                          >
                            Update Quick Service
                          </Button>
                        ) : (
                          <Button
                            type="submit"
                            color="primary"
                            className="w-md btn-sm me-4"
                            // onClick={saveChanges}
                          >
                            Save Quick Service
                          </Button>
                        )}

                        <Button
                          type="submit"
                          color="light"
                          className="w-md btn-sm"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateQuickService;
