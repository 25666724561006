import React from "react";
import { Container, Button, Card, Row, Col, CardBody } from "reactstrap";
import ListLabel from "./ListLabel";
import { getProperty } from "./ListProperties";
import moment from "moment";
import noImage from "assets/images/PicUpload.jpg";
const LeftLeftPanel = ({
  list,
  handleItemClick,
  propertiesName,
  handleSearch,
}) => {
  let obj = getProperty(propertiesName);
  const { name, createdAt, updatedAt, image, type } = obj;
  return (
    <div>
      <div className="search-box me-2 mb-2  ">
        <div className="position-relative">
          <i className="bx bx-search-alt search-icon" />
          <input
            className="form-control py-2 rounded-pill bg-white"
            type="search"
            id="example-search-input"
            placeholder=" Search for Brands"
            onChange={event => handleSearch(event.target.value)}
          />
        </div>
      </div>
      <Card>
        {list &&
          list.map((ele, index) => (
            <CardBody
              className="border border-bottom-1"
              role="button"
              key={ele._id}
              onClick={() => {
                handleItemClick(ele, index);
              }}
            >
              <Row>
                <Col md="2">
                  <div>
                    <img
                      src={ele[image] ? ele[image] : noImage}
                      height="50"
                      alt=""
                      className="fit-content rounded-circle avatar-sm "
                    />
                  </div>
                </Col>
                <Col md="8">
                  <div className="ms-2">
                    <h6>{ele[name]}</h6>

                    {createdAt && (
                      <small className="text-secondary">
                        Created :&nbsp;
                        {moment(ele[createdAt]).format("MMM Do YYYY")}
                        <br />
                      </small>
                    )}
                    {updatedAt && (
                      <small className="text-secondary">
                        Updated :&nbsp;
                        {moment(ele[updatedAt]).format("MMM Do YYYY")}
                      </small>
                    )}
                  </div>
                </Col>
                {type && <ListLabel type={ele[type]} />}
              </Row>
            </CardBody>
          ))}
      </Card>
    </div>
  );
};
export default LeftLeftPanel;
