import { GET_DATA,DATA_ERROR } from './actionTypes';

const initialState = {
    error: "",
    success:false
}

const Data = (state = initialState, action) => {
   
    switch (action.type) {
        case GET_DATA:
            console.log(action)
            state = { 
                success:action.payload?true:false,
                data:action.payload?action.payload:[]
             }
            break; 
        case DATA_ERROR:
            state = { ...state, error: action.payload };
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}

export default Data;