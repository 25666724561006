import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Row, Col, BreadcrumbItem } from "reactstrap";
import arrowIcon from "../../../src/assets/icons-image/right-arrow.png";
const BreadcrumbSub = props => {
  return (
    <Row>
      <Col xs="12">
        <div className="page-title-box d-flex align-items-center">
          <div className="page-title-right">
            <ol className="breadcrumb-sub m-0">
              <div className="arrow_icon me-2">
                <img src={arrowIcon} alt="" className="pb-2" />
              </div>
              <BreadcrumbItem>
                <Link to="#">{props?.subtile}</Link>
              </BreadcrumbItem>
              <BreadcrumbItem active>
                <Link to="#">{props?.SubBreadcrumbItem}</Link>
              </BreadcrumbItem>
            </ol>
          </div>
        </div>
      </Col>
    </Row>
  );
};

BreadcrumbSub.propTypes = {
  SubBreadcrumbItem: PropTypes.string,
  subtile: PropTypes.string,
};
export default BreadcrumbSub;
