export const getslides = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/slides/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Brands ${e}`));
};

export const getslide = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/slides/slide/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Brand ${e}`));
};

export const deleteSlide = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/slides/slide/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Brand ${e}`));
};

export const createSlide = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/slides/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Brand create ${e}`));
};

export const updateSlide = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/slides/slide/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Brand create ${e}`));
};
