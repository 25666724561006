import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Row,
  CardBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Select from "react-select";
import {
  getIndvidualById,
  updateIndvidualFields,
} from "services/indvidual.service";
import SweetAlert from "react-bootstrap-sweetalert";

const StatusBar = ({ toggle, modal, id, reload }) => {
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [statusInfo, setStatsInfo] = useState({
    status: "",
    comments: "",
  });
  const [loading, setLoading] = useState(true);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const statusType = [
    { label: "Verified", value: "Verified" },
    { label: "Rejected", value: "Rejected" },
    { label: "pending", value: "pending" },
  ];

  useEffect(() => {
    if (id) {
      getCurrentProvider();
    }
  }, [id]);

  const getCurrentProvider = () => {
    getIndvidualById(id)
      .then(res => {
        if (res.data) {
          let { status, comments } = res.data;
          setStatsInfo({ ...statusInfo, status, comments });
          setSelectedStatus(statusType?.find(item => item.value === status));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleSelectStatus = event => {
    setSelectedStatus(event);
  };

  const handleChange = value => {
    setStatsInfo({ ...statusInfo, comments: value });
  };

  const save = () => {
    let obj = {
      status: selectedStatus?.value,
      comments: statusInfo.comments,
    };
    handleSubmit(obj);
  };

  const handleSubmit = body => {
    setLoading(true);
    updateIndvidualFields(id, body)
      .then(res => {
        setsuccess_msg(true);
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Status Updated Successfully
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} size="md">
        <ModalHeader toggle={toggle}>Provider Status</ModalHeader>
        <ModalBody>
          <Row className="mt-1">
            <Col md={12}>
              <Select
                name="status"
                id="statusType"
                value={selectedStatus}
                onChange={handleSelectStatus}
                options={statusType}
                classNamePrefix={"select2-selection "}
              />
            </Col>
            <Col md={12} className="mt-2">
              <textarea
                name="comments"
                className="form-control"
                id="comments"
                placeholder="Comments"
                onChange={event => handleChange(event.target.value)}
                value={statusInfo.comments}
              ></textarea>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className="w-md btn-sm me-2"
            onClick={leaveToList}
          >
            Cancel
          </Button>
          <Button color="primary" className="w-md btn-sm me-2" onClick={save}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </>
  );
};

export default StatusBar;
