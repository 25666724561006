import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from "components/spinner";
import noImage from "assets/images/PicUpload.jpg";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getPaymentMethods } from "services/paymentMethods.service";
import Breadcrumbs from "components/Common/Breadcrumb";
import moment from "moment";
import { Link } from "react-router-dom";
import CreatePaymentMethod from "./process/CreatePaymentMethod";
import UpdatePaymentMethod from "./process/UpdatePaymentMethod";

const PaymentList = props => {
  const [list, setList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);

  useEffect(() => {
    getAllList();
  }, []);

  const toggle = () => {
    setModal(!modal);
  };

  const getAllList = async () => {
    setLoading(true);

    try {
      const { data, success } = await getPaymentMethods();

      if (success) {
        data.map(ele => {
          ele["showKey"] = false;
          ele["showSecret"] = false;
        });

        setList(data);
      } else {
        console.log("An Error has occured.");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const showKeys = i => {
    let obj = [...list];

    obj[i].showKey = !obj[i].showKey;

    setList([...obj]);
  };

  const showSecrets = i => {
    let obj = [...list];

    obj[i].showSecret = !obj[i].showSecret;

    setList([...obj]);
  };

  const removeProvider = id => {
    deleteArtist(id)
      .then(res => {
        setDelete_modal(false);
        getAllList();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onClickDelete = id => {
    setDeleteId(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeProvider(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Payment Methods | stylepro - -</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumbs title="Settings" breadcrumbItem="Payment Methods" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center"> </div>

            <div>
              <Button
                type="button"
                color="primary"
                className=""
                onClick={toggle}
              >
                <i className="bx bx-user-plus me-1" />
                Create Payment Method
              </Button>
            </div>
          </div>

          {list?.length === 0 ? (
            <Card className="mt-3">
              <CardBody>
                <div className="p-5 text-center">
                  <h5>No results found</h5>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card className="bg-light my-3">
              <CardBody className="">
                <Row className="services-card">
                  {list?.map((pm, index) => {
                    let FixedClass = "fw-bold ";
                    let statusClass =
                      pm?.status === "active"
                        ? FixedClass + "text-success"
                        : FixedClass + "text-danger";
                    return (
                      <>
                        <Col
                          sm={6}
                          md={6}
                          xl={6}
                          xxl={6}
                          key={index}
                          className="mb-1"
                        >
                          <Card className="">
                            <CardBody className="">
                              <UpdatePaymentMethod
                                initialValues={pm}
                                reload={getAllList}
                              />

                              <div className="d-flex">
                                <Row style={{ maxWidth: "100%" }}>
                                  <Col sm={5}>
                                    <div>
                                      <div>
                                        <img
                                          className=""
                                          src={noImage}
                                          alt=""
                                          width={100}
                                          height={100}
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm={7}>
                                    <div className="ms-4">
                                      <h6 className="fw-bold font-size-16 individual_name text-uppercase">
                                        {pm?.title}
                                      </h6>
                                      <h6 className="fw-bold">{pm?.name}</h6>
                                      <div className="mb-1">
                                        <span className="fw-bold">
                                          Status:{" "}
                                        </span>
                                        <span className={statusClass}>
                                          {pm?.status}
                                        </span>
                                      </div>
                                      <div className="mt-1">
                                        <span className="">
                                          Since:{" "}
                                          {pm?.createdAt &&
                                            moment(
                                              new Date(pm?.createdAt)
                                            ).format("DD MMM Y")}{" "}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col sm={12}>
                                    <Row className="ms-1">
                                      <Col sm={3} className="mb-1">
                                        <span
                                          className="fw-bold"
                                          style={{
                                            position: "absolute",
                                            paddingTop: "7px",
                                          }}
                                        >
                                          Key:
                                        </span>
                                      </Col>
                                      <Col sm={5}>
                                        <input
                                          className={`form-control form-input-hidden ${
                                            pm.showKey ? "overflow" : ""
                                          }`}
                                          type={
                                            pm.showKey ? "text" : "password"
                                          }
                                          value={pm?.key}
                                        />
                                      </Col>
                                      <Col sm={2}>
                                        <button
                                          className="btn btn-default hover"
                                          onClick={() => showKeys(index)}
                                        >
                                          {pm.show ? (
                                            <i className="fa fa-eye-slash" />
                                          ) : (
                                            <i className="fa fa-eye" />
                                          )}
                                        </button>
                                      </Col>
                                      <Col sm={1}>
                                        <button
                                          className="btn btn-default hover"
                                          onClick={() =>
                                            navigator.clipboard.writeText(
                                              pm?.key
                                            )
                                          }
                                        >
                                          <i className="bx bx-copy" />
                                        </button>
                                      </Col>
                                      <Col sm={3} className="mb-1">
                                        <span
                                          className="fw-bold"
                                          style={{
                                            position: "absolute",
                                            paddingTop: "7px",
                                          }}
                                        >
                                          Secret:
                                        </span>
                                      </Col>
                                      <Col sm={5}>
                                        <input
                                          className="form-control form-input-hidden"
                                          type={
                                            pm.showSecret ? "text" : "password"
                                          }
                                          value={pm?.secret}
                                        />
                                      </Col>
                                      <Col sm={2}>
                                        <button
                                          className="btn btn-default hover"
                                          onClick={() => showSecrets(index)}
                                        >
                                          {pm.show ? (
                                            <i className="fa fa-eye-slash" />
                                          ) : (
                                            <i className="fa fa-eye" />
                                          )}
                                        </button>
                                      </Col>
                                      <Col sm={1}>
                                        <button
                                          className="btn btn-default hover"
                                          onClick={() =>
                                            navigator.clipboard.writeText(
                                              pm?.secret
                                            )
                                          }
                                        >
                                          <i className="bx bx-copy" />
                                        </button>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </div>
                            </CardBody>
                          </Card>
                        </Col>
                      </>
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
      <CreatePaymentMethod toggle={toggle} modal={modal} reload={getAllList} />
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};

export default PaymentList;
