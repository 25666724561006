export const createPage=(body)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/page/create`,{
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        //   Authorization: `Bearer ${token}`
        },
        body: JSON.stringify(body)
      })
        .then(response => {
          return response.json();
        })
        .catch(err => console.log(err));
    };
    
    export const getPages=()=>{
        return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/page/pages`,{
            method:"GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: `Bearer ${token}`
              },
        }).then(res=>{
            return res.json()
        }).catch(e=>console.log(`error at page ${e}`))  
    }
    export const getPage=(id)=>{
        return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/page/page/${id}`,{
            method:"GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: `Bearer ${token}`
              },
        }).then(res=>{
            return res.json()
        }).catch(e=>console.log(`error at page ${e}`))  
    }
    
    export const updatepage=(id,body)=>{
        return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/page/update/${id}`,{
            method:"PUT",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: `Bearer ${token}`
              },
        body: JSON.stringify(body)
    
        }).then(res=>{
            return res.json()
        }).catch(e=>console.log(`error at page ${e}`))  
    }
    export const deletePage=(id)=>{
        return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/page/delete/${id}`,{
            method:"DELETE",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: `Bearer ${token}`
              },
        }).then(res=>{
            return res.json()
        }).catch(e=>console.log(`error at page ${e}`))  
    }
    
    
    
    