import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";


import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";
import { getBrand } from "services/brand.service";
import ProfileImage from './../../components/profileImage';
import Spinner from "components/spinner"



const defaultValues = {
  brandName: "",
  image: {},
  createdAt:null,
  updatedAt:null
};

const ViewBrand = props => {
  const { id } = useParams();
  const [values, setValues] = useState(defaultValues);
  const [loading,setLoading] = useState(true)


  let { image } = values;

  useEffect(() => {
    getBrand(id)
      .then(res => {
        let { image, brandName, createdAt,updatedAt } =
          res.data;

        let obj = { brandName, image,createdAt,updatedAt };
        setValues({ ...obj });
        setLoading(false)
      })
      .catch(err => {
        console.log(err);
        setLoading(false)
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | stylepro </title>
        </MetaTags>
       <Spinner loading={loading}/>
        <Container fluid>
          <Breadcrumb title="Brands" breadcrumbItem="Brand View" />
          <Card className="view-users">
            <CardBody>
              <div className="d-flex justify-content-between">
              <Link to="/brands">
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link>
                <Link className="text-end" to={`/brand-update/${id}`}>
                  <Badge
                    className="font-size-12 badge-soft-warning py-1 px-3"
                    color={"bg-warning"}
                    pill
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                </Link>
              </div>
              <Row>
                <Col md={4} className="">
                  <div className="text-center my-4">
                    {/* <img src={profileImg} alt="" className="img-thumbnail" /> */}
                    <ProfileImage
                      image={image}
                      classNames="img-thumbnail"
                    />
                    <h5 className="mt-4 text-muted">{values.brandName}</h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 view_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">Brand Name </span>{" "}
                      <span className="text-muted">{values.brandName} </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  {/* <div className=" mt-2 me-3 hr"> </div> */}
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">{values.createdAt ? values.createdAt: '_'}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">{values.updatedAt ? values.updatedAt : '_'}</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewBrand;
