import * as _ from "lodash";

function getVariantObj(variants, variantsList) {
  let existingValues;
  variantsList.forEach(ele => {
    if (_.isEqual(variants, ele.variants)) {
      existingValues = ele;
      return;
    }
  });
  if (existingValues) return existingValues;
  return {
    variants: variants,
    price: "",
    beforePrice: "",
    quantity: "",
    visible: "active",
  };
}

function createCombinationsForUpdate(
  fields,
  currentCombinations,
  variantsList,
  generate = false
) {
  //prevent side-effects
  let tempFields = fields.slice();
  console.log(tempFields, "tempfields");
  //recursively build a list combinations
  let delimiter = "|";
  if (!tempFields || tempFields.length == 0) {
    return currentCombinations;
  } else {
    let combinations = [];
    let field = tempFields.pop();
    for (let valueIndex = 0; valueIndex < field.length; valueIndex++) {
      let valueName = field[valueIndex]["name"];
      console.log(valueName, "val name");
      if (generate) {
        let combinationName = valueName;
        combinations = [...currentCombinations];
        combinations.push(getVariantObj([combinationName], variantsList));
      } else {
        if (!currentCombinations || currentCombinations.length == 0) {
          let combinationName = valueName;
          combinations = [...currentCombinations];
          combinations.push(getVariantObj([combinationName], variantsList));
        } else {
          for (
            let combinationIndex = 0;
            combinationIndex < currentCombinations.length;
            combinationIndex++
          ) {
            let currentCombination = currentCombinations[combinationIndex];
            let combinationName = [valueName, ...currentCombination?.variants];
            combinations[combinationIndex] = getVariantObj(
              combinationName,
              variantsList
            );
          }
        }
      }
    }
    console.log(
      tempFields,
      combinations,
      variantsList,
      "tempFields, combinations, variantsList"
    );
    return createCombinationsForUpdate(tempFields, combinations, variantsList);
  }
}

export default createCombinationsForUpdate;
