import StarRating from "components/Ratings/StarRating";
import React from "react";

const StylistRating = ({ value }) => {
  if (!value) {
    return "";
  }
  return (
    <div className=" ">
      <div className="">
        <div className="d-flex align-items-center">
          <StarRating totalStars={5} rating={value?.toFixed(1)} />
          <h6 className="fw-bolder ms-2 mb-0">{value?.toFixed(1)}</h6>
        </div>
      </div>
    </div>
  );
};

export default StylistRating;
