import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { getCouponUsedUsers } from "services/coupons.service";
import { useParams } from "react-router";
import moment from "moment";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";

const CouponsCount = () => {
  const { code } = useParams();
  const { SearchBar } = Search;

  const [users, setUsers] = useState([]);
  useEffect(() => {
    getUsersList(code);
  }, [code]);
  const getUsersList = code => {
    getCouponUsedUsers(code)
      .then(res => {
        setUsers(res?.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleRoute = serverData => {
    if (serverData?.orderId) {
      window.open("/orders?orderId=" + serverData.orderId?.orderId, "_blank");
    }
  };

  // Table Data
  const customer = () => [
    {
      dataField: "orderId.orderId",
      text: "OrderId",
      formatter: (cell, row) => (
        <>
          <div
            onClick={() => {
              handleRoute(row);
            }}
          >
            {row?.orderId?.orderId}
          </div>
        </>
      ),
    },
    {
      dataField: "userId.firstname",
      text: "First Name",
    },
    {
      dataField: "userId.lastname",
      text: "Last Name",
    },
    {
      dataField: "orderId.discount.discountAmount",
      text: "Discount Amount",
      formatter: (cell, row) => (
        <>{row?.orderId?.discount?.discountAmount?.toFixed(2)}</>
      ),
    },
    {
      dataField: "code",
      text: "Code",
    },
    {
      dataField: "useDate",
      text: "Use Date and Time",
      formatter: (cell, row) => (
        <>
          {moment(row?.useDate).format("DD-MMM-YYYY")} at {row?.useTime}
        </>
      ),
    },
  ];
  const pageOptions = {
    sizePerPage: 5,
    totalSize: users?.length, // replace later with size(customers),
    custom: true,
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Coupons</title>
        </MetaTags>
        <Container fluid>
          <div>
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="brandName"
                      columns={customer()}
                      data={users}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="brandName"
                          data={users}
                          columns={customer()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-flex align-items-center justify-content-between mb-2">
                                <div className="d-flex align-items-center">
                                  <div className="search-box d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="brandName"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    {users?.length == 0 && (
                      <p className="text-center"> No Data Found</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CouponsCount;
