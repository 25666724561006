export const createAnAuthor = body => {
  console.log(body);
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/author/dd`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      return res.json();
    })
    .catch(error => console.log(`error at Author create ${error}`));
};

export const getAllAuthors = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/author/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(error => console.log(`error at Getting Authors ${error}`));
};

export const UpdateAuthor = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/author/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(error => console.log(`error at  Updating Author ${error}`));
};
