import Spinner from "components/spinner";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { getAllContactUsUsers } from "services/ContactUs.service";
import { getAllArtistQuotes } from "services/quote.service";
// import "./Styles.scss";
import _ from "lodash";
const ContactUsUsersList = ({ handleActiveOrder }) => {
  const [allList, setAllList] = useState([]);
  const [List, setOrdersList] = useState([]);
  const [loading, setLoading] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const containerRef = useRef(null);
  const [canLoadMore, setCanLoadMore] = useState(true);
  useEffect(() => {
    debouncedFunction();
  }, [pageNumber]);

  const SortedArray = orders => {
    let sortedByDateList = orders?.sort(
      (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
    );
    setAllList(sortedByDateList);
  };

  const debouncedFunction = _.debounce(() => {
    if (loading || !canLoadMore) return;
    setLoading(true);
    setCanLoadMore(false);

    const limit = 10;
    const updatedURL = `/api/v1/contactUs/all?limit=${limit}&page=${pageNumber}`;

    getAllContactUsUsers(updatedURL)
      .then(res => {
        setLoading(false);
        const newData = res.data;
        if (newData?.length > 0) {
          const updatedList = [...allList, ...newData];
          SortedArray(updatedList);
          setOrdersList(updatedList);
          setPageNumber(prevPageNumber => prevPageNumber + 1);
        }
        setCanLoadMore(true);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
        setCanLoadMore(true);
      });
  }, 500);

  const handleScroll = () => {
    const container = containerRef.current;

    if (
      container.scrollHeight - container.scrollTop <=
        container.clientHeight + 1 &&
      !loading
    ) {
      debouncedFunction();
    }
  };

  const handleOrder = order => {
    handleActiveOrder(order);
  };
  const handleSearch = search => {
    if (search) {
      let tempList = _.cloneDeep(List);
      let list = tempList.filter(
        order =>
          //   order?.quoteid?.includes(search) ||
          order?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
          order?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
          order?.mobilenumber?.includes(search) ||
          order?.email?.includes(search.toLowerCase())
      );
      setAllList(list);
    } else {
      setAllList(List);
    }
  };
  const RenderOrderCards = orders => {
    if (orders?.length === 0) {
      return (
        <CardBody role="button" className="">
          Orders are empty
        </CardBody>
      );
    }
    return (
      <>
        {orders?.map((order, indx) => {
          return (
            <CardBody
              role="button"
              key={indx}
              className="border border-bottom-1"
              onClick={() => {
                handleOrder(order);
              }}
            >
              <div className="buttons">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <h6 className="font-size-18 fw-bold">{order?.quoteid}</h6>
                    <small className="float-end mt-1 text-secondary">
                      {moment(order?.createdAt).fromNow()}
                    </small>
                  </div>
                  <span className="text-secondary ">Submitted Date: </span>
                  <br />
                  <span className="fw-bold font-size-14">
                    {moment(order?.createdAt).format("DD-MMM-YYYY @ hh:mm a")}
                  </span>
                  <div className="mt-1">
                    <span className="text-secondary">Customer Name: </span>
                    <span className="fw-bold">
                      {order?.firstName ? order.firstName : ""}{" "}
                      {order?.lastName ? order.lastName : ""}
                    </span>
                  </div>
                  <div className="mt-1">
                    <span className="text-secondary">MobileNumber: </span>
                    <span className="fw-bold">
                      {order?.mobilenumber ? order?.mobilenumber : ""}{" "}
                    </span>
                  </div>
                  <div className="mt-1">
                    <span className="text-secondary">Submitted From: </span>
                    <span className="fw-bold">
                      {order?.deviceType ? order?.deviceType : ""}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          );
        })}
      </>
    );
  };
  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <Card className="">
        <CardBody className="p-2">
          <div className="search-box  position-relative w-100 ">
            <i className="bx bx-search-alt search-icon" />
            <input
              className="form-control  rounded-pill bg-white "
              type="search"
              id="example-search-input"
              placeholder=" Search..."
              onChange={event => handleSearch(event.target.value)}
            />
          </div>
        </CardBody>
        <CardBody className="p-0 artist_orders_list">
          <div className="tabs Orders_tabs">
            <div
              className="order-scroll"
              ref={containerRef}
              onScroll={handleScroll}
            >
              {RenderOrderCards(allList)}
            </div>
          </div>
        </CardBody>
        {/* <div className="text-center">
          <button onClick={handleLoadMore} className="btn btn-primary">
            Load More
          </button>
        </div> */}
      </Card>
    </React.Fragment>
  );
};

export default ContactUsUsersList;
