import Spinner from "components/spinner";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { getAllArtistQuotes } from "services/quote.service";
import "./Styles.scss";

const ArtistOrderlist = ({ handleActiveOrder }) => {
  const [allList, setAllList] = useState([]);
  const [List, setOrdersList] = useState([]);
  const [loading, setLoading] = useState(null);
  useEffect(() => {
    getAllArtistForms();
  }, []);
  const SortedArray = orders => {
    let sortedByDateList = orders?.sort(
      (a, b) => new Date(b?.createdAt) - new Date(a?.createdAt)
    );
    setAllList(sortedByDateList);
  };
  const getAllArtistForms = () => {
    setLoading(true);
    getAllArtistQuotes()
      .then(res => {
        if (res?.success === true) {
          SortedArray(res?.data);
          setOrdersList(res?.data);
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const handleOrder = order => {
    handleActiveOrder(order);
  };
  const handleSearch = search => {
    if (search) {
      let tempList = _.cloneDeep(List);
      let list = tempList.filter(
        order =>
          order?.quoteid?.includes(search) ||
          order?.userid?.firstname
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          order?.userid?.lastname
            ?.toLowerCase()
            .includes(search.toLowerCase()) ||
          order?.userid?.mobilenumber?.includes(search) ||
          order?.artistid?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setAllList(list);
    } else {
      setAllList(List);
    }
  };
  const RenderOrderCards = orders => {
    if (orders?.length === 0) {
      return (
        <CardBody role="button" className="">
          Orders are empty
        </CardBody>
      );
    }
    return (
      <>
        {orders?.map((order, indx) => {
          return (
            <CardBody
              role="button"
              key={indx}
              className="border border-bottom-1"
              onClick={() => {
                handleOrder(order);
              }}
            >
              <div className="buttons">
                <div className="">
                  <div className="d-flex justify-content-between">
                    <h6 className="font-size-18 fw-bold">#{order?.quoteid}</h6>
                    <small className="float-end mt-1 text-secondary">
                      {moment(order?.createdAt).fromNow()}
                    </small>
                  </div>
                  <span className="text-secondary ">Submitted Date: </span>
                  <br />
                  <span className="fw-bold font-size-14">
                    {moment(order?.createdAt).format("DD-MMM-YYYY @ hh:mm a")}
                  </span>
                  <div className="mt-1">
                    <span className="text-secondary">Client Name: </span>
                    <span className="fw-bold">
                      {order?.userid?.firstname ? order.userid.firstname : ""}{" "}
                      {order?.userid?.lastname ? order.userid.lastname : ""}
                    </span>
                  </div>
                  <div className="mt-1">
                    <span className="text-secondary">Makeup Artist: </span>
                    <span className="fw-bold">
                      {order?.artistid?.name ? order.artistid.name : ""}{" "}
                    </span>
                  </div>
                </div>
              </div>
            </CardBody>
          );
        })}
      </>
    );
  };
  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <Card className="">
        <CardBody className="p-2">
          <div className="search-box  position-relative w-100 ">
            <i className="bx bx-search-alt search-icon" />
            <input
              className="form-control  rounded-pill bg-white "
              type="search"
              id="example-search-input"
              placeholder=" Search..."
              onChange={event => handleSearch(event.target.value)}
            />
          </div>
        </CardBody>
        <CardBody className="p-0 artist_orders_list">
          <div className="tabs Orders_tabs">
            <div className="order-scroll">{RenderOrderCards(allList)}</div>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

export default ArtistOrderlist;
