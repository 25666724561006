import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import countryList from "react-select-country-list";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import "react-dual-listbox/lib/react-dual-listbox.css";
import { withRouter } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "./CreateProvider.styles.scss";
import Geocode from "react-geocode";
import ProfileImage from "components/profileImage";

import {
  createProvider,
  getProvideById,
  updateProvider,
} from "services/provider.service";
import { getProvidertypes } from "services/providertype.service";
import { getEmployees } from "services/user.service";
import {
  getServices,
  getServicesByCountry,
  getServicesByCountryType,
} from "services/services.service";
import { getQuickServicesByCountry } from "services/quickServices.service";

import SweetAlert from "react-bootstrap-sweetalert";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

const defaultValues = {
  legalname: "",
  shortname: "",
  gender: "",
  //   country:{},
  providertype: "",
  user: "",
  phonenumber: "",
  mobilenumber: "",
  tax: "",
  description: "",
  proofstype: "",
  experienceType: "",
  biography: "",
  accepted: "false",
  avaliable: "false",
  featured: "false",
  defaultLocation: {},
  image: {},
  certificates: {},
  idProofs: {},
  expertise: JSON.stringify([]),
  employees: JSON.stringify([]),
};

const CreateProvider = props => {
  Geocode.setApiKey("AIzaSyC-2_aBS0F76_gmbRzx3ToIEdzS9EEiC2Q");
  Geocode.setLanguage("en");
  Geocode.setRegion("ind");
  Geocode.setLocationType("ROOFTOP");

  const { id } = useParams();
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedGroup, setselectedGroup] = useState(null);
  const [selectedMulti, setselectedMulti] = useState(null);
  const [expertiseSelect, selectExper] = useState(null);
  const [employeeSelect, selectEmploye] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [toCreateEmp, setToCreateEmp] = useState(true);
  const [providerTypeList, setorders] = useState([]);
  const [idProofType, setidProof] = useState();
  const [experType, setexp] = useState();
  const [selectedType, setSelectedType] = useState(null);

  const [employeeList, setEmployeeList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [selectedDesc, setSelectedDesc] = useState("");
  const [selectedBio, setSelectedBio] = useState("");
  const [dataFetched, setDataFetched] = useState(false);
  const [adressPlace, setPlace] = useState("");
  const [locations, setLocations] = useState([
    {
      title: "Location 1",
      name: "",
      address: "",
      loc: {
        type: "Point",
        coordinates: [],
      },
      defaultstatus: "false",
    },
  ]);
  const options = useMemo(() => countryList().getData(), []);
  const [country, setCountry] = useState("");
  const [enableQuickService, setEnableQuickService] = useState(false);
  const [quickServiceList, setQuickServiceList] = useState([]);
  const [slectedQuickSer, setSelectedQuickSer] = useState(null);

  const getQuickServicesByCountryCode = country => {
    //QuickService List
    getQuickServicesByCountry(country)
      .then(res => {
        let list = [];
        res.data?.map(service => {
          list.push({ label: service.name, value: service._id });
        });
        if (list.length > 0) {
          list = [{ label: "Select All", value: "ALL" }, ...list];
        }
        setQuickServiceList(list);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const changeHandler = value => {
    getServicesByCountry(value.value)
      .then(res => {
        let list = [];
        res.data?.map(type => {
          list.push({ label: `${type.name}(${type.type})`, value: type._id });
        });
        if (list.length > 0) {
          list = [{ label: "Select All", value: "ALL" }, ...list];
        }
        setServiceList(list);
      })
      .catch(err => {
        console.log(err);
      });
    getQuickServicesByCountryCode(value.value);
    setCountry(value);
  };
  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
    // { label: "Unisex", value: "Unisex" }
  ];
  const certificatesType = [
    { label: "Experience", value: "Experience" },
    { label: "Fresher", value: "Fresher" },
  ];
  const proffsType = [
    { label: "Pancard", value: "Pancard" },
    { label: "Adhar Card", value: "Adhar Card" },
    { label: "Passport", value: "Passport" },
  ];
  const initialValues = defaultValues;
  const onSubmit = values => {
    console.log(values);
    if (id) {
      update(values);
    } else {
      createProviderForm(values);
    }
  };
  const handleAddLocation = () => {
    const exp = [...locations];
    exp.push({
      title: `Location ${exp.length + 1}`,
      name: "",
      address: "",
      loc: {
        type: "Point",
        coordinates: [],
      },
      defaultstatus: "false",
    });
    setLocations(exp);
  };
  const getLocation = index => {
    if (!navigator.geolocation) {
      //   console.log("Geolocation is not supported by your browser");
    } else {
      navigator.geolocation.getCurrentPosition(
        position => {
          Geocode.fromLatLng(
            position.coords.latitude,
            position.coords.longitude
          ).then(
            response => {
              const address = response.results[0].formatted_address;
              //   console.log(address);
              const locationData = [...locations];
              locationData[index].address = address;
              locationData[index]["loc"]["coordinates"] = [
                position.coords.longitude,
                position.coords.latitude,
              ];
              setValues({ ...values, ["locations"]: locationData });
            },
            error => {
              console.error(error);
            }
          );
        },
        () => {
          console.log("Unable to retrieve your location");
        }
      );
    }
  };

  const getlatitudeandlongtitude = (address, index) => {
    return Geocode.fromAddress(address).then(
      response => {
        const data = [...locations];
        const { lat, lng } = response.results[0].geometry.location;
        data[index].loc.coordinates = [lng, lat];
        setLocations(data);
        console.log(data);
        // setValues({ ...values, locations: data });
      },
      error => {
        console.error(error);
      }
    );
  };

  let response;
  const getAllEmployee = () => {
    getEmployees()
      .then(res => {
        const list = [];
        res?.map(type => {
          list.push({ label: type.firstname, value: type._id });
        });
        setEmployeeList(list);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getServicesList = () => {
    getServices()
      .then(res => {
        let list = [];
        res.data?.map(type => {
          list.push({ label: `${type.name}(${type.type})`, value: type._id });
        });
        if (list.length > 0) {
          list = [{ label: "Select All", value: "ALL" }, ...list];
        }
        setServiceList(list);
      })
      .catch(err => {
        console.log(err);
      });
  };

  // validation
  const getAllList = () => {
    getProvidertypes()
      .then(res => {
        const list = [];
        res.map(type => {
          list.push({ label: type.name, value: type._id });
        });
        setorders(list);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const [values, setValues] = useState(defaultValues);

  const validationSchema = Yup.object().shape({
    legalname: Yup.string().required("Please Enter Legal Name"),
    shortname: Yup.string().required("Please Enter Short Name"),
    providertype: Yup.string().required("Please Select Type"),
    gender: Yup.string().required("Please Select gender"),
    // country: Yup.string().required("Please Select country"),
    phonenumber: Yup.string().required("Please Enter Phone Number"),
    mobilenumber: Yup.string().required("Please Enter Mobile Number"),
    description: Yup.string().min(8).required("Please Enter Description"),
    tax: Yup.string().required("Please Enter Tax"),
    user: Yup.string().required("Please Enter user name"),
  });

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleSelectGroup(selectedMulti2) {
    setValues({ ...values, ["providertype"]: selectedMulti2.value });
    setselectedGroup(selectedMulti2);
    if (selectedMulti2.label == "Quick Provider") {
      setEnableQuickService(true);
      setValues({ ...values, ["expertise"]: [] });
      selectExper(null);
    } else {
      setEnableQuickService(false);
      setValues({ ...values, ["quickService"]: [] });
      setSelectedQuickSer(null);
    }
  }

  function handleMulti(selectedMulti) {
    setValues({ ...values, ["user"]: selectedMulti.value });
    setselectedMulti(selectedMulti);
  }

  function handleQuickServices(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["quickService"]: data });
    setSelectedQuickSer(selectedMulti);
  }
  function handleExpertise(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["expertise"]: data });
    selectExper(selectedMulti);
  }
  function handleEmployee(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["employees"]: data });
    selectEmploye(selectedMulti);
  }
  const handleChangeAdress = (name, index, event) => {
    console.log(event);
    const locationData = [...locations];
    locationData[index][name] = event;
    geocodeByAddress(event)
      .then(results => getLatLng(results[0]))
      .then(latLng => {
        const locationlatlag = [...locations];
        locationlatlag[index]["loc"]["coordinates"] = [latLng.lng, latLng.lat];
        setLocations(locationlatlag);
        console.log(locationlatlag);
      })
      .catch(error => console.error("Error", error));
    setLocations(locationData);
  };

  function handleSelectType(event) {
    setListByType(country.value, event.value);
    setSelectedType(event);
  }
  const setListByType = (country, type) => {
    getServicesByCountryType(country, type)
      .then(res => {
        let list = [];
        res.data?.map(type => {
          list.push({ label: `${type.name}(${type.type})`, value: type._id });
        });
        if (list.length > 0) {
          list = [{ label: "Select All", value: "ALL" }, ...list];
        }
        setServiceList(list);
      })
      .catch(e => console.log(e));
  };
  const createProviderForm = values => {
    let formData = new FormData();
    if (enableQuickService) {
      values["expertise"] = [];
    } else {
      values["quickService"] = [];
    }
    for (let key in values) {
      formData.set(key, values[key]);
    }
    formData.set("locations", JSON.stringify(locations));
    formData.set("country", JSON.stringify(country));

    formData.set(
      "defaultLocation",
      JSON.stringify(
        locations[locations.findIndex(loc => loc.defaultstatus === "true")].loc
      )
    );
    setTimeout(() => {
      createProvider(formData)
        .then(res => {
          response = res;
          setsuccess_msg(true);
        })
        .catch(e => {
          console.log(e);
          seterror_msg(true);
        });
    }, 1000);
  };

  const update = values => {
    let formData = new FormData();

    if (enableQuickService) {
      values["expertise"] = [];
    } else {
      values["quickService"] = [];
    }
    let updatedata = { ...values };
    for (let key in updatedata) {
      formData.set(
        key,
        Array.isArray(updatedata[key])
          ? JSON.stringify(updatedata[key])
          : updatedata[key]
      );
    }
    formData.set("locations", JSON.stringify(locations));
    formData.set("country", JSON.stringify(country));

    console.log(locations);
    formData.set(
      "defaultLocation",
      JSON.stringify(
        locations[locations.findIndex(loc => loc.defaultstatus === "true")].loc
      )
    );
    setTimeout(() => {
      updateProvider(id, formData)
        .then(res => {
          setsuccess_msg(true);
        })
        .catch(e => {
          console.log(e);
          seterror_msg(true);
        });
    }, 1000);
  };
  const leaveToList = () => {
    props.history.push("/provider-list");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
    props.history.push("/provider-list");
  };
  const handellocation = (name, index, event) => {
    const locationData = [...locations];
    locationData[index][name] = event.target.value;
    if (name === "address") {
      getlatitudeandlongtitude(event.target.value, index).then(res => res);
    }
    setLocations(locationData);
  };
  const handelLocCheckbox = (index, event) => {
    const locationData = [...locations];
    locationData[index]["defaultstatus"] = event.target.checked
      ? "true"
      : "false";
    setLocations(locationData);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Category created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const checkedCheckBox = (location, index) => {
    if (dataFetched) {
      return (
        <Input
          key={index}
          type="checkbox"
          className="form-check-Input"
          id={`${location.name} checkbox`}
          value={location.defaultstatus === "true" ? true : false}
          defaultChecked={location.defaultstatus === "true" ? true : false}
          onChange={event => handelLocCheckbox(index, event)}
        />
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Layouts | stylepro - -</title>
        </MetaTags>
        <Container fluid={false}>
          <Breadcrumb
            title="Providers Management"
            breadcrumbItem="Create Provider"
          />
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  values,
                }) => {
                  useEffect(() => {
                    getAllList();
                    // getAllProviders();
                    getServicesList();
                    getAllEmployee();
                    if (id) {
                      setDataFetched(false);
                      getProvideById(id)
                        .then(res => {
                          setDataFetched(false);
                          const data = { ...res.data };
                          const fields = [
                            "legalname",
                            "shortname",
                            "user",
                            "phonenumber",
                            "mobilenumber",
                            "tax",
                            "gender",
                            "proofstype",
                            // "country",
                            "experienceType",
                            "description",
                            "biography",
                            "accepted",
                            "avaliable",
                            "featured",
                            "image",
                            "certificates",
                            "idProofs",
                          ];
                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          setCountry(res.data.country);
                          setListByType(
                            res.data.country.value,
                            res.data.gender
                          );
                          setLocations(data.locations);
                          setSelectedType({
                            label: data.gender,
                            value: data.gender,
                          });
                          let expertiseData = [];
                          res.data.expertise.map(e => {
                            const data = [];
                            data.push(e._id);
                            expertiseData.push({ label: e.name, value: e._id });
                            setFieldValue("expertise", data);
                          });
                          selectExper(expertiseData);
                          let quickSerData = [];
                          res.data?.quickService?.map(e => {
                            const data = [];
                            data.push(e._id);
                            quickSerData.push({ label: e.name, value: e._id });
                            setFieldValue("quickService", data);
                          });
                          setSelectedQuickSer(quickSerData);
                          setexp({
                            label: data.experienceType,
                            value: data.experienceType,
                          });
                          setidProof({
                            label: data.proofstype,
                            value: data.proofstype,
                          });
                          let employeeData = [];
                          res.data.employees.map(e => {
                            const data = [];
                            data.push(e._id);
                            employeeData.push({
                              label: e.firstname,
                              value: e._id,
                            });
                            setFieldValue("employees", data);
                          });
                          selectEmploye(employeeData);
                          setselectedMulti({
                            label: res.data.user.firstname,
                            value: res.data.user._id,
                          });
                          //   setFieldValue("user", res.data.user._id);
                          if (res.data.providertype.name == "Quick Provider") {
                            getQuickServicesByCountryCode(
                              res.data.country.value
                            );
                            setEnableQuickService(true);
                          }
                          setselectedGroup({
                            label: res.data.providertype.name,
                            value: res.data.providertype._id,
                          });
                          setFieldValue(
                            "providertype",
                            res.data.providertype._id
                          );

                          setSelectedBio(res.data.biography);
                          setSelectedDesc(res.data.description);
                          setDataFetched(true);
                        })
                        .catch(e => {
                          setDataFetched(true);
                          console.log(e);
                        });
                    } else {
                      setDataFetched(true);
                    }
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-4">
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Legal Name
                              </Label>
                              <Input
                                name="legalname"
                                placeholder="Legalname"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.legalname}
                                invalid={
                                  touched.legalname && errors.legalname
                                    ? true
                                    : false
                                }
                              />
                              {touched.legalname && errors.legalname ? (
                                <FormFeedback type="invalid">
                                  {errors.legalname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Short Name
                              </Label>
                              <Input
                                name="shortname"
                                placeholder="shortname"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={handleChange}
                                value={values.shortname}
                                invalid={
                                  touched.shortname && errors.shortname
                                    ? true
                                    : false
                                }
                              />
                              {touched.shortname && errors.shortname ? (
                                <FormFeedback type="invalid">
                                  {errors.shortname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>

                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">Country</Label>
                              <Select
                                name="country"
                                id="country"
                                options={options}
                                value={country}
                                onChange={event => {
                                  changeHandler(event);
                                }}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">Type</Label>
                              <Select
                                name="gender"
                                id="gender"
                                value={selectedType}
                                onChange={event => {
                                  handleSelectType(event);
                                  handleChange("gender")(event.value);
                                }}
                                options={typeList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.type ? errors.type : ""}
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Provider Type
                              </Label>
                              <Select
                                // className="form-control"
                                id="validationCustom03"
                                value={selectedGroup}
                                onChange={event => {
                                  handleSelectGroup(event);
                                  handleChange("providertype")(event.value);
                                }}
                                options={providerTypeList}
                                classNamePrefix="select2-selection"
                                name="providertype"
                              />
                              {touched.providertype && errors.providertype ? (
                                <FormFeedback type="invalid">
                                  {errors.providertype}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom04">Owner</Label>
                              <Input
                                name="user"
                                placeholder="Owner Name"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={handleChange}
                                value={values.user}
                                invalid={
                                  touched.user && errors.user ? true : false
                                }
                              />
                              {touched.user && errors.user ? (
                                <FormFeedback type="invalid">
                                  {errors.user}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">Image</Label>
                              <Dropzone
                                id="validationCustom05"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("image", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.image &&
                                  Object.keys(values.image).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.image}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.image.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.image.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">
                                Description
                              </Label>
                              <div className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  id="validationCustom06"
                                  data={selectedDesc}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // editor.setData(values.description);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("description")(data);
                                  }}
                                />
                              </div>
                              <Label className="statusValidation">
                                {" "}
                                {errors.description ? errors.description : ""}
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Certificates Type
                              </Label>
                              <Select
                                // className="form-control"
                                id="validationCustom03"
                                value={experType}
                                onChange={event => {
                                  setexp(event);
                                  handleChange("experienceType")(event.value);
                                }}
                                options={certificatesType}
                                classNamePrefix="select2-selection"
                                name="providertype"
                              />
                              {touched.providertype && errors.providertype ? (
                                <FormFeedback type="invalid">
                                  {errors.providertype}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom07">
                                Experience Certificates
                              </Label>
                              <Dropzone
                                id="validationCustom07"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue(
                                    "certificates",
                                    acceptedFiles[0]
                                  );
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.certificates &&
                                  Object.keys(values.certificates).length >
                                    0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.certificates}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.certificates.name
                                                ? values.certificates.name
                                                : values.certificates.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {
                                                  values.certificates
                                                    .formattedSize
                                                }
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom03">
                                Proofs Type
                              </Label>
                              <Select
                                // className="form-control"
                                id="validationCustom03"
                                value={idProofType}
                                onChange={event => {
                                  setidProof(event);
                                  handleChange("proofstype")(event.value);
                                }}
                                options={proffsType}
                                classNamePrefix="select2-selection"
                                name="proofstype"
                              />
                              {touched.providertype && errors.providertype ? (
                                <FormFeedback type="invalid">
                                  {errors.providertype}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom08">
                                {" "}
                                ID Proofs
                              </Label>
                              <Dropzone
                                id="validationCustom08"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("idProofs", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.idProofs &&
                                  Object.keys(values.idProofs).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.idProofs}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.idProofs.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.idProofs.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom1">
                                Mobile Number
                              </Label>
                              <Input
                                name="mobilenumber"
                                placeholder="Mobile Number"
                                type="text"
                                className="form-control"
                                id="validationCustom1"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.mobilenumber}
                                invalid={
                                  touched.mobilenumber && errors.mobilenumber
                                    ? true
                                    : false
                                }
                              />

                              {touched.mobilenumber && errors.mobilenumber ? (
                                <FormFeedback type="invalid">
                                  {errors.mobilenumber}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom2">
                                Phone Number
                              </Label>
                              <Input
                                name="phonenumber"
                                placeholder="Phone Number"
                                type="text"
                                className="form-control"
                                id="validationCustom2"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.phonenumber}
                                invalid={
                                  touched.phonenumber && errors.phonenumber
                                    ? true
                                    : false
                                }
                              />

                              {touched.phonenumber && errors.phonenumber ? (
                                <FormFeedback type="invalid">
                                  {errors.phonenumber}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom2">
                                Locations :
                              </Label>
                              <Row>
                                <Col md={6} className="">
                                  <Button
                                    color="primary"
                                    className="w-md btn-sm me-4"
                                    onClick={() => {
                                      handleAddLocation();
                                    }}
                                  >
                                    Add Location
                                  </Button>
                                </Col>
                                <Col md={6} className=""></Col>
                              </Row>
                              {locations.map((location, index) => (
                                <div key={location.title}>
                                  <label className="col-form-label">
                                    {location.title}
                                  </label>
                                  <Input
                                    type="text"
                                    className="mb-4"
                                    placeholder="Enter Your location Name"
                                    onChange={event =>
                                      handellocation("name", index, event)
                                    }
                                    value={locations[index].name}
                                  />
                                  <div className="mb-4">
                                    {/* <Input
                                      type="textarea"
                                      rows="5"
                                      value={locations[index].address}
                                      onChange={event =>
                                        handellocation("address", index, event)
                                      }
                                    /> */}
                                    <PlacesAutocomplete
                                      value={locations[index].address}
                                      onChange={event =>
                                        handleChangeAdress(
                                          "address",
                                          index,
                                          event
                                        )
                                      }
                                      onSelect={event =>
                                        handleChangeAdress(
                                          "address",
                                          index,
                                          event
                                        )
                                      }
                                    >
                                      {({
                                        getInputProps,
                                        suggestions,
                                        getSuggestionItemProps,
                                        loading,
                                      }) => (
                                        <div>
                                          <Input
                                            {...getInputProps({
                                              placeholder: "Search Places ...",
                                              className:
                                                "location-search-input",
                                            })}
                                          />
                                          <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                              const className =
                                                suggestion.active
                                                  ? "suggestion-item--active"
                                                  : "suggestion-item";
                                              // inline style for demonstration purpose
                                              const style = suggestion.active
                                                ? {
                                                    backgroundColor: "#fafafa",
                                                    cursor: "pointer",
                                                  }
                                                : {
                                                    backgroundColor: "#ffffff",
                                                    cursor: "pointer",
                                                  };
                                              return (
                                                <div
                                                  key={suggestion}
                                                  {...getSuggestionItemProps(
                                                    suggestion,
                                                    {
                                                      className,
                                                      style,
                                                    }
                                                  )}
                                                >
                                                  <span>
                                                    {suggestion.description}
                                                  </span>
                                                </div>
                                              );
                                            })}
                                          </div>
                                        </div>
                                      )}
                                    </PlacesAutocomplete>
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <div className="form-check">
                                      <Label
                                        className="form-check-Label fw-bold mb-0"
                                        htmlFor="default"
                                      >
                                        Default Location
                                      </Label>
                                      {dataFetched &&
                                        checkedCheckBox(location, index)}
                                    </div>
                                    <div>
                                      <Button
                                        color="primary"
                                        className="w-md btn-sm"
                                        onClick={() => getLocation(index)}
                                      >
                                        Get Current location
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom4">Taxes</Label>
                              <Input
                                name="tax"
                                placeholder="taxes"
                                type="text"
                                className="form-control"
                                id="validationCustom4"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.tax}
                                invalid={
                                  touched.tax && errors.tax ? true : false
                                }
                              />

                              {touched.tax && errors.tax ? (
                                <FormFeedback type="invalid">
                                  {errors.tax}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          {!enableQuickService && (
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom5">
                                  Expertise
                                </Label>
                                <Select
                                  id="validationCustom5"
                                  value={expertiseSelect}
                                  isMulti={true}
                                  onChange={event => {
                                    let selectedList = event;
                                    let isALL = event.find(
                                      option => option.value === "ALL"
                                    );
                                    if (isALL) {
                                      selectedList = serviceList.slice(1);
                                    }
                                    handleExpertise(selectedList);
                                    const data = [];
                                    selectedList.map(e => {
                                      data.push(e.value);
                                    });
                                    handleChange("expertise")(
                                      JSON.stringify(data)
                                    );
                                  }}
                                  options={serviceList}
                                  classNamePrefix="select2-selection"
                                />
                              </FormGroup>
                            </Row>
                          )}
                          {enableQuickService && (
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="quickService">
                                  Quick Service
                                </Label>
                                <Select
                                  id="quickService"
                                  value={slectedQuickSer}
                                  isMulti={true}
                                  onChange={event => {
                                    let selectedList = event;
                                    let isALL = event.find(
                                      option => option.value === "ALL"
                                    );
                                    if (isALL) {
                                      selectedList = quickServiceList.slice(1);
                                    }
                                    handleQuickServices(selectedList);
                                    const data = [];
                                    selectedList.map(e => {
                                      data.push(e.value);
                                    });
                                    handleChange("quickService")(
                                      JSON.stringify(data)
                                    );
                                  }}
                                  options={quickServiceList}
                                  classNamePrefix="select2-selection"
                                />
                              </FormGroup>
                            </Row>
                          )}

                          {selectedGroup?.label !== "Freelancer" && (
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom6">
                                  Employees
                                </Label>
                                <Select
                                  id="validationCustom6"
                                  value={employeeSelect}
                                  isMulti={true}
                                  onChange={event => {
                                    handleEmployee(event);
                                    const data = [];
                                    event.map(e => {
                                      data.push(e.value);
                                    });
                                    handleChange("employees")(
                                      JSON.stringify(data)
                                    );
                                  }}
                                  options={employeeList}
                                  classNamePrefix="select2-selection"
                                />
                              </FormGroup>
                            </Row>
                          )}
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom7">
                                Biography
                              </Label>
                              <div className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={selectedBio}
                                  onReady={editor => {}}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("biography")(data);
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <div className="form-check me-4 my-auto">
                          <Label
                            className="form-check-Label fw-bold mb-0"
                            htmlFor="accepted"
                          >
                            Accepted
                          </Label>
                          {dataFetched && (
                            <Input
                              key={"accepted" + "checkbox"}
                              type="checkbox"
                              className="form-check-Input"
                              id={"accepted checkbox"}
                              value={values.accepted === "true" ? true : false}
                              defaultChecked={
                                values.accepted === "true" ? true : false
                              }
                              onChange={event => {
                                handleChange("accepted")(
                                  event.target.checked ? "true" : "false"
                                );
                              }}
                            />
                          )}
                        </div>
                        <div className="form-check me-4 my-auto">
                          <Label
                            className="form-check-Label fw-bold mb-0"
                            htmlFor="avaliable"
                          >
                            Available
                          </Label>
                          {dataFetched && (
                            <Input
                              key={"avaliable" + "checkbox"}
                              type="checkbox"
                              className="form-check-Input"
                              id={"avaliable checkbox"}
                              value={values.avaliable === "true" ? true : false}
                              defaultChecked={
                                values.avaliable === "true" ? true : false
                              }
                              onChange={event => {
                                handleChange("avaliable")(
                                  event.target.checked ? "true" : "false"
                                );
                              }}
                            />
                          )}
                        </div>
                        <div className="form-check me-4 my-auto">
                          <Label
                            className="form-check-Label fw-bold mb-0"
                            htmlFor="featured"
                          >
                            Featured
                          </Label>
                          {dataFetched && (
                            <Input
                              key={"featured" + "checkbox"}
                              type="checkbox"
                              className="form-check-Input"
                              id={"featured checkbox"}
                              value={values.featured === "true" ? true : false}
                              defaultChecked={
                                values.featured === "true" ? true : false
                              }
                              onChange={event => {
                                handleChange("featured")(
                                  event.target.checked ? "true" : "false"
                                );
                              }}
                            />
                          )}
                        </div>
                        {!id ? (
                          <Button
                            color="primary"
                            type="submit"
                            className="w-md btn-sm me-4"
                            // onClick={saveChanges}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="w-md btn-sm me-4"
                            type="submit"
                            // onClick={editChanges}
                          >
                            Save Changes
                          </Button>
                        )}
                        <Button
                          color="light"
                          className="w-md btn-sm"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(CreateProvider);
