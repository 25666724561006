export const getAllContactUsUsers = url => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}${url}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      console.log(res, "res");
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists ${e}`));
};
