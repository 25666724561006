import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button } from "reactstrap";
import * as _ from "lodash";

import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import { updatefilefeildService } from "services/services.service";

const BannerInfoModal = ({ toggle, initValues, reloadService }) => {
  let initBannerVal = initValues?.banner ? _.cloneDeep(initValues?.banner) : "";
  const [values, setValues] = useState({
    banner: initBannerVal,
  });

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  let { banner } = values;

  const onSubmit = () => {
    let formData = new FormData();

    formData.set("banner", values.banner);

    // let body = [...values];
    updatefilefeildService(initValues._id, formData)
      .then(res => {
        console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => {
        console.log(e);
      });
  };
  return (
    <>
      <Row>
        <Label htmlFor="firstname-Input" className="col-form-label">
          Banner
        </Label>
        <Col md={6} className="mt-1">
          <Dropzone
            onDrop={acceptedFiles => {
              // handleAcceptedFiles(acceptedFiles, "banner");
              acceptedFiles.map(file =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                  formattedSize: formatBytes(file.size),
                })
              );
              setValues({
                ...values,
                banner: acceptedFiles[0],
              });
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone image_drop">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="">
                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h6>Drop files here or click to upload.</h6>
                </div>
              </div>
            )}
          </Dropzone>
        </Col>
        <Col md={6}>
          <div className="dropzone-previews" id="file-previews">
            {banner && (
              <div className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                <div className="p-2">
                  <Row className="align-items-center">
                    <Col className="col-auto">
                      <ProfileImage
                        image={banner}
                        height="80"
                        classNames="avatar-sm rounded bg-light"
                      />
                    </Col>
                    <Col>
                      <Link to="#" className="text-muted font-weight-bold">
                        {banner.name || banner.filename}
                      </Link>
                      <p className="mb-0">
                        <strong>{banner.formattedSize}</strong>
                      </p>
                    </Col>
                  </Row>
                </div>
              </div>
            )}
          </div>
        </Col>
      </Row>
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Save
        </Button>
      </div>
    </>
  );
};
export default BannerInfoModal;
