import React, { useState, useEffect } from "react";
import { Card, CardBody, Table } from "reactstrap";
import _ from "lodash";
import { getCategories } from "services/category.service";

const MyServiceTable = ({ serviceList, expInitialValues }) => {
  useEffect(() => {}, [serviceList, expInitialValues]);

  const sortedArray = expInitialValues?.service?.sort(
    (a, b) => a?.id?.categories - b?.id?.categories
  );
  const RenderSelectedList = () => {
    return (
      <div>
        <div className="d-flex justify-content-between mb-2">
          <h5 className="text-primary mt-3">BASIC SERVICE COST (Selected)</h5>
        </div>

        <Card className="mb-4 p-3 pb-0">
          <div>
            <h5 className="text-primary">Women - My Services Menu</h5>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>Service Id</th>
                  <th>Service Category</th>
                  <th>Service Name</th>
                  <th>PriceType</th>
                  <th>Price</th>
                  <th>currency</th>
                </tr>
              </thead>
              <tbody>
                {sortedArray &&
                  sortedArray?.map((service, index) => {
                    if (service?.id?.type === "Female")
                      return (
                        <tr key={index}>
                          <th scope="row">
                            {service?.serviceId || service?.id?.serviceId}
                          </th>
                          <td>
                            {service?.id?.categories?.name ||
                              service?.id?.categories}
                          </td>
                          <td>{service?.name}</td>
                          <td>{service?.priceType || "_ "}</td>
                          <td>{service?.price || " _"}</td>
                          <td>{service?.currency || "_ "}</td>
                        </tr>
                      );
                  })}
              </tbody>
            </Table>
          </div>
        </Card>
        <Card className="mb-4 p-3 pb-0">
          <div>
            <h5 className="text-primary">Men - My Services Menu</h5>
            <hr />
            <Table>
              <thead>
                <tr>
                  <th>Service Id</th>
                  <th>Service Category</th>
                  <th>Service Name</th>
                  <th>PriceType</th>
                  <th>Price</th>
                  <th>currency</th>
                </tr>
              </thead>
              <tbody>
                {sortedArray &&
                  sortedArray?.map((service, index) => {
                    if (service?.id?.type === "Male") {
                      return (
                        <tr key={index}>
                          <th scope="row">
                            {service?.serviceId || service?.id?.serviceId}
                          </th>
                          <td>
                            {service?.id?.categories?.name ||
                              service?.id?.categories}
                          </td>
                          <td>{service?.name}</td>
                          <td>{service?.priceType || "-"}</td>
                          <td>{service?.price || "-"}</td>
                          <td>{service?.currency || "-"}</td>
                        </tr>
                      );
                    }
                    // else {
                    //   return (
                    //     <tr key={index}>
                    //       <tr>
                    //         <td>No Data Found</td>
                    //       </tr>
                    //     </tr>
                    //   );
                    // }
                  })}
                {!expInitialValues?.service ||
                  (expInitialValues?.service === 0 && (
                    <tr>
                      <td>No Data Found</td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Card>
      </div>
    );
  };

  return <>{RenderSelectedList()}</>;
};
export default MyServiceTable;
