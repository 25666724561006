import React, { useState, useEffect } from "react";

import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import "./UserView.styles.scss";
import Select from "react-select";

const Expertise = props => {
    const {data}=props
  const [expertiseList, setExpertiseList] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  useEffect(() => {
   
    setExpertiseList(data);
  }, [data]);
  

  const handleMulti = event => {
    setExpertiseList(event);
  };

  const handleUpdate = () => {
    setShowEdit(true);
  };

  const saveChanges = () => {
    setShowEdit(false);
  };

  const cancelChanges = () => {
    setShowEdit(false);
  };

  let ViewExpertise = () => {
    return (
      <React.Fragment>
        <div className="text-end">
          {/* <Button
            className="btn-sm px-4"
            color="primary"
            onClick={() => handleUpdate()}
          >
            Update
          </Button> */}
        </div>
        <div className="d-flex flex-wrap mt-3">
          {expertiseList?.map((exp, i) => {
            return (
              <Badge
                key={i}
                className="font-size-12 badge-soft-primary px-4 py-2 me-3"
                color={"bg-primary"}
                pill
              >
                {exp.name}
              </Badge>
            );
          })}
        </div>
      </React.Fragment>
    );
  };


  return (
    <React.Fragment>
     { ViewExpertise()}
    </React.Fragment>
  );
};

export default Expertise;
