import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

import {getProvidertypes,deleteProvidertypes} from "services/providertype.service"
//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";

import { getOrders as onGetOrders } from "store/actions";

const ProviderType = props => {
  const dispatch = useDispatch();
  const [orders,setorders] = useState([])
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
useEffect(()=>{
    getAllList()
},[])
  // validation
const getAllList=()=>{
    getProvidertypes().then(res=>{
        setorders(res)
        console.log(res)
    }).catch(err=>{
        console.log(err)
    })
}
const onClickDelete=(id)=>{
    setDeleteid(id)
    setDelete_modal(true);
}

const handleOnDelete = () => {
    removeProviderType(deleteId)
  };

  const removeProviderType = (id) => {
    deleteProvidertypes(id)
      .then(res => {
        console.log(res);
        setDelete_modal(false);
        getAllList()
      })
      .catch(err => {
        console.log(err);
      });
  };
  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
 const pageOptions = {
    sizePerPage: 5,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  };
  const { SearchBar } = Search;
  const badgeColor = "font-size-12 badge-soft-";

  const ProvidersColumn = () => [
    // {
    //   dataField: "id",
    //   text: "S.No",
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, row) => (
    //     <Link to="#" className="text-body fw-bold">
    //       {row.id}
    //     </Link>
    //   ),
    // },
    {
      dataField: "name",
      text: "Name",
    },
    {
        dataField: "commission",
        text: "Commission",
      },
    // {
    //   dataField: "email",
    //   text: "Email",
    // },
    {
      dataField: "status",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={
            row.status === "inactive"
              ? "bg-danger"
              : "bg-success"
          }
          color={"bg-secondary"}
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updatedAt),
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, order) => (
        <>
          <div className="d-flex gap-3">
            <Link
              to={`/update-type/${order._id}`}
              className="text-success"
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(order._id)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (orders && !orders.length) {
      dispatch(onGetOrders());
    }
  }, [dispatch, orders]);

  const handleOrderClicks = () => {};

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Providers" breadcrumbItem="Provider Type" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProvidersColumn()}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={ProvidersColumn()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Link
                                    to="/create-type"
                                    className="has-arrow "
                                  >
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleOrderClicks}
                                    >
                                      <i className="bx bx-user-plus me-1" />
                                      Create Provider type
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {delete_modal ? deletePopup() : null}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ProviderType.propTypes = {
  orders: PropTypes.array,
  onGetOrders: PropTypes.func,
  onAddNewOrder: PropTypes.func,
  onUpdateOrder: PropTypes.func,
};

export default withRouter(ProviderType);
