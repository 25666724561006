import React, { useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import noImage from "assets/images/PicUpload.jpg";
import moment from "moment";
import UpdateExceptionStatus from "./UpdateExceptionStatus";

const ExceptionsView = ({ modal, toggle, activeObj, reload,setModal }) => {
  const badgeColor = "font-size-12 px-3 py-1 badge-soft-";
  const [updateModal, setUpdatemodal] = useState(false);
  const [Notes, setNotes] = useState([]);

  const UpdateToggle = () => {
    setUpdatemodal(!updateModal);
  };
  useEffect(() => {
    if (activeObj) {
      setNotes(activeObj?.reason || []);
    }
  }, [activeObj]);
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>Exception Details</ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <Row className="m-0">
              <Col md={12} className="p-0">
                <Card>
                  <CardBody>
                    <Row className="m-0">
                      <Col md={12} className="mb-1 px-0 view_detail">
                        <Row className="m-0 mb-2">
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">Type</p>
                            <p className="text-muted">
                              {activeObj?.type ? activeObj?.type : "_"}
                            </p>
                          </Col>
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">Platform</p>
                            <p className="text-muted">
                              {activeObj?.platform ? activeObj?.platform : "_"}
                            </p>
                          </Col>
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">Logged In As</p>
                            <p className="text-muted">
                              {activeObj?.loggedInAs
                                ? activeObj?.loggedInAs
                                : "0"}
                            </p>
                          </Col>
                        </Row>
                        <Row className="m-0 mb-2">
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">Created At</p>
                            <p className="text-muted">
                              {moment(activeObj?.createdAt).format(
                                "DD-MMMM-yyyy"
                              )}
                            </p>
                          </Col>
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">Updated At</p>
                            <p className="text-muted">
                              {moment(activeObj?.updatedAt).format(
                                "DD-MMMM-yyyy"
                              )}
                            </p>
                          </Col>
                          <Col md={3} className="mt-1">
                            <p className="fw-bold mb-1">Status</p>
                            <p
                              className={
                                activeObj?.status === "resolved"
                                  ? "text-success"
                                  : "text-danger"
                              }
                            >
                              {activeObj?.status ? activeObj.status : "_"}
                            </p>
                          </Col>
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">Priority</p>
                            <p
                              className={
                                activeObj?.priority === "Medium"
                                  ? "text-warning"
                                  : activeObj?.priority === "Critical"
                                  ? "text-danger"
                                  : activeObj?.priority === "Low"
                                  ? "text-secondary"
                                  : activeObj?.priority === "High"
                                  ? "text-info"
                                  : ""
                              }
                            >
                              {activeObj?.priority ? activeObj.priority : "_"}
                            </p>
                          </Col>
                          <Col md={6} className="mt-1">
                            <p className="fw-bold mb-1">Page</p>
                            {activeObj?.page ? activeObj?.page : "_"}
                          </Col>
                        </Row>
                        <Row className="m-0 mb-2 mt-3">
                          <Col md={6} className="">
                            <p className="fw-bold mb-1">User Details</p>
                            {activeObj?.loggedInAs === "User" ? (
                              <div>
                                <h6 className="">
                                  {activeObj?.userId?.firstname
                                    ? activeObj?.userId?.firstname
                                    : "_"}{" "}
                                  {activeObj?.userId?.lastname
                                    ? activeObj?.userId?.lastname
                                    : "_"}
                                </h6>
                                <h6>
                                  {activeObj?.userId?.email
                                    ? activeObj?.userId?.email
                                    : "_"}
                                </h6>
                              </div>
                            ) : activeObj?.loggedInAs === "Stylist" ? (
                              <div>
                                <h6 className="">
                                  {activeObj?.stylistId?.legalname
                                    ? activeObj?.stylistId?.legalname
                                    : "_"}{" "}
                                  {activeObj?.stylistId?.shortname
                                    ? activeObj?.stylistId?.shortname
                                    : "_"}
                                </h6>
                                <h6>
                                  {activeObj?.stylistId?.email
                                    ? activeObj?.stylistId?.email
                                    : "_"}
                                </h6>
                              </div>
                            ) : (
                              ""
                            )}
                          </Col>
                          <Col md={12} className="mt-3">
                            <p className="fw-bold mb-1">Trace</p>
                            {activeObj?.trace ? activeObj?.trace : "_"}
                          </Col>
                        </Row>
                        <hr />
                        <div className="d-flex justify-content-end">
                          <Badge
                            className={badgeColor + "white"}
                            color={"primary"}
                            pill
                            onClick={() => {
                              UpdateToggle();
                            }}
                            role="button"
                          >
                            <i className="mdi mdi-pencil" id="editTooltip" />{" "}
                            Add
                          </Badge>
                        </div>
                        <div className="border border-1 p-2 mt-2">
                          <Col md={12} className="">
                            <p className="fw-bold mb-1">Analysis</p>
                            {/* {activeObj?.reason ? (
                              <div
                                className="text-muted mt-2"
                                dangerouslySetInnerHTML={{
                                  __html: activeObj?.reason,
                                }}
                              />
                            ) : (
                              "_"
                            )}{" "} */}
                            {Notes?.map((note, index) => {
                              return (
                                <div
                                  key={index}
                                  className="mb-2 p-2 admin_notes"
                                >
                                  <div className="d-flex  border-bottom ">
                                    <div>
                                      <h6 className="border border-2 mb-0 sl_no">
                                        {index + 1}
                                      </h6>
                                    </div>
                                    <div className="d-flex align-item-center justify-content-between w-100 ms-2">
                                      <div className="d-flex align-item-center">
                                        {/* <h6 className="fw-bold me-1 mb-0">Edited By -</h6> */}
                                        <h6 className="mb-0 fw-bold mb-1 pb-2">
                                          {note?.adminid?.firstname}{" "}{note?.adminid?.lastname}
                                        </h6>
                                        <h6 className="font-size-12 mb-0 ms-1">
                                          {" "}
                                          at{" "}
                                          {moment(note?.date).format(
                                            "DD-MMM-YY"
                                          )}
                                          , {note?.time}
                                        </h6>
                                      </div>
                                      {/* <div className="">
                                        <h6
                                          className="fw-bold"
                                          onClick={() => {
                                            mailtoggle();
                                            setmaildata(note);
                                          }}
                                          role="button"
                                        >
                                          <i className="bx bx-mail-send font-size-20"></i>
                                        </h6>
                                      </div> */}
                                    </div>
                                  </div>
                                  <div className="adminNotes_description ps-4 mt-2">
                                    {note?.reason ? (
                                      <div
                                        className="text-muted"
                                        dangerouslySetInnerHTML={{
                                          __html: note?.reason,
                                        }}
                                      />
                                    ) : (
                                      ""
                                    )}{" "}
                                  </div>
                                </div>
                              );
                            })}
                          </Col>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
      <UpdateExceptionStatus
        toggle={UpdateToggle}
        modal={updateModal}
        activeObj={activeObj}
        reload={reload}
        viewtoggle={toggle}
        setModal={setModal}
      />
    </>
  );
};

export default ExceptionsView;
