import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import AddMember from "../addMember";
import { deleteTeam } from "services/company.service";
import SweetAlert from "react-bootstrap-sweetalert";

const MyTeam = ({ myTeamInitialVal, handleSubmit }) => {
  const { id } = useParams();
  const [activeId, setActiveId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [myTeamList, setMyTeamList] = useState(myTeamInitialVal);
  const [modal, setModal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  // const [deleteId, setDeleteid] = useState(null);
  const [idComp, setIdComp] = useState();
  const [idMem, setIdMem] = useState();
  useEffect(() => {
    setMyTeamList(myTeamInitialVal);
  }, [myTeamInitialVal]);

  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };

  //Delete//
  const removeTeam = (compID, memID) => {
    setLoading(true);
    deleteTeam(compID, memID)
      .then(res => {
        setDelete_modal(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = (compID, memID) => {
    setIdComp(compID);
    setIdMem(memID);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeTeam(idComp, idMem);
  };
  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  return (
    <Card className="border-light view-users team_member_container">
      <CardBody>
        <div className="d-flex justify-content-between">
          <h5 className="fw-bold">Team Members</h5>

          <Button
            className="btn btn-sm btn-primary"
            color={"bg-warning"}
            onClick={() => {
              toggle();
              setActiveId(id);
            }}
          >
            <i className="bx bx-group font-size-14" /> Add Team Member
          </Button>
        </div>
        <Row className="justify-content-center my-2">
          <Row className="t_m_header">
            <Col sm={1} className="pe-0">
              <div className="fw-bold t_m_td">Sl.No.</div>
            </Col>
            <Col sm={3}>
              <div className="fw-bold t_m_td">Registration No.</div>
            </Col>
            <Col sm={2}>
              <div className="fw-bold t_m_td">Legal Name</div>
            </Col>
            <Col sm={3}>
              <div className="fw-bold t_m_td">Short Name</div>
            </Col>
            <Col sm={3}>
              <div className="fw-bold t_m_td">Location</div>
            </Col>
          </Row>
          {myTeamList &&
            myTeamList.length > 0 &&
            myTeamList.map((member, ind) => {
              return (
                <Row className="my-2 t_m_tr" key={ind}>
                  <Col sm={1}>
                    <div className="t_m_td tm_m_sno">{ind + 1}</div>
                  </Col>
                  <Col sm={2}>
                    <div className="t_m_td">{member.regNo}</div>
                  </Col>
                  <Col sm={2}>
                    <div className="t_m_td">{member.legalname}</div>
                  </Col>
                  <Col sm={3}>
                    <div className="t_m_td">{member.shortname}</div>
                  </Col>
                  <Col sm={3}>
                    <div className="t_m_td">{member.address}</div>
                  </Col>
                  <Col sm={1}>
                    <button
                      className="btn btn-danger btn-sm"
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        onClickDelete(id, member._id);
                      }}
                    >
                      <i className="bx bx-trash-alt font-size-16"></i>
                    </button>
                  </Col>
                </Row>
              );
            })}

          {myTeamList.length < 1 && (
            <Row className="mt-3 t_m_tr">
              <div className="team_0 text-center py-3">
                Please Add Team Members
              </div>
            </Row>
          )}
        </Row>
      </CardBody>
      <div>
        <AddMember toggle={toggle} modal={modal} id={activeId} />
      </div>
      {delete_modal ? deletePopup() : null}
    </Card>
  );
};

export default MyTeam;
