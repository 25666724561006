import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";
import moment from "moment";
import profileImg from "assets/images/users/avatar-3.jpg";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

import { getParentCategory } from "services/parentcategory.service";
import { getCategoryTypeandParent } from "services/category.service";
import "./viewStyle.scss";

const defaultValues = {
  name: "",
  type: "",
  image: {},
  createdAt: null,
  updatedAt: null,
};

const ViewParentCat = props => {
  const { id } = useParams();
  const [values, setValues] = useState(defaultValues);
  const [subCatList, setSubCatList] = useState([]);
  const [loading, setLoading] = useState(true);

  let { image } = values;

  const getCataegories = body => {
    getCategoryTypeandParent(body)
      .then(res => {
        console.log(res);
        if (res.data) {
          setSubCatList(res.data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getParentCategory(id)
      .then(res => {
        let { name, type, image, createdAt, updatedAt } = res.data;

        let obj = { name, type, image, createdAt, updatedAt };
        setValues({ ...obj });
        setLoading(false);
        let body = {
          type: type,
          parentCategory: id,
        };
        getCataegories(body);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const renderCategoryList = () => {
    return (
      <Card className="view-users parentCat_view">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">SUB CATEGORIES</h5>
            <Link to="/category-create">
              <Button
                type="button"
                color="primary"
                className="btn-rounded btn-md"
              >
                <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                Add Sub Category
              </Button>
            </Link>
          </div>
          <div className=" my-3 me-3 hr"> </div>
          {subCatList && subCatList.length > 0 ? (
            <Row className="mt-2">
              {subCatList.map((cat, index) => {
                return (
                  <Col key={index} md="3">
                    <Card className="shadow-sm mt-3">
                      <CardBody>
                        <div className="text-center">
                          <ProfileImage
                            image={cat.image}
                            classNames="rounded-circle avatar-md"
                          />
                          <h5 className="my-2">{cat.name}</h5>
                          <p className="category_status">
                            <span
                              className={
                                "status_dot " +
                                (cat.status == "Active" ? "active" : "inactive")
                              }
                            ></span>
                            <span className="ms-3">{cat.status}</span>
                          </p>
                        </div>
                        <div className=" my-2 hr"> </div>
                        <div className="d-flex justify-content-between pt-1">
                          <Link
                            to={`/category-update/${cat._id}`}
                            className="w-50 pe-2"
                          >
                            <input
                              type="button"
                              value="Edit"
                              className="w-100 btn btn-sm btn-outline-light"
                            />
                          </Link>
                          <Link
                            to={`/category-view/${cat._id}`}
                            className="w-50 ps-2"
                          >
                            <input
                              type="button"
                              value="View"
                              className="w-100 btn btn-sm btn-outline-primary"
                            />
                          </Link>
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="text-center">
              <p className="mt-4 mb-2"> No data found</p>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Parent Category | Parent Category View </title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumb
            title="Parent-Category "
            breadcrumbItem="Parent-Category  View"
          />
          <Card className="view-users parentCat_view">
            <CardBody>
              <div className="d-flex justify-content-between">
                <Link to="/parentcategory-list">
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link>
                <Link className="text-end" to={`/parentcategory-update/${id}`}>
                  <Badge
                    className="font-size-12 badge-soft-warning py-1 px-3"
                    color={"bg-warning"}
                    pill
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                </Link>
              </div>
              <Row>
                <Col md={4} className="">
                  <div className="text-center my-4">
                    {/* <img src={profileImg} alt="" className="img-thumbnail" /> */}
                    <ProfileImage
                      image={image}
                      classNames="img-thumbnail max_width_250"
                    />
                    <h5 className="mt-4 text-muted">{values.name}</h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 view_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold"> Name </span>{" "}
                      <span className="text-muted">{values.name} </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Type</p>
                      <p className="text-muted">{values?.type}</p>
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">
                        {moment(
                          values.createdAt ? values.createdAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">
                        {moment(
                          values.updatedAt ? values.updatedAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {renderCategoryList()}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewParentCat;
