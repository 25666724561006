import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import avatar from "assets/images/avatar.jpg";
import EditBasic from "./Edit/EditBasic";
import { updateArtistById } from "services/artist.service";
import EditArtistInfo from "./Edit/EditArtistInfo";
import EditArtistServices from "./Edit/EditArtistServices";
import SweetAlert from "react-bootstrap-sweetalert";
import EditPortfolio from "./Edit/EditPortfolio";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import EditAdminNotes from "./Edit/EditAdminNotes";

const defaultValues = {
  title: "",
  name: "",
  city: "",
  state: "",
  country: "",
  area: "",
  mobileNumber: "",
  email: "",
  status: "",
  artistImage: "",
  startingPrice: "",
  services: [],
  portfolio: [],
  videos: [],
  reviews: [],
  productsUsed: "",
  startingYear: "",
  travels: "",
  airbrush: "",
  trialPolicy: "",
  pricesInclusive: "",
  category: "",
  readMore: "",
  description: "",
};

const View = ({ artistValues, reloadArtist, closeTab }) => {
  const [values, setValues] = useState(defaultValues);
  const [editBasicModal, setEditBasicModal] = useState(false);
  const [editArtistInfoModal, setEditArtistInfoModal] = useState(false);
  const [editServicesModal, setEditServicesModal] = useState(false);
  const [editPortfolioModal, setEditPortfolioModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const params = useParams();

  useEffect(() => {
    if (artistValues) {
      let {
        _id,
        title,
        name,
        city,
        state,
        country,
        area,
        mobileNumber,
        email,
        status,
        artistImage,
        artistInfo,
        startingPrice,
        artistServices,
        portfolio,
        videos,
        reviews,
        productsUsed,
        startingYear,
        travels,
        airbrush,
        trialPolicy,
        pricesInclusive,
        category,
        readMore,
        description,
        createdAt,
        updatedAt,
        adminNotes,
      } = artistValues[0];

      let obj = {
        _id,
        title,
        name,
        city,
        state,
        country,
        area,
        mobileNumber,
        email,
        status,
        artistImage,
        artistInfo,
        startingPrice,
        artistServices,
        portfolio,
        videos,
        reviews,
        productsUsed,
        startingYear,
        travels,
        airbrush,
        trialPolicy,
        pricesInclusive,
        category,
        readMore,
        description,
        createdAt,
        updatedAt,
        adminNotes,
      };

      setValues({ ...obj });
      handleCancel(closeTab);
    }
  }, [artistValues]);

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const handleEdit = tabName => {
    switch (tabName) {
      case "basic":
        setEditBasicModal(true);
        break;
      case "artistInfo":
        setEditArtistInfoModal(true);
        break;
      case "services":
        setEditServicesModal(true);
        break;
      case "portfolio":
        setEditPortfolioModal(true);
        break;
      default:
        break;
    }
  };

  const handleCancel = tabName => {
    switch (tabName) {
      case "basic":
        setEditBasicModal(false);
        break;
      case "artistInfo":
        setEditArtistInfoModal(false);
        break;
      case "services":
        setEditServicesModal(false);
        break;
      case "portfolio":
        setEditPortfolioModal(false);
        break;
      default:
        break;
    }
  };

  const handleIsEdit = () => {
    setIsEdit(!isEdit);
  };
  const handleSave = async (body, tabName) => {
    try {
      const res = await updateArtistById(params.id, body);

      if (res?.success) {
        reloadArtist(tabName);
        setsuccess_msg(true);
        setIsEdit(!isEdit);
      } else {
        seterror_msg(true);
      }
    } catch (err) {
      seterror_msg(true);
    }
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Indvidual created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button color="danger" className="w-md btn-sm mx-1">
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const renderBasicInfo = () => {
    return (
      <div className="m-3 services_basic_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>BASIC INFO</h6>
            <div className="d-flex ">
              <Link
                className="btn btn-sm btn-primary btn-bg-primary me-3 "
                to="#"
                onClick={() => handleEdit("basic")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Edit</span>
              </Link>
            </div>
          </div>
        </Row>
        <Row className="m-0">
          <Col md={4} className="services_images_info ps-0 pe-2">
            <Card className="mb-2">
              <CardBody>
                <Row className="mb-2">
                  {" "}
                  <Col md={6}>
                    <p className="fw-bold mb-0">Artist Image</p>{" "}
                  </Col>
                  <Col md={6}> </Col>
                </Row>
                <div className="banner">
                  <img
                    alt="banner"
                    src={values?.artistImage ? values?.artistImage : avatar}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={8} className="p-0">
            <Card>
              <CardBody>
                <Row className="m-0">
                  <Col md={12} className="mb-1 px-0 view_detail">
                    <Row className="m-0 mb-2">
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Title</p>
                        <p className="text-muted">
                          {values?.title ? values?.title : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Name</p>
                        <p className="text-muted">
                          {values?.name ? values?.name : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Status</p>
                        <p
                          className={
                            values?.status === "Active"
                              ? "text-success"
                              : values?.status === "Locked"
                              ? "text-warning"
                              : "text-danger"
                          }
                        >
                          {values?.status ? values.status : "_"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">Area</p>
                        <p className="text-muted">
                          {values?.area ? values?.area : "_"}
                        </p>
                      </Col>
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">City</p>
                        <p className="text-muted">
                          {values?.city ? values?.city : "_"}
                        </p>
                      </Col>
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">State</p>
                        <p className="text-muted">
                          {values?.state ? values?.state : "_"}
                        </p>
                      </Col>
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">Country</p>
                        <p className="text-muted">
                          {values?.country ? values?.country : "_"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Mobile</p>
                        <p className="text-muted">
                          {values?.mobileNumber ? values?.mobileNumber : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Email</p>
                        <p className="text-muted">
                          {values?.email ? values?.email : "_"}{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={12} className="mt-1">
                        <p className="fw-bold mb-1">Description</p>
                        {values?.description ? (
                          <div
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: values?.description,
                            }}
                          />
                        ) : (
                          "_"
                        )}{" "}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  const renderArtistInfo = () => {
    return (
      <div className="m-3 services_basic_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>ARTIST INFO</h6>
            <div className="d-flex ">
              <Link
                className="btn btn-sm btn-primary btn-bg-primary me-3 "
                to="#"
                onClick={() => handleEdit("artistInfo")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Edit</span>
              </Link>
            </div>
          </div>
        </Row>
        <Row className="m-0">
          <Col md={12} className="p-0">
            <Card>
              <CardBody>
                <Row className="m-0">
                  <Col md={12} className="mb-1 px-0 view_detail">
                    {values?.artistInfo?.map((ele, index) => {
                      return (
                        <Row className="m-0 mb-2" key={index}>
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">{ele?.name}</p>
                          </Col>
                          <Col md={8} className="mt-1">
                            <p className="text-muted">{ele?.value}</p>
                          </Col>
                        </Row>
                      );
                    })}
                    <Row className="m-0 mb-2">
                      <Col md={12} className="mt-1">
                        <p className="fw-bold mb-1">More Information</p>
                        {values?.readMore ? (
                          <div
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: values?.readMore,
                            }}
                          />
                        ) : (
                          "_"
                        )}{" "}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  const renderArtistServices = () => {
    return (
      <div className="m-3 services_basic_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>ARTIST SERVICES</h6>
            <div className="d-flex ">
              <Link
                className="btn btn-sm btn-primary btn-bg-primary me-3 "
                to="#"
                onClick={() => handleEdit("services")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Edit</span>
              </Link>
            </div>
          </div>
        </Row>
        <Row className="m-0">
          <Col md={12} className="p-0">
            <Card>
              <CardBody>
                <Row className="m-0">
                  <Col md={12} className="mb-1 px-0 view_detail">
                    <Row className="m-0 mb-2">
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Starting Price</p>
                      </Col>
                      <Col md={8} className="mt-1">
                        <p className="text-muted">{values?.startingPrice}</p>
                      </Col>
                    </Row>
                    {values?.artistServices?.map((ele, index) => {
                      return (
                        <Row className="m-0 mb-2" key={index}>
                          <Col md={4} className="mt-1">
                            <p className="fw-bold mb-1">{ele.name}</p>
                          </Col>
                          <Col md={8} className="mt-1">
                            <p className="text-muted">{ele?.price}</p>
                          </Col>
                        </Row>
                      );
                    })}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };

  const renderPortfolio = () => {
    return (
      <div>
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>PORTFOLIO</h6>
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("portfolio")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className=" m-0 mb-2 p-3 option_div me-4">
              <Col md={12} className="">
                {values?.portfolio && values?.portfolio?.length === 0 && (
                  <div className="empty_list p-3 pt-0">
                    <div className="text-center">
                      <i
                        className="mdi mdi-circle-off-outline"
                        id="EmptyCatList"
                      ></i>
                    </div>
                    <h4>Empty List</h4>
                    <p className="text-center">No Portfolio Found</p>
                  </div>
                )}
                <div className="service_img_container ">
                  {values?.portfolio?.map((rel, index) => (
                    <div
                      className="img_card"
                      style={{ width: "100px" }}
                      key={index}
                    >
                      <img src={rel?.image} />
                    </div>
                  ))}
                  <hr />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  const renderVideos = () => {
    return (
      <div>
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>VIDEOS</h6>
            {/* <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("relatedService")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link> */}
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className=" m-0 mb-2 p-3 option_div me-4">
              <Col md={12} className="">
                {values?.videos && values?.videos?.length === 0 && (
                  <div className="empty_list p-3 pt-0">
                    <div className="text-center">
                      <i
                        className="mdi mdi-circle-off-outline"
                        id="EmptyCatList"
                      ></i>
                    </div>
                    <h4>Empty List</h4>
                    <p className="text-center">No Videos Found</p>
                  </div>
                )}
                <div className="service_img_container ">
                  {values?.videos?.map((rel, index) => (
                    <div
                      className="img_card"
                      style={{ width: "100px" }}
                      key={index}
                    >
                      <img src={rel?.video} />
                    </div>
                  ))}
                  <hr />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  const renderReviews = () => {
    return (
      <div>
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>REVIEWS</h6>
            {/* <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("relatedService")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link> */}
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className=" m-0 mb-2 p-3 option_div me-4">
              <Col md={12} className="">
                {values?.reviews && values?.reviews?.length === 0 && (
                  <div className="empty_list p-3 pt-0">
                    <div className="text-center">
                      <i
                        className="mdi mdi-circle-off-outline"
                        id="EmptyCatList"
                      ></i>
                    </div>
                    <h4>Empty List</h4>
                    <p className="text-center">No Reviews Found</p>
                  </div>
                )}
                <div className="service_img_container ">
                  {values?.reviews?.map((rel, index) => (
                    <></>
                  ))}
                  <hr />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <React.Fragment>
      {editBasicModal ? (
        <EditBasic
          artistValues={artistValues}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      ) : (
        renderBasicInfo()
      )}
      {editArtistInfoModal ? (
        <EditArtistInfo
          artistValues={artistValues}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      ) : (
        renderArtistInfo()
      )}
      {editServicesModal ? (
        <EditArtistServices
          artistValues={artistValues}
          handleSave={handleSave}
          handleCancel={handleCancel}
        />
      ) : (
        renderArtistServices()
      )}

      <div className="m-3 services_options_info">
        <Row>{renderPortfolio()}</Row>
        <Row>{renderVideos()}</Row>
        <Row>{renderReviews()}</Row>
        {/* <Row>
          <EditAdminNotes
            artistValues={artistValues}
            handleSave={handleSave}
            handleIsEdit={handleIsEdit}
            isEdit={isEdit}
          />
        </Row> */}
      </div>
      <EditPortfolio
        artistValues={artistValues}
        handleSave={handleSave}
        handleCancel={handleCancel}
        modal={editPortfolioModal}
      />
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </React.Fragment>
  );
};

export default View;
