export const getReviewsById = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/review/get/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Reviews ${e}`));
};
export const deleteReview = (reviewId, commentId) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/review/remove/${reviewId}/${commentId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        // "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Review ${e}`));
};
