import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";
import { getBrands } from "services/ecommerce/brand.service";
import { getProducts } from "services/ecommerce/product.service";
import { updatefeildService } from "services/services.service";

const ProductInfoModal = ({ toggle, initValues, reloadService }) => {
  const [values, setValues] = useState();
  const [productsList, setProductslist] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);

  useEffect(() => {
    getProductsList();
    const { products } = initValues;
    setSelectedProducts(buildOptions(products));
  }, []);

  const getProductsList = () => {
    getProducts()
      .then(res => {
        const result = buildOptions(res?.data);
        setProductslist(result);
      })
      .catch(err => {
        console.log(err);
      });
  };

  //Build {label,value}array
  const buildOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele._id,
      };
      return obj;
    });
    return result;
  };

  function handleProducts(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["products"]: data });
    setSelectedProducts(selectedMulti);
  }

  const onSubmit = () => {
    let body = { ...values };
    updatefeildService(initValues._id, body)
      .then(res => {
        console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };

  return (
    <>
      <Row className="mt-3 mx-0">
        <Col md={12} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="products">Products</Label>
            <Select
              id="products"
              value={selectedProducts}
              isMulti={true}
              onChange={event => {
                handleProducts(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={productsList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default ProductInfoModal;
