import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import noImage from "assets/images/PicUpload.jpg";
import MobileLabelCreate from "./MobileLabelCreate";
import CallUsLabelCreate from "./CallUsLabelCreate";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  GetMobileBanners,
  GetCallUsBanners,
  deleteBanner,
} from "services/MobileBanner.service";
import Spinner from "components/spinner";
import ModalImage from "react-modal-image";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import Breadcrumb from "components/Common/Breadcrumb";

const LabelsPage = () => {
  const [tab, setTab] = useState("mobile");
  const [modal, setModal] = useState(false);
  const [mobileModal, setMobileModal] = useState(false);
  const [callUsModal, setCallUsModal] = useState(false);
  const [bannerData, setbannerData] = useState(null);
  const [delete_modal, setDelete_modal] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState([]);
  const [deleteId, setDeleteid] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (tab == "mobile") GetMobileBannerRecords();
    else if (tab == "call-us") GetCallUsBannerRecords();
  }, [bannerData]);

  const toggle = () => {
    if (tab == "mobile") {
      if (mobileModal) {
        setbannerData(null);
      }
      setMobileModal(!mobileModal);
    } else if (tab == "call-us") {
      if (callUsModal) {
        setbannerData(null);
      }
      setCallUsModal(!callUsModal);
    }
  };

  const GetMobileBannerRecords = () => {
    setLoading(true);
    GetMobileBanners()
      .then(res => {
        if (res) {
          setValues(res?.data);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const GetCallUsBannerRecords = () => {
    setLoading(true);
    GetCallUsBanners()
      .then(res => {
        if (res) {
          setValues(res?.data);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const removeBanner = id => {
    setLoading(true);
    deleteBanner(id)
      .then(res => {
        if (res) {
          setsuccess_msg(true);
          setLoading(false);
          if (tab == "mobile") GetMobileBannerRecords();
          else if (tab == "call-us") GetCallUsBannerRecords();
        } else {
          seterror_msg(true);
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const selectMobileTab = () => {
    setLoading(true);
    setValues([]);
    GetMobileBanners()
      .then(res => {
        if (res) {
          setValues(res?.data);
          setTab("mobile");
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        setTab("mobile");
        setLoading(false);
      });
  };

  const selectCallUsTab = () => {
    setLoading(true);
    setValues([]);
    GetCallUsBanners()
      .then(res => {
        if (res) {
          setValues(res?.data);
          setTab("call-us");
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        setTab("call-us");
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeBanner(deleteId);
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    setDelete_modal(false);
    seterror_msg(false);
  };
  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-md mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully banner deleted
      </SweetAlert>
    );
  };
  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="page-content">
        <MetaTags>
          <title>Mobile Banner | stylepro</title>
        </MetaTags>
        <Breadcrumb title="Banner" breadcrumbItem="Banners List" />

        <BreadcrumbSub
          subtile="Mobile Homepage Banner"
          SubBreadcrumbItem="Mobile Homepage"
        />
        <Container fluid>
          <Row>
            <Col xs="12">
              <div className="page-title-box d-sm-flex align-items-center">
                <h4
                  className="mb-0 font-size-18 hover"
                  role="button"
                  style={tab == "mobile" ? { textDecoration: "underline" } : {}}
                  onClick={() => selectMobileTab()}
                >
                  Mobile Banner
                </h4>
                <span style={{ margin: "0px 8px" }}>|</span>
                <h4
                  className="mb-0 font-size-18"
                  role="button"
                  style={
                    tab == "call-us" ? { textDecoration: "underline" } : {}
                  }
                  onClick={() => selectCallUsTab()}
                >
                  Call-Us Banner
                </h4>
              </div>
            </Col>
          </Row>
          <div>
            <div className="text-end mb-3">
              <Button
                type="button"
                color="primary"
                className="mt-2"
                onClick={toggle}
              >
                Create
              </Button>
            </div>
            {values?.map((ele, index) => {
              return (
                <Card className="labels_view" key={index}>
                  <CardBody className="">
                    <Row className="text-center d-flex align-items-center">
                      <Col md="4">
                        <div className="modal-img-react">
                          <ModalImage
                            small={ele?.image ? ele?.image : noImage}
                            medium={ele?.image ? ele?.image : noImage}
                            large={ele?.image ? ele?.image : noImage}
                            className="modal-img-size"
                          />
                        </div>

                        {/* <img
                          src={ele?.image ? ele?.image : noImage}
                          alt=""
                          className="img-thumbnail profile"
                          width={200}
                        /> */}
                      </Col>
                      <Col md="4">
                        <p className="fw-bold p-0 m-0">{ele?.title}</p>
                      </Col>
                      <Col md="1">
                        <p
                          className={
                            ele?.status === "Active"
                              ? "text-success fw-bold p-0 m-0"
                              : "text-danger fw-bold p-0 m-0"
                          }
                        >
                          {ele?.status}
                        </p>
                      </Col>
                      <Col md="3">
                        <div className="d-flex justify-content-end">
                          <div>
                            <Badge
                              className="font-size-12 badge-soft-warning me-3"
                              color={"bg-warning"}
                              pill
                              role="button"
                              onClick={() => {
                                setbannerData(ele);
                                toggle();
                              }}
                            >
                              <i className="mdi mdi-pencil font-size-14" /> Edit
                            </Badge>
                          </div>
                          <div>
                            <Badge
                              className="font-size-12 badge-soft-danger"
                              color={"bg-warning"}
                              onClick={() => onClickDelete(ele?._id)}
                              style={{ cursor: "pointer" }}
                              pill
                            >
                              <i className="mdi mdi-delete font-size-14" />
                              Delete
                            </Badge>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              );
            })}
            {!values && values.length < 0 && (
              <Card>
                <CardBody className="py-4">
                  <p className="text-center p-0 m-0">There are no Banners</p>
                </CardBody>
              </Card>
            )}
          </div>
          <MobileLabelCreate
            toggle={toggle}
            modal={mobileModal}
            bannerData={bannerData}
            reload={GetMobileBannerRecords}
          />
          <CallUsLabelCreate
            toggle={toggle}
            modal={callUsModal}
            bannerData={bannerData}
            reload={GetCallUsBannerRecords}
          />
        </Container>
        {delete_modal ? deletePopup() : null}
        {success_msg ? successPopup() : null}
        {error_msg ? errorPopup() : null}
      </div>
    </React.Fragment>
  );
};

export default LabelsPage;
