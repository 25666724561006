import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";
import ProfileImage from "../../components/profileImage";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import { createBanner, updateBanner } from "services/MobileBanner.service";
import Spinner from "components/spinner";

const defaultValues = {
  title: "",
  image: "",
  status: "",
};

const MobileLabelCreate = ({ toggle, modal, reload, bannerData }) => {
  const [selectedType, setSelectedType] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const initialValues = bannerData
    ? { ...defaultValues, ...bannerData }
    : defaultValues;

  const validationSchema = Yup.object().shape({
    // title: Yup.string().required("Please Enter title"),
    status: Yup.string().required("Please Select status"),
    image: Yup.string().required("Please Upload Image"),
  });
  const onSubmit = values => {
    if (bannerData) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const { image } = values;
  function handleSelectType(event) {
    setValues({ ...values, ["status"]: event.value });
    setSelectedType(event);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    createBanner(formData)
      .then(res => {
        if (res?.success === true) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    updateBanner(bannerData?._id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
    setSelectedType(null);
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        {bannerData
          ? "Successfully Banner Edited"
          : "Successfully Banner created"}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Spinner loading={loading} />
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
          {bannerData ? "Edit Mobile Banner" : "Create Mobile Banner"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid={false}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    setFieldTouched,
                    values,
                  }) => {
                    useEffect(() => {
                      if (bannerData) {
                        setSelectedType({
                          value: bannerData.status,
                          label:
                            bannerData.status === "Active"
                              ? "Active"
                              : "Inactive",
                        });
                      }
                    }, [bannerData]);

                    return (
                      <Form>
                        <Row className="mt-4">
                          <Col md={6} className="px-4">
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Title
                                </Label>
                                <Input
                                  name="title"
                                  placeholder="title"
                                  type="text"
                                  classtitle="form-control"
                                  id="title"
                                  onChange={handleChange}
                                  // onBlur={validation.handleBlur}
                                  value={values.title}
                                  invalid={
                                    touched.title && errors.title ? true : false
                                  }
                                />

                                {touched.title && errors.title ? (
                                  <FormFeedback type="invalid">
                                    {errors.title}
                                    {touched.title}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="Status">Status</Label>
                                <Select
                                  // className="form-control"
                                  id="Status"
                                  value={selectedType}
                                  onChange={event => {
                                    handleSelectType(event);
                                    handleChange("status")(event.value);
                                  }}
                                  name="Status"
                                  options={statusList}
                                  classNamePrefix="select2-selection"
                                />
                                <Label className="statusValidation">
                                  {errors.status ? errors.status : ""}
                                </Label>
                              </FormGroup>
                            </Row>
                          </Col>
                          <Col md={6} className="px-4">
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom06">
                                  Image
                                </Label>
                                <Dropzone
                                  id="validationCustom06"
                                  onDrop={acceptedFiles => {
                                    acceptedFiles.map(file =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        formattedSize: formatBytes(file.size),
                                      })
                                    );
                                    setFieldValue("image", acceptedFiles[0]);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone image_drop">
                                      <div
                                        className="dz-message needsclick "
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="">
                                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h6>
                                          Drop files here or click to upload.
                                        </h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.image ? errors.image : ""}
                                </Label>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {values.image &&
                                    Object.keys(values.image).length > 0 && (
                                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={values?.image}
                                                height="80"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {values.image.name ||
                                                  values.image.filename}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {values.image.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    )}
                                </div>
                              </FormGroup>
                            </Row>
                          </Col>
                        </Row>
                        <hr></hr>
                        <div className="d-flex justify-content-end">
                          <Button
                            color="light"
                            className="w-md btn-sm me-3"
                            onClick={leaveToList}
                          >
                            cancel
                          </Button>
                          {!bannerData ? (
                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm"
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              className="w-md btn-sm"
                              type="submit"
                            >
                              Save Changes
                            </Button>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>

                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default MobileLabelCreate;
