import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import avatar from "assets/images/avatar.jpg";

const defaultValues = {
    services: []
};

const EditArtistServices = ({
    artistValues,
    handleSave,
    handleCancel,
}) => {
    const [values, setValues] = useState(defaultValues);

    useEffect(() => {
        if (artistValues) {
            let {
                _id,
                startingPrice,
                artistServices
            } = artistValues[0];

            let obj = {
                _id,
                startingPrice,
                artistServices
            };

            setValues({ ...obj });
        }
    }, [artistValues]);

    const handleChange = (e) => {
        let { name, value } = e.target;
        let obj = { ...values };

        switch (name) {
            case 'startingPrice':
                obj.startingPrice = value;
                break;

            default:
                break;
        }

        setValues({ ...obj });
    }

    const addArrayElement = () => {
        let obj = { ...values };

        obj.artistServices = [...obj.artistServices, { "name": "", "value": "", order: 0 }]

        setValues({ ...obj });
    }

    const deleteArrayElement = (i) => {
        let obj = { ...values };

        obj.artistServices.splice(i);

        setValues({ ...obj });
    }

    const handleArrayChange = (e, i) => {
        let { name, value } = e.target;
        let obj = { ...values };

        switch (name) {
            case 'name':
                obj.artistServices[i].name = value;
                break;
            case 'price':
                obj.artistServices[i].price = value;
                break;

            default:
                break;
        }

        setValues({ ...obj });
    }

    return (
        <React.Fragment>
            <div className="m-3 services_basic_info">
                <Row>
                    <div className="info_conetent_header d-flex mb-2">
                        <h6>ARTIST INFO</h6>
                        <div className="d-flex ">
                            <Link
                                className="btn btn-sm btn-primary btn-bg-primary me-3 "
                                to="#"
                                onClick={() => handleSave(values, "services")}
                            >
                                <i className="bx bx-edit-alt pe-1"> </i>
                                <span className="py-3"> Save</span>
                            </Link>
                            <Link
                                className="btn btn-sm btn-danger btn-bg-danger me-3 "
                                to="#"
                                onClick={() => handleCancel("services")}
                            >
                                <i className="bx bx-edit-alt pe-1"> </i>
                                <span className="py-3"> Cancel</span>
                            </Link>
                        </div>
                    </div>
                </Row>
                <Row className="m-0">
                    <Col md={12} className="p-0">
                        <Card>
                            <CardBody>
                                <Row className="m-0">
                                    <Col md={12} className="mb-1 px-0 view_detail">
                                        <Row className="m-0 mb-2">
                                            <Col md={4} className="mt-1">
                                                <input
                                                    className="form-control text-muted"
                                                    type="text"
                                                    name="name"
                                                    value="Starting Price"
                                                    disabled={true}
                                                />
                                            </Col>
                                            <Col md={8} className="mt-1">
                                                <input
                                                    className="form-control text-muted"
                                                    type="text"
                                                    name="startingPrice"
                                                    value={values?.startingPrice}
                                                    onChange={handleChange}
                                                />
                                            </Col>
                                        </Row>
                                        {values?.artistServices?.map((ele, index) => {
                                            return (
                                                <Row className="m-0 mb-2" key={index}>
                                                    <Col md={4} className="mt-1">
                                                        <input
                                                            className="form-control text-muted"
                                                            type="text"
                                                            name="name"
                                                            value={ele?.name}
                                                            onChange={e => handleArrayChange(e, index)}
                                                        />
                                                    </Col>
                                                    <Col md={7} className="mt-1">
                                                        <input
                                                            className="form-control text-muted"
                                                            type="text"
                                                            name="price"
                                                            value={ele?.price}
                                                            onChange={e => handleArrayChange(e, index)}
                                                        />
                                                    </Col>
                                                    <Col md={1} className="mt-1" style={{ paddingLeft: '0px' }}>
                                                        <Link
                                                            className="fw-bold mb-1 btn btn-sm btn-primary btn-bg-primary "
                                                            to="#"
                                                            onClick={() => deleteArrayElement(index)}
                                                        >
                                                            <span className="py-3">Delete</span>
                                                        </Link>
                                                    </Col>
                                                </Row>
                                            )
                                        })}

                                        <Row className="m-0 mb-2">
                                            <Col md={12} className="mt-1">
                                                <Link
                                                    className="btn btn-sm btn-primary btn-bg-primary me-3 "
                                                    to="#"
                                                    onClick={() => addArrayElement()}
                                                >
                                                    <i className="bx bx-plus pe-1"> </i>
                                                    <span className="py-3">Add Service</span>
                                                </Link>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default EditArtistServices;
