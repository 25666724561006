import { getSubCategory } from "services/ecommerce/category.service";
import { GET_SUB_CATEGORY, GET_SUB_CATEGORY_FAIL } from "./actionTypes";

export const getAllSubCategory = dispatch => {
  return getSubCategory()
    .then(res => {
      if (res?.data) {
        const result = _(res.data)
          .groupBy(x => x?.parentCategory?.name)
          .map((value, key) => ({ label: key, options: value }))
          .filter(ele => {
            ele.options = ele.options.filter(opt => {
              opt["label"] = opt.name;
              opt["value"] = opt._id;
              opt["mainCategory"] = opt.mainCategory;
              opt["parentCategory"] = opt.parentCategory;
              return opt;
            });
            return ele;
          })
          .value();

        dispatch({
          type: GET_SUB_CATEGORY,
          payload: result,
        });
        // setCategoriesList(result);
      } else {
        dispatch({
          type: GET_SUB_CATEGORY_FAIL,
          payload: "Something went wrong",
        });
      }
    })
    .catch(e => {
      console.log(e);
    });
};

export const getSubCategoryFail = error => ({
  type: GET_SUB_CATEGORY_FAIL,
  payload: error,
});
