import toastr from "toastr";

const ToastMessage = (type = "success", title, message, data = {}) => {
const timeout = (1000 * 60 * 30);
toastr.options = {
    closeButton: true,
    progressBar: false,
    timeOut: timeout,
    extendedTimeOut:timeout, 
    onclick: function() { 
        const serverData = JSON.parse(data.data);
        console.log('data=', serverData)
        if(serverData?.orderId) {
            window.location.href = '/orders?orderId='+serverData.orderId
        }
     }
}


toastr.options.onCloseClick = function() { console.log('close button clicked'); }

toastr[type](message, title);


}

export default ToastMessage;