export const getCompanies = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/company/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company all ${e}`));
};

export const createCompany = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/company/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company ${e}`));
};

export const getcompanyById = Id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/company/get/${Id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company ${e}`));
};

export const updatecompany = (Id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/company/update/${Id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company ${e}`));
};
export const updateCompanyField = (Id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/company/updateCompany/${Id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company update field ${e}`));
};
export const addteam = (Id, team) => {
  const body = {
    indvidual: team,
  };
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/company/addteam/${Id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company update field ${e}`));
};
export const deleteTeam = (compID, memID) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/company/deletemember/${compID}/${memID}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company ${e}`));
};

export const deletecompany = Id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/company/delete/${Id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company ${e}`));
};

export const getCompanyByRegNo = Id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/company/regno/${Id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at company By RegNo. ${e}`));
};
