import { getProduct, getProducts } from "services/ecommerce/product.service";
import {
  GET_PRODUCT_ID,
  GET_PRODUCT_FAIL,
  GET_PRODUCT_EMPTY,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCTS,
} from "./actionTypes";
import { actions } from "react-table";

export const getAllProducts = (dispatch, id) => {
  return getProducts()
    .then(res => {
      console.log(res, "product res");
      if (res.data) {
        dispatch({
          type: GET_PRODUCTS,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_PRODUCT_FAIL,
          payload: "Something went wrong",
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_PRODUCT_FAIL,
        payload: err.message,
      });
    });
};

export const getProductById = (dispatch, id) => {
  return getProduct(id)
    .then(res => {
      if (res.data) {
        dispatch({
          type: GET_PRODUCT_ID,
          payload: res.data,
        });
      } else {
        dispatch({
          type: GET_PRODUCT_FAIL,
          payload: "Something went wrong",
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_PRODUCT_FAIL,
        payload: err.message,
      });
    });
};

export const getProvidersFail = error => ({
  type: GET_PRODUCT_FAIL,
  payload: error,
});
export const empltyProductById = defaultValues => ({
  type: GET_PRODUCT_EMPTY,
  payload: defaultValues,
});
export const createNewProduct = values => ({
  type: CREATE_PRODUCT,
  payload: values,
});

export const updateProduct = values => ({
  type: UPDATE_PRODUCT,
  payload: values,
});
