export const getAllExceptionsSummary = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/exceptions/summary`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting exceptions summary ${e}`));
};

export const getAllAppExceptions = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/exceptions/getLogs/app`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting app exceptions ${e}`));
};

export const getAllApiExceptions = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/exceptions/getLogs/api`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting api exceptions ${e}`));
};

export const updateExceptionStatus = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/exceptions/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update file feild ${e}`));
};
