import React from "react";
import { Button } from "reactstrap";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
const ErrorAlert = ({ msg, leaveToList, stayHere }) => {
  return (
    <SweetAlert
      title="Failure"
      danger
      onConfirm={leaveToList}
      onCancel={stayHere}
      customButtons={
        <React.Fragment>
          <Button
            color="primary"
            className="w-md btn-sm mx-1"
            onClick={leaveToList}
          >
            Leave
          </Button>
          <Button color="light" className="w-md btn-sm mx-1" onClick={stayHere}>
            Continue
          </Button>
        </React.Fragment>
      }
    >
      {"Something went wrong"}
    </SweetAlert>
  );
};
export default ErrorAlert;
