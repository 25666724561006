import React, { useState, useEffect } from "react";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import noImage from "assets/images/PicUpload.jpg";
import { getProduct } from "services/ecommerce/product.service";
import ProfileImage from "../../../components/profileImage";
import images from "assets/images";
import { Reviews } from "../Reviews";
import { getReviewsById } from "services/ecommerce/reviews.service";
const defaultValues = {
  name: "",
  category: "",
  parentCategory: "",
  mainCategory: "",
  type: "",
  price: "",
  brand: "",
  description: [],
  banner: {},
  images: [],
  createdAt: null,
  updatedAt: null,
  status: "",
  stockStatus: "",
  featuredimage: "",
};

const ViewProduct = ({ toggle, modal, id = false }) => {
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);
  const [modalService, setModalService] = useState(false);
  const [reviewList, setReviewList] = useState();
  useEffect(() => {
    getProduct(id)
      .then(res => {
        let {
          name,
          price,
          category,
          parentCategory,
          type,
          discountPrice,
          description,
          banner,
          images,
          brand,
          createdAt,
          updatedAt,
          mainCategory,
          status,
          stockStatus,
          featuredimage,
          _id,
          attributes,
        } = res.data;
        let obj = {
          name,
          price,
          category,
          parentCategory,
          type,
          mainCategory,
          discountPrice,
          description,
          brand,
          banner,
          images,
          status,
          stockStatus,
          featuredimage,
          createdAt,
          updatedAt,
          _id,
          attributes,
        };
        setValues({ ...obj });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
    getReviews(id);
  }, []);

  const getReviews = id => {
    getReviewsById(id).then(res => {
      setReviewList(res.data);
      console.log(res, "resss");
    });
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}> Products View</ModalHeader>
        <ModalBody className="bg-light">
          {/* <Spinner loading={loading} /> */}
          <Container fluid>
            <Card className="view-users">
              <CardBody>
                <Row>
                  <Col md={4} className="">
                    {values?.isOffer ? (
                      <div className="avatar-sm product-ribbon">
                        <span className="avatar-title rounded-circle bg-primary">
                          {`- ${values.offer} %`}
                        </span>
                      </div>
                    ) : null}

                    <div className="text-center">
                      <ProfileImage
                        image={values?.images[0] ? values?.images[0] : noImage}
                        classNames="Featured_img"
                      />
                    </div>
                  </Col>
                  <Col md={8} className="mb-1 px-0 view_detail">
                    <Row className="mx-3 ps-2">
                      <p className="ps-0">
                        <span className="pe-3 fw-bold"> Name </span>{" "}
                        <span className="text-muted">{values.name} </span>
                      </p>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>
                    <Row className="mx-3 mb-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Main Category</p>
                        <p className="text-muted">
                          {values.mainCategory ? values.mainCategory.name : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Parent Category</p>
                        <p className="text-muted">
                          {values.parentCategory
                            ? values.parentCategory.name
                            : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1"> Category</p>
                        <p className="text-muted">
                          {values.category ? values.category.name : "_"}{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mx-3 mb-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Brand</p>
                        {values?.brand ? values.brand.name : "_"}
                      </Col>

                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Price</p>
                        {values?.type === "variant" ? (
                          values?.attributes?.map(attr => {
                            return (
                              <>
                                {attr?.combos?.map((ele, ind) => {
                                  return (
                                    <p className="text-muted" key={ind}>
                                      {ele?.price ? ele.price : "_"}
                                    </p>
                                  );
                                })}
                              </>
                            );
                          })
                        ) : (
                          <p className="text-muted">
                            {values?.price ? values.price : "_"}
                          </p>
                        )}
                      </Col>
                    </Row>
                    <Row className="mx-3 my-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Status</p>
                        <p className="text-muted">
                          {values.status ? values.status : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Stock Status</p>
                        <p className="text-muted">
                          {values.stockStatus ? values.stockStatus : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Product Type</p>
                        <p className="text-muted">
                          {values.type ? values.type : "_"}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={12} className="mt-4">
                      <h5 className="fw-bold text-secondary">Description</h5>
                      {values?.description && values?.description[0] !== "" ? (
                        <>
                          {values?.description?.map((ele, index) => {
                            return (
                              <div key={index}>
                                <div className="">
                                  <div className="pt-2">
                                    <span className="fw-bold">{ele?.name}</span>
                                  </div>
                                  <div className="product-info">
                                    <div
                                      className=""
                                      dangerouslySetInnerHTML={{
                                        __html: ele?.value,
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </>
                      ) : (
                        ""
                      )}
                    </Col>
                  </Row>
                </Row>
                <div className=" mt-2 me-3 hr"> </div>
                <Row>
                  <Col md={4} className="">
                    {/* {values?.isOffer ? (
                      <div className="avatar-sm product-ribbon">
                        <span className="avatar-title rounded-circle bg-primary">
                          {`- ${values.offer} %`}
                        </span>
                      </div>
                    ) : null} */}
                    {/* <div className="text-center">
                      <h5 className="text-muted mt-3">FEATURED IMAGE</h5>
                      <ProfileImage
                        image={
                          values?.featuredimage
                            ? values?.featuredimage
                            : noImage
                        }
                        classNames="Featured_img"
                      />
                    </div> */}
                  </Col>
                </Row>
                <Row>
                  <Col md="12" className="ms-2">
                    <h5 className="ms-2 mt-4 text-secondary">Images</h5>
                    <div className="">
                      {values?.images &&
                        values?.images.map(img => {
                          return (
                            <>
                              <img
                                src={img}
                                alt=""
                                className="m-2 p-1"
                                style={{ border: "1px solid #cdc8c8" }}
                                width={80}
                              />
                            </>
                          );
                        })}
                    </div>
                  </Col>
                </Row>

                <Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="">
                    <Col md={12} className="mt-2">
                      <Reviews reviewList={reviewList} id={id}></Reviews>
                    </Col>
                  </Row>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default ViewProduct;
