import React, { useEffect, useState } from "react";

//Country-State-Cities
import csc from "countries-states-cities";

//CKEDITOR
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { updatefeildService } from "services/services.service";

import {
  Col,
  Row,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  InputGroup,
  InputGroupText,
  FormGroup,
} from "reactstrap";

import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { getCategoryTypeandParent } from "services/category.service";

const BasicInfoModal = ({
  toggle,
  initValues,
  parentCategory,
  subCategoryList,
  reloadService,
}) => {
  const [countriesList, setCountriesList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedParCat, setSelectedParCat] = useState(null);
  const [selectedSubCat, setSelectedSubCat] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedPriceUnit, setSelectedPriceUnit] = useState(null);
  const [parentCatList, setParentCatList] = useState([]);
  const [subCatList, setSubCatList] = useState([]);

  const initParentCatList = () => {
    if (parentCategory) {
      let list = parentCategory.map(ele => {
        return {
          value: ele._id,
          label: ele.name,
        };
      });
      setParentCatList(list);
    }
  };

  const initSubCatList = () => {
    if (subCategoryList) {
      let list = subCategoryList.map(ele => {
        return {
          value: ele._id,
          label: ele.name,
        };
      });
      setSubCatList(list);
    }
  };

  useEffect(() => {
    //Get All Countries List
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
    initParentCatList();
    initSubCatList();
  }, []);

  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    // { label: "Unisex", value: "Unisex" },
  ];

  const priceUnitList = [
    { label: "Hourly", value: "hourly" },
    { label: "Day", value: "Day" },
    { label: "Monthly", value: "monthly" },
  ];
  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    categories: Yup.string().required("Please Select Sub Category"),
    status: Yup.string().required("Please Select Status"),
    parentCategory: Yup.string().required("Please Select Parent Category"),
    price: Yup.string().required("Please Enter Price"),
    discountPrice: Yup.string().required("Please Enter Discount Price"),
    priceUnit: Yup.string().required("Please Enter Price Unit"),
    customQuantityUnit: Yup.string().required(
      "Please Enter Custom Quantity Unit"
    ),
    order: Yup.number().required("Please Enter Order"),
    duration: Yup.string().required("Please Enter Duration"),
    country: Yup.object().nullable().required("Please Select Country"),
    type: Yup.string().required("Please Select gender"),
    description: Yup.string(),
    tagline: Yup.string(),
  });

  const getCategoryTypeParent = body => {
    getCategoryTypeandParent(body)
      .then(res => {
        const list = [];
        res?.data?.map(type => {
          list.push({ label: type.name, value: type._id });
        });
        setSubCatList(list);
      })
      .catch(err => {
        console.log(err);
      });
  };

  function handleSelectType(event) {
    setSelectedType(event);
    let list = [];
    parentCategory?.forEach(ele => {
      if (ele.type == event.label) {
        list.push({
          label: ele.name,
          value: ele._id,
        });
      }
    });
    setParentCatList(list);
    setSubCatList([]);
    setSelectedParCat(null);
    setSelectedSubCat(null);
    setSelectedStatus(null);
  }

  function handleSelectParentCat(event) {
    setSelectedParCat(event);
    if (selectedType?.value) {
      let obj = {
        type: selectedType.value,
        parentCategory: event.value,
      };
      getCategoryTypeParent(obj);
    }
    setSelectedSubCat(null);
  }
  function handleSelectSubCat(event) {
    setSelectedSubCat(event);
  }

  function handleSelectPriceUnit(event) {
    setSelectedPriceUnit(event);
  }
  function handleSelectStatus(event) {
    setSelectedStatus(event);
  }

  const onSubmit = values => {
    let body = { ...values };
    updatefeildService(initValues._id, body)
      .then(res => {
        console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={initValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          setErrors,
          values,
        }) => {
          useEffect(() => {
            if (initValues) {
              const fields = [
                "name",
                "categories",
                "parentCategory",
                "price",
                "discountPrice",
                "priceUnit",
                "customQuantityUnit",
                "order",
                "duration",
                "country",
                "type",
                "status",
                "description",
                "tagline",
                "status",
              ];
              fields.forEach(field => {
                if (field == "categories" || field == "parentCategory") {
                  let cat = initValues[field]["_id"];
                  setFieldValue(field, cat, false);
                } else {
                  setFieldValue(field, initValues[field], false);
                }
                setSelectedType({
                  label: initValues.type,
                  value: initValues.type,
                });
                setSelectedSubCat({
                  value: initValues.categories._id,
                  label: initValues.categories.name,
                });
                setSelectedParCat({
                  value: initValues.parentCategory?._id || "",
                  label: initValues.parentCategory?.name || "",
                });
                setSelectedPriceUnit({
                  value: initValues.priceUnit,
                  label: initValues.priceUnit,
                });
                setSelectedStatus({
                  value: initValues.status,
                  label: initValues.status,
                });
              });
              setErrors({});
            }
          }, [initValues]);
          return (
            <Form>
              <Row>
                <Col md={12}>
                  <Row>
                    {/* Name */}
                    <Col sm={4}>
                      <Label htmlFor="name" className=" col-form-label">
                        Name
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="name"
                        placeholder="Enter Your First Name"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        invalid={touched.name && errors.name ? true : false}
                      />
                      {touched.name && errors.name ? (
                        <FormFeedback type="invalid">
                          {errors.name}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    {/* country */}
                    <Col sm={4} className="">
                      <Label htmlFor="country" className="col-form-label">
                        Country
                      </Label>
                      <Select
                        name="country"
                        id="country"
                        onChange={event => {
                          setFieldValue("country", event);
                        }}
                        options={countriesList}
                        value={values.country}
                        classNamePrefix={"select2-selection "}
                      />
                      {touched.country && errors.country && (
                        <Label className="statusValidation">
                          {errors.country}
                        </Label>
                      )}
                    </Col>
                    {/* status */}
                    <Col md={3} className="">
                      {/* <FormGroup className="m-0"> */}
                      <Label htmlFor="price-Input" className="col-form-label">
                        Status
                      </Label>
                      <Select
                        name="status"
                        value={selectedStatus}
                        onChange={event => {
                          handleSelectStatus(event);
                          handleChange("status")(event.value);
                        }}
                        options={statusList}
                        classNamePrefix={"select2-selection "}
                      />
                      {touched.priceUnit && errors.priceUnit && (
                        <Label className="statusValidation">
                          {errors.priceUnit}
                        </Label>
                      )}
                      {/* </FormGroup> */}
                    </Col>

                    {/* Price */}
                    <Col md={4} className="">
                      {/* <FormGroup className="m-0"> */}
                      <Label className="col-form-label">Price Range</Label>
                      <InputGroup>
                        <Input
                          name="price"
                          placeholder=""
                          type="text"
                          id="price"
                          value={values.price}
                          onChange={handleChange}
                          invalid={touched.price && errors.price ? true : false}
                        />
                        <InputGroupText>$</InputGroupText>
                      </InputGroup>
                      {touched.price && errors.price ? (
                        <FormFeedback type="invalid">
                          {errors.price}
                        </FormFeedback>
                      ) : null}
                      {/* </FormGroup> */}
                    </Col>

                    {/* Duration */}
                    <Col md={4} className="">
                      {/* <FormGroup className="m-0"> */}
                      <Label
                        htmlFor="duration-Input"
                        className="col-form-label"
                      >
                        Duration
                      </Label>
                      <Input
                        type="text"
                        name="duration"
                        className="form-control"
                        id="duration-Input"
                        placeholder="Enter Duration "
                        onChange={handleChange}
                        value={values.duration}
                        invalid={
                          touched.duration && errors.duration ? true : false
                        }
                      />
                      {touched.duration && errors.duration ? (
                        <FormFeedback type="invalid">
                          {errors.duration}
                        </FormFeedback>
                      ) : null}
                      {/* </FormGroup> */}
                    </Col>

                    {/* Order  */}
                    <Col sm={4}>
                      <Label htmlFor="order" className=" col-form-label">
                        Order
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="order"
                        placeholder="Enter Order"
                        name="order"
                        onChange={handleChange}
                        value={values.order}
                        invalid={touched.order && errors.order ? true : false}
                      />
                      {touched.order && errors.order ? (
                        <FormFeedback type="invalid">
                          {errors.order}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    {/* Discount Price */}
                    <Col md={4} className="">
                      {/* <FormGroup className="m-0"> */}
                      <Label className="col-form-label">Discount Price</Label>
                      <InputGroup>
                        <Input
                          type="number"
                          placeholder=""
                          name="discountPrice"
                          value={values.discountPrice}
                          onChange={handleChange}
                          invalid={
                            touched.discountPrice && errors.discountPrice
                              ? true
                              : false
                          }
                        />
                        <InputGroupText>$</InputGroupText>
                      </InputGroup>
                      {touched.discountPrice && errors.discountPrice ? (
                        <FormFeedback type="invalid">
                          {errors.discountPrice}
                        </FormFeedback>
                      ) : null}
                      {/* </FormGroup> */}
                    </Col>

                    {/* Price Unit */}
                    <Col md={4} className="">
                      {/* <FormGroup className="m-0"> */}
                      <Label htmlFor="price-Input" className="col-form-label">
                        Price Unit
                      </Label>
                      <Select
                        name="priceUnit"
                        value={selectedPriceUnit}
                        onChange={event => {
                          handleSelectPriceUnit(event);
                          handleChange("priceUnit")(event.value);
                        }}
                        options={priceUnitList}
                        classNamePrefix={"select2-selection "}
                      />
                      {touched.priceUnit && errors.priceUnit && (
                        <Label className="statusValidation">
                          {errors.priceUnit}
                        </Label>
                      )}
                      {/* </FormGroup> */}
                    </Col>

                    {/* Custom Quantity Unit*/}
                    <Col md={4} className="">
                      {/* <FormGroup className="m-0"> */}
                      <Label
                        htmlFor="customQuantityUnit-Input"
                        className="col-form-label"
                      >
                        Custom quantity unit
                      </Label>
                      <Input
                        name="customQuantityUnit"
                        type="text"
                        className="form-control"
                        id="customQuantityUnit-Input"
                        onChange={handleChange}
                        value={values.customQuantityUnit}
                        invalid={
                          touched.customQuantityUnit &&
                          errors.customQuantityUnit
                            ? true
                            : false
                        }
                      />
                      {touched.customQuantityUnit &&
                      errors.customQuantityUnit ? (
                        <FormFeedback type="invalid">
                          {errors.customQuantityUnit}
                        </FormFeedback>
                      ) : null}
                      {/* </FormGroup> */}
                    </Col>

                    {/* Gender */}
                    <Col sm={4}>
                      <Label htmlFor="Gender" className="col-form-label">
                        Gender
                      </Label>

                      <Select
                        name="gender"
                        id="gender"
                        value={selectedType}
                        onChange={event => {
                          handleSelectType(event);
                          handleChange("type")(event.value);
                          handleChange("parentCategory")("");
                          handleChange("categories")("");
                        }}
                        options={typeList}
                        classNamePrefix={"select2-selection "}
                      />
                      {touched.type && errors.type && (
                        <Label className="statusValidation">
                          {errors.type}
                        </Label>
                      )}
                    </Col>

                    {/* Parent Category */}
                    <Col sm={4}>
                      <Label
                        htmlFor="parentCategory"
                        className="col-form-label"
                      >
                        Parent Category
                      </Label>

                      <Select
                        name="parentCategory"
                        id="parentCategory"
                        value={selectedParCat}
                        onChange={event => {
                          handleSelectParentCat(event);
                          handleChange("parentCategory")(event.value);
                          handleChange("categories")("");
                        }}
                        options={parentCatList}
                        classNamePrefix={"select2-selection "}
                      />
                      {touched.parentCategory && errors.parentCategory && (
                        <Label className="statusValidation">
                          {errors.parentCategory}
                        </Label>
                      )}
                    </Col>

                    {/* Sub Category */}
                    <Col sm={4}>
                      <Label htmlFor="subCategory" className="col-form-label">
                        Sub Category
                      </Label>

                      <Select
                        name="subCategory"
                        id="subCategory"
                        value={selectedSubCat}
                        onChange={event => {
                          handleSelectSubCat(event);
                          handleChange("categories")(event.value);
                        }}
                        options={subCatList}
                        classNamePrefix={"select2-selection "}
                      />
                      {touched.subCategory && errors.subCategory && (
                        <Label className="statusValidation">
                          {errors.subCategory}
                        </Label>
                      )}
                    </Col>
                    <Col sm={4}></Col>

                    {/* tagline */}
                    <Col md={12} className="">
                      <Label htmlFor="tagline-Input" className="col-form-label">
                        Tagline
                      </Label>
                      <Input
                        type="textarea"
                        name="tagline"
                        className="form-control"
                        id="tagline-Input"
                        placeholder="Enter tagline "
                        onChange={handleChange}
                        value={values.tagline}
                      />
                    </Col>

                    {/* Description */}
                    <Col md={12} className="">
                      <FormGroup className="mb-3">
                        <Label
                          htmlFor="firstname-Input"
                          className="col-form-label"
                        >
                          Description
                        </Label>
                        <Col sm={12} className="custom_ck_editor">
                          <CKEditor
                            id="description"
                            editor={ClassicEditor}
                            data={values.description ? values.description : ""}
                            onReady={editor => {
                              // You can store the "editor" and use when it is needed.
                              if (values.description) {
                                editor.setData(values.description);
                              }
                            }}
                            onChange={(event, editor) => {
                              const data = editor.getData();
                              handleChange("description")(data);
                            }}
                          />
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              {/* <hr /> */}
              <div className="d-flex mt-4 justify-content-end">
                <Button
                  color="light"
                  className="w-md btn-sm me-2"
                  onClick={() => toggle()}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="w-md btn-sm ms-2"
                  type="submit"
                >
                  Save
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default BasicInfoModal;
