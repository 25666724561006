import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from "components/spinner";
import noImage from "assets/images/PicUpload.jpg";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
} from "reactstrap";
import { getArtists, deleteArtist } from "services/artist.service";
import Breadcrumbs from "components/Common/Breadcrumb";
import AddArtist from "pages/ProviderRegister/artist/createArtist";
import fileIcon from "../../../src/assets/icons-image/document.png";
import moment from "moment";
import { Link } from "react-router-dom";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const IndvidualListNew = props => {
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [tempList, setTempList] = useState([]);
  const [typeFilter, setTypeFilter] = useState(["Active"]);
  const typeFilterOptions = ["Active", "Locked", "Inactive"];

  const toggle = () => {
    setModal(!modal);
  };

  useEffect(() => {
    getAllList();
  }, []);

  const getAllList = async () => {
    setLoading(true);

    try {
      const { data, success } = await getArtists();
      if (success) {
        setList(data);
        setAllList(data);
        setTempList(data);
      } else {
        console.log("An Error has occured.");
      }
    } catch (err) {
      console.log(err);
    }

    setLoading(false);
  };

  const removeProvider = id => {
    deleteArtist(id)
      .then(res => {
        setDelete_modal(false);
        getAllList();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onClickDeActivate = id => {
    setDeleteId(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeProvider(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  const handleSearchByName = search => {
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(
        ele =>
          ele?.name?.toLowerCase().includes(search.toLowerCase()) ||
          ele?.title?.toLowerCase().includes(search.toLowerCase())
      );
      setList(filterList);
    } else {
      setList(allList);
    }
  };

  useEffect(() => {
    if (typeFilter?.length > 0) {
      const newList = tempList.filter(
        obj =>
          typeFilter.includes(obj.status) ||
          typeFilter.includes(obj?.adminVerification?.VerificationStatus)
      );
      setList(newList);
    } else {
      setList(tempList);
    }
  }, [typeFilter, tempList]);

  const handleCheckboxChange = text => {
    let newTypeFilter = [...typeFilter];
    let indx = newTypeFilter.indexOf(text);

    if (indx >= 0) {
      newTypeFilter.splice(indx, 1);
    } else {
      newTypeFilter.push(text);
    }
    setTypeFilter(newTypeFilter);
  };

  const checkboxItems = () => {
    return (
      <React.Fragment>
        {typeFilterOptions?.map((option, index) => {
          const isChecked = typeFilter.includes(option);
          return (
            <div className="d-flex me-3 align-items-center" key={index}>
              <Input
                key={option}
                id={option}
                type="checkbox"
                className="form-check-Inpu me-1 cursor-pointer"
                onChange={() => handleCheckboxChange(option)}
                defaultChecked={isChecked}
              />
              <Label
                className="form-check-Label fw-bold mb-0  pt-1  cursor-pointer"
                htmlFor={option}
              >
                {option}
              </Label>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Artists | stylepro - -</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumbs title="Artists" breadcrumbItem="Artist List" />
          <BreadcrumbSub subtile="Artists List" SubBreadcrumbItem="Artist" />

          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                <h5 className="fw-bold">Total Artists: {allList?.length}</h5>
              </div>
              <div className="search-box ms-4 individual_search">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <input
                    className="form-control py-2 bg-white"
                    type="search"
                    id="example-search-input"
                    placeholder="Search by name"
                    onChange={event => {
                      handleSearchByName(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div>
              <Button
                type="button"
                color="primary"
                className=""
                onClick={() => {
                  toggle();
                }}
              >
                <i className="bx bx-user-plus me-1" />
                Create Artist
              </Button>
            </div>
          </div>

          <div className="d-flex mt-3 align-items-center">
            {checkboxItems()}
          </div>
          {list?.length === 0 ? (
            <Card className="mt-3">
              <CardBody>
                <div className="p-5 text-center">
                  <h5>No results found</h5>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card className="bg-light my-3">
              <CardBody className="">
                <Row className="services-card">
                  {list?.map((artist, index) => {
                    let FixedClass = "fw-bold ";
                    let statusClass =
                      artist?.status === "Active"
                        ? FixedClass + "text-success"
                        : artist?.status === "Locked"
                        ? FixedClass + "text-warning"
                        : FixedClass + "text-danger";
                    return (
                      <Col
                        sm={6}
                        md={6}
                        xl={4}
                        xxl={4}
                        key={index}
                        className="mb-1"
                      >
                        <Card className="individual_cards">
                          <CardBody className="">
                            <div className="view_button_1">
                              <Link to={`/artist/${artist._id}`} className="">
                                <img src={fileIcon} alt="" className="me-1" />
                              </Link>
                            </div>
                            {/* <div className="Delete_button mt-1">
                            <Badge
                            role="button"
                            className="font-size-10 badge-soft-danger py-1 px-2 me-1 fw-bold"
                            color="bg-danger"
                            onClick={() => onClickDeActivate(artist?._id)}
                            >
                            <i className="bx bx-trash-alt font-size-12 fw-bold"></i>
                            </Badge>
                            </div> */}
                            <div className="d-flex">
                              <div>
                                <div>
                                  <img
                                    className=""
                                    src={
                                      !artist?.artistImage ||
                                      artist?.artistImage === "undefined"
                                        ? noImage
                                        : artist?.artistImage
                                    }
                                    alt=""
                                    width={100}
                                    height={100}
                                  />
                                </div>
                              </div>
                              <div className="ms-4">
                                <h6 className="fw-bold font-size-16 individual_name text-uppercase">
                                  {artist?.title}
                                </h6>
                                <h6 className="fw-bold">{artist?.name}</h6>
                                <div className="mb-1">
                                  <span className="fw-bold">Status: </span>
                                  <span className={statusClass}>
                                    {artist?.status}
                                  </span>
                                </div>
                                <div className="mt-1">
                                  <span className="">
                                    Since:{" "}
                                    {artist?.createdAt &&
                                      moment(
                                        new Date(artist?.createdAt)
                                      ).format("DD MMM Y")}{" "}
                                  </span>
                                </div>
                                <div>
                                  <div className="rating mt-1">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                  </div>
                                </div>
                                <div className="mt-1 d-flex align-items-center mobile_number">
                                  <i className="bx bxs-phone"></i>{" "}
                                  <span className="fw-bold mb-0 ps-1">
                                    {artist?.mobileNumber}
                                  </span>
                                </div>
                                {/* <div className="mt-1 d-flex align-items-center mobile_number">
                                <i className="bx bxs-envelope"></i>{" "}
                                <span className="fw-bold mb-0 ps-1">
                                {artist?.email}
                                </span>
                                </div> */}
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
      <AddArtist toggle={toggle} modal={modal} reload={getAllList} />
      {/* {delete_modal ? deletePopup() : null} */}
    </React.Fragment>
  );
};

export default IndvidualListNew;
