
export const getIndviduals=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/providers`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Indviduals all ${e}`))  
}


export const createIndvidual=(body)=>{
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/create`,{
        method:"POST",
        headers: {
            Accept: "application/json",
          },
        body:body
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Indvidual ${e}`))  
}

export const getIndvidualById=(Id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/get/${Id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Indvidual ${e}`))  
}

export const updateIndvidual=(Id,body)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/update/${Id}`,{
        method:"PUT",
        headers: {
            Accept: "application/json",
          },
          body:body
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Indvidual ${e}`))  
}

export const updateIndvidualFields=(Id,body)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/updateFields/${Id}`,{
        method:"PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
          body:JSON.stringify(body)
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Indvidual update Fields ${e}`))  
}

export const deleteIndvidual=(Id)=>{
   return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/delete/${Id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Indvidual ${e}`))  
}
export const getIndividualByRegNo=(Id)=>{
   return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/provider/regno/${Id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Indvidual By RegNo. ${e}`))  
}