import ProfileImage from "components/profileImage";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import noImage from "assets/images/PicUpload.jpg";
import moment from "moment";

export const EcomOrderView = ({ modal, toggle, id = false, activeOrder }) => {
  const [Address, setAddress] = useState(null);
  useEffect(() => {
    if (activeOrder) {
      const shippingAddress = activeOrder.shippingaddress;
      const parsedShippingAddress = JSON.parse(shippingAddress);
      setAddress(parsedShippingAddress);
    }
  }, [activeOrder]);

  let DeliveryDate = moment(activeOrder?.orderdate)
    .add(3, "days")
    .format("MMMM Do, YYYY");

  let cartItems =
    activeOrder?.products?.length &&
    activeOrder?.products?.map((data, idx) => {
      return (
        <tr key={idx}>
          <td className="text-center">{idx + 1}</td>
          <td className="text-center">
            {activeOrder?.isWeb === false && data?.type === "Variant"
              ? data?.variantSelectedsku
              : data.sku}
          </td>
          <td className="product-thumbnail text-center">
            <img
              className=""
              src={
                data?.images[0] && data?.images[0].includes("http")
                  ? data?.images[0]
                  : noImage
              }
              alt="item"
              width="65px"
            />
          </td>

          <td className="product-name">
            {data?.name}
            {data?.selectedVariants ? (
              <>
                {", "}
                {activeOrder?.isWeb === true && data?.type === "Variant"
                  ? data?.selectedVariants != ""
                    ? data?.selectedVariants[0].Color
                    : ""
                  : ""}
              </>
            ) : (
              ""
            )}
          </td>

          <td className="product-price text-center">
            <span className="unit-amount ">₹ {data?.price}</span>
          </td>

          <td className="product-quantity text-center">
            <div>
              {data?.cartQuantity ? data?.cartQuantity : data?.quantity}
            </div>
          </td>
          <td className="product-quantity text-center">
            <div>
              ₹{" "}
              {data?.cartQuantity
                ? data?.cartQuantity * data?.price
                : data?.quantity * data?.price}
            </div>
          </td>
        </tr>
      );
    });
  return (
    <>
      {" "}
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}> Order Details</ModalHeader>
        <ModalBody className="bg-light">
          {/* <Spinner loading={loading} /> */}
          <Container fluid>
            <Card className="view-users">
              <CardBody>
                <Row>
                  <Col md={12} className="mb-1 px-0 view_detail">
                    <Row className="mx-3 ps-2">
                      <p className="ps-0">
                        <span className="pe-3 fw-bold h5">
                          {" "}
                          Order No - #{activeOrder.ordercode}{" "}
                        </span>{" "}
                      </p>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>
                    <Row className="mx-3 mb-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Order date</p>
                        <p className="text-muted">
                          {activeOrder?.orderdate
                            ? moment(activeOrder?.orderdate).format(
                                "MMMM Do, YYYY"
                              )
                            : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">
                          {" "}
                          Approximate delivery date
                        </p>
                        <p className="text-muted">{DeliveryDate} </p>
                      </Col>
                    </Row>
                    <Row className="mx-3 my-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Payment Status</p>
                        <p className="text-muted">
                          {activeOrder.paymentstatus
                            ? activeOrder.paymentstatus
                            : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Payment Method</p>
                        <p className="text-muted">
                          {activeOrder.paymentMethod
                            ? activeOrder.paymentMethod
                            : "_"}
                        </p>
                      </Col>
                      {/* <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Delivery Type</p>
                        <p className="text-muted">
                          {activeOrder.deliverytype
                            ? activeOrder.deliverytype
                            : "_"}
                        </p>
                      </Col> */}
                    </Row>
                  </Col>
                </Row>
                {/* <div className=" mt-2 me-3 hr"> </div> */}
                <Row className="mx-2 mb-2 mt-2">
                  <Col>
                    {" "}
                    <div className="text-start delivery_address">
                      <h6 className="fw-bold">Delivery Address</h6>
                      <div className="">
                        <div>
                          <span>
                            {Address?.address}, {Address?.City},&nbsp;
                            {Address?.state},&nbsp;{Address?.zipcode},&nbsp;{" "}
                            {Address?.country}
                          </span>
                        </div>
                        <div>
                          <span>
                            {Address?.apartment ? (
                              <>Apartment name: {Address?.apartment}</>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div>
                          <span>
                            {Address?.area ? (
                              <>Landmark: {Address?.area}</>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                        <div>
                          <span>
                            {Address?.mobilenumber ? (
                              <>Mobile number: {Address?.mobilenumber}</>
                            ) : (
                              ""
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row className="mx-2">
                  <div className=" mt-2 me-3 hr"> </div>

                  <Col md={12} className="mt-4 p-0">
                    <p className="fw-bold mb-1 h5 mb-2">Products</p>
                    <table className="table table-bordered table-white align-middle">
                      <thead className="text-center bg-light">
                        <tr>
                          <th>Item No</th>
                          <th>SKU #</th>
                          <th scope="col">Product</th>
                          <th scope="col">Product Name</th>
                          <th scope="col">Unit Price</th>
                          <th scope="col">Quantity</th>
                          <th scope="col">Total</th>
                        </tr>
                      </thead>
                      <tbody>{cartItems}</tbody>
                    </table>
                  </Col>
                </Row>

                <Row className="mt-3 mx-2">
                  <p className="fw-bold mb-0 h5 p-0"> Transaction Summary</p>

                  <Col md="12" className="p-0">
                    {/* <ul>
                      <li className="sub_total">
                        Subtotal{" "}
                        <span>
                          ₹{parseInt(activeOrder?.total?.subtotal)?.toFixed(2)}
                        </span>
                      </li>

                      <li className="Sub_amount">
                        Shipping{" "}
                        <span>
                          {activeOrder?.total?.shipping === "0" ? (
                            "Free"
                          ) : (
                            <>{activeOrder?.total?.shipping}</>
                          )}
                        </span>
                      </li>
                      <li className="amount-paid">
                        <b className="text-dark">Amount Paid</b>
                        <span className="font-size-18">
                          ₹{parseInt(activeOrder?.total?.total)?.toFixed(2)}
                        </span>
                      </li>
                    </ul> */}
                    <table className="table table-bordered table-white align-middle mt-3">
                      <thead className="bg-light">
                        <tr>
                          <th scope="col">Subtotal</th>
                          <th scope="col">Shipping</th>
                          <th scope="col">Amount Paid</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="product-name">
                            {" "}
                            ₹
                            {parseFloat(activeOrder?.total?.subtotal)?.toFixed(
                              2
                            )}
                          </td>

                          <td className="product-price ">
                            <span className="unit-amount">
                              {activeOrder?.total?.shipping === "0" ? (
                                "Free"
                              ) : (
                                <>₹ {activeOrder?.total?.shipping}</>
                              )}
                            </span>
                          </td>

                          <td className="product-quantity">
                            <div>
                              ₹{" "}
                              {parseFloat(activeOrder?.total?.total)?.toFixed(
                                2
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>{" "}
    </>
  );
};
export default EcomOrderView;
