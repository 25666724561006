import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "../../components/profileImage";

import {
  createParentCategory,
  getParentCategory,
  updateParentCategory,
} from "services/parentcategory.service";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const defaultValues = {
  name: "",
  type: "",
  image: "",
  order: "",
};

const CreateParentcategory = ({ toggle, modal,id=false }) => {
  // const { id } = useParams();

  const [selectedType, setSelectedType] = useState(null);

  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Kids", value: "Kids" },
  ];

  const initialValues = defaultValues;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    type: Yup.string().required("Please Select Type"),
  });
  const onSubmit = values => {
    if (id) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const { image } = values;

  function handleSelectType(event) {
    setValues({ ...values, ["type"]: event.value });
    setSelectedType(event);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    createParentCategory(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    updateParentCategory(id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    props.history.push("/parentcategory-list");
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Parent Category created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>{
                id ? "Edit Parent Category" : "Create Parent Category"
              }</ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid={false}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    setFieldTouched,
                    values,
                  }) => {
                    useEffect(() => {
                      if (id) {
                        setLoading(true);
                        getParentCategory(id)
                          .then(res => {
                            const data = { ...res.data };
                            const fields = ["name", "order", "image"];
                            fields.forEach(field =>
                              setFieldValue(field, data[field], false)
                            );
                            setFieldValue("type", data.type, false);
                            setSelectedType({
                              value: data.type,
                              label: data.type,
                            });
                            setLoading(false);
                          })
                          .catch(e => {
                            console.log(e);
                          });
                      }
                    }, []);

                    return (
                      <Form>
                        <Row className="mt-4">
                          <Col md={6} className="px-4">
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">Name</Label>
                                <Input
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  onChange={handleChange}
                                  // onBlur={validation.handleBlur}
                                  value={values.name}
                                  invalid={
                                    touched.name && errors.name ? true : false
                                  }
                                />

                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                    {touched.name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="type">Type</Label>
                                <Select
                                  // className="form-control"
                                  id="type"
                                  value={selectedType}
                                  onChange={event => {
                                    handleSelectType(event);
                                    handleChange("type")(event.value);
                                  }}
                                  name="type"
                                  options={typeList}
                                  classNamePrefix="select2-selection"
                                />
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.type ? errors.type : ""}
                                </Label>
                              </FormGroup>
                            </Row>
                          </Col>
                          <Col md={6} className="px-4">
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Order
                                </Label>
                                <Input
                                  name="order"
                                  placeholder="Order"
                                  type="text"
                                  className="form-control"
                                  id="order"
                                  onChange={handleChange}
                                  // onBlur={validation.handleBlur}
                                  value={values.order}
                                />

                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                    {touched.name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Row>
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom06">
                                  Image
                                </Label>
                                <Dropzone
                                  id="validationCustom06"
                                  onDrop={acceptedFiles => {
                                    acceptedFiles.map(file =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        formattedSize: formatBytes(file.size),
                                      })
                                    );
                                    setFieldValue("image", acceptedFiles[0]);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone image_drop">
                                      <div
                                        className="dz-message needsclick "
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="">
                                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h6>
                                          Drop files here or click to upload.
                                        </h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.image ? errors.image : ""}
                                </Label>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {values.image &&
                                    Object.keys(values.image).length > 0 && (
                                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={values?.image}
                                                height="80"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {values.image.name ||
                                                  values.image.filename}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {values.image.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    )}
                                </div>
                              </FormGroup>
                            </Row>
                          </Col>
                        </Row>
                        <hr></hr>
                        <div className="d-flex justify-content-end">
                        <Button
                            color="light"
                            className="w-md btn-sm me-3"
                            onClick={leaveToList}
                          >
                            cancel
                          </Button>
                          {!id ? (
                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm"
                              // onClick={saveChanges}
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              className="w-md btn-sm"
                              type="submit"
                              // onClick={editChanges}
                            >
                              Save Changes
                            </Button>
                          )}
                          
                        </div>
                      </Form>
                    );
                  }}
                </Formik>

                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateParentcategory;
