import React from "react";
import { getParentCategories } from "services/parentcategory.service";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Badge } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import {
  getParentCategory,
  deleteParentCategory,
} from "services/parentcategory.service";
import { getCategoryTypeandParent } from "services/category.service";
import CreateParentcategory from "pages/ParentCategory/create";
import { useParams } from "react-router";
import MultipleCheckbox from "components/MutlpleCheckbox";
import ProfileImage from "./../../components/profileImage";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateCategory from "pages/Categories/create";
import ViewCategory from "pages/Categories/ViewCategory";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const ParentCategoryNew = props => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState([]);
  const [subCatList, setSubCatList] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalSub, setModalSub] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [activeId, setActiveId] = useState(false);
  const [activeSubId, setActiveSubId] = useState(false);
  const [activeViewId, setActiveViewId] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [allList, setAllList] = useState([]);
  const [tempList, setTempList] = useState([]);
  const [typeFilter, setTypeFilter] = useState([]);
  const typeFilterOptions = ["Male", "Female", "Kids"];

  const handleSearch = search => {
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(ele =>
        ele?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setList(filterList);
    } else {
      setList(allList);
    }
  };

  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };
  const toggleSub = () => {
    if (modalSub) {
      setActiveSubId(null);
    }
    setModalSub(!modalSub);
  };
  const toggleView = () => {
    if (modalView) {
      setActiveViewId(null);
    }
    setModalView(!modalView);
  };
  useEffect(() => {
    getParentCatList();
    getCataegories();
  }, []);

  const getParentCatList = () => {
    getParentCategories()
      .then(res => {
        let { data } = res;
        setList(data);
        setTempList(data);
        setAllList(data);
        if (res.data[0]) {
          setParentValues(res.data[0], res.data[0]._id);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getParentCategoriesById = id => {
    setLoading(true);
    getParentCategory(id)
      .then(res => {
        if (res.data) {
          setParentValues(res.data, id);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const setParentValues = (data, id) => {
    let { name, type, image, createdAt, updatedAt, _id } = data;
    let obj = { name, type, image, createdAt, updatedAt, _id };
    setValues({ ...obj });
    setLoading(false);
    let body = {
      type: type,
      parentCategory: id,
    };
    getCataegories(body);
  };

  const handleService = (ele, index) => {
    getParentCategoriesById(ele._id);
  };

  const getCataegories = body => {
    getCategoryTypeandParent(body)
      .then(res => {
        if (res.data) {
          setSubCatList(res.data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const addParentCategory = () => {
    props.history.push("/parentcategory-create");
  };
  // const leaveToList = () => {
  //   props.history.push("/parentcategory-new")
  // }

  //delete
  const removeParentCat = id => {
    setLoading(true);
    deleteParentCategory(id)
      .then(res => {
        setDelete_modal(false);
        getParentCatList();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeParentCat(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const renderCategoryList = () => {
    return (
      <CardBody className="parentCat_view">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">SUB CATEGORIES</h5>
          {/* <Link to="/category-create"> */}
          <Button
            type="button"
            color="primary"
            className="btn-rounded btn-md"
            onClick={toggleSub}
          >
            <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
            Add Sub Category
          </Button>
          {/* </Link> */}
        </div>
        <div className=" my-3 me-3 hr"> </div>
        {subCatList && subCatList.length > 0 ? (
          <Row className="mt-2">
            {subCatList.map((cat, index) => {
              return (
                <Col key={index} md="3">
                  <Card className="shadow-sm mt-3">
                    <CardBody>
                      <div className="text-center">
                        <ProfileImage
                          image={cat.image}
                          classNames="rounded-circle avatar-md"
                        />
                        <h5 className="my-2">{cat.name}</h5>
                        <p className="category_status">
                          <span
                            className={
                              "status_dot " +
                              (cat.status == "Active" ? "active" : "inactive")
                            }
                          ></span>
                          <span className="ms-3">{cat.status}</span>
                        </p>
                      </div>
                      <div className=" my-2 hr"> </div>
                      <div className="d-flex justify-content-between pt-1">
                        {/* <Link
                          to={`/category-update/${cat._id}`}
                          className="w-50 pe-2"
                        > */}
                        <input
                          type="button"
                          value="Edit"
                          className="w-100 btn btn-sm btn-outline-light me-3"
                          onClick={() => {
                            setActiveSubId(cat._id);
                            toggleSub();
                          }}
                        />
                        {/* </Link> */}
                        {/* <Link
                          to={`/category-view/${cat._id}`}
                          className="w-50 ps-2"
                        > */}
                        <input
                          type="button"
                          value="View"
                          className="w-100 btn btn-sm btn-outline-primary"
                          onClick={() => {
                            setActiveViewId(cat._id);
                            toggleView();
                          }}
                        />
                        {/* </Link> */}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              );
            })}
          </Row>
        ) : (
          <div className="text-center">
            <p className="mt-4 mb-2"> No data found</p>
          </div>
        )}
      </CardBody>
    );
  };
  const renderParentView = () => {
    return (
      <div>
        <div className="text-end mb-3">
          <Button type="button" color="primary" className="" onClick={toggle}>
            <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
            Add Parent Category
          </Button>
        </div>
        <Card className="view-users parentCat_view">
          <CardBody>
            {!values && values.length < 0 && (
              <div className="empty_list p-3 pt-0">
                <div className="text-center">
                  <i className="mdi mdi-arrow-left-circle" id=""></i>
                </div>
                <p className="text-center">Please Select an Order</p>
              </div>
            )}
            {values && (
              <div>
                <div className="d-flex float-end">
                  <div className="">
                    {/* <Link
                      className="text-end"
                      to={`/parentcategory-update/${values._id}`}
                    > */}
                    <Badge
                      className="font-size-12 badge-soft-warning py-1 px-3 me-2"
                      color={"bg-warning"}
                      pill
                      onClick={() => {
                        setActiveId(values._id);
                        toggle();
                      }}
                    >
                      <i className="mdi mdi-pencil font-size-14" /> Edit
                    </Badge>
                    {/* </Link> */}
                  </div>
                  <div>
                    <Badge
                      className="font-size-12 badge-soft-danger py-1 px-3"
                      color={"bg-warning"}
                      onClick={() => onClickDelete(values._id)}
                      style={{ cursor: "pointer" }}
                      pill
                    >
                      <i className="mdi mdi-delete font-size-14" />
                      Delete
                    </Badge>
                  </div>
                </div>
                <Row>
                  <Col md={4} className="">
                    <div className="text-center">
                      <img
                        src={values.image}
                        alt=""
                        className="img-thumbnail profile rounded-circle"
                      />
                      <h5 className="mt-4 text-primary"></h5>
                    </div>
                  </Col>
                  <Col md={8} className=" pt-4 mb-4 px-0 view_detail">
                    <Row className="mx-3 ps-2">
                      <p className="ps-0">
                        <span className="pe-3 fw-bold"> Name </span>{" "}
                        <span className="text-muted">{values.name} </span>
                      </p>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>
                    <Row className="mx-3 my-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Type</p>
                        <p className="text-muted">{values?.type}</p>
                      </Col>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>
                    <Row className="mx-3 my-2">
                      <Col md={6} className="mt-4">
                        <p className="fw-bold mb-1">Created</p>
                        <p className="text-muted">
                          {moment(
                            values.createdAt ? values.createdAt : "_"
                          ).format("MMM Do YYYY")}
                        </p>
                      </Col>
                      <Col md={6} className="mt-4">
                        <p className="fw-bold mb-1">Last Updated</p>
                        <p className="text-muted">
                          {moment(
                            values.updatedAt ? values.updatedAt : "_"
                          ).format("MMM Do YYYY")}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            )}
          </CardBody>
        </Card>
        <Card> {renderCategoryList()}</Card>
      </div>
    );
  };

  //checkbox.......//
  const handleCheckboxChange = text => {
    setLoading(true);
    let indx = typeFilter.indexOf(text);
    if (indx >= 0) {
      typeFilter.splice(indx, 1);
    } else {
      typeFilter.push(text);
    }
    if (typeFilter.length > 0) {
      let newList = _.filter(tempList, function (obj) {
        if (typeFilter.includes(obj.type)) {
          return obj;
        }
      });
      setList(newList);
    } else {
      setList(tempList);
    }
    setTypeFilter(typeFilter);
    setLoading(false);
  };

  const checkboxItems = () => {
    return (
      <React.Fragment>
        {typeFilterOptions.map(option => (
          <MultipleCheckbox
            key={option}
            text={option}
            handleOnChange={text => handleCheckboxChange(text)}
          ></MultipleCheckbox>
        ))}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Parent Category"
            breadcrumbItem="Parent Categories List"
          />
          <BreadcrumbSub subtile="Parent Categories" SubBreadcrumbItem="Parent category" />

          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="search-box me-2 ">
                    <div className="position-relative">
                      <i className="bx bx-search-alt search-icon" />
                      <input
                        className="form-control py-2 rounded-pill bg-white "
                        type="search"
                        id="example-search-input"
                        placeholder=" Search "
                        onChange={event => handleSearch(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="d-flex  justify-content-between ps-3 pe-3 pt-3">
                    {checkboxItems()}
                  </div>
                </CardBody>
                {list &&
                  list.map((ele, index) => (
                    <CardBody
                      className="border border-bottom-1"
                      key={ele._id}
                      onClick={() => {
                        handleService(ele, index);
                      }}
                      role="button"
                    >
                      <Row>
                        <Col md="2">
                          <div>
                            <img
                              src={ele.image}
                              height="50"
                              alt=""
                              className="fit-content rounded-circle avatar-sm "
                            />
                          </div>
                        </Col>
                        <Col md="8">
                          <div className="ms-2">
                            <h6>{ele.name}</h6>

                            <small className="text-secondary">
                              <b>Created : </b>
                              {moment(ele.createdAt).format("MMM Do YYYY")}
                            </small>
                            <br />
                            <small className="text-secondary">
                              <b>Updated : </b>
                              {moment(ele.updatedAt).format("MMM Do YYYY")}
                            </small>
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="text-secondary float-end">
                            <Badge
                              className={
                                ele.type == "Female"
                                  ? " badge-soft-info "
                                  : " badge-soft-warning "
                              }
                              color={
                                ele.type == "Female" ? "bg-info" : "bg-warning"
                              }
                              pill
                            >
                              {ele.type}
                            </Badge>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  ))}
              </Card>
            </Col>
            <Col md="9">{renderParentView()}</Col>
          </Row>
        </Container>
      </div>
      <div>
        {modal && (
          <CreateParentcategory toggle={toggle} modal={modal} id={activeId} />
        )}
        {modalSub && (
          <CreateCategory
            toggle={toggleSub}
            modal={modalSub}
            id={activeSubId}
          />
        )}
        {modalView && (
          <ViewCategory
            toggle={toggleView}
            modal={modalView}
            id={activeViewId}
          />
        )}
      </div>
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};
export default ParentCategoryNew;
