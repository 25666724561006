export const getRatingsByOrderId=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/serviceReview/byorder/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at Get Revies ${e}`))  
};