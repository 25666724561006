import {
GET_PROVIDERS,GET_PROVIDERS_FAIL,GET_PROVIDERS_SUCCESS
} from "./actionTypes"

export const getProviders = () => ({
  type: GET_PROVIDERS,
})

export const getProvidersSuccess = Providers => ({
  type: GET_PROVIDERS_SUCCESS,
  payload: Providers,
})


export const getProvidersFail = error => ({
  type: GET_PROVIDERS_FAIL,
  payload: error,
})
