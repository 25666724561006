import React from "react";
import "./Styles.scss";
import Variants from "./variants";
import { useState, useEffect } from "react";
import { Button, Card, Row, Col, CardBody, CardTitle } from "reactstrap";
import Dropzone from "react-dropzone";
import { Form, FormGroup, Label, Input } from "reactstrap";
import { UncontrolledCollapse } from "reactstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import banner from "./PicUpload.jpg";
import Select, { components } from "react-select";
import { getSubCategory } from "services/ecommerce/category.service";
import ProfileImage from "components/profileImage";
import { Link } from "react-router-dom";
import formatBytes from "utils/formatBytes";
import { getProducts } from "services/ecommerce/product.service";
import { element } from "prop-types";
import CkEditor from "./CkEditor";

const groupStyles = {
  border: `2px dotted red}`,
  borderRadius: "5px",
  background: "#f2fcff",
};
const Group = props => (
  <div style={groupStyles}>
    <components.Group {...props} />
  </div>
);

const ProductsCreate1 = ({
  handleChangeProperties,
  val,
  selectedCat,
  id,
  handleUpdateMulti,
}) => {
  const [values, setValues] = useState(val);

  const [categoriesList, setCategoriesList] = useState(null);
  const [productType, setProductType] = useState("simple");
  const [productslist, setProductlist] = useState([]);
  const [selectedRelated, setSelectedRelated] = useState(null);
  const [selectedCross, setSelectedCross] = useState(null);
  const initKeywords = val?.keyWords;
  const [input, setInput] = useState("");
  const [keys, setKeys] = useState([...initKeywords]);

  useEffect(() => {
    getSubCategoriesList();
    getProductsList();
  }, []);

  useEffect(() => {
    if (val) {
      setValues(val);
      setKeys(val?.keyWords);
      setProductType(val.type);
    }
  }, [id, val]);

  useEffect(() => {
    const productsRelated = [];
    val?.relatedProducts?.map(e => {
      if (e) {
        productsRelated.push({ label: e.name, value: e._id });
      }
    });
    setSelectedRelated(productsRelated);
    const cross = [];
    val?.crossSelingProducts &&
      val.crossSelingProducts.map(e => {
        if (e) {
          cross.push({ label: e.name, value: e._id });
        }
      });
    setSelectedCross(cross);
  }, [id]);

  const getSubCategoriesList = () => {
    getSubCategory()
      .then(res => {
        if (res?.data) {
          const result = _(res.data)
            .groupBy(x => x?.parentCategory?.name)
            .map((value, key) => ({ label: key, options: value }))
            .filter(ele => {
              ele.options = ele.options.filter(opt => {
                opt["label"] = opt.name;
                opt["value"] = opt._id;
                return opt;
              });
              return ele;
            })
            .value();
          setCategoriesList(result);
        } else {
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getProductsList = () => {
    getProducts()
      .then(res => {
        if (res.data) {
          const data = [];
          res.data.map(ele => {
            data.push({ label: ele.name, value: ele._id });
          });
          setProductlist(data);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    const images = values?.images || [];

    const imgArray = [...images];
    imgArray.push(files[0]);

    handleChangeProperties("images", imgArray);
  }

  //Updating State values
  const handleUpdateState = (property, value) => {
    const tempValues = { ...values };
    tempValues[property] = value;
    setValues(tempValues);
  };

  function removeImage(index) {
    let imgArray = [...values?.images];
    imgArray.splice(index, 1);
    handleChangeProperties("images", imgArray);
  }

  const handleCatChange = event => {
    handleChangeProperties("category", event);
  };

  const handleProductType = event => {
    setProductType(event.target.value);
    handleChangeProperties("type", event.target.value);
  };

  let handleOnChange = (i, e, type) => {
    let newValues = [...values[type]];
    if (type == "description") {
      newValues[i]["name"] = e.target.value;
      handleChangeProperties(type, newValues);
    } else newValues[i][e.target.name] = e.target.value;
    handleChangeProperties(type, newValues);
  };

  let handleOnEditorChange = (i, e, type, name) => {
    let newValues = [...values[type]];
    newValues[i][name] = e;
    handleChangeProperties(type, newValues);
  };

  const handleClick = () => {
    let keysList = [...keys, input];
    setInput("");
    handleChangeProperties("keyWords", keysList);
  };

  const handleDelete = index => {
    const newKeys = [...keys];
    newKeys.splice(index, 1);
    setKeys(newKeys);
  };

  let addFields = type => {
    if (type === "faqs") {
      let newValues = [...values.faqs];
      newValues.push({
        question: "",
        answer: "",
      });
      handleChangeProperties("faqs", newValues);
    } else {
      let newValues = [...values.properties];
      newValues.push({
        name: "",
        value: "",
      });
      handleChangeProperties("properties", newValues);
    }
  };

  let removeFields = (i, type) => {
    if (type === "faqs") {
      let newValues = [...values.faqs];
      newValues.splice(i, 1);
      handleChangeProperties("faqs", newValues);
    } else if (type === "description") {
      let newValues = [...values.description];
      newValues.splice(i, 1);
      handleChangeProperties("description", newValues);
    } else {
      let newValues = [...values.properties];
      newValues.splice(i, 1);
      handleChangeProperties("properties", newValues);
    }
  };

  function handleRelated(selectedMulti) {
    const data = [];
    selectedMulti?.forEach(e => {
      data.push(e.value);
    });
    setSelectedRelated(selectedMulti);
    setValues({ ...values, ["relatedProducts"]: data });
    handleChangeProperties("relatedProducts", selectedMulti);
  }

  function handleCross(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setSelectedCross(selectedMulti);
    setValues({ ...values, ["crossSelingProducts"]: data });
    handleChangeProperties("crossSelingProducts", selectedMulti);
  }

  const calculateDiscount = (discountType, value) => {
    const newValues = {};
    if (discountType === "percentageDiscount") {
      newValues["percentageDiscount"] = value;
      // calculate Flat Discount, Our price
      const ourPrice = (values.mrp - values.mrp * (value / 100)).toFixed(2);
      newValues["price"] = ourPrice;
      newValues["flatDiscount"] = values.mrp - newValues.price;
    } else {
      newValues["flatDiscount"] = value;
      // calculate Percentage Discount, Our price
      newValues["price"] = (values.mrp - value).toFixed(2);
      newValues["percentageDiscount"] =
        ((values.mrp - newValues.price) / values.mrp) * 100;
    }
    handleUpdateMulti(newValues);
  };

  const handleMRP = val => {
    const newValues = {
      flatDiscount: 0,
      percentageDiscount: 0,
      price: val,
      mrp: val,
    };

    handleUpdateMulti(newValues);
  };

  const addShortDescription = (type, event) => {
    event.preventDefault();

    let newValues = [...values.description];
    newValues.push({
      name: "",
      value: "",
    });
    handleChangeProperties(type, newValues);
  };

  return (
    <div>
      <Card>
        <CardBody>
          <div className="mb-3">
            <Row>
              <Col>
                <FormGroup>
                  <Label for="category">
                    Category <span className="text-danger">*</span>
                  </Label>
                  <Select
                    id="category"
                    defaultValue={selectedCat || {}}
                    options={categoriesList || []}
                    components={{ Group }}
                    onChange={event => handleCatChange(event)}
                  />
                </FormGroup>
              </Col>
              <Col>
                <FormGroup>
                  <Label for="name">
                    Product Title <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Name"
                    value={values?.name}
                    onChange={event => {
                      handleChangeProperties("name", event.target.value);
                      handleUpdateState("name", event.target.value);
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <div className="mb-3">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="text-primary">Content(Long description)</h6>
              <div className="">
                <Button
                  color="primary"
                  id="toggler00"
                  className="btn-sm w-100 "
                >
                  Show/Hide Editor
                </Button>
              </div>
            </div>
            <div>
              <UncontrolledCollapse toggler="#toggler00" defaultOpen="true">
                <Card>
                  <CardBody className="p-0">
                    <CKEditor
                      editor={ClassicEditor}
                      data=""
                      onReady={editor => {
                        editor.setData(values?.content);
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChangeProperties("content", data);
                      }}
                    />
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </div>
          </div>

          <div className="mt-3">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <h6 className="text-primary mb-0">
                Description(Short Description)
              </h6>
            </div>

            <Card>
              <CardBody className="p-0 my-2">
                <div>
                  {values?.description?.map((data, index) => {
                    return (
                      <div key={index} className="mt-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="col-md-6 d-flex mb-2">
                            <div className="border border-1 border-secondary rounded px-3 py-2 me-2">
                              <p className="p-0 m-0 fw-bold fs-6">
                                {index + 1}
                              </p>
                            </div>

                            <input
                              name="name"
                              id="name"
                              className="form-control"
                              type="text"
                              placeholder="Description Title"
                              value={data.name || ""}
                              onChange={e =>
                                handleOnChange(index, e, "description")
                              }
                            />
                          </div>
                          <div className="mb-3 float-end d-flex">
                            <Button
                              color="danger"
                              // id={`toggler${index}`}
                              className="btn-sm  me-2 "
                              onClick={() => removeFields(index, "description")}
                            >
                              <i className="bx bx-trash-alt fs-5"></i>
                            </Button>
                            <Button
                              color="primary"
                              id={`toggler${index}`}
                              className="btn-sm w-100 "
                            >
                              Show/Hide Editor
                            </Button>
                          </div>
                        </div>
                        <UncontrolledCollapse
                          toggler={`#toggler${index}`}
                          defaultOpen="false"
                        >
                          <CKEditor
                            name="value"
                            id="value"
                            editor={ClassicEditor}
                            data={data?.value}
                            onChange={(event, editor) => {
                              const data = editor.getData();

                              // setText(data);
                              handleOnEditorChange(
                                index,
                                data,
                                "description",
                                "value"
                              );
                            }}
                            //  onChange={e => handleOnChange(index, e, "properties")}
                          />
                        </UncontrolledCollapse>
                      </div>
                    );
                  })}
                  <button
                    className="btn text-white bg-primary w-sm btn-sm mt-2"
                    onClick={e => {
                      addShortDescription("description", e);
                    }}
                  >
                    Add
                  </button>
                </div>
              </CardBody>
            </Card>
          </div>
        </CardBody>
      </Card>
      <Card>
        <div className="d-flex justify-content-between border border-bottom-3 ">
          <CardTitle>
            <h6 className="ms-3 mt-3 ">Product Properties</h6>
          </CardTitle>
          <div>
            <button
              type=""
              className="addNew-btn btn btn-md me-2 mt-2 text-info"
              onClick={() => {
                addFields("properties");
              }}
            >
              Add Property
            </button>
          </div>
        </div>
        <CardBody>
          {values?.properties?.map((prop, index) => (
            <Row className="" key={index}>
              <Col md={5}>
                <Form>
                  <FormGroup>
                    <Label for="" className="">
                      Property Name<span>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      rows="1"
                      value={prop.name || ""}
                      onChange={e => handleOnChange(index, e, "properties")}
                    />
                  </FormGroup>
                </Form>
              </Col>
              <Col md={5}>
                <Form>
                  <FormGroup>
                    <Label for="">
                      value<span>*</span>
                    </Label>
                    <Input
                      type="text"
                      name="value"
                      id="value"
                      rows="1"
                      value={prop.value || ""}
                      onChange={e => handleOnChange(index, e, "properties")}
                    />
                  </FormGroup>
                </Form>
              </Col>
              <Col md={1} className="mt-4">
                {" "}
                <i
                  style={{
                    cursor: "pointer",
                  }}
                  className="mdi mdi-close-thick font-size-18 text-danger float-end"
                  id="removeImgtooltip"
                  onClick={() => removeFields(index, "properties")}
                />
              </Col>
            </Row>
          ))}
        </CardBody>
      </Card>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 tect-primary ">Product Type</h6>
        </CardTitle>
        <CardBody>
          <Label for="product_type">Product Type</Label>
          <Input
            type="select"
            name="product_type"
            id="product_type"
            value={values.type}
            onChange={event => handleProductType(event)}
          >
            <option value="simple">Simple Product</option>
            <option value="variant">Variant Product</option>
          </Input>
        </CardBody>
      </Card>
      {productType === "simple" ? (
        <Card>
          <CardTitle className=" border border-bottom-3">
            <h6 className="ms-3 mt-3 tect-primary ">Images</h6>
          </CardTitle>
          <CardBody>
            <div className="border-dashed1">
              <Dropzone
                onDrop={acceptedFiles => {
                  handleAcceptedFiles(acceptedFiles);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone ser_img_drop">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="">
                        <img
                          src={banner}
                          className=" mx-auto d-block mt-4 mb-4"
                          alt=""
                          height={150}
                          width={150}
                        />
                      </div>
                      <h6>upload image</h6>
                    </div>
                  </div>
                )}
              </Dropzone>
              <div className="dropzone-previews row" id="file-previews">
                {values?.images &&
                  values?.images.length > 0 &&
                  values?.images.map((img, index) => {
                    return (
                      <div
                        key={index}
                        className="col-md-4 mt-1"
                        style={{
                          position: "relative",
                        }}
                      >
                        <div className="border">
                          <div className="p-2">
                            <Row className="align-items-center">
                              <Col className="col-auto">
                                <ProfileImage
                                  image={img}
                                  height="80"
                                  classNames="avatar-sm rounded bg-light"
                                />
                              </Col>
                              <Col>
                                <Link
                                  to="#"
                                  className="text-muted font-weight-bold"
                                >
                                  {img.name || img.filename}
                                </Link>
                                <p className="mb-0">
                                  <strong>{img.formattedSize}</strong>
                                </p>
                              </Col>
                            </Row>
                          </div>
                          <i
                            style={{
                              position: "absolute",
                              right: "16px",
                              cursor: "pointer",
                              top: 0,
                            }}
                            className="mdi mdi-close-thick font-size-18 text-danger"
                            id="removeImgtooltip"
                            onClick={() => removeImage(index)}
                          />
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </CardBody>
        </Card>
      ) : null}

      {productType === "simple" ? (
        <Card>
          <CardTitle className=" border border-bottom-3">
            <h6 className="ms-3 mt-3 tect-primary ">Overview</h6>
          </CardTitle>
          <CardBody>
            <Form>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="sku">SKU</Label>
                    <Input
                      id="sku"
                      name="sku"
                      placeholder=""
                      type="text"
                      value={values?.sku}
                      disabled={true}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="mrp">MRP</Label>
                    <Input
                      id="mrp"
                      name="mrp"
                      placeholder="₹"
                      type="number"
                      value={values?.mrp || ""}
                      onChange={event => {
                        handleMRP(event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <Label for="price" className="">
                    Our Price
                  </Label>
                  <Input
                    type="number"
                    name="price"
                    id="price"
                    placeholder="₹"
                    value={values.price}
                    disabled
                  />
                </Col>
              </Row>
              <Row>
                <Col md={4}>
                  <FormGroup>
                    <Label for="tax">Tax (%)</Label>
                    <Input
                      id="tax"
                      name="tax"
                      type="number"
                      placeholder="%"
                      value={values.tax}
                      onChange={event =>
                        handleChangeProperties("tax", event.target.value)
                      }
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="discount">Flat Discount</Label>
                    <Input
                      id="flatDiscount"
                      name="flatDiscount"
                      type="number"
                      placeholder="₹"
                      value={values.flatDiscount}
                      onChange={event => {
                        calculateDiscount("flatDiscount", event.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="discount">Percentage Discount (%)</Label>
                    <Input
                      id="percentageDiscount"
                      name="percentagediscount"
                      type="number"
                      placeholder="%"
                      value={values.percentageDiscount}
                      onChange={event => {
                        calculateDiscount(
                          "percentageDiscount",
                          event.target.value
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>

            <div>
              <FormGroup>
                <Label for="exampleSelect">Unit Type</Label>
                <Input
                  id=""
                  name="select"
                  type="select"
                  value={values.unit}
                  onChange={event =>
                    handleChangeProperties("unit", event.target.value)
                  }
                >
                  <option value="kg">Kg</option>
                  <option value="gms">Gms</option>
                  <option value="ltrs">Ltrs</option>
                  <option value="pc">Pc</option>
                </Input>
              </FormGroup>
            </div>
            <div className="mt-4">
              <Form>
                <Row>
                  <Col md="4">
                    <FormGroup>
                      <Label for="TotalQuantity">Total Quantity</Label>
                      <Input
                        id="totalQuantity"
                        name="totalQuantity"
                        type="number"
                        placeholder="₹"
                        value={values.totalQuantity}
                        onChange={event =>
                          handleChangeProperties(
                            "totalQuantity",
                            event.target.value
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  <Col md="4">
                    <FormGroup>
                      <Label for="minimumOrderQuantity">
                        Minimum Order Quantity
                      </Label>
                      <Input
                        id="minimumOrderQuantity"
                        name="minimumOrderQuantity"
                        type="number"
                        placeholder="0"
                        value={values.minimumOrderQuantity}
                        onChange={event =>
                          handleChangeProperties(
                            "minimumOrderQuantity",
                            event.target.value
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                  {values.shippingMultiplyWithQuantity === true ? (
                    <Col md="4">
                      <FormGroup>
                        <Label for="shippingCost">Shipping Cost</Label>
                        <Input
                          id="shippingCost"
                          name="shippingCost"
                          type="number"
                          placeholder="0"
                          value={values.shippingCost * values.totalQuantity}
                          onChange={event =>
                            handleChangeProperties(
                              "shippingCost",
                              event.target.value
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  ) : (
                    <Col md="4">
                      <FormGroup>
                        <Label for="shippingCost">Shipping Cost</Label>
                        <Input
                          id="shippingCost"
                          name="shippingCost"
                          type="number"
                          placeholder="0"
                          value={values.shippingCost}
                          onChange={event =>
                            handleChangeProperties(
                              "shippingCost",
                              event.target.value
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                  )}

                  <Col md="12" className="mt-3 ">
                    <FormGroup switch>
                      <Label for="" className="ms-2 float-end">
                        Shipping Cost Multiply With Quantity
                      </Label>
                      <Input
                        className="float-end"
                        type="switch"
                        role="switch"
                        defaultChecked={values.shippingMultiplyWithQuantity}
                        onChange={event =>
                          handleChangeProperties(
                            "shippingMultiplyWithQuantity",
                            event.target.checked
                          )
                        }
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
            </div>
          </CardBody>
        </Card>
      ) : (
        <>
          <Variants
            handleChangeProperties={handleChangeProperties}
            data={values.attributes}
            handleUpdateMulti={handleUpdateMulti}
          />
        </>
      )}
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary">Related Products</h6>
        </CardTitle>
        <CardBody>
          <div className="">
            <Form>
              <FormGroup>
                <Label for="exampleSelect">Related Products</Label>
                <Select
                  id="related"
                  value={selectedRelated}
                  isMulti={true}
                  onChange={event => {
                    handleRelated(event, "relatedProducts");
                    const data = [];
                    event.map(e => {
                      data.push(e.value);
                    });
                  }}
                  options={productslist}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Form>
          </div>
          <div className=" mt-5">
            <Form>
              <FormGroup>
                <Label for="exampleSelect">cross-seling Products</Label>
                <Select
                  id="cross"
                  value={selectedCross}
                  isMulti={true}
                  onChange={event => {
                    handleCross(event, "crossSelingProducts");
                    const data = [];
                    event.map(e => {
                      data.push(e.value);
                    });
                  }}
                  options={productslist}
                  classNamePrefix="select2-selection"
                />
              </FormGroup>
            </Form>
          </div>
        </CardBody>
      </Card>
      <Card>
        <CardTitle>
          <div className="d-flex justify-content-between align-items-center  border border-bottom-3 py-2">
            <CardTitle>
              <h6 className="ms-3 mt-3 ">Keywords</h6>
            </CardTitle>
            <div className=" d-flex justify-content-between align-items-center">
              <Input
                value={input}
                onInput={e => setInput(e.target.value)}
                placeholder="Enter Keyword"
              />
              <button
                type=""
                className="addNew-btn btn btn-md me-2 mt-2 text-info"
                onClick={() => handleClick()}
              >
                Add
              </button>
            </div>
          </div>
        </CardTitle>{" "}
        <CardBody>
          <div>
            <ul className="d-flex flex-wrap mx-0 px-0">
              {keys.map((ele, ind) => {
                return (
                  <div className="" key={ind}>
                    <span className="me-3 ">
                      <span className=" h6 border border-1 bg-light py-1 px-2 ">
                        {ele}
                      </span>

                      <span
                        role="button"
                        onClick={() => handleDelete(ind)}
                        className="key-del-btn h6 border border-1  text-danger  py-1 px-2 "
                      >
                        X
                      </span>
                    </span>
                  </div>
                );
              })}
            </ul>
          </div>
        </CardBody>
      </Card>
      <Card>
        <div className="d-flex justify-content-between border border-bottom-3 ">
          <CardTitle>
            <h6 className="ms-3 mt-3 ">Product FAQS</h6>
          </CardTitle>
          <div>
            <button
              type=""
              className="addNew-btn btn btn-md me-2 mt-2 text-info"
              // onClick={() => addFaQFields()}
              onClick={() => {
                addFields("faqs");
              }}
            >
              Add item
            </button>
          </div>
        </div>
        <CardBody>
          <div>
            {values?.faqs?.map((ele, index) => (
              <div
                className="border border-1 border-light p-3 mb-3"
                key={index}
              >
                <div className="mb-3">
                  <i
                    style={{
                      cursor: "pointer",
                    }}
                    className="mdi mdi-close-thick font-size-18 text-danger float-end"
                    id="removeImgtooltip"
                    // onClick={() => removeFaQFields(index)}
                    onClick={() => removeFields(index, "faqs")}
                  />
                </div>
                <div className="">
                  <Form>
                    <FormGroup>
                      <Label for="" className="mt-3">
                        Question<span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="question"
                        id=""
                        rows="1"
                        value={ele.question || ""}
                        onChange={e => handleOnChange(index, e, "faqs")}
                      />
                    </FormGroup>
                  </Form>
                </div>
                <div>
                  <Form>
                    <FormGroup>
                      <Label for="">
                        Answer<span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="answer"
                        id=""
                        rows="1"
                        value={ele.answer || ""}
                        onChange={e => handleOnChange(index, e, "faqs")}
                      />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductsCreate1;
