import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Select, { components } from "react-select";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import { useDispatch, useSelector } from "react-redux";
import {
  createNewProduct,
  updateProduct,
  getAllSubCategory,
} from "store/actions";
import banner from "./PicUpload.jpg";
import formatBytes from "utils/formatBytes";
import { useParams } from "react-router-dom";
import { empltyProductById, getProductById } from "store/actions";
import { getEcomBrands } from "store/ecomBrands/actions";
import { getViewById } from "services/ecommerce/category.service";

const BasicDetails = ({ defaultValues, stepperActiveTab, handleSubmit }) => {
  const subCategories = useSelector(state => state.EcomSubCategory.category);
  const brands = useSelector(state => state.EcomBrands?.brands);
  const product = useSelector(state => state?.EcomProducts?.product);
  console.log(product, "prod");
  const [values, setValues] = useState(product);
  const [selectedName, setSelectedName] = useState(product?.name);
  const [selectedCat, setSelectedCat] = useState("");
  const [selectedBrand, setSelectedBrand] = useState();
  const [selectedStatus, setSelectedStatus] = useState(product?.status);
  const [selectedFeaturedImg, setSelectedFeaturedImg] = useState("");
  console.log(selectedFeaturedImg, "selectedfeatired");
  const [isFeaturedToggle, setIsFeaturedToggle] = useState(product?.isFeatured);
  const [isServiceToggle, setIsServiceToggle] = useState();
  const [isEcomToggle, setIsEcomToggle] = useState(product?.isEcomProduct);
  console.log(isServiceToggle, "is Servicetoggle");
  console.log(isEcomToggle, "isEcomToggle");

  const [validationError, setValidationError] = useState({});
  const route = useParams();
  console.log(route, "route");
  const statusList = [
    { label: "Publish", value: "Publish" },
    { label: "Draft", value: "Draft" },
    { label: "Pending", value: "Pending" },
  ];

  const dispatch = useDispatch();
  useEffect(() => {
    getAllSubCategory(dispatch);
    getEcomBrands(dispatch);
  }, []);

  useEffect(() => {
    if (route?.catId) {
      getSubCatById(route?.catId);
    }
  }, []);
  const getSubCatById = catId => {
    getViewById(catId)
      .then(res => {
        setSelectedCat(res?.data);
        const obj = {
          mainCategory: res?.data?.mainCategory?._id,
          parentCategory: res?.data?.parentCategory?._id,
          category: res?.data,
        };
        dispatch(updateProduct(obj));
      })
      .catch(err => {
        console.log(err);
      });
  };
  useEffect(() => {
    if (product) {
      if (product?.category) {
        setSelectedCat(product?.category);
      }
      if (product?.brand?._id) {
        setSelectedBrand({
          label: product?.brand?.name,
          value: product?.brand?._id,
        });
      }
      if (product?.status) {
        setSelectedStatus({
          label: product?.status,
          value: product?.status,
        });
      }
      if (product?.name) {
        setSelectedName(product?.name);
      }
      if (product?.featuredimage) {
        setSelectedFeaturedImg(product?.featuredimage);
      }
      if (product?.isFeatured && product?.isFeatured != "undefined") {
        console.log(product?.isFeatured);
        let isFeatured = JSON.parse(product?.isFeatured);
        setIsFeaturedToggle(isFeatured);
      }
      // if (product?.isServiceProduct) {
      //   console.log(product?.isServiceProduct);
      //   let isFeatured = product?.isServiceProduct;
      setIsServiceToggle(product?.isServiceProduct);
      // }
      // if (product?.isEcomProduct) {
      //   console.log(product?.isEcomProduct);
      //   let isFeatured = product?.isEcomProduct;
      setIsEcomToggle(product?.isEcomProduct);
      // }
      product["category"] = product?.category;
      product["brand"] = product?.brand;
      product["status"] = product?.status;
      setValues(product);
    }
  }, [product]);

  useEffect(() => {
    setSelectedBrand("");
    setSelectedName("");
    setSelectedStatus("");
    // setSelectedFeaturedImg("");
    // setIsServiceToggle(null);
    // setIsEcomToggle(product ? product?.isEcomProduct : "");
  }, []);

  const handleSelectChange = (event, name) => {
    if (name == "brand") {
      setSelectedBrand({
        label: event?.label,
        value: event?.value,
      });
    } else if (name == "status") {
      setSelectedStatus(event);
    }
  };

  const saveValues = () => {
    if (route?.catId) {
      let body = {
        name: selectedName,
        category: selectedCat?._id,
        brand: selectedBrand?.value,
        status: selectedStatus?.value,
        featuredimage: selectedFeaturedImg,
        isFeatured: isFeaturedToggle,
        isServiceProduct: isServiceToggle,
        isEcomProduct: isEcomToggle,
      };
      let basicNewValues = Object.assign(values, body);
      dispatch(updateProduct(basicNewValues));
      stepperActiveTab("2");
    } else {
      let body = {
        name: selectedName,
        brand: selectedBrand?.value,
        status: selectedStatus?.value,
        featuredimage: selectedFeaturedImg,
        isFeatured: isFeaturedToggle,
        isServiceProduct: isServiceToggle,
        isEcomProduct: isEcomToggle,
      };

      handleSubmit(body);
    }
  };

  const validation = () => {
    let errors = {};
    if (selectedBrand == "" || selectedBrand == undefined) {
      errors["brand"] = "Please select Brand";
    }
    if (selectedName == "" || selectedName == undefined) {
      errors["name"] = "Please enter Product name";
    }
    if (selectedStatus == "" || selectedStatus == undefined) {
      errors["status"] = "Please select Status";
    }
    if (Object.keys(errors).length === 0) {
      saveValues();
      setValidationError({});
    } else {
      setValidationError(errors);
    }
  };
  const handleToggleChange = event => {
    setIsServiceToggle(event.target.checked);
  };
  const handleEcomToggleChange = event => {
    setIsEcomToggle(event.target.checked);
  };
  return (
    <>
      <Card className="mb-5">
        <CardBody className="mb-5">
          <Row className="mb-5">
            <Col md={9}>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label for="category">
                      Category <span className="text-danger">*</span>
                    </Label>

                    <Input
                      type="text"
                      name="category"
                      id="category"
                      placeholder="Category"
                      value={selectedCat?.name}
                      disabled
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="name">
                      Product Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value={selectedName}
                      onChange={event => {
                        setSelectedName(event?.target?.value);
                      }}
                    />
                    {validationError?.name && (
                      <div className="validaton-text text-danger">
                        {validationError?.name}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="brands">Brands</Label>
                    <Select
                      id="brands"
                      value={selectedBrand || {}}
                      options={brands || []}
                      onChange={event => {
                        setSelectedBrand(event);
                      }}
                    />
                    {validationError?.brand && (
                      <div className="validaton-text text-danger">
                        {validationError?.brand}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label for="status">Status</Label>
                    <Select
                      id="activeStatus"
                      value={selectedStatus || {}}
                      options={statusList || []}
                      onChange={event => {
                        handleSelectChange(event, "status");
                      }}
                    />
                    {validationError?.status && (
                      <div className="validaton-text text-danger">
                        {validationError?.status}
                      </div>
                    )}
                  </FormGroup>
                </Col>{" "}
                <Col md={5}>
                  <div className="mt-3">
                    <Label>Is Service Product (show as service product)</Label>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        role="switch"
                        // deg={isServiceToggle}
                        defaultChecked={isServiceToggle}
                        // onChange={event => {
                        //   console.log(event);
                        //   handleToggleChange(event);
                        // }}
                        onClick={event => {
                          handleToggleChange(event);
                        }}
                        value={isServiceToggle}
                      />
                    </FormGroup>
                  </div>
                </Col>
                <Col md={5}>
                  <div className="mt-3">
                    <Label>Is Ecom Product (show as Ecom product)</Label>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        role="switch"
                        // deg={isServiceToggle}
                        defaultChecked={isEcomToggle}
                        // onChange={event => {
                        //   handleEcomToggleChange(event);
                        // }}
                        onClick={event => {
                          handleEcomToggleChange(event);
                        }}
                        value={isEcomToggle}
                      />
                    </FormGroup>
                  </div>
                </Col>
              </Row>
            </Col>

            <Col className="">
              {" "}
              <CardTitle className=" p-0">
                <h6 className=" mt-3 text-primary p-0">Featured image</h6>
              </CardTitle>
              <Dropzone
                onDrop={acceptedFiles => {
                  acceptedFiles.map(file =>
                    Object.assign(file, {
                      preview: URL.createObjectURL(file),
                      formattedSize: formatBytes(file.size),
                    })
                  );
                  setSelectedFeaturedImg(acceptedFiles[0]);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div
                    className="dropzone ser_img_drop"
                    style={{ height: "80px", minHeight: "0px" }}
                  >
                    <div
                      className="text-center needsclick "
                      {...getRootProps()}
                    >
                      <input {...getInputProps()} />
                      <div className="">
                        <img
                          src={banner}
                          className=" mx-auto d-block "
                          alt=""
                          height={50}
                          width={50}
                        />
                      </div>
                      <h6>upload image</h6>
                    </div>
                  </div>
                )}
              </Dropzone>
              <ProfileImage
                image={selectedFeaturedImg}
                height="80"
                classNames="avatar-sm rounded bg-light mt-2"
              />
              <div className="mt-3">
                <Label>Is Featured (show as popular product)</Label>
                <FormGroup switch>
                  <Input
                    type="switch"
                    role="switch"
                    // defaultChecked={JSON?.parse(values.isFeatured)}
                    onChange={event => {
                      setIsFeaturedToggle(event.target.checked);
                    }}
                    value={isFeaturedToggle}
                  />
                </FormGroup>
              </div>
            </Col>
          </Row>
          <hr className="mt-5" />
          <div className="d-flex mt-2 justify-content-end float-end">
            {route?.catId ? (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2 align-items-center"
                  onClick={() => {
                    validation();
                  }}
                >
                  Next <i className="bx bx-right-arrow-alt mx-2"></i> Variants
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => saveValues()}
                >
                  Save Changes
                </Button>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default BasicDetails;
