let properties = {
  brands: {
    name: "name",
    image: "logo",
    createdAt: "createdAt",
    updatedAt: "updatedAt",
  },
};

const getProperty = name => {
  return properties[name];
};

export { getProperty };
