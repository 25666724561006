export const getBrands=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/brand/all`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get Brands ${e}`))  
  }
  export const getBrand=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/brand/get/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get Brand ${e}`))  
  }
  export const deleteBrand=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/brand/delete/${id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get Brand ${e}`))  
  }
  export const createBrand = body => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/brand/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at Brand create ${e}`));
  };
  export const updateBrand =(id,body) => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/brand/update/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at Brand create ${e}`));
  };
