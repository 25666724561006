import React, { useEffect, useState } from "react";
import Spinner from "components/spinner";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Container,
  Form,
  Row,
  Col,
  Label,
  Input,
  FormFeedback,
  Button,
} from "reactstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import "./payment.scss";
import { createPaymentMethod } from "services/paymentMethods.service";
import SweetAlert from "react-bootstrap-sweetalert";

const defaultValues = {
  name: "",
  type: "",
  key: "",
  secret: "",
};

const CreatePaymentMethod = ({ toggle, modal, reload }) => {
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    type: Yup.string().required("Please Select Type"),
    key: Yup.string().required("Please Enter Key"),
    secret: Yup.string().required("Please Enter Secret"),
  });

  const leaveToList = () => {
    if (success_msg) reload();
    setValues(defaultValues);
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Created Payment Method
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button color="danger" className="w-md btn-sm mx-1">
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const handleChange = e => {
    let obj = { ...values };
    let { name, value } = e.target;

    switch (name) {
      case "name":
        obj.name = value;
        break;
      case "type":
        obj.type = value;
        break;
      case "key":
        obj.key = value;
        break;
      case "secret":
        obj.secret = value;
        break;
      default:
        break;
    }

    setValues({ ...obj });
  };

  const onSubmit = async () => {
    try {
      const data = await createPaymentMethod(values);

      if (data?.success) {
        setValues(defaultValues);
        setsuccess_msg(true);
      } else {
        seterror_msg(true);
      }
    } catch (err) {
      console.log(err);
      seterror_msg(true);
    }
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <Modal isOpen={modal} toggle={leaveToList} backdrop="static" size="lg">
        <ModalHeader toggle={leaveToList}>
          Create Payment Method | stylepro
        </ModalHeader>

        <ModalBody>
          <Container fluid={false}>
            <Form>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col sm={8}>
                      <Label htmlFor="legalname" className=" col-form-label">
                        Name
                        <span className="text-danger"> *</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Please Enter Your Payment Method"
                        name="name"
                        onChange={handleChange}
                        value={values.name}
                        // invalid={touched.name && errors.name ? true : false}
                      />
                      {/* {touched.name && errors.name ? (
                        <FormFeedback type="invalid">
                          {errors.name}
                        </FormFeedback>
                      ) : null} */}
                    </Col>
                    <Col sm={4}>
                      <Label htmlFor="shortname" className="col-form-label">
                        Type
                        <span className="text-danger"> *</span>
                      </Label>

                      <select
                        className="form-control"
                        name="type"
                        value={values.type}
                        onChange={handleChange}
                        // invalid={
                        //   touched.type && errors.type ? true : false
                        // }
                      >
                        <option value=""> --- Please Select Type --- </option>
                        <option value="test">Test</option>
                        <option value="live">Live</option>
                      </select>
                      {/* {touched.type && errors.type ? (
                        <FormFeedback type="invalid">
                          {errors.type}
                        </FormFeedback>
                      ) : null} */}
                    </Col>
                    <Col sm={8}>
                      <Label htmlFor="legalname" className=" col-form-label">
                        Key ID
                        <span className="text-danger"> *</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Please Enter Your Key ID"
                        name="key"
                        onChange={handleChange}
                        value={values.key}
                        // invalid={touched.name && errors.name ? true : false}
                      />
                      {/* {touched.name && errors.name ? (
                        <FormFeedback type="invalid">
                          {errors.name}
                        </FormFeedback>
                      ) : null} */}
                    </Col>
                    <Col sm={8}>
                      <Label htmlFor="legalname" className=" col-form-label">
                        Key Secret
                        <span className="text-danger"> *</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        placeholder="Please Enter Your Key Secret"
                        name="secret"
                        onChange={handleChange}
                        value={values.secret}
                        // invalid={touched.name && errors.name ? true : false}
                      />
                      {/* {touched.name && errors.name ? (
                        <FormFeedback type="invalid">
                          {errors.name}
                        </FormFeedback>
                      ) : null} */}
                    </Col>
                  </Row>
                </Col>
              </Row>
              <hr />
              <div className="d-flex mt-4 justify-content-end">
                <Button
                  color="light"
                  className="w-md btn-sm me-2"
                  onClick={leaveToList}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="w-md btn-sm ms-2"
                  // type="submit"
                  onClick={onSubmit}
                >
                  Create
                </Button>
              </div>
            </Form>
          </Container>
        </ModalBody>
      </Modal>

      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </React.Fragment>
  );
};

export default CreatePaymentMethod;
