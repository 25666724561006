import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import Spinner from "components/spinner";
import moment from "moment";
// import "./Styles.scss";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import getImageUrl from "utils/getImageUrl";
import formatBytes from "utils/formatBytes";
import { Link } from "react-router-dom";
import banner from "pages/Ecommerce/Products/PicUpload.jpg";
import ModalImage from "react-modal-image";
import noImage from "assets/images/PicUpload.jpg";
import { getAllAdminUsers } from "services/user.service";
// import Sendmail from "./Sendmail";
import SweetAlert from "react-bootstrap-sweetalert";
import { AddNote } from "services/AdminNotes";
import Sendmail from "pages/ArtistQuoteForms/Sendmail";
import SendmailContactUs from "./Sendmail";

const AdminNotes = ({ activeOrder }) => {
  console.log(activeOrder, "acttt");
  const [Edit, setisEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [modal, setModal] = useState(false);
  const [Notes, setNotes] = useState([]);
  console.log(Notes?.length, "lenth");
  console.log(Notes, "notes");
  const [users, setAllUsers] = useState([]);
  const [selectedUser, setselectedUser] = useState();
  const [selectedPriority, setSelectedPriority] = useState();
  const [newnote, setnewnote] = useState("");
  const [mailmodal, setMailModal] = useState(false);
  const [maildata, setmaildata] = useState({});
  const [featuredImage, setFeaturedImage] = useState([]);
  let index = Notes?.length;
  console.log(index);
  const ImportanceList = [
    {
      label: "Important:Resolution Needed Immediately",
      value: "Important:Resolution Needed Immediately",
    },
    {
      label: "Medium: Attention needed to the issue",
      value: "Medium: Attention needed to the issue",
    },
    {
      label: "Normal: For information only",
      value: "Normal: For information only",
    },
  ];
  let UserId = JSON.parse(localStorage.getItem("authUser"))["_doc"]["_id"];
  let UserFirstname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "firstname"
  ];
  let UserLastname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "lastname"
  ];
  let Username = UserFirstname + " " + UserLastname;

  const handleChange = data => {
    console.log(data, "datat");
    setnewnote(data);
  };
  useEffect(() => {
    if (activeOrder) {
      setNotes(activeOrder?.notes || []);
      getallUsers();
    }
  }, [activeOrder]);

  const getallUsers = () => {
    getAllAdminUsers()
      .then(res => {
        let options = res?.date?.map(user => ({
          label: user?.email,
          value: user?.email,
        }));
        setAllUsers(options);
      })
      .catch(err => console.log(err));
  };

  async function handleAcceptedFiles(files) {
    files = files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    const imageUrl = await getImageUrl({
      image: files,
    });
    const images = Notes?.attachments || [];
    const imgArray = [...images];
    imgArray.push(imageUrl);
    setFeaturedImage([...featuredImage, ...imgArray]);
  }

  function removeImage(index) {
    let imgArray = [...featuredImage];
    imgArray.splice(index, 1);
    setFeaturedImage(imgArray);
  }

  let OrderId = activeOrder?._id;
  const OnSubmit = () => {
    setLoading(true);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;

    const hours = currentDate.getHours();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;

    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${amOrPm}`;

    const newNote = {
      name: Username,
      adminid: UserId,
      foreignid: activeOrder?._id,
      description: newnote,
      date: formattedDate,
      time: formattedTime,
      attachments: featuredImage,
      importance: selectedPriority?.value,
      order: index,
    };
    const updatedNotes = [...Notes, newNote];
    AddNote(newNote)
      .then(res => {
        if (res.success === true) {
          setsuccess_msg(true);
          setLoading(false);
          setNotes(updatedNotes);
          setnewnote("");
          setisEdit(!Edit);
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Notes added
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
    setnewnote("");
    setFeaturedImage([]);
    setSelectedPriority();
    setselectedUser();
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };

  const toggle = () => {
    setModal(!modal);
  };

  const mailtoggle = () => {
    setMailModal(!mailmodal);
  };

  const renderNotesModal = () => {
    return (
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Admin Notes</ModalHeader>
        <ModalBody>
          <Container fluid={false}>
            <div>
              <div className="Ck_editor_text_box">
                <Card style={{ border: "0px" }}>
                  <div className="mt-2">
                    <Row>
                      <Col md={5}>
                        <div className="">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">
                              Importance
                            </Label>
                            <Select
                              id="importance"
                              value={selectedPriority}
                              onChange={event => {
                                setSelectedPriority(event);
                              }}
                              name="importance"
                              options={ImportanceList}
                              classNamePrefix="select2-selection"
                            />{" "}
                          </FormGroup>
                        </div>
                      </Col>
                      <Col md={5}>
                        <div className="">
                          <FormGroup className="mb-3">
                            <Label htmlFor="validationCustom01">Action</Label>
                            <Select
                              id="action"
                              value={selectedUser}
                              onChange={event => {
                                setselectedUser(event);
                              }}
                              name="action"
                              options={users}
                              classNamePrefix="select2-selection"
                            />{" "}
                          </FormGroup>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={9}>
                        <CardBody className="p-0">
                          <form>
                            <div className="custom_ck_editor">
                              <CKEditor
                                editor={ClassicEditor}
                                id="adminNotes"
                                data={newnote || ""}
                                onReady={editor => {
                                  // You can store the "editor" and use when it is needed.
                                  // editor.setData(values.tagline);
                                }}
                                onChange={(event, editor) => {
                                  const data = editor.getData();
                                  handleChange(data);
                                }}
                              />
                            </div>
                          </form>
                        </CardBody>
                      </Col>
                      <Col md={3} className="notes_attach_file">
                        <div>
                          <Dropzone
                            onDrop={acceptedFiles => {
                              handleAcceptedFiles(acceptedFiles);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone ser_img_drop">
                                <div
                                  className="dz-message needsclick "
                                  {...getRootProps()}
                                >
                                  <input
                                    {...getInputProps({ multiple: true })}
                                  />
                                  <div className="">
                                    <img
                                      src={banner}
                                      className=" mx-auto d-block mb-4"
                                      alt=""
                                      height={100}
                                      width={100}
                                    />
                                  </div>
                                  <h6 className="mb-0">upload image</h6>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </div>
                      </Col>
                      <Col md={8}>
                        <div className="mt-4 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                          <div className="p-2">
                            <div
                              className="dropzone-previews row"
                              id="file-previews"
                            >
                              {featuredImage &&
                                featuredImage?.length > 0 &&
                                featuredImage?.map((img, index) => {
                                  return (
                                    <div
                                      key={index}
                                      className="col-md-4 mt-1"
                                      style={{
                                        position: "relative",
                                      }}
                                    >
                                      <div className="border">
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={img}
                                                height="40"
                                                width="40"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {img.name || img.filename}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {img.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                        <i
                                          style={{
                                            position: "absolute",
                                            right: "16px",
                                            cursor: "pointer",
                                            top: 0,
                                          }}
                                          className="mdi mdi-close-thick font-size-18 text-danger"
                                          id="removeImgtooltip"
                                          onClick={() => removeImage(index)}
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={leaveToList}
              >
                cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                className="w-md btn-sm float-end"
                onClick={OnSubmit}
              >
                Save
              </Button>
            </div>
          </Container>
        </ModalBody>
      </Modal>
    );
  };
  return (
    <>
      <div>
        <Card>
          <CardBody>
            <div className="bg-light p-1 mb-3 d-flex justify-content-between">
              <div>
                <p className="mb-0 fw-bold text-start font-size-16 ps-2">
                  Notes
                </p>
              </div>
              <div>
                <Button
                  className="btn btn-primary btn-sm font-size-14"
                  color="primary"
                  onClick={() => {
                    toggle();
                  }}
                >
                  <i className="bx bx-plus font-size-14 me-1"></i>
                  Add Note
                </Button>
              </div>
            </div>
            <div>
              {Notes?.length > 0 ? (
                <>
                  {Notes?.map((note, index) => {
                    return (
                      <div key={index} className="mb-2 p-2 admin_notes">
                        <div className="d-flex  border-bottom ">
                          <div>
                            <h6 className="border border-2 mb-0 sl_no">
                              {index + 1}
                            </h6>
                          </div>
                          <div className="d-flex align-item-center justify-content-between w-100 ms-2">
                            <div className="d-flex align-item-center">
                              {/* <h6 className="fw-bold me-1 mb-0">Edited By -</h6> */}
                              <h6 className="mb-0 fw-bold">{note?.name}</h6>
                              <h6 className="font-size-12 mb-0 ms-1">
                                {" "}
                                at {moment(note?.date).format(
                                  "DD-MMM-YY"
                                )}, {note?.time}
                              </h6>
                            </div>
                            <div className="">
                              <h6
                                className="fw-bold"
                                onClick={() => {
                                  mailtoggle();
                                  setmaildata(note);
                                }}
                                role="button"
                              >
                                <i className="bx bx-mail-send font-size-20"></i>
                              </h6>
                            </div>
                          </div>
                        </div>
                        <div className="adminNotes_description ps-4 mt-2">
                          {note?.description ? (
                            <div
                              className="text-muted"
                              dangerouslySetInnerHTML={{
                                __html: note?.description,
                              }}
                            />
                          ) : (
                            ""
                          )}{" "}
                        </div>
                        <div className="ps-4 mt-2 d-flex">
                          {note?.attachments?.map((image, ind) => {
                            return (
                              <div key={ind} className="modal_image me-2">
                                <ModalImage
                                  small={image || noImage}
                                  medium={image || noImage}
                                  large={image || noImage}
                                  className="modal-img-size"
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    );
                  })}
                </>
              ) : (
                <div className="border">
                  <Card className="mb-0 text-center">
                    <CardBody>No Notes Available</CardBody>
                  </Card>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
        <Spinner loading={loading} />
        <SendmailContactUs
          toggle={mailtoggle}
          modal={mailmodal}
          data={maildata}
          userslist={users}
          ImportanceList={ImportanceList}
          leaveToList={leaveToList}
        />
        {success_msg ? successPopup() : null}
        {error_msg ? errorPopup() : null}
      </div>
      {renderNotesModal()}
    </>
  );
};

export default AdminNotes;
