import React, { useEffect, useState } from "react";
import * as _ from "lodash";

import { Col, Row, Label, Button, Input, FormGroup } from "reactstrap";
import { Link } from "react-router-dom";

import Select from "react-select";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import { updatefilefeildService } from "services/services.service";

const optionalRow = {
  type: "",
  name: "",
  value: "",
  image: "",
  displayType: "",
  order: "",
  status: "",
};

const optionInitVal = {
  optionTitle: "",
  values: [optionalRow],
};

const OptionTypes = [
  { label: "Multi Select", value: "Multi Select" },
  { label: "Single Select", value: "Single Select" },
];
const OptionDisplayTypes = [
  { label: "Text with Image", value: "Text with Image" },
  { label: "Only Text", value: "Only Text" },
];
const optionStatusList = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];

const customStyles = {
  control: base => ({
    ...base,
    height: 28,
    minHeight: 28,
  }),
};

function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

const Options = ({ initValues, optionId, reloadService }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [values, setValues] = useState(initValues);
  const [images, setimages] = useState({});
  console.log(values, "INIT OPT VALUE");
  console.log(initValues, "INIT OPT VALUE");

  useEffect(() => {
    console.log(initValues, "OPTIONS INFO");
    if (initValues) {
      setValues(initValues);
    }
  }, [initValues]);

  const toggleEdit = () => {
    // setDefautValues();
    setIsEdit(!isEdit);
  };

  const onSubmit = () => {
    console.log(values);
    let formData = new FormData();
    formData.set("options", JSON.stringify(values));
    for (let key in images) {
      formData.set(key, images[key]);
    }
    updatefilefeildService(optionId, formData)
      .then(res => {
        console.log(res);
        reloadService();
      })
      .catch(e => {
        console.log(e);
      });
  };

  const addOption = () => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues.push(optionInitVal);
    setValues(tempValues);
  };

  const handleTitleChange = (index, value) => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[index]["optionTitle"] = value;
    setValues([...tempValues]);
  };

  const handleChange = (fieldName, subIndex, parentIndex, value) => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[parentIndex]["values"][subIndex][fieldName] = value;
    setValues([...tempValues]);
  };

  function handleAcceptedFiles(subIndex, parentIndex, files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    // let tempValues = JSON.parse(JSON.stringify(values));
    // tempValues[parentIndex]["values"][subIndex]["image"] = files[0];
    // setValues([...tempValues]);
    const imagesdata = { ...images };
    imagesdata[`option${parentIndex}${subIndex}`] = files[0];
    setimages(imagesdata);
  }

  const handleDeleteRow = (subInd, parentInd) => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[parentInd]["values"].splice(subInd, 1);
    setValues([...tempValues]);
  };
  const handleAddRow = parentInd => {
    let tempValues = JSON.parse(JSON.stringify(values));
    tempValues[parentInd]["values"].push(optionalRow);
    setValues([...tempValues]);
  };

  return (
    <div id="servicce_options_container">
      <Row>
        <div className="info_conetent_header d-flex mb-2">
          <h5>OPTIONS INFO</h5>
          {!isEdit && (
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => toggleEdit()}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          )}
          {isEdit && (
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => addOption()}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Add Option</span>
            </Link>
          )}
        </div>
      </Row>
      <div>
        {values &&
          values.map((ele, ind) => {
            return (
              <div key={ind} className="p-2">
                <Row>
                  {/** Option Title */}
                  <Col md={4}>
                    <Label htmlFor="name" className=" col-form-label">
                      Option Title
                    </Label>
                    <Input
                      type="text"
                      bsSize="sm"
                      className="form-control"
                      id="name"
                      placeholder="Enter Option Title"
                      name="name"
                      onChange={event =>
                        handleTitleChange(ind, event.target.value)
                      }
                      value={ele.optionTitle}
                      disabled={!isEdit}
                    />
                  </Col>
                </Row>
                {ele.values &&
                  ele.values?.map((subEle, subInd) => {
                    const { image } = subEle;
                    return (
                      <Row className="option_row py-2 my-3 m-0" key={ind + " " + subInd}>
                        <Col md={11}>
                          <Row>
                            {/** OptionType */}
                            <Col md={3}>
                              <Label htmlFor="type" className=" col-form-label">
                                Option Type
                              </Label>
                              <Select
                                id="type"
                                value={{
                                  label: subEle.type,
                                  value: subEle.type,
                                }}
                                onChange={event => {
                                  handleChange(
                                    "type",
                                    subInd,
                                    ind,
                                    event.value
                                  );
                                }}
                                options={OptionTypes}
                                isDisabled={!isEdit}
                                className="custom_dropdown_sm"
                                classNamePrefix={
                                  "select2-selection " +
                                  (isEdit && "dropdown-disabled")
                                }
                                styles={customStyles}
                              />
                            </Col>

                            {/** Option Name */}
                            <Col md={3}>
                              <Label
                                htmlFor="optionName"
                                className=" col-form-label"
                              >
                                Option Name
                              </Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                className="form-control"
                                id="optionName"
                                placeholder="Enter Option Name"
                                name="optionName"
                                onChange={event =>
                                  handleChange(
                                    "name",
                                    subInd,
                                    ind,
                                    event.target.value
                                  )
                                }
                                value={subEle.name}
                                disabled={!isEdit}
                              />
                            </Col>

                            {/** Option Value */}
                            <Col md={3}>
                              <Label
                                htmlFor="optionvalue"
                                className=" col-form-label"
                              >
                                Option Value
                              </Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                className="form-control"
                                id="optionvalue"
                                placeholder="Enter Option Value"
                                name="optionvalue"
                                onChange={event =>
                                  handleChange(
                                    "value",
                                    subInd,
                                    ind,
                                    event.target.value
                                  )
                                }
                                value={subEle.value}
                                disabled={!isEdit}
                              />
                            </Col>

                            {/** Option Display Type */}
                            <Col md={3}>
                              <Label
                                htmlFor="displayType"
                                className=" col-form-label"
                              >
                                Option Display Type
                              </Label>
                              <Select
                                id="displayType"
                                value={{
                                  label: subEle.displayType,
                                  value: subEle.displayType,
                                }}
                                onChange={event => {
                                  handleChange(
                                    "displayType",
                                    subInd,
                                    ind,
                                    event.value
                                  );
                                }}
                                options={OptionDisplayTypes}
                                isDisabled={!isEdit}
                                classNamePrefix={
                                  "select2-selection " +
                                  (isEdit && "dropdown-disabled")
                                }
                                className="custom_dropdown_sm"
                                styles={customStyles}
                              />
                            </Col>

                            {/** Order */}
                            <Col md={3}>
                              <Label
                                htmlFor="order"
                                className=" col-form-label"
                              >
                                Order
                              </Label>
                              <Input
                                type="text"
                                bsSize="sm"
                                className="form-control"
                                id="order"
                                placeholder="Order"
                                name="order"
                                onChange={event =>
                                  handleChange(
                                    "order",
                                    subInd,
                                    ind,
                                    event.target.value
                                  )
                                }
                                value={subEle.order}
                                disabled={!isEdit}
                              />
                            </Col>

                            {/** Status */}
                            <Col md={3}>
                              <Label
                                htmlFor="optionStatus"
                                className=" col-form-label"
                              >
                                Status
                              </Label>
                              <Select
                                id="optionStatus"
                                value={{
                                  label: subEle.status,
                                  value: subEle.status,
                                }}
                                onChange={event => {
                                  handleChange(
                                    "status",
                                    subInd,
                                    ind,
                                    event.value
                                  );
                                }}
                                options={optionStatusList}
                                isDisabled={!isEdit}
                                classNamePrefix={
                                  "select2-selection " +
                                  (isEdit && "dropdown-disabled")
                                }
                                className="custom_dropdown_sm"
                                styles={customStyles}
                              />
                            </Col>
                            {isEdit && (
                              <Col md={3}>
                                <Dropzone
                                  onDrop={acceptedFiles => {
                                    handleAcceptedFiles(
                                      subInd,
                                      ind,
                                      acceptedFiles
                                    );
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone ser_img_drop">
                                      <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="">
                                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h6>Image</h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                              </Col>
                            )}
                            {image && (
                              <Col md={3}>
                                <div className="ser_img">
                                  <ProfileImage src={image} />
                                </div>
                              </Col>
                            )}
                          </Row>
                        </Col>
                        <Col md={1}>
                          <div className="ser_row_action">
                            <i
                              className="mdi mdi-delete font-size-18 text-danger"
                              onClick={() => {
                                handleDeleteRow(subInd, ind);
                              }}
                            ></i>
                            {ele.values.length === subInd + 1 && (
                              <i
                                className="mdi mdi-plus-thick font-size-18 text-success"
                                onClick={() => {
                                  handleAddRow(ind);
                                }}
                              ></i>
                            )}
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
              </div>
            );
          })}
      </div>

      {isEdit && (
        <>
          {/* <hr /> */}
          <div className="d-flex mt-4 justify-content-end">
            <Button
              color="light"
              className="w-md btn-sm me-2"
              onClick={() => toggleEdit()}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="w-md btn-sm ms-2"
              onClick={() => {
                onSubmit();
              }}
            >
              Save
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Options;
