import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import avatar from "assets/images/avatar.jpg";
// import EditArtistImage from "./EditPortfolio";
import {
  Container,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Card,
  CardBody,
  Col,
  Row,
  Label,
} from "reactstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Dropzone from "react-dropzone";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Spinner from "components/spinner";
import ProfileImage from "../../../components/profileImage";
import { createImageUrl } from "services/imageUrl.service";

const defaultValues = {
  title: "",
  name: "",
  city: "",
  state: "",
  country: "",
  area: "",
  mobileNumber: "",
  email: "",
  status: "",
  description: "",
};

const EditBasic = ({ artistValues, handleSave, handleCancel }) => {
  const [values, setValues] = useState(defaultValues);
  const [artistImageModal, setArtistImageModal] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (artistValues) {
      let {
        _id,
        title,
        name,
        city,
        state,
        country,
        area,
        mobileNumber,
        email,
        status,
        description,
        artistImage,
      } = artistValues[0];

      let obj = {
        _id,
        title,
        name,
        city,
        state,
        country,
        area,
        mobileNumber,
        email,
        status,
        description,
        artistImage,
      };

      setValues({ ...obj });
    }
    setLoading(false);
  }, [artistValues]);

  const toggle = () => {
    setArtistImageModal(!artistImageModal);
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleImageChange = async image => {
    let obj = { ...values };
    try {
      let imageFormData = new FormData();
      imageFormData.set("image", image);

      const data = await createImageUrl(imageFormData);


      if (data.success) {
        obj.artistImage = data.image;
      }

      setValues({ ...obj });

      toggle();
    } catch (err) {
      console.log(err);
    }
  };

  const handleDescriptionChange = data => {
    let obj = { ...values };

    obj.description = data;

    setValues({ ...obj });
  };

  const handleChange = e => {
    let { name, value } = e.target;
    let obj = { ...values };

    switch (name) {
      case "title":
        obj.title = value;
        break;
      case "name":
        obj.name = value;
        break;
      case "status":
        obj.status = value;
        break;
      case "area":
        obj.area = value;
        break;
      case "city":
        obj.city = value;
        break;
      case "state":
        obj.state = value;
        break;
      case "country":
        obj.country = value;
        break;
      case "mobileNumber":
        obj.mobileNumber = value;
        break;
      case "email":
        obj.email = value;
        break;
      case "description":
        obj.description = value;
        break;

      default:
        break;
    }

    setValues({ ...obj });
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="m-3 services_basic_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>BASIC INFO</h6>
            <div className="d-flex ">
              <Link
                className="btn btn-sm btn-primary btn-bg-primary me-3 "
                to="#"
                onClick={() => handleSave(values, "basic")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Save</span>
              </Link>
              <Link
                className="btn btn-sm btn-danger btn-bg-danger me-3 "
                to="#"
                onClick={() => handleCancel("basic")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Cancel</span>
              </Link>
            </div>
          </div>
        </Row>
        <Row className="m-0">
          <Col md={4} className="services_images_info ps-0 pe-2">
            <Card className="mb-2">
              <CardBody>
                <Row className="mb-2">
                  {" "}
                  <Col md={6}>
                    <p className="fw-bold mb-0">Artist Image</p>{" "}
                  </Col>
                  <Col md={6}>
                    {" "}
                    <div className="edit_btn_div">
                      <Link
                        className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
                        to="#"
                        onClick={() => toggle()}
                      >
                        <i className="bx bx-edit-alt bx-xs pe-1"> </i>
                        <span> Select Image</span>
                      </Link>{" "}
                    </div>
                  </Col>
                </Row>
                <div className="banner">
                  <img
                    alt="banner"
                    src={values?.artistImage ? values?.artistImage : avatar}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={8} className="p-0">
            <Card>
              <CardBody>
                <Row className="m-0">
                  <Col md={12} className="mb-1 px-0 view_detail">
                    <Row className="m-0 mb-2">
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Title</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="title"
                          value={values?.title}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Name</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="name"
                          value={values?.name}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Status</p>
                        <select
                          className="form-control text-muted"
                          name="status"
                          value={values?.status}
                          onChange={handleChange}
                        >
                          <option value="Active">Active</option>
                          <option value="Locked">Locked</option>
                          <option value="Inactive">Inactive</option>
                        </select>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">Area</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="area"
                          value={values?.area}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">City</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="city"
                          value={values?.city}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">State</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="state"
                          value={values?.state}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={3} className="mt-1">
                        <p className="fw-bold mb-1">Country</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="country"
                          value={values?.country}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Mobile</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="mobileNumber"
                          value={values?.mobileNumber}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Email</p>
                        <input
                          className="form-control text-muted"
                          type="text"
                          name="email"
                          value={values?.email}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={12} className="mt-1">
                        <p className="fw-bold mb-1">Description</p>
                        <CKEditor
                          editor={ClassicEditor}
                          id="description"
                          data={values?.description}
                          onReady={editor => {
                            console.log("Editor is ready to use!", editor);
                            if (values?.description)
                              editor.data.set(values?.description);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            handleDescriptionChange(data);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>

      <Modal
        isOpen={artistImageModal}
        toggle={toggle}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={toggle}>Artist Image | stylepro</ModalHeader>

        <ModalBody style={{ paddingBottom: "60px" }}>
          <Container fluid={false}>
            <Row>
              <Col sm={12} className="mt-3">
                <Label htmlFor="validationCustom06">Image</Label>
                <Dropzone
                  id="validationCustom06"
                  onDrop={acceptedFiles => {
                    acceptedFiles.map(file =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                      })
                    );
                    handleImageChange(acceptedFiles[0]);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="dropzone image_drop">
                      <div
                        className="dz-message needsclick "
                        {...getRootProps()}
                      >
                        <input {...getInputProps()} />
                        <div className="">
                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                        </div>
                        <h6>Drop files here or click to upload.</h6>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </Col>
            </Row>
          </Container>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default EditBasic;
