import React, { Fragment, useState, useEffect } from "react";
import { useParams } from "react-router";

import MetaTags from "react-meta-tags";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Input,
  FormFeedback,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

// import { createService } from "services/services.service";
import ProfileImage from "./../../components/profileImage";

import { userRegister, updateUser } from "services/auth.service";
import { getUser } from "services/user.service";
const defaultValues = {
  firstname: "",
  lastname: "",
  email: "",
  password: "123456",
  status: "confirmed",
  idProofs: [],
  avatar: "",
  mobilenumber: "",
  role: "Customer",
  experiences: [],
};

const CreateUser = props => {
  const { id } = useParams();

  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [providersList, setProvidersList] = useState([
    { label: "Provider", value: "Provider" },
    { label: "Employee", value: "Employee" },
    { label: "Customer", value: "Customer" },
  ]);
  const [statusList, setStatusList] = useState([
    { label: "confirmed", value: "confirmed" },
    { label: "pending", value: "pending" },
  ]);
  const [selectedProvider, setSelectedProvider] = useState({
    label: "Customer",
    value: "Customer",
  });
  const [selectedStatus, setStatus] = useState(null);

  const [values, setValues] = useState(defaultValues);
  const [experiences, setExperiences] = useState([
    { service: "", fromDate: "", toDate: "" },
  ]);
  const initialValues = defaultValues;
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Please Enter Email"),
    firstname: Yup.string().required("Please Enter First Name"),
    lastname: Yup.string().required("Please Enter Last Name"),
    mobilenumber: Yup.string().required("Please Enter mobilenumber"),
    status: Yup.string().required("Please Select Status"),
    role: Yup.string().required("Please Select Role"),
  });
  const onSubmit = values => {
    
    if (id) {
      updateChanges(values);
    } else {
      saveChanges(values);
    }
  };

  const handleAddExperience = () => {
    const exp = [...experiences];
    exp.push({ service: "", fromDate: "", toDate: "" });
    setExperiences(exp);
  };

  const handleRemoveExperience = index => {
    const exp = [...experiences];
    exp.splice(index, 1);
    setExperiences(exp);
  };

  const handleExperienceChange = (index, event) => {
    const values = [...experiences];
    
    if (event.target.name === "service") {
      values[index].service = event.target.value;
    } else if (event.target.name === "fromDate") {
      values[index].fromDate = event.target.value;
    } else {
      values[index].toDate = event.target.value;
    }

    setExperiences(values);
  };

  const inputChange = name => event => {
    setValues({ ...values, [name]: event.target.value });
  };
  function handleSelect(data) {
    setValues({ ...values, ["role"]: data.value });
    setSelectedProvider(data);
  }
  function hanldeStatus(data) {
    setValues({ ...values, ["status"]: data.value });
    setStatus(data);
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    let imgArray = values["idProofs"];
    imgArray.push(files[0]);
    setValues({ ...values, ["idProofs"]: imgArray });
  }
  function handleAvatar(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    setValues({ ...values, ["avatar"]: files[0] });
  }

  function removeImage(index, values) {
    let imgArray = [...values["idProofs"]];
    imgArray.splice(index, 1);
    setValues({ ...values, ["idProofs"]: imgArray });
  }

  const saveChanges = values => {
    let formData = new FormData();
    for (let key in values) {
      if (key.includes("idProofs")) {
        if (values.idProofs.length > 0) {
          
          formData.set(key, values.idProofs);
        }
      } else if (key === "avatar") {
        if (values.avatar !== "") {
          formData.set("avatar", values.avatar);
        }
      } else if (key === "experiences") {
        if (values.role !== "Customer" && experiences.length > 0) {
          formData.set("experiences", JSON.stringify(experiences));
        }
      } else {
        formData.set(key, values[key]);
      }
    }
    userRegister(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };
  const updateChanges = values => {
    let formData = new FormData();
    values["experiences"] = JSON.stringify(experiences);
    for (let key in values) {
      if (key.includes("idProofs")) {
        const previousData = [];
        values.idProofs.forEach((e,i) => {
          if (Object.keys(e).length === 4) {
            previousData.push(e);
          }
          if (Object.keys(e).length !== 4) {
            formData.set(`idProofs${i}`, e);
          }
        });
        formData.set("idProofs", JSON.stringify(previousData));
      } else if (key === "avatar") {
        if (values.avatar !== "") {
          formData.set("avatar", JSON.stringify(values.avatar));
        }
      } else if (key === "experiences") {
        if (values.role !== "Customer" && experiences.length > 0) {
          formData.set("experiences", JSON.stringify(experiences));
        }
      } else {
        formData.set(key, values[key]);
      }
    }
    updateUser(formData,id)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const leaveToList = () => {
    props.history.push("/User-list");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully User created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>User | Create User</title>
        </MetaTags>
        <Container fluid={false}>
          <Breadcrumb title="User" breadcrumbItem="Create User" />
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  values,
                }) => {
                  useEffect(() => {
                    if (id) {
                      // setLoading(true);
                      getUser(id)
                        .then(res => {
                          const data = { ...res.data };
                          const fields = [
                            "firstname",
                            "lastname",
                            "email",
                            "password",
                            "status",
                            "idProofs",
                            "mobilenumber",
                            "avatar",
                            "experiences",
                            "role",
                          ];
                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          setSelectedProvider({
                            label: res.data.role,
                            value: res.data.role,
                          });
                          setStatus({
                            label: res.data.status,
                            value: res.data.status,
                          });
                          setExperiences(res.data.experiences);
                          //   setLoading(false);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-3">
                        <Col md={6} className="px-5">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                First Name
                              </Label>
                              <Input
                                name="firstname"
                                placeholder="firstname"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.firstname}
                                invalid={
                                  touched.firstname && errors.firstname
                                    ? true
                                    : false
                                }
                              />
                              {touched.firstname && errors.firstname ? (
                                <FormFeedback type="invalid">
                                  {errors.firstname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom02">
                                Last Name
                              </Label>
                              <Input
                                name="lastname"
                                placeholder="lastname"
                                type="text"
                                className="form-control"
                                id="validationCustom02"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.lastname}
                                invalid={
                                  touched.lastname && errors.lastname
                                    ? true
                                    : false
                                }
                              />
                              {touched.lastname && errors.lastname ? (
                                <FormFeedback type="invalid">
                                  {errors.lastname}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom03">Email</Label>
                              <Input
                                name="email"
                                placeholder="email"
                                type="text"
                                className="form-control"
                                id="validationCustom03"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.email}
                                invalid={
                                  touched.email && errors.email ? true : false
                                }
                              />
                              {touched.email && errors.email ? (
                                <FormFeedback type="invalid">
                                  {errors.email}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom04">Role</Label>
                              <Select
                                id="validationCustom04"
                                value={selectedProvider}
                                onChange={event => {
                                  handleSelect(event);
                                  handleChange("role")(event.value);
                                }}
                                name="type"
                                options={providersList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.role}
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">
                                Mobile Number
                              </Label>
                              <Input
                                name="mobilenumber"
                                placeholder="mobilenumber"
                                type="text"
                                className="form-control"
                                id="validationCustom05"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.mobilenumber}
                                invalid={
                                  touched.mobilenumber && errors.mobilenumber
                                    ? true
                                    : false
                                }
                              />
                              {touched.mobilenumber && errors.mobilenumber ? (
                                <FormFeedback type="invalid">
                                  {errors.mobilenumber}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-5">
                          {values.role !== "Customer" ? (
                            <Row className="mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom06">
                                  ID Proof
                                </Label>
                                <Dropzone
                                  id="validationCustom06"
                                  onDrop={acceptedFiles => {
                                    acceptedFiles.map(file =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        formattedSize: formatBytes(file.size),
                                      })
                                    );
                                    let imgArray = [...values["idProofs"]];
                                    imgArray.push(acceptedFiles[0]);
                                    setFieldValue("idProofs", imgArray);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone image_drop">
                                      <div
                                        className="dz-message needsclick "
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="">
                                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h6>
                                          Drop files here or click to upload.
                                        </h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.idProofs ? errors.idProofs : ""}
                                </Label>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {values.idProofs &&
                                    values.idProofs.length > 0 &&
                                    values.idProofs.map((img, index) => {
                                      return (
                                        <Card
                                          key={index}
                                          className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                        >
                                          <div className="p-2">
                                            <Row className="align-items-center">
                                              <Col className="col-auto">
                                                <ProfileImage
                                                  image={img}
                                                  height="80"
                                                  classNames="avatar-sm rounded bg-light"
                                                />
                                              </Col>
                                              <Col>
                                                <Link
                                                  to="#"
                                                  className="text-muted font-weight-bold"
                                                >
                                                  {img.name || img.filename}
                                                </Link>
                                                <p className="mb-0">
                                                  <strong>
                                                    {img.formattedSize}
                                                  </strong>
                                                </p>
                                              </Col>
                                            </Row>
                                          </div>
                                          <i
                                            style={{
                                              position: "absolute",
                                              right: "3px",
                                              cursor: "pointer",
                                            }}
                                            className="mdi mdi-close-thick font-size-18 text-danger"
                                            id="removeImgtooltip"
                                            onClick={() => {
                                              let imgArray = [
                                                ...values["idProofs"],
                                              ];
                                              imgArray.splice(index, 1);
                                              setFieldValue(
                                                "idProofs",
                                                imgArray
                                              );
                                            }}
                                          />
                                        </Card>
                                      );
                                    })}
                                </div>
                              </FormGroup>
                            </Row>
                          ) : (
                            ""
                          )}
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">Avatar</Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  
                                  setFieldValue("avatar", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="statusValidation">
                                {" "}
                                {errors.avatar ? errors.avatar : ""}
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.avatar &&
                                  Object.keys(values.avatar).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.avatar}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.avatar.name}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.avatar.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom08">Status</Label>
                              <Select
                                // className="form-control"
                                id="validationCustom08"
                                value={selectedStatus}
                                onChange={event => {
                                  hanldeStatus(event);
                                  handleChange("status")(event.value);
                                }}
                                name="type"
                                options={statusList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.status ? errors.status : ""}
                              </Label>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>

                      {/* start here in morning */}
                      {values.role !== "Customer" ? (
                        <Row className="mb-4 px-5">
                          <h5 className="text-muted text-bold mb-3 px-0">
                            Experiences :{" "}
                          </h5>
                          {experiences.map((exp, index) => (
                            <Row className="mb-2 px-0" key={`${exp}~${index}`}>
                              <Col md={5}>
                                <label htmlFor="service">Service</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="service"
                                  name="service"
                                  placeholder="Enter Experience Service"
                                  value={exp.service}
                                  onChange={event =>
                                    handleExperienceChange(index, event)
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <label htmlFor="fromDate">From Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="fromDate"
                                  name="fromDate"
                                  placeholder="Experience Service"
                                  value={exp.fromDate}
                                  onChange={event =>
                                    handleExperienceChange(index, event)
                                  }
                                  onSelect={event =>
                                    handleExperienceChange(index, event)
                                  }
                                />
                              </Col>
                              <Col md={3}>
                                <label htmlFor="toDate">To Date</label>
                                <input
                                  type="date"
                                  className="form-control"
                                  id="toDate"
                                  name="toDate"
                                  value={exp.toDate}
                                  onChange={event =>
                                    handleExperienceChange(index, event)
                                  }
                                />
                              </Col>
                              <Col md={1} className="mt-auto text-end">
                                {experiences.length - 1 !== index && (
                                  <Button
                                    outline
                                    color="danger"
                                    className="btn"
                                    type="button"
                                    onClick={() =>
                                      handleRemoveExperience(index)
                                    }
                                  >
                                    remove
                                  </Button>
                                )}
                                {experiences.length - 1 === index && (
                                  <Button
                                    outline
                                    color="primary"
                                    className=" btn-md"
                                    type="button"
                                    style={{ width: "80px" }}
                                    onClick={() => handleAddExperience()}
                                  >
                                    Add
                                  </Button>
                                )}
                              </Col>
                            </Row>
                          ))}
                        </Row>
                      ) : (
                        ""
                      )}
                      <hr />
                      <div className="d-flex justify-content-end">
                        {id ? (
                          <Button
                            color="primary"
                            className="w-md btn-sm me-4"
                            type="submit"
                          >
                            Update User
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="w-md btn-sm me-4"
                            type="submit"
                          >
                            Save User
                          </Button>
                        )}
                        <Button
                          type="submit"
                          color="light"
                          className="w-md btn-sm"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateUser;
