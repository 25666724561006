import ProfileImage from "components/profileImage";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import banner from "./../PicUpload.jpg";
import formatBytes from "utils/formatBytes";
import { product1 } from "assets/images/product";
import { useDispatch, useSelector } from "react-redux";
import { updateProduct } from "store/actions";
import { getSkuCode } from "services/ecommerce/product.service";

export const VariantsView = ({
  variantsList,
  combosList,
  handleSubmitValues,
  productType,
  stepperActiveTab,
}) => {
  const [comboList, setComboList] = useState(combosList);
  const product = useSelector(state => state?.EcomProducts?.product);
  const route = useParams();
  const [values, setValues] = useState(product);
  const [skuCode, setSkuCode] = useState(values?.sku);
  const dispatch = useDispatch();
  useEffect(() => {
    setComboList(combosList);
  }, [combosList]);

  useEffect(() => {
    setValues(product);
    if (route?.id) {
      let tempData = JSON.parse(JSON.stringify(product));
      if (tempData && tempData?.attributes) {
        if (tempData?.attributes[0]?.combos) {
          setComboList(tempData?.attributes[0]?.combos || []);
        }
      }
    }
  }, [product]);

  const handleChange = (i, e) => {
    let newVariantsList = [...comboList];
    newVariantsList[i][e.target.name] = e.target.value;
    setComboList(newVariantsList);
  };

  function handleAcceptedFiles(index, files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    let newVariantsList = [...comboList];
    const images = newVariantsList[index]["images"] || [];
    const imgArray = [...images];
    imgArray.push(files[0]);
    newVariantsList[index]["images"] = imgArray;
    setComboList(newVariantsList);
  }

  function removeImage(imgIndex, index) {
    let newVariantsList = [...comboList];
    let imgArray = newVariantsList[index]["images"];
    imgArray.splice(imgIndex, 1);
    newVariantsList[index]["images"] = imgArray;
    setComboList(newVariantsList);
  }
  const calculateDiscount = (index, discountType, value) => {
    let tempVariant = comboList[index];
    const newValues = {};
    let ourPrice, flatPrice, percentage;
    if (discountType === "percentageDiscount") {
      newValues["percentageDiscount"] = value;
      // calculate Flat Discount, Our price
      ourPrice = (tempVariant.mrp - tempVariant.mrp * (value / 100)).toFixed(2);
      flatPrice = tempVariant.mrp - ourPrice;
      newValues["price"] = ourPrice;
      newValues["flatDiscount"] = flatPrice;
    } else {
      newValues["flatDiscount"] = value;
      // calculate Percentage Discount, Our price
      ourPrice = (tempVariant.mrp - value).toFixed(2);
      percentage = ((tempVariant.mrp - ourPrice) / tempVariant.mrp) * 100;
      newValues["price"] = ourPrice;
      newValues["percentageDiscount"] = percentage;
    }
    comboList[index] = { ...tempVariant, ...newValues };
    setComboList([...comboList]);
  };
  const handleMRP = (index, val) => {
    let tempVariant = comboList[index];
    const newValues = {
      flatDiscount: 0,
      percentageDiscount: 0,
      price: val,
      mrp: val,
    };

    comboList[index] = { ...tempVariant, ...newValues };
    setComboList([...comboList]);
  };

  const handleSubmit = () => {
    const body = {
      variants: variantsList,
      combos: comboList,
    };
    let tempProduct = _.cloneDeep(product);
    if (route?.catId) {
      tempProduct["type"] = productType;
      tempProduct["attributes"].push(body);
      let basicNewValues = Object.assign(values, tempProduct);
      dispatch(updateProduct(basicNewValues));
      stepperActiveTab("3");
    } else {
      let attributes = [];
      let tempVariants = [...body?.variants];
      let tempCombos = [...body?.combos];
      attributes.push({ ["variants"]: tempVariants, ["combos"]: tempCombos });
      let atrributeValuues = { attributes: attributes, type: productType };
      handleSubmitValues(atrributeValuues);
    }
  };
  const generateRandomCode = index => {
    getSkuCode()
      .then(res => {
        setSkuCode(res?.data);
        let newVariantsList = [...comboList];
        newVariantsList[index]["sku"] = res?.data;
        setComboList(newVariantsList);
      })
      .catch(err => console.log(err));
  };

  return (
    <>
      <label className="mb-1">Variants</label>
      <Card>
        {comboList.length > 0 ? (
          comboList?.map((element, index) => (
            <Card key={index}>
              <div className="d-flex  mb-3 bg-light p-2  align-items-center ">
                <h5 className="mb-0 text-dark">Variant </h5>
                {element?.variants?.map((ele, ind) => (
                  <span
                    key={ind}
                    className=" h6 border border-1 px-2 mb-0 bg-secondary text-white py-1 px-2 mx-2 rounded"
                  >
                    {ele}
                  </span>
                ))}
              </div>
              <CardBody>
                <div className="  mb-2">
                  <Row>
                    {/* <Col md={4}>
                      <Label for="sku">SKU</Label>
                      <Input
                        id="sku"
                        name="sku"
                        placeholder=""
                        type="number"
                        value={element?.sku}
                        onChange={e => {
                          handleChange(index, e);
                        }}
                      />
                    </Col> */}
                    <Col md={3}>
                      <FormGroup>
                        <Label
                          for="sku"
                          className="d-flex justify-content-between"
                        >
                          <div>Our SKU </div>
                          {element?.sku ? (
                            ""
                          ) : (
                            <Button
                              className=" border border-0 p-0 px-2  text-info "
                              color="light"
                              role="button"
                              onClick={() => generateRandomCode(index)}
                            >
                              Generate Sku code
                            </Button>
                          )}
                        </Label>
                        <Input
                          id="sku"
                          name="sku"
                          placeholder=""
                          type="text"
                          value={element?.sku}
                          onChange={e => {
                            handleChange(index, e);
                          }}
                          disabled={element?.sku ? true : false}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3}>
                      <FormGroup>
                        <Label for="sku">
                          <div>Manufacturer SKU </div>
                        </Label>
                        <Input
                          id="manfsku"
                          name="manfsku"
                          placeholder=""
                          type="text"
                          value={element?.manfsku}
                          onChange={e => {
                            // handleChangeProperties(
                            //   "manfsku",
                            //   event.target.value
                            // );
                            handleChange(index, e);
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={3} className="align-items-center">
                      <Label for="mrp" className="">
                        MRP
                      </Label>
                      <Input
                        type="mrp"
                        name="mrp"
                        id="mrp"
                        value={element.mrp || ""}
                        onChange={e => {
                          handleMRP(index, e.target.value);
                        }}
                      />
                    </Col>
                    <Col md={3} className="align-items-center">
                      <Label for="price" className="">
                        Our Price
                      </Label>
                      <Input
                        type="number"
                        name="price"
                        id="price"
                        placeholder="₹"
                        value={element.price}
                        readOnly
                        disabled
                      />
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4}>
                      <FormGroup>
                        <Label for="tax">Tax (%)</Label>
                        <Input
                          id="tax"
                          name="tax"
                          type="number"
                          placeholder="%"
                          value={element.tax}
                          onChange={e => handleChange(index, e)}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="flatDiscount">Flat Discount</Label>
                        <Input
                          id="flatDiscount"
                          name="flatDiscount"
                          type="number"
                          placeholder="₹"
                          value={element.flatDiscount}
                          onChange={e => {
                            handleChange(index, e);
                            calculateDiscount(
                              index,
                              "flatDiscount",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup>
                        <Label for="discount">Percentage Discount (%)</Label>
                        <Input
                          id="percentageDiscount"
                          name="percentageDiscount"
                          type="number"
                          placeholder="%"
                          value={element.percentageDiscount || ""}
                          onChange={e => {
                            handleChange(index, e);
                            calculateDiscount(
                              index,
                              "percentageDiscount",
                              e.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={12}>
                      <Label for="exampleSelect">Unit Type</Label>
                      <Input
                        id=""
                        name="select"
                        type="select"
                        value={element.unit}
                        onChange={e => handleChange(index, e)}
                      >
                        <option value="ml">Ml</option>
                        <option value="pc">Pc</option>
                        <option value="gms">Gms</option>
                        <option value="kg">Kg</option>
                        <option value="ltrs">Ltrs</option>
                      </Input>
                    </Col>
                  </Row>
                  <Row className="mt-3">
                    <Col md={4} className="align-items-center">
                      <Label for="quantity" className="">
                        Total Quantity
                      </Label>
                      <Input
                        type="number"
                        name="quantity"
                        id="quantity"
                        value={element.quantity || ""}
                        onChange={e => handleChange(index, e)}
                      />
                    </Col>
                    <Col md={4}>
                      <Label for="minimumOrderQuantity">
                        Minimum Order Quantity
                      </Label>
                      <Input
                        id="minimumOrderQuantity"
                        name="minimumOrderQuantity"
                        type="number"
                        placeholder="0"
                        value={element.minimumOrderQuantity}
                        onChange={e => handleChange(index, e)}
                      />
                    </Col>
                    {element.shippingMultiplyWithQuantity === true ? (
                      <Col md="4">
                        <FormGroup>
                          <Label for="shippingCost">Shipping Cost</Label>
                          <Input
                            id="shippingCost"
                            name="shippingCost"
                            type="number"
                            placeholder="0"
                            value={element.shippingCost * element.totalQuantity}
                            onChange={e => handleChange(index, e)}
                          />
                        </FormGroup>
                      </Col>
                    ) : (
                      <Col md="4">
                        <FormGroup>
                          <Label for="shippingCost">Shipping Cost</Label>
                          <Input
                            id="shippingCost"
                            name="shippingCost"
                            type="number"
                            placeholder="0"
                            value={element.shippingCost}
                            onChange={e => handleChange(index, e)}
                          />
                        </FormGroup>
                      </Col>
                    )}
                    <Col md="12" className="mt-3 ">
                      <FormGroup switch>
                        <Label for="" className="ms-2 float-end">
                          Shipping Cost Multiply With Quantity
                        </Label>
                        <Input
                          className="float-end"
                          type="switch"
                          role="switch"
                          defaultChecked={element.shippingMultiplyWithQuantity}
                          onChange={e => handleChange(index, e)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col sm={12}>
                      <div className="mt-2">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(index, acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone variant_img_dropzone px-0">
                              <div
                                className="dz-message p-0"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="">
                                  <img
                                    src={banner}
                                    className=" mx-auto d-block mb-1"
                                    alt=""
                                    height={50}
                                    width={50}
                                  />
                                </div>
                                <h6>upload image</h6>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                        <div
                          className="dropzone-previews row"
                          id="file-previews"
                        >
                          {element?.images &&
                            element?.images.length > 0 &&
                            element?.images.map((img, imgInd) => {
                              return (
                                <div
                                  key={imgInd}
                                  className="col-md-4 mt-1"
                                  style={{
                                    position: "relative",
                                  }}
                                >
                                  <div className="border">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <ProfileImage
                                            image={img}
                                            height="80"
                                            classNames="avatar-sm rounded bg-light"
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {img.name || img.filename}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>{img.formattedSize}</strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                    <i
                                      style={{
                                        position: "absolute",
                                        right: "16px",
                                        cursor: "pointer",
                                        top: 0,
                                      }}
                                      className="mdi mdi-close-thick font-size-18 text-danger"
                                      id="removeImgtooltip"
                                      onClick={() => removeImage(imgInd, index)}
                                    />
                                  </div>
                                </div>
                              );
                            })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </CardBody>
            </Card>
          ))
        ) : (
          <div className="mt-4 mx-3 text-center">No Varinats found</div>
        )}
        <hr className="mt-5" />

        <div className="d-flex mt-2 justify-content-end float-end">
          {route?.catId ? (
            <>
              <Button
                type="button"
                color="primary"
                className="w-md btn-sm ms-2 align-items-center mb-3 me-2"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Next <i className="bx bx-right-arrow-alt mx-2"></i>{" "}
                Specifications
              </Button>
            </>
          ) : (
            <>
              <Button
                type="button"
                color="primary"
                className="w-md btn-sm ms-2 mb-3 me-2"
                onClick={() => {
                  handleSubmit();
                }}
              >
                Save Changes
              </Button>
            </>
          )}
        </div>
      </Card>
    </>
  );
};
