import React from "react"
import { Container, Card, CardBody, Button } from "reactstrap"
import { useState } from "react"
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap"

export const OrderList = props => {
  const [activeTab, setActiveTab] = useState("1")
  return (
    <React.Fragment>
     
        <Card>
          <CardBody>
            <div className="input-group col-md-4">
              <input
                className="form-control py-2  rounded-pill bg-light "
                type="search"
                id="example-search-input"
                placeholder="search for orders"
              />
              {/* <span className="input-group-append"></span> */}
            </div>
            <div className="tabs mt-3 ">
              <Nav tabs>
                <NavItem>
                  <NavLink
                    className={activeTab == "1" ? "active" : ""}
                    onClick={() => setActiveTab("1")}
                  >
                    <small>RECENT ORDERS</small>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab == "2" ? "active" : ""}
                    onClick={() => setActiveTab("2")}
                  >
                    <small> ALL ORDERS</small>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={activeTab == "3" ? "active" : ""}
                    onClick={() => setActiveTab("3")}
                  >
                    <small>FILTERS</small>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={activeTab}>
                {/* <TabPane tabId="1">Tab 1 Content</TabPane>
                    <TabPane tabId="2">Tab 2 Content</TabPane> */}
              </TabContent>
            </div>
          </CardBody>
          <CardBody className="border border-bottom-1">
            <div className="d-flex buttons">
              <div className="me-5">
                <h6>Naveen Kumar</h6>
                <small className="text-secondary">Order NO #1000047</small>
                <br />
                <small className="text-secondary">
                  Created:03-04-2022,5:00pm
                </small>
              </div>
              <div className="text-secondary">
                Just now
                <div className="mt-3">
                  <Button className=" btn btn-sm bg-success rounded border border-0">
                    Completed
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody className="border border-bottom-1">
            <div className="d-flex">
              <div className="me-5">
                <h6> Venu</h6>
                <small className="text-secondary">Order NO #1000047</small>
                <br />
                <small className="text-secondary">
                  Created:03-04-2022,5:00pm
                </small>
              </div>
              <div className="text-secondary">
                5 Hours
                <div className="mt-3">
                  <Button className=" btn btn-sm bg-danger rounded border border-0 ps-3 pe-3">
                    pending
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
          <CardBody className="border border-bottom-1">
            <div className="d-flex">
              <div className="me-5">
                <h6> Kumar</h6>
                <small className="text-secondary">Order NO #1000047</small>
                <br />
                <small className="text-secondary">
                  Created:03-04-2022,5:00pm
                </small>
              </div>
              <div className="text-secondary">
                2 Hours
                <div className="mt-3">
                  <Button className=" btn btn-sm bg-danger rounded border border-0 ps-3 pe-3">
                    pending
                  </Button>
                </div>
              </div>
            </div>
          </CardBody>
        </Card>
     
    </React.Fragment>
  )
}
