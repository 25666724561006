import Breadcrumb from "components/Common/Breadcrumb";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import React, { useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { MetaTags } from "react-meta-tags";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import EarningsDetails from "./EarningsDetails";
import moment from "moment";

const SettlementsByCode = props => {
  const dataArray = props.location.state
    ? props.location.state.dataArray
    : null;
  console.log(dataArray, "dataArray");
  const { SearchBar } = Search;
  let { id } = useParams();
  const settlementList = dataArray?.filter(ele => ele?.settlementCode === id);
  console.log(settlementList, "settlemets");
  const [earningsModal, setEarningsmodal] = useState(false);
  const [earnigsData, setearnigsData] = useState();

  const toggleEarningsModal = data => {
    setEarningsmodal(!earningsModal);
    setearnigsData(data);
  };
  const customer = () => [
    {
      dataField: "orderNumber",
      text: "OrderId",
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div>
                <p className="text-primary fw-bold mb-0">#{row?.orderNumber}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "orderAmount",
      text: "Order Total",
      formatter: row => {
        return (
          <div className="">
            <h6 className="">{row ? row?.toFixed(2) : "0"}</h6>
          </div>
        );
      },
    },
    {
      dataField: "partnerEarnings",
      text: "Partner Earnings",
      formatter: row => {
        return (
          <div className="">
            <h6 className="">{row ? row?.toFixed(2) : "0"}</h6>
          </div>
        );
      },
    },
    {
      dataField: "completionDate",
      text: "Completion Date",
      formatter: (cell, row) => (
        <>{moment(row?.completionDate).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      dataField: "settlementCode",
      text: "Settlement Code",
      formatter: (cell, row) => {
        console.log(row, "row settled");
        return (
          <div
            className=""
            onClick={() => {
              handleRouterChange(row?.settlementCode);
            }}
          >
            <h6 className="">
              {row?.settlementCode ? row?.settlementCode : "_"}
            </h6>
          </div>
        );
      },
    },
    {
      dataField: "bankCode",
      text: "Bank Code",
      formatter: (cell, row) => {
        console.log(row, "row settled");
        return (
          <div className="">
            <h6 className="">{row?.bankCode ? row?.bankCode : "_"}</h6>
          </div>
        );
      },
    },
    {
      dataField: "stylistId",
      text: "Stylist",
      formatter: (cellContent, row) => {
        // if (access === "Company") {
        console.log(row, "partnerEarnings");
        return (
          <div className="">
            {row?.stylistId?.legalname ? row?.stylistId?.legalname : ""}{" "}
            {row?.stylistId?.shortname ? row?.stylistId?.shortname : ""}
          </div>
        );
        // } else {
        //   return null;
        // }
      },
      //   hidden: access !== "Company",
    },
    {
      dataField: "status",
      text: "Settlements",
      formatter: (cellContent, row) => {
        console.log("row=", row);
        const badgeColor = "font-size-12 px-2 py-1 badge-soft-";
        return (
          <div>
            <div className="d-flex gap-3">
              <div>
                {row?.settlementStatus === true ? (
                  <i
                    className="bx bxs-check-circle font-size-24"
                    style={{ color: "#07a021" }}
                  ></i>
                ) : (
                  <i
                    className="bx bxs-x-circle font-size-24"
                    style={{ color: "#B30101" }}
                  ></i>
                )}
              </div>
              {row?.settlementStatus === true ? (
                <p className="font-size-12 mt-1">
                  {moment(row?.settlementDate)?.format("DD-MMM-YYYY")}
                </p>
              ) : (
                <p className="font-size-12 mt-1">Pending</p>
              )}
              <div>
                <Badge
                  className={badgeColor + "info"}
                  color={"bg-secondary"}
                  pill
                  onClick={() => {
                    toggleEarningsModal(row);
                  }}
                  role="button"
                >
                  <i className="mdi mdi-eye " id="viewtooltip" />
                </Badge>
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: settlementList?.length, // replace later with size(customers),
    custom: true,
  };

  return (
    <>
      <React.Fragment>
        {/* <Spinner loading={loading} /> */}
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb
              title="Settlements"
              breadcrumbItem="Settlements By Code"
            />
            <BreadcrumbSub
              subtile="Settled Orders"
              SubBreadcrumbItem="Company"
            />
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="settlementList"
                      columns={customer()}
                      data={settlementList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="settlementList"
                          data={settlementList}
                          columns={customer()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                                <div className="d-flex align-items-center">
                                  <div className="search-box d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="settlementList"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      <EarningsDetails
        toggle={toggleEarningsModal}
        modal={earningsModal}
        data={earnigsData}
      />
    </>
  );
};

export default SettlementsByCode;
