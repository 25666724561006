export const createImageUrl = body => {
  console.log(body, "body");
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/image/upload`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at image url create ${e}`));
};
