import React from "react"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import "./style.scss"
import { OrderHistory } from "./OrderHistory"
import { OrderDetails } from "./OrderDetails"
import { OrderList } from "./OrderList"

export const OrderIndex = () => {
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="" />
          <Row>
            <Col md="3">
              <OrderList />
            </Col>
            <Col md="6">
              <OrderDetails />
            </Col>
            <Col md="3">
              <OrderHistory />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default OrderIndex
