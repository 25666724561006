import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import { withRouter } from "react-router-dom";
import * as _ from "lodash";

import {
  getIndvidualById,
  updateIndvidualFields,
} from "services/indvidual.service";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Button,
  Input,
} from "reactstrap";


const zipcodeArray = [
  {
    zipcode: "",
    address: "",
  },
  {
    zipcode: "",
    address: "",
  },
  {
    zipcode: "",
    address: "",
  },
];

import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import "./individual.scss";
const ServiceLocations = props => {
  const { id } = useParams();
  const [zipcodeList, setZipcodeList] = useState([...zipcodeArray]);
  let currentProvider;
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const leaveToList = () => {
    props.history.push("/Provider-list-individul");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
    props.history.push("/Provider-list-individul");
  };
  useEffect(() => {
    getCurrentProvider();
  }, []);

  const getCurrentProvider = () => {
    getIndvidualById(id)
      .then(res => {
        if (res.data) {
          if (res.data.zipcodes?.length > 0) {
            setZipcodeList(res.data.zipcodes);
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Added
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              // onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  const handleChange = (value, index, type) => {
    let list = [...zipcodeList];
    list[index][type] = value;
    setZipcodeList(list);
  };
  const save = () => {
    const zipcodes = zipcodeList;
    updateIndvidualFields(id, { zipcodes })
      .then(res => {
        setsuccess_msg(true);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Provider Individual Service Locations | stylepro</title>
        </MetaTags>
        <Container fluid={false}>
          <Breadcrumb
            title="Individual Provider"
            breadcrumbItem="Service Locations"
          />
          <Card>
            <CardBody className="service_locations">
              <Row>
                <Col md={1}>
                  <h5 className="font-size-14 zipcode_header">Sl. no </h5>
                </Col>
                <Col md={3}>
                  <h5 className="font-size-14 zipcode_header">
                    ZIP Code / PIN Code{" "}
                  </h5>
                </Col>
                <Col md={4}>
                  <h5 className="font-size-14 zipcode_header">
                    {" "}
                    Location Name{" "}
                  </h5>
                </Col>
              </Row>
              {zipcodeList.map((ele, ind) => {
                return (
                  <Row key={ind} className="my-3">
                    <Col md={1}>
                      <h5 className="font-size-14 zipcode_td sl_no">{ind}</h5>
                    </Col>
                    <Col md={3}>
                      <Input
                        className="font-size-14 zipcode_td"
                        value={ele.zipcode}
                        onChange={event =>
                          handleChange(event.target.value, ind, "zipcode")
                        }
                      />
                    </Col>
                    <Col md={4}>
                      <Input
                        className="font-size-14 zipcode_td"
                        value={ele.address}
                        onChange={event =>
                          handleChange(event.target.value, ind, "address")
                        }
                      />
                    </Col>
                  </Row>
                );
              })}

              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  className="w-md btn-sm me-4"
                  type="submit"
                  onClick={save}
                >
                  Save Changes
                </Button>
                <Button
                  color="light"
                  className="w-md btn-sm"
                  onClick={leaveToList}
                >
                  cancel
                </Button>
              </div>
              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(ServiceLocations);
