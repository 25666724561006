export const AddNote = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/adminNotes/create`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at asigning a note ${e}`));
};


export const AssignNote = body => {
    return fetch(
      `${process.env.REACT_APP_DATABASEURL}/api/v1/adminNotes/assign`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at asigning a note ${e}`));
  };