import {
GET_PROVIDERS,GET_PROVIDERS_FAIL,GET_PROVIDERS_SUCCESS
} from "./actionTypes"

const INIT_STATE = {
  providers: [],
  error: {},
}

const Providers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROVIDERS_SUCCESS:
      return {
        ...state,
        providers: action.payload,
      }

    case GET_PROVIDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Providers
