import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  FormGroup,
  //   Form,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SweetAlert from "react-bootstrap-sweetalert";
import "./create.scss";
import Select from "react-select";
import {
  createZipcode,
  updateZipcode,
  getZipcode,
} from "services/zipcode.service";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useEffect } from "react";
import csc from "countries-states-cities";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const CreateZipcode = ({ props, modal, toggle, id = false, reload }) => {
  const defaultStateList = [
    {
      label: "please Select Country",
      value: "please Select Country",
      isDisabled: true,
    },
  ];
  const defaultCityList = [
    {
      label: "please Select State",
      value: "please Select State",
      isDisabled: true,
    },
  ];
  const [selectedGroup, setselectedGroup] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState(defaultStateList);
  const [citiesList, setCitiesList] = useState(defaultCityList);
  const [enabledProducts, setEnabledProducts] = useState(false);
  const [enabledServices, setEnabledServices] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    value: "IN",
    id: 101,
    label: "India",
  });
  const [selectedState, setSelectedState] = useState({
    value: "Telangana",
    id: 4012,
    label: "Telangana",
  });

  const [selectedCity, setSelectedCity] = useState({
    value: "Hyderabad",
    id: 5001,
    label: "Hyderabad",
  });
  useEffect(() => {
    handleSelectCountry(selectedCountry);
    handleSelectState(selectedState);
    setSelectedCity(selectedCity);
  }, []);
  // const { id } = useParams();
  const initialValues = {
    zipcode: "",
    status: "",
    country: {},
    state: {},
    city: {},
    X1: null,
    X2: null,
    area: "",
  };

  const validationSchema = Yup.object().shape({
    zipcode: Yup.string().required("Please Enter Zipcode"),
    area: Yup.string().required("Please Enter area"),
    status: Yup.string().required("Please Select Your Status"),
    city: Yup.object().required("Please Enter City"),
    state: Yup.object().required("Please Enter State"),
    country: Yup.object().required("Please Select Country"),
  });

  const onSubmit = values => {
    const data = {
      ...values,
      country: selectedCountry,
      state: selectedState,
      city: selectedCity,
      X1: enabledServices,
      X2: enabledProducts,
    };
    if (id) {
      handleUpdate(data);
    } else {
      handleSubmit(data);
    }
  };

  const statusList = [
    { label: "inactive", value: "inactive" },
    { label: "active", value: "active" },
  ];

  function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);

    // setValues({ ...values, ["status"]: selectedGroup.value });
  }
  const handleChange = name => event => {
    // setValues({ ...values, [name]: event.target.value });
  };

  const handleSubmit = values => {
    createZipcode(values)
      .then(res => {
        if (res?.success === true) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        reload();
        console.log(res);
      })
      .catch(err => {
        setsuccess_msg(false);
        seterror_msg(true);
        console.log(err);
      });
  };

  const handleUpdate = values => {
    updateZipcode(id, values)
      .then(res => {
        setsuccess_msg(true);
        seterror_msg(false);
        console.log(res);
        reload();
      })
      .catch(err => {
        setsuccess_msg(false);
        seterror_msg(true);
        console.log(err);
      });
  };
  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully New Zip Code created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  useEffect(() => {
    //Get All Countries List
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
  }, []);

  function handleSelectCountry(event) {
    //Get All States Under the Selected Country
    let stateArray = [];
    csc.getStatesOfCountry(event.id).forEach(ele => {
      let stateObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      stateArray.push(stateObj);
    });
    setStatesList(stateArray);
  }

  function handleSelectState(event) {
    let citiesArr = [];
    csc.getCitiesOfState(event.id).forEach(ele => {
      let cityObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      citiesArr.push(cityObj);
    });
    setCitiesList(citiesArr);
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Zipcode - Create / Update| stylepro </title>
        </MetaTags>
        <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
          <ModalHeader toggle={toggle}>
            {" "}
            {id ? "Edit Zipcode" : "Create Zipcode"}
          </ModalHeader>
          <ModalBody className="bg-light">
            <Container fluid={true}>
              {/* <Breadcrumb title="Zip Codes" breadcrumbItem="Create Zip Code" /> */}
              <Card>
                <CardBody>
                  <CardTitle className="mb-4"></CardTitle>
                  <Row>
                    <Formik
                      enableReinitialize
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={onSubmit}
                    >
                      {({
                        errors,
                        touched,
                        isSubmitting,
                        setFieldValue,
                        handleChange,
                        values,
                      }) => {
                        useEffect(() => {
                          if (id) {
                            getZipcode(id)
                              .then(res => {
                                console.log(res, "resss");
                                const data = { ...res.data };
                                const fields = [
                                  "zipcode",
                                  "status",
                                  "country",
                                  "state",
                                  "city",
                                  "X1",
                                  "X2",
                                  "area",
                                ];
                                fields?.forEach(field =>
                                  setFieldValue(field, data[field], false)
                                );
                                setselectedGroup({
                                  label: data?.status,
                                  value: data?.status,
                                });
                                setEnabledServices(data?.X1);
                                setEnabledProducts(data?.X2);
                                // setCountriesList({
                                //   lable: data.country,
                                //   value: data.country,
                                // });
                                // setStatesList({
                                //   lable: data.state,
                                //   value: data.state,
                                // });
                                // setCountriesList({
                                //   lable: data.city,
                                //   value: data.city,
                                // });
                              })
                              .catch(e => {
                                console.log(e);
                              });
                          }
                        }, [id]);

                        return (
                          <Form>
                            <Row className="m-3">
                              <Col sm={4} className="mt-3">
                                <Label
                                  htmlFor="country"
                                  className="col-form-label"
                                >
                                  Country
                                </Label>
                                <Select
                                  name="country"
                                  id="country"
                                  onChange={event => {
                                    handleSelectCountry(event);
                                    setFieldValue("country", event);
                                    setFieldValue("state", null);
                                    setFieldValue("city", null);
                                    setSelectedCountry(event);
                                  }}
                                  options={countriesList}
                                  value={selectedCountry}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col className="mt-3" sm={4}>
                                <Label
                                  htmlFor="state"
                                  className="col-form-label"
                                >
                                  State
                                </Label>
                                <Select
                                  name="state"
                                  id="state"
                                  onChange={event => {
                                    if (event) {
                                      handleSelectState(event);
                                      setFieldValue("state", event);
                                      setFieldValue("city", null);
                                      setSelectedState(event);
                                    }
                                  }}
                                  options={statesList}
                                  value={selectedState}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                              <Col className="mt-3" sm={4}>
                                <Label
                                  htmlFor="city"
                                  className="col-form-label"
                                >
                                  city
                                </Label>
                                <Select
                                  name="city"
                                  id="city"
                                  onChange={event => {
                                    setFieldValue("city", event);
                                    setSelectedCity(event);
                                  }}
                                  options={citiesList}
                                  value={selectedCity}
                                  classNamePrefix="select2-selection"
                                />
                              </Col>
                            </Row>
                            <Row className="m-3">
                              <Col sm={4} className="">
                                <Label htmlFor="zipcode">Zip Code</Label>
                                <Input
                                  name="zipcode"
                                  placeholder="Zip Code"
                                  type="text"
                                  className="form-control"
                                  id="zipcode"
                                  onChange={handleChange}
                                  value={values.zipcode}
                                  invalid={
                                    touched.zipcode && errors.zipcode
                                      ? true
                                      : false
                                  }
                                />
                                {touched.zipcode && errors.zipcode ? (
                                  <FormFeedback type="invalid">
                                    {errors.zipcode}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                              <Col sm={4} className="">
                                <Label htmlFor="area">Service Location</Label>
                                <Input
                                  name="area"
                                  placeholder="area"
                                  type="text"
                                  className="form-control"
                                  id="area"
                                  onChange={handleChange}
                                  value={values?.area}
                                  invalid={
                                    touched.area && errors.area ? true : false
                                  }
                                />
                                {touched.area && errors.area ? (
                                  <FormFeedback type="invalid">
                                    {errors.area}
                                  </FormFeedback>
                                ) : null}
                              </Col>
                            </Row>
                            <Row className="m-3">
                              <Col sm={3}>
                                <FormGroup className="mb-0">
                                  <Label htmlFor="status">
                                    Zipcode Availability Status
                                  </Label>
                                  <Select
                                    value={selectedGroup}
                                    onChange={event => {
                                      handleSelectGroup(event);
                                      handleChange("status")(event.value);
                                    }}
                                    name="status"
                                    options={statusList}
                                    classNamePrefix="select2-selection"
                                  />
                                  <Label className="statusValidation">
                                    {" "}
                                    {errors.status ? errors.status : ""}
                                  </Label>
                                </FormGroup>
                              </Col>
                            </Row>
                            <div className="d-flex">
                              <div
                                className="d-flex align-items-center ms-4"
                                role="button"
                              >
                                <div>
                                  <Input
                                    type="checkbox"
                                    className="me-2"
                                    id="services"
                                    name="services"
                                    checked={enabledServices}
                                    onClick={() =>
                                      setEnabledServices(!enabledServices)
                                    }
                                  />
                                </div>
                                <div className="verification_title">
                                  <label
                                    htmlFor="services"
                                    className="mb-0 fw-bold"
                                    role="button"
                                  >
                                    Enabled for Services
                                  </label>
                                </div>
                              </div>
                              <div
                                className="d-flex align-items-center ms-4"
                                role="button"
                              >
                                <div>
                                  <Input
                                    type="checkbox"
                                    className="me-2"
                                    id="products"
                                    name="products"
                                    checked={enabledProducts}
                                    onClick={() =>
                                      setEnabledProducts(!enabledProducts)
                                    }
                                  />
                                </div>
                                <div className="verification_title">
                                  <label
                                    htmlFor="products"
                                    className="mb-0 fw-bold"
                                    role="button"
                                  >
                                    Enabled for Products
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row justify-content-md-center m-2">
                              <Col>
                                <div className="d-flex justify-content-end">
                                  <Button
                                    color="light"
                                    className="w-md btn-sm me-3"
                                    onClick={leaveToList}
                                  >
                                    cancel
                                  </Button>
                                  {id ? (
                                    <Button
                                      color="primary"
                                      className="w-md btn-sm "
                                      type="submit"
                                    >
                                      Save Changes
                                    </Button>
                                  ) : (
                                    <Button
                                      type="submit"
                                      color="primary"
                                      className="w-md btn-sm"
                                      // onClick={handleSubmit}
                                    >
                                      Submit
                                    </Button>
                                  )}
                                </div>
                              </Col>
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  </Row>
                  {success_msg ? successPopup() : null}
                  {error_msg ? errorPopup() : null}
                </CardBody>
              </Card>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default CreateZipcode;
