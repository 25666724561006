import React, { useEffect, useState } from "react";
import { Button, Col, FormGroup, Label, Row } from "reactstrap";
import Select from "react-select";
import { getArticles } from "services/article.service";
import { useSSR } from "react-i18next";
import { updatefeildService } from "services/services.service";

export const RelatedArticleModal = ({ toggle, initValues, reloadService }) => {
  const [allArticles, setAllArticles] = useState([]);
  const [selectedArticles, setSelectedArticles] = useState([]);
  const [showArticles, setShowArticles] = useState([]);
  useEffect(() => {
    getAllArticles();
  }, []);

  const getAllArticles = () => {
    getArticles()
      .then(res => {
        setAllArticles(buildOptions(res?.data));
      })
      .catch(err => console.log(err));
  };

  const buildOptions = list => {
    const data = [];
    list?.forEach(ele => {
      let obj = {
        label: ele?.title,
        value: ele?._id,
      };
      data.push(obj);
    });
    return data;
  };

  useEffect(() => {
    if (initValues?.articles?.length) {
      setShowArticles(buildOptions(initValues?.articles));
    }
  }, []);

  const onSubmit = () => {
    updatefeildService(initValues._id, { articles: selectedArticles })
      .then(res => {
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };

  return (
    <>
      <Row className="mt-3 mx-0">
        <Col className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="brands">Related Articles</Label>
            <Select
              id="brands"
              value={showArticles}
              isMulti={true}
              onChange={event => {
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
                setSelectedArticles(data);
                setShowArticles(event);
              }}
              options={allArticles}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>

      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default RelatedArticleModal;
