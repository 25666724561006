export const createType=(body)=>{
return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/providertype/create`,{
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    //   Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body)
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
};

export const getProvidertypes=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/providertype/types`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at provider type ${e}`))  
}
export const getProvidertype=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/providertype/type/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at provider type ${e}`))  
}

export const updateProvidertypes=(id,body)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/providertype/update/${id}`,{
        method:"PUT",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    body: JSON.stringify(body)

    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at provider type ${e}`))  
}
export const deleteProvidertypes=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/providertype/delete/${id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at provider type ${e}`))  
}



