import axios from "axios";

export const createArtist = body => {
  return axios
    .post(`${process.env.REACT_APP_DATABASEURL}/api/v1/artist/create`, body)
    .then(res => {
      return res.data;
    })
    .catch(e => console.log(`error at creating Artist ${e}`));
};

export const getArtists = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/artist/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists ${e}`));
};

export const getArtistById = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/artist/get/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists ${e}`));
};

export const updateArtistById = (id, body) => {
  return axios
    .post(
      `${process.env.REACT_APP_DATABASEURL}/api/v1/artist/update/${id}`,
      body
    )
    .then(res => {
      return res.data;
    })
    .catch(e => console.log(`error at updating Artist ${e}`));
};

export const deleteArtist = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/artist/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists ${e}`));
};
