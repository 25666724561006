import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  FormGroup,
  //   Form,
  Input,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SweetAlert from "react-bootstrap-sweetalert";
import "./create.scss";
import Select from "react-select";
import { createPage, getPage, updatepage } from "services/page.service";
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useEffect } from "react";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const CreatePage = ({ toggle, modal, id = false, reload, props }) => {
  const [selectedGroup, setselectedGroup] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [contextOfPage, setContext] = useState("");
  // const { id } = useParams();
  const initialValues = {
    title: "",
    context: "",
  };
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Please Enter title"),
    context: Yup.string().required("Please Enter Your context"),
  });

  const onSubmit = values => {
    console.log("values", values);
    if (id) {
      handleUpdate(values);
    } else {
      handleSubmit(values);
    }
  };

  const handleSubmit = values => {
    createPage(values)
      .then(res => {
        setsuccess_msg(true);
        seterror_msg(false);
        console.log(res);
        reload();
      })
      .catch(err => {
        setsuccess_msg(false);
        seterror_msg(true);
        console.log(err);
      });
  };

  const handleUpdate = values => {
    updatepage(id, values)
      .then(res => {
        setsuccess_msg(true);
        seterror_msg(false);
        console.log(res);
        reload();
      })
      .catch(err => {
        setsuccess_msg(false);
        seterror_msg(true);
        console.log(err);
      });
  };
  const leaveToList = () => {
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully New Page type created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      {/* <div className="page-content">
        <MetaTags>
          <title>
            Form Layouts | stylepro - React Admin & Dashboard Template
          </title>
        </MetaTags> */}
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {" "}
          {id ? "Edit Page" : "Create Page"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid={false}>
            {/* <Breadcrumb
              title="Page"
              breadcrumbItem={id ? "Edit Page" : "Create Page"}
            /> */}
            <Card>
              <CardBody>
                <CardTitle className="mb-4"></CardTitle>
                <Row>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      handleChange,
                      values,
                    }) => {
                      const [user, setUser] = useState({});
                      const [showPassword, setShowPassword] = useState(false);

                      useEffect(() => {
                        if (id) {
                          getPage(id)
                            .then(res => {
                              const data = { ...res.data };
                              const fields = ["title", "context"];
                              fields.forEach(field =>
                                setFieldValue(field, data[field], false)
                              );
                              setContext(res.data.context);
                            })
                            .catch(e => {
                              console.log(e);
                            });
                        }
                      }, []);

                      return (
                        <Form>
                          <Row className="m-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Title</Label>
                              <Input
                                name="title"
                                placeholder="title"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.title}
                                invalid={
                                  touched.title && errors.title ? true : false
                                }
                              />
                              {touched.title && errors.title ? (
                                <FormFeedback type="invalid">
                                  {errors.title}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="m-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom7">Context</Label>
                              <div className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  data={contextOfPage}
                                  onReady={editor => {}}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("context")(data);
                                  }}
                                />
                              </div>
                            </FormGroup>
                          </Row>
                          <div className="row justify-content-md-center m-2">
                            <Col>
                              <div className="d-flex justify-content-end">
                                <Button
                                  color="light"
                                  className="w-md btn-sm me-3"
                                  onClick={leaveToList}
                                >
                                  cancel
                                </Button>
                                {id ? (
                                  <Button
                                    color="primary"
                                    className="w-md btn-sm"
                                    type="submit"
                                  >
                                    Save Changes
                                  </Button>
                                ) : (
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="w-md btn-sm"
                                    // onClick={handleSubmit}
                                  >
                                    Submit
                                  </Button>
                                )}
                              </div>
                            </Col>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </Row>
                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
          </Container>
        </ModalBody>
      </Modal>
      {/* </div> */}
    </>
  );
};

export default CreatePage;
