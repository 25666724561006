import React from "react";
import { Button } from "reactstrap";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

const SuccessAlert = ({ msg, leaveToList, stayHere }) => {
  return (
    <SweetAlert
      title="Success"
      success
      onConfirm={leaveToList}
      onCancel={stayHere}
      customButtons={
        <React.Fragment>
          <Button
            color="light"
            className="w-md btn-sm mx-1"
            onClick={leaveToList}
          >
            Leave
          </Button>
          <Button
            color="danger"
            className="w-md btn-sm mx-1"
            onClick={stayHere}
          >
            Continue
          </Button>
        </React.Fragment>
      }
    >
      {msg}
    </SweetAlert>
  );
};
export default SuccessAlert;
