import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "services/filters.service";
import { useParams } from "react-router-dom";
import { updateProduct } from "store/actions";

export const ProductFilters = ({ stepperActiveTab, handleSubmit }) => {
  const storeProduct = useSelector(state => state?.EcomProducts?.product);
  const [product, setProduct] = useState(storeProduct);
  const [allFilters, setAllFilters] = useState([]);
  const [parCatFilters, setParCatFilters] = useState(null);
  const [subCatFilters, setSubCatFilters] = useState(null);
  // const parentCatId = product?.parentCategory;
  // const subCatId = product?.category?._id;

  const [parentCatId, setParentCatId] = useState();
  const [subCatId, setSubCatId] = useState();
  const route = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (storeProduct) {
      setProduct(storeProduct);
      setParentCatId(storeProduct?.parentCategory?._id);
      setSubCatId(storeProduct?.category?._id);
    }
  }, [storeProduct]);

  useEffect(() => {
    if (parentCatId) {
      if (!parCatFilters) {
        let body = {
          parentCategory: parentCatId?._id ? parentCatId._id : parentCatId,
        };
        getFiltersByParentId(body);
      }
    }
    if (subCatId) {
      if (!subCatFilters) {
        let body = { category: subCatId };
        getFiltersByParentId(body);
      }
    }
  }, [product]);

  const getFiltersByParentId = body => {
    getFilters(body)
      .then(res => {
        if (res?.data) {
          if (
            res?.data[0] &&
            res.data[0]["parentCategory"] &&
            res.data[0]["filters"]
          ) {
            buildOptions(res.data[0]["filters"], "parent");
          }
          if (
            res?.data[0] &&
            res.data[0]["category"] &&
            res.data[0]["filters"]
          ) {
            buildOptions(res.data[0]["filters"], "sub");
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const buildOptions = (tempFilters, type) => {
    let selectedFilters = product.filters;

    let filtersArr = tempFilters;
    const result = filtersArr?.map(ele => {
      let selectedFilter = selectedFilters?.find(
        flr =>
          flr.name === ele.filterName &&
          flr.filterType != "brand" &&
          flr.filterType == type
      );
      if (selectedFilter) {
        ele["selectedOption"] = selectedFilter.value?.map(ele => {
          let obj = {
            label: ele,
            value: ele,
          };
          return obj;
        });
      }

      ele?.options?.map(opt => {
        let optionObj = opt;
        optionObj["label"] = opt?.optionName;
        optionObj["value"] = opt?.optionName;
        return optionObj;
      });
      return ele;
    });

    if (type == "sub") {
      setSubCatFilters(result);
    }
    if (type == "parent") {
      setParCatFilters(result);
    }
  };

  const handleParentFilters = (name, optionName) => {
    let filtersArr = JSON.parse(JSON.stringify([...parCatFilters]));
    let parentArr = [];
    filtersArr?.map(ele => {
      if (ele?.filterName === name) {
        ele["selectedOption"] = optionName;
      }
      parentArr.push(ele);
      return ele;
    });
    setParCatFilters(parentArr);
  };
  const handleSubFilters = (name, optionName) => {
    let filtersArr;
    filtersArr = JSON.parse(JSON.stringify([...subCatFilters]));
    filtersArr?.map(ele => {
      if (ele?.filterName === name) {
        ele["selectedOption"] = optionName;
      }
      return ele;
    });
    setSubCatFilters(filtersArr);
  };

  const handleSubmitValues = () => {
    let tempArr = [];
    let selectedFilters = product.filters;
    let brandFilter = selectedFilters.filter(ele => {
      if (ele.filterType === "brand") {
        return ele;
      }
    });
    parCatFilters?.map(ele => {
      let obj = {
        name: ele?.filterName,
        value: ele?.selectedOption?.map(ele => ele.value),
        filterType: "parent",
      };
      tempArr.push(obj);
    });
    subCatFilters?.map(ele => {
      let obj = {
        name: ele?.filterName,
        value: ele?.selectedOption?.map(ele => ele.value),
        filterType: "sub",
      };
      tempArr.push(obj);
    });

    let body = {
      filters: [...brandFilter, ...tempArr],
    };

    if (route?.catId) {
      dispatch(updateProduct(body));
      stepperActiveTab("5");
    } else {
      let basicNewValues = Object.assign(product, body);
      handleSubmit(basicNewValues);
    }
  };

  return (
    <div className="mt-3">
      <div className="">
        <Label>
          <h5>Parent Category Filters</h5>
        </Label>
        <Card>
          <CardBody>
            {parCatFilters?.length > 0 ? (
              parCatFilters?.map((ele, index) => {
                return (
                  <div key={index} className="mb-3">
                    <Row className="mx-2 my-3">
                      <Col className="" md={6}>
                        <Label for="name">Filter name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={ele?.filterName}
                          disabled
                        />
                      </Col>
                      <Col md={6}>
                        <Label for="options">
                          Options<span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="options"
                          options={ele?.options || []}
                          value={ele?.selectedOption}
                          isMulti
                          onChange={event => {
                            handleParentFilters(ele?.filterName, event);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <div className=" py-3">
                <h5>No results found</h5>
              </div>
            )}
          </CardBody>
        </Card>
        <Label>
          <h5>Sub Category Filters</h5>
        </Label>
        <Card>
          <CardBody>
            {subCatFilters?.length > 0 ? (
              subCatFilters?.map((ele, index) => {
                return (
                  <div key={index} className="mb-3">
                    <Row className="mx-2 my-3">
                      <Col className="" md={6}>
                        <Label for="name">Filter name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={ele?.filterName}
                          disabled
                        />
                      </Col>
                      <Col md={6}>
                        <Label for="options">
                          Options<span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="options"
                          options={ele?.options || []}
                          value={ele?.selectedOption}
                          isMulti
                          onChange={event => {
                            handleSubFilters(ele?.filterName, event);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <div className=" py-3">
                <h5>No results found</h5>
              </div>
            )}
            <hr className="mt-5" />
            <div className="d-flex mt-2 justify-content-end float-end">
              {route?.catId ? (
                <>
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn-sm ms-2 align-items-center"
                    onClick={() => handleSubmitValues()}
                  >
                    Next <i className="bx bx-right-arrow-alt mx-2"></i> Keywords
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="button"
                    color="primary"
                    className="w-md btn-sm ms-2"
                    onClick={() => handleSubmitValues()}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </CardBody>
        </Card>
      </div>

      {/* </CardBody>
      </Card> */}
    </div>
  );
};

export default ProductFilters;
