import React, { useEffect, useState } from "react";

import { Col, Row, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";

// import { getBrands } from "services/brand.service";
// import { getProducts } from "services/product.service";
import { getBrands } from "services/ecommerce/brand.service";
import { getProducts } from "services/ecommerce/product.service";

const Step2 = ({ step2Values, backStepper, handleSubmitStepper }) => {
  const [values, setValues] = useState(step2Values);
  const [brandsList, setBrandslist] = useState([]);
  const [productsList, setProductslist] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [selectedProducts, setSelectedProducts] = useState(null);

  useEffect(() => {
    // getCategoriesList();
    getBrandsList();
    getProductsList();
  }, []);

  const getBrandsList = () => {
    getBrands()
      .then(res => {
        // console.log(res);
        if (res.data) {
          const result = res.data.map(brand => {
            let obj = {
              label: brand.name,
              value: brand._id,
            };
            return obj;
          });
          setBrandslist(result);
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getProductsList = () => {
    getProducts()
      .then(res => {
        // console.log(res);
        const list = [];
        res?.data?.map(product => {
          list.push({ label: product.name, value: product._id });
        });
        setProductslist(list);
      })
      .catch(err => {
        console.log(err);
      });
  };

  function handleBrands(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["brands"]: data });
    setSelectedBrands(selectedMulti);
  }

  function handleProducts(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["products"]: data });
    setSelectedProducts(selectedMulti);
  }

  const handleSubmit = () => {
    let body = { ...values };
    body["brands"] = JSON.stringify(body["brands"]);
    body["products"] = JSON.stringify(body["products"]);
    handleSubmitStepper(body);
  };

  return (
    <>
      <Row className="mt-3 mx-0">
        <Col md={6} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="brands">Brands</Label>
            <Select
              id="brands"
              value={selectedBrands}
              isMulti={true}
              onChange={event => {
                handleBrands(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={brandsList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
        <Col md={6} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="products">Products</Label>
            <Select
              id="products"
              value={selectedProducts}
              isMulti={true}
              onChange={event => {
                handleProducts(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={productsList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={backStepper}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => handleSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default Step2;
