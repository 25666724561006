import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";

import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Button,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Common/Breadcrumb";
import profileImg from "assets/images/users/avatar-3.jpg";
import "./ViewCategory.styles.scss";
import ViewService from "pages/Services/ViewSevice";
import { getCategory } from "services/category.service";
import { getServicesByQuery } from "services/services.service";
import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";
import CreateCategory from "pages/Categories/create";
import CreateService from "pages/Services/create";
import moment from "moment";

const defaultValues = {
  name: "",
  color: "",
  order: "",
  tagline: "",
  type: "",
  parentCategory: "",
  image: {},
  createdAt: null,
  updatedAt: null,
};

const ViewCategory = ({ toggle, modal, id = false }) => {
  let history = useHistory();
  // const { id } = useParams();
  const [values, setValues] = useState(defaultValues);
  const [serviceList, setServiceList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalSub, setModalSub] = useState(false);
  const [activeSubId, setActiveSubId] = useState(false);
  const [activeServiceId, setActiveServiceId] = useState(false);
  const [modalService, setModalService] = useState(false);
  const [activeViewId, setActiveViewId] = useState(false);
  const [modalView, setModalView] = useState(false);
  let { image } = values;

  const toggleView = () => {
    console.log("CLIKCED MODAL", !modalView);
    if (modalView) {
      setActiveViewId(null);
    }
    setModalView(!modalView);
  };

  const toggleService = () => {
    console.log("CLIKCED MODAL", !modalService);
    if (modalService) {
      setActiveServiceId(null);
    }
    setModalService(!modalService);
  };
  const toggleSub = () => {
    console.log("CLIKCED MODAL", !modalSub);
    if (modalSub) {
      setActiveSubId(null);
    }
    setModalSub(!modalSub);
  };
  const getServices = body => {
    getServicesByQuery(body)
      .then(res => {
        if (res.data) {
          setServiceList([...res.data]);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getCategory(id)
      .then(res => {
        let {
          color,
          tagline,
          image,
          name,
          order,
          type,
          parentCategory,
          createdAt,
          updatedAt,
          _id,
        } = res.data;
        let obj = {
          name,
          color,
          order,
          type,
          parentCategory,
          tagline,
          image,
          createdAt,
          updatedAt,
          _id,
        };
        setValues({ ...obj });
        getServices({ categories: id, type: type });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const goToPreviousPath = () => {
    console.log(history);
    history.goBack();
  };

  const renderServiceList = () => {
    return (
      <Card className="view-users parentCat_view">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">SERVICES</h5>
            {/* <Link to="/Services-create"> */}
            <Button
              type="button"
              color="primary"
              className="btn-rounded btn-md"
              onClick={toggleService}
            >
              <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
              Add Service
            </Button>
            {/* </Link> */}
          </div>
          <div className=" my-3 me-3 hr"> </div>
          {serviceList && serviceList.length > 0 ? (
            <Row className="mt-2">
              {serviceList.map((ser, index) => {
                return (
                  <Col key={index} md="3">
                    <Card className="shadow-sm mt-3">
                      <CardBody>
                        <div className="text-center">
                          <ProfileImage
                            image={ser.banner ? ser.banner : null}
                            classNames="rounded-circle avatar-md"
                          />
                          <h5 className="my-2">{ser.name}</h5>
                          <p className="category_status">
                            <span
                              className={
                                "status_dot " +
                                (ser.status == "Active" ? "active" : "inactive")
                              }
                            ></span>
                            <span className="ms-3">{ser.status}</span>
                          </p>
                          {/* <p className="category_status"><span className={"status_dot " + (ser.enableBooking =="true"?"active":"inactive")}></span><span className="ms-3">{ser.enableBooking == "true" ? "Booking Enabled" : "Booking Disabled"}</span></p> */}
                        </div>
                        <div className=" my-2 hr"> </div>
                        <div className="d-flex justify-content-between pt-1">
                          {/* <Link
                            to={`/services-update/${ser._id}`}
                            className="w-50 pe-2"
                          > */}
                          <input
                            type="button"
                            value="Edit"
                            className="w-100 btn btn-sm btn-outline-light me-3"
                            onClick={() => {
                              setActiveServiceId(ser._id);
                              toggleService();
                            }}
                          />
                          {/* </Link> */}
                          {/* <Link
                            to={`/services-view/${ser._id}`}
                            className="w-50 ps-2"
                          > */}
                          <input
                            type="button"
                            value="View"
                            className="w-100 btn btn-sm btn-outline-primary"
                            onClick={() => {
                              setActiveViewId(ser._id);
                              toggleView();
                            }}
                          />
                          {/* </Link> */}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="text-center">
              <p className="mt-4 mb-2"> No data found</p>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}> SubCategory View</ModalHeader>
        <ModalBody className="bg-light">
          <Spinner loading={loading} />
          <Container fluid>
            <Card className="view-users">
              <CardBody>
                <div className="float-end">
                  {/* <Link to="#" onClick={() => goToPreviousPath()}>
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link> */}
                  {/* <Link className="text-end" to={`/category-update/${id}`}> */}
                  <Badge
                    className="font-size-12 badge-soft-warning py-1 px-3 "
                    color={"bg-warning"}
                    pill
                    onClick={() => {
                      setActiveSubId(values._id);
                      toggleSub();
                    }}
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                  {/* </Link> */}
                </div>
                <Row>
                  <Col md={4} className="">
                    <div className="text-center my-4">
                      {/* <img src={profileImg} alt="" className="img-thumbnail" /> */}
                      <ProfileImage
                        image={image}
                        classNames="img-thumbnail max_width_250"
                      />
                      <h5 className="mt-4 text-muted">{values.name}</h5>
                    </div>
                  </Col>
                  <Col md={8} className=" pt-4 mb-4 px-0 view_detail">
                    <Row className="mx-3 ps-2">
                      <p className="ps-0">
                        <span className="pe-3 fw-bold">Sub Category Name </span>{" "}
                        <span className="text-muted">{values.name} </span>
                      </p>
                      <p className="ps-0 ">
                        <span className="pe-3 fw-bold">Color </span>{" "}
                        <span className="text-muted">{values.color} </span>
                      </p>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>
                    <Row className="mx-3 my-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Order</p>
                        <p className="text-muted">{values.order}</p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Parent Category</p>
                        <p className="text-muted">
                          {values.parentCategory.name}
                        </p>
                      </Col>

                      <Col md={12} className="mt-4">
                        <p className="fw-bold mb-1">Tagline</p>
                        {values.tagline ? (
                          <div
                            dangerouslySetInnerHTML={{ __html: values.tagline }}
                          />
                        ) : (
                          "_"
                        )}
                      </Col>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>
                    <Row className="mx-3 my-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Created</p>
                        <p className="text-muted">
                          {moment(
                            values.createdAt ? values.createdAt : "_"
                          ).format("MMM Do YYYY")}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Last Updated</p>
                        <p className="text-muted">
                          {moment(
                            values.updatedAt ? values.updatedAt : "_"
                          ).format("MMM Do YYYY")}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {renderServiceList()}
          </Container>
        </ModalBody>
      </Modal>
      {modalSub && (
        <CreateCategory toggle={toggleSub} modal={modalSub} id={activeSubId} />
      )}
      {modalService && (
        <CreateService
          toggle={toggleService}
          modal={modalService}
          id={activeServiceId}
        />
      )}
      {modalView && (
        <ViewService toggle={toggleView} modal={modalView} id={activeViewId} />
      )}
    </>
  );
};

export default ViewCategory;
