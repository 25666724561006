import {
  GET_PRODUCT_ID,
  GET_PRODUCT_FAIL,
  GET_PRODUCT,
  GET_PRODUCT_EMPTY,
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  GET_PRODUCTS,
} from "./actionTypes";

const INIT_STATE = {
  product: [],
  error: {},
  loading: false,
  products: {},
};

const EcomProducts = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCT_ID:
      return {
        ...state,
        product: action.payload,
      };
    case GET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case GET_PRODUCT_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    case GET_PRODUCT_EMPTY:
      return {
        ...state,
        product: action.payload,
      };
    case CREATE_PRODUCT:
      return {
        ...state,
        product: action.payload,
      };
    case UPDATE_PRODUCT:
      return {
        ...state,
        product: { ...state.product, ...action.payload },
      };
    default:
      return state;
  }
};

export default EcomProducts;
