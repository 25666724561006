import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import Select from "react-select";
import * as Yup from "yup";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Spinner from "components/spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import { UpdateOrderSettlement } from "services/settlements.service";
import { getSkuCode } from "services/ecommerce/product.service";

const defaultValues = {
  orders: [],
  code: "",
  bank: "",
  date: "",
};
const CreateSettlement = ({ toggle, modal, settledOrders, reload }) => {
  console.log(settledOrders, "settledorders");
  const settledOrderIds = settledOrders.map(order => order?._id);
  const initialValues = defaultValues;
  const [couponCode, setcouponCode] = useState();
  const [values, setValues] = useState(defaultValues);
  const [success_msg, setsuccess_msg] = useState(false);
  const [selectedBank, setSelectedBank] = useState({});
  const [error_msg, seterror_msg] = useState(false);
  const [loading, setLoading] = useState(false);
  const validationSchema = Yup.object().shape({
    code: Yup.string().required("Please Enter title"),
    // type: Yup.string().required("Please Select status"),ss
    // code: Yup.string().required("Please Upload Image"),
  });
  function handleSelectBank(event) {
    setValues({ ...values, ["bank"]: event.value });
    setSelectedBank(event);
  }
  const generateRandomCode = () => {
    getSkuCode()
      .then(res => {
        setcouponCode(res?.data);
        setValues({ ...values, ["code"]: couponCode });
      })
      .catch(err => console.log(err));
  };
  const sortedSettlementList = settledOrders?.sort((a, b) => {
    return new Date(b?.completionDate) - new Date(a?.completionDate);
  });
  function calculateTotalPartnerEarnings(orders) {
    let totalPartnerEarnings = 0;

    orders.forEach(order => {
      totalPartnerEarnings += order.partnerEarnings ? order.partnerEarnings : 0;
    });

    return totalPartnerEarnings;
  }
  const totalPartnerEarnings = calculateTotalPartnerEarnings(settledOrders);
  const BanksList = [
    {
      label: "Stylify-ICICI-1212",
      value: "Stylify-ICICI-1212",
    },
    {
      label: "Cash",
      value: "Cash",
    },
  ];
  const onSubmit = values => {
    saveChanges(values);
  };
  const saveChanges = values => {
    setLoading(true);
    let body = {
      orders: settledOrderIds,
      code: couponCode?.toUpperCase(),
      bank: selectedBank.value,
      date: values?.date,
    };
    const ObjBody = JSON.stringify(body);
    UpdateOrderSettlement(ObjBody)
      .then(res => {
        if (res?.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
    setValues(defaultValues);
    setSelectedBank({});
    setcouponCode("");
    // emptySelectedOrders([]);
  };

  const onCancel = () => {
    setValues(defaultValues);
    setcouponCode("");
    setSelectedBank({});
    // emptySelectedOrders([]);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Settings Updated.
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        size="xl"
        onClosed={() => {
          onCancel();
        }}
      >
        <ModalHeader toggle={toggle}>
          <div className="d-flex align-items-center">
            <h5 className="fw-bold text-uppercase mb-0">Orders settlement</h5>
          </div>
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <div className="mt-3">
              <Card>
                <CardBody className="">
                  <h5 className="mb-4">Settlement Amount Details</h5>
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Order Id</th>
                        <th>Stylist</th>
                        <th>Completion Date</th>
                        <th>Partner Amount</th>
                      </tr>
                    </thead>
                    {sortedSettlementList?.map((ele, index) => {
                      return (
                        <tbody key={index}>
                          <tr>
                            <td>{index + 1}</td>
                            <td className="fw-bold">#{ele?.orderNumber}</td>
                            <td>
                              {ele?.stylistId?.legalname}{" "}
                              {ele?.stylistId?.shortname}
                            </td>
                            <td>
                              {moment(ele?.completionDate).format(
                                "DD-MMM-YYYY"
                              )}
                            </td>
                            <td>
                              {ele?.partnerEarnings
                                ? ele?.partnerEarnings?.toFixed(2)
                                : 0}
                            </td>
                          </tr>
                        </tbody>
                      );
                    })}
                    <td colSpan={4} className="p-2 fw-bold font-size-16">
                      Grand Total
                    </td>
                    <td className="p-2 fw-bold font-size-16">
                      {totalPartnerEarnings?.toFixed(2)}
                    </td>
                  </Table>
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                  >
                    {({
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      handleChange,
                      setFieldTouched,
                      values,
                    }) => {
                      return (
                        <Form>
                          <Row>
                            <Col md={4}>
                              <FormGroup>
                                <Label
                                  for="code"
                                  className="d-flex justify-content-between"
                                >
                                  <div>Settlement Code </div>

                                  {!couponCode ? (
                                    <Button
                                      className=" border border-0 p-0 px-2 text-info "
                                      color="light"
                                      role="button"
                                      onClick={() => generateRandomCode()}
                                    >
                                      Generate Bank code
                                    </Button>
                                  ) : (
                                    ""
                                  )}
                                </Label>
                                <Input
                                  type="text"
                                  name="code"
                                  id="code"
                                  placeholder="Settlement Code"
                                  value={
                                    couponCode
                                      ? couponCode?.toUpperCase()
                                      : values?.code.toUpperCase()
                                  }
                                  onChange={event => {
                                    handleChange("code")(event.target.value);
                                  }}
                                  // disabled={true}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="bank">Settlement Type</Label>
                                <Select
                                  id="bank"
                                  options={BanksList || []}
                                  placeholder="Select..."
                                  value={selectedBank}
                                  onChange={event => {
                                    handleSelectBank(event);
                                    handleChange("bank")(event.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="date">Settled Date</Label>
                                <DatePicker
                                  name="date"
                                  selected={
                                    values?.date
                                      ? moment(
                                          values.date,
                                          "YYYY-MM-DD"
                                        ).toDate()
                                      : null
                                  }
                                  onChange={date => {
                                    const formattedDate =
                                      moment(date).format("YYYY-MM-DD");
                                    setFieldValue("date", formattedDate);
                                  }}
                                  dateFormat="dd-MMM-yyyy" // Display format
                                  className="form-control"
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="total">Total Settled Amount</Label>
                                <Input
                                  type="text"
                                  name="total"
                                  id="total"
                                  placeholder="Total Settled Amount"
                                  value={totalPartnerEarnings?.toFixed(2)}
                                  disabled={true}
                                />
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr></hr>
                          <div className="d-flex justify-content-end">
                            <Button
                              color="light"
                              className="w-md btn-sm me-3"
                              onClick={leaveToList}
                            >
                              Cancel
                            </Button>

                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm"
                              onClick={() => {
                                onSubmit(values);
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </CardBody>
              </Card>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
      <Spinner loading={loading} />
    </>
  );
};

export default CreateSettlement;
