import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router-dom";
import { Card, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import Spinner from "components/spinner";
import "pages/Services/list.scss";
import View from "./View";
import { getArtistById } from "services/artist.service";

const ArtistInfo = () => {
  const [values, setValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [closeTab, setCloseTab] = useState(true);
  const params = useParams();

  useEffect(() => {
    getArtistInfoById();
  }, []);

  const getArtistInfoById = () => {
    setLoading(true);
    getArtistById(params.id)
      .then(res => {
        let { data } = res;
        setValues(data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const reloadArtist = tabName => {
    setLoading(true);
    getArtistInfoById();
    setCloseTab(tabName);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Artist | stylepro</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid id="service_list">
          <Breadcrumbs title="Artist List" breadcrumbItem="Artist" />
          <Row>
            <Col className="tab_3" style={{ margin: "0px 25px" }}>
              <Card className="border-light">
                <div>
                  {values?.length !== 0 ? (
                    <div className="service_view_container">
                      <View
                        artistValues={values}
                        reloadArtist={reloadArtist}
                        closeTab={closeTab}
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ArtistInfo;
