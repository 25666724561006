import React from "react"
import { Container, Card, Row, Col, CardBody } from "reactstrap"
import banner from "./hair_color.jpeg"
import "./style.scss"
import { Form, FormGroup, Label, Input } from "reactstrap"

export const OrderDetails = () => {
  return (
    <React.Fragment>
      <Card className="">
        <CardBody className=" border-3 border-bottom">
          <Row>
            <Col md="4" className="">
              <div>
                <h6>Order Information #1000047</h6>
                <p>Create: 03-07-20, 05:00 PM</p>
              </div>
            </Col>
            <Col md="8" className="border-start border-3">
              <div className="d-flex justify-content-between ">
                <div>
                  <h6>Time Slot Date</h6>
                  <p>Fri Aug 26 2022 - 12:00 </p>
                </div>
                <div>
                  <button className="btn btn-sm btn-success">Completed</button>
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>

        <CardBody className=" border-3 border-bottom">
          <Row>
            <Col md="6" className="">
              <div>
                <h6>Naveen Kumar</h6>
                <p>
                  11, Image Gardens Rd, VIP Hills, Silicon Valley, Madhapur,
                  Telangana 5000081, India
                </p>
              </div>
            </Col>
            <Col md="6" className="border-start border-3">
              <div>
                <h6>Provider Name</h6>
                <p>Naveen Styles </p>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <div>
            <ul className="ps-0">
              <li className=" d-flex justify-content-sm-between text-decoration-none me-5 mb-3 ">
                <div>
                  <p className="border border-secondary rounded pe-2 p-1 ps-2">
                    1
                  </p>
                </div>
                <div className="">
                  <img src={banner} width="60px" className="rounded" alt="" />
                </div>
                <div className="">
                  <small className="h6">Hair Cut Full Works</small>
                  <br />
                  <small>45 min</small>
                  <br />
                  <small>Its quick, convenient & hygienic</small>
                </div>
                <div>
                  <p>Rs 1,000 X 3</p>
                </div>
                <div>
                  <p>Rs 3000.00</p>
                </div>
              </li>
              <li className=" d-flex justify-content-between text-decoration-none me-5  mb-3">
                <div>
                  <p className="border border-secondary rounded pe-2 p-1 ps-2">
                    2
                  </p>
                </div>
                <div className="">
                  <img src={banner} alt="" width="60px" className="rounded" />
                </div>
                <div>
                  <small className="h6">Hair Coloring</small>
                  <br />
                  <small>45 min</small>
                  <br />
                  <small>It quick, convenient & hygienic</small>
                </div>
                <div>
                  <p>Rs 1,000 X 3</p>
                </div>
                <div>
                  <p>Rs 3000.00</p>
                </div>
              </li>
              <li className=" d-flex justify-content-between text-decoration-none me-5  mb-3">
                <div>
                  <p className="border border-secondary rounded pe-2 p-1 ps-2 ">
                    3
                  </p>
                </div>
                <div className="float-left">
                  <img src={banner} width="60px" className="rounded" alt="" />
                </div>
                <div>
                  <small className="h6">Hair Styling</small>
                  <br />
                  <small>45 min</small>
                  <br />
                  <small>It quick, convenient & hygienic</small>
                </div>
                <div>
                  <p>Rs 1,000 X 4</p>
                </div>
                <div>
                  <p>Rs 4000.00</p>
                </div>
              </li>
            </ul>
          </div>
          <Row>
            <Col md="4"></Col>
            <Col md="8" className="p-4">
              <Row className="">
                <Col md="5" className="ps-5">
                  <p className="">Sub amount </p>
                  <p className="">Discount </p>
                  <p className="">Tax </p>
                  <p className="">
                    <b>Total Amount</b>
                  </p>
                </Col>
                <Col md="7" className="">
                  <div className="text-end">
                    <p className="me-5">10,000.00 </p>
                    <p className="me-5">10,000.00</p>
                    <p className="me-5">2,400</p>
                    <p className="me-5 ">
                      <b>22400.00</b>
                    </p>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col md="4"></Col>
            <Col md="8">
              <Form className="ms-5">
                <FormGroup>
                  <Label>Note</Label>
                  <div className="d-flex">
                    <Input type="text" name="" id="" className="me-2" />
                    <button className="btn btn-primary me-5">Save</button>
                  </div>
                </FormGroup>
              </Form>
            </Col>
          </Row>

          <div className="d-flex justify-content-between border border-2 rounded p-2 mb-2">
            <div className="d-flex">
              <i className="bx bx-md bx-check-circle text-success p-2"></i>

              <h6 className="p-2 mt-2">Order is confirmed</h6>
            </div>
          </div>

          <div className="d-flex justify-content-between border border-2 rounded p-2 ">
            <div className="d-flex">
              <i className="bx bx-md bx-check-circle text-success p-2"></i>

              <h6 className="p-2 mt-2">Payment is done.</h6>
            </div>
            <div>
              <button type="" className="btn btn-success mt-2 ">
                Refund
              </button>
            </div>
          </div>
          <div className="pt-5">
            <h5>History</h5>
          </div>
        </CardBody>
        <CardBody className="border-top border-3">
          <div className="wrapper">
            <ul className="StepProgress">
              <div className="StepProgress-item is-done d-flex justify-content-between">
                <p>Order biding started</p>
                <p className="">2022-3-4 04:12:32</p>
              </div>
              <div className="StepProgress-item is-done d-flex justify-content-between">
                <p>Order assign to the professional</p>
                <p className="">2022-3-4 04:12:32</p>
              </div>
              <div className="StepProgress-item is-done d-flex justify-content-between">
                <p>4 Stylist quoted price</p>
                <p className="">2022-3-4 04:12:32</p>
              </div>
              <div className="StepProgress-item is-done d-flex justify-content-between">
                <p>Customer accepted quoted</p>
                <p className="">2022-3-4 04:12:32</p>
              </div>
              <div className="StepProgress-item current d-flex justify-content-between">
                <p>Order is confirmed</p>
                <p className="">2022-3-4 04:12:32</p>
              </div>
            </ul>
          </div>
        </CardBody>
      </Card>
    </React.Fragment>
  )
}
