import React, { useState, useEffect } from "react";

import MetaTags from "react-meta-tags";
import Select from "react-select";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

import {
  getSettings,
  updateGlobalSettings,
} from "services/globalsettings.service";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import CurrencyList from "currency-list";

const defaultValues = {
  settingsId: "",
  applicationName: "",
  googleAuth: "",
  shortDescription: "",
  applicationLogo: "",
  backgroundImage: "",
  currencies: "",
  googleMapKey: "",
  androidVersion: "",
  iosVersion: "",
  language: "",
  applicationId: "",
  applicationSecret: "",
  theme: "",
  themeColor: "#ffffff",
  primaryColor: "#ffffff",
  mainColor: "#ffffff",
  secondColor: "#ffffff",
  backgroundColor: "#ffffff",
  accentColor: "#ffffff",
  optionalColors: "#ffffff",
};

const editSettings = props => {
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [currencieList, setCurriencieList] = useState([]);
  const [selectedCurrience, setSelected] = useState({});
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [optionalColorsList, setOptionalList] = useState([""]);
  const themeList = [
    { label: "Dark", value: "Dark" },
    { label: "Light", value: "Light" },
  ];
  const initialValues = defaultValues;

  const onSubmit = values => {
    editChanges(values);
  };

  const getList = () => {
    const data = [];
    const list = CurrencyList.getAll("en_US");
    Object.keys(list).map(e => {
      data.push({ label: list[e].code, value: list[e].code });
    });
    console.log(data);
    setCurriencieList(data);
  };
  const addOptional = () => {
    const data = [...optionalColorsList];
    data.push(" ");
    setOptionalList(data);
  };
  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const editChanges = values => {
    setLoading(true);
    console.log(values);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    formData.set("optionalColors", JSON.stringify(optionalColorsList));
    updateGlobalSettings(values)
      .then(res => {
        console.log(res, "resss");
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  function hanldeCurrience(data) {
    setSelected(data);
  }

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    props.history.push("/app-settings");
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const handelOptionColor = (value, index) => {
    const data = [...optionalColorsList];
    data[index] = value;
    setOptionalList(data);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Settings Updated.
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Layouts | stylepro - -</title>
        </MetaTags>
        {/* <Spinner loading={loading} /> */}
        <Container fluid={false}>
          <Breadcrumb title="Settings" breadcrumbItem={"Edit Settings"} />
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  setFieldTouched,
                  values,
                }) => {
                  useEffect(() => {
                    setLoading(true);
                    getList();
                    getSettings()
                      .then(res => {
                        const data = { ...res.data };
                        const fields = [
                          "settingsId",
                          "applicationName",
                          "googleAuth",
                          "shortDescription",
                          "applicationLogo",
                          "backgroundImage",
                          "currencies",
                          "googleMapKey",
                          "apiBaseURL",
                          "partnerURL",
                          "androidVersion",
                          "playStoreURL",
                          "iosVersion",
                          "appStoreURL",
                          "language",
                          "applicationId",
                          "applicationSecret",
                          "theme",
                          "themeColor",
                          "primaryColor",
                          "mainColor",
                          "secondColor",
                          "backgroundColor",
                          "accentColor",
                          "optionalColors",
                        ];
                        fields.forEach(field =>
                          setFieldValue(field, data[field], false)
                        );
                        setSelected({
                          label: res?.data?.currencies,
                          value: res?.data?.currencies,
                        });
                        setSelectedTheme({
                          label: res?.data?.theme,
                          value: res?.data?.theme,
                        });
                        setOptionalList(res.data.optionalColors);
                        setLoading(false);
                      })
                      .catch(e => {
                        console.log(e);
                      });
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-4">
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Application Name
                              </Label>
                              <Input
                                name="applicationName"
                                placeholder="ApplicationName"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.applicationName}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">
                                Application Logo
                              </Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue(
                                    "applicationLogo",
                                    acceptedFiles[0]
                                  );
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.applicationLogo &&
                                  Object.keys(values.applicationLogo).length >
                                    0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.applicationLogo}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.applicationLogo.name ||
                                                values.applicationLogo.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {
                                                  values.applicationLogo
                                                    .formattedSize
                                                }
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom08">
                                currencies
                              </Label>
                              <Select
                                // className="form-control"
                                id="validationCustom08"
                                value={selectedCurrience}
                                onChange={event => {
                                  hanldeCurrience(event);
                                  handleChange("currencies")(event.value);
                                }}
                                name="type"
                                options={currencieList}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Android Version
                              </Label>
                              <Input
                                name="androidVersion"
                                placeholder="android version"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.androidVersion}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Play Store URL
                              </Label>
                              <Input
                                name="playStoreURL"
                                placeholder="Play Store URL"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.playStoreURL}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                API Base URL
                              </Label>
                              <Input
                                name="apiBaseURL"
                                placeholder="API Base URL"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.apiBaseURL}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Language
                              </Label>
                              <Input
                                name="language"
                                placeholder="language"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.language}
                              />
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Short Description
                              </Label>
                              <Input
                                name="shortDescription"
                                placeholder="ShortDescription"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.shortDescription}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">
                                Background Image
                              </Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue(
                                    "backgroundImage",
                                    acceptedFiles[0]
                                  );
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>

                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.backgroundImage &&
                                  Object.keys(values.backgroundImage).length >
                                    0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.backgroundImage}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.backgroundImage.name ||
                                                values.backgroundImage.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {
                                                  values.backgroundImage
                                                    .formattedSize
                                                }
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Google Map Key
                              </Label>
                              <Input
                                name="googleMapKey"
                                placeholder="GoogleMapKey"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.googleMapKey}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                iOS Version
                              </Label>
                              <Input
                                name="iosVersion"
                                placeholder="iOS version"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.iosVersion}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                App Store URL
                              </Label>
                              <Input
                                name="appStoreURL"
                                placeholder="App Store URL"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.appStoreURL}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Partner URL
                              </Label>
                              <Input
                                name="partnerURL"
                                placeholder="partnerURL"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.partnerURL}
                              />
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={8}>Authentication Using Google</Col>{" "}
                        <Col md={4}>
                          <div className="d-flex justify-content-end">
                            <div className="form-check me-4 my-auto">
                              <Label
                                className="form-check-Label fw-bold mb-0"
                                htmlFor="accepted"
                              >
                                Google
                              </Label>
                              {values.googleAuth && (
                                <Input
                                  key={"googleAuth" + "checkbox"}
                                  type="checkbox"
                                  className="form-check-Input"
                                  id={"googleAuth checkbox"}
                                  value={
                                    values.googleAuth === "true" ? true : false
                                  }
                                  defaultChecked={
                                    values.googleAuth === "true" ? true : false
                                  }
                                  onChange={event => {
                                    handleChange("googleAuth")(
                                      event.target.checked ? "true" : "false"
                                    );
                                  }}
                                />
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-4">
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Application ID
                              </Label>
                              <Input
                                name="applicationId"
                                placeholder="applicationId"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.applicationId}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label className="col-form-label">Theme</Label>
                              <Select
                                name="theme"
                                value={selectedTheme}
                                onChange={event => {
                                  setSelectedTheme(event);
                                  handleChange("theme")(event.value);
                                }}
                                options={themeList}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Main Color
                              </Label>
                              <Input
                                name="mainColor"
                                placeholder="mainColor"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.mainColor}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Secondary Color
                              </Label>
                              <Input
                                name="secondColor"
                                placeholder="secondColor"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.secondColor}
                              />
                            </FormGroup>
                          </Row>

                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Accent Color
                              </Label>
                              <Input
                                name="accentColor"
                                placeholder="accentColor"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.accentColor}
                              />
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Application ID
                              </Label>
                              <Input
                                name="applicationSecret"
                                placeholder="applicationSecret"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.applicationSecret}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Theme Color
                              </Label>
                              <Input
                                name="themeColor"
                                placeholder="themeColor"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.themeColor}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Primary Color
                              </Label>
                              <Input
                                name="primaryColor"
                                placeholder="primaryColor"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.primaryColor}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Background Color
                              </Label>
                              <Input
                                name="backgroundColor"
                                placeholder="BackgroundColor"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.backgroundColor}
                              />
                            </FormGroup>
                          </Row>

                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom2">
                                Optional Color :
                              </Label>
                              <Row>
                                <Col md={6} className="">
                                  <Button
                                    color="primary"
                                    className="w-md btn-sm me-4"
                                    onClick={() => {
                                      addOptional();
                                    }}
                                  >
                                    Add optional Color
                                  </Button>
                                </Col>
                                <Col md={6} className=""></Col>
                              </Row>
                              {optionalColorsList.map((location, index) => (
                                <div key={`location${index}`}>
                                  <Input
                                    type="color"
                                    className="mb-4"
                                    placeholder="Enter Your optional Color"
                                    onChange={event => {
                                      handelOptionColor(
                                        event.target.value,
                                        index
                                      );
                                    }}
                                    value={optionalColorsList[index]}
                                  />
                                  <div className="mb-4"></div>
                                </div>
                              ))}
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="d-flex justify-content-end">
                        <Button
                          color="primary"
                          className="w-md btn-sm me-4"
                          type="submit"
                          // onClick={editChanges}
                        >
                          Save Changes
                        </Button>

                        <Button
                          color="light"
                          className="w-md btn-sm"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default editSettings;
