import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import SweetAlert from "react-bootstrap-sweetalert";
import Spinner from "components/spinner";
import noImage from "assets/images/PicUpload.jpg";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import fileIcon from "../../../src/assets/icons-image/document.png";
//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import moment from "moment";
import { deletecompany, getCompanies } from "services/company.service";
import Companycreate from "pages/ProviderRegister/company/companycreate";
import StatusBarCompany from "pages/ProviderRegister/company/info/CompanyStatusBar";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const CompaniesList = () => {
  const [list, setList] = useState([]);
  const [allList, setAllList] = useState([]);
  const [search, setSearch] = useState("");
  const [TotalList, setTotalList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [Statusmodal, setStatusModal] = useState(false);
  const [IndId, setIndId] = useState();
  const [tempList, setTempList] = useState([]);
  const [typeFilter, setTypeFilter] = useState(["pending"]);
  const typeFilterOptions = ["pending", "Verified", "Rejected", "Blocked"];
  let UserId = JSON.parse(localStorage.getItem("authUser"))["_doc"]["_id"];
  let UserFirstname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "firstname"
  ];
  let UserLastname = JSON.parse(localStorage.getItem("authUser"))["_doc"][
    "lastname"
  ];
  let Username = UserFirstname + " " + UserLastname;
  const toggle = () => {
    setModal(!modal);
  };
  const toggleSatus = () => {
    setStatusModal(!Statusmodal);
  };

  useEffect(() => {
    getAllList();
  }, []);

  const getAllList = () => {
    setLoading(true);
    getCompanies()
      .then(res => {
        let result = res.data;
        setList(result);
        setTempList(result);
        let obj = {};
        result.forEach(pr => {
          let id = pr._id;
          obj[id] = pr["access"];
        });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const removeProvider = id => {
    deletecompany(id)
      .then(res => {
        setDelete_modal(false);
        getAllList();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeProvider(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };

  const handleRoute = id => {
    props.history.push(`/individul-info/${id}`);
  };

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  const handleSearchByName = search => {
    if (search.length > 0) {
      let allList = _.cloneDeep(tempList);
      let filterList = allList?.filter(ele =>
        ele?.companyName?.toLowerCase().includes(search.toLowerCase())
      );
      setList(filterList);
    } else {
      setList(allList);
    }
  };
  const handleSearchByZipcode = search => {
    setSearch(search);
    if (search) {
      let allList = _.cloneDeep(tempList);
      let filterList = allList.filter(item => {
        return item.zipcodes.some(zipcodeObj =>
          zipcodeObj.zipcode.includes(search)
        );
      });
      setList(filterList);
    } else {
      setList(allList);
    }
  };
  function calculateExperienceFromDate(dateString) {
    const startDate = moment(dateString);
    const currentDate = moment();

    const totalMonths = currentDate.diff(startDate, "months");
    const years = Math.floor(totalMonths / 12);
    const months = totalMonths % 12;

    return `${years} ${years === 1 ? "yr" : "yrs"}, ${months} ${
      months === 1 ? "MM" : "MM"
    }`;
  }

  useEffect(() => {
    if (typeFilter?.length > 0) {
      const newList = tempList?.filter(
        obj =>
          typeFilter.includes(obj.status) ||
          typeFilter.includes(obj?.adminVerification?.VerificationStatus)
      );
      setList(newList);
    } else {
      setList(tempList);
    }
  }, [typeFilter, tempList]);

  const handleCheckboxChange = text => {
    let newTypeFilter = [...typeFilter];
    let indx = newTypeFilter.indexOf(text);

    if (indx >= 0) {
      newTypeFilter.splice(indx, 1);
    } else {
      newTypeFilter.push(text);
    }

    setTypeFilter(newTypeFilter);
  };
  const checkboxItems = () => {
    return (
      <React.Fragment>
        {typeFilterOptions?.map((option, index) => {
          const isChecked = typeFilter.includes(option);
          return (
            <div className="d-flex me-3 align-items-center" key={index}>
              <Input
                key={option}
                id={option}
                type="checkbox"
                className="form-check-Inpu me-1 cursor-pointer"
                onChange={() => handleCheckboxChange(option)}
                defaultChecked={isChecked}
              />
              <Label
                className="form-check-Label fw-bold mb-0  pt-1  cursor-pointer"
                htmlFor={option}
              >
                {option}
              </Label>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Company | stylepro - -</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumbs title="Company" breadcrumbItem="Company List" />
          <BreadcrumbSub subtile="Companies List" SubBreadcrumbItem="Company" />
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <div>
                <h5 className="fw-bold">Total Companies: {tempList?.length}</h5>
              </div>
              <div className="search-box ms-5 individual_search">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <input
                    className="form-control py-2 bg-white w-100"
                    type="search"
                    id="example-search-input"
                    placeholder="Search by Zipcode"
                    onChange={event => {
                      handleSearchByZipcode(event.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="search-box ms-4 individual_search">
                <div className="position-relative">
                  <i className="bx bx-search-alt search-icon" />
                  <input
                    className="form-control py-2 bg-white"
                    type="search"
                    id="example-search-input"
                    placeholder="Search by name"
                    onChange={event => {
                      handleSearchByName(event.target.value);
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <Button
                type="button"
                color="primary"
                className=""
                onClick={() => {
                  toggle();
                }}
              >
                <i className="bx bx-user-plus me-1" />
                Create Company
              </Button>
            </div>
          </div>

          {search ? (
            <div className="d-flex justify-content-start align-items-center mt-3">
              <div className="">
                <h6 className="mb-0">
                  Provider In ZipCode{" "}
                  <span className="text-danger ms-1 fw-bold">{search}</span>
                </h6>
              </div>
              <div className="ms-5 text-danger">
                {list?.length <= 0 ? (
                  <i>No results found</i>
                ) : (
                  <i>{list?.length} results found</i>
                )}
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="d-flex mt-3 align-items-center">
            {checkboxItems()}
          </div>
          {list?.length === 0 ? (
            <Card className="mt-3">
              <CardBody>
                <div className="p-5 text-center">
                  <h5>No results found</h5>
                </div>
              </CardBody>
            </Card>
          ) : (
            <Card className="bg-light my-3">
              <CardBody className="">
                <Row className="services-card">
                  {list?.map((comp, index) => {
                    let FixedClass = "fw-bold ";
                    let statusClass =
                      comp?.status === "Verified"
                        ? FixedClass + "text-success"
                        : comp?.status === "pending"
                        ? FixedClass + "text-warning"
                        : FixedClass + "text-danger";
                    const dateString = comp?.experience;
                    const experience = calculateExperienceFromDate(dateString);
                    let partnerStatus =
                      comp?.adminVerification?.VerificationStatus;
                    const partnershipstatus =
                      partnerStatus === "Active"
                        ? "text-success fw-bold"
                        : partnerStatus === "Blocked"
                        ? "text-danger fw-bold"
                        : "text-warning fw-bold";
                    return (
                      <Col
                        sm={6}
                        md={6}
                        xl={4}
                        xxl={4}
                        key={index}
                        className="mb-1"
                      >
                        <Card className="individual_cards">
                          <CardBody className="">
                            <div
                              id="View"
                              className="view_button_1"
                              role="button"
                              onClick={() =>
                                window.open(
                                  `${process.env.REACT_APP_PARTNER_PORTAL}/login/${comp._id}/com/ADMIN?adminId=${UserId}&name=${Username}`,
                                  "_blank",
                                  "width=1200,height=800"
                                )
                              }
                            >
                              <img src={fileIcon} alt="" className="me-1" />
                              <UncontrolledTooltip
                                placement="top"
                                target="View"
                              >
                                Open Partner Portal
                              </UncontrolledTooltip>
                            </div>
                            {comp?.status === "Verified" &&
                            partnerStatus === "Active" ? (
                              ""
                            ) : (
                              <div className="Delete_button mt-1">
                                <Badge
                                  role="button"
                                  className="font-size-10 badge-soft-danger py-1 px-2 me-1 fw-bold"
                                  color="bg-danger"
                                  onClick={() => onClickDelete(comp?._id)}
                                  id="delete"
                                >
                                  <i className="bx bx-trash-alt font-size-12 fw-bold"></i>
                                  <UncontrolledTooltip
                                    placement="top"
                                    target="delete"
                                  >
                                    Delete Company
                                  </UncontrolledTooltip>
                                </Badge>
                              </div>
                            )}

                            <div className="d-flex">
                              <div>
                                <div>
                                  <img
                                    className=""
                                    src={
                                      !comp?.image ||
                                      comp?.image === "undefined"
                                        ? noImage
                                        : comp?.image
                                    }
                                    alt=""
                                    width={100}
                                    height={100}
                                    role="button"
                                  />
                                </div>
                                <div>
                                  {partnerStatus != "" ? (
                                    <div>
                                      <h6 className="text-center mt-2 parnership_status">
                                        {partnerStatus === "Active" ? (
                                          <i className="bx bx-check-shield text-success font-size-50"></i>
                                        ) : partnerStatus === "Blocked" ? (
                                          <i className="bx bx-block text-danger font-size-50"></i>
                                        ) : (
                                          ""
                                        )}
                                      </h6>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                              <div className="ms-4">
                                <h6 className="fw-bold font-size-16 individual_name text-uppercase">
                                  {comp?.companyName}
                                </h6>
                                <h6 className="fw-bold" role="button">
                                  Reg #{comp?.regId}
                                </h6>
                                <div
                                  className="mb-1"
                                  onClick={() => {
                                    toggleSatus();
                                    setIndId(comp?._id);
                                  }}
                                  role="button"
                                >
                                  <span className="fw-bold">Status: </span>
                                  <span className={statusClass}>
                                    {comp?.status}
                                  </span>
                                </div>

                                <div className="mt-1">
                                  <span className="">
                                    Since:{" "}
                                    {comp?.createdAt &&
                                      moment(new Date(comp?.createdAt)).format(
                                        "DD MMM Y"
                                      )}{" "}
                                  </span>
                                </div>
                                <div>
                                  <div className="rating mt-1">
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="fas fa-star"></i>
                                    <i className="far fa-star"></i>
                                    <i className="far fa-star"></i>
                                  </div>
                                </div>
                                <div className="mt-1 d-flex align-items-center mobile_number">
                                  <i className="bx bxs-phone"></i>{" "}
                                  <span className="fw-bold mb-0 ps-1">
                                    {comp?.mobileNumber}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                    );
                  })}
                </Row>
              </CardBody>
            </Card>
          )}
        </Container>
      </div>
      {/* <IndividualCreate toggle={toggle} modal={modal} reload={getAllList} /> */}
      <Companycreate toggle={toggle} modal={modal} reload={getAllList} />
      <StatusBarCompany
        toggle={toggleSatus}
        modal={Statusmodal}
        id={IndId}
        reload={getAllList}
      />
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};
export default CompaniesList;
