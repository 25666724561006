import ProfileImage from "components/profileImage";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
// import banner from "./PicUpload.jpg";
import formatBytes from "utils/formatBytes";
import Variants from "../variants";
import { VariantsView } from "./VariantsView";
import { useSelector } from "react-redux";
import createCombinations from "../helper";
import { useParams } from "react-router-dom";
import createCombinationsForUpdate from "../createComForUpdate";
const defaultOptionValues = {
  name: "",
  image: "",
};
const defaultAttributeValues = {
  variant: "",
  selectedOptions: [{ name: "", image: "" }],
};
export const VariantAttributes = ({
  handleSubmit,
  productType,
  stepperActiveTab,
}) => {
  const [attributes, setAttributes] = useState([]);
  const [combosList, setCombosList] = useState([]);
  const product = useSelector(state => state?.EcomProducts?.product);
  const route = useParams();

  useEffect(() => {
    if (route?.id) {
      let tempData = JSON.parse(JSON.stringify(product));
      if (tempData && tempData?.attributes) {
        if (tempData?.attributes[0]?.variants) {
          setAttributes(tempData?.attributes[0]?.variants || []);
        }
      }
    }
  }, [product]);

  const addAttributeValues = () => {
    let newValues = _.cloneDeep(attributes);
    newValues.push(defaultAttributeValues);
    setAttributes(newValues);
  };

  const addOptionValues = index => {
    let attrValues = _.cloneDeep(attributes);
    let addOption = attrValues[index]["selectedOptions"];
    addOption.push(defaultOptionValues);
    setAttributes(attrValues);
  };

  const handleChangeValues = (attrindex, name, value) => {
    const tempValues = _.cloneDeep(attributes);
    tempValues[attrindex]["variant"] = value;
    setAttributes(tempValues);
  };
  const handleOptionsChange = (attrIndex, optIndex, name, value) => {
    let tempValues = _.cloneDeep(attributes);
    tempValues[attrIndex]["selectedOptions"][optIndex][name] = value;
    setAttributes(tempValues);
  };

  const removeOptionFeilds = (attrIndex, optIndex) => {
    let attrValues = _.cloneDeep(attributes);
    let deleteOption = attrValues[attrIndex]["selectedOptions"];
    deleteOption.splice(optIndex, 1);
    setAttributes(attrValues);
  };
  const removeAttributeFeilds = attrIndex => {
    let attrValues = _.cloneDeep(attributes);
    attrValues.splice(attrIndex, 1);
    setAttributes(attrValues);
  };

  const handleSaveAttributes = () => {
    const tempProduct = _.cloneDeep(product);
    let tempVariants = [];
    if (tempProduct?.attributes.length) {
      tempVariants = [...attributes];
      tempProduct["attributes"][0]["variants"] = tempVariants;
    } else {
      let attributesArray = [{ variant: [], combos: [] }];
      tempVariants = attributesArray[0]["variant"];
      tempVariants?.push(...attributes);
    }
    let tempCombosList = [];
    tempVariants?.forEach(ele => {
      console.log(ele, "elee");
      if (ele?.selectedOptions) {
        tempCombosList.push(ele?.selectedOptions);
      }
    });

    let combos;
    if (route?.catId) {
      combos = createCombinations(tempCombosList, [], combosList);
    } else {
      combos = createCombinationsForUpdate(
        tempCombosList,
        tempProduct?.attributes[0]?.combos,
        combosList,
        true
      );
    }
    setCombosList(combos);
  };

  return (
    <div>
      <div className="d-flex justify-content-between py-1">
        <label className="mb-0">Attributes</label>
        <button
          type=""
          className="btn btn-sm  btn-primary"
          onClick={() => {
            addAttributeValues();
          }}
        >
          Add atribute
        </button>
      </div>
      <Card>
        <CardBody>
          {attributes.length > 0 ? (
            attributes?.map((ele, index) => {
              return (
                <div key={index} className="border border-2 p-2 px-3 my-1">
                  <i
                    role="button"
                    className="mdi mdi-close-thick font-size-18 text-danger float-end"
                    id="removeImgtooltip"
                    onClick={() => removeAttributeFeilds(index)}
                  />
                  <Row className="mt-3">
                    <Col md={3}>
                      <label>Attribute name</label>
                      <Input
                        type="text"
                        onChange={event => {
                          handleChangeValues(
                            index,
                            "variant",
                            event?.target.value
                          );
                        }}
                        value={ele?.variant}
                      />
                    </Col>
                    <Col className="" md={9}>
                      {ele?.selectedOptions?.map((opt, ind) => {
                        return (
                          <Row key={ind}>
                            <Col className="" md={5}>
                              <div className="mt-4">
                                <Input
                                  type="text"
                                  placeholder="Option name"
                                  onChange={event => {
                                    handleOptionsChange(
                                      index,
                                      ind,
                                      "name",
                                      event.target.value
                                    );
                                  }}
                                  value={opt.name}
                                />
                              </div>
                            </Col>
                            <Col md={5}>
                              <div className="d-flex align-items-center justify-content-between mt-3">
                                <div className="d-flex align-items-center">
                                  <Dropzone
                                    onDrop={acceptedFiles => {
                                      acceptedFiles.map(file =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                          formattedSize: formatBytes(file.size),
                                        })
                                      );
                                      handleOptionsChange(
                                        index,
                                        ind,
                                        "image",
                                        acceptedFiles[0]
                                      );
                                    }}
                                  >
                                    {({ getRootProps, getInputProps }) => (
                                      <div
                                        className="dropzone ser_img_drop"
                                        style={{
                                          height: "40px",
                                          width: "200px",
                                          minHeight: "0px",
                                        }}
                                      >
                                        <div
                                          className="text-center needsclick mt-1 "
                                          {...getRootProps()}
                                        >
                                          <input {...getInputProps()} />
                                          <div className="d-flex justify-content-center ">
                                            <p className="mt-1">Upload image</p>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                  </Dropzone>
                                  <ProfileImage
                                    image={opt?.image}
                                    height={30}
                                    classNames="avatar-sm rounded bg-light mx-3"
                                  />
                                </div>
                                <i
                                  className="bx bx-trash text-danger font-size-18 mx-3 float-end"
                                  role="button"
                                  onClick={() => {
                                    removeOptionFeilds(index, ind, ele);
                                  }}
                                ></i>
                              </div>
                            </Col>
                          </Row>
                        );
                      })}

                      <button
                        className="btn btn-sm btn-primary my-2"
                        onClick={() => addOptionValues(index)}
                      >
                        Add Options
                      </button>
                    </Col>
                  </Row>
                </div>
              );
            })
          ) : (
            <div className="text-center mt-3">No attributes Found</div>
          )}

          <hr className="mt-5" />
          <div className=" mb-3">
            <button
              className="btn btn-primary btn-sm px-3 float-end "
              onClick={() => {
                handleSaveAttributes();
              }}
            >
              Generate Variants
            </button>
          </div>
        </CardBody>
      </Card>

      <VariantsView
        productType={productType}
        variantsList={attributes}
        combosList={combosList}
        handleSubmitValues={handleSubmit}
        stepperActiveTab={stepperActiveTab}
        product={product}
      />
    </div>
  );
};
