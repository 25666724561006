import Breadcrumb from "components/Common/Breadcrumb";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import { getAllExceptionsSummary } from "services/appExceptions.service";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const ExceptionsSummaryList = () => {
  const [summaryList, setSummaryList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdatemodal] = useState(false);

  useEffect(() => {
    getExceptionsSummary();
  }, []);

  const getExceptionsSummary = () => {
    setLoading(true);
    getAllExceptionsSummary()
      .then(res => {
        setSummaryList(res?.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getExceptions = type => {
    switch (type) {
      case "APP":
        return "Mobile";
      case "API":
        return "Server";
      default:
        break;
    }
  };

  const SummaryList = () => {
    return summaryList?.map((el, index) => {
      return (
        <Col key={index} sm={6} md={6} xl={3} xxl={3}>
          <Link to={`/exceptions/${el?._id}`} className="">
            <Card>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <div className="d-flex">
                      <div className="me-3"></div>
                      <div>
                        <h5 className="fw-bold mb-1 text-uppercase">
                          {getExceptions(el?._id)}
                        </h5>
                        <hr className="my-1"/>
                        <span> Total Count: </span>
                        <span className="fw-bold">{el?.typeCount}</span>
                      </div>
                    </div>
                  </Col>
                  <Col md={12}>
                    <div className="ms-3">
                      <div>
                        <span> Low: </span>
                        <span className="fw-bold">{el?.lowCount}</span>
                      </div>
                      <div>
                        <span> Medium: </span>
                        <span className="fw-bold">{el?.mediumCount}</span>
                      </div>
                      <div>
                        <span> High: </span>
                        <span className="fw-bold">{el?.highCount}</span>
                      </div>
                      <div>
                        <span> Critical: </span>
                        <span className="fw-bold">{el?.criticalCount}</span>
                      </div>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Link>
        </Col>
      );
    });
  };

  return (
    <>
      <React.Fragment>
        <Spinner loading={loading} />
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb
              title="Exceptions"
              breadcrumbItem="Exceptions Summary"
            />
            <BreadcrumbSub subtile="Exceptions" SubBreadcrumbItem="Summary" />
            <Row>
              <>{SummaryList()}</>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default ExceptionsSummaryList;
