import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import "./Styles.scss";
import Select from "react-select";
import { getBrands } from "services/ecommerce/brand.service";
import ProfileImage from "components/profileImage";
import Dropzone from "react-dropzone";
import formatBytes from "utils/formatBytes";

const ProductsCreate2 = ({ handleChangeProperties, val, selectedBrand }) => {
  const [values, setValues] = useState(val);
  const [brands, setBrands] = useState([]);
  useEffect(() => {
    setValues(val);
  }, [val]);

  useEffect(() => {
    getBrands()
      .then(res => {
        if (res.data) {
          const result = res.data.map(brand => {
            let obj = {
              label: brand.name,
              value: brand._id,
            };
            return obj;
          });
          setBrands(result);
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  //tags
  const options = [
    { value: "phones", label: "phones" },
    { value: "Electronics", label: "Electronics" },
  ];

  const handelCheckboxes = (value, key) => {
    console.log(value, key);
    if (value) {
      setValues({ ...values, labels: key });
      handleChangeProperties("labels", key);
    } else {
      setValues({ ...values, labels: "" });
      handleChangeProperties("labels", "");
    }
  };

  return (
    <div>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary ">
            Status<span className="text-danger">*</span>
          </h6>
        </CardTitle>
        <CardBody>
          <Form>
            <FormGroup>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={values?.status}
              >
                <option value="Draft">Draft</option>
                <option value="Publish">Publish</option>
                <option value="Pending">Pending</option>
              </Input>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary">Store</h6>
        </CardTitle>
        <CardBody>
          <Form>
            <FormGroup>
              <Input
                type="select"
                name="select"
                id="exampleSelect"
                value={values.store || ""}
                onChange={event =>
                  handleChangeProperties("store", event.target.value)
                }
              >
                <option disabled value="">
                  Select store
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
              </Input>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary">Is featured?</h6>
        </CardTitle>
        <CardBody>
          <Form>
            <FormGroup switch>
              <Input
                type="switch"
                role="switch"
                defaultChecked={values.isFeatured}
                onChange={event =>
                  handleChangeProperties("isFeatured", event.target.checked)
                }
              />
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary ">Brand</h6>
        </CardTitle>
        <CardBody>
          <Form>
            <FormGroup>
              <Label for="brands">Brands</Label>
              <Select
                id="brands"
                defaultValue={selectedBrand || {}}
                options={brands || []}
                
                onChange={event => {
                  console.log(event)
                  handleChangeProperties("brand", event.value);
                }}
              />
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary">Featured image(optional)</h6>
        </CardTitle>
        <CardBody>
          <ProfileImage
            image={values?.featuredimage}
            height={150}
            width={150}
            className=""
          />

          <Dropzone
            onDrop={acceptedFiles => {
              acceptedFiles.map(file =>
                Object.assign(file, {
                  preview: URL.createObjectURL(file),
                  formattedSize: formatBytes(file.size),
                })
              );
              handleChangeProperties("featuredimage", acceptedFiles[0]);
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="needsclick" {...getRootProps()}>
                <input {...getInputProps()} />
                <h6 className="text-info mb-0 mt-2" role="button">
                  Choose Image
                </h6>
              </div>
            )}
          </Dropzone>
        </CardBody>
      </Card>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary">Product collections</h6>
        </CardTitle>
        <CardBody>
          <Form>
            <FormGroup>
              <Input
                type="checkbox"
                id=""
                className="me-2"
                // onChange={event =>
                //   handleChangeProperties("newArraival", event.target.checked)
                // }
              />
              <Label>New Arrivals</Label>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <Input type="checkbox" className="me-2"/>
              <Label>Best Seller</Label>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup>
              <Input type="checkbox" className="me-2"/>
              <Label>Special offer</Label>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
      <Card>
        <CardTitle className=" border border-bottom-3">
          <h6 className="ms-3 mt-3 text-primary">Labels</h6>
        </CardTitle>
        <CardBody>
          <Form>
            <FormGroup check>
              <Input
                type="checkbox"
                value={values.labels === "Hot" ? true : false}
                defaultChecked={values.labels === "Hot" ? true : false}
                onClick={e => {
                  console.log(e.target.checked);
                  handelCheckboxes(e.target.checked, "Hot");
                }}
              />
              <Label check>Hot</Label>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup check>
              <Input
                type="checkbox"
                value={values.labels === "New" ? true : false}
                defaultChecked={values.labels === "New" ? true : false}
                onChange={e => {
                  console.log(e.target.checked);

                  handelCheckboxes(e.target.checked, "New");
                }}
              />
              <Label check>New</Label>
            </FormGroup>
          </Form>
          <Form>
            <FormGroup check>
              <Input
                type="checkbox"
                value={values.labels === "Sale" ? true : false}
                defaultChecked={values.labels === "Sale" ? true : false}
                onChange={e => {
                  handelCheckboxes(e.target.checked, "Sale");
                }}
              />
              <Label check>Sale</Label>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default ProductsCreate2;
