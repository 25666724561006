import React from "react";
import "./StarRating.scss"; // Import your CSS file for styling

const StarRating = ({ totalStars, rating }) => {
  const renderStar = starIndex => {
    const decimalPart = rating - starIndex + 1;

    if (decimalPart >= 1) {
      return <span className="star filled">★</span>;
    } else if (decimalPart > 0) {
      return (
        <span
          className={
            "star partially-filled partially-filled-" +
            `${Math.round(decimalPart * 100)}`
          }
        >
          ★
        </span>
      );
    } else {
      return <span className="star">★</span>;
    }
  };

  const stars = [];
  for (let i = 1; i <= totalStars; i++) {
    stars.push(
      <span key={i} className="star-container">
        {renderStar(i)}
      </span>
    );
  }

  return <div className="star-rating">{stars}</div>;
};

export default StarRating;
