import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Select from "react-select";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import ProfileImage from "./../../../components/profileImage";
import Spinner from "components/spinner";
import CreatableSelect from "react-select/creatable";
import {
  getMainCategory,
  createSubCategory,
  updateSubCategory,
  getParentCategoryByMain,
} from "services/ecommerce/category.service";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useRef } from "react";
import { filterOptions } from "utils/filterOptions";
import Filters from "./filters";

const defaultValues = {
  name: "",
  mainCategory: "",
  parentCategory: "",
  image: {},
  description: "",
  order: "",
};

const EcomCreateSub = ({ toggle, modal, editObj = null, reloadService }) => {
  const [selectedMainCat, setSelectedMainCat] = useState(null);
  const [selectedParentCat, setSelectedParentCat] = useState(null);
  const [mainCatList, setMainCatList] = useState([]);
  const [parentCatList, setParentCatList] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const { image } = values;
  const initialValues = defaultValues;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    status: Yup.string().required("Please Select Status"),
    mainCategory: Yup.string().required("Please Select Main Category"),
    parentCategory: Yup.string().required("Please Select Parent Category"),
    description: Yup.string().required("Please Enter description"),
  });
  const typeList = [
    // { label: "Publish", value: "Publish" },
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  const onSubmit = values => {
    if (editObj) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };

  useEffect(() => {
    getAllCategories();
  }, []);
  const getParentCategories = id => {
    getParentCategoryByMain(id)
      .then(res => {
        if (res.data) {
          let list = res?.data;
          let resArr = [];
          list.map(ele => {
            resArr.push({
              value: ele._id,
              label: ele.name,
            });
          });
          setParentCatList(resArr);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllCategories = () => {
    getMainCategory()
      .then(res => {
        if (res.data) {
          let list = res?.data;
          let resArr = [];
          list.map(ele => {
            resArr.push({
              value: ele._id,
              label: ele.name,
            });
          });
          setMainCatList(resArr);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  function handleSelectMainCat(event) {
    setValues({ ...values, ["mainCategory"]: event.value });
    setSelectedMainCat(event);
    setSelectedParentCat(null);

    getParentCategories(event.value);
  }
  function handleSelectParentCat(event) {
    setValues({ ...values, ["parentCategory"]: event.value });
    setSelectedParentCat(event);
  }
  function handleSelectType(event) {
    setValues({ ...values, ["type"]: event.value });
    setSelectedType(event);
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    createSubCategory(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        reloadService();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    updateSubCategory(editObj._id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        }
        if (res.error) {
          seterror_msg(true);
        }
        reloadService();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        {!editObj ? (
          <div>Successfully Parent-Category created</div>
        ) : (
          <div>Successfully Parent-Category edited</div>
        )}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  /* Formats the size */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {editObj ? "Edit Product SubCategory" : "Create Product SubCategory"}
        </ModalHeader>
        <ModalBody className="bg-light">
          {/* <Spinner loading={loading} /> */}
          <Container fluid={false}>
            <Card>
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    errors,
                    touched,
                    isSubmitting,
                    setFieldValue,
                    handleChange,
                    setFieldTouched,
                    values,
                  }) => {
                    useEffect(() => {
                      if (editObj) {
                        setLoading(true);
                        const data = { ...editObj };
                        const fields = [
                          "name",
                          "image",
                          "description",
                          "status",
                          "order",
                        ];
                        fields.forEach(field =>
                          setFieldValue(field, data[field], false)
                        );
                        setFieldValue(
                          "mainCategory",
                          data.mainCategory._id,
                          false
                        );
                        setSelectedMainCat({
                          value: data.mainCategory._id,
                          label: data.mainCategory.name,
                        });
                        setFieldValue(
                          "parentCategory",
                          data.parentCategory._id,
                          false
                        );
                        setSelectedParentCat({
                          value: data.parentCategory._id,
                          label: data.parentCategory.name,
                        });
                        setFieldValue("status", data.status, false);
                        setSelectedType({
                          value: data.status,
                          label: data.status,
                        });
                      }
                    }, []);

                    return (
                      <Form>
                        <Row className="mt-4">
                          <Col md={6} className="px-4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Name</Label>
                              <Input
                                name="name"
                                placeholder="Name"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.name}
                                invalid={
                                  touched.name && errors.name ? true : false
                                }
                              />

                              {touched.name && errors.name ? (
                                <FormFeedback type="invalid">
                                  {errors.name}
                                  {touched.name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>{" "}
                          </Col>
                          <Col md={6}>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">
                                Main Category
                              </Label>
                              <Select
                                id="validationCustom05"
                                value={selectedMainCat}
                                onChange={event => {
                                  handleSelectMainCat(event);
                                  handleChange("mainCategory")(event.value);
                                }}
                                name="mainCategory"
                                options={mainCatList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.mainCategory ? errors.mainCategory : ""}
                              </Label>
                            </FormGroup>
                          </Col>{" "}
                        </Row>

                        <Row className="mb-3">
                          <Col md="4" className="px-4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">
                                Parent Category
                              </Label>
                              <Select
                                id="validationCustom05"
                                value={selectedParentCat}
                                onChange={event => {
                                  handleSelectParentCat(event);
                                  handleChange("parentCategory")(event.value);
                                }}
                                name="parentCategory"
                                options={parentCatList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.parentCategory
                                  ? errors.parentCategory
                                  : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md="4">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom05">Status</Label>
                              <Select
                                id="type"
                                value={selectedType}
                                onChange={event => {
                                  handleSelectType(event);
                                  handleChange("status")(event.value);
                                }}
                                name="type"
                                options={typeList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.status ? errors.status : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                          <Col md={4} className="">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Order</Label>
                              <Input
                                name="order"
                                placeholder="order"
                                type="number"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                value={values.order}
                              />
                            </FormGroup>{" "}
                          </Col>
                        </Row>

                        <Row className="mb-3 ">
                          <Col md="6">
                            <FormGroup className="mb-3 px-3">
                              <Label htmlFor="validationCustom06">Image</Label>
                              <Dropzone
                                id="image"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("image", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="statusValidation">
                                {" "}
                                {errors.image ? errors.image : ""}
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.image &&
                                  Object.keys(values.image).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.image}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.image.name ||
                                                values.image.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.image.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Col>

                          <Col md="6">
                            <FormGroup className="mb-3 px-3">
                              <Label htmlFor="description">Description</Label>
                              <div className="custom_ck_editor">
                                <CKEditor
                                  id="description"
                                  editor={ClassicEditor}
                                  data={
                                    values.description ? values.description : ""
                                  }
                                  onReady={editor => {
                                    if (values.description) {
                                      editor.setData(values.description);
                                    }
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("description")(data);
                                  }}
                                />
                              </div>
                              <Label className="statusValidation">
                                {" "}
                                {errors.description ? errors.description : ""}
                              </Label>
                            </FormGroup>
                          </Col>
                        </Row>

                        <hr></hr>
                        <div className="d-flex justify-content-end">
                          <Button
                            color="light"
                            className="w-md btn-sm me-4"
                            onClick={leaveToList}
                          >
                            Cancel
                          </Button>
                          {!editObj ? (
                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm "
                            >
                              Submit
                            </Button>
                          ) : (
                            <Button
                              color="primary"
                              className="w-md btn-sm "
                              type="submit"
                            >
                              Save Changes
                            </Button>
                          )}
                        </div>
                      </Form>
                    );
                  }}
                </Formik>

                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
            {editObj?._id && (
              <Filters
                subCatId={editObj?._id}
                setsuccess_msg={setsuccess_msg}
                seterror_msg={seterror_msg}
                leaveToList={leaveToList}
              />
            )}
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EcomCreateSub;
