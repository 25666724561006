import React, { useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Col,
  Row,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  List,
  UncontrolledAccordion,
} from "reactstrap";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import { deleteParentCategory } from "services/ecommerce/category.service";
import EcomCreateParent from "../ProductCategory/CreateParent";
import noImage from "assets/images/PicUpload.jpg";
import { deleteProductByParent } from "services/ecommerce/product.service";

const EcomMainCat = ({ list, handleClick, isEditable = false, getMainCat }) => {
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(null);
  const [modal, setModal] = useState(false);
  const [activeId, setActiveId] = useState(false);

  //Delete//
  const removeParentCategory = id => {
    console.log(id, "parent");
    setLoading(true);
    deleteParentCategory(id)
      .then(res => {
        setDelete_modal(false);
        getMainCat();
        deleteProductByParent(id);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeParentCategory(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };

  const renderParentCatList = list => {
    return (
      <List type="unstyled" className="service-ul">
        {list.map((ele, index) => {
          return (
            <Row key={index}>
              <Col md={isEditable ? "8" : "12"}>
                <li
                  className={
                    "activeParentCatInd" === index
                      ? "ps-1 py-1 active"
                      : "ps-1 py-1"
                  }
                  onClick={() => {
                    handleClick(ele, ele.id);
                  }}
                  role="button"
                >
                  <Row className="mt-2">
                    {" "}
                    <Col md={3} className="">
                      <img
                        src={
                          ele?.image && ele.image?.includes("http")
                            ? ele.image
                            : noImage
                        }
                        style={{ height: "2rem", width: "2rem" }}
                        alt=""
                        className="fit-content bg-light rounded-circle"
                      />
                    </Col>
                    <Col md={isEditable ? "8" : "9"} className="">
                      {" "}
                      {ele.name}
                    </Col>
                  </Row>
                </li>
              </Col>
              {isEditable && (
                <Col md={3}>
                  <div className="">
                    <UncontrolledDropdown className="" direction="down">
                      <DropdownToggle caret color="white">
                        <i className="bx bx-dots-vertical-rounded"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setActiveId(ele);
                            toggle();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => onClickDelete(ele._id)}
                          style={{ cursor: "pointer" }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Col>
              )}
            </Row>
          );
        })}
      </List>
    );
  };
  return (
    <>
      <div className="custom_accordion">
        <UncontrolledAccordion stayOpen>
          {list?.map((ele, index) => (
            <AccordionItem className="border-0" key={index}>
              <AccordionHeader targetId={index.toString()}>
                <img
                  src={ele.image}
                  alt=""
                  className="fit-content bg-light rounded-circle me-2 avatar-sm "
                />
                {ele.name}
              </AccordionHeader>
              <AccordionBody accordionId={index.toString()}>
                {renderParentCatList(ele.docs)}
              </AccordionBody>
            </AccordionItem>
          ))}
        </UncontrolledAccordion>
        {delete_modal ? deletePopup() : null}
        {modal ? (
          <EcomCreateParent
            toggle={toggle}
            modal={modal}
            editObj={activeId}
            reloadService={getMainCat}
          />
        ) : null}
      </div>
    </>
  );
};

export default EcomMainCat;
