import Breadcrumb from "components/Common/Breadcrumb";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { MetaTags } from "react-meta-tags";
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { deleteQuestion, getQuestions } from "services/ratingQuestion.service";
import CreateQuestion from "./create";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const RatingsQuestions = () => {
  const [questions, setQuestions] = useState();
  const [activeId, setActiveId] = useState(null);
  const [modal, setModal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAllQuestions = () => {
    getQuestions().then(res => {
      console.log(res.data);
      setQuestions(res.data);
    });
  };
  useEffect(() => {
    getAllQuestions();
  }, []);
  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };
  //Delete//
  const removeQuestion = id => {
    setLoading(true);
    deleteQuestion(id)
      .then(res => {
        setDelete_modal(false);
        getAllQuestions();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeQuestion(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const reloadService = () => {
    getAllQuestions();
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Service Reviews</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumb
            title="Service Reviews "
            breadcrumbItem="Service Reviews"
          />
          <BreadcrumbSub
            subtile="Service Review Questions"
            SubBreadcrumbItem="Service Questions"
          />
          <div className="text-end mb-3">
            <Button
              type="button"
              color="primary"
              className="  mt-2"
              onClick={toggle}
            >
              <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
              Add question
            </Button>
          </div>

          <Card className="view-users parentCat_view">
            <CardBody>
              {questions?.map((ele, index) => {
                return (
                  <div key={index}>
                    <div className="d-flex justify-content-between p-2 py-3">
                      <div>
                        <h6>
                          {index + 1} .&nbsp;&nbsp;
                          {ele.name}
                        </h6>
                      </div>
                      <div>
                        <div className="d-flex float-end">
                          <Badge
                            className="font-size-12 badge-soft-warning py-1 px-2 me-2"
                            color={"bg-warning"}
                            pill
                            onClick={() => {
                              console.log(ele);
                              setActiveId(ele._id);
                              toggle();
                            }}
                          >
                            <i className="mdi mdi-pencil font-size-14" />
                          </Badge>

                          <Badge
                            className="font-size-12 badge-soft-danger py-1 px-2"
                            color={"bg-warning"}
                            onClick={() => onClickDelete(ele._id)}
                            style={{ cursor: "pointer" }}
                            pill
                          >
                            <i className="mdi mdi-delete font-size-14" />
                          </Badge>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </Container>
        <CreateQuestion
          toggle={toggle}
          modal={modal}
          id={activeId}
          reloadService={reloadService}
        />
      </div>
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};
