import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Container,
  Button,
  Card,
  Row,
  Col,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import cellEditFactory from "react-bootstrap-table2-editor";
import Breadcrumbs from "components/Common/Breadcrumb";

// import ProfileImage from "./../../components/profileImage"

import { Link } from "react-router-dom";
import { getVariants, deleteVariant } from "services/ecommerce/variant.service";
import CreateAttributes from "./CreateAttribute";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const ListAttribute = props => {
  const { SearchBar } = Search;
  const [variantsList, setVariantsList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [active, setActive] = useState(null);

  useEffect(() => {
    getVariantsList();
  }, []);

  const getVariantsList = () => {
    getVariants()
      .then(res => {
        setVariantsList(res?.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const toggle = () => {
    if (modal) {
      setActive(null);
    }
    setModal(!modal);
  };

  const removeVariants = id => {
    setLoading(true);
    deleteVariant(id)
      .then(res => {
        setDelete_modal(false);
        getVariantsList();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  // Table Data
  const customer = () => [
    {
      dataField: "name",
      text: "Variant Name",
    },

    {
      dataField: "variants",
      text: "Variants",
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              {row?.variants?.map((ele, index) => (
                <span className="border border-1 px-2 bg-light" key={index}>
                  {ele?.value}
                </span>
              )) || "-"}
            </div>
          </>
        );
      },
    },
    {
      dataField: "description",
      text: "Description",
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              {/* <Link to={"#"} className="text-success"> */}
              <div
                onClick={() => {
                  setActive(row);
                  console.log(row, "roww");
                  setModal(true);
                }}
              >
                <i
                  className="mdi mdi-pencil text-success font-size-18"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
                {/* </Link> */}
              </div>
              <div
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(row._id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: variantsList?.length, // replace later with size(customers),
    custom: true,
  };

  const addBrand = () => {
    props.history.push("/product-create-attributes");
  };

  const onClickDelete = id => {
    console.log(id);
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeVariants(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Variants List</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Variants"
            breadcrumbItem="Product Variants Lists"
          />
          <BreadcrumbSub subtile="Variants List" SubBreadcrumbItem="Variants" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={customer()}
                    data={variantsList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={variantsList}
                        columns={customer()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4" className="d-flex">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              {/* <Col sm="4">
                              </Col> */}
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={toggle}
                                  >
                                    <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                    Add Variant
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="brandName"
                                    // cellEdit={cellEditFactory({
                                    //   mode: "click",
                                    // })}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {variantsList?.length == 0 && (
                    <p className="text-center"> No Data Found</p>
                  )}

                  {delete_modal ? deletePopup() : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        <CreateAttributes
          toggle={toggle}
          modal={modal}
          active={active}
          reload={getVariantsList}
        />
      </div>
    </React.Fragment>
  );
};
export default ListAttribute;
