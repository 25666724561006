export const getParentCategories=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/parentCategory/all`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get ParentCategory ${e}`))  
  }
  export const getParentCategory=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/parentCategory/parentCategory/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get ParentCategory ${e}`))  
  }
  export const deleteParentCategory=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/parentCategory/parentCategory/${id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get parentCategory ${e}`))  
  }
  export const createParentCategory = body => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/parentCategory/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at ParentCategory create ${e}`));
  };
  export const updateParentCategory =(id,body) => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/parentCategory/parentCategory/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at ParentCategory create ${e}`));
  };

  export const getParentCategoriesByQuery=(body)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/parentCategory/parentCategory`,{
        method:"POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
      body: JSON.stringify(body)
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get ParentCategory ${e}`))  
  }