import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Badge } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import MultipleCheckbox from "components/MutlpleCheckbox";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  getQuickServices,
  deleteQuickService,
} from "services/quickServices.service";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

const QuickServicesList = props => {
  const [serviceList, setServiceList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(true);

  //pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: serviceList.length, // replace later with size(serviceList),
    custom: true,
  };
  const { SearchBar } = Search;

  useEffect(() => {
    getServicesList();
  }, []);
  const badgeColor = "font-size-12 badge-soft-";

  const getServicesList = () => {
    getQuickServices()
      .then(res => {
        setServiceList(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };
  const removeService = id => {
    setLoading(true);
    deleteQuickService(id)
      .then(res => {
        setDelete_modal(false);
        getServicesList();
      })
      .catch(err => {
        setLoading(false);
      });
  };
  const handleOnDelete = () => {
    removeService(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };

  const serviceColumn = () => [
    {
      dataField: "banner",
      text: "Image",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <ProfileImage
          image={row.banner}
          classNames="rounded-circle avatar-sm"
        />
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },
    {
      dataField: "price",
      text: "Price",
    },
    {
      dataField: "discountPrice",
      text: "Discount Price",
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <Badge
          className={
            row.status === "Active"
              ? badgeColor + "success"
              : badgeColor + "danger"
          }
          color={"bg-secondary"}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "updatedAt",
      text: "Updated Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.updatedAt),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <Link
                to={`/quick-service-update/${row._id}`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <div
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(row._id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to={`/quick-service-view/${row._id}`}>
          <Button
            type="button"
            color="bg-secondary"
            className="btn-sm btn-rounded btn-outline-primary"
          >
            View Details
          </Button>
        </Link>
      ),
    },
  ];

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Quick Services | stylepro</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumbs
            title="Quick Services"
            breadcrumbItem="Quick Services List"
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={serviceColumn()}
                    data={serviceList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="name"
                        data={serviceList}
                        columns={serviceColumn()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4" className="d-flex">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                                {/* <FilterMenu filtersList={filtersList} changeFilter={handleFilters} clearFilter={clearFilter}/>
                                {filterCount > 0 && <p className="filterCount">{filterCount}</p>} */}
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Link
                                    to="/quick-service-create"
                                    className="has-arrow "
                                  >
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded  mb-2 me-2"
                                    >
                                      <i className="bx bx-user-plus me-1" />
                                      Create Quick Service
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="name"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {serviceList?.length == 0 && (
                    <p className="text-center"> No Data Found</p>
                  )}
                  {delete_modal ? deletePopup() : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default QuickServicesList;
