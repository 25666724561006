export const createAvatar = async body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/avatars/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at creating Avatar ${e}`));
};

export const getAllAvatars = async () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/avatars/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Avatars ${e}`));
};

export const getAvatarById = async id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/avatars/get/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Avatar ${e}`));
};

export const getAvatarByGender = async gender => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/avatars/gender/${gender}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Avatars ${e}`));
};

export const updateAvatar = async (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/avatars/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at updating Avatar ${e}`));
};

export const deleteAvatar = async id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/avatars/remove/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at deleting Avatar ${e}`));
};
