import React, { useState, useEffect } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SweetAlert from "react-bootstrap-sweetalert";
import ProfileImage from "./../../components/profileImage";
import {
  createAvatar,
  getAvatarById,
  updateAvatar,
} from "services/avatar.service";
import { createImageUrl } from "services/imageUrl.service";

const defaultValues = {
  gender: "",
  image: "",
};

const AddAvatar = ({ toggle, modal, id = false, reload, props }) => {
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [loadingImage, setLoadingImage] = useState(false);

  useEffect(() => {
    if (id) AvatarInfo();
    else setValues(defaultValues);
  }, [id]);

  const AvatarInfo = () => {
    getAvatarById(id)
      .then(res => {
        let { data } = res;
        console.log(data);
        setValues({ ...data });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onSubmit = values => {
    if (id) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleChange = e => {
    let { name, value } = e.target;
    let obj = { ...values };

    switch (name) {
      case "gender":
        obj.gender = value;
        break;

      default:
        break;
    }

    setValues({ ...obj });
  };

  const handleImageChange = async image => {
    setLoadingImage(true);
    let obj = { ...values };
    try {
      let imageFormData = new FormData();
      imageFormData.set("image", image);

      const data = await createImageUrl(imageFormData);

      if (data.success) {
        obj.image = data.image;
      }

      setValues({ ...obj });
    } catch (err) {
      console.log(err);
    }
    setLoadingImage(false);
  };

  const saveChanges = () => {
    setLoading(true);
    createAvatar(values)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        reload();
        setValues(defaultValues);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = () => {
    setLoading(true);
    console.log(values);
    updateAvatar(id, values)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        reload();
        setValues(defaultValues);
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={id ? leaveToList : stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Avatar Successfully {id ? "Updated" : "Added"}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={id ? leaveToList : stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {" "}
          {id ? "Edit Avatar" : "Add Avatar"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid={false}>
            <Card>
              <CardBody>
                <Row className="mt-4">
                  <Col md={12} className="px-4">
                    <Row className="mb-3">
                      <FormGroup className="mb-3">
                        <Label htmlFor="validationCustom01">Gender</Label>
                        <select
                          name="gender"
                          className="form-control"
                          id="validationCustom01"
                          onChange={handleChange}
                          value={values.gender}
                          required
                        >
                          <option value={""}>-- Select --</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </select>
                      </FormGroup>
                    </Row>
                    <Row className="mb-3">
                      <FormGroup className="mb-3">
                        <Col md={8}>
                          <Label htmlFor="validationCustom06">Image</Label>
                          <Dropzone
                            id="validationCustom06"
                            onDrop={acceptedFiles => {
                              acceptedFiles.map(file =>
                                Object.assign(file, {
                                  preview: URL.createObjectURL(file),
                                  formattedSize: formatBytes(file.size),
                                })
                              );
                              handleImageChange(acceptedFiles[0]);
                            }}
                          >
                            {({ getRootProps, getInputProps }) => (
                              <div className="dropzone image_drop">
                                <div
                                  className="dz-message needsclick "
                                  {...getRootProps()}
                                >
                                  <input {...getInputProps()} />
                                  <div className="">
                                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                                  </div>
                                  <h6>Drop files here or click to upload.</h6>
                                </div>
                              </div>
                            )}
                          </Dropzone>
                        </Col>
                        <Col md={4}>
                          <div
                            className="dropzone-previews mt-3"
                            id="file-previews"
                          >
                            {loadingImage ? (
                              ""
                            ) : values?.image ? (
                              <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <ProfileImage
                                        image={values?.image}
                                        height="150"
                                        classNames="avatar-sm rounded bg-light"
                                      />
                                    </Col>
                                  </Row>
                                </div>
                              </Card>
                            ) : (
                              ""
                            )}
                          </div>
                        </Col>
                      </FormGroup>
                    </Row>
                  </Col>
                </Row>
                <hr></hr>
                <div className="d-flex justify-content-end">
                  <Button
                    color="light"
                    className="w-md btn-sm me-3"
                    onClick={leaveToList}
                  >
                    cancel
                  </Button>
                  {!id ? (
                    <Button
                      color="primary"
                      type="submit"
                      className="w-md btn-sm me-4"
                      onClick={onSubmit}
                    >
                      Submit
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      className="w-md btn-sm me-4"
                      type="submit"
                      onClick={onSubmit}
                    >
                      Save Changes
                    </Button>
                  )}
                </div>
                {success_msg ? successPopup() : null}
                {error_msg ? errorPopup() : null}
              </CardBody>
            </Card>
          </Container>
          {/* </div> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default AddAvatar;
