import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import { Badge, Card, CardBody, Col, Container, Row, Label } from "reactstrap";
import { Link } from "react-router-dom";
import Select from "react-select";

import Breadcrumb from "../../components/Common/Breadcrumb";
import { getOrderbyId, applyBid } from "services/orders.service";
import {
  getProvidersbyLocation,
  getProviders,
} from "services/provider.service";
import Spinner from "components/spinner";
import ProfileImage from "components/profileImage";
import "./view.scss";
import moment from "moment";

const defaultValues = {
  addons: [],
  biddings: [],
  categoryid: {},
  createdAt: "",
  gender: "Male",
  location: {},
  date: "",
  time: "",
  numberOfFeMale: 0,
  numberOfKids: 0,
  numberOfMale: 1,
  serviceid: {},
  status: "",
  address: "",
  updatedAt: "",
  userid: {},
};

const ViewOrder = props => {
  const { id } = useParams();
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);
  const [providersList, setProvidersList] = useState([]);
  const [priceList, setPriceList] = useState([]);
  const [allProviders, setAllProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [manualPrice, setManualPrice] = useState(0);

  let { image } = values;

  const getOrderByID = () => {
    getOrderbyId(id)
      .then(res => {
        let {
          _id,
          gender,
          status,
          address,
          createdAt,
          updatedAt,
          serviceid,
          categoryid,
          userid,
          numberOfFeMale,
          numberOfKids,
          numberOfMale,
          addons,
          biddings,
          location,
          time,
          date,
        } = res.data;

        let obj = {
          _id,
          gender,
          status,
          address,
          createdAt,
          updatedAt,
          serviceid,
          categoryid,
          userid,
          numberOfFeMale,
          numberOfKids,
          numberOfMale,
          addons,
          biddings,
          location,
          time,
          date,
        };
        setValues({ ...obj });
        getProvidersList();
        if (!biddings || biddings.length == 0) {
          getprovidersByLocation(obj);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getProvidersList = type => {
    getProviders()
      .then(res => {
        if (res.data) {
          let result = res.data;
          result = result.map(prov => {
            return {
              value: prov._id,
              label: prov.legalname,
            };
          });
          setAllProviders(result);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  useEffect(() => {
    getOrderByID();
  }, []);

  const getprovidersByLocation = ({ serviceid, gender, location }) => {
    let idList = [];
    serviceid.forEach(ser => idList.push(ser._id));
    let body = {
      serviceid: idList,
      gender: gender,
      range: 20000,
      location: location,
    };
    getProvidersbyLocation(body)
      .then(res => {
        if (res.rcs) {
          let list = res.rcs;
          setProvidersList(list);
          let priceArr = [];
          list.forEach((ele, idx) => {
            priceArr[idx] = 0;
          });
          setPriceList([...priceArr]);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const handleManualPrice = price => {
    setManualPrice(price);
  };

  const handlePriceChange = (price, index) => {
    priceList[index] = price;
    setPriceList([...priceList]);
  };

  const handleAssaign = (provider, index, manual = false) => {
    let price = "";
    let id = "";
    if (manual) {
      price = manualPrice;
      id = selectedProvider.value;
    } else {
      price = priceList[index];
      id = provider._id;
    }

    if (price) {
      let body = {
        provider: id,
        amount: price,
        status: "Assigned",
      };
      setLoading(true);
      applyBid(id, body)
        .then(res => {
          if (res.data) {
            getOrderByID();
          } else {
            setLoading(false);
          }
        })
        .catch(e => {
          console.log(error);
          setLoading(false);
        });
    }
  };

  function handleSelect(event) {
    setSelectedProvider(event);
  }

  const renderProviders = () => {
    if (providersList && providersList.length > 0) {
      return (
        <Row>
          {providersList.map((provider, index) => {
            return (
              <Col key={index} md="4" className="my-3 provider_by_loc">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <Link
                      to={`/provider-view/${provider._id}`}
                      className="back_btn"
                    >
                      <i className="mdi mdi-forwardburger font-size-14" />
                    </Link>
                    <div className="w-100">
                      <div className="d-flex">
                        <ProfileImage classNames="avatar-sm" />
                        <div className="ms-3">
                          <p className="mb-0 fw-bold">Name</p>
                          <p className="text-muted mb-0 fw-medium">
                            {provider.legalname}
                          </p>
                        </div>
                      </div>
                      <hr className="my-3" />
                      <Row>
                        <Col lg="6">
                          <p className="mb-0 fw-medium">Mobile Number</p>
                          <p className="text-muted mb-0">
                            {" "}
                            {provider.mobilenumber}
                          </p>
                        </Col>
                        <Col lg="6">
                          <p className="mb-0 fw-medium">Experience Type</p>
                          <p className="text-muted mb-0">
                            {provider.experienceType}
                          </p>
                        </Col>
                      </Row>

                      <div className="d-flex mt-3">
                        {provider.accepted === "true" && (
                          <div className="timeline_tag">Accepted</div>
                        )}
                        {provider.access === "true" && (
                          <div className="timeline_tag">Access</div>
                        )}
                        {provider.avaliable === "true" && (
                          <div className="timeline_tag">Available</div>
                        )}
                      </div>

                      <hr className="my-3" />
                      <div className="d-flex justify-content-between">
                        <div className="w-50 pe-2">
                          <input
                            type="number"
                            placeholder="Price"
                            className="form-control btn-sm"
                            value={priceList[index]}
                            onChange={event =>
                              handlePriceChange(event.target.value, index)
                            }
                          />
                        </div>
                        <div className="w-50 ps-2">
                          <input
                            type="button"
                            value="Assaign"
                            className="w-100 btn btn-primary btn-sm"
                            onClick={() => handleAssaign(provider, index)}
                          />
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      );
    }
    return (
      <Row>
        <Card>
          <CardBody>
            <h4 className="text-center px-3">
              Sorry!!... Providers are not available
            </h4>
          </CardBody>
        </Card>
      </Row>
    );
  };

  const renderBiddings = () => {
    if (values.biddings && values.biddings.length > 0) {
      return (
        <Row>
          <div className="">
            <h5 className="font-size-18">Biddings</h5>
          </div>
          {values.biddings.map((bidding, index) => {
            return (
              <Col key={index} md="4" className="my-3 provider_by_loc">
                <Card className="mini-stats-wid">
                  <CardBody>
                    <div className="w-100">
                      <div className="d-flex">
                        <div className="ms-3 d-flex">
                          <p className="mb-0 fw-bold me-2">Provider</p>
                          <p className="text-muted mb-0 fw-medium">
                            {bidding.provider.legalname}
                          </p>
                        </div>
                      </div>
                      <hr className="my-3" />
                      <Row className="mb-3">
                        <Col lg="6">
                          <p className="mb-0 fw-medium">Amount</p>
                          <p className="text-muted mb-0"> {bidding.amount}</p>
                        </Col>
                        <Col lg="6">
                          <p className="mb-0 fw-medium">Payment</p>
                          <p className="text-muted mb-0">{bidding.payment}</p>
                        </Col>
                      </Row>
                      <Row className="mb-3">
                        <Col lg="6">
                          <p className="mb-0 fw-medium">Status</p>
                          <p className="text-muted mb-0"> {bidding.status}</p>
                        </Col>
                        <Col lg="6">
                          <p className="mb-0 fw-medium">Transcation</p>
                          <p className="text-muted mb-0">
                            {bidding.transcation}
                          </p>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      );
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Orders | stylepro </title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumb title="Orders" breadcrumbItem="Order View" />
          <Card className="view-users">
            <CardBody>
              <div className="d-flex justify-content-between">
                <Link to="/orders">
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link>
                <div className="text-end">
                  <Badge
                    className={
                      values.status == "Completed"
                        ? "font-size-12 badge-soft-success py-1 px-3"
                        : "font-size-12 badge-soft-danger py-1 px-3"
                    }
                    color={
                      values.status == "Completed" ? "bg-success" : "bg-warning"
                    }
                    pill
                  >
                    {values.status}
                  </Badge>
                </div>
              </div>
              <Row>
                <Col md={1} className="">
                  <div className="text-center my-4"></div>
                </Col>
                <Col md={11} className=" pt-4 mb-4 px-0 view_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">Order Id </span>{" "}
                      <span className="text-muted">{values._id}</span>
                    </p>

                    <p className="ps-0">
                      <span className="pe-3 fw-bold">User Name </span>{" "}
                      <span className="text-muted">
                        {values?.userid?.firstname
                          ? values?.userid?.firstname
                          : " _"}{" "}
                      </span>
                      <span className="text-muted">
                        {values?.userid?.lastname
                          ? values?.userid?.lastname
                          : " _"}{" "}
                      </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={6} className="mt-4">
                      <p className="fw-bold mb-1">Services</p>
                      {values.serviceid && values.serviceid.length > 0 && (
                        <p className="text-muted">
                          {values.serviceid.map((ser, indx) => {
                            return (
                              <Badge
                                key={indx}
                                color="primary"
                                className="m-1 fs-6 p-2"
                              >
                                {ser.name}
                              </Badge>
                            );
                          })}
                        </p>
                      )}
                    </Col>
                    {/* <Col md={6} className="mt-4">
                      <p className="fw-bold mb-1">Categories</p>
                      <p className="text-muted">
                        {values.biddings?.length ? values.biddings.length : "_"}
                      </p>
                    </Col> */}
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Gender</p>
                      <p className="text-muted">
                        {values.gender ? values.gender : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Status</p>
                      <p className="text-muted">
                        {values.status ? values.status : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Biddings</p>
                      <p className="text-muted">
                        {values.biddings?.length ? values.biddings.length : "_"}
                      </p>
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Time Slot Date</p>
                      <p className="text-muted">
                        {values.date
                          ? new Date(values.date).toDateString()
                          : "_"}
                        {" - "}
                        {values.time ? values.time : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Payment</p>
                      <p className="text-muted">
                        {values.payment ? values.payment : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Address</p>
                      <p className="text-muted">
                        {values.address ? values.address : "_"}
                      </p>
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={3} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">
                        {moment(
                          values.createdAt ? values.createdAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={3} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">
                        {moment(
                          values.updatedAt ? values.updatedAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={6} className="mt-4">
                      <div className="d-flex justify-content-between">
                        <div className="w-100 pe-3">
                          <Label htmlFor="providers">Select Provider</Label>
                          <Select
                            // className="form-control"
                            id="providers"
                            value={selectedProvider}
                            onChange={event => {
                              handleSelect(event);
                            }}
                            name="type"
                            options={allProviders}
                            classNamePrefix="select2-selection"
                          />
                        </div>
                        <div className="w-50 pe-3">
                          <Label htmlFor="amount">amount</Label>
                          <input
                            id="amount"
                            type="number"
                            placeholder="amount"
                            className="form-control btn-md"
                            value={manualPrice}
                            onChange={event =>
                              handleManualPrice(event.target.value)
                            }
                          />
                        </div>
                        <div className="align-self-end">
                          <button
                            className="btn btn-md btn-primary"
                            onClick={() => handleAssaign("", "", true)}
                          >
                            Assaign
                          </button>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {renderBiddings()}
          {values.status == "Pending" &&
            values.biddings?.length == 0 &&
            renderProviders()}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewOrder;
