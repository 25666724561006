import React from "react"
import { Card, CardBody } from "reactstrap"
import MetaTags from "react-meta-tags"
import { Container, Row, Col } from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import { Link } from "react-router-dom"
import {
  Collapse,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap"
import { useState } from "react"
import { BasicServiceCost } from "./Basic"
import { AdditionalServiceCost } from "./Additional"
import "./style.scss"

const ServiceCost = () => {
  const [isOpen, setIsOpen] = useState(true)

  const toggle = () => setIsOpen(!isOpen)
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="" breadcrumbItem="" />

          <Row>
            <Col md="2">
              <Card>
                <CardBody className="">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4">
                      <div className="mb-3">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn btn-light w-100"
                            color="#eff2f7"
                          >
                            <i className="mdi mdi-plus me-1"></i> Create New
                          </DropdownToggle>
                          <DropdownMenu>
                            <Link className="dropdown-item" to="#">
                              <i className="bx bx-folder me-1"></i> Folder
                            </Link>
                            <Link className="dropdown-item" to="#">
                              <i className="bx bx-file me-1"></i> File
                            </Link>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </div>
                      <ul className="list-unstyled categories-list">
                        <li>
                          <div className="custom-accordion">
                            <Link
                              className="text-body fw-medium py-1 d-flex align-items-center"
                              onClick={toggle}
                              to="#"
                            >
                              <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                              Women{" "}
                              <i
                                className={
                                  isOpen
                                    ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                    : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                }
                              />
                            </Link>
                            <Collapse isOpen={isOpen}>
                              <div className="card border-0 shadow-none ps-2 mb-0">
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Hair</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Nails</span>{" "}
                                      <i className="mdi mdi-pin ms-auto"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Face</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Wax</span>{" "}
                                      <i className="mdi mdi-pin ms-auto"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Makeup</span>{" "}
                                      <i className="mdi mdi-pin ms-auto"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Threading</span>{" "}
                                      <i className="mdi mdi-pin ms-auto"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </Collapse>
                          </div>
                        </li>
                      </ul>
                      <ul className="list-unstyled categories-list">
                        <li>
                          <div className="custom-accordion">
                            <Link
                              className="text-body fw-medium py-1 d-flex align-items-center"
                              onClick={toggle}
                              to="#"
                            >
                              <i className="mdi mdi-folder font-size-16 text-warning me-2"></i>{" "}
                              Men{" "}
                              <i
                                className={
                                  isOpen
                                    ? "mdi mdi-chevron-up accor-down-icon ms-auto"
                                    : "mdi mdi-chevron-down accor-down-icon ms-auto"
                                }
                              />
                            </Link>
                            <Collapse isOpen={isOpen}>
                              <div className="card border-0 shadow-none ps-2 mb-0">
                                <ul className="list-unstyled mb-0">
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Hair</span>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Nails</span>{" "}
                                      <i className="mdi mdi-pin ms-auto"></i>
                                    </Link>
                                  </li>
                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Face</span>
                                    </Link>
                                  </li>

                                  <li>
                                    <Link
                                      to="#"
                                      className="d-flex align-items-center"
                                    >
                                      <span className="me-auto">Makeup</span>{" "}
                                      <i className="mdi mdi-pin ms-auto"></i>
                                    </Link>
                                  </li>
                                </ul>
                              </div>
                            </Collapse>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="2">
              <Card>
                <CardBody>
                  <div className="empty_list p-3 pt-0">
                    <div className="text-center">
                      <i
                        className="mdi mdi-arrow-left-circle"
                        id="nullCatList"
                      ></i>
                    </div>
                    <p className="text-center">Please Select Parent Category</p>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="8">
              <Card>
                <CardBody className="border-3 border-bottom">
                  <div className="services_tabs p-3 pb-0">
                    <button className="me-3 mb-3 px-3 py-1">Layer Cut</button>
                    <button className="me-3 mb-3 px-3 py-1">
                      Face framing layers
                    </button>
                    <button className="me-3 mb-3 px-3 py-1">
                      Straight cut
                    </button>
                    <button className="me-3 mb-3 px-3 py-1">
                      Layers with bangs
                    </button>
                    <button className="me-3 mb-3 px-3 py-1">
                      Bangs for medium hair
                    </button>
                    <button className="me-3 mb-3 px-3 py-1">
                      Regular Hair Cut
                    </button>
                    <button className="me-3 mb-3 px-3 py-1">test</button>
                    <button className="me-3 mb-3 px-3 py-1">
                      Regular Hair Cut 1
                    </button>
                  </div>
                </CardBody>
              </Card>
              <Card>
                <CardBody className="border-3 border-bottom">
                  <BasicServiceCost />
                </CardBody>
              </Card>
              <Card>
                <CardBody>
                  <AdditionalServiceCost />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}
export default ServiceCost
