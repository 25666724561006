import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";
import { getBrands } from "services/ecommerce/brand.service";
import { updatefeildService } from "services/services.service";

export const BrandInfoModel = ({ toggle, initValues, reloadService }) => {
  const [values, setValues] = useState();
  const [brandsList, setBrandslist] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState(null);
  useEffect(() => {
    getBrandsList();

    const { brands } = initValues;
    setSelectedBrands(buildOptions(brands));
  }, []);
  const getBrandsList = () => {
    getBrands()
      .then(res => {
        if (res.data) {
          const result = buildOptions(res?.data);
          setBrandslist(result);
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  //Build {label,value}array
  const buildOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele._id,
      };
      return obj;
    });
    return result;
  };

  function handleBrands(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["brands"]: data });
    setSelectedBrands(selectedMulti);
  }
  const onSubmit = () => {
    let body = { ...values };
    updatefeildService(initValues._id, body)
      .then(res => {
        console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };
  return (
    <>
      <Row className="mt-3 mx-0">
        <Col md={12} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="brands">Brands</Label>
            <Select
              id="brands"
              value={selectedBrands}
              isMulti={true}
              onChange={event => {
                handleBrands(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={brandsList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default BrandInfoModel;
