import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import Spinner from "components/spinner";
import { Container, Button, Card, Row, Col, CardBody, Badge } from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { getEcomOrders, getOrderbyProviderId } from "services/orders.service";
import moment from "moment";
import MultipleCheckbox from "components/MutlpleCheckbox";
import EcomOrderView from "./view";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const EcomOrders = () => {
  const { SearchBar } = Search;
  const [ordersList, setOrdersList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [typeFilter, setTypeFilter] = useState([]);
  const typeFilterOptions = ["Pending", "Completed", "Paid", "Accept"];
  const [tempList, setTempList] = useState([]);
  const [modal, setModal] = useState(false);
  const [activeViewId, setActiveViewId] = useState(false);
  const [activeOrder, setActiveOrder] = useState();

  useEffect(() => {
    getOrdersList();
  }, []);

  const getOrdersList = () => {
    getEcomOrders()
      .then(res => {
        setOrdersList(res?.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const orders = () => [
    {
      dataField: "ordercode",
      text: "Order Id",
      formatter: id => {
        return (
          <>
            <div className=" ">
              {" "}
              <h6>#{id}</h6>
            </div>
          </>
        );
      },
    },

    {
      dataField: "orderdate",
      text: "Order Date",
      formatter: created => {
        return (
          <>
            <div className="">{moment(created).format("MMM Do YYYY")}</div>
          </>
        );
      },
    },
    {
      dataField: "paymentMethod",
      text: "Payment Method",
    },

    {
      dataField: "paymentstatus",
      text: "Payment Status",
      formatter: status => {
        return (
          <div className="">
            {" "}
            <Badge
              className="font-size-6 badge-soft-success py-1 px-2 "
              color={
                status == "Completed" || status == "completed"
                  ? "bg-success"
                  : ""
              }
              pill
            >
              {status == "Completed" || status == "completed" ? "Paid" : ""}
            </Badge>
          </div>
        );
      },
    },
    {
      dataField: "total.total",
      text: "Amount",
      formatter: total => {
        if (typeof total === "string") {
          total = Number(total).toFixed(2);
        }
        return (
          <>
            <div className="">₹ {total}</div>
          </>
        );
      },
    },
    {
      dataField: "isWeb",
      text: "Ordered From",
      formatter: isWeb => {
        if (isWeb === false) {
          isWeb = "Mobile";
        } else {
          isWeb = "Web";
        }
        return (
          <>
            <div className="">{isWeb}</div>
          </>
        );
      },
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "View",
      formatter: (col, row) => (
        <div className="">
          <Badge
            type="button"
            className="font-size-6 badge-soft-info py-1 px-2 me-2"
            color={"bg-success"}
            pill
            onClick={() => {
              setActiveViewId(row._id);
              setActiveOrder(row);
              toggle();
            }}
          >
            <i className="mdi mdi-eye font-size-10" />{" "}
          </Badge>
        </div>
      ),
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: ordersList?.length,
    custom: true,
  };

  const toggle = () => {
    if (modal) {
      setActiveViewId(null);
    }
    setModal(!modal);
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="page-content">
        <MetaTags>
          <title>Orders</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Orders" breadcrumbItem="Orders List" />
          <BreadcrumbSub subtile="Ecom orders" SubBreadcrumbItem="Orders" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={orders()}
                    data={ordersList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={ordersList}
                        columns={orders()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4" className="d-flex">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              {/* <Col md="5" className="">
                                <div className="d-flex justify-content-start">
                                  {checkboxItems()}
                                </div>
                              </Col> */}
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="name"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {ordersList && ordersList?.length == 0 && (
                    <p className="text-center"> No Data Found</p>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {modal && (
        <EcomOrderView
          toggle={toggle}
          modal={modal}
          id={activeViewId}
          activeOrder={activeOrder}
          // reload={reloadProduct}
        />
      )}
    </React.Fragment>
  );
};

export default EcomOrders;
