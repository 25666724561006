const getStatusBgClass = (prefix, status) => {
  if (status === "Completed") return prefix + "-success";
  else if (status === "Paid") return prefix + "-info";
  else if (status === "Accept") return prefix + "-secondary";
  else if (status === "In Progress") return prefix + "-warning";
  else if (status === "Pending") return prefix + "-pending";
  else return prefix + "-danger";
};

export { getStatusBgClass };
