import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { Container, Button, Card, Row, Col, CardBody, Badge } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import EcomMainCat from "../CategoriesMenu/MainCatList";
import EcomParentCat from "../CategoriesMenu/parentCat";
import "./Styles.scss";
import { getCategoryRoot } from "services/ecommerce/category.service";
import {
  deleteProduct,
  getProductByCatId,
} from "services/ecommerce/product.service";
import { getSubCategoryByParent } from "services/ecommerce/category.service";
import SweetAlert from "react-bootstrap-sweetalert";
import ProductsIndex from ".";
import ViewProduct from "./view";
import Spinner from "components/spinner";
import { Link } from "react-router-dom";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const ProductsListEcommerce = () => {
  const [modal, setModal] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const { SearchBar } = Search;
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [mainCatList, setMainCatList] = useState([]);
  const [subCatList, setSubCatList] = useState(null);
  const [activeProd, setActiveProd] = useState(null);
  const [activeViewId, setActiveViewId] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [activeProductCatInd, setActiveProductCatInd] = useState(null);

  // Table Data
  const products = () => [
    {
      dataField: "brand.name",
      text: "Brand Name",
    },
    {
      dataField: "name",
      text: "Product Name",
    },
    {
      dataField: "status",
      text: "Status",
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (col, row) => (
        <div className="">
          <Badge
            type="button"
            className="font-size-6 badge-soft-info py-1 px-2 me-2"
            color={"bg-success"}
            pill
            onClick={() => {
              setActiveViewId(row._id);
              toggleView();
            }}
          >
            <i className="mdi mdi-eye font-size-10" />{" "}
          </Badge>
          <Link to={`/product/${row._id}`}>
            <Badge
              type="button"
              className="font-size-6 badge-soft-success py-1 px-2 me-2"
              color={"bg-warning"}
              pill
              onClick={() => {
                setActiveProd({ ...row });
                // toggle();
              }}
            >
              <i className="mdi mdi-pencil font-size-10" />{" "}
            </Badge>
          </Link>
          <Badge
            className="font-size-6 badge-soft-danger py-1 px-2"
            color={"bg-warning"}
            onClick={() => onClickDelete(row._id)}
            pill
          >
            <i className="mdi mdi-delete font-size-10 " />
          </Badge>
        </div>
      ),
    },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: list?.length, // replace later with size(products),
    custom: true,
  };

  const toggleView = () => {
    if (modalView) {
      setActiveViewId(null);
    }
    setModalView(!modalView);
  };

  useEffect(() => {
    getMainCategories();
  }, []);

  const getMainCategories = () => {
    getCategoryRoot()
      .then(res => {
        let { data } = res;

        setMainCatList(data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const getSubById = id => {
    getSubCategoryByParent(id)
      .then(res => {
        setSubCatList(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getProductsById = body => {
    getProductByCatId(body)
      .then(res => {
        if (res) {
          setList(res.data);
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleClickParent = ele => {
    setActiveProductCatInd("");
    getSubById(ele._id);
    setList([]);
  };

  const handleClickSub = ele => {
    setActiveProductCatInd(ele._id);
    let obj = {
      category: ele._id,
    };

    getProductsById(obj);
  };

  const removeProduct = id => {
    setLoading(true);
    deleteProduct(id)
      .then(res => {
        setDelete_modal(false);
        let body = {
          category: activeProductCatInd,
        };
        getProductsById(body);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeProduct(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const toggle = () => {
    if (modal) {
      setActiveProd(null);
    }
    setModal(!modal);
  };

  const reloadProduct = () => {
    getProductsById({
      category: activeProductCatInd,
    });
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="page-content">
        <MetaTags>
          <title>Products | Products List</title>
        </MetaTags>
        <Container fluid id="service_list">
          <Breadcrumbs title="Products" breadcrumbItem="Products List" />
          <BreadcrumbSub subtile="Products List" SubBreadcrumbItem="Products" />

          <Row>
            <Col className="tab_5 px-0 me-1">
              <Card className="">
                <CardBody className="px-2">
                  <EcomMainCat
                    list={mainCatList}
                    handleClick={handleClickParent}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col className=" tab_2 px-0 ">
              {" "}
              <Card className="">
                <CardBody className="px-3">
                  <EcomParentCat
                    list={subCatList}
                    handleClick={handleClickSub}
                  />
                </CardBody>
              </Card>
            </Col>

            <Col className=" px-0">
              <div className="">
                <Container fluid>
                  <Row>
                    <Col xs="12">
                      <Card>
                        <CardBody>
                          <PaginationProvider
                            pagination={paginationFactory(pageOptions)}
                            keyField="name"
                            columns={products()}
                            data={list}
                          >
                            {({ paginationProps, paginationTableProps }) => (
                              <ToolkitProvider
                                keyField="name"
                                data={list}
                                columns={products()}
                                bootstrap4
                                search
                              >
                                {toolkitProps => (
                                  <React.Fragment>
                                    <Row className="mb-2">
                                      <Col sm="4" className="d-flex">
                                        <div className="search-box me-2 mb-2 d-inline-block">
                                          <div className="position-relative">
                                            <SearchBar
                                              {...toolkitProps.searchProps}
                                            />
                                            <i className="bx bx-search-alt search-icon" />
                                          </div>
                                        </div>
                                      </Col>

                                      <Col sm="8">
                                        <div className="text-sm-end">
                                          {/* <Link to={`/product/product-create`}> */}
                                          {activeProductCatInd ? (
                                            <Link
                                              to={`/product/product-create/${activeProductCatInd}`}
                                            >
                                              <Button
                                                type="button"
                                                color="primary"
                                                className="btn-rounded  mb-2 me-2"
                                                disabled={false}
                                              >
                                                <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                                Add Product
                                              </Button>
                                            </Link>
                                          ) : (
                                            <Link to="#">
                                              <Button
                                                type="button"
                                                color="primary"
                                                className="btn-rounded  mb-2 me-2"
                                                disabled={true}
                                              >
                                                <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                                Add Product
                                              </Button>
                                            </Link>
                                          )}
                                          {/* <Link
                                            to={`/product/product-create/${activeProductCatInd}`}
                                          >
                                            <Button
                                              type="button"
                                              color="primary"
                                              className="btn-rounded  mb-2 me-2"
                                              // onClick={toggle}
                                              disabled={
                                                activeProductCatInd
                                                  ? false
                                                  : true
                                              }
                                            >
                                              <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                              Add Product
                                            </Button>
                                          </Link> */}
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row>
                                      <Col xl="12">
                                        <div className="table-responsive">
                                          <BootstrapTable
                                            keyField="name"
                                            responsive
                                            bordered={false}
                                            striped={false}
                                            classes={
                                              "table align-middle table-nowrap table-check"
                                            }
                                            headerWrapperClasses={"table-light"}
                                            {...toolkitProps.baseProps}
                                            {...paginationTableProps}
                                          />
                                        </div>
                                      </Col>
                                    </Row>
                                    <Row className="align-items-md-center mt-30">
                                      <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                        <PaginationListStandalone
                                          {...paginationProps}
                                        />
                                      </Col>
                                    </Row>
                                  </React.Fragment>
                                )}
                              </ToolkitProvider>
                            )}
                          </PaginationProvider>
                          {list && list?.length == 0 && (
                            <p className="text-center"> No Data Found</p>
                          )}

                          {delete_modal ? deletePopup() : null}
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {delete_modal ? deletePopup() : null}
      {modal ? (
        <ProductsIndex
          toggle={toggle}
          modal={modal}
          product={activeProd}
          reload={reloadProduct}
        />
      ) : null}
      {modalView && (
        <ViewProduct toggle={toggleView} modal={modalView} id={activeViewId} />
      )}
    </React.Fragment>
  );
};

export default ProductsListEcommerce;
