import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";

import { getQuickServicesById } from "services/quickServices.service";
import ProfileImage from "../../components/profileImage";
import Spinner from "components/spinner";

const defaultValues = {
  name: "",
  price: "",
  discountPrice: "",
  priceUnit: "",
  customQuantityUnit: "",
  duration: "",
  description: "",
  banner: {},
  images: [],
  brands: [],
  products: [],
  createdAt: null,
  updatedAt: null,
};

const ViewQuickService = props => {
  const { id } = useParams();
  let history = useHistory();
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);

  let { banner, images, brands, products } = values;

  useEffect(() => {
    getQuickServicesById(id)
      .then(res => {
        let {
          name,
          price,
          discountPrice,
          priceUnit,
          customQuantityUnit,
          duration,
          description,
          banner,
          images,
          tips,
          tagline,
          benfits,
          precautions,
          brands,
          products,
          createdAt,
          updatedAt,
        } = res.data;

        let obj = {
          name,
          price,
          discountPrice,
          priceUnit,
          customQuantityUnit,
          duration,
          description,
          banner,
          images,
          tips,
          tagline,
          benfits,
          precautions,
          brands,
          products,
          createdAt,
          updatedAt,
        };
        setValues({ ...obj });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  const goToPreviousPath = () => {
    console.log(history);
    history.goBack();
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Quick Service View | stylepro</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumb
            title="Quick Services"
            breadcrumbItem="Quick Service View"
          />
          <Card className="view-users">
            <CardBody>
              <div className="d-flex justify-content-between">
                <Link to="/#" className="" onClick={() => goToPreviousPath()}>
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link>
                <Link className="text-end" to={`/quick-service-update/${id}`}>
                  <Badge
                    className="font-size-12 badge-soft-warning py-1 px-3"
                    color={"bg-warning"}
                    pill
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                </Link>
              </div>
              <Row>
                <Col md={4} className="">
                  <div className="text-center my-4">
                    <ProfileImage image={banner} classNames="img-thumbnail" />
                    <h5 className="mt-4 text-muted">
                      {values.name ? values.name : "_"}
                    </h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 view_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">name </span>{" "}
                      <span className="text-muted">
                        {values.name ? values.name : "_"}{" "}
                      </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Price</p>
                      <p className="text-muted">
                        {values?.price ? values.price : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Discount Price</p>
                      <p className="text-muted">
                        {values?.discountPrice ? values.discountPrice : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Price Unit</p>
                      <p className="text-muted">
                        {values?.priceUnit ? values.priceUnit : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Custom Quantity Unit</p>
                      <p className="text-muted">
                        {values?.customQuantityUnit
                          ? values.customQuantityUnit
                          : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Duration</p>
                      <p className="text-muted">
                        {values?.duration ? values.duration : "_"}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Enable Booking</p>
                      <p className="text-muted">
                        {values?.enableBooking == "true" ? "YES" : "NO"}
                      </p>
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Brands</p>
                      {brands &&
                        brands.length > 0 &&
                        brands.map(brand => {
                          return (
                            <Badge
                              key={brand.brandName}
                              className="font-size-12 badge-soft-primary px-4 py-2 me-3"
                              color={"bg-primary"}
                              pill
                            >
                              {brand.brandName}
                            </Badge>
                          );
                        })}
                      {brands?.length == 0 && "_"}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Products</p>
                      {products &&
                        products?.length > 0 &&
                        products.map(product => {
                          return (
                            <Badge
                              key={product.productName}
                              className="font-size-12 badge-soft-primary px-4 py-2 me-3"
                              color={"bg-primary"}
                              pill
                            >
                              {product.productName}
                            </Badge>
                          );
                        })}
                      {products?.length == 0 && "_"}
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Description</p>
                      {values?.description ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.description,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Tips</p>
                      {values?.tips ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.tips,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Tagline</p>
                      {values?.tagline ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.tagline,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Benfits</p>
                      {values?.benfits ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.benfits,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                    <Col md={12} className="mt-4">
                      <p className="fw-bold mb-1">Precautions</p>
                      {values?.precautions ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values.precautions,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">
                        {moment(
                          values.createdAt ? values.createdAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">
                        {moment(
                          values.updatedAt ? values.updatedAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <h5 className="text-muted">IMAGES</h5>
                {images?.map((img, index) => {
                  return (
                    <Col key={index} md={4} className="px-1">
                      <ProfileImage image={img} classNames="img-thumbnail" />
                    </Col>
                  );
                })}
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewQuickService;
