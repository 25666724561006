import React, { Component, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import BarChart from "components/charts/bar";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import profileImg1 from "assets/images/users/avatar-1.jpg";
import profileImg2 from "assets/images/users/avatar-2.jpg";
import profileImg3 from "assets/images/users/avatar-3.jpg";
import profileImg4 from "assets/images/users/avatar-4.jpg";

import maintenance from "assets/images/maintenance.svg";
import logo from "assets/images/logo-dark.png";

import {
  getCategoriesCount,
  getProviderCount,
  getServiceCount,
  getUserCount,
} from "services/dashboard.service";

import Spinner from "components/spinner";
import { getToken } from "@firebase/messaging";
import { messaging } from "firebase";
import axios from "axios";

const Dashboard = () => {
  const [reports, setReports] = useState([
    {
      title: "Total Categories",
      iconClass: "bx-calendar-check",
      description: 0,
      value: "category",
      link: "/SubCategorieNew",
    },
    {
      title: "Total Services",
      iconClass: "bx-dollar",
      description: 0,
      value: "service",
      link: "/Services-list",
    },
    {
      title: "Total Provider",
      iconClass: "bx-group",
      description: 0,
      value: "provider",
      link: "/Provider-list-individul",
    },
    {
      title: "Total users",
      iconClass: "bxs-group",
      description: 0,
      value: "user",
      link: "/users2",
    },
  ]);

  const getWebToken = async () => {
    console.log(messaging)
    // const firebaseMessaging = getMessaging();
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        return getToken(messaging, {vapidKey: 'BOwMDUCR-6GhtNpoD-6lMILWwM6iHgtzBCAoke0I0Hr0S1gT4GQgqGtF3v7OZ96byaIZJiDgsnCQUEy2oHPVMKs'})
        .then(async (currentToken) => {
          if (currentToken) {
            let authUser = await localStorage.getItem('authUser');
            authUser = JSON.parse(authUser);
            console.log('authUser=', authUser);
            
            console.log('current token for client: ', currentToken)
            console.log(authUser.token);
            if(currentToken == authUser?.firebaseToken) {
              console.log('firebase token is already existed. No need to call api');
            } else {
              axios.put(`${process.env.REACT_APP_DATABASEURL}/api/v1/auth/updateFirebaseToken/`,
              {"firebaseToken":currentToken,
              "_id":authUser._doc._id
              }).then((res)=>{ })
            }
           
            // setTokenFound(true);
            // Track the token -> client mapping, by sending to backend server
            // show on the UI that permission is secured
          } else {
            console.log('No registration token available. Request permission to generate one.');
            // setTokenFound(false);
            // shows on the UI that permission is required 
          }
        }).catch((err) => {
          console.log('An error occurred while retrieving token. ', err);
          // catch error while creating client token
        });
      }
        
      })
  }





  useEffect(() => {
    getcount();
    getWebToken();
  }, []);
  const getcount = () => {
    Promise.allSettled([
      getCategoriesCount(),
      getServiceCount(),
      getProviderCount(),
      getUserCount(),
    ])
      .then(res => {
        const data = [...reports];
        data.forEach((e, i) => {
          console.log(res[i].value[e.value]);
          e.description = res[i].value[e.value];
        });
        setReports(data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  let orders = [
    {
      id: 1,
      name: "John Ran",
      image: profileImg1,
      providerType: "Individual",
      phoneNumber: "+91 9876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "10 KM",
    },
    {
      id: 2,
      name: "Meher San",
      image: profileImg2,
      providerType: "Company",
      phoneNumber: "+91 9876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "8 KM",
    },
    {
      id: 3,
      name: "Ali Baljis",
      image: profileImg3,
      providerType: "Individual",
      phoneNumber: "+91 9876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "15 KM",
    },
    {
      id: 4,
      name: "Ali Baljis",
      image: profileImg4,
      providerType: "Group",
      phoneNumber: "+91 8876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "6 KM",
    },
    {
      id: 5,
      name: "Ali Baljis",
      image: profileImg2,
      providerType: "Individual",
      phoneNumber: "+91 7876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "13 KM",
    },
    {
      id: 6,
      name: "Ali Baljis",
      image: profileImg1,
      providerType: "Individual",
      phoneNumber: "+91 6876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "5 KM",
    },
    {
      id: 7,
      name: "Ali Baljis",
      image: profileImg3,
      providerType: "Individual",
      phoneNumber: "+91 9676543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "14 KM",
    },
    {
      id: 8,
      name: "Ali Baljis",
      image: profileImg4,
      providerType: "Individual",
      phoneNumber: "+91 6876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "7 KM",
    },
    {
      id: 9,
      name: "Ali Baljis",
      image: profileImg1,
      providerType: "Individual",
      phoneNumber: "+91 9876543210",
      address: "Hyderabad,Telangana",
      availabilityRange: "3 KM",
    },
  ];
  const { SearchBar } = Search;
  //pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: orders.length, // replace later with size(orders),
    custom: true,
  };
  const handleOrderClicks = () => {};
  const ProvidersColumn = () => [
    {
      dataField: "profile",
      text: "Profile",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <img src={row.image} alt="" className="rounded-circle avatar-sm" />
      ),
    },
    {
      dataField: "name",
      text: "Name",
    },

    {
      dataField: "address",
      text: "Address",
      formatter: (cellContent, row) => {
        let address = row.address.split(",");
        return (
          <address>
            {address?.map(addr => {
              return <p key={addr}>{addr}</p>;
            })}
          </address>
        );
      },
    },

    {
      dataField: "view",
      isDummyField: true,
      text: "Action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: () => (
        <Link to="/user-view">
          <Button
            type="button"
            color="bg-secondary"
            className="btn-sm btn-rounded btn-outline-primary"
          >
            View Details
          </Button>
        </Link>
      ),
    },
  ];
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Dashboard | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Row>
            {reports.map((report, key) => (
              <Col md="3" key={"_col_" + key}>
                <Link to={report.link}>
                  <Card className="mini-stats-wid">
                    <CardBody>
                      <div className="d-flex">
                        <div className="flex-grow-1">
                          <p className="text-muted fw-medium">{report.title}</p>
                          <h4 className="mb-0">{report.description}</h4>
                        </div>
                        <div className="avatar-sm rounded-circle bg-secondary align-self-center mini-stat-icon">
                          <span className="avatar-title ">
                            <i
                              className={
                                "bx " + report.iconClass + " font-size-24"
                              }
                            ></i>
                          </span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </Link>
              </Col>
            ))}
          </Row>
          {/* <Row> 
              <Col lg={6}>
              <Card>
                <CardBody>

                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0 font-size-20">2541</h5>
                        <p className="text-muted">Activated</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0 font-size-20">84845</h5>
                        <p className="text-muted">Pending</p>
                      </div>
                    </Col>
                    <Col sm={4}>
                      <div className="text-center">
                        <h5 className="mb-0 font-size-20">12001</h5>
                        <p className="text-muted">Deactivated</p>
                      </div>
                    </Col>
                  </Row>

                  <BarChart />
                </CardBody>
              </Card>
            </Col>
            <Col lg={6}>

              <Card>
              <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={ProvidersColumn()}
                    data={orders}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={orders}
                        columns={ProvidersColumn()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={true}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
              </Row> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

const InDeveloping = () => {
  return (
    <Container fluid style={{ "margin-top": "15%", "margin-bottom": "100px" }}>
      <Row>
        <Col xs="12" className="text-center">
          <div className="home-wrapper">
            <div className="mb-5">
              <Link to="#" className="d-block auth-logo">
                <img src={logo} alt="logo" height="40" />
              </Link>
            </div>

            <Row className="justify-content-center">
              <Col sm={4}>
                <div className="maintenance-img">
                  <img
                    src={maintenance}
                    alt=""
                    className="img-fluid mx-auto d-block"
                  />
                </div>
              </Col>
            </Row>
            <h3 className="mt-5">Dashboard is Under Development</h3>
            <p>Please check back in sometime.</p>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
