import React, { useEffect, useState } from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import { MetaTags } from "react-meta-tags";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import CreateAuthor from "./Create";
import { getAllAuthors } from "services/Author.service";
import Spinner from "components/spinner";
import ProfileImage from "./../../components/profileImage";
import { Link } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
const Authorsindex = () => {
  const { SearchBar } = Search;
  const [modal, setModal] = useState(false);
  const [activeAuthorData, setactiveAuthorData] = useState();
  const [AuthorsList, setAuthorsList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(true);
  const toggle = () => {
    if (modal) {
      setactiveAuthorData(null);
    }
    setModal(!modal);
  };

  useEffect(() => {
    GetAllAuthorsData();
  }, []);

  const GetAllAuthorsData = () => {
    getAllAuthors()
      .then(res => {
        setAuthorsList(res?.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const pageOptions = {
    sizePerPage: 5,
    totalSize: AuthorsList?.length, // replace later with size(customers),
    custom: true,
  };

  const Authors = () => [
    {
      dataField: "avatar",
      text: "Profile Photo",
      formatter: (cellContent, row) => (
        <ProfileImage
          image={row.avatar}
          classNames="rounded-circle avatar-sm"
        />
      ),
    },
    {
      dataField: "firstname",
      text: "First Name",
    },
    {
      dataField: "lastname",
      text: "Last Name",
    },
    {
      dataField: "designation",
      text: "Designation",
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
      formatter: (cell, row) => <>{new Date(row.updatedAt).toDateString()}</>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      formatter: (col, row) => (
        <div className="">
          {/* <Link to={`/product/${row._id}`}> */}
          <Badge
            type="button"
            className="font-size-6 badge-soft-success py-1 px-2 me-2"
            color={"bg-warning"}
            pill
            onClick={() => {
              setactiveAuthorData({ ...row });
              toggle();
            }}
          >
            <i className="mdi mdi-pencil font-size-10" />{" "}
          </Badge>
          {/* </Link> */}
          <Badge
            className="font-size-6 badge-soft-danger py-1 px-2"
            color={"bg-danger"}
            // onClick={() => onClickDelete(row._id)}
            pill
          >
            <i className="mdi mdi-delete font-size-10 " />
          </Badge>
        </div>
      ),
    },
  ];

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Authors" breadcrumbItem="Authors List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="brandName"
                    columns={Authors()}
                    data={AuthorsList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="brandName"
                        data={AuthorsList}
                        columns={Authors()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4" className="d-flex">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="mb-2 me-2"
                                    onClick={toggle}
                                  >
                                    Create
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="brandName"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {AuthorsList?.length == 0 && (
                    <p className="text-center"> No Data Found</p>
                  )}

                  {/* {delete_modal ? deletePopup() : null} */}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateAuthor
          toggle={toggle}
          modal={modal}
          AuthorData={activeAuthorData}
          reload={GetAllAuthorsData}
        />
      </div>
    </React.Fragment>
  );
};

export default Authorsindex;
