import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import MetaTags from "react-meta-tags";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

import {
  createProduct,
  getProduct,
  updateProduct,
} from "services/product.service";
import { getBrands } from "services/brand.service";

import { Formik, Form } from "formik";
import * as Yup from "yup";

const defaultValues = {
  productName: "",
  brand: "",
  price: "",
  image: "",
};

const CreateProduct = props => {
  const { id } = useParams();

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandList, setBrandList] = useState([]);

  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [attributes, setattributes] = useState([]);

  const initialValues = defaultValues;
  const validationSchema = Yup.object().shape({
    productName: Yup.string().required("Please Enter Product Name"),
    brand: Yup.string().required("Please Select Brand"),
    price: Yup.number().required("Please Enter Price"),
  });

  const attributeList = [
    { label: "Custom", value: "Custom" },
    { label: "Images", value: "Images" },
    { label: "Shades", value: "Shades" },
    { label: "Color", value: "Color" },
  ];

  const onSubmit = values => {
    if (id) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const { image } = values;

  const getAllBrands = () => {
    getBrands()
      .then(res => {
        const list = [];
        res?.data?.map(brand => {
          list.push({ label: brand.brandName, value: brand._id });
        });
        setBrandList(list);
      })
      .catch(err => {
        console.log(err);
      });
  };

  useEffect(() => {
    getAllBrands();
  }, []);

  function handleSelectBrand(event) {
    setValues({ ...values, ["brand"]: event.value });
    setSelectedBrand(event);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }

    attributes?.map((ele, idx) => {
      if (ele.type == "Images") {
        let imgArr = ele.value;
        if (imgArr.length > 0) {
          imgArr.forEach((imgObj, index) => {
            formData.set("attrImg" + index, imgObj.value);
          });
        }
        // attributes["Images"] = [];
      } else if (ele.type == "Shades") {
        let imgArr = ele.value;
        if (imgArr.length > 0) {
          imgArr.forEach((img, index) => {
            formData.set("attrShades" + index, img);
          });
        }
        // attributes["Shades"] = [];
      } else {
        if (ele.value) {
          let tempArr = ele.value.split(",");
          attributes[idx]["value"] = tempArr;
        }
      }
    });
    console.log(attributes);
    formData.set("attributes", JSON.stringify(attributes));
    createProduct(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    attributes?.map((ele, idx) => {
      if (ele.type == "Images") {
        let imgArr = ele.value;
        if (imgArr.length > 0) {
          let tempArr = [];
          imgArr.forEach((imgObj, index) => {
            if (typeof imgObj?.value == "string") {
              tempArr.push(imgObj);
            } else {
              formData.set("attrImg" + index, imgObj.value);
              tempArr.push(imgObj);
            }
          });
          attributes[idx]["value"] = tempArr;
        }
      } else if (ele.type == "Shades") {
        let imgArr = ele.value;
        if (imgArr.length > 0) {
          let tempArr = [];
          imgArr.forEach((img, index) => {
            if (typeof img == "string") {
              tempArr.push(img);
            } else {
              formData.set("attrShades" + index, img);
            }
          });
          attributes[idx]["value"] = tempArr;
        }
      } else {
        if (ele.value && typeof ele.value == "string") {
          let tempArr = ele.value.split(",");
          attributes[idx]["value"] = tempArr;
        }
      }
    });
    formData.set("attributes", JSON.stringify(attributes));
    updateProduct(id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    props.history.push("/products");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    props.history.push("/products");
  };

  const addNewAddon = () => {
    const feilds = [...attributes];
    feilds.push({ key: "", value: "", type: "Custom" });
    setattributes(feilds);
  };

  const removeAddon = index => {
    const feilds = [...attributes];
    feilds.splice(index, 1);
    setattributes(feilds);
  };
  const handleAddOn = (event, key, index) => {
    const feilds = [...attributes];
    feilds[index][key] = event.target.value;
    setattributes(feilds);
  };

  function handleSelectAttr(event, index) {
    console.log(event);
    const feilds = [...attributes];
    feilds[index]["type"] = event.value;
    if (event.label !== "Custom") {
      feilds[index]["key"] = event.label;
    }
    if (event.value == "Images" || event.value == "Shades") {
      feilds[index]["value"] = [];
    }
    setattributes(feilds);
  }

  function handleAcceptedAttrFiles(files, index, key) {
    let fields = [...attributes];
    let imgArray = fields[index]["value"];
    if (imgArray.length < 7) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );
      if (key == "Images") {
        const imgObj = {
          name: "",
          price: "",
          value: files[0],
        };
        imgArray.push(imgObj);
      } else {
        imgArray.push(files[0]);
      }
      // setValues({ ...values, [field]: imgArray });
      fields[index]["value"] = imgArray;
      setattributes(fields);
    } else {
      alert("Maximum 7 Images are allowed");
    }
  }

  function removeImage(rootIndex, imgIndex) {
    let fields = [...attributes];
    let imgArray = fields[rootIndex]["value"];
    imgArray.splice(imgIndex, 1);
    fields[rootIndex]["value"] = imgArray;
    setattributes(fields);
  }

  const handleAddImgProps = (event, rootIndex, imgIndex, type) => {
    const feilds = [...attributes];
    feilds[rootIndex]["value"][imgIndex][type] = event.target.value;
    setattributes(feilds);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Product created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Product Edit | stylepro</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid={false}>
          <Breadcrumb
            title="Product"
            breadcrumbItem={id ? "Edit Product" : "Create Product"}
          />
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  setFieldTouched,
                  values,
                }) => {
                  useEffect(() => {
                    if (id) {
                      setLoading(true);
                      getProduct(id)
                        .then(res => {
                          const data = { ...res.data };
                          const fields = ["productName", "image", "price"];
                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          setFieldValue("brand", data.brand._id, false);
                          setSelectedBrand({
                            value: data.brand._id,
                            label: data.brand.brandName,
                          });
                          data?.attributes?.length > 0
                            ? setattributes(data.attributes)
                            : setattributes([{ key: "", type: "", value: "" }]);
                          setLoading(false);
                          console.log(data.attributes);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-4">
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Product Name
                              </Label>
                              <Input
                                name="productName"
                                placeholder="Product Name"
                                type="text"
                                className="form-control"
                                id="productName"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.productName}
                                invalid={
                                  touched.productName && errors.productName
                                    ? true
                                    : false
                                }
                              />

                              {touched.productName && errors.productName ? (
                                <FormFeedback type="invalid">
                                  {errors.productName}
                                  {touched.productName}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="brand">Brand</Label>
                              <Select
                                // className="form-control"
                                id="brand"
                                value={selectedBrand}
                                onChange={event => {
                                  handleSelectBrand(event);
                                  handleChange("brand")(event.value);
                                }}
                                name="type"
                                options={brandList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.brand ? errors.brand : ""}
                              </Label>
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Price</Label>
                              <Input
                                name="price"
                                placeholder="Price"
                                type="number"
                                className="form-control"
                                id="price"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.price}
                                invalid={
                                  touched.price && errors.price ? true : false
                                }
                              />

                              {touched.price && errors.price ? (
                                <FormFeedback type="invalid">
                                  {errors.price}
                                  {touched.price}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-4">
                          <Row className="">
                            <FormGroup className="">
                              <Label htmlFor="validationCustom06">Image</Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("image", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="statusValidation">
                                {" "}
                                {errors.image ? errors.image : ""}
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.image &&
                                  Object.keys(values.image).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.image}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.image.name ||
                                                values.image.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.image.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                          <Row>
                            <div className=" mt-3">
                              <Label>Attributes</Label>

                              <Button
                                color="primary"
                                className="w-md btn-sm ms-4"
                                onClick={() => {
                                  addNewAddon();
                                }}
                              >
                                Add New Attributes
                              </Button>
                            </div>
                          </Row>
                          {attributes.length > 0 &&
                            attributes.map((e, i) => (
                              <Row className="mt-2 mb-3 flex-nowrap" key={i}>
                                <Col md={4} className="">
                                  <Select
                                    // className="form-control"
                                    id="attr-select"
                                    value={{ label: e.type, value: e.type }}
                                    onChange={event => {
                                      handleSelectAttr(event, i);
                                    }}
                                    name="attr"
                                    options={attributeList}
                                    classNamePrefix="select2-selection"
                                  />
                                </Col>
                                {e.type == "Custom" && (
                                  <Col className="px-0">
                                    <Input
                                      placeholder="name"
                                      value={e.key}
                                      onChange={event => {
                                        handleAddOn(event, "key", i);
                                      }}
                                    />
                                  </Col>
                                )}
                                {(e.type == "Custom" || e.type == "Color") && (
                                  <Col className="">
                                    <Input
                                      placeholder="value"
                                      value={e.value}
                                      onChange={event => {
                                        handleAddOn(event, "value", i);
                                      }}
                                    />
                                  </Col>
                                )}
                                {(e.type == "Images" || e.type == "Shades") && (
                                  <Col className="">
                                    {/* Multi Image dropdown here */}
                                    <Row className="mb-3">
                                      <Col sm={12}>
                                        <Dropzone
                                          onDrop={acceptedFiles => {
                                            handleAcceptedAttrFiles(
                                              acceptedFiles,
                                              i,
                                              e.type
                                            );
                                          }}
                                        >
                                          {({
                                            getRootProps,
                                            getInputProps,
                                          }) => (
                                            <div className="dropzone image_drop">
                                              <div
                                                className="dz-message needsclick"
                                                {...getRootProps()}
                                              >
                                                <input {...getInputProps()} />
                                                <div className="">
                                                  <i className="display-6 text-muted bx bxs-cloud-upload" />
                                                </div>
                                                <h6>
                                                  Drop files here or click to
                                                  upload.
                                                </h6>
                                              </div>
                                            </div>
                                          )}
                                        </Dropzone>
                                        <div
                                          className="dropzone-previews mt-3"
                                          id="file-previews"
                                        >
                                          {e.value &&
                                            e.value.length > 0 &&
                                            e.value.map((img, index) => {
                                              return (
                                                <>
                                                  {e.type == "Images" && (
                                                    <Row>
                                                      <Col md={6}>
                                                        <Input
                                                          placeholder="name"
                                                          value={img.name}
                                                          onChange={event => {
                                                            handleAddImgProps(
                                                              event,
                                                              i,
                                                              index,
                                                              "name"
                                                            );
                                                          }}
                                                        />
                                                      </Col>
                                                      <Col md={6}>
                                                        <Input
                                                          placeholder="Price"
                                                          value={img.price}
                                                          onChange={event => {
                                                            handleAddImgProps(
                                                              event,
                                                              i,
                                                              index,
                                                              "price"
                                                            );
                                                          }}
                                                        />
                                                      </Col>
                                                    </Row>
                                                  )}
                                                  <Card
                                                    key={index}
                                                    className="mt-1 mb-3 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                  >
                                                    <div className="p-2">
                                                      <Row className="align-items-center">
                                                        <Col className="col-auto">
                                                          <ProfileImage
                                                            image={
                                                              img.value || img
                                                            }
                                                            height="80"
                                                            classNames="avatar-sm rounded bg-light"
                                                          />
                                                        </Col>
                                                        <Col>
                                                          <Link
                                                            to="#"
                                                            className="text-muted font-weight-bold"
                                                          >
                                                            {img?.name ||
                                                              img?.filename}
                                                          </Link>
                                                          <p className="mb-0">
                                                            <strong>
                                                              {
                                                                img.formattedSize
                                                              }
                                                            </strong>
                                                          </p>
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                    {/* <div> */}
                                                    <i
                                                      style={{
                                                        position: "absolute",
                                                        right: "3px",
                                                        cursor: "pointer",
                                                      }}
                                                      className="mdi mdi-close-thick font-size-18 text-danger"
                                                      id="removeImgtooltip"
                                                      onClick={() =>
                                                        removeImage(i, index)
                                                      }
                                                    />
                                                    {/* </div> */}
                                                  </Card>
                                                </>
                                              );
                                            })}
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                )}

                                <Col md={1}>
                                  <i
                                    style={{
                                      position: "absolute",
                                      right: "3px",
                                      cursor: "pointer",
                                    }}
                                    className="mdi mdi-close-thick font-size-18 text-danger"
                                    id="removeImgtooltip"
                                    onClick={() => removeAddon(i)}
                                  />
                                </Col>
                              </Row>
                            ))}
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="d-flex justify-content-end">
                        {!id ? (
                          <Button
                            color="primary"
                            type="submit"
                            className="w-md btn-sm me-4"
                            // onClick={saveChanges}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="w-md btn-sm me-4"
                            type="submit"
                            // onClick={editChanges}
                          >
                            Save Changes
                          </Button>
                        )}
                        <Button
                          color="light"
                          className="w-md btn-sm"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateProduct;
