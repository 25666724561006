import Breadcrumb from "components/Common/Breadcrumb";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import { MetaTags } from "react-meta-tags";
import {
  Card,
  CardBody,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
} from "reactstrap";
import { getSettlementsSummaryByStylistId } from "services/settlements.service";
import { Link } from "react-router-dom";
import noImage from "assets/images/PicUpload.jpg";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

const SettlementsSummaryStylist = () => {
  const [loading, setLoading] = useState(true);
  const [stylistList, setStylistList] = useState([]);
  useEffect(() => {
    getSettlementsSummaryByStylist();
  }, []);

  const getSettlementsSummaryByStylist = () => {
    setLoading(true);
    getSettlementsSummaryByStylistId()
      .then(res => {
        if (res?.success === true) {
          console.log(res, "resss");
          setStylistList(res?.data || []);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const individualList = () => {
    return stylistList?.map((el, index) => {
      return (
        <Col key={index} sm={6} md={6} xl={3} xxl={3}>
          {el?.due > 0 ? (
            <Link to={`/settlements/${el?._id}?access=individual`} className="">
              <Card>
                <CardBody>
                  <div className="d-flex">
                    <div className="me-3">
                      <img
                        className="rounded"
                        src={
                          !el?.stylist[0]?.image ||
                          el?.stylist[0]?.image === "undefined"
                            ? noImage
                            : el?.stylist[0]?.image
                        }
                        alt=""
                        width={80}
                        height={80}
                        role="button"
                      />
                    </div>
                    <div>
                      <h6 className="fw-bold mb-1 text-uppercase">
                        {el?.stylist[0]?.legalname} {el?.stylist[0]?.shortname}
                      </h6>
                      <span> Total Orders: </span>
                      <span className="fw-bold">{el?.totalOrders}</span>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between mt-2 align-items-center">
                    <div>
                      <span> Unsettled Orders: </span>
                      <span className="fw-bold"> {el?.unsettledOrders}</span>
                    </div>
                    <div className="text-center rounded pending_payment">
                      <span className="font-size-16 fw-bold">
                        ₹ {el?.due?.toFixed(2)}
                      </span>
                      <br />
                      <span className="font-size-10">Pending Payment</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Link>
          ) : (
            ""
          )}
        </Col>
      );
    });
  };

  return (
    <>
      <React.Fragment>
        <Spinner loading={loading} />
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb
              title="Settlements"
              breadcrumbItem="Settlements Summary"
            />
            <BreadcrumbSub
              subtile="Pending Settlemets by individual"
              SubBreadcrumbItem="Company"
            />
            <Row>
              <>{individualList()} </>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </>
  );
};

export default SettlementsSummaryStylist;
