import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import {
  deleteSubCategory,
  getCategoryRoot,
  getMainCategory,
  getSubCategory,
  getViewById,
} from "services/ecommerce/category.service";
import noImage from "assets/images/PicUpload.jpg";
import EcomCreateSub from "./ProductCategory/CreateSub";
import EcomCreateParent from "./ProductCategory/CreateParent";
import {
  getSubCategoryByParent,
  getParentCategoryByMain,
} from "services/ecommerce/category.service";
import SweetAlert from "react-bootstrap-sweetalert";
import { deleteParentCategory } from "services/ecommerce/category.service";

export const Ecomcategories = () => {
  const [mainCatList, setMainCatList] = useState([]);
  const [parentCategoryList, setParentCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [modal, setModal] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [modalSub, setModalSub] = useState(false);
  const [subId, setSubId] = useState();
  const [mainId, setMainId] = useState();
  const [activeSubId, setActiveSubId] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteParentId, setParentDeleteid] = useState(null);
  const [deletesubId, setSubDeleteid] = useState(null);
  const [activeId, setActiveId] = useState(false);
  const [list, setList] = useState();
  const [loading, setLoading] = useState();

  const toggleSub = () => {
    if (modalSub) {
      setActiveSubId(null);
    }
    setModalSub(!modalSub);
  };

  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };
  useEffect(() => {
    getMainCategories();
  }, []);

  const getMainCategories = () => {
    getCategoryRoot()
      .then(res => {
        let { data } = res;
        setMainCatList(data);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getSubById = id => {
    getSubCategoryByParent(id)
      .then(res => {
        setSubCategoryList(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getParentById = id => {
    getParentCategoryByMain(id)
      .then(res => {
        setParentCategoryList(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getCatViewById = id => {
    getViewById(id)
      .then(res => {
        setList(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const reloadSub = () => {
    getSubById(subId);
    getCatViewById(subId);
  };
  const reloadParent = () => {
    getParentById(mainId);
  };
  const handleClickSub = ele => {
    getCatViewById(ele._id);
  };
  //Delete//
  const removeParentCategory = id => {
    setLoading(true);
    deleteParentCategory(id)
      .then(res => {
        setDelete_modal(false);
        getMainCategories();
        reloadParent();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const removeCategory = id => {
    setLoading(true);
    deleteSubCategory(id)
      .then(res => {
        setDelete_modal(false);
        getSubCategory();
        reloadSub();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const onClickDeleteParent = id => {
    setParentDeleteid(id);
    setDelete_modal(true);
  };
  const onClickDeleteSub = id => {
    console.log(id, "id");
    setSubDeleteid(id);
    setDelete_modal(true);
  };
  const handleOnDelete = () => {
    removeParentCategory(deleteParentId);
    removeCategory(deletesubId);
  };
  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const renderMainCategories = () => {
    return (
      <>
        {mainCatList?.map((ele, index) => (
          <div
            className="d-flex"
            key={index}
            onClick={() => {
              setParentCategoryList(ele.docs);
              setSubCategoryList([]);
              setMainId(ele.id);
            }}
            style={{ cursor: "pointer" }}
          >
            <img
              src={ele.image}
              alt=""
              className="fit-content bg-light rounded-circle me-2 avatar-sm mt-3"
            />
            <h6 className="mt-4 ms-2">{ele.name}</h6>
          </div>
        ))}
      </>
    );
  };
  const renderParentCategories = () => {
    return (
      <>
        <div className="text-center">
          <button
            className="w-xl btn-md mx-1 bg-light border-0 rounded text-primary p-2 px= mb-3"
            onClick={toggle}
          >
            {" "}
            <i className="bx bx-user-plus font-size-16 align-middle me-2 "></i>
            Parent Category
          </button>
        </div>
        {parentCategoryList && parentCategoryList?.length === 0 && (
          <>
            <div className="empty_list p-3 pt-0">
              <div className="text-center">
                <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
              </div>
              <p className="text-center">Please Select Main Category</p>
            </div>
          </>
        )}
        {parentCategoryList.map((ele, index) => {
          return (
            <Card
              className="shadow-sm bg-white rounded"
              key={index}
              onClick={() => {
                setSubCategoryList(ele.subdoc);
                setSubId(ele._id);
              }}
              style={{ cursor: "pointer" }}
            >
              <img
                alt={ele.name}
                className="header_img"
                src={
                  ele?.image && ele.image?.includes("http")
                    ? ele.image
                    : noImage
                }
              />

              <div className="d-flex justify-content-between">
                <div className=" p-2 "> {ele.name}</div>
                <UncontrolledDropdown className="float-end" direction="down">
                  <DropdownToggle caret color="white">
                    <i className="bx bx-dots-vertical-rounded"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setActiveId(ele);
                        toggle();
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => onClickDeleteParent(ele._id)}
                      style={{ cursor: "pointer" }}
                    >
                      Delete
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </div>
            </Card>
          );
        })}
      </>
    );
  };
  const renderSubCategories = () => {
    return (
      <>
        <Row className="">
          <div>
            <button
              className="w-xl btn-md mx-1 bg-light border-0 rounded text-primary p-2 float-end mb-3"
              onClick={toggleSub}
            >
              {" "}
              <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
              Sub Category
            </button>
          </div>
          {subCategoryList && subCategoryList?.length === 0 && (
            <>
              <div className="empty_list p-3 pt-0">
                <div className="text-center">
                  <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
                </div>
                <p className="text-center">Please Select Parent Category</p>
              </div>
            </>
          )}
          {subCategoryList?.map((ele, index) => {
            return (
              <div
                className="col-md-3 mb-3"
                key={index}
                onClick={() => handleClickSub(ele)}
              >
                <Card
                  className="shadow-sm bg-white rounded border border-1"
                  key={index}
                >
                  <img
                    alt={ele.name}
                    className="header_img"
                    src={
                      ele?.image && ele.image?.includes("http")
                        ? ele.image
                        : noImage
                    }
                    height="200px"
                  />
                  <div className="d-flex justify-content-between">
                    <div className=" text-center p-2"> {ele.name}</div>
                    <UncontrolledDropdown
                      className="float-end"
                      direction="down"
                    >
                      <DropdownToggle caret color="white">
                        <i className="bx bx-dots-vertical-rounded"></i>
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem
                          onClick={() => {
                            setActiveSubId(list);
                            toggleSub();
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => onClickDeleteSub(ele._id)}
                          style={{ cursor: "pointer" }}
                        >
                          Delete
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Card>
              </div>
            );
          })}
        </Row>
      </>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Category | stylepro</title>
        </MetaTags>

        <Container fluid id="service_list">
          <Breadcrumbs title=" Category" breadcrumbItem="Category List" />
          <Row>
            <Col className="tab_4 px-0 me-2">
              <Card className="">
                <CardBody className="px-2">
                  <div className="mb-3 text-center">
                    {renderMainCategories()}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col md="2" className="  px-0 me-2">
              {" "}
              <Card className="">
                <CardBody className="px-3">
                  <div className="mb-3">{renderParentCategories()}</div>
                </CardBody>
              </Card>
            </Col>
            <Col className="tab_3 px-0 me-1">
              <Card className="bg-white">
                <CardBody>
                  {" "}
                  <div className="mb-3">{renderSubCategories()}</div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        {modal ? (
          <EcomCreateParent
            toggle={toggle}
            modal={modal}
            editObj={activeId}
            reloadService={reloadParent}
          />
        ) : null}
        {delete_modal ? deletePopup() : null}
        {modalSub && (
          <EcomCreateSub
            toggle={toggleSub}
            modal={modalSub}
            editObj={activeSubId}
            reloadService={reloadSub}
          />
        )}
      </div>
    </React.Fragment>
  );
};
export default Ecomcategories;
