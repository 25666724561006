import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import avatar from "assets/images/avatar.jpg";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Spinner from "components/spinner";

const defaultValues = {
  artistInfo: [],
};

const EditArtistInfo = ({ artistValues, handleSave, handleCancel }) => {
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (artistValues) {
      let { _id, artistInfo, readMore } = artistValues[0];

      let obj = {
        _id,
        artistInfo,
        readMore,
      };

      setValues({ ...obj });
    }
    setLoading(false);
  }, [artistValues]);

  //   const handleChange = e => {
  //     let { name, value } = e.target;
  //     let obj = { ...values };

  //     switch (name) {
  //       case "readMore":
  //         obj.readMore = value;
  //         break;

  //       default:
  //         break;
  //     }

  //     setValues({ ...obj });
  //   };

  const handleChange = data => {
    let obj = { ...values };

    obj.readMore = data;

    setValues({ ...obj });
  };

  const addArrayElement = () => {
    let obj = { ...values };

    obj.artistInfo = [...obj.artistInfo, { name: "", value: "", order: 0 }];

    setValues({ ...obj });
  };

  const deleteArrayElement = i => {
    let obj = { ...values };

    obj.artistInfo.splice(i);

    setValues({ ...obj });
  };

  const handleArrayChange = (e, i) => {
    let { name, value } = e.target;
    let obj = { ...values };

    switch (name) {
      case "name":
        obj.artistInfo[i].name = value;
        break;
      case "value":
        obj.artistInfo[i].value = value;
        break;

      default:
        break;
    }

    setValues({ ...obj });
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="m-3 services_basic_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>ARTIST INFO</h6>
            <div className="d-flex ">
              <Link
                className="btn btn-sm btn-primary btn-bg-primary me-3 "
                to="#"
                onClick={() => handleSave(values, "artistInfo")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Save</span>
              </Link>
              <Link
                className="btn btn-sm btn-danger btn-bg-danger me-3 "
                to="#"
                onClick={() => handleCancel("artistInfo")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Cancel</span>
              </Link>
            </div>
          </div>
        </Row>
        <Row className="m-0">
          <Col md={12} className="p-0">
            <Card>
              <CardBody>
                <Row className="m-0">
                  <Col md={12} className="mb-1 px-0 view_detail">
                    {values?.artistInfo?.map((ele, index) => {
                      return (
                        <Row className="m-0 mb-2" key={index}>
                          <Col md={4} className="mt-1">
                            <input
                              className="form-control text-muted"
                              type="text"
                              name="name"
                              value={ele?.name}
                              onChange={e => handleArrayChange(e, index)}
                            />
                          </Col>
                          <Col md={7} className="mt-1">
                            <input
                              className="form-control text-muted"
                              type="text"
                              name="value"
                              value={ele?.value}
                              onChange={e => handleArrayChange(e, index)}
                            />
                          </Col>
                          <Col
                            md={1}
                            className="mt-1"
                            style={{ paddingLeft: "0px" }}
                          >
                            <Link
                              className="fw-bold mb-1 btn btn-sm btn-primary btn-bg-primary "
                              to="#"
                              onClick={() => deleteArrayElement(index)}
                            >
                              <span className="py-3">Delete</span>
                            </Link>
                          </Col>
                        </Row>
                      );
                    })}

                    <Row className="m-0 mb-2">
                      <Col md={12} className="mt-1">
                        <Link
                          className="btn btn-sm btn-primary btn-bg-primary me-3 "
                          to="#"
                          onClick={() => addArrayElement()}
                        >
                          <i className="bx bx-plus pe-1"> </i>
                          <span className="py-3">Add Service</span>
                        </Link>
                      </Col>
                    </Row>

                    <Row className="m-0 mb-2">
                      <Col md={12} className="mt-1">
                        <p className="fw-bold mb-1">More Information</p>
                      </Col>
                      <Col md={12} className="mt-1">
                        <CKEditor
                          editor={ClassicEditor}
                          id="readMore"
                          data={values?.readMore}
                          onReady={editor => {
                            console.log("Editor is ready to use!", editor);
                            if (values?.readMore)
                              editor.data.set(values?.readMore);
                          }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            handleChange(data);
                          }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default EditArtistInfo;
