import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import "./Styles.scss";
import moment from "moment";
import ArtistOrderlist from "./Orderlist";
import { getArtistQuoteById } from "services/quote.service";
import ArtistQuoteView from "./View";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const OrdersList = () => {
  const [activeOrder, setActiveOrder] = useState(null);
  const handleActiveOrder = order => {
    getArtistFormById(order?._id);
  };
  const getArtistFormById = id => {
    getArtistQuoteById(id)
      .then(res => {
        if (res?.success === true) {
          setActiveOrder(res?.data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Orders" breadcrumbItem="Artist Orders List" />
          <BreadcrumbSub
            subtile="Artist quotes"
            SubBreadcrumbItem="Makeup orders"
          />

          <Row className="mt-3">
            <Col md="4">
              <ArtistOrderlist handleActiveOrder={handleActiveOrder} />
            </Col>
            <Col md="8">
              <ArtistQuoteView activeOrder={activeOrder} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default OrdersList;
