export const getPackages=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/packages/all`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get package ${e}`))  
  }
  export const getPackage=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/packages/package/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get package ${e}`))  
  }
  export const deletePackage=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/packages/package/${id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get package ${e}`))  
  }
  export const createPackage = body => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/packages/add`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at package create ${e}`));
  };
  export const updatePackage =(id,body) => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/packages/package/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at package update ${e}`));
  };

  export const updatePackageStatus=(id,body)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/packages/status/${id}`, {
        method: "PUT",
        headers: {
          Accept: "application/json",
        },
        body: body,
      })
        .then(res => {
          return res.json();
        })
        .catch(e => console.log(`error at package update ${e}`)); 
  }
