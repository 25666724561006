import StarRating from "components/Ratings/StarRating";
import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getQuestions } from "services/ratingQuestion.service";

const Rating = ({ value }) => {
  if (!value || !value?.totalIndvidualRating) {
    return "";
  }
  const [IndQuestions, setIndQuestions] = useState([]);
  const [AppQuestions, setAppQuestions] = useState([]);
  useEffect(() => {
    getServiceQuestions();
  }, []);

  const filterquestions = data => {
    const getIndQns = data?.filter(
      ele => ele?.type === "indvidual" && ele?.isText === false
    );
    const getAppQns = data?.filter(
      ele => ele?.type === "app" && ele?.isText === false
    );
    setIndQuestions(getIndQns);
    setAppQuestions(getAppQns);
  };
  const getServiceQuestions = () => {
    getQuestions()
      .then(res => {
        filterquestions(res?.data);
      })
      .catch(error => {
        console.log(error, "error");
      });
  };
  return (
    <Card className=" ">
      <h5 className="p-2 mt-2 ms-2">Rating & Review</h5>
      <CardBody className="border-top border-3 pt-1">
        <Row>
          <Col md={12}>
            {/* <div className="d-flex align-items-center">
              <h6 className="me-2 mb-0">Stylist Rating :</h6>
              <div className="d-flex align-items-center">
                <StarRating
                  totalStars={5}
                  rating={value?.totalIndvidualRating?.toFixed(1)}
                />
                <h6 className="fw-bolder ms-2 mb-0">
                  {value?.totalIndvidualRating?.toFixed(1)}
                </h6>
              </div>
            </div>
          </Col>
          <Col md={12}>
            <div className="d-flex align-items-center mb-3">
              <h6 className="me-2 mb-0">App Rating :</h6>
              <div className="d-flex align-items-center">
                <StarRating
                  totalStars={5}
                  rating={value?.totalAppRating?.toFixed(1) || 0}
                />
                <h6 className="fw-bolder ms-2 mb-0">
                  {value?.totalAppRating?.toFixed(1) || 0}
                </h6>
              </div>
            </div> */}
            <h4 className="mt-2 mb-3">
              <u>Stylist Rating </u>
            </h4>

            {IndQuestions?.map((ele, index) => {
              const getRating = value?.indvidualRating?.filter(
                qns => qns?.questionID === ele?._id
              );
              return (
                <div key={index} className="">
                  <div className="d-flex align-items-center">
                    <h6 className="mb-0 me-3">{ele?.name}</h6>
                    <div className="d-flex align-items-center">
                      <StarRating
                        totalStars={5}
                        rating={getRating[0]?.rating?.toFixed(1) || 0}
                      />
                      <h6 className="fw-bolder ms-2 mb-0">
                        {getRating[0]?.rating?.toFixed(1) || 0}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
          <Col md={12}>
            <h4 className="mt-2 mb-3">
              <u>App Rating </u>
            </h4>

            {AppQuestions?.map((ele, index) => {
              const getRating = value?.appRating?.filter(
                qns => qns?.questionID === ele?._id
              );
              return (
                <div key={index} className="">
                  <div className="d-flex align-items-center">
                    <h6 className="mb-0 me-3">{ele?.name}</h6>
                    <div className="d-flex align-items-center">
                      <StarRating
                        totalStars={5}
                        rating={getRating[0]?.rating?.toFixed(1) || 0}
                      />
                      <h6 className="fw-bolder ms-2 mb-0">
                        {getRating[0]?.rating?.toFixed(1) || 0}
                      </h6>
                    </div>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
        {value?.comments && value?.comments != "" ? (
          <div className="mt-3">
            <h4 className="">
              <u>Review</u>
            </h4>
            <div className="border border-1 p-3">{value?.comments}</div>
          </div>
        ) : (
          <div className="mt-3">
            <h4 className="">
              <u>Review</u>
            </h4>
            <div className="border border-1 p-3">
              {value?.appRating[2]?.value
                ? value?.appRating[2]?.value
                : "No comments available"}
            </div>
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default Rating;
