import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Container, Card, Row, Col, CardBody, CardTitle } from "reactstrap";
import { getBrand } from "services/ecommerce/brand.service";
import {
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Select from "react-select";
import ProfileImage from "../../../components/profileImage";
import { useRef, useState, useEffect } from "react";
import { UncontrolledCollapse } from "reactstrap";
import { Formik, Form } from "formik";
import { createBrand, updateBrand } from "services/ecommerce/brand.service";
import Spinner from "components/spinner";
import ErrorAlert from "components/Alerts/error";
import SuccessAlert from "components/Alerts/succes";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import Dropzone from "react-dropzone";
import Breadcrumb from "../../../components/Common/Breadcrumb";
import _, { valuesIn } from "lodash";
import BrandFilters from "./filters";
const defaultValues = {
  logo: "",
  isFeatured: "",
  name: "",
  order: "",
  description: "",
  website: "",
  status: "active",
  isServiceBrand: false,
  banners: [
    {
      image: "",
      status: "active",
    },
  ],
};
const defaultBannerValues = {
  image: "",
  status: "active",
};

export const BrandCreate = ({ toggle, modal, id = false, reload }) => {
  const [selectedType, setSelectedType] = useState(null);
  const initialValues = defaultValues;
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [isServiceBrand, setIServiceBrand] = useState(false);
  const [bannerList, setBannerList] = useState([
    {
      image: "",
      status: "active",
    },
  ]);

  const typeList = [
    { label: "Active", value: "active" },
    { label: "Inactive", value: "inactive" },
  ];

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    order: Yup.number().required("Please Enter order").positive().integer(),
    status: Yup.object().required("Please select Status"),
  });

  const { image } = values;

  function handleSelectType(event) {
    setValues({ ...values, ["type"]: event.value });
    setSelectedType(event);
  }

  useEffect(() => {
    if (id) {
      setBannerList(values?.banners);
    } else setBannerList(defaultValues?.banners);
  }, [id]);

  function handleSelectStatus(event, index) {
    let newValues = _.cloneDeep(bannerList);
    newValues[index]["status"] = event.value;
    setBannerList(newValues);
  }

  const handleBannerImage = (file, index) => {
    let newValues = _.cloneDeep(bannerList);
    newValues[index]["image"] = file;
    setBannerList(newValues);
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleInputChange = (fieldName, value) => {
    let obj = { ...values };
    obj[fieldName] = value;
    setValues({ ...obj });
  };

  const saveChanges = fieldValues => {
    setLoading(true);
    let formData = new FormData();
    fieldValues["banners"] = bannerList;
    const values = { ...fieldValues };
    for (let key in values) {
      if (key === "banners") {
        let bannerArr = values?.banners?.map((ele, idx) => {
          if (ele?.image && typeof ele?.image == "object") {
            formData.set("banner" + idx, ele?.image);
          }
          return ele;
        });

        formData.set(
          key,
          values[key] ? JSON.stringify(bannerArr) : JSON.stringify([])
        );
      } else {
        formData.set(key, values[key]);
      }
    }
    createBrand(formData)
      .then(res => {
        if (res?.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = fieldValues => {
    console.log(fieldValues, "fieldvalues");
    setLoading(true);
    let formData = new FormData();
    fieldValues["banners"] = bannerList;
    fieldValues.isServiceBrand = isServiceBrand;
    const values = { ...fieldValues };
    for (let key in values) {
      if (key === "banners") {
        let bannerArr = values?.banners?.map((ele, idx) => {
          if (ele?.image && typeof ele?.image == "object") {
            formData.set("banner" + idx, ele?.image);
          }
          return ele;
        });

        formData.set(
          key,
          values[key] ? JSON.stringify(bannerArr) : JSON.stringify([])
        );
      } else {
        formData.set(key, values[key]);
      }
    }

    updateBrand(id, formData)
      .then(res => {
        if (res?.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const addBannerFields = () => {
    let newValues = _.cloneDeep(bannerList);
    newValues.push(defaultBannerValues);
    setBannerList(newValues);
  };

  const removeBannerFields = index => {
    let newValues = _.cloneDeep(bannerList);
    newValues.splice(index, 1);
    setBannerList(newValues);
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  useEffect(() => {
    getBrand(id)
      .then(res => {
        const data = { ...res.data };
        setIServiceBrand(data?.isServiceBrand);
      })
      .catch(e => {
        console.log(e);
      });
  }, [id]);

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {id ? "Edit Brand" : "Create Brand"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <Spinner loading={loading} />
            <div>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  values,
                }) => {
                  useEffect(() => {
                    if (id) {
                      setLoading(true);
                      getBrand(id)
                        .then(res => {
                          const data = { ...res.data };
                          const fields = [
                            "logo",
                            "isFeatured",
                            "name",
                            "order",
                            "description",
                            "website",
                            "status",
                            "banners",
                            "IsServiceBrand",
                          ];
                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          setFieldValue("status", data.status, false);
                          setIServiceBrand(data?.isServiceBrand);
                          setSelectedType({
                            value: data.status,
                            label:
                              data.status === "active" ? "Active" : "Inactive",
                          });
                          setBannerList(data?.banners);
                          setLoading(false);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }
                  }, []);
                  return (
                    <Form>
                      <Row>
                        <Col md="9">
                          <Card>
                            <CardBody>
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">Name</Label>
                                <Input
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  onChange={e => {
                                    handleChange("name")(e.target.value);
                                  }}
                                  value={values?.name}
                                  invalid={
                                    touched.name && errors.name ? true : false
                                  }
                                />

                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                    {touched.name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>

                              <div className="">
                                <h6>Description</h6>
                                <div className="mb-2">
                                  <button
                                    className="btn btn-primary btn-md me-2"
                                    id="toggler"
                                  >
                                    Show/Hide Editor
                                  </button>
                                </div>
                              </div>
                              <div className="mb-3">
                                <UncontrolledCollapse
                                  toggler="#toggler"
                                  defaultOpen={true}
                                >
                                  <Card style={{ border: "0px" }}>
                                    <CardBody className="p-0">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={values.description}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          handleChange("description")(data);
                                        }}
                                      />
                                    </CardBody>
                                  </Card>
                                </UncontrolledCollapse>
                              </div>
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Order
                                </Label>
                                <Input
                                  name="order"
                                  placeholder="Order"
                                  type="number"
                                  className="form-control"
                                  id="order"
                                  onChange={e => {
                                    handleChange("order")(e.target.value);
                                  }}
                                  value={values?.order}
                                  invalid={
                                    touched.order && errors.order ? true : false
                                  }
                                />

                                {touched.order && errors.order ? (
                                  <FormFeedback type="invalid">
                                    {errors.order}
                                    {touched.order}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </CardBody>
                          </Card>
                          <Row>
                            <Col>
                              <Label htmlFor="validationCustom01">
                                Banner Images
                              </Label>
                              <Button
                                className="float-end mb-2"
                                color="primary"
                                size="sm"
                                onClick={e => addBannerFields()}
                              >
                                Add Banner
                              </Button>
                            </Col>
                          </Row>
                          <Card>
                            <CardBody>
                              {bannerList?.map((ele, ind) => {
                                return (
                                  <Row key={ind} className="my-3">
                                    <Col md={1}>
                                      <div className="h6 bg-light p-2 text-center rounded">
                                        {ind + 1}
                                      </div>
                                    </Col>
                                    <Col md={6}>
                                      <Dropzone
                                        id="image"
                                        onDrop={acceptedFiles => {
                                          acceptedFiles.map(file =>
                                            Object.assign(file, {
                                              preview:
                                                URL.createObjectURL(file),
                                              formattedSize: formatBytes(
                                                file.size
                                              ),
                                            })
                                          );
                                          handleBannerImage(
                                            acceptedFiles[0],
                                            ind
                                          );
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone image_drop">
                                            <div
                                              className="dz-message needsclick "
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="">
                                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                                              </div>
                                              <h6>
                                                Drop files here or click to
                                                upload.
                                              </h6>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                      <div className="my-2">
                                        <Row className="align-items-center ">
                                          <Col className="col-auto ">
                                            <ProfileImage
                                              image={ele?.image}
                                              height="40"
                                              width="40"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {ele?.image?.path ||
                                                ele?.image?.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {ele?.image?.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Col>
                                    <Col>
                                      <div>
                                        <Label>Status</Label>
                                        <FormGroup className="mb-3">
                                          <Select
                                            id="bannerStatus"
                                            value={{
                                              label:
                                                ele?.status === "active"
                                                  ? "Active"
                                                  : "Inactive",
                                              value: ele?.status,
                                            }}
                                            onChange={event => {
                                              handleSelectStatus(event, ind);
                                            }}
                                            name="bannerStatus"
                                            options={typeList}
                                            classNamePrefix="select2-selection"
                                          />{" "}
                                        </FormGroup>
                                      </div>
                                    </Col>{" "}
                                    <Col md={1}>
                                      <Button
                                        color="danger"
                                        className="btn-sm  mt-4 "
                                        onClick={() => removeBannerFields(ind)}
                                      >
                                        <i className="bx bx-trash-alt fs-5"></i>
                                      </Button>
                                    </Col>
                                  </Row>
                                );
                              })}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md="3">
                          <Card>
                            <CardTitle className="p-2 border border-bottom-3">
                              Brand Status{" "}
                              <span className="text-danger">*</span>
                            </CardTitle>
                            <CardBody>
                              <FormGroup className="mb-3">
                                <Select
                                  id="Status"
                                  value={selectedType}
                                  onChange={event => {
                                    handleSelectType(event);
                                    handleChange("status")(event.value);
                                  }}
                                  name="status"
                                  options={typeList}
                                  classNamePrefix="select2-selection"
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardTitle className="p-2 border border-bottom-3">
                              Logo
                            </CardTitle>
                            <CardBody>
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom06">
                                  Image
                                </Label>
                                <Dropzone
                                  id="validationCustom06"
                                  onDrop={acceptedFiles => {
                                    acceptedFiles.map(file =>
                                      Object.assign(file, {
                                        preview: URL.createObjectURL(file),
                                        formattedSize: formatBytes(file.size),
                                      })
                                    );
                                    setFieldValue("logo", acceptedFiles[0]);
                                  }}
                                >
                                  {({ getRootProps, getInputProps }) => (
                                    <div className="dropzone image_drop">
                                      <div
                                        className="dz-message needsclick "
                                        {...getRootProps()}
                                      >
                                        <input {...getInputProps()} />
                                        <div className="">
                                          <i className="display-6 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h6>
                                          Drop files here or click to upload.
                                        </h6>
                                      </div>
                                    </div>
                                  )}
                                </Dropzone>
                                <Label className="statusValidation">
                                  {" "}
                                  {errors.logo ? errors.logo : ""}
                                </Label>
                                <div
                                  className="dropzone-previews mt-3"
                                  id="file-previews"
                                >
                                  {values.logo &&
                                    Object.keys(values.logo).length > 0 && (
                                      <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={values?.logo}
                                                height="80"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                            <Col>
                                              <Link
                                                to="#"
                                                className="text-muted font-weight-bold"
                                              >
                                                {values.logo.name ||
                                                  values.logo.filename}
                                              </Link>
                                              <p className="mb-0">
                                                <strong>
                                                  {values.logo.formattedSize}
                                                </strong>
                                              </p>
                                            </Col>
                                          </Row>
                                        </div>
                                      </Card>
                                    )}
                                </div>
                              </FormGroup>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardTitle className="p-2 border border-bottom-3">
                              Is featured
                            </CardTitle>
                            <CardBody>
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  role="switch"
                                  defaultChecked={values?.isFeatured}
                                  onChange={event =>
                                    handleChange(
                                      "isFeatured",
                                      event.target.checked
                                    )
                                  }
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                          <Card>
                            <CardTitle className="p-2 border border-bottom-3">
                              Is Service Brand Only
                            </CardTitle>
                            <CardBody>
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  role="switch"
                                  defaultChecked={isServiceBrand}
                                  onClick={event => {
                                    console.log(
                                      event.target.checked,
                                      "updated value"
                                    );
                                    // handleChange(
                                    //   "isServiceBrand",
                                    //   event.target.checked
                                    // );
                                    setIServiceBrand(event.target.checked);
                                  }}
                                />
                              </FormGroup>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col md={12}>
                          <BrandFilters
                            brandId={id}
                            setsuccess_msg={setsuccess_msg}
                            seterror_msg={seterror_msg}
                            leaveToList={leaveToList}
                          />
                        </Col>
                      </Row>
                      <div className="d-flex justify-content-end">
                        <Button
                          color="light"
                          className="w-md btn-sm me-2"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                        {!id ? (
                          <Button
                            color="primary"
                            type="submit"
                            className="w-md btn-sm "
                            onClick={() => saveChanges(values)}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="w-md btn-sm "
                            type="submit"
                            onClick={() => editChanges(values)}
                          >
                            Save Changes
                          </Button>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </Container>
          {success_msg ? (
            <SuccessAlert
              msg={"Successfully Brand created"}
              leaveToList={leaveToList}
              stayHere={stayHere}
            />
          ) : null}
          {error_msg ? (
            <ErrorAlert
              msg={"error"}
              leaveToList={leaveToList}
              stayHere={stayHere}
            />
          ) : null}
        </ModalBody>
      </Modal>
    </>
  );
};
export default BrandCreate;
