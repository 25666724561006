import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button, FormGroup } from "reactstrap";
import Select from "react-select";
import { getBrands } from "services/ecommerce/brand.service";
import {
  CreateServiceProduct,
  updatefeildService,
} from "services/services.service";
import { getCategories } from "services/category.service";
import { getServiceProducts } from "services/product.service";

const ServiceProductsinfo = ({ toggle, initValues, reloadService }) => {
  // const [values, setValues] = useState();
  const [brandsList, setBrandslist] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [ProductList, setProductList] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedGender, setSelectedGender] = useState(null);
  const [selectedProduct, setSelectedProduct] = useState(null);
  let serviveId = initValues?._id;
  const parentCategory = initValues?.parentCategory;
  useEffect(() => {
    getBrandsList();
    getAllCategories();
    getAllServiceProducts();
  }, []);

  const GenderList = [
    { label: "Male", value: "M" },
    { label: "Female", value: "F" },
  ];

  const getBrandsList = () => {
    getBrands()
      .then(res => {
        if (res?.data) {
          const result = buildOptions(res?.data);
          setBrandslist(result);
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getAllCategories = () => {
    // let obj = { parentCategory: data }
    getCategories()
      .then(res => {
        if (res) {
          const getCatByparentID = res?.filter(
            ele =>
              ele?.parentCategory?._id === parentCategory?._id &&
              ele?.status === "Active"
          );
          const result = buildOptions(getCatByparentID);
          setCategoryList(result);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  function filterSelectedProducts(selectedProducts, products) {
    const productValues = products.map(product => product?.product?._id);
    const filteredSelectedProducts = selectedProducts.filter(
      selectedProduct => !productValues.includes(selectedProduct.value)
    );
    return filteredSelectedProducts;
  }

  const getAllServiceProducts = () => {
    getServiceProducts()
      .then(res => {
        console.log(res, "ress");
        if (res) {
          const result = buildOptionsProducts(res?.data);
          if (initValues?.serviceProducts?.length) {
            const filteredSelectedProducts = filterSelectedProducts(
              result,
              initValues?.serviceProducts
            );
            setProductList(filteredSelectedProducts);
          } else {
            setProductList(result);
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  //Build {label,value}array
  const buildOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele._id,
      };
      return obj;
    });
    return result;
  };
  const buildOptionsProducts = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele._id,
        mainCategory: ele?.mainCategory,
        parentCategory: ele?.parentCategory,
      };
      return obj;
    });
    return result;
  };
  function handleBrands(selectedMulti) {
    setSelectedBrands(selectedMulti);
  }

  function handleCategory(selectedMulti) {
    setSelectedCategory(selectedMulti);
  }

  function handleGender(selectedMulti) {
    setSelectedGender(selectedMulti);
  }
  function handleselectProduct(selectedMulti) {
    setSelectedProduct(selectedMulti);
  }
  const onSubmit = () => {
    // let body = { ...values };
    let body = {
      id: serviveId,
      product: {
        brand: selectedBrands?.value,
        mainCategory: selectedProduct?.mainCategory,
        parentCategory: selectedProduct?.parentCategory,
        product: selectedProduct?.value,
        gender: selectedGender.value,
        // generalPricePercentage: 20,
      },
    };
    CreateServiceProduct(body)
      .then(res => {
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };
  return (
    <>
      <Row className="mt-3 mx-0">
        <Col md={4} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="brands">Brand</Label>
            <Select
              id="brands"
              value={selectedBrands}
              isMulti={false}
              onChange={event => {
                handleBrands(event);
              }}
              options={brandsList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
        <Col md={4} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="category">Category</Label>
            <Select
              id="category"
              value={selectedCategory}
              isMulti={false}
              onChange={event => {
                handleCategory(event);
              }}
              options={CategoryList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
        <Col md={4} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="gender">Gender</Label>
            <Select
              id="gender"
              value={selectedGender}
              onChange={event => {
                handleGender(event);
              }}
              options={GenderList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
        <Col md={6} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="products">Products</Label>
            <Select
              id="products"
              value={selectedProduct}
              isMulti={false}
              onChange={event => {
                handleselectProduct(event);
              }}
              options={ProductList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Add Service Product
        </Button>
      </div>
    </>
  );
};

export default ServiceProductsinfo;
