import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  List,
  UncontrolledAccordion,
} from "reactstrap";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { deleteService } from "services/services.service";
import { getParentCategories } from "services/parentcategory.service";
import { getCategoryTypeandParent } from "services/category.service";
import { getServicesByQuery, getServicesById } from "services/services.service";

import Spinner from "components/spinner";
import ServiceTable from "./serviceTable";
import MyServiceTable from "./myServiceTable";

const ServiceInfo = ({ expInitialValues, handleSubmit }) => {
  const [parentCatList, setParentCatList] = useState([]);
  const [activeParentCatInd, setActiveParentInd] = useState(null);
  const [subCatList, setSubCatList] = useState(null);
  const [activeSubCatInd, setActiveSubCatInd] = useState(null);
  const [activeSubCat, setActiveSubCat] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [activeServiceInd, setActiveServiceInd] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getParentCatList();
  }, []);

  const getParentCatList = () => {
    getParentCategories()
      .then(res => {
        let { data } = res;
        setParentCatList(data);

        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getCataegories = body => {
    getCategoryTypeandParent(body)
      .then(res => {
        if (res.data) {
          setSubCatList(res.data);
        }
        setServiceList(null);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getServicesList = body => {
    getServicesByQuery(body)
      .then(res => {
        if (res.data) {
          setServiceList([...res.data]);
        }
        setLoading(false);
        setActiveServiceInd(null);
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  const handleParentCat = (ele, index) => {
    setActiveParentInd(index);
    setActiveSubCatInd(null);
    setActiveServiceInd(null);
    const body = {
      type: ele.type,
      parentCategory: ele._id,
    };
    getCataegories(body);
  };

  const handleSubCat = (ele, index) => {
    setActiveSubCatInd(index);
    setActiveSubCat(subCatList[index]);
    getServicesList({ categories: ele._id, type: ele.type });
  };

  const reloadServicesList = () => {
    const selectedCategory = subCatList[activeSubCatInd];
    getServicesList({
      categories: selectedCategory._id,
      type: selectedCategory.type,
    });
  };

  const renderParentCatList = type => {
    return (
      <List type="unstyled" className="service-ul">
        {parentCatList.map((ele, indx) => {
          if (ele.type === type)
            return (
              <li
                key={indx}
                className={
                  activeParentCatInd === indx ? "ps-3 py-1 active" : "ps-3 py-1"
                }
                onClick={() => {
                  handleParentCat(ele, indx);
                }}
              >
                {ele.name}
              </li>
            );
        })}
      </List>
    );
  };

  const renderSubCatList = () => {
    return (
      <Card className="border-dark">
        <CardBody className="p-0">
          {!subCatList && (
            <div className="empty_list p-3 pt-0">
              <div className="text-center">
                <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
              </div>
              <p className="text-center">Please Select Parent Category</p>
            </div>
          )}
          {subCatList && subCatList.length === 0 && (
            <div className="empty_list p-3 pt-0">
              <div className="text-center">
                <i className="mdi mdi-circle-off-outline" id="EmptyCatList"></i>
              </div>
              <h4>Empty List</h4>
              <p className="text-center">No Categories Found</p>
            </div>
          )}

          {subCatList &&
            subCatList.map((ele, indx) => {
              return (
                <Card
                  key={indx}
                  className={
                    activeSubCatInd === indx
                      ? "mx-3 my-4 border-light active"
                      : "mx-3 my-4 border-light"
                  }
                  onClick={() => {
                    handleSubCat(ele, indx);
                  }}
                >
                  <img alt={ele.name} className="header_img" src={ele.image} />
                  <CardBody className="p-2">
                    <div className="card_title"> {ele.name}</div>
                    <div
                      className="mt-1 card_tagline"
                      dangerouslySetInnerHTML={{ __html: ele.tagline }}
                    />
                  </CardBody>
                </Card>
              );
            })}
        </CardBody>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <div className="">
        <Spinner loading={loading} />
        <Container fluid id="service_list" className="mt-3 px-0">
          <Row>
            <Col>
              <MyServiceTable
                serviceList={serviceList}
                expInitialValues={expInitialValues}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-between mb-2">
            <h5 className="text-primary mt-3">
              AVAILABLE SERVICE (Add/Edit services)
            </h5>
          </div>
          <Row>
            <Col className="tab_1 px-0 me-2">
              <Card className="border-light">
                <CardBody className="px-3">
                  <div className="custom_accordion">
                    <UncontrolledAccordion defaultOpen={["1", "2"]} stayOpen>
                      <AccordionItem className="border-0">
                        <AccordionHeader targetId="1">
                          {" "}
                          <i className="mdi mdi-folder font-size-16 text-warning me-2">
                            {" "}
                          </i>{" "}
                          Women
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          {renderParentCatList("Female")}
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem className="border-0">
                        <AccordionHeader targetId="2">
                          {" "}
                          <i className="mdi mdi-folder font-size-16 text-warning me-2">
                            {" "}
                          </i>{" "}
                          Men
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                          {" "}
                          {renderParentCatList("Male")}
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem className="border-0">
                        <AccordionHeader targetId="3">
                          {" "}
                          <i className="mdi mdi-folder font-size-16 text-warning me-2">
                            {" "}
                          </i>{" "}
                          Kids
                        </AccordionHeader>
                        <AccordionBody accordionId="3">
                          {" "}
                          {renderParentCatList("Kids")}
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className=" tab_2 px-0 me-2">{renderSubCatList()}</Col>
            <Col className="tab_3">
              <div>
                {activeSubCatInd !== null &&
                  subCatList &&
                  subCatList.length > 0 && (
                    <div className="service_title">
                      {subCatList[activeSubCatInd]["name"]}
                    </div>
                  )}

                <div className="">
                  {!serviceList && (
                    <Card className="border-light">
                      <div className="empty_list p-3 pt-0">
                        <div className="text-center">
                          <i
                            className="mdi mdi-arrow-left-circle"
                            id="nullCatList"
                          ></i>
                        </div>
                        <p className="text-center">
                          Please Select Sub Category
                        </p>
                      </div>
                    </Card>
                  )}
                  {serviceList && serviceList.length === 0 && (
                    <Card className="border-light">
                      <div className="empty_list p-3 pt-0">
                        <div className="text-center">
                          <i
                            className="mdi mdi-circle-off-outline"
                            id="EmptyCatList"
                          ></i>
                        </div>
                        <h4>Empty List</h4>
                        <p className="text-center">No Services Found</p>
                      </div>
                    </Card>
                  )}
                  {serviceList && serviceList.length > 0 && (
                    <ServiceTable
                      serviceList={serviceList}
                      expInitialValues={expInitialValues}
                      handleSubmit={handleSubmit}
                      subCatId={activeSubCat._id}
                    />
                  )}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ServiceInfo;
