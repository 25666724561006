import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";

import Select from "react-select";

const BasicInfo = ({ basicInitialValues, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [basicInfo, setBasicInfo] = useState(basicInitialValues);

  useEffect(() => {
    setBasicInfo(basicInitialValues);

    setIsEdit(false);
  }, [basicInitialValues]);

  const toggleEdit = () => {
    setBasicInfo(basicInitialValues);
    setIsEdit(!isEdit);
  };

  const handleChange = (value, name) => {
    let info = { ...basicInfo };
    info[name] = value;
    setBasicInfo(info);
  };

  const save = () => {
    // console.log(basicInfo);
    handleSubmit(basicInfo);
  };

  return (
    <Card className="border-light">
      <CardBody>
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h5>BASIC INFO</h5>
            {!isEdit && (
              <Link
                className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
                to="#"
                onClick={() => toggleEdit()}
              >
                <i className="bx bx-edit-alt bx-xs pe-1"> </i>
                <span> Edit</span>
              </Link>
            )}
          </div>
        </Row>
        <Row>
          <Col md={12}>
            <Row>
              <Col sm={3} className="mt-2">
                <Label htmlFor="companyName" className="col-form-label">
                  Company Name
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="companyName"
                  placeholder="Enter Your Company Name"
                  name="companyName"
                  value={basicInfo.companyName}
                  onChange={event =>
                    handleChange(event.target.value, "companyName")
                  }
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="address" className="col-form-label">
                  Address
                </Label>
                <textarea
                  name="address"
                  className="form-control"
                  id="address"
                  rows={1}
                  onChange={event =>
                    handleChange(event.target.value, "address")
                  }
                  value={basicInfo.address}
                  disabled={!isEdit}
                ></textarea>
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="zipcode" className="col-form-label">
                  Zipcode
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  placeholder="Enter Your Zipcode"
                  name="zipcode"
                  onChange={event =>
                    handleChange(event.target.value, "zipcode")
                  }
                  value={basicInfo.zipcode}
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="city" className="col-form-label">
                  city
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="city"
                  placeholder="Enter Your city"
                  name="city"
                  onChange={event => handleChange(event.target.value, "city")}
                  value={basicInfo.city}
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="state" className="col-form-label">
                  State
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="state"
                  placeholder="Enter Your State"
                  name="state"
                  onChange={event => handleChange(event.target.value, "state")}
                  value={basicInfo.state}
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="country" className="col-form-label">
                  Country
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="country"
                  placeholder="Enter Your Country"
                  name="country"
                  onChange={event =>
                    handleChange(event.target.value, "country")
                  }
                  value={basicInfo.country}
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="person" className="col-form-label">
                  Authorized Person
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="person"
                  placeholder="Enter Your Company Name"
                  name="person"
                  value={basicInfo.person}
                  onChange={event => handleChange(event.target.value, "person")}
                  disabled={!isEdit}
                />
              </Col>
              <Col sm={3} className="mt-2">
                <Label htmlFor="mobileNumber" className="col-form-label">
                  Mobile Number
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="mobileNumber"
                  placeholder="Enter Your Mobile Number"
                  name="mobileNumber"
                  onChange={event =>
                    handleChange(event.target.value, "mobileNumber")
                  }
                  value={basicInfo.mobileNumber}
                  disabled={!isEdit}
                />
              </Col>
              <Col sm={12} className="mt-2">
                <Label htmlFor="note" className="col-form-label">
                  Additional Notes About Company
                </Label>
                <div className="">
                  <textarea
                    name="note"
                    className="form-control"
                    id="note"
                    rows={2}
                    onChange={event => handleChange(event.target.value, "note")}
                    value={basicInfo.note}
                    disabled={!isEdit}
                  ></textarea>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
        {isEdit && (
          <Row className="mt-4">
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={() => toggleEdit()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="w-md btn-sm ms-2"
                type="button"
                onClick={() => save()}
              >
                Save
              </Button>
            </div>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default BasicInfo;
