import ErrorAlert from "components/Alerts/error";
import SuccessAlert from "components/Alerts/succes";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { UpdateBonusToStylist } from "services/orders.service";

const UpdateBonus = ({ modal, toggle, activeOrder }) => {
  const [values, setValues] = useState(
    activeOrder?.notAvailable?.bonusToStylist
  );
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);

  useEffect(() => {
    setValues(values);
  }, []);

  function handleChange(value) {
    setValues(value);
  }

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const onSubmit = () => {
    let body = {
      bonus: values,
    };
    UpdateBonusToStylist(activeOrder?._id, body)
      .then(res => {
        if (res?.success === true) {
          setsuccess_msg(true);
          setLoading(false);
          //   getAll(activeOrder);
          //   getallOrders();
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="md">
        <ModalHeader toggle={toggle}>Update Bonus</ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <div>
              <FormGroup>
                <Label for="bonus">Bonus</Label>
                <Input
                  type="text"
                  name="bonus"
                  id="bonus"
                  value={values}
                  onChange={event => {
                    handleChange(event.target.value);
                  }}
                />
              </FormGroup>
            </div>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={leaveToList}
              >
                cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                className="w-md btn-sm float-end"
                onClick={onSubmit}
              >
                Save
              </Button>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <Spinner loading={loading} />
      {success_msg ? (
        <SuccessAlert
          msg={"Successfully Order Updated"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
      {error_msg ? (
        <ErrorAlert
          msg={"error"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
    </>
  );
};

export default UpdateBonus;
