export const createArticle = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/article/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Article create ${e}`));
};

export const getArticles = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/article/get`, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Article create ${e}`));
};
export const getArticleById = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/article/article/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Article create ${e}`));
};
export const updateArticleById = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/article/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update file feild ${e}`));
};
export const deleteArticle = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/article/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      console.log(res, "res");
      return res.json();
    })
    .catch(e => console.log(`error at get Product ${e}`));
};

export const getArticlesByAuthor = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/article/byauthorname`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Article create ${e}`));
};
