export const getAllCoupons = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/coupons/getAll`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting all coupons ${e}`));
};

export const createcoupon = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/coupons/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Create coupon ${e}`));
};

export const updateCoupon = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/coupons/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at update coupon ${e}`));
};

export const updateCouponStatus = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/coupons/deactivate/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at status update ${e}`));
};

export const getCouponUsedUsers = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/couponLogs/getByCode/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get coupons used users ${e}`));
};
