import React from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
// import "./Styles.scss";
import moment from "moment";
import AdminNotesContactUs from "./AdminNotes";
// import AdminNotes from "./AdminNotes";

const ContactUsUsersDetails = ({ activeOrder }) => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Artist Forms View | stylepro - -</title>
      </MetaTags>
      <Container fluid>
        <React.Fragment>
          {activeOrder !== null ? (
            <>
              {" "}
              <Card className="">
                {" "}
                <CardBody className=" border-3 border-bottom pb-1">
                  <Row>
                    <Col md="12" className="">
                      <div className="">
                        <div className="d-flex justify-content-between mb-2 align-items-center">
                          {/* <div className="">
                        <h6 className="font-size-18 fw-bold mb-0">
                          Quotation / Order ID #{activeOrder?.quoteid}
                        </h6>
                      </div> */}
                          <h6 className="fw-bold">
                            Submitted On :
                            <span className="fw-bold">
                              {" "}
                              {moment(activeOrder?.createdAt).format(
                                "DD-MMMM-YYYY @ hh:mm a"
                              )}
                            </span>
                          </h6>
                          <div>
                            <button className="btn btn-primary btn-sm float-end">
                              {activeOrder?.deviceType}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
                <CardBody className=" border-3 border-bottom">
                  <div>
                    <div className="bg-light p-1 mb-3">
                      <p className="mb-0 fw-bold text-center font-size-14">
                        Customer Details
                      </p>
                    </div>
                    <div className="d-flex mb-1 ms-3">
                      <h6 className="customer_name"> Name</h6>
                      <h6>:</h6>
                      <h6 className="fw-bold ms-1">
                        {activeOrder?.firstName ? activeOrder?.firstName : ""}{" "}
                        {activeOrder?.lastName ? activeOrder?.lastName : ""}
                      </h6>
                    </div>
                    <div className="d-flex mb-1 ms-3">
                      <h6 className="customer_name"> Mobile Number</h6>
                      <h6>:</h6>
                      <h6 className="fw-bold ms-1">
                        {activeOrder?.mobilenumber
                          ? activeOrder?.mobilenumber
                          : ""}
                      </h6>
                    </div>
                    <div className="d-flex mb-1 ms-3">
                      <h6 className="customer_name"> E-mail</h6>
                      <h6 className="">:</h6>
                      <h6 className="fw-bold ms-1">
                        {activeOrder?.email ? activeOrder?.email : ""}
                      </h6>
                    </div>
                    <div className="d-flex mb-1 ms-3">
                      <h6 className="customer_name ">Reasons</h6>
                      <h6 className="">:</h6>
                      <h6 className="fw-bold ms-1 ">
                        {activeOrder?.reasons ? activeOrder?.reasons : ""}
                      </h6>
                    </div>
                    {activeOrder?.serviceDescription !== "" ? (
                      <div className="my-2">
                        <div className="d-flex">
                          <h6 className="ms-3">Other Important Information</h6>
                          <h6 className="ms-1">:</h6>
                        </div>
                        <div className="border border-1 p-2 ms-3">
                          <h6 className="fw-bold ms-1 mb-0">
                            {activeOrder?.serviceDescription
                              ? activeOrder?.serviceDescription
                              : "-"}
                          </h6>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </CardBody>
              </Card>
            </>
          ) : (
            ""
          )}
          {activeOrder != null ? (
            <div>
              <AdminNotesContactUs activeOrder={activeOrder} />
            </div>
          ) : (
            ""
          )}
          {activeOrder === null ? (
            <Card className="p-3 pt-0 empty_order">
              <CardBody className="border-3">
                <div className="text-center">
                  <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
                </div>
                <p className="text-center">Please Select Order</p>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </React.Fragment>
      </Container>
    </React.Fragment>
  );
};

export default ContactUsUsersDetails;
