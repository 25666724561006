import React from "react";
import { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Badge } from "reactstrap";
import moment from "moment";
import { useParams } from "react-router";
import ListLeftPanel from "components/List/ListLeftPanel";
import {
  getBrands,
  getBrand,
  deleteBrand,
} from "services/ecommerce/brand.service";
import BrandCreate from "./Create";
import SweetAlert from "react-bootstrap-sweetalert";
import noImage from "assets/images/PicUpload.jpg";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const ParentCategoryNew = props => {
  const { id } = useParams();
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [values, setValues] = useState([]);
  const [modal, setModal] = useState(false);
  const [allList, setAllList] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);

  useEffect(() => {
    getBrandList();
  }, []);
  const getBrandList = () => {
    getBrands()
      .then(res => {
        let { data } = res;
        setList(data);
        setAllList(data);
        if (res.data[0]) {
          let {
            name,
            logo,
            createdAt,
            updatedAt,
            description,
            isFeatured,
            _id,
            status,
            brandId,
          } = res.data[0];
          let obj = {
            name,
            logo,
            createdAt,
            updatedAt,
            description,
            isFeatured,
            _id,
            status,
            brandId,
          };
          setValues({ ...obj });
          setLoading(false);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };

  const handleService = (ele, index) => {
    getBrandById(ele._id);
  };
  const getBrandById = id => {
    setLoading(true);
    getBrand(id)
      .then(res => {
        if (res.data) {
          let {
            name,
            logo,
            createdAt,
            updatedAt,
            description,
            status,
            brandId,
            isFeatured,
            banners,
            _id,
          } = res.data;
          let obj = {
            name,
            logo,
            createdAt,
            updatedAt,
            description,
            status,
            isFeatured,
            brandId,
            banners,
            _id,
          };
          setValues({ ...obj });

          setLoading(false);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSearch = search => {
    // debugger;
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(ele =>
        ele?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setList(filterList);
    } else {
      setList(allList);
    }
  };

  const removeBrand = id => {
    setLoading(true);
    deleteBrand(id)
      .then(res => {
        setDelete_modal(false);
        getBrandList();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeBrand(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const renderView = () => {
    return (
      <div>
        <div className="text-end mb-3">
          <Button
            type="button"
            color="primary"
            className="  mt-2"
            onClick={toggle}
          >
            <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
            Create Brand
          </Button>
        </div>
        <Card className="view-users parentCat_view">
          <CardBody>
            {!values && values.length < 0 && (
              <div className="empty_list p-3 pt-0">
                <div className="text-center">
                  <i className="mdi mdi-arrow-left-circle" id=""></i>
                </div>
                <p className="text-center">Please Select Brand</p>
              </div>
            )}
            {values && (
              <div>
                <div className="d-flex float-end">
                  <div>
                    <Badge
                      className="font-size-12 badge-soft-warning py-1 px-3 me-2"
                      color={"bg-warning"}
                      role="button"
                      pill
                      onClick={() => {
                        setActiveId(values._id);
                        toggle();
                      }}
                    >
                      <i className="mdi mdi-pencil font-size-14" /> Edit
                    </Badge>
                  </div>
                  <div>
                    <Badge
                      className="font-size-12 badge-soft-danger py-1 px-3"
                      color={"bg-warning"}
                      role="button"
                      onClick={() => onClickDelete(values._id)}
                      style={{ cursor: "pointer" }}
                      pill
                    >
                      <i className="mdi mdi-delete font-size-14" />
                      Delete
                    </Badge>
                  </div>
                </div>
                <Row className="">
                  <Col md={4} className="">
                    <div className="text-center">
                      <img
                        src={values?.logo ? values?.logo : noImage}
                        alt=""
                        className="img-thumbnail profile rounded-circle"
                      />
                      <h5 className="mt-4 text-primary"></h5>
                    </div>
                  </Col>
                  <Col md={8} className=" pt-4 mb-4 px-0 view_detail ">
                    <Row className="mx-3 ps-2">
                      <p className="ps-0">
                        <span className="pe-3 fw-bold"> Name </span>{" "}
                        <span className="text-muted">{values.name} </span>
                      </p>
                    </Row>
                    <div className=" mt-2 me-3 hr"> </div>

                    <Row className="mx-3 my-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">brand Id</p>
                        <p className="text-muted">
                          {values.brandId ? values.brandId : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">status</p>
                        <p className="text-muted">
                          {values.status
                            ? values.status === "active"
                              ? "Active"
                              : "Inactive"
                            : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Isfeatured</p>
                        <p className="text-muted">
                          {values.isFeatured ? values.isFeatured : "_"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="mx-3 my-2">
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Created</p>
                        <p className="text-muted">
                          {moment(values?.createdAt).format("MMM Do YYYY")}
                        </p>
                      </Col>
                      <Col md={4} className="mt-4">
                        <p className="fw-bold mb-1">Last Updated</p>
                        <p className="text-muted">
                          {moment(values?.updatedAt).format("MMM Do YYYY")}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="mx-3 ">
                  <Col md={12} className="mt-4">
                    <p className="fw-bold mb-1">Description </p>
                    {values.description ? (
                      <div
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: values.description,
                        }}
                      />
                    ) : (
                      "_"
                    )}{" "}
                  </Col>
                </Row>
                <Row className="mx-4 mt-3">
                  {values?.banners?.length ? (
                    <p className="fw-bold mb-1 m-0 p-0 ">Banners</p>
                  ) : (
                    ""
                  )}

                  {values?.banners?.length
                    ? values?.banners?.map((ele, ind) => {
                        return (
                          <Col
                            key={ind}
                            md={12}
                            className="border border-2 my-2"
                          >
                            <div>
                              <div className="d-flex mt-3 align-items-center">
                                <h6 className="me-2 mb-0">{ind + 1}.</h6>{" "}
                                <h6
                                  className={
                                    ele?.status === "active"
                                      ? "text-success mb-0"
                                      : "text-danger mb-0"
                                  }
                                >
                                  {ele?.status === "active"
                                    ? "Active"
                                    : "Inactive"}
                                </h6>
                                {ele?.status === "active" ? (
                                  <i className="bx bxs-badge-check ms-1 text-success"></i>
                                ) : (
                                  <i className="bx bxs-error-circle text-danger ms-1"></i>
                                )}
                              </div>
                            </div>
                            <img
                              src={ele?.image}
                              alt=""
                              height="200px"
                              style={{ width: "inherit" }}
                              className="mt-2"
                            />
                          </Col>
                        );
                      })
                    : ""}
                </Row>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Brands List | stylepro</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Brands" breadcrumbItem="Brands List" />
          <BreadcrumbSub subtile="Brands List" SubBreadcrumbItem="Brands" />
          <Row>
            <Col md="3" className="list-column">
              <ListLeftPanel
                list={list}
                handleItemClick={handleService}
                propertiesName="brands"
                handleSearch={handleSearch}
              />
            </Col>
            <Col md="9">{renderView()}</Col>
          </Row>
        </Container>
      </div>
      <div>
        <BrandCreate
          toggle={toggle}
          modal={modal}
          id={activeId}
          reload={getBrandList}
        />
      </div>
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};
export default ParentCategoryNew;
