export const GetMobileBanners = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/banner/mobile/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(error => console.log(`error at get Banners ${error}`));
};

export const GetCallUsBanners = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/banner/callUs/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(error => console.log(`error at get Banners ${error}`));
};

export const createBanner = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/banner/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Banner create ${e}`));
};
export const updateBanner = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/banner/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Banner Update ${e}`));
};

export const deleteBanner = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/banner/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at deleting banner ${e}`));
};
