import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as _ from "lodash";
import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import { updatefilefeildService } from "services/services.service";
import { createImageUrl } from "services/imageUrl.service";
import Spinner from "components/spinner";

const defaultValues = {
  portfolio: [],
};

const EditPortfolio = ({ artistValues, handleSave, handleCancel, modal }) => {
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    if (artistValues) {
      let { portfolio } = artistValues[0];

      let obj = { portfolio: [...portfolio] };

      setValues({ ...obj });
    }
    setLoading(false);
  }, [artistValues]);

  const removeImage = index => {
    let obj = { ...values };

    obj.portfolio.splice(index, 1);

    setValues({ ...obj });
  };

  const handleAcceptedImages = async images => {
    setLoading(true);
    let obj = { ...values };

    if (obj.portfolio.length < 7) {
      try {
        let imageFormData = new FormData();
        imageFormData.set("image", images[0]);

        const data = await createImageUrl(imageFormData);

        if (data.success) {
          obj.portfolio.push({ image: data.image });
        }

        setValues({ ...obj });
      } catch (err) {
        console.log(err);
      }
    } else {
      alert("Maximum 7 Images are allowed");
    }
    setLoading(false);
  };

  return (
    <>
      <Spinner loading={loading} />
      <Modal
        isOpen={modal}
        toggle={() => handleCancel("portfolio")}
        backdrop="static"
        size="lg"
      >
        <ModalHeader toggle={() => handleCancel("portfolio")}>
          ARTIST IMAGES
        </ModalHeader>
        <ModalBody className="px-4">
          <Row>
            <Label htmlFor="firstname-Input" className="col-form-label">
              Images
            </Label>
            <Col md={6} className="">
              <Dropzone
                onDrop={acceptedImages => {
                  handleAcceptedImages(acceptedImages);
                }}
              >
                {({ getRootProps, getInputProps }) => (
                  <div className="dropzone image_drop mt-1">
                    <div className="dz-message needsclick" {...getRootProps()}>
                      <input {...getInputProps()} />
                      <div className="">
                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                      </div>
                      <h6>Drop files here or click to upload.</h6>
                    </div>
                  </div>
                )}
              </Dropzone>
            </Col>
            <Col md={6}>
              <div className="dropzone-previews" id="file-previews">
                <div className="p-2">
                  <Row className="align-items-center">
                    {values?.portfolio &&
                      values?.portfolio.length > 0 &&
                      values?.portfolio.map((img, index) => {
                        let { image } = img;
                        return (
                          <Col className="col-auto" sm={6} key={index}>
                            <div
                              className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                              style={{
                                position: "relative",
                              }}
                            >
                              <img
                                alt={"banner"}
                                src={image}
                                height="80"
                                className=" rounded bg-light"
                              />
                              <i
                                style={{
                                  position: "absolute",
                                  right: "3px",
                                  cursor: "pointer",
                                  top: 0,
                                }}
                                className="mdi mdi-close-thick font-size-18 text-danger"
                                id="removeImgtooltip"
                                onClick={() => removeImage(index)}
                              />
                            </div>
                          </Col>
                        );
                      })}
                  </Row>
                </div>
              </div>
            </Col>
          </Row>
          <div className="d-flex mt-4 justify-content-end">
            <Button
              color="light"
              className="w-md btn-sm me-2"
              onClick={() => handleCancel("portfolio")}
            >
              Cancel
            </Button>
            <Button
              color="primary"
              className="w-md btn-sm ms-2"
              onClick={() => handleSave(values, "portfolio")}
            >
              Save
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default EditPortfolio;
