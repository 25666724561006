import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Link } from "react-router-dom";

import Breadcrumb from "../../components/Common/Breadcrumb";

import profileImg from "assets/images/users/avatar-3.jpg";
import moment from "moment";
import { getPackage } from "services/package.service";
import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

const defaultValues = {
  packageName: "",
  image: {},
  gender: "",
  parentCategory: [],
  category: [],
  services: [],
  price: "",
  discount: "",
  status: "",
  country: {},
  createdAt: null,
  updatedAt: null,
};

const ViewPackage = props => {
  const { id } = useParams();
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(true);

  let { image } = values;

  useEffect(() => {
    getPackage(id)
      .then(res => {
        let {
          packageName,
          discount,
          gender,
          price,
          image,
          country,
          services,
          category,
          parentCategory,
          status,
          createdAt,
          updatedAt,
        } = res.data;

        let obj = {
          packageName,
          discount,
          gender,
          price,
          country,
          services,
          category,
          parentCategory,
          image,
          status,
          createdAt,
          updatedAt,
        };
        setValues({ ...obj });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | stylepro </title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumb title="Package" breadcrumbItem="Package View" />
          <Card className="view-users">
            <CardBody>
              <div className="d-flex justify-content-between">
                <Link to="/category-list">
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link>
                <Link className="text-end" to={`/category-update/${id}`}>
                  <Badge
                    className="font-size-12 badge-soft-warning py-1 px-3"
                    color={"bg-warning"}
                    pill
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                </Link>
              </div>
              <Row>
                <Col md={4} className="">
                  <div className="text-center my-4">
                    {/* <img src={profileImg} alt="" className="img-thumbnail" /> */}
                    <ProfileImage
                      image={image}
                      classNames="img-thumbnail max_width_250"
                    />
                    <h5 className="mt-4 text-muted">{values.packageName}</h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 view_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">Category Name </span>{" "}
                      <>
                        {" "}
                        {values.parentCategory.map(e => (
                          <span key={e._id} className="text-muted">
                            {e.name}
                            {" ,"}
                          </span>
                        ))}
                      </>
                    </p>
                    <p className="ps-0 ">
                      <span className="pe-3 fw-bold">SubCategory </span>{" "}
                      <>
                        {" "}
                        {values.category.map(e => (
                          <span key={e._id} className="text-muted">
                            {e.name}
                            {" ,"}
                          </span>
                        ))}
                      </>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Country</p>
                      <p className="text-muted">{values.country.label}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Service</p>
                      <>
                        {" "}
                        {values.services.map(e => (
                          <span key={e._id} className="text-muted">
                            {e.name}
                            {" ,"}
                          </span>
                        ))}
                      </>
                    </Col>
                  </Row>

                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Price</p>
                      <p className="text-muted">
                        {moment(values.createdAt ? values.price : "_").format(
                          "MMM Do YYYY"
                        )}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Discount</p>
                      <p className="text-muted">
                        {moment(
                          values.updatedAt ? values.discount : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">
                        {moment(
                          values.createdAt ? values.createdAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Last Updated</p>
                      <p className="text-muted">
                        {moment(
                          values.updatedAt ? values.updatedAt : "_"
                        ).format("MMM Do YYYY")}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ViewPackage;
