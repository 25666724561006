import Breadcrumb from "components/Common/Breadcrumb";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { MetaTags } from "react-meta-tags";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  Badge,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import moment from "moment";
import { getAllAppExceptions } from "services/appExceptions.service";
import ExceptionsView from "./View";
import UpdateExceptionStatus from "./UpdateExceptionStatus";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const AppExceptionsList = () => {
  const { SearchBar } = Search;
  const [exceptionsList, setexceptionsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [updateModal, setUpdatemodal] = useState(false);
  const [activedata, setActivedata] = useState();

  useEffect(() => {
    getAppExceptions();
  }, []);

  const getAppExceptions = () => {
    setLoading(true);
    getAllAppExceptions()
      .then(res => {
        const sortedExceptionsList = res?.data?.sort((a, b) => {
          return new Date(b?.createdAt) - new Date(a?.createdAt);
        });
        setexceptionsList(sortedExceptionsList || []);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const toggle = () => {
    setModal(!modal);
  };
  const UpdateToggle = () => {
    setUpdatemodal(!updateModal);
  };
  const handleActiveOrder = data => {
    setActivedata(data);
  };
  // Table Data
  const customer = () => [
    {
      dataField: "type",
      text: "Type",
    },
    {
      dataField: "platform",
      text: "Platform",
    },
    {
      dataField: "loggedInAs",
      text: "Logged In As",
    },
    {
      dataField: "page",
      text: "Page",
    },
    {
      dataField: "userId",
      text: "User",
      formatter: (cell, row) => {
        // console.log(row, "row");
        return (
          <div className="">
            <div>
              {row?.loggedInAs === "User" ? (
                <>
                  {row?.userId?.firstname} {row?.userId?.lastname}
                </>
              ) : row?.loggedInAs === "Stylist" ? (
                <>
                  {row?.stylistId?.legalname} {row?.stylistId?.shortname}
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
    {
      dataField: "priority",
      text: "Priority",
      formatter: (cellContent, row) => {
        if (row?.priority === "Low") {
          return <div className=" text-secondary fw-bold">Low</div>;
        } else if (row?.priority === "Medium") {
          return (
            <>
              <div className="fw-bold text-warning">Medium</div>
            </>
          );
        } else if (row?.priority === "Critical") {
          return (
            <>
              <div className="fw-bold text-danger">Critical</div>
            </>
          );
        } else if (row?.priority === "danger") {
          return (
            <>
              <div className="fw-bold text-danger">Hight</div>
            </>
          );
        }
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => {
        if (row?.status === "Pending") {
          return (
            <div className="d-flex align-items-center text-danger">
              <i className="bx bxs-circle me-1 text-danger"></i>
              Pending
            </div>
          );
        } else if (row?.status === "Resolved") {
          return (
            <>
              <div className="d-flex align-items-center">
                <i className="bx bxs-circle me-1 text-success"></i>
                Resolved
              </div>
            </>
          );
        }
      },
    },
    {
      dataField: "createdAt",
      text: "createdAt",
      formatter: (cell, row) => (
        <>{moment(row?.createdAt).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      dataField: "updatedAt",
      text: "updatedAt",
      formatter: (cell, row) => (
        <>{moment(row?.updatedAt).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      dataField: "",
      text: "View",
      formatter: (cellContent, row) => {
        const badgeColor = "font-size-12 px-2 py-1 badge-soft-";
        return (
          <div className="d-flex gap-3">
            <Badge
              className={badgeColor + "info"}
              color={"bg-secondary"}
              pill
              onClick={() => {
                handleActiveOrder(row);
                // toggle();
                setModal(!modal)
              }}
              role="button"
            >
              <i className="mdi mdi-eye " id="viewtooltip" />
            </Badge>
            {/* <Badge
              className={badgeColor + "success"}
              color={"bg-secondary"}
              pill
              onClick={() => {
                handleActiveOrder(row);
                UpdateToggle();
              }}
              role="button"
            >
              <i className="mdi mdi-pencil " id="editTooltip" />
            </Badge> */}
          </div>
        );
      },
    },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: exceptionsList?.length, // replace later with size(customers),
    custom: true,
  };
  return (
    <>
      <React.Fragment>
        <Spinner loading={loading} />
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Exceptions" breadcrumbItem="Mobile Exceptions List" />
            <BreadcrumbSub
              subtile="Mobile Exceptions List"
              SubBreadcrumbItem="Exceptions"
            />

            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="exceptionsList"
                      columns={customer()}
                      data={exceptionsList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="exceptionsList"
                          data={exceptionsList}
                          columns={customer()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                                <div className="d-flex align-items-center">
                                  <div className="search-box d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="exceptionsList"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    {exceptionsList?.length == 0 && (
                      <p className="text-center"> No Data Found</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      <ExceptionsView toggle={toggle} modal={modal} activeObj={activedata} reload={getAppExceptions} setModal={setModal}/>
      {/* <UpdateExceptionStatus
        toggle={UpdateToggle}
        modal={updateModal}
        activeObj={activedata}
        reload={getAllAppExceptions}
      /> */}
    </>
  );
};

export default AppExceptionsList;
