import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useParams } from "react-router";
import JoinCompany from "./joinCompany";

const MyCompanies = ({ joinedComapnies, handleSubmit }) => {
  const { id } = useParams();
  const [companiesList, setComapniesList] = useState(joinedComapnies);
  const [modal, setModal] = useState(false);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    setComapniesList(joinedComapnies);
  }, [joinedComapnies]);

  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };
  return (
    <Card className="border-light view-users joined_companies_container">
      <CardBody>
        <div className="d-flex justify-content-between">
          <h5 className="fw-bold">JOINED COMPANIES</h5>
          {/* <Link className="" to={`/individul-join-company/${id}`}> */}
          <Button
            className="btn btn-sm btn-primary"
            color={"bg-warning"}
            onClick={() => {
              toggle();
              setActiveId(id);
            }}
          >
            <i className="bx bx-group font-size-14" /> Join Comapny
          </Button>
          {/* </Link> */}
        </div>
        <Row className="justify-content-center my-2">
          <Row className="t_m_header">
            <Col sm={1} className="pe-0">
              <div className="fw-bold t_m_td">Sl.No.</div>
            </Col>
            <Col sm={3}>
              <div className="fw-bold t_m_td">Registration Id.</div>
            </Col>
            <Col sm={2}>
              <div className="fw-bold t_m_td">Company Name</div>
            </Col>
            <Col sm={3}>
              <div className="fw-bold t_m_td">Owner Name</div>
            </Col>
            <Col sm={3}>
              <div className="fw-bold t_m_td">City</div>
            </Col>
          </Row>
          {companiesList &&
            companiesList.length > 0 &&
            companiesList.map((member, ind) => {
              return (
                <Row className="my-2 t_m_tr" key={ind}>
                  <Col sm={1}>
                    <div className="t_m_td tm_m_sno">{ind + 1}</div>
                  </Col>
                  <Col sm={3}>
                    <div className="t_m_td">{member.regId}</div>
                  </Col>
                  <Col sm={2}>
                    <div className="t_m_td">{member.companyName}</div>
                  </Col>
                  <Col sm={3}>
                    <div className="t_m_td">{member.person}</div>
                  </Col>
                  <Col sm={3}>
                    <div className="t_m_td">{member.city}</div>
                  </Col>
                </Row>
              );
            })}

          {companiesList.length < 1 && (
            <Row className="mt-3 t_m_tr">
              <div className="team_0 text-center py-3">
                Oops!.. No Companies.
              </div>
            </Row>
          )}
        </Row>
      </CardBody>
      <JoinCompany toggle={toggle} modal={modal} id={activeId} />
    </Card>
  );
};

export default MyCompanies;
