const timesSlots = {
    1: "5:00 AM",
    2: "5:30 AM",
    3: "6:00 AM",
    4: "6:30 AM",
    5: "7:00 AM",
    6: "7:30 AM",
    7: "8:00 AM",
    8: "8:30 AM",
    9: "9:00 AM",
    10: "9:30 AM",
    11: "10:00 AM",
    12: "10:30 AM",
    13: "11:00 AM",
    14: "11:30 AM",
    // 15: "11:59 AM",
    16: "12:00 PM",
    17: "12:30 PM",
    18: "1:00 PM",
    19: "1:30 PM",
    20: "2:00 PM",
    21: "2:30 PM",
    22: "3:00 PM",
    23: "3:30 PM",
    24: "4:00 PM",
    25: "4:30 PM",
    // 26: "4:59 PM",
    27: "5:00 PM",
    28: "5:30 PM",
    29: "6:00 PM",
    30: "6:30 PM",
    31: "7:00 PM",
    32: "7:30 PM",
    33: "8:00 PM",
    34: "8:30 PM",
    35: "9:00 PM",
  };

  export default timesSlots;