import React ,{useState} from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  FormGroup,
//   Form,
  Input,
  FormFeedback
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import SweetAlert from "react-bootstrap-sweetalert";
import "./createProviderType.scss"
import Select from "react-select";
import {createType,updateProvidertypes,getProvidertype} from "services/providertype.service"
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import { useEffect } from "react";

import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const CreateType = props => {
    const [selectedGroup, setselectedGroup] = useState(null);
    const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const { id } = useParams();
  const  initialValues= {
    name: "",
    commission: "",
    status:""  
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Name"),
    commission: Yup.string().required("Please Enter Your commission"),
    status:Yup.string().required("Please Select Your Status")
});

const onSubmit= (values) => {
    console.log("values", values);
    if(id){
        handleUpdate(values)
    }else{
        handleSubmit(values)

    }
  }

    const providerTypeList = [
        { label: "inactive", value: "inactive" },
        { label: "active", value: "active" },
    
      ];
    function handleSelectGroup(selectedGroup) {
    setselectedGroup(selectedGroup);
    
    // setValues({ ...values, ["status"]: selectedGroup.value });

  }
  const handleChange = name => event => {
    // setValues({ ...values, [name]: event.target.value });
  };
  const preload = id => {
    if (id) {
        getProviderTypebyid(id);
    } else {
    //   setValues({ ...values });
    }
  };

  useEffect(() => {
    preload(id);
  }, []);


  const getProviderTypebyid=(id)=>{
    getProvidertype(id).then((res)=>{
        // setValues({...res.data})
        setselectedGroup({label:res.data.status,value:res.data.status})
    }).catch(e=>{
        console.log(e)
    })
  }
  const handleSubmit=(values)=>{
      createType(values).then(res=>{
        setsuccess_msg(true);
        seterror_msg(false);
          console.log(res)
      }).catch(err=>{
        setsuccess_msg(false);
        seterror_msg(true);
          console.log(err)
      })
  }

  const handleUpdate=(values)=>{
      updateProvidertypes(id,values).then(res=>{
        setsuccess_msg(true);
        seterror_msg(false);
          console.log(res)
      }).catch(err=>{
        setsuccess_msg(false);
        seterror_msg(true);
          console.log(err)
      })
  }
  const leaveToList = () => {
    props.history.push("/provider-type");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
    props.history.push("/provider-type");
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully New Provider type created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Layouts | stylepro - -</title>
        </MetaTags>
        <Container fluid={false}>
          <Breadcrumb
            title="Provider Type"
            breadcrumbItem="Create Type"
          />
          <Card>
            <CardBody>
              <CardTitle className="mb-4"></CardTitle>
              <Row>
                
              <Formik enableReinitialize  initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ errors, touched, isSubmitting, setFieldValue,handleChange,values }) => {
            const [user, setUser] = useState({});
            const [showPassword, setShowPassword] = useState(false);

            useEffect(() => {
                if (id) {
                    getProvidertype(id).then((res)=>{
                        const data = {...res.data}
                        const fields = ['name', 'commission', 'status'];
                        fields.forEach(field => setFieldValue(field, data[field], false));
                        setselectedGroup({label:res.data.status,value:res.data.status})
                    }).catch(e=>{
                        console.log(e)
                    })
                }
            }, []);

            return (
                <Form >
                    <Row className="m-3">
                     
                      <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom01">Name</Label>
                          <Input
                            name="name"
                            placeholder="Name"
                            type="text"
                            className="form-control"
                            id="validationCustom01"
                            onChange={handleChange}
                            // onBlur={validation.handleBlur}
                            value={(values.name )}
                            invalid={
                              touched.name && errors.name ? true : false
                            }
                          />
                          {touched.name && errors.name ? (
                            <FormFeedback type="invalid">{errors.name}</FormFeedback>
                          ) : null}
                        </FormGroup>
                    </Row>
                     <Row className="m-3">
                     <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom02">Commission</Label>
                          <Input
                            name="commission"
                            placeholder="commission"
                            type="text"
                            className="form-control"
                            id="validationCustom02"
                            onChange={handleChange}
                            // onBlur={validation.handleBlur}
                            value={values.commission || ""}
                            invalid={
                              touched.commission && errors.commission ? true : false
                            }
                          />
                          {touched.commission && errors.commission ? (
                            <FormFeedback type="invalid">{errors.commission}</FormFeedback>
                          ) : null}
                        </FormGroup>
                    </Row>
                   
                    <Row className="m-3">

                    <FormGroup className="mb-3">
                          <Label htmlFor="validationCustom03">Status</Label>
                          <Select
                        //   className={validation.errors.status?"statusSelect":""}
                          value={selectedGroup}
                          onChange={(event) => {
                            handleSelectGroup(event);
                            handleChange("status")(event.value);
                          }}
                          name="status"
                          options={providerTypeList}
                          classNamePrefix="select2-selection"
                        />
                        <Label className="statusValidation"> { errors.status?errors.status:''}</Label>
                        </FormGroup>
                  </Row>
                    
          

                    <div className="row justify-content-md-center m-2">
                      <Col>
                        
                        <div>
                         {id?<Button color="primary"
                            className="w-md"
                            type="submit"
                            >Update</Button> :<Button
                            type="submit"
                            color="primary"
                            className="w-md"
                            // onClick={handleSubmit}
                          >
                            Submit
                          </Button>}
                        </div>
                      </Col>
                    </div>
                  </Form>
            );
        }}
    </Formik>
              </Row>
              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateType;
