import Breadcrumb from "components/Common/Breadcrumb";
import Spinner from "components/spinner";
import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { MetaTags } from "react-meta-tags";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import settledImage from "../../assets/images/setlted1.jpg";
import moment from "moment";
import CreateSettlement from "./Editsettlement";
import {
  getSettlementsByCompanyId,
  getSettlementsByStylist,
} from "services/settlements.service";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import EarningsDetails from "./EarningsDetails";
import { getProvideById } from "services/provider.service";

const SettlementsLists = () => {
  const { SearchBar } = Search;
  const history = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const access = queryParams.get("access");
  const [settlementList, setSettlementList] = useState([]);
  const [selectedOrders, setSelectedOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [earningsModal, setEarningsmodal] = useState(false);
  const [tempList, setTempList] = useState([]);
  const [earnigsData, setearnigsData] = useState();
  const [typeFilter, setTypeFilter] = useState([false]);
  const typeFilterOptions = [false, true];
  const [ordersData, setOrdersData] = useState([]);
  const [TotalTipAmount, setTotalTipAmount] = useState(0);

  let { id } = useParams();
  useEffect(() => {
    getSettlementsList();
    getOrdersList(id);
  }, [id]);
  const getOrdersList = id => {
    // setLoading(true);
    getProvideById(id)
      .then(res => {
        if (res?.data) {
          setOrdersData(res?.data?.bookings);
        }
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        // setLoading(false);
      });
  };
  useEffect(() => {
    TipsEarned();
  }, [ordersData]);
  const TipsEarned = () => {
    // -----TOTAL EARNINGS-----//
    let TotalEarnigs = ordersData;
    TotalEarnigs = TotalEarnigs.map(item => item.booking);
    TotalEarnigs = TotalEarnigs.filter(
      item =>
        item?.biddings?.length > 0 &&
        item?.biddings?.filter(i => i?.status == "Paid" && i?.provider)?.length
    );

    const Totalearnings = TotalEarnigs;

    let TotalRevenue = TotalEarnigs.map(i => i.biddings);

    TotalRevenue = TotalRevenue.map(item => {
      let TotalEarning = item.filter(i => i.status == "Paid");

      return TotalEarning;
    });

    TotalRevenue = [...TotalRevenue];

    let totalTotalRevenue = 0;

    TotalRevenue.forEach((item, index) => {
      if (item && item[0]) {
        totalTotalRevenue =
          totalTotalRevenue +
          Number(item[0]?.tipAmount ? item[0]?.tipAmount : 0);
      }
      if (index + 1 == TotalRevenue.length) {
        setTotalTipAmount(totalTotalRevenue);
      }
    });
  };

  const getSettlementsList = async () => {
    setLoading(true);
    let apiCall;

    if (access === "individual") {
      apiCall = await getSettlementsByStylist(id);
    } else if (access === "Company") {
      apiCall = await getSettlementsByCompanyId(id);
    }
    if (apiCall) {
      const sortedSettlementList = apiCall?.data?.sort((a, b) => {
        return new Date(a?.completionDate) - new Date(b?.completionDate);
      });
      setSettlementList(sortedSettlementList || []);
      setTempList(sortedSettlementList || []);
      setLoading(false);
    }
  };

  // const handleOrderSelection = row => {
  //   setSelectedOrders(prevSelectedOrders => {
  //     debugger;
  //     if (prevSelectedOrders.includes(row?._id)) {
  //       return prevSelectedOrders.filter(id => id !== row?._id);
  //     } else {
  //       return [...prevSelectedOrders, row?._id];
  //     }
  //   });
  // };

  const handleOrderSelection = row => {
    setSelectedOrders(prevSelectedOrders => {
      if (prevSelectedOrders.some(order => order._id === row._id)) {
        return prevSelectedOrders.filter(order => order._id !== row._id);
      } else {
        return [...prevSelectedOrders, row];
      }
    });
  };
  const handleRouterChange = id => {
    const dataArray = settlementList;
    history.push({
      pathname: `/SettlementsByCode/${id}`,
      state: { dataArray: dataArray },
    });
  };
  const toggleEarningsModal = data => {
    setEarningsmodal(!earningsModal);
    setearnigsData(data);
  };

  useEffect(() => {
    if (typeFilter?.length > 0) {
      const newList = tempList.filter(obj =>
        typeFilter.includes(obj?.settlementStatus)
      );
      setSettlementList(newList || []);
    } else {
      setSettlementList(tempList || []);
    }
  }, [typeFilter, tempList]);

  const handleCheckboxChange = text => {
    let newTypeFilter = [...typeFilter];
    let indx = newTypeFilter.indexOf(text);

    if (indx >= 0) {
      newTypeFilter.splice(indx, 1);
    } else {
      newTypeFilter.push(text);
    }
    setTypeFilter(newTypeFilter);
  };
  const toggle = () => {
    setModal(!modal);
  };
  const checkboxItems = () => {
    const displayTexts = {
      true: "Settled",
      false: "Pending",
    };

    return (
      <React.Fragment>
        {typeFilterOptions?.map((option, index) => {
          const isChecked = typeFilter.includes(option);
          const displayText = displayTexts[option];
          return (
            <div className="d-flex me-3 align-items-center" key={index}>
              <Input
                id={option.toString()}
                type="checkbox"
                className="form-check-Inpu me-2 cursor-pointer"
                onChange={() => handleCheckboxChange(option)}
                defaultChecked={isChecked}
              />
              <Label
                className="form-check-Label fw-bold mb-0 pt-1 cursor-pointer"
                htmlFor={option.toString()}
              >
                {displayText}
              </Label>
            </div>
          );
        })}
      </React.Fragment>
    );
  };

  // Table Data
  const customer = () => [
    {
      dataField: "orderNumber",
      text: "OrderId",
      formatter: (cell, row) => {
        return (
          <>
            <div className="d-flex align-items-center">
              <div className="me-3">
                <Input
                  type="checkbox"
                  role="button"
                  defaultChecked={row?.settlementStatus}
                  onChange={() => handleOrderSelection(row)}
                  className=""
                  disabled={row?.settlementStatus === true ? true : false}
                />{" "}
              </div>
              <div>
                <p className="text-primary fw-bold mb-0">#{row?.orderNumber}</p>
              </div>
            </div>
          </>
        );
      },
    },
    {
      dataField: "orderAmount",
      text: "Order Total",
      formatter: row => {
        return (
          <div className="">
            <h6 className="">{row ? row?.toFixed(2) : "0"}</h6>
          </div>
        );
      },
    },
    {
      dataField: "partnerEarnings",
      text: "Partner Earnings",
      formatter: row => {
        return (
          <div className="">
            <h6 className="">{row ? row?.toFixed(2) : "0"}</h6>
          </div>
        );
      },
    },
    {
      dataField: "completionDate",
      text: "Completion Date",
      formatter: (cell, row) => (
        <>{moment(row?.completionDate).format("DD-MMM-YYYY")}</>
      ),
    },
    {
      dataField: "settlementCode",
      text: "Settlement Code",
      formatter: (cell, row) => {
        return (
          <div
            className=""
            onClick={() => {
              handleRouterChange(row?.settlementCode);
            }}
          >
            <h6 className="">
              {row?.settlementCode ? row?.settlementCode : "_"}
            </h6>
          </div>
        );
      },
    },
    {
      dataField: "bankCode",
      text: "Bank Code",
      formatter: (cell, row) => {
        return (
          <div className="">
            <h6 className="">{row?.bankCode ? row?.bankCode : "_"}</h6>
          </div>
        );
      },
    },
    // {
    //   dataField: "productsAmount",
    //   text: "Products Amount",
    //   formatter: row => {
    //     return (
    //       <div className="">
    //         <h6 className="">{row ? row?.toFixed(2) : "0"}</h6>
    //       </div>
    //     );
    //   },
    // },

    // {
    //   dataField: "serviceEarningsPercentage",
    //   text: "Service earning (%)",
    // },
    // {
    //   dataField: "productEarningsPercentage",
    //   text: "Product earning (%)",
    // },
    {
      dataField: "stylistId",
      text: "Stylist",
      formatter: (cellContent, row) => {
        if (access === "Company") {
          return (
            <div className="">
              {row?.stylistId?.legalname ? row?.stylistId?.legalname : ""}{" "}
              {row?.stylistId?.shortname ? row?.stylistId?.shortname : ""}
            </div>
          );
        } else {
          return null;
        }
      },
      hidden: access !== "Company",
    },
    // {
    //   dataField: "orderStatus",
    //   text: "Order Status",
    //   formatter: (cellContent, row) => {
    //     return (
    //       <button className="btn btn-sm font-size-12 text-white bg-info">
    //         {row?.orderStatus}
    //       </button>
    //     );
    //   },
    // },
    {
      dataField: "status",
      text: "Settlements",
      formatter: (cellContent, row) => {
        const badgeColor = "font-size-12 px-2 py-1 badge-soft-";
        return (
          <div>
            <div className="d-flex gap-3">
              <div>
                {row?.settlementStatus === true ? (
                  <i
                    className="bx bxs-check-circle font-size-24"
                    style={{ color: "#07a021" }}
                  ></i>
                ) : (
                  <i
                    className="bx bxs-x-circle font-size-24"
                    style={{ color: "#B30101" }}
                  ></i>
                )}
              </div>
              {row?.settlementStatus === true ? (
                <p className="font-size-12 mt-1">
                  {moment(row?.settlementDate)?.format("DD-MMM-YYYY")}
                </p>
              ) : (
                <p className="font-size-12 mt-1">Pending</p>
              )}
              <div>
                <Badge
                  className={badgeColor + "info"}
                  color={"bg-secondary"}
                  pill
                  onClick={() => {
                    toggleEarningsModal(row);
                  }}
                  role="button"
                >
                  <i className="mdi mdi-eye " id="viewtooltip" />
                </Badge>
              </div>
            </div>
          </div>
        );
      },
    },
    // {
    //   dataField: "status",
    //   text: "Settle Status",
    //   formatter: (cellContent, row) => {
    //     console.log(row, "roww");

    //     return (
    //       <div>
    //         {row?.settlementStatus === true ? (
    //           <>
    //             <img src={settledImage} alt="" width={"60px"} />
    //           </>
    //         ) : (
    //           <>
    //             <h6 className="fw-bold">Pending</h6>
    //           </>
    //         )}
    //       </div>
    //     );
    //   },
    // },
    // {
    //   dataField: "settlementDate",
    //   text: "Settle Date",
    //   formatter: (cell, row) => (
    //     <>
    //       {" "}
    //       {row?.settlementDate ? (
    //         <>{moment(row?.settlementDate).format("DD-MMM-YYYY")} </>
    //       ) : (
    //         "-"
    //       )}
    //     </>
    //   ),
    // },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: settlementList?.length, // replace later with size(customers),
    custom: true,
  };

  return (
    <>
      <React.Fragment>
        <Spinner loading={loading} />
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <Breadcrumb title="Settlements" breadcrumbItem="settlements List" />

            <div className="mb-2 d-flex align-items-center justify-content-center">
              {settlementList?.length > 0 ? (
                <div className="">
                  <h6 className="fw-bold mb-1 font-size-18 text-uppercase">
                    {access === "individual" ? (
                      <>
                        {settlementList[0]?.stylistId?.legalname
                          ? settlementList[0]?.stylistId?.legalname
                          : ""}{" "}
                        {settlementList[0]?.stylistId?.shortname
                          ? settlementList[0]?.stylistId?.shortname
                          : ""}
                      </>
                    ) : (
                      <>
                        {settlementList[0]?.companyId?.companyName
                          ? settlementList[0]?.companyId?.companyName
                          : ""}{" "}
                      </>
                    )}
                  </h6>
                </div>
              ) : (
                ""
              )}
            </div>
            {access === "individual" ? (
              <>
                <Row>
                  <Col md={5}>
                    <Card className="mb-2 text-center rounded border">
                      <CardBody className="d-flex align-items-center justify-content-center">
                        <h5 className="fw-bold mb-0">Total Tips Earned</h5>
                        <h5 className="mx-1 mb-0">:</h5>
                        <h5 className="mb-0">
                          INR{" "}
                          {TotalTipAmount ? TotalTipAmount?.toFixed(2) : "0"}
                        </h5>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            ) : (
              ""
            )}
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                      keyField="settlementList"
                      columns={customer()}
                      data={settlementList}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="settlementList"
                          data={settlementList}
                          columns={customer()}
                          bootstrap4
                          search
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <div className="d-flex align-items-center justify-content-between mb-2 flex-wrap">
                                <div className="d-flex align-items-center">
                                  <div className="search-box d-inline-block">
                                    <div className="position-relative">
                                      <SearchBar
                                        {...toolkitProps.searchProps}
                                      />
                                      <i className="bx bx-search-alt search-icon" />
                                    </div>
                                  </div>
                                  <div className="d-flex ms-4 align-items-center mb-2">
                                    {checkboxItems()}
                                  </div>
                                </div>
                                <div>
                                  <div className="text-sm-end">
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="-2 me-2"
                                      onClick={() => toggle()}
                                      disabled={
                                        selectedOrders?.length <= 0
                                          ? true
                                          : false
                                      }
                                    >
                                      <i className="bx bx-money font-size-16 align-middle me-2"></i>
                                      Settle Orders
                                    </Button>
                                  </div>
                                </div>
                              </div>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      keyField="settlementList"
                                      responsive
                                      bordered={false}
                                      striped={false}
                                      classes={
                                        "table align-middle table-nowrap table-check"
                                      }
                                      headerWrapperClasses={"table-light"}
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                    />
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-items-md-center mt-30">
                                <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                    {settlementList?.length == 0 && (
                      <p className="text-center"> No Data Found</p>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
      <CreateSettlement
        toggle={toggle}
        modal={modal}
        settledOrders={selectedOrders}
        reload={() => {
          getSettlementsList();
        }}
        emptySelectedOrders={setSelectedOrders}
      />
      <EarningsDetails
        toggle={toggleEarningsModal}
        modal={earningsModal}
        data={earnigsData}
      />
    </>
  );
};

export default SettlementsLists;
