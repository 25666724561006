import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
  CardText,
  CardTitle,
  CardLink,
  Col,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Expertise from "./Expertise";
import Biography from "./Biography";
import Experience from "./Experience";
import Gallery from "./Gallery";

import { Link } from "react-router-dom";
import classnames from "classnames";

const UserTabs = props => {
    const {data}=props
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const toggleCustom = tab => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };
  useEffect(()=>{
      console.log(data)
  },[])
  return (
    <React.Fragment>
      <Nav className="icon-tab nav-justified">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "1",
            })}
            onClick={() => {
              toggleCustom("1");
            }}
          >
            <span className="d-none d-sm-block">
              <i className="fas fa-clipboard-list"></i> Expertise
            </span>
            <span className="d-block d-sm-none">
              <i className="fas fa-clipboard-list"></i>
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "2",
            })}
            onClick={() => {
              toggleCustom("2");
            }}
          >
            <span className="d-none d-sm-block">
              <i className="fas fa-user-tie"></i> Biography
            </span>
            <span className="d-block d-sm-none">
              <i className="fas fa-user-tie"></i>
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "3",
            })}
            onClick={() => {
              toggleCustom("3");
            }}
          >
            <span className="d-none d-sm-block">
              <i className="fas fa-list-alt"></i> Gallery
            </span>
            <span className="d-block d-sm-none">
              <i className="fas fa-list-alt"></i>
            </span>
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classnames({
              active: customActiveTab === "4",
            })}
            onClick={() => {
              toggleCustom("4");
            }}
          >
            <span className="d-none d-sm-block">
              <i className="fas fa-list-alt"></i> Orders
            </span>
            <span className="d-block d-sm-none">
              <i className="fas fa-list-alt"></i>
            </span>
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={customActiveTab} className="p-3 text-muted">
        <TabPane tabId="1">
          <Expertise  data={data?.expertise}/>
        </TabPane>
        <TabPane tabId="2" id="biography">
          <Biography data={data?.bio}/>
        </TabPane>
        <TabPane tabId="3">
         <Gallery id={data?.provider}/>
          
        </TabPane>
        <TabPane tabId="4">
         <Row>
             {data?.bookings&&data.bookings.map(e=> <Col key={e._id} sm={6} md={4} className='mt-3'>
           <Card>
               {console.log(e)}
               <p>{e?.booking?.gender}</p>
               <p>{e?.booking?.time}</p>
               <p>{e?.booking?.serviceid?.name}</p>
           </Card>
                    </Col>)}
         </Row>
          
        </TabPane>
      </TabContent>
    </React.Fragment>
  );
};

export default UserTabs;
