import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import { updateIndvidualFields } from "services/indvidual.service";
import editIcon from "assets/icons-image/edit-icon.svg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Select from "react-select";
import ProfileImage from "components/profileImage";
import Dropzone from "react-dropzone";

//Country-State-Cities
import csc from "countries-states-cities";

//Country Flag with Phone number
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const BasicInfo = ({ basicInitialValues, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [basicInfo, setBasicInfo] = useState(basicInitialValues);
  const [selectedType, setSelectedType] = useState(null);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState([]);
  const [citiesList, setCitiesList] = useState([]);

  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    // { label: "Unisex", value: "Unisex" },
    { label: "Other", value: "Other" },
  ];

  useEffect(() => {
    //Get All Countries List
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
    console.log(basicInfo, "basicInfo");
  }, []);

  function getStatesList(event) {
    let stateArray = [];
    csc.getStatesOfCountry(event.id).forEach(ele => {
      let stateObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      stateArray.push(stateObj);
    });
    return stateArray;
  }

  function getCitiesList(event) {
    let citiesArr = [];
    csc.getCitiesOfState(event.id).forEach(ele => {
      let cityObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      citiesArr.push(cityObj);
    });
    return citiesArr;
  }

  useEffect(() => {
    //Finding Country Object based on Country Label
    let selectedCountry;
    if (basicInitialValues.country) {
      selectedCountry = countriesList.find(
        ele => ele.label === basicInitialValues.country
      );
      console.log(selectedCountry, "selected country");
      let stateList = getStatesList(selectedCountry);
      let selectedState = stateList.find(
        ele => ele.label === basicInitialValues.state
      );
      console.log(selectedState, "selected state");

      let citiesListArr = getCitiesList(selectedState);
      let selectedCity = citiesListArr.find(
        ele => ele.label === basicInitialValues.city
      );
      console.log(selectedCity, "selected city");

      basicInitialValues["country"] = selectedCountry;
      basicInitialValues["state"] = selectedState;
      basicInitialValues["city"] = selectedCity;
      setStatesList(stateList);
      setCitiesList(citiesListArr);
    }

    setBasicInfo(basicInitialValues);
    setSelectedType({
      label: basicInitialValues.gender,
      value: basicInitialValues.gender,
    });
    setIsEdit(false);
    console.log(basicInfo, "basic");
  }, [basicInitialValues]);

  function handleSelectCountry(event) {
    //Get All States Under the Selected Country
    let stateArray = getStatesList(event);
    setStatesList(stateArray);
    let info = { ...basicInfo };
    info["country"] = event;
    info["state"] = null;
    info["city"] = null;
    setBasicInfo({ ...info });
  }

  function handleSelectState(event) {
    //Get All States Under the Selected Country
    let citiesArray = getCitiesList(event);
    setCitiesList(citiesArray);
    let info = { ...basicInfo };
    info["state"] = event;
    info["city"] = null;
    setBasicInfo({ ...info });
  }

  const toggleEdit = () => {
    setBasicInfo(basicInitialValues);
    setIsEdit(!isEdit);
  };
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleSelectType(event) {
    setSelectedType(event);
  }

  const handleChange = (value, name) => {
    let info = { ...basicInfo };
    info[name] = value;
    setBasicInfo(info);
  };

  const save = () => {
    basicInfo["country"] = basicInfo?.country?.label;
    basicInfo["state"] = basicInfo?.state?.label;
    basicInfo["city"] = basicInfo?.city?.label;

    handleSubmit(basicInfo);
    console.log(basicInfo);
    console.log(handleSubmit(basicInfo));
  };

  return (
    <Card className="border-light">
      <CardBody>
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h5>BASIC INFO</h5>
            {!isEdit && (
              <Link
                className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
                to="#"
                onClick={() => toggleEdit()}
              >
                <i className="bx bx-edit-alt bx-xs pe-1"> </i>
                <span> Edit</span>
              </Link>
            )}
          </div>
        </Row>
        <Row>
          <Col md={10}>
            <Row>
              <Col sm={4}>
                <Label htmlFor="legalname" className="col-form-label">
                  First Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="legalname"
                  placeholder="Enter Your First Name"
                  name="legalname"
                  value={basicInfo.legalname}
                  onChange={event =>
                    handleChange(event.target.value, "legalname")
                  }
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={4}>
                <Label htmlFor="shortname" className="col-form-label">
                  Last Name <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="shortname"
                  placeholder="Enter Your Last Name"
                  name="shortname"
                  value={basicInfo.shortname}
                  onChange={event =>
                    handleChange(event.target.value, "shortname")
                  }
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={4}>
                <Label htmlFor="Gender" className="col-form-label">
                  Gender <span className="text-danger">*</span>
                </Label>
                <Select
                  name="gender"
                  id="gender"
                  value={selectedType}
                  onChange={event => {
                    handleSelectType(event);
                    handleChange(event.value, "gender");
                  }}
                  options={typeList}
                  isDisabled={!isEdit}
                  classNamePrefix={
                    "select2-selection " + (isEdit && "dropdown-disabled")
                  }
                />
              </Col>
              <Col sm={4} className="mt-2">
                <Label htmlFor="mobileNumber" className="col-form-label">
                  Mobile Number <span className="text-danger">*</span>
                </Label>
                <PhoneInput
                  className={
                    "custom_country_phoneNumber " +
                    (!isEdit && "phone_number-disabled")
                  }
                  id="mobileNumber"
                  placeholder="Enter Your Mobile Number"
                  name="mobileNumber"
                  international={false}
                  value={basicInfo.mobileNumber}
                  onChange={value => {
                    console.log(value);
                    handleChange(value, "mobileNumber");
                  }}
                />
              </Col>

              <Col sm={4} className="mt-2">
                <Label htmlFor="note" className="col-form-label">
                  Additional Notes
                </Label>
                <div className="">
                  <textarea
                    name="note"
                    className="form-control"
                    id="note"
                    rows={1}
                    onChange={event => handleChange(event.target.value, "note")}
                    value={basicInfo.note}
                    disabled={!isEdit}
                  ></textarea>
                </div>
              </Col>
              <Col sm={4} className="mt-2">
                <Label htmlFor="address" className="col-form-label">
                  Address <span className="text-danger">*</span>
                </Label>
                <textarea
                  name="address"
                  className="form-control"
                  id="address"
                  rows={1}
                  onChange={event =>
                    handleChange(event.target.value, "address")
                  }
                  value={basicInfo.address}
                  disabled={!isEdit}
                ></textarea>
              </Col>

              <Col sm={2} className="mt-2">
                <Label htmlFor="zipcode" className="col-form-label">
                  Zipcode <span className="text-danger">*</span>
                </Label>
                <Input
                  type="text"
                  className="form-control"
                  id="zipcode"
                  placeholder="Enter Your Zipcode"
                  name="zipcode"
                  onChange={event =>
                    handleChange(event.target.value, "zipcode")
                  }
                  value={basicInfo.zipcode}
                  disabled={!isEdit}
                />
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="city" className="col-form-label">
                  city <span className="text-danger">*</span>
                </Label>
                <Select
                  name="city"
                  id="city"
                  onChange={event => {
                    handleChange(event, "city");
                  }}
                  options={citiesList}
                  value={basicInfo.city}
                  isDisabled={!isEdit}
                  classNamePrefix={
                    "select2-selection " + (isEdit && "dropdown-disabled")
                  }
                />
              </Col>

              <Col sm={3} className="mt-2">
                <Label htmlFor="state" className="col-form-label">
                  State <span className="text-danger">*</span>
                </Label>
                <Select
                  name="state"
                  id="state"
                  onChange={event => {
                    handleSelectState(event);
                  }}
                  options={statesList}
                  value={basicInfo.state}
                  isDisabled={!isEdit}
                  classNamePrefix={
                    "select2-selection " + (isEdit && "dropdown-disabled")
                  }
                />
              </Col>

              <Col sm={2} className="mt-2">
                <Label htmlFor="country" className="col-form-label">
                  Country <span className="text-danger">*</span>
                </Label>
                <Select
                  name="country"
                  id="country"
                  onChange={event => {
                    handleSelectCountry(event);
                  }}
                  options={countriesList}
                  value={basicInfo.country}
                  isDisabled={!isEdit}
                  classNamePrefix={
                    "select2-selection " + (isEdit && "dropdown-disabled")
                  }
                />
              </Col>
              <Col sm={2} className=" mt-2">
                <div>
                  <Label htmlFor="since" className="col-form-label">
                    Experience <span className="text-danger">*</span>
                  </Label>
                  <Input
                    type="text"
                    className="form-control"
                    id="since"
                    placeholder=""
                    name="since"
                    onChange={event =>
                      handleChange(event.target.value, "since")
                    }
                    value={basicInfo.since}
                    disabled={!isEdit}
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col md={2} className="ps-2">
            <Row className="mb-2">
              <div className="text-end">
                <div className="profile-div text-end d-inline-flex justify-content-end flex-column">
                  <ProfileImage
                    classNames={"border p-3"}
                    image={basicInfo.image}
                  />
                  {isEdit && (
                    <Dropzone
                      id="validationCustom05"
                      onDrop={acceptedFiles => {
                        acceptedFiles.map(file =>
                          Object.assign(file, {
                            preview: URL.createObjectURL(file),
                            formattedSize: formatBytes(file.size),
                          })
                        );
                        // setFieldValue("image", acceptedFiles[0]);
                        handleChange(acceptedFiles[0], "image");
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="">
                          <div className="" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <button
                              type="button"
                              className="btn btn-primary btn-sm mt-2"
                            >
                              {" "}
                              Upload Profile
                            </button>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  )}
                </div>
              </div>
            </Row>
          </Col>
          <Col md={2}>
            <div className="form-check mt-3">
              <Input
                type="checkbox"
                className="form-check-input"
                id="idVerification"
                value={basicInfo?.idVerification}
                // checked={basicInfo?.idVerification ? checked : false}
                onChange={event => {
                  handleChange(event.target.value, "idVerification");
                }}
              />
              <Label className="form-check-label" htmlFor="idVerification">
                Certified
              </Label>
            </div>
          </Col>
          <Row>
            <FormGroup className="mb-3 px-1 ms-2 mt-2">
              <Label htmlFor="description">
                Description <span className="text-danger">*</span>
              </Label>
              <div className="custom_ck_editor">
                <CKEditor
                  disabled={!isEdit}
                  id="description"
                  name="description"
                  editor={ClassicEditor}
                  data={basicInfo.description ? basicInfo.description : "_"}
                  onReady={editor => {
                    console.log("Editor is ready to use!", editor);
                    if (basicInfo.description) {
                      editor.setData(basicInfo.description);
                    }
                  }}
                  onChange={(event, editor) => {
                    if (isEdit) {
                      const data = editor.getData();
                      handleChange(data, "description");
                    }
                  }}
                  onBlur={(event, editor) => {
                    console.log("Blur.", editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log("Focus.", editor);
                  }}
                />
              </div>
            </FormGroup>
          </Row>
        </Row>
        {isEdit && (
          <Row>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={() => toggleEdit()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="w-md btn-sm ms-2"
                type="button"
                onClick={() => save()}
              >
                Save
              </Button>
            </div>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default BasicInfo;
