import React, { useState } from "react";
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import banner from "./Products/avatar.jpg";
import moment from "moment";
import {
  deleteReview,
  getReviewsById,
} from "services/ecommerce/reviews.service";
import SweetAlert from "react-bootstrap-sweetalert";

export const Reviews = (reviewList, id) => {
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const handleDelete = (reviewId, companyId) => {
    deleteReview(reviewId, companyId)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        getReviewsById(id);
      })
      .catch(err => {
        console.log(err);
        seterror_msg(true);
        setLoading(false);
      });
  };
  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Review deleted
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const renderReviews = () => {
    return reviewList?.reviewList?.map((review, index) => {
      return (
        <div key={index}>
          {review?.comments?.map((ele, ind) => {
            return (
              <Card key={ind} className="border border-0">
                <CardBody>
                  <Row>
                    <Col md="10">
                      <div className="d-flex">
                        <div>
                          <img
                            src={banner}
                            height="50"
                            alt=""
                            className="fit-content rounded-circle avatar-sm  "
                          />
                        </div>
                        <div className="ms-3 mt-2">
                          <span className="h5 ">
                            {ele.user.firstname}
                            &nbsp;{ele.user.lastname}
                          </span>
                          <br />
                          <span className="">
                            {" "}
                            Reviewed on &nbsp;
                            {moment(ele.createdAt).format("MMM Do YYYY")}
                          </span>
                        </div>
                      </div>
                      <div className="ms-5 ps-3 mt-3">
                        <span className="h6">{ele.comment}</span>
                      </div>
                    </Col>
                    <Col md="2" className="mt-2">
                      <div className="float-end ">
                        <Badge
                          type="button"
                          className="font-size-12 badge-soft-danger py-1 px-2 "
                          color={"bg-danger"}
                          pill
                          onClick={() => handleDelete(review.reviewId, ele._id)}
                        >
                          <i className="bx bxs-trash-alt me-1 font-size-10"></i>
                          Delete
                        </Badge>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            );
          })}
          <p></p>
        </div>
      );
    });
  };

  return (
    <React.Fragment>
      <div className="mt-3">
        <Container fluid>
          <div className="mb-3">
            <h5 className="ms-2 text-secondary ">Reviews</h5>
          </div>
          <Row className="">
            <Col className="">
              <Card className="border border-1">
                <CardBody>
                  <div className="">
                    {reviewList?.reviewList?.length > 0
                      ? renderReviews()
                      : "Currently no reviews found"}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </React.Fragment>
  );
};
