import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import Spinner from "components/spinner";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import {
  Container,
  Button,
  Card,
  Row,
  Col,
  CardBody,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Breadcrumbs from "components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";

import { Link } from "react-router-dom";

import { getPages, deletePage } from "services/page.service";
import CreatePage from "./create";

const PageList = props => {
  const { SearchBar } = Search;
  const [list, setList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [activeId, setActiveId] = useState(null);

  useEffect(() => {
    getPackageList();
  }, []);

  const getPackageList = () => {
    getPages()
      .then(res => {
        let { data } = res;
        setList(data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const removeParentCat = id => {
    setLoading(true);
    deletePage(id)
      .then(res => {
        setDelete_modal(false);
        getPackageList();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  // Table Data
  const customer = () => [
    {
      dataField: "title",
      text: "Title",
    },

    {
      dataField: "updatedAt",
      text: "Last Updated",
      formatter: (cell, row) => <>{new Date(row.updatedAt).toDateString()}</>,
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        let editUrl = `/page-update/${row._id}`;
        return (
          <>
            <div className="d-flex gap-3">
              <div
                onClick={() => {
                  setActiveId(row._id);
                  toggle();
                }}
              >
                {/* <Link to={editUrl} className="text-success"> */}
                <i className="mdi mdi-pencil text-success font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
                {/* </Link> */}
              </div>
              <div
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(row._id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: list.length, // replace later with size(customers),
    custom: true,
  };

  // const addParentCategory = () => {
  //   props.history.push("/page-create");
  // };

  const toggle = () => {
    console.log("CLIKCED MODAL", !modal);
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };
  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeParentCat(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="page-content">
        <MetaTags>
          <title>Packages | Package List</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Pages" breadcrumbItem="Page List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="keyField"
                    columns={customer()}
                    data={list}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="keyField"
                        data={list}
                        columns={customer()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4" className="d-flex">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              {/* <Col sm="4">
                              </Col> */}
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={() => {
                                      toggle();
                                    }}
                                  >
                                    <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                    Add Page
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="keyField"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {list?.length == 0 && (
                    <p className="text-center"> No Data Found</p>
                  )}

                  {delete_modal ? deletePopup() : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <CreatePage
        toggle={toggle}
        modal={modal}
        id={activeId}
        reload={getPackageList}
      />
    </React.Fragment>
  );
};

export default PageList;
