import axios from "axios";

export const updateGlobalSettings = body => {
  return axios
    .put(
      `${process.env.REACT_APP_DATABASEURL}/api/v1/globalsettings/update`,
      body
    )
    .then(res => {
      return res.data;
    })
    .catch(e => console.log(`error at updating Global Settings ${e}`));
};

export const getSettings = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/globalsettings/get`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Brand ${e}`));
};
