import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router";
import { useHistory } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  addteam,
  getcompanyById,
  updateCompanyField,
} from "services/company.service";
import { getIndividualByRegNo } from "services/indvidual.service";
import * as moment from "moment";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

const AddMember = ({ toggle, modal, props }) => {
  const { id } = useParams();
  let history = useHistory();
  const [company, setCompany] = useState([]);
  const [regId, setRegId] = useState("");
  const [companyOTP, setCompanyOTP] = useState("");
  const [memberOTP, setMemeberOTP] = useState("");
  const [loading, setLoading] = useState(true);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [c_otp_send, set_c_otp_sent] = useState(false);
  const [i_otp_send, set_i_otp_sent] = useState(false);
  const [companySessionID, setCompanySessionID] = useState("");
  const [memberSessionID, setMemberSessionID] = useState("");
  const [activeProvider, setActiveProvider] = useState({});

  useEffect(() => {
    getcompanyById(id)
      .then(res => {
        if (res.data) {
          setCompany(res.data);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  }, []);

  const leaveToList = () => {
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
  };

  const handleChange = (value, type) => {
    if (type === "company") {
      setCompanyOTP(value);
    } else if (type === "member") {
      setMemeberOTP(value);
    } else {
      setRegId(value);
    }
  };

  const getProviderByRegNo = () => {
    if (regId) {
      getIndividualByRegNo(regId.trim())
        .then(res => {
          if (res.data[0]) {
            sendOtp("ind", res.data[0].mobileNumber);
            setActiveProvider(res.data[0]);
          } else {
            alert("please enter valid REG NO.");
          }
        })
        .catch(e => {
          console.log(e);
        });
    } else {
      alert("please enter RegNo.");
    }
  };

  const handleOTP = type => {
    if (type === "company") {
      sendOtp("company", company.mobileNumber);
    } else {
      getProviderByRegNo();
    }
  };

  const sendOtp = (type, mobileNumber) => {
    setLoading(true);
    const random = Math.floor(1000 + Math.random() * 9000);
    const countrycode = "+91";
    axios
      .get(
        `https://2factor.in/API/V1/${process.env.REACT_APP_SMS_API_KEY}/SMS/${countrycode}${mobileNumber}/${random}`
      )
      .then(async res => {
        setLoading(false);
        if (res.data.Details) {
          if (type === "company") {
            setCompanySessionID(res.data.Details);
            set_c_otp_sent(true);
          } else {
            setMemberSessionID(res.data.Details);
            set_i_otp_sent(true);
          }
        } else {
          alert("Not found Registration Number in our records");
        }
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  const verifyOTP = async (session_id, otp_input) => {
    setLoading(true);

    return await axios.get(
      `https://2factor.in/API/V1/${process.env.REACT_APP_SMS_API_KEY}/SMS/VERIFY/${session_id}/${otp_input}`
    );
  };

  const handleSubmit = async () => {
    company.team.push(activeProvider._id);
    await Promise.all([
      verifyOTP(companySessionID, companyOTP),
      verifyOTP(memberSessionID, memberOTP),
    ])
      .then(otpRes => {
        if (otpRes[0] && otpRes[1]) {
          //Adding team
          addteam(company._id, activeProvider._id)
            .then(res => {
              if (res.success) {
                setsuccess_msg(true);
              } else {
                seterror_msg(true);
              }
              setLoading(false);
            })
            .catch(e => {
              seterror_msg(true);
              console.log(e);
              setLoading(false);
            });
        }
      })
      .catch(e => {
        alert("please enter valid OTP");
        setLoading(false);
      });
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-md mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Team Member &nbsp;{activeProvider?.legalname}&nbsp;
        {activeProvider?.shortname} &nbsp; added on &nbsp;
        {activeProvider?.updatedAt &&
          moment(new Date(activeProvider?.updatedAt)).format("DD MMM Y")}{" "}
        at{" "}
        {activeProvider?.updatedAt &&
          moment(new Date(activeProvider?.updatedAt)).format("hh:mm")}{" "}
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button color="danger" className="w-md btn-sm mx-1">
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>Add Team Member</ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <Card className="view-users">
              <CardBody>
                <div className="d-flex justify-content-between"></div>
                <Row className="my-4">
                  <Col sm={8} className="offset-md-2">
                    <Row className="mb-4">
                      <Label
                        htmlFor="reg_id"
                        className="col-sm-4 col-form-label"
                      >
                        Team Member Registration ID
                      </Label>
                      <Col sm={7}>
                        <Row>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control border-radius-none"
                              id="reg_id"
                              placeholder="Individual Registration Number"
                              name="reg_id"
                              onChange={event => {
                                handleChange(event.target.value, "reg");
                              }}
                              value={regId}
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-4">
                      <Label
                        htmlFor="company_otp"
                        className="col-sm-4 col-form-label"
                      >
                        Company OTP
                      </Label>
                      <Col sm={7}>
                        <Row>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control border-radius-none"
                              id="company_otp"
                              placeholder=""
                              name="company_otp"
                              onChange={event =>
                                handleChange(event.target.value, "company")
                              }
                              value={companyOTP}
                            />
                          </Col>
                          <Col sm={4} className="ps-0">
                            <button
                              className="btn btn-light border-radius-none"
                              onClick={() => handleOTP("company")}
                            >
                              {c_otp_send ? "Resend OTP" : "Send OTP"}
                            </button>
                          </Col>
                        </Row>

                        {c_otp_send && (
                          <p className="mt-2 mb-0">
                            Enter OTP sent to Authorized Company Mobile number
                          </p>
                        )}
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Label
                        htmlFor="ind_otp"
                        className="col-sm-4 col-form-label"
                      >
                        Team Member OTP
                      </Label>

                      <Col sm={7}>
                        <Row>
                          <Col sm={8}>
                            <Input
                              type="text"
                              className="form-control border-radius-none"
                              id="ind_otp"
                              placeholder=""
                              name="ind_otp"
                              onChange={event =>
                                handleChange(event.target.value, "member")
                              }
                              value={memberOTP}
                            />
                          </Col>
                          <Col sm={4} className="ps-0">
                            <button
                              className="btn btn-light border-radius-none"
                              onClick={() => handleOTP("individual")}
                            >
                              {i_otp_send ? "Resend OTP" : "Send OTP"}
                            </button>
                          </Col>
                        </Row>

                        {i_otp_send && (
                          <p className="mt-2 mb-0">
                            Enter OTP sent to Team member Mobile Number
                          </p>
                        )}
                      </Col>
                    </Row>

                    <hr className="mt-4" />
                    <div className="d-flex justify-content-center mb-1 mt-4">
                      <Button
                        color="light"
                        className="btn w-md me-2"
                        onClick={() => toggle()}
                      >
                        Back
                      </Button>
                      <Button
                        color="primary"
                        className="btn w-md ms-2"
                        type="button"
                        onClick={() => {
                          handleSubmit();
                        }}
                      >
                        JOIN
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Container>

          {success_msg ? successPopup() : null}
          {error_msg ? errorPopup() : null}
        </ModalBody>
      </Modal>
    </>
  );
};
export default AddMember;
