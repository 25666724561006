import React, { useState,useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  FormGroup
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ProfileImage from "./../../components/profileImage";

import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import {getProviders} from "services/provider.service"
import {createGallery,updateGallery,getGallerybyId} from "services/gallery.service"

import "./CreateGallery.styles.scss";

const CreateGallery = props => {
  const [selectedFiles, setselectedFiles] = useState([]);
  const [selectedProvider, setselectedProvider] = useState(null);
  const [employeesList,setEmpList] = useState("")
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const { id } = useParams();
  const  initialValues= {
    provider: "",
    description:"<p></p>",
    image:{},
  };
  const validationSchema = Yup.object().shape({
    provider: Yup.string().required("Please Enter Provider"),
    description:Yup.string().required("Please Select Your Status")
});

const onSubmit= (values) => {
    console.log("values", values);
    if(id){
        handleUpdate(values)
    }else{
        handleSubmit(values)

    }
  }

const handleUpdate=values=>{
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    formData.set("image",JSON.stringify(values.image))
    updateGallery(formData,id).then(res=>{
        setsuccess_msg(true);
        seterror_msg(false);
        console.log(res)
    }).catch(e=>{
        setsuccess_msg(false);
        seterror_msg(true);
        console.log(e)
    })
}
  const [values, setValues] = useState({
    provider: null,
    description:"",
    image:{}
  });

  const {
    provider,
    description,
    image,
    formData
  } = values;

  const preload = () => {
   setValues({ ...values, formData: new FormData() });
  };

  useEffect(() => {
    getAllProviders()
  }, []);


  const getAllProviders=()=>{
    getProviders().then(res=>{
        const list = [];
            res.data?.map(type => {
              list.push({ label: type.legalname, value: type._id });
            });
            setEmpList(list);
    }).catch(err=>{
        console.log(err)
    })
  }

  function handleAcceptedFiles(files,name) {
    console.log(files)
  files.map(file =>
    Object.assign(file, {
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size),
    })
  );
  // setselectedFiles(files);
  formData.set(name, files[0]);
  setValues({ ...values, [name]: files[0] });

}

  function handleSelectProvider(selectedProvider) {
    // setValues({ ...values, ["provider"]: selectedProvider.value });
    setselectedProvider(selectedProvider);
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }
  const handleSubmit=(values)=>{
      console.log(values) 
      let formData = new FormData();
      for (let key in values) {
        formData.set(key, values[key]);
      }
      createGallery(formData).then(res=>{
        setsuccess_msg(true);
        seterror_msg(false);
          console.log(res)
      }).catch(e=>{
        setsuccess_msg(false);
        seterror_msg(true);
          console.log(e)
      })
  }
  const leaveToList = () => {
    props.history.push("/gallery-list");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
    props.history.push("/gallery-list");
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully New Provider type created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Form Layouts | stylepro - -
          </title>
        </MetaTags>
        <Container fluid={false}>
          <Breadcrumb title="Gallery" breadcrumbItem="Create Gallery" />
          <Card>
            <CardBody>
            <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  values,
                }) => {
                  useEffect(() => {
                    if (id) {
                        // setLoading(true);
                        getGallerybyId(id)
                        .then(res => {
                          const data = { ...res.data };
                          const fields = [ 
                            "description",
                            "image",
                            ];
                            setFieldValue("provider", data["provider"]._id, false)
                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          
                          setselectedProvider({label:data["provider"].legalname,value:data["provider"]._id})
                          
                        //   setLoading(false);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-4">
                        <Col md={6} className="px-4">
                        <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom07">
                                Description
                              </Label>
                              <div className="custom_ck_editor">
                                <CKEditor
                                  editor={ClassicEditor}
                                  id="validationCustom07"
                                  data={values.description}
                                  onReady={editor => {
                                    // You can store the "editor" and use when it is needed.
                                    // editor.setData(values.description);
                                  }}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("description")(data);
                                  }}
                                />
                              </div>
                              <Label className="statusValidation">
                                {" "}
                                {errors.description ? errors.description : ""}
                              </Label>
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">
                                Image
                              </Label>
                              <Dropzone
                              id="validationCustom06"
                                onDrop={acceptedFiles => {
                                    acceptedFiles.map(file =>
                                        Object.assign(file, {
                                          preview: URL.createObjectURL(file),
                                          formattedSize: formatBytes(file.size),
                                        })
                                      );                                  
                                  setFieldValue("image", acceptedFiles[0]);                                
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="statusValidation">
                                {" "}
                                {errors.image
                                  ? errors.image
                                  : ""}
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                 
                                {values.image && Object.keys(values.image).length > 0 && (
                                  <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                    <div className="p-2">
                                      <Row className="align-items-center">
                                        <Col className="col-auto">
                                          <ProfileImage
                                            image={values?.image}
                                            height="80"
                                            classNames="avatar-sm rounded bg-light"
                                          />
                                        </Col>
                                        <Col>
                                          <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                          >
                                            {values.image.name}
                                          </Link>
                                          <p className="mb-0">
                                            <strong>
                                              {values.image.formattedSize}
                                            </strong>
                                          </p>
                                        </Col>
                                      </Row>
                                    </div>
                                  </Card>
                                )}
                              </div>
                            </FormGroup>
                          </Row>

                         
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom08">Provider</Label>
                              <Select
                                // className="form-control"
                                id="validationCustom08"
                                value={selectedProvider}
                                onChange={event => {
                                    handleSelectProvider(event);

                                  handleChange("provider")(event.value);
                                }}
                                name="type"
                                options={employeesList}

                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.provider ? errors.provider : ""}
                              </Label>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="d-flex justify-content-end">
                        {!id ? (
                          <Button
                            color="primary"
                            type="submit"
                            className="w-md btn-sm me-4"
                            // onClick={saveChanges}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="w-md btn-sm me-4"
                            type="submit"
                            // onClick={editChanges}
                          >
                            Save Changes
                          </Button>
                        )}
                        <Button
                          color="light"
                          className="w-md btn-sm"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik> 
            
            </CardBody>
          </Card>
          {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateGallery;
