import React from "react"
import { Container, Card, CardBody } from "reactstrap"
import "./style.scss"

export const OrderHistory = () => {
  return (
    <React.Fragment>
    
        <Card className=" ">
          <h5 className="p-2 mt-2 ms-2">Binding History</h5>
          <CardBody className="border-top border-3">
            {/* order history  */}
            <div className="wrapper">
              <ul className="StepProgress">
                <div className="StepProgress-item is-done">
                  <p>
                    Order biding started
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item is-done">
                  <p>
                    Order assign to the professional <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item is-done">
                  <p>
                    4 Stylist quoted price
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item is-done">
                  <p>
                    Customer accepted quoted
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item current">
                  <p>
                    Order is confirmed
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
              </ul>
            </div>
          </CardBody>
        </Card>
        <Card className=" ">
          <h5 className="p-2 mt-2 ms-2">Order History</h5>
          <CardBody className="border-top border-3">
            {/* order history  */}
            <div className="wrapper">
              <ul className="StepProgress">
                <div className="StepProgress-item is-done">
                  <p>
                    Order biding started
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item is-done">
                  <p>
                    Order assign to the professional <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item is-done">
                  <p>
                    4 Stylist quoted price
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item is-done">
                  <p>
                    Customer accepted quoted
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
                <div className="StepProgress-item current">
                  <p>
                    Order is confirmed
                    <br />
                    <small className="">2022-3-4 04:12:32</small>
                  </p>
                </div>
              </ul>
            </div>
          </CardBody>
        </Card>
     
    </React.Fragment>
  )
}
