import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createNewProduct,
  empltyProductById,
  getProductById,
} from "store/actions";

export const TagsTab = ({
  defaultValues,
  handleChangeProperties,
  handleUpdateState,
  handleSubmit,
  stepperActiveTab,
}) => {
  const route = useParams();
  const product = useSelector(state => state?.EcomProducts?.product);
  const [values, setValues] = useState(product);
  const [input, setInput] = useState("");
  const [keys, setKeys] = useState(product?.keyWords);
  const dispatch = useDispatch();

  useEffect(() => {
    if (product) {
      setKeys(product?.keyWords);
    }
    setValues(product);
  }, [product]);

  const handleClick = () => {
    let keysList = [...keys, input];
    setKeys(prev => [...prev, input]);
    setInput("");
    handleUpdateState("keyWords", keys);
  };

  const handleDelete = index => {
    const newKeys = [...keys];
    newKeys.splice(index, 1);
    setKeys(newKeys);
  };
  const saveValues = () => {
    let body = {
      keyWords: keys,
    };

    let basicNewValues = Object.assign(values, body);
    if (route?.catId) {
      dispatch(createNewProduct(basicNewValues));
      stepperActiveTab("6");
    } else handleSubmit(basicNewValues);
  };

  return (
    <div>
      <Card>
        <div className="d-flex   bg-light px-2 py-1 align-items-center justify-content-between ">
          <h5 className="mb-0 text-dark">Keywords </h5>
          <div className=" d-flex justify-content-between align-items-center">
            <Input
              value={input}
              onInput={e => setInput(e.target.value)}
              placeholder="Enter Keyword"
            />
            <button
              type=""
              className="addNew-btn btn btn-md me-2 mt-2 text-info"
              onClick={() => handleClick()}
            >
              Add
            </button>
          </div>
        </div>
        <CardTitle></CardTitle>{" "}
        <CardBody>
          <div>
            <ul className="d-flex flex-wrap mx-0 px-0">
              {keys?.map((ele, ind) => {
                return (
                  <div className="" key={ind}>
                    <span className="me-3 ">
                      <span className=" h6 border border-1 bg-light py-1 px-2 ">
                        {ele}
                      </span>

                      <span
                        role="button"
                        onClick={() => handleDelete(ind)}
                        className="key-del-btn h6 border border-1  text-danger  py-1 px-2 "
                      >
                        X
                      </span>
                    </span>
                  </div>
                );
              })}
            </ul>
          </div>
          <hr className="mt-5" />
          <div className="d-flex mt-2 justify-content-end float-end">
            {route?.catId ? (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => saveValues()}
                >
                  Next <i className="bx bx-right-arrow-alt mx-2"></i> FAQS
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => saveValues()}
                >
                  Save Changes
                </Button>
              </>
            )}
          </div>
        </CardBody>
      </Card>{" "}
    </div>
  );
};
