import React, { useEffect, useState } from "react";
import { Button, Card, CardBody, Col, Input, Label, Row } from "reactstrap";
import Select, { components } from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getFilters } from "services/filters.service";
import { useParams } from "react-router-dom";
import { updateProduct } from "store/actions";

export const BrandFilters = ({ stepperActiveTab, handleSubmit }) => {
  const storeProduct = useSelector(state => state?.EcomProducts?.product);
  const [product, setProduct] = useState(storeProduct);
  const [allFilters, setAllFilters] = useState([]);
  const [brandFilters, setBrandFilters] = useState(null);
  // const brandId = product?.brand?._id;
  const [brandId, setBrandId] = useState();
  const route = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (storeProduct) {
      setProduct(storeProduct);
      setBrandId(storeProduct?.brand?._id);
    }
  }, [storeProduct]);

  useEffect(() => {
    if (brandId) {
      if (!brandFilters) {
        let body = {
          brand: brandId,
        };
        getFiltersByBrandId(body);
      }
    }
  }, [product]);

  useEffect(() => {
    let brandsFilters = brandFilters ? brandFilters : [];
    let tempArr = [...brandsFilters];
    buildOptions(tempArr);
  }, [brandFilters]);

  const getFiltersByBrandId = body => {
    getFilters(body)
      .then(res => {
        if (res?.data) {
          if (res?.data[0] && res.data[0]["brand"] && res.data[0]["filters"]) {
            setBrandFilters(res.data[0]["filters"]);
          }
        }
      })
      .catch(err => {
        console.log(err);
      });
  };

  const buildOptions = tempFilters => {
    let selectedFilters = product.filters;
    let filtersArr = tempFilters;
    const result = filtersArr?.map(ele => {
      let selectedFilter = selectedFilters?.find(
        flr => flr.name === ele.filterName && flr.filterType === "brand"
      );
      if (selectedFilter) {
        ele["selectedOption"] = selectedFilter.value?.map(ele => {
          let obj = {
            label: ele,
            value: ele,
          };
          return obj;
        });
      }

      ele?.options?.map(opt => {
        let optionObj = opt;
        optionObj["label"] = opt?.optionName;
        optionObj["value"] = opt?.optionName;
        return optionObj;
      });
      return ele;
    });
    setAllFilters(result);
  };

  const handleSelectOptions = (name, optionName) => {
    let filtersArr = JSON.parse(JSON.stringify(allFilters));
    filtersArr?.map(ele => {
      if (ele?.filterName === name) {
        ele["selectedOption"] = optionName;
      }
      return ele;
    });
    setAllFilters(filtersArr);
  };

  const handleSubmitValues = () => {
    let tempArr = [];
    let selectedFilters = product.filters;
    let CatFilters = selectedFilters.filter(ele => {
      if (!ele.filterType || ele.filterType !== "brand") {
        return ele;
      }
    });
    allFilters?.map(ele => {
      let obj = {
        name: ele?.filterName,
        value: ele?.selectedOption?.map(ele => ele?.value),
        filterType: "brand",
      };
      tempArr.push(obj);
    });

    let body = {
      filters: [...CatFilters, ...tempArr],
    };
    if (route?.catId) {
      dispatch(updateProduct(body));
      stepperActiveTab("5");
    } else {
      let basicNewValues = Object.assign(product, body);
      handleSubmit(basicNewValues);
    }
  };

  return (
    <div>
      <Label className="h5">Brand Filters</Label>
      <Card>
        <CardBody>
          <div className="">
            {allFilters?.length > 0 ? (
              allFilters?.map((ele, index) => {
                return (
                  <div key={index} className="mb-3">
                    <Row className="mx-2 my-3">
                      <Col className="" md={6}>
                        <Label for="name">Filter name</Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={ele?.filterName}
                          disabled
                        />
                      </Col>
                      <Col md={6}>
                        <Label for="options">
                          Options<span className="text-danger">*</span>
                        </Label>
                        <Select
                          id="options"
                          isMulti
                          options={ele?.options || []}
                          value={ele?.selectedOption}
                          onChange={event => {
                            handleSelectOptions(ele?.filterName, event);
                          }}
                        />
                      </Col>
                    </Row>
                  </div>
                );
              })
            ) : (
              <div className=" py-3">
                <h5>No results found</h5>
              </div>
            )}
          </div>
          <hr className="mt-5" />
          <div className="d-flex mt-2 justify-content-end float-end">
            {route?.catId ? (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2 align-items-center"
                  onClick={() => handleSubmitValues()}
                >
                  Next <i className="bx bx-right-arrow-alt mx-2"></i> Keywords
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => handleSubmitValues()}
                >
                  Save Changes
                </Button>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
