import { CKEditor } from "@ckeditor/ckeditor5-react";
import ProfileImage from "components/profileImage";
import Spinner from "components/spinner";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  UncontrolledCollapse,
} from "reactstrap";
import Select from "react-select";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import {
  createArticle,
  getArticleById,
  getArticlesByAuthor,
  updateArticleById,
} from "services/article.service";
import formatBytes from "utils/formatBytes";
import { object } from "prop-types";
import getImageUrl from "utils/getImageUrl";
import {
  getSubCategoryByParent,
  getParentCategory,
  getCategoriesByMultiParent,
  getMainCategory,
  getParentCategoryByMain,
} from "services/ecommerce/category.service";
import {
  getProductByBrands,
  getProductsByMultiCategory,
} from "services/ecommerce/product.service";
import { getProductByCatId } from "services/product.service";
import { getCategories } from "services/category.service";
import { getServicesByCategories } from "services/services.service";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import { getBrands } from "services/ecommerce/brand.service";

export const CreateArticle = ({
  toggle,
  modal,
  defaultValues,
  id,
  idValues,
}) => {
  const [relatedProduct, setRelatedProduct] = useState();
  const [relatedService, setRelatedService] = useState();
  const [productsList, setProductsList] = useState([]);
  const [servicesList, setServicesList] = useState([]);
  const [productParent, setProductParent] = useState([]);
  const [productMain, setProductMain] = useState([]);
  const [productCat, setProductCat] = useState([]);
  const [relatedServiceList, setRelatedServiceList] = useState();
  const [serviceCat, setServiceCat] = useState([]);
  const [selectedProductCat, setSelectedProductCat] = useState([]);
  const [selectedServiceCat, setSelectedServiceCat] = useState([]);
  const [selectedProductParent, setSelectedProductParent] = useState([]);
  const [selectedProductMain, setSelectedProductMain] = useState([]);
  const [selectedArticles, SetSelectedArticle] = useState([]);
  const [showArticles, setShowArticles] = useState([]);
  const [allProducts, setAllProducts] = useState([]);
  const [allArticles, setAllArticles] = useState([]);
  const [images, setImages] = useState([]);
  const [featuredImage, setFeaturedImage] = useState("");
  const [initialValues, setInitialValues] = useState();
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [brandsList, setBrandsList] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  //display values on edit
  const [showProParent, setShowProParent] = useState();
  const [showProMain, setShowProMain] = useState({});
  const [showProCat, setShowProCat] = useState();
  const [showRelProducts, setShowRelProducts] = useState();
  const [showSerCat, setShowSerCat] = useState();
  const [showRelServices, showSetRelService] = useState();
  const [showBrands, setShowBrands] = useState();
  const typeList = [
    { label: "Article", value: "Article" },
    { label: "Banner", value: "Banner" },
  ];
  const statuList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  useEffect(() => {
    getAllCategories();
    getAllMainCategories();
    getBrandsList();
  }, [modal]);

  useEffect(() => {
    if (id) {
      getAllProductsByCategories({ category: idValues?.product?.categories });
      getAllCategoriesByParent({
        parentCategory: idValues?.product?.parentCategory,
      });
      getAllServicesByCategories({ categories: idValues?.service?.categories });
      getRelatedArticles({
        authorname: idValues.authorname,
      });
    } else {
      setShowArticles(null);
      setShowProCat(null);
      setShowProMain(null);
      setShowProParent(null);
      setShowSerCat(null);
      setShowRelProducts(null);
      setShowBrands(null);
      showSetRelService(null);
      setImages([]);
      setFeaturedImage("");
    }
  }, [modal]);

  useEffect(() => {
    id ? setInitialValues(idValues) : setInitialValues(defaultValues);
  }, [modal, id]);

  //PRODUCT
  const getAllMainCategories = () => {
    getMainCategory()
      .then(res => {
        setProductMain(buildOptions(res?.data));
      })
      .catch(err => console.log(err));
  };

  const getAllParentByMain = id => {
    getParentCategoryByMain(id)
      .then(res => {
        setProductParent(buildOptions(res?.data));
      })
      .catch(err => console.log(err));
  };

  //SEVICES
  const getAllCategories = () => {
    getCategories()
      .then(res => {
        if (res) {
          setServiceCat(buildOptions(res));
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getAllServicesByCategories = obj => {
    getServicesByCategories(obj)
      .then(res => {
        setRelatedServiceList(buildOptions(res?.data));
      })
      .catch(err => console.log(err));
  };
  const getAllCategoriesByParent = obj => {
    getCategoriesByMultiParent(obj)
      .then(res => {
        setProductCat(buildOptions(res?.data));
      })
      .catch(err => console.log(err));
  };

  const getAllProductsByCategories = obj => {
    getProductsByMultiCategory(obj)
      .then(res => {
        setAllProducts(buildOptions(res?.data));
      })
      .catch(err => console.log(err));
  };
  const getRelatedArticles = obj => {
    getArticlesByAuthor(obj)
      .then(res => {
        setAllArticles(buildArticleOptions(res?.data));
      })
      .catch(err => console.log(err));
  };
  const getBrandsList = () => {
    getBrands()
      .then(res => {
        if (res.data) {
          setBrandsList(buildOptions(res?.data));
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getProductsList = body => {
    getProductByBrands(body)
      .then(res => {
        if (res?.data) {
          setAllProducts(buildOptions(res?.data));
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const buildOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele._id,
      };
      return obj;
    });
    return result;
  };
  const buildArticleOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.title,
        value: ele._id,
      };
      return obj;
    });
    return result;
  };

  function handleRelated(selectedMulti, type) {
    const data = [];
    selectedMulti?.forEach(e => {
      data.push(e.value);
    });
    if (type == "products") {
      setProductsList(data);
    } else {
      setServicesList(data);
    }
  }
  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const onSubmit = values => {
    let obj = {
      product: {
        mainCategory: selectedProductMain,
        parentCategory: selectedProductParent,
        categories: selectedProductCat,
      },
      service: {
        categories: selectedServiceCat,
      },
      ["brands"]: selectedBrands,
      ["pages"]: inputs,
      ["featuredImage"]: featuredImage,
      ["relatedProducts"]: relatedProduct,
      ["relatedServices"]: relatedService,
      ["relatedArticles"]: selectedArticles,
      ["images"]: images,
    };
    let formValues = {
      ...values,
      ...obj,
    };

    setLoading(true);
    if (id) {
      updateArticleById(id, formValues)
        .then(res => {
          if (res?.data) {
            setsuccess_msg(true);
          } else {
            seterror_msg(true);
          }
          setLoading(false);
          // toggle();
        })
        .catch(e => {
          console.log(e);
          seterror_msg(true);
          setLoading(false);
        });
    } else
      createArticle(formValues)
        .then(res => {
          if (res?.data) {
            setsuccess_msg(true);
          } else {
            seterror_msg(true);
          }
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          seterror_msg(true);
          setLoading(false);
        });
  };

  const [inputs, setInputs] = useState([""]);

  const handleInputChange = (index, event) => {
    const values = [...inputs];
    values[index] = event.target.value;
    setInputs(values);
  };

  const handleAddInput = () => {
    setInputs([...inputs, ""]);
  };

  const handleRemoveInput = index => {
    const values = [...inputs];
    values.splice(index, 1);
    setInputs(values);
  };

  return (
    <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
      <ModalHeader toggle={toggle}>
        {id ? `Edit - ${idValues?.title}` : `Create an Article`}
      </ModalHeader>
      <ModalBody className="bg-light">
        <Container fluid>
          <div>
            <Formik
              enableReinitialize
              initialValues={initialValues}
              onSubmit={onSubmit}
            >
              {({ errors, touched, handleChange, handleBlur, values }) => {
                useEffect(() => {
                  if (id) {
                    getArticleById(id)
                      .then(res => {
                        const data = { ...res.data };
                        setShowProParent(
                          buildOptions(data?.product?.parentCategory)
                        );
                        setShowProMain({
                          value: data?.product?.mainCategory?._id,
                          label: data?.product?.mainCategory?.name,
                        });
                        setShowProCat(buildOptions(data?.product?.categories));
                        setShowRelProducts(buildOptions(data?.relatedProducts));
                        setShowSerCat(buildOptions(data?.service?.categories));
                        showSetRelService(buildOptions(data?.relatedServices));
                        setShowArticles(
                          buildArticleOptions(data?.relatedArticles)
                        );
                        setShowBrands(buildOptions(data?.brands));
                        setInputs(data?.pages);
                        setImages(data?.images);
                        setFeaturedImage(data?.featuredImage);
                        setSelectedProductMain(data?.product?.mainCategory);
                        setSelectedProductParent(data?.product?.parentCategory);
                        setSelectedProductCat(data?.product?.categories);
                        setSelectedServiceCat(data?.service?.categories);
                        SetSelectedArticle(data?.relatedArticles);
                        setRelatedProduct(data?.relatedProducts);
                        setRelatedService(data?.relatedServices);
                      })
                      .catch(e => {
                        console.log(e);
                      });
                  }
                }, []);
                return (
                  <Form>
                    <Row>
                      <Col md="12" className="p-0">
                        <Card className="p-0">
                          <CardBody>
                            <FormGroup>
                              <Col md={3}>
                                <Label htmlFor="validationCustom01">Type</Label>
                                <Select
                                  id="type"
                                  value={{
                                    label: values?.type,
                                    value: values?.type,
                                  }}
                                  onChange={event => {
                                    handleChange("type")(event.value);
                                  }}
                                  name="type"
                                  options={typeList}
                                  classNamePrefix="select2-selection"
                                />{" "}
                              </Col>
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Title</Label>
                              <Input
                                name="title"
                                placeholder="Title"
                                type="text"
                                className="form-control"
                                id="title"
                                value={values.title}
                                onChange={e => {
                                  handleChange("title")(e.target.value);
                                }}
                                onBlur={handleBlur}
                                invalid={
                                  touched.title && errors.title ? true : false
                                }
                              />
                            </FormGroup>
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Author Name
                              </Label>
                              <Input
                                name="authorname"
                                placeholder="Author Name"
                                type="text"
                                className="form-control"
                                id="authorname"
                                value={values.authorname}
                                onChange={e => {
                                  handleChange("authorname")(e.target.value);
                                }}
                                onBlur={handleBlur}
                                invalid={
                                  touched.name && errors.name ? true : false
                                }
                              />
                            </FormGroup>

                            <Card>
                              <CardBody className="p-0">
                                <FormGroup className="mb-3">
                                  <Row>
                                    <Col md={3}>
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">
                                          Status
                                        </Label>
                                        <Select
                                          id="Status"
                                          value={{
                                            label: values?.status,
                                            value: values?.status,
                                          }}
                                          onChange={event => {
                                            handleChange("status")(event.value);
                                          }}
                                          name="Status"
                                          options={statuList}
                                          classNamePrefix="select2-selection"
                                        />{" "}
                                      </FormGroup>
                                    </Col>
                                    <Col md={3}>
                                      {" "}
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom01">
                                          Order
                                        </Label>
                                        <Input
                                          name="order"
                                          placeholder="Order"
                                          type="text"
                                          className="form-control"
                                          id="order"
                                          onChange={e => {
                                            handleChange("order")(
                                              e.target.value
                                            );
                                          }}
                                          value={values?.order}
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col sm={3} className="">
                                      <Label htmlFor="validationCustom06">
                                        Banner Image
                                      </Label>
                                      <Dropzone
                                        onDrop={async acceptedFiles => {
                                          acceptedFiles.map(file =>
                                            Object.assign(file, {
                                              preview:
                                                URL.createObjectURL(file),
                                              formattedSize: formatBytes(
                                                file.size
                                              ),
                                            })
                                          );

                                          const imgUrl = await getImageUrl({
                                            image: acceptedFiles,
                                          });

                                          const images = [];
                                          const imgArray = [...images];
                                          imgArray.push(imgUrl);
                                          setImages(imgArray);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone ser_img_drop min-vh-0">
                                            <div
                                              className="dz-message needsclick "
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="">
                                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                                              </div>
                                              <h6>
                                                Drop files here or click to
                                                upload.
                                              </h6>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                    </Col>
                                    <Col sm={1} className="custom_ck_editor">
                                      <div className="mt-4 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={images[0]}
                                                height="50"
                                                width="50"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm={3} className="">
                                      <Label htmlFor="validationCustom06">
                                        Featured Image
                                      </Label>
                                      <Dropzone
                                        onDrop={async acceptedFiles => {
                                          acceptedFiles.map(file =>
                                            Object.assign(file, {
                                              preview:
                                                URL.createObjectURL(file),
                                              formattedSize: formatBytes(
                                                file.size
                                              ),
                                            })
                                          );
                                          const imgUrl = await getImageUrl({
                                            image: acceptedFiles,
                                          });
                                          setFeaturedImage(imgUrl);
                                        }}
                                      >
                                        {({ getRootProps, getInputProps }) => (
                                          <div className="dropzone ser_img_drop">
                                            <div
                                              className="dz-message needsclick "
                                              {...getRootProps()}
                                            >
                                              <input {...getInputProps()} />
                                              <div className="">
                                                <i className="display-6 text-muted bx bxs-cloud-upload" />
                                              </div>
                                              <h6>
                                                Drop files here or click to
                                                upload.
                                              </h6>
                                            </div>
                                          </div>
                                        )}
                                      </Dropzone>
                                    </Col>
                                    <Col sm={1} className="custom_ck_editor">
                                      <div className="mt-4 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                        <div className="p-2">
                                          <Row className="align-items-center">
                                            <Col className="col-auto">
                                              <ProfileImage
                                                image={featuredImage}
                                                height="50"
                                                width="50"
                                                classNames="avatar-sm rounded bg-light"
                                              />
                                            </Col>
                                          </Row>
                                        </div>
                                      </div>
                                    </Col>
                                  </Row>
                                </FormGroup>
                              </CardBody>
                            </Card>
                            <Card>
                              <CardBody className="p-0">
                                <div className="">
                                  <Form>
                                    <FormGroup className="mb-3">
                                      <Label htmlFor="validationCustom01">
                                        Video URL
                                      </Label>
                                      <Input
                                        name="video"
                                        placeholder="Video URL"
                                        type="text"
                                        className="form-control"
                                        id="video"
                                        value={values.video}
                                        onChange={e => {
                                          handleChange("video")(e.target.value);
                                        }}
                                        onBlur={handleBlur}
                                      />
                                    </FormGroup>
                                  </Form>
                                </div>
                                <div className="">
                                  <h6>Short Description</h6>
                                </div>
                                <div className="mb-3">
                                  <Card style={{ border: "0px" }}>
                                    <CardBody className="p-0">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={values?.shortDescription}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          handleChange("shortDescription")(
                                            data
                                          );
                                        }}
                                      />
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="">
                                  <h6> Description</h6>
                                </div>
                                <div className="mb-3">
                                  <Card style={{ border: "0px" }}>
                                    <CardBody className="p-0">
                                      <CKEditor
                                        editor={ClassicEditor}
                                        data={values?.description}
                                        onChange={(event, editor) => {
                                          const data = editor.getData();
                                          handleChange("description")(data);
                                        }}
                                      />
                                    </CardBody>
                                  </Card>
                                </div>
                              </CardBody>
                            </Card>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                    <Row>
                      <div className="d-flex justify-content-between">
                        <label className="px-0">Related Products</label>
                      </div>
                      <Card className="p-0">
                        <CardBody>
                          <Row className="mb-3">
                            <Col>
                              {" "}
                              <Label
                                htmlFor="parentCat"
                                className=" col-form-label"
                              >
                                Main Category
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="parentCat"
                                id="parentCat"
                                value={showProMain}
                                placeholder="Select..."
                                onChange={event => {
                                  setShowProMain(event);
                                  setSelectedProductMain(event.value);
                                  getAllParentByMain(event.value);
                                }}
                                options={productMain}
                              />
                            </Col>
                            <Col md={6}>
                              {" "}
                              <Label
                                htmlFor="parentCat"
                                className=" col-form-label"
                              >
                                Parent Category
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="parentCat"
                                id="parentCat"
                                value={showProParent}
                                isMulti={true}
                                placeholder="Select..."
                                onChange={event => {
                                  const data = [];
                                  event?.forEach(e => {
                                    data.push(e.value);
                                  });
                                  setShowProParent(event);
                                  setSelectedProductParent(data);
                                  let obj = { parentCategory: data };
                                  getAllCategoriesByParent(obj);
                                }}
                                options={productParent}
                              />
                            </Col>
                            <Col md={6}>
                              {" "}
                              <Label
                                htmlFor="subCat"
                                className=" col-form-label"
                              >
                                Sub Category
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="subcat"
                                id="subCat"
                                value={showProCat}
                                isMulti={true}
                                placeholder="Select..."
                                onChange={event => {
                                  const data = [];
                                  event?.forEach(e => {
                                    data.push(e.value);
                                  });
                                  setShowProCat(event);
                                  setSelectedProductCat(data);
                                  getAllProductsByCategories({
                                    category: data,
                                  });
                                }}
                                options={productCat}
                              />
                            </Col>
                            <Col md={6}>
                              {" "}
                              <Label
                                htmlFor="subCat"
                                className=" col-form-label"
                              >
                                Brands
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="subcat"
                                id="subCat"
                                value={showBrands}
                                isMulti={true}
                                placeholder="Select..."
                                onChange={event => {
                                  const data = [];
                                  event?.forEach(e => {
                                    data.push(e.value);
                                  });
                                  getProductsList({ brands: data });
                                  setSelectedBrands(data);
                                  setShowBrands(event);
                                }}
                                options={brandsList}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <Label htmlFor="subCat" className=" col-form-label">
                              Related Products
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="subcat"
                              id="subCat"
                              value={showRelProducts}
                              isMulti={true}
                              placeholder="Select..."
                              onChange={event => {
                                handleRelated(event, "products");
                                const data = [];
                                event?.forEach(e => {
                                  data.push(e.value);
                                });
                                setShowRelProducts(event);
                                setRelatedProduct(data);
                              }}
                              options={allProducts}
                            />
                          </Row>
                        </CardBody>
                      </Card>
                    </Row>
                    <Row>
                      <div className="d-flex justify-content-between">
                        <label className="px-0">Related Services</label>
                      </div>
                      <Card className="p-0">
                        <CardBody>
                          <Row className="mb-3">
                            <Col>
                              {" "}
                              <Label
                                htmlFor="subCat"
                                className=" col-form-label"
                              >
                                Service Sub Category
                                <span className="text-danger">*</span>
                              </Label>
                              <Select
                                name="subcat"
                                id="subCat"
                                value={showSerCat}
                                isMulti={true}
                                placeholder="Select..."
                                onChange={event => {
                                  const data = [];
                                  event?.forEach(e => {
                                    data.push(e.value);
                                  });
                                  setShowSerCat(event);
                                  setSelectedServiceCat(data);
                                  getAllServicesByCategories({
                                    categories: data,
                                  });
                                }}
                                options={serviceCat}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Label htmlFor="subCat" className=" col-form-label">
                              Related Services
                              <span className="text-danger">*</span>
                            </Label>
                            <Select
                              name="subcat"
                              id="subCat"
                              value={showRelServices}
                              isMulti={true}
                              placeholder="Select..."
                              onChange={event => {
                                handleRelated(event, "services");
                                const data = [];
                                event?.forEach(e => {
                                  data.push(e.value);
                                });
                                showSetRelService(event);
                                setRelatedService(data);
                              }}
                              options={relatedServiceList}
                            />
                          </Row>
                        </CardBody>
                      </Card>
                    </Row>
                    <Row>
                      <div className="d-flex justify-content-between">
                        <label className="px-0">Where to display</label>
                      </div>
                      <Card>
                        <CardBody className="px-2 py-3">
                          {inputs?.map((input, index) => (
                            <div key={index} className="d-flex my-2">
                              <Input
                                type="text"
                                value={input}
                                onChange={event =>
                                  handleInputChange(index, event)
                                }
                                // placeholder="ex .  /Makeup/Eyes/Eyeliner"
                                name="pageUrl"
                              />
                              <Button
                                type="button"
                                onClick={() => handleRemoveInput(index)}
                                className="btn btn-sm btn-danger ms-2"
                              >
                                <i
                                  className="mdi mdi-delete font-size-18"
                                  id="deletetooltip"
                                />
                              </Button>
                            </div>
                          ))}
                          <Button
                            className="btn btn-sm btn-primary my-2 w-md"
                            color="primary"
                            onClick={handleAddInput}
                          >
                            Add
                          </Button>
                        </CardBody>
                      </Card>
                    </Row>
                    <Row>
                      <div className="d-flex justify-content-between">
                        <label className="px-0">Link for Article</label>
                      </div>
                      <Card>
                        <CardBody className="px-2 py-3">
                          <div className="d-flex my-2">
                            <Input
                              type="text"
                              value={values?.articleLink}
                              onChange={event =>
                                handleChange("articleLink")(
                                  event?.target?.value
                                )
                              }
                              // placeholder="ex .  /Makeup/Eyes/Eyeliner"
                              name="articleLink"
                            />
                          </div>
                        </CardBody>
                      </Card>
                    </Row>
                    <Row>
                      {" "}
                      <div className="d-flex justify-content-between">
                        <label className="px-0">Related Articles</label>
                      </div>
                      <Card>
                        <CardBody className="p-2 py-4">
                          <Col>
                            <Select
                              name="relatedArticles"
                              id="relatedArticles"
                              value={showArticles}
                              isMulti={true}
                              placeholder="Select..."
                              onChange={event => {
                                const data = [];
                                event?.forEach(e => {
                                  data.push(e.value);
                                });
                                setShowArticles(event);
                                SetSelectedArticle(data);
                              }}
                              options={allArticles}
                            />
                          </Col>
                        </CardBody>
                      </Card>
                    </Row>
                    {/* <div className="d-flex justify-content-end">
                      <Button
                        color="light"
                        className="w-md btn-sm"
                        onClick={leaveToList}
                      >
                        cancel
                      </Button>
                      {!id ? (
                        <Button
                          color="primary"
                          type="submit"
                          className="w-md btn-sm me-4"
                          // onClick={saveChanges}
                        >
                          Submit
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          className="w-md btn-sm me-4"
                          type="submit"
                          // onClick={editChanges}
                        >
                          Save Changes
                        </Button>
                      )}
                    </div> */}
                    <div className="float-end">
                      <Button
                        color="primary"
                        className="w-md btn-sm "
                        type="submit"
                        onClick={() => {
                          // onSubmit(values);
                        }}
                      >
                        Save Changes
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Container>
        {success_msg ? (
          <SuccessAlert
            msg={"Successfully Article Updated"}
            leaveToList={leaveToList}
            stayHere={stayHere}
          />
        ) : null}
        {error_msg ? (
          <ErrorAlert
            msg={"error"}
            leaveToList={leaveToList}
            stayHere={stayHere}
          />
        ) : null}
      </ModalBody>
    </Modal>
  );
};
export default CreateArticle;
