import React from "react";
import { useState, useRef, useEffect } from "react";
import Select from "react-select";
import {
  Button,
  Card,
  Row,
  Col,
  Label,
  Input,
  FormGroup,
  CardBody,
  CardTitle,
} from "reactstrap";
import createCombinations from "./helper";
import { getVariants } from "services/ecommerce/variant.service";
import { map } from "lodash";
import ProfileImage from "components/profileImage";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import formatBytes from "utils/formatBytes";
import banner from "./PicUpload.jpg";
import { merge } from "lodash";

const Variants = ({ handleChangeProperties, data, handleUpdateMulti }) => {
  const [formValues, setFormValues] = useState([
    { variant: "", options: [], selectedOptions: [] },
  ]);
  const [variantsOptions, setVariantsOptions] = useState([]);
  const [variantsList, setVariantsList] = useState([]);

  useEffect(() => {
    getVariants()
      .then(res => {
        if (res.data) {
          let options = res.data;
          setVariantsOptions(options);
          setOptionsList();
        }
      })
      .catch(err => console.log(err));
  }, [data]);

  useEffect(() => {
    if (data) {
      let tempData = JSON.parse(JSON.stringify(data));
      if (tempData[0]?.combos) {
        setVariantsList(tempData[0]?.combos || []);
        setFormValues(tempData[0]?.variants || []);
        setOptionsList();
      }
    }
  }, [data]);

  useEffect(() => {
    setOptionsList();
  }, [variantsOptions]);

  const setOptionsList = () => {
    if (variantsOptions.length > 0) {
      let newFormValues = [...formValues];
      const tempFormVal = newFormValues?.map(item => {
        const getvarOptions =
          variantsOptions?.find(opt => opt.name === item.variant) || {};
        let options = [];
        getvarOptions?.variants?.forEach(ele => {
          options.push({ ...ele, label: ele.value });
        });

        item["options"] = options;
        return item;
      });
      setFormValues(tempFormVal);
    }
  };

  let handleChangeVariant = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i]["variant"] = e.target.value;
    const currentVarients = variantsOptions.find(
      variant => variant.name === e.target.value
    );
    let options = [];
    currentVarients.variants.forEach(ele => {
      options.push({ ...ele, label: ele.value });
    });
    newFormValues[i]["options"] = options;
    newFormValues[i]["selectedOptions"] = [];
    setFormValues(newFormValues);
  };

  const handleChangeOption = (i, values) => {
    let newFormValues = [...formValues];
    newFormValues[i]["selectedOptions"] = values;

    let tempVariantsList = [];
    newFormValues.forEach(ele => {
      if (ele?.selectedOptions) {
        tempVariantsList.push(ele.selectedOptions);
      }
    });
    const combos = createCombinations(tempVariantsList, [], variantsList);
    setVariantsList(combos);
  };

  let addFormFields = () => {
    setFormValues([
      ...formValues,
      {
        name: "",
        value: "",
      },
    ]);
  };

  let removeFormFields = i => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);

    let tempVariantsList = [];
    newFormValues.forEach(ele => {
      if (ele?.selectedOptions) {
        tempVariantsList.push(ele.selectedOptions);
      }
    });
    const combos = createCombinations(tempVariantsList);
    setFormValues(newFormValues);
    setVariantsList(combos);
  };

  const handleChange = (i, e) => {
    let newVariantsList = [...variantsList];
    newVariantsList[i][e.target.name] = e.target.value;
    setVariantsList(newVariantsList);
  };

  function handleAcceptedFiles(index, files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
        // attrIndex: index
      })
    );
    let newVariantsList = [...variantsList];

    const images = newVariantsList[index]["images"] || [];
    const imgArray = [...images];
    imgArray.push(files[0]);
    newVariantsList[index]["images"] = imgArray;
    setVariantsList(newVariantsList);
  }

  function removeImage(imgIndex, index) {
    let newVariantsList = [...variantsList];
    let imgArray = newVariantsList[index]["images"];
    imgArray.splice(imgIndex, 1);
    newVariantsList[index]["images"] = imgArray;
    setVariantsList(newVariantsList);
  }

  const handleSubmit = () => {
    const tempVariants = [];
    formValues?.forEach(ele => {
      const { variant, selectedOptions } = ele;
      tempVariants.push({ variant, selectedOptions });
    });
    const obj = {
      variants: tempVariants,
      combos: variantsList,
    };
    handleChangeProperties("attributes", [obj]);
  };

  const calculateDiscount = (index, discountType, value) => {
    let tempVariant = variantsList[index];
    const newValues = {};
    let ourPrice, flatPrice, percentage;
    if (discountType === "percentageDiscount") {
      newValues["percentageDiscount"] = value;
      // calculate Flat Discount, Our price
      ourPrice = (tempVariant.mrp - tempVariant.mrp * (value / 100)).toFixed(2);
      flatPrice = tempVariant.mrp - ourPrice;
      newValues["price"] = ourPrice;
      newValues["flatDiscount"] = flatPrice;
    } else {
      newValues["flatDiscount"] = value;
      // calculate Percentage Discount, Our price
      ourPrice = (tempVariant.mrp - value).toFixed(2);
      percentage = ((tempVariant.mrp - ourPrice) / tempVariant.mrp) * 100;
      newValues["price"] = ourPrice;
      newValues["percentageDiscount"] = percentage;
    }
    variantsList[index] = { ...tempVariant, ...newValues };
    setVariantsList([...variantsList]);
    // handleUpdateMulti(newValues);
  };
  const handleMRP = (index, val) => {
    let tempVariant = variantsList[index];
    const newValues = {
      flatDiscount: 0,
      percentageDiscount: 0,
      price: val,
      mrp: val,
    };
    // handleUpdateMulti(newValues);
    variantsList[index] = { ...tempVariant, ...newValues };
    setVariantsList([...variantsList]);
  };
  return (
    <>
      {" "}
      <Card className="variant_container">
        <CardTitle className=" border border-bottom-3 d-flex justify-content-between">
          <h6 className="ms-3 mt-3 text-primary">Attribute</h6>
          <div>
            <button
              type=""
              className="addNew-btn btn btn-md me-2 mt-2 text-info"
              onClick={() => addFormFields()}
            >
              Add new attribute
            </button>
          </div>
        </CardTitle>
        <CardBody>
          <p>
            Adding new attributes helps the product to have many options, such
            as size or color.
          </p>

          {formValues.map((element, index) => (
            <div className="d-flex" key={index}>
              <FormGroup className="me-5 w-100">
                <Label for="exampletext">Variant Name</Label>
                <Input
                  type="select"
                  name="variant"
                  value={element.variant || ""}
                  onChange={e => handleChangeVariant(index, e)}
                  className=" w-xl"
                >
                  {" "}
                  <option value="" disabled>
                    Please select
                  </option>
                  {variantsOptions?.map((ele, indx) => {
                    return (
                      <option key={indx} value={ele.name}>
                        {ele.name}
                      </option>
                    );
                  })}
                </Input>
              </FormGroup>

              <FormGroup className="me-5 w-100">
                <Label for="exampletext">Value</Label>
                <Select
                  defaultValue={[]}
                  value={element?.selectedOptions}
                  isMulti
                  name="value"
                  options={element.options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={event => handleChangeOption(index, event)}
                />
              </FormGroup>
              <button
                className=" btn text-danger mt-3 "
                onClick={() => removeFormFields(index)}
              >
                <i className="bx bx-sm bxs-trash-alt"></i>
              </button>
            </div>
          ))}
        </CardBody>
      </Card>
      {variantsList?.map((element, index) => (
        <Card key={index}>
          <div className="d-flex  mb-3 bg-light p-2  align-items-center ">
            <h5 className="mb-0 text-dark">Variant </h5>
            {element?.variants?.map((ele, ind) => (
              <span
                key={ind}
                className=" h6 border border-1 px-2 mb-0 bg-secondary text-white py-1 px-2 mx-2 rounded"
              >
                {ele}
              </span>
            ))}
          </div>
          <CardBody>
            <div className="  mb-2">
              <Row>
                <Col md={4}>
                  <Label for="sku">SKU</Label>
                  <Input
                    id="sku"
                    name="sku"
                    placeholder=""
                    type="text"
                    value={product?.sku}
                    onChange={e => handleChange(index, e)}
                  />
                </Col>

                <Col md={4} className="align-items-center">
                  <Label for="mrp" className="">
                    MRP
                  </Label>
                  <Input
                    type="mrp"
                    name="mrp"
                    id="mrp"
                    value={element.mrp || ""}
                    onChange={e => {
                      // handleChange(index, e);
                      handleMRP(index, e.target.value);
                    }}
                  />
                </Col>
                <Col md={4} className="align-items-center">
                  <Label for="price" className="">
                    Our Price
                  </Label>
                  <Input
                    type="number"
                    name="price"
                    id="price"
                    placeholder="₹"
                    value={element.price}
                    readOnly
                    disabled
                  />
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4}>
                  <FormGroup>
                    <Label for="tax">Tax (%)</Label>
                    <Input
                      id="tax"
                      name="tax"
                      type="number"
                      placeholder="%"
                      value={element.tax}
                      onChange={e => handleChange(index, e)}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="flatDiscount">Flat Discount</Label>
                    <Input
                      id="flatDiscount"
                      name="flatDiscount"
                      type="number"
                      placeholder="₹"
                      value={element.flatDiscount}
                      onChange={e => {
                        handleChange(index, e);
                        calculateDiscount(
                          index,
                          "flatDiscount",
                          e.target.value
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col md={4}>
                  <FormGroup>
                    <Label for="discount">Percentage Discount (%)</Label>
                    <Input
                      id="percentageDiscount"
                      name="percentageDiscount"
                      type="number"
                      placeholder="%"
                      value={element.percentageDiscount || ""}
                      onChange={e => {
                        handleChange(index, e);
                        calculateDiscount(
                          index,
                          "percentageDiscount",
                          e.target.value
                        );
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={12}>
                  <Label for="exampleSelect">Unit Type</Label>
                  <Input
                    id=""
                    name="select"
                    type="select"
                    value={element.unit}
                    onChange={e => handleChange(index, e)}
                  >
                    <option value="ml">Ml</option>
                    <option value="pc">Pc</option>
                    <option value="gms">Gms</option>
                    <option value="ltrs">Ltrs</option>
                    <option value="kg">Kg</option>
                  </Input>
                </Col>
              </Row>
              <Row className="mt-3">
                <Col md={4} className="align-items-center">
                  <Label for="quantity" className="">
                    Total Quantity
                  </Label>
                  <Input
                    type="number"
                    name="quantity"
                    id="quantity"
                    value={element.quantity || ""}
                    onChange={e => handleChange(index, e)}
                  />
                </Col>
                <Col md={4}>
                  <Label for="minimumOrderQuantity">
                    Minimum Order Quantity
                  </Label>
                  <Input
                    id="minimumOrderQuantity"
                    name="minimumOrderQuantity"
                    type="number"
                    placeholder="0"
                    value={element.minimumOrderQuantity}
                    onChange={e => handleChange(index, e)}
                  />
                </Col>
                {element.shippingMultiplyWithQuantity === true ? (
                  <Col md="4">
                    <FormGroup>
                      <Label for="shippingCost">Shipping Cost</Label>
                      <Input
                        id="shippingCost"
                        name="shippingCost"
                        type="number"
                        placeholder="0"
                        value={element.shippingCost * element.totalQuantity}
                        onChange={e => handleChange(index, e)}
                      />
                    </FormGroup>
                  </Col>
                ) : (
                  <Col md="4">
                    <FormGroup>
                      <Label for="shippingCost">Shipping Cost</Label>
                      <Input
                        id="shippingCost"
                        name="shippingCost"
                        type="number"
                        placeholder="0"
                        value={element.shippingCost}
                        onChange={e => handleChange(index, e)}
                      />
                    </FormGroup>
                  </Col>
                )}
                <Col md="12" className="mt-3 ">
                  <FormGroup switch>
                    <Label for="" className="ms-2 float-end">
                      Shipping Cost Multiply With Quantity
                    </Label>
                    <Input
                      className="float-end"
                      type="switch"
                      role="switch"
                      defaultChecked={element.shippingMultiplyWithQuantity}
                      onChange={e => handleChange(index, e)}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <div className="mt-2">
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(index, acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone variant_img_dropzone px-0">
                          <div className="dz-message p-0" {...getRootProps()}>
                            <input {...getInputProps()} />
                            <div className="">
                              <img
                                src={banner}
                                className=" mx-auto d-block mb-1"
                                alt=""
                                height={50}
                                width={50}
                              />
                            </div>
                            <h6>upload image</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                    <div className="dropzone-previews row" id="file-previews">
                      {element?.images &&
                        element?.images.length > 0 &&
                        element?.images.map((img, imgInd) => {
                          return (
                            <div
                              key={imgInd}
                              className="col-md-4 mt-1"
                              style={{
                                position: "relative",
                              }}
                            >
                              <div className="border">
                                <div className="p-2">
                                  <Row className="align-items-center">
                                    <Col className="col-auto">
                                      <ProfileImage
                                        image={img}
                                        height="80"
                                        classNames="avatar-sm rounded bg-light"
                                      />
                                    </Col>
                                    <Col>
                                      <Link
                                        to="#"
                                        className="text-muted font-weight-bold"
                                      >
                                        {img.name || img.filename}
                                      </Link>
                                      <p className="mb-0">
                                        <strong>{img.formattedSize}</strong>
                                      </p>
                                    </Col>
                                  </Row>
                                </div>
                                <i
                                  style={{
                                    position: "absolute",
                                    right: "16px",
                                    cursor: "pointer",
                                    top: 0,
                                  }}
                                  className="mdi mdi-close-thick font-size-18 text-danger"
                                  id="removeImgtooltip"
                                  onClick={() => removeImage(imgInd, index)}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </CardBody>
        </Card>
      ))}
      {/* <div className="mb-5">
        <button
          className="btn btn-sm btn-primary px-4 float-end mb-5"
          onClick={() => {
            handleSubmit();
          }}
        >
          Save Changes
        </button>
      </div> */}
    </>
  );
};
export default Variants;
