import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  CardTitle,
  Label,
  Button,
  FormGroup,
  FormFeedback,
  Input,
  CardSubtitle,
  InputGroup,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import SweetAlert from "react-bootstrap-sweetalert";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Select from "react-select";
import CreatableSelect from "react-select/creatable";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import profileImg from "assets/images/users/avatar-1.jpg";
import ProfileImage from "components/profileImage";
import Spinner from "components/spinner";


import {
  getSettingById,
  createSettings,
  updateSettings,
} from "services/settings";

const defaultValues = {
  applicationName: "",
  providerAppName: "",
  shortDescription: "",
  themeContract: "",
  navbarColor: "",
  backgroundLogoColor: "",
  customFieldsModel: JSON.stringify([]),
  blockedIps: "",
  applicationLogo: {},
  // backgroundColor: "",
  fixedHeader: "false",
  fixedFooter: "false",
  zipCodes: JSON.stringify([]),
};

const validationSchema = Yup.object().shape({
  applicationName: Yup.string().required("Please Enter Application Name"),
  providerAppName: Yup.string().required("Please Enter Provider App Name"),
  shortDescription: Yup.string().required("Please Enter Short Description"),
  themeContract: Yup.string().required("Please Enter Short Theme Contract"),
  navbarColor: Yup.string().required("Please Enter Navbar Color"),
});

const GlobalSettings = props => {
  const { id } = useParams();

  const [zipCodes, setZipCodes] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [loading, setLoading] = useState(false);

  const [list, setList] = useState({});
  let obj = {
    zipCodesList: [
      { label: "122", value: "122" },
      { label: "123", value: "123" },
      { label: "124", value: "124" },
    ],
    themeContracts: [
      { label: "blue", value: "blue" },
      { label: "pink", value: "pink" },
    ],
    navbarColors: [
      { label: "blue", value: "blue" },
      { label: "Navy", value: "navy" },
    ],
    backgroundColors: [
      { label: "blue", value: "blue" },
      { label: "Navy", value: "navy" },
    ],
    customFields: [
      { label: "field 1", value: "field 1" },
      { label: "field 2", value: "field 2" },
    ],
  };

  const [selectedThemeContract, setSelectedThemeContract] = useState({});
  const [selectedNavbarColor, setSelectedNavbarColor] = useState({});
  const [selectedBackgroundColor, setSelectedBackgroundColor] = useState({});
  const [selectedCustomFieldInput, setSelectedCustomFieldInput] = useState([
    {
      label: "field 1",
      value: "field 1",
    },
  ]);

  function handleThemeContract(selectedThemeContract) {
    setSelectedThemeContract(selectedThemeContract);
  }
  function handleNavbarColor(selectedNavbarColor) {
    setSelectedNavbarColor(selectedNavbarColor);
  }

  function handleBackgroundColor(selectedBacakgroundColor) {
    setSelectedBackgroundColor(selectedBackgroundColor);
  }

  function handleBackgroundColor(selectedBacakgroundColor) {
    setSelectedBackgroundColor(selectedBackgroundColor);
  }

  const handleChangeCustomField = selectedCustomFieldInput => {
    setSelectedCustomFieldInput(selectedCustomFieldInput);
  };

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleExpertise(selectedMultiZip) {
    const data = [];
    selectedMultiZip.map(e => {
      data.push(e.value);
    });
    setZipCodes(selectedMultiZip);
  }

  const onSubmit = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    if (id) {
      formData.set(
        "customFieldsModel",
        JSON.stringify(values["customFieldsModel"])
      );
      formData.set("zipCodes", JSON.stringify(values["zipCodes"]));
      if (values.applicationLogo.filename) {
        formData.set(
          "applicationLogo",
          JSON.stringify(values["applicationLogo"])
        );
      }
      updateSettings(formData, id)
        .then(res => {
          setsuccess_msg(true);
          setLoading(false);
        })
        .catch(e => {
          console.log(e);
          seterror_msg(true);
          setLoading(false);
        });
    } else {
      createSettings(formData)
        .then(res => {
          setLoading(false);
          setsuccess_msg(true);
        })
        .catch(e => {
          console.log(e);
          seterror_msg(true);
          setLoading(false);
        });
    }
  };

  const leaveToList = () => {
    props.history.push("/global-settings");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Category created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Global Settings | stylepro - -
          </title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid={false}>
          <Breadcrumb title="Settings" breadcrumbItem="Global Settings" />
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={defaultValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  values,
                }) => {
                  const [user, setUser] = useState({});
                  const [showPassword, setShowPassword] = useState(false);

                  useEffect(() => {
                    setList(obj);
                    if (id) {
                      getSettingById(id)
                        .then(res => {
                          let data = res.data;
                          let {
                            themeContract,
                            backgroundLogoColor,
                            navbarColor,
                            customFieldsModel,
                            zipCodes,
                            applicationLogo,
                          } = data;
                          setSelectedThemeContract({
                            label: themeContract,
                            value: themeContract,
                          });
                          setSelectedNavbarColor({
                            label: navbarColor,
                            value: navbarColor,
                          });
                          setSelectedBackgroundColor({
                            label: backgroundLogoColor,
                            value: backgroundLogoColor,
                          });
                          customFieldsModel = customFieldsModel?.map(fld => {
                            return { label: fld, value: fld };
                          });
                          zipCodes = zipCodes?.map(fld => {
                            return { label: fld, value: fld };
                          });
                          setSelectedCustomFieldInput([...customFieldsModel]);
                          setZipCodes([...zipCodes]);
                          const fields = [
                            "applicationName",
                            "providerAppName",
                            "shortDescription",
                            "themeContract",
                            "navbarColor",
                            "backgroundLogoColor",
                            "customFieldsModel",
                            "blockedIps",
                            "applicationLogo",
                            "fixedHeader",
                            "fixedFooter",
                            "zipCodes",
                          ];

                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          setDataFetched(true);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    } else {
                      setDataFetched(true);
                    }
                  }, []);
                  return (
                    <Form>
                      <Row className="mt-3">
                        <Col md={6} className="px-5">
                          <Row className="mb-3">
                            <Label
                              htmlFor="application-name"
                              className="col-form-label"
                            >
                              Application Name
                            </Label>
                            <Col sm={12}>
                              <Input
                                name="applicationName"
                                type="text"
                                className="form-control"
                                id="application-name"
                                placeholder=""
                                onChange={handleChange}
                                value={values.applicationName}
                                invalid={
                                  touched.applicationName &&
                                  errors.applicationName
                                    ? true
                                    : false
                                }
                              />
                              {touched.applicationName &&
                              errors.applicationName ? (
                                <FormFeedback type="invalid">
                                  {errors.applicationName}
                                </FormFeedback>
                              ) : null}
                              <Label className="col-form-label text-muted fw-normal">
                                The application name appear in title
                              </Label>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="provider-app-name"
                              className="col-form-label"
                            >
                              Provider App Name
                            </Label>
                            <Col sm={12}>
                              <Input
                                name="providerAppName"
                                type="text"
                                className="form-control"
                                id="provider-app-name"
                                placeholder=""
                                onChange={handleChange}
                                value={values.providerAppName}
                                invalid={
                                  touched.providerAppName &&
                                  errors.providerAppName
                                    ? true
                                    : false
                                }
                              />
                              {touched.providerAppName &&
                              errors.providerAppName ? (
                                <FormFeedback type="invalid">
                                  {errors.providerAppName}
                                </FormFeedback>
                              ) : null}
                              <Label className="col-form-label text-muted fw-normal">
                                The service provider app name appear in title
                              </Label>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="short-description"
                              className="col-form-label"
                            >
                              Short Description
                            </Label>
                            <Col sm={12}>
                              <Input
                                name="shortDescription"
                                type="text"
                                className="form-control"
                                id="short-description"
                                placeholder=""
                                onChange={handleChange}
                                value={values.shortDescription}
                                invalid={
                                  touched.shortDescription &&
                                  errors.shortDescription
                                    ? true
                                    : false
                                }
                              />
                              {touched.shortDescription &&
                              errors.shortDescription ? (
                                <FormFeedback type="invalid">
                                  {errors.shortDescription}
                                </FormFeedback>
                              ) : null}
                              <Label className="col-form-label text-muted fw-normal">
                                the short description appear in title
                              </Label>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="theme-contract"
                              className="col-form-label"
                            >
                              Theme Contract
                            </Label>
                            <div className="col-md-12">
                              <Select
                                name=""
                                value={selectedThemeContract}
                                onChange={event => {
                                  handleThemeContract();
                                  handleChange("themeContract")(event.value);
                                }}
                                options={list?.themeContracts}
                                classNamePrefix="select2-selection"
                              />
                              {errors.themeContract && (
                                <Label className="statusValidation">
                                  {errors.themeContract}
                                </Label>
                              )}
                              <Label className="col-form-label text-muted fw-normal">
                                Select your prefer theme color
                              </Label>
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <Label
                              htmlFor="navbar-color"
                              className="col-form-label"
                            >
                              Navbar Color
                            </Label>
                            <div className="col-md-12">
                              <Select
                                value={selectedNavbarColor}
                                onChange={event => {
                                  handleNavbarColor();
                                  handleChange("navbarColor")(event.value);
                                }}
                                options={list?.navbarColors}
                                classNamePrefix="select2-selection"
                              />
                              {errors.navbarColor && (
                                <Label className="statusValidation">
                                  {errors.navbarColor}
                                </Label>
                              )}
                              <Label className="col-form-label text-muted fw-normal">
                                Select your prefer Navigation bar color
                              </Label>
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <Label
                              htmlFor="background-logo"
                              className="col-form-label"
                            >
                              Background Main Logo
                            </Label>
                            <div className="col-md-12">
                              <Select
                                id="background-logo"
                                value={selectedBackgroundColor}
                                onChange={event => {
                                  handleBackgroundColor();
                                  handleChange("backgroundLogoColor")(
                                    event.value
                                  );
                                }}
                                options={list?.backgroundColors}
                                classNamePrefix="select2-selection"
                              />
                              {errors.backgroundLogoColor && (
                                <Label className="statusValidation">
                                  {errors.backgroundLogoColor}
                                </Label>
                              )}
                              <Label className="col-form-label text-muted fw-normal">
                                Select your prefer Background color
                              </Label>
                            </div>
                          </Row>

                          <Row className="mb-3">
                            <Label
                              htmlFor="custom-fields-model"
                              className="col-form-label"
                            >
                              Custom Fields Models
                            </Label>
                            <div className="col-md-12">
                              <Select
                                id="custom-fields-model"
                                value={selectedCustomFieldInput}
                                isMulti={true}
                                onChange={event => {
                                  handleChangeCustomField();
                                  const data = [];
                                  event.map(e => {
                                    data.push(e.value);
                                  });
                                  handleChange("customFieldsModel")(
                                    JSON.stringify(data)
                                  );
                                }}
                                options={list?.customFields}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="col-form-label text-muted fw-normal">
                                Select models to enable custom fields on theme
                              </Label>
                            </div>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="blocked-ip"
                              className="col-form-label"
                            >
                              Blocked IPs
                            </Label>
                            <Col sm={12}>
                              <Input
                                name="blockedIps"
                                type="text"
                                className="form-control"
                                id="blocked-ip"
                                placeholder=""
                                onChange={handleChange}
                                value={values.blockedIps}
                              />
                              <Label className="col-form-label text-muted fw-normal">
                                Insert ip address to deny access
                              </Label>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6} className="px-5">
                          <Row className="mb-3">
                            <Label htmlFor="image" className="col-form-label">
                              Application Logo
                            </Label>
                            <Col md={12}>
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue(
                                    "applicationLogo",
                                    acceptedFiles[0]
                                  );
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6 className="text-muted">
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="col-form-label text-muted fw-normal">
                                Upload your logo or select from media library
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.applicationLogo &&
                                  Object.keys(values.applicationLogo).length >
                                    0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.applicationLogo}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.applicationLogo?.name ||
                                                values.applicationLogo
                                                  ?.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {
                                                  values.applicationLogo
                                                    .formattedSize
                                                }
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="fixed-header"
                              className="col-form-label col-sm-3"
                            >
                              Fixed Header
                            </Label>
                            <Col sm={9} className="p-2">
                              {/* <Input
                                type="checkbox"
                                className="form-check-Input me-2"
                                id="fixed-header"
                              /> */}
                              {dataFetched && (
                                <Input
                                  key={"header" + "checkbox"}
                                  type="checkbox"
                                  className="form-check-Input me-2"
                                  id={"fixed-header"}
                                  value={
                                    values.fixedHeader === "true" ? true : false
                                  }
                                  defaultChecked={
                                    values.fixedHeader === "true" ? true : false
                                  }
                                  onChange={event => {
                                    handleChange("fixedHeader")(
                                      event.target.checked ? "true" : "false"
                                    );
                                  }}
                                />
                              )}
                              <Label
                                className="form-check-Label fw-bold mb-0"
                                htmlFor="fixed-header"
                              >
                                Accept
                              </Label>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <Label
                              htmlFor="fixed-footer"
                              className="col-form-label col-sm-3"
                            >
                              Fixed Footer
                            </Label>
                            <Col sm={9} className="p-2">
                              {/* <Input
                                type="checkbox"
                                className="form-check-Input me-2"
                                id="fixed-footer"
                              /> */}
                              {dataFetched && (
                                <Input
                                  key={"footer" + "checkbox"}
                                  type="checkbox"
                                  className="form-check-Input me-2"
                                  id={"fixed-footer"}
                                  value={
                                    values.fixedFooter === "true" ? true : false
                                  }
                                  defaultChecked={
                                    values.fixedFooter === "true" ? true : false
                                  }
                                  onChange={event => {
                                    handleChange("fixedFooter")(
                                      event.target.checked ? "true" : "false"
                                    );
                                  }}
                                />
                              )}

                              <Label
                                className="form-check-Label fw-bold mb-0"
                                htmlFor="fixed-footer"
                              >
                                Accept
                              </Label>
                            </Col>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="zip-codes">ZIP codes</Label>
                              <Select
                                id="zip-codes"
                                value={zipCodes}
                                isMulti={true}
                                onChange={event => {
                                  handleExpertise(event);
                                  const data = [];
                                  event.map(e => {
                                    data.push(e.value);
                                  });
                                  handleChange("zipCodes")(
                                    JSON.stringify(data)
                                  );
                                }}
                                options={list?.zipCodesList}
                                classNamePrefix="select2-selection"
                              />
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                      <hr />
                      <div className="d-flex justify-content-end">
                        <Button
                          type="submit"
                          color="primary"
                          className="w-md btn-sm me-4"
                        >
                          <i className="far fa-save"></i> Save Changes
                        </Button>
                        <Button color="light" className="w-md btn-sm">
                          <i className="fa fa-undo"></i> cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </CardBody>
          </Card>
        </Container>
      </div>
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </React.Fragment>
  );
};

export default GlobalSettings;
