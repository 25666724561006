import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Form,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import { Reviews } from "../Reviews";
import { useParams } from "react-router-dom";
import { getReviewsById } from "services/ecommerce/reviews.service";
import {
  createNewProduct,
  empltyProductById,
  getProductById,
} from "store/actions";
import { useDispatch, useSelector } from "react-redux";

export const FaqsReviewsTab = ({
  defaultValues,
  handleSubmit,
  handleChangeProperties,
}) => {
  const product = useSelector(state => state?.EcomProducts?.product);
  const [values, setValues] = useState(product);
  const [faqs, setFaqs] = useState([]);
  const route = useParams();
  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (route?.id) {
  //     getProductById(dispatch, route?.id);
  //     setValues(product);
  //   } else {
  //     dispatch(empltyProductById(defaultValues));
  //     setValues(product);
  //   }
  // }, [route]);

  useEffect(() => {
    if (route?.id) {
      setValues(product);
      setFaqs(product?.faqs);
    } else {
      setFaqs(product?.faqs);
      setValues(product);
    }
  }, [product]);

  let addFields = () => {
    let newValues = [...faqs];
    newValues.push({
      question: "",
      answer: "",
    });
    setFaqs(newValues);
    handleChangeProperties("faqs", faqs);
  };
  let removeFields = (i, type) => {
    let newValues = [...faqs];
    newValues.splice(i, 1);
    setFaqs(newValues);
    handleChangeProperties("faqs", faqs);
  };
  let handleOnChange = (i, e, type, name) => {
    let newValues = [...faqs];
    newValues[i][name] = e.target.value;
    handleChangeProperties(type, newValues);
  };
  const saveValues = () => {
    let body = {
      faqs: faqs,
    };
    let basicNewValues = Object.assign(values, body);
    if (route?.catId) {
      dispatch(createNewProduct(basicNewValues));
      handleSubmit(basicNewValues);
    } else handleSubmit(basicNewValues);
  };
  return (
    <div>
      <div className="d-flex justify-content-between  ">
        <h6 className="mt-3 "> Frequently asked Questions</h6>
        <div>
          <button
            type=""
            className="addNew-btn btn btn-md me-2 mt-2 text-info"
            onClick={() => {
              addFields();
            }}
          >
            Add item
          </button>
        </div>
      </div>

      <Card>
        <CardBody>
          <div>
            {faqs?.map((ele, index) => (
              <div
                className="border border-1 border-light p-0 mb-3"
                key={index}
              >
                <div className="d-flex justify-content-between bg-light p-2">
                  <div className="mx-2 mt-1">
                    <h6 className="mb-0">{index + 1}</h6>
                  </div>
                  <div>
                    <i
                      style={{
                        cursor: "pointer",
                      }}
                      className="mdi mdi-close-thick font-size-18 text-danger float-end"
                      id="removeImgtooltip"
                      onClick={() => removeFields(index)}
                    />
                  </div>
                </div>
                <div className="p-2">
                  <Form>
                    <FormGroup>
                      <Label for="" className="mt-3">
                        Question<span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="question"
                        id=""
                        rows="1"
                        value={ele.question || ""}
                        onChange={e =>
                          handleOnChange(index, e, "faqs", "question")
                        }
                      />
                    </FormGroup>
                  </Form>
                </div>
                <div className="p-2">
                  <Form>
                    <FormGroup>
                      <Label for="">
                        Answer<span>*</span>
                      </Label>
                      <Input
                        type="textarea"
                        name="answer"
                        id=""
                        rows="1"
                        value={ele.answer || ""}
                        onChange={e =>
                          handleOnChange(index, e, "faqs", "answer")
                        }
                      />
                    </FormGroup>
                  </Form>
                </div>
              </div>
            ))}
          </div>
          <hr className="mt-5" />
          <div className="d-flex mt-2 justify-content-end float-end">
            {route?.catId ? (
              <>
                {" "}
                <Button
                  type="button"
                  color="light"
                  className="w-md btn-sm ms-2"
                >
                  Cancel
                </Button>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => saveValues()}
                >
                  Save
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => saveValues()}
                >
                  Save Changes
                </Button>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};
export default FaqsReviewsTab;
