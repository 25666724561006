import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import UserTabs from "pages/Users/UserTabs";

import { Badge, Card, CardBody, Col, Container, Row } from "reactstrap";

import Breadcrumb from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";

import profileImg from "assets/images/users/avatar-3.jpg";
import "pages/Users/UserView.styles.scss";
import { useParams } from "react-router";
import {getProvideById} from "services/provider.service";
import moment from "moment"

const ProviderView = props => {
  const { id } = useParams();
  const[values,setValues] = useState({})
  const [adress,setAdress] = useState()
useEffect(()=>{
    getUserById()
    console.log(values)
},[id])
const getUserById=()=>{
    getProvideById(id)
    .then(res => {
        
     setValues({...res.data})
     setAdress(res.data?.locations.find(e=>e.defaultstatus==="true"))
  
    })
    .catch(err => {
      console.log(err);
    });
}
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Profile | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumb title="Providers" breadcrumbItem="User View" />
          <Card className="view-users">
            <CardBody>
            <div className="d-flex justify-content-between">
              <Link to="/provider-list">
                  <Badge
                    className="font-size-12 badge-soft-secondary py-1 px-3"
                    color={"bg-secondary"}
                  >
                    <i className="mdi mdi-backburger font-size-14" />
                  </Badge>
                </Link>
                <Link className="text-end" to={`/provider-update/${id}`}>
                  <Badge
                    className="font-size-12 badge-soft-warning py-1 px-3"
                    color={"bg-warning"}
                    pill
                  >
                    <i className="mdi mdi-pencil font-size-14" /> Edit
                  </Badge>
                </Link>
 
              </div>
              <Row>
                            </Row>
              <Row>
                <Col md={4} className="">
                  <div className="text-center">
                   { values?.image?.length>0 && <img
                       src={
                        values?.image[0]?.file?.data
                          ? `data:${values?.image[0]?.mimetype};base64,${Buffer.from(
                            values?.image[0]?.file.data
                            ).toString("base64")}`
                          : profileImg
                      }
                      alt=""
                      className="img-thumbnail profile "
                    />}
                    <h5 className="mt-4 text-muted">{values?.shortname?values.shortname:"_"}</h5>
                  </div>
                </Col>
                <Col md={8} className=" pt-4 mb-4 px-0 user_detail">
                  <Row className="mx-3 ps-2">
                    <p className="ps-0">
                      <span className="pe-3 fw-bold">Legal Name </span>{" "}
                      <span className="text-muted">
                        {values?.legalname ? values.legalname : "_"}{" "}
                      </span>
                    </p>
                    <p className="ps-0 ">
                      <span className="pe-3 fw-bold">Owner </span>{" "}
                      <span className="text-muted">
                        {values?.user ? values.user: "_"}{" "}
                      </span>
                    </p>
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                    <Col md={4} className="mt-4">
                 
                      <p className="fw-bold mb-1">Address</p>
                      <p className="text-muted">{adress?.address ? adress.address : "_"}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                    <p className="fw-bold mb-1">Mobile Number</p>
                      <p className="text-muted"> {values?.mobilenumber ? values.mobilenumber : "_"}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                    <p className="fw-bold mb-1">Phone Number</p>
                      <p className="text-muted"> {values?.phonenumber ? values.phonenumber : "_"}</p>
                    </Col>
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Created</p>
                      <p className="text-muted">{moment(values?.createdAt).format('L')}</p>
                    </Col>
                    
                    <Col md={4} className="mt-4">
                      <p className="fw-bold mb-1">Updated At</p>
                      <p className="text-muted">{moment(values?.updatedAt).format('L')}</p>
                    </Col>
                    
                  </Row>
                  <div className=" mt-2 me-3 hr"> </div>
                  <Row className="mx-3 my-2">
                  <p className="fw-bold mb-1">Description</p>
                      {values?.description ? (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: values?.description,
                          }}
                        />
                      ) : (
                        "_"
                      )}
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <UserTabs  data = {{expertise:values?.expertise,bio:values?.biography,provider:values?._id,bookings:values?.bookings}}/>
              </Row>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProviderView;
