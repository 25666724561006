import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as Yup from "yup";
import { useFormik } from "formik";
import SweetAlert from "react-bootstrap-sweetalert";

import Switch from "react-switch";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";

import Spinner from "components/spinner";
import ProfileImage from "./../../components/profileImage";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import {
  getProviders,
  deleteprovider,
  updateProviderAccess,
} from "services/provider.service";
//redux
import { useSelector, useDispatch } from "react-redux";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import logo from "assets/images/logo.svg";

const ProviderList = props => {
  const dispatch = useDispatch();
  const [list, setList] = useState([]);
  const [idList, setIdList] = useState({});

  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllList();
  }, []);
  // validation
  const getAllList = () => {
    getProviders()
      .then(res => {
        let result = res.data;
        setList(result);
        let obj = {};
        result.forEach(pr => {
          let id = pr._id;
          obj[id] = pr["access"];
        });
        setIdList(obj);
      })
      .catch(err => {
        console.log(err);
      });
  };
  const removeProvider = id => {
    deleteprovider(id)
      .then(res => {
        console.log(res);
        setDelete_modal(false);
        getAllList();
      })
      .catch(err => {
        console.log(err);
      });
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: list.length, // replace later with size(list),
    custom: true,
  };
  const { SearchBar } = Search;
  const badgeColor = "font-size-12 badge-soft-";

  const toLowerCase1 = str => {
    return str === "" || str === undefined ? "" : str.toLowerCase();
  };

  const handleSwitch = id => {
    setLoading(true);
    let prevState = idList[id];
    let switch_toggle = prevState === "true" ? "false" : "true";
    setIdList({ ...idList, [id]: switch_toggle });
    updateProviderAccess(id, switch_toggle)
      .then(res => {
        setLoading(false);
        if (res.data.success) {
        } else {
          alert("something went wrong")
          setIdList({ ...idList, [id]: prevState });
        }
        console.log(res);
      })
      .catch(e => {
        alert("something went wrong")
        setLoading(false);
        setIdList({ ...idList, [id]: prevState });
        console.log(e);
      });
  };

  const ProvidersColumn = () => [
    {
      dataField: "profile",
      text: "Profile",
      formatter: (cellContent, row) => (
        <ProfileImage image={row.image} classNames="rounded-circle avatar-sm" />
      ),
    },
    {
      dataField: "legalname",
      text: "Legal Name",
    },
    {
      dataField: "shortname",
      text: "Short Name",
    },
    {
      dataField: "providertype.name",
      text: "Provider Type",
    },
    {
      dataField: "phonenumber",
      text: "Phone Number",
    },
    // {
    //     dataField: "availabilityRange",
    //     text: "Availability Range",
    //   },
    {
      dataField: "access",
      isDummyField: true,
      text: "Access",
      formatter: (cell, row) => {
        let id = row._id;
        return (
          <Switch
            onChange={() => handleSwitch(id)}
            checked={idList[id] == "true" ? true : false}
            onColor="#86d3ff"
            onHandleColor="#2693e6"
            handleDiameter={25}
            uncheckedIcon={false}
            checkedIcon={false}
            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
            height={15}
            width={40}
            className="react-switch"
            id="material-switch"
          />
        );
      },
      formatExtraData: idList,
    },

    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        let editUrl = `/provider-update/${row._id}`;
        return (
          <>
            <div className="d-flex gap-3">
              <Link className="text-success" to={`/provider-view/${row._id}`}>
                <i className="mdi mdi-file-document font-size-18" id="view"></i>
              </Link>
              <Link
                to={editUrl}
                className="text-success"
                // onClick={() => console.log(row._id)}
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <div
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(row._id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeProvider(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  const handleOrderClicks = () => {};

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro - -</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumbs title="Providers" breadcrumbItem="Provider List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={ProvidersColumn()}
                    data={list}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={list}
                        columns={ProvidersColumn()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Link
                                    to="/create-provider"
                                    className="has-arrow "
                                  >
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleOrderClicks}
                                    >
                                      <i className="bx bx-user-plus me-1" />
                                      Create Providers
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {delete_modal ? deletePopup() : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ProviderList;
