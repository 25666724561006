import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { getSubCategoryByParent } from "services/ecommerce/category.service";
import {
  getCategoryRoot,
  getViewById,
} from "services/ecommerce/category.service";
import EcomMainCat from "../CategoriesMenu/MainCatList";
import EcomParentCat from "../CategoriesMenu/parentCat";
import EcomViewCat from "./subCatList";
import EcomCreateParent from "./CreateParent";
import EcomCreateSub from "./CreateSub";
import "./index.scss";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const ProductCatListEcommerce = () => {
  const [modal, setModal] = useState(false);
  const [modalSub, setModalSub] = useState(false);
  const [mainCatList, setMainCatList] = useState([]);
  const [ViewList, setViewList] = useState({});
  const [loading, setLoading] = useState([]);
  const [subCatList, setSubCatList] = useState(null);
  const [subId, setSubId] = useState();

  useEffect(() => {
    getMainCategories();
  }, []);

  const getMainCategories = () => {
    getCategoryRoot()
      .then(res => {
        let { data } = res;
        setMainCatList(data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const getSubById = id => {
    getSubCategoryByParent(id)
      .then(res => {
        setSubCatList(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getCatViewById = id => {
    getViewById(id)
      .then(res => {
        setViewList(res.data);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const handleClickParent = ele => {
    getSubById(ele._id);
    setSubId(ele._id);
    setViewList({});
  };

  const handleClickSub = ele => {
    getCatViewById(ele._id);
  };
  const toggle = () => {
    setModal(!modal);
  };
  const toggleSub = () => {
    setModalSub(!modalSub);
  };
  const reloadService = () => {
    getSubById(subId);
  };
  const reloadSub = () => {
    getSubById(subId);
    getCatViewById(subId);
  };
  // const reloadParent = () => {
  //   getParentById(mainId);
  // };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Category | stylepro</title>
        </MetaTags>
        {/* <Spinner loading={loading} /> */}
        <Container fluid id="service_list">
          <Breadcrumbs
            title="Product Category"
            breadcrumbItem="Product Category List"
          />
          <BreadcrumbSub subtile="Products Categories List" SubBreadcrumbItem="Product categories" />

          <Row>
            <Col className="tab_4 px-0 me-2">
              <Card className="">
                <CardBody className="px-2">
                  <div className="mb-3 text-center">
                    <button
                      className="w-lg btn-md mx-4 p-2 bg-light border-0 rounded text-primary  align-center"
                      onClick={toggle}
                    >
                      {" "}
                      <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                      Parent Category
                    </button>
                  </div>
                  <EcomMainCat
                    list={mainCatList}
                    handleClick={handleClickParent}
                    isEditable={true}
                    getMainCat={getMainCategories}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col className=" tab_2 px-0 me-2">
              {" "}
              <Card className="">
                <CardBody className="px-3">
                  <div className="mb-3">
                    <button
                      className="w-xl btn-md mx-1 bg-light border-0 rounded text-primary p-2"
                      onClick={toggleSub}
                    >
                      {" "}
                      <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                      Sub Category
                    </button>
                  </div>
                  <EcomParentCat
                    list={subCatList}
                    handleClick={handleClickSub}
                    reloadService={getSubById}
                  />
                </CardBody>
              </Card>
            </Col>
            <Col className="tab_3 px-0 me-1">
              <Card className="bg-white">
                <CardBody>
                  <EcomViewCat list={ViewList} reloadService={reloadService} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <div>
        {modal ? (
          <EcomCreateParent
            toggle={toggle}
            modal={modal}
            reloadService={getMainCategories}
          />
        ) : null}
        {modalSub ? (
          <EcomCreateSub
            toggle={toggleSub}
            modal={modalSub}
            reloadService={reloadSub}
          />
        ) : null}
      </div>
    </React.Fragment>
  );
};

export default ProductCatListEcommerce;
