import React from "react";
import noImage from "assets/images/PicUpload.jpg";

const ProfileImage = ({ image, classNames, height = "auto", width = null }) => {
  let src = image;
  if (typeof src !== "string") {
    let data = Array.isArray(image) ? image[0] : image;
    src = data?.file
      ? `data:${data.mimetype};base64,${Buffer.from(data.file.data).toString(
          "base64"
        )}`
      : data?.preview
      ? data.preview
      : noImage;
  }
  return (
    <>
      <img
        // src={
        // data?.file
        // ? `data:${data.mimetype};base64,${Buffer.from(
        //     data.file.data
        //   ).toString("base64")}`
        // : data?.preview
        // ? data.preview
        // : noImage
        // }
        src={src ? src : noImage}
        alt=""
        height={height}
        width={width}
        className={classNames}
      />
    </>
  );
};

export default ProfileImage;
