export const getCustomers = token => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/users/customers`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at login ${e}`));
};

export const getProviders = token => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/users/providers`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at login ${e}`));
};

export const getEmployees = token => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/users/employees`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at login ${e}`));
};

export const getProfileAvatar = token => {
  fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/users/avatar`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  })
    .then(res => {
      return res;
    })
    .catch(e => console.log(`error at login ${e}`));
};

export const deleteUser = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/users/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at users ${e}`));
};

export const getUser = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/users/user/${id}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at users ${e}`));
};

export const getAllAdminUsers = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/users/admins`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      console.log(res);
      return res.json();
    })
    .catch(e => console.log(`error at get all admin users ${e}`));
};
