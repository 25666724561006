export const login=(body)=>{
    fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/auth/login`,{
        method:"POST",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          },
        body:JSON.stringify(body)
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at login ${e}`))
}

export const userRegister=(body)=>{
   return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/auth/register`,{
        method:"POST",
        headers: {
            Accept: "application/json"
          },
        body:body
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at register ${e}`))  
}

export const getme=(token)=>{
    fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/auth/getme`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at getme ${e}`))  
}

export const logout=()=>{
    fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/auth/logout`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at logout ${e}`))  
}

export const updateUser=(body,id)=>{
   return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/auth/updatedetails/${id}`,{
        method:"PUT",
        headers: {
            Accept: "application/json"
          },
        body:body
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at updateUser ${e}`))  
}
