import React, { useEffect, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";
import { Badge, Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import {
  deleteSubCategory,
  getSubCategory,
} from "services/ecommerce/category.service";
import EcomCreateSub from "./CreateSub";
import noImage from "assets/images/PicUpload.jpg";
import { deleteProductBySub } from "services/ecommerce/product.service";
const EcomViewCat = ({ list, reloadService }) => {
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(null);
  const [modalSub, setModalSub] = useState(false);
  const [activeSubId, setActiveSubId] = useState(false);

  const toggleSub = () => {
    if (modalSub) {
      setActiveSubId(null);
    }
    setModalSub(!modalSub);
  };

  //Delete
  const removeCategory = id => {
    console.log(id, "rmove sub id");
    setLoading(true);
    deleteSubCategory(id)
      .then(res => {
        setDelete_modal(false);
        getSubCategory();
        deleteProductBySub(id);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    console.log(id);
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeCategory(deleteId);
    // console.log("Category deleted");
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const color = "text-";
  const renderViewCatList = () => {
    return (
      <div>
        {/* {list && (
          <div className="empty_list p-3 pt-0">
            <div className="text-center">
              <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
            </div>
            <p className="text-center">Please Select Sub Category</p>
          </div>
        )} */}

        {list && Object.keys(list).length === 0 && (
          <div className="empty_list p-3 pt-0">
            <div className="text-center">
              <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
            </div>
            <p className="text-center">Please Select Sub Category</p>
          </div>
        )}
        {list && Object.keys(list).length > 0 && (
          <div>
            <div className="d-flex float-end">
              <Badge
                className="font-size-12 badge-soft-warning py-1 px-3 me-2"
                color={"bg-warning"}
                pill
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // console.log(list, "sub");
                  setActiveSubId(list);
                  toggleSub();
                }}
              >
                <i className="mdi mdi-pencil font-size-14" /> Edit
              </Badge>

              <Badge
                className="font-size-12 badge-soft-danger py-1 px-3"
                color={"bg-warning"}
                onClick={() => onClickDelete(list._id)}
                style={{ cursor: "pointer" }}
                pill
              >
                <i className="mdi mdi-delete font-size-14" />
                Delete
              </Badge>
            </div>

            <Row>
              <Col md={5} className="">
                <div className="text-center">
                  <img
                    src={
                      list?.image && list.image?.includes("http")
                        ? list.image
                        : noImage
                    }
                    alt=""
                    className="img-thumbnail profile rounded-circle "
                  />
                </div>
              </Col>
              <Col md={7} className=" pt-4 mb-4 px-0 view_detail">
                <Row className="mx-3 ps-2">
                  <p className="ps-0">
                    <span className="pe-3 fw-bold"> Name </span>{" "}
                    <span className="text-muted">{list?.name} </span>
                  </p>
                </Row>
                <div className=" mt-2 me-3 hr"> </div>
                <Row className="mx-3 my-2">
                  <Col md={6} className="mt-4">
                    <p className="fw-bold mb-1">Main Category</p>
                    <p className="text-muted">
                      {list?.mainCategory ? list?.mainCategory.name : "-"}
                    </p>
                  </Col>
                  <Col md={6} className="mt-4">
                    <p className="fw-bold mb-1"> Parent Category</p>
                    <p className="text-muted">
                      {list?.parentCategory ? list?.parentCategory.name : "-"}
                    </p>
                  </Col>
                </Row>
                <Row className="mx-3 my-2">
                  <Col md={6} className="mt-4">
                    <p className="fw-bold mb-1">Status</p>
                    <p
                      className={
                        list.status === "Active"
                          ? color + "success"
                          : color + "danger"
                      }
                    >
                      {list?.status}
                    </p>
                  </Col>
                  <Col md={6} className="mt-4">
                    <p className="fw-bold mb-1">Order</p>
                    <p className="text-muted">
                      {list?.order ? list?.order : "_"}
                    </p>
                  </Col>
                </Row>
                <Row className="mx-3 my-2">
                  <Col className="mt-4">
                    <p className="fw-bold mb-1">Description</p>
                    {list.description ? (
                      <div
                        className="text-muted"
                        dangerouslySetInnerHTML={{
                          __html: list.description,
                        }}
                      />
                    ) : (
                      "_"
                    )}{" "}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="p-2">
      <div className="mb-2">{renderViewCatList(list._id)}</div>
      {delete_modal ? deletePopup() : null}
      {modalSub && (
        <EcomCreateSub
          toggle={toggleSub}
          modal={modalSub}
          editObj={activeSubId}
          reloadService={reloadService}
        />
      )}
    </div>
  );
};
export default EcomViewCat;
