import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import moment from "moment";
import ContactUsUsersList from "./List";
import ContactUsUsersDetails from "./Details";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const ContactUsUsers = () => {
  const [activeOrder, setActiveOrder] = useState(null);
  const handleActiveOrder = order => {
    setActiveOrder(order);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Contacts" breadcrumbItem="Contact-Us Queries" />
          <BreadcrumbSub
            subtile="Queries list"
            SubBreadcrumbItem="Contact-us Queries"
          />
          <Row className="mt-3">
            <Col md="4">
              <ContactUsUsersList handleActiveOrder={handleActiveOrder} />
            </Col>
            <Col md="8">
              <ContactUsUsersDetails activeOrder={activeOrder} />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};
export default ContactUsUsers;
