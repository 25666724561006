import React from "react";
import { Col,Badge } from "reactstrap";
const ListLabel = ({ type }) => {
  return (
    <Col md="2">
      <div className="text-secondary float-end">
        <Badge
          className={
            type == "Female" ? " badge-soft-info " : " badge-soft-warning "
          }
          color={type == "Female" ? "bg-info" : "bg-warning"}
          pill
        >
          {type}
        </Badge>
      </div>
    </Col>
  );
};

export default ListLabel;
