import { GET_BRANDS, GET_BRAND_FAIL } from "./actionTypes";

const INIT_STATE = {
  brands: [],
  error: {},
  loading: false,
};

const EcomBrands = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
      };

    case GET_BRAND_FAIL:
      return {
        ...state,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default EcomBrands;
