import React, { useState, useEffect } from "react";
import { Input, UncontrolledTooltip, Card, Table } from "reactstrap";
import EditService from "./editService";
import _ from "lodash";

const ServiceTable = ({
  subCatId,
  serviceList,
  handleSubmit,
  expInitialValues,
}) => {
  const [list, setList] = useState([]);
  const [selectedList, setSelectedList] = useState([]);
  const [allSelectedList, setAllSelectedList] = useState([]);
  const [isChange, setIsChange] = useState(false);
  const [activeEditService, setActiveEditService] = useState(null);
  const [activeEditServiceIndx, setActiveEditServiceIndx] = useState(null);
  useEffect(() => {
    initSelectedList();
  }, [serviceList, expInitialValues]);

  const structureObj = obj => {
    const resObj = {
      priceType: obj["PriceType"] || "",
      name: obj["name"] || "",
      id: obj["_id"] || "",
      serviceId: obj["serviceId"] || "",
      price: obj["price"] || "",
      priceType: obj["priceType"] || "",
      currency: "INR" || "INR",
    };
    return resObj;
  };

  const initSelectedList = () => {
    let allServices = _.cloneDeep(serviceList);
    let selectedSerArr = [];
    let selectedAllSerArr = [];

    expInitialValues?.service?.forEach(ser => {
      if (ser?.id?.categories?._id === subCatId) {
        selectedSerArr.push(ser);
      } else {
        selectedAllSerArr.push(ser);
      }
    });
    selectedSerArr.forEach(ele => {
      let index = _.findIndex(allServices, function (o) {
        return o.serviceId == ele?.id?.serviceId;
      });
      if (index > -1) {
        allServices.splice(index, 1);
      }
    });
    setList(allServices);
    setSelectedList(_.cloneDeep(selectedSerArr));
    setAllSelectedList(selectedAllSerArr);
    setIsChange(false);
  };

  const cancel = () => {
    initSelectedList();
  };

  const cancelEditService = () => {
    setActiveEditService(null);
    setActiveEditServiceIndx(null);
  };

  const saveEditService = service => {
    let serList = _.cloneDeep(selectedList);
    serList[activeEditServiceIndx] = service;
    setSelectedList(serList);
    cancelEditService();
    detectChange();
  };

  const handleAdd = service => {
    let tempList = _.cloneDeep(list);
    _.remove(tempList, n => n.serviceId === service?.serviceId);
    setList(tempList);
    detectChange();
    let tempSelectedList = _.cloneDeep(selectedList);
    tempSelectedList.push(structureObj(service));
    setSelectedList([...tempSelectedList]);
  };

  const handleDelete = service => {
    let tempSelectedList = _.cloneDeep(selectedList);
    let serviceId = service?.serviceId || service?.id?.serviceId;
    _.remove(
      tempSelectedList,
      n => n.serviceId === serviceId || n.id.serviceId === serviceId
    );
    setSelectedList(tempSelectedList);
    detectChange();
    let allServices = _.cloneDeep(serviceList);
    tempSelectedList.forEach(ele => {
      let index = _.findIndex(allServices, function (o) {
        if (
          o.serviceId == ele?.serviceId ||
          o.serviceId == ele?.id?.serviceId
        ) {
          return o;
        }
      });
      if (index > -1) {
        allServices.splice(index, 1);
      }
    });
    setList(allServices);
  };

  const detectChange = () => {
    if (!isChange) {
      setIsChange(true);
    }
  };

  const onSubmit = () => {
    const obj = {
      service: [...allSelectedList, ...selectedList],
      package: expInitialValues?.package ? expInitialValues?.package : [],
    };
    const body = {
      expertise: obj,
    };
    handleSubmit(body);
  };

  const RenderSelectedList = () => {
    return (
      <Card className="mb-2 p-3 pb-0">
        <div className="d-flex justify-content-between mb-2">
          <h6 className="text-primary">BasicService Cost (Selected)</h6>
          {isChange && (
            <div className="d-flex">
              <Input
                type="button"
                className="btn btn-light btn-sm px-5 me-2"
                value="Cancel"
                onClick={() => cancel()}
              />
              <Input
                type="button"
                className="btn btn-primary btn-sm px-3"
                value="Save Changes"
                onClick={() => {
                  onSubmit();
                }}
              />
            </div>
          )}
        </div>
        <Table>
          <thead>
            <tr>
              <th>Service Id</th>
              <th>Service Name</th>
              <th>PriceType</th>
              <th>Price</th>
              <th>currency</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {selectedList &&
              selectedList.map((service, index) => {
                return (
                  <tr key={index}>
                    <th scope="row">
                      {service?.serviceId || service?.id?.serviceId}
                    </th>
                    <td>{service?.name}</td>
                    <td>{service?.priceType || "-"}</td>
                    <td>{service?.price || "-"}</td>
                    <td>{service?.currency || "INR"}</td>
                    <td>
                      <>
                        <i
                          className="mdi mdi-delete font-size-18 text-danger"
                          id="deleteSevice"
                          onClick={() => {
                            handleDelete(service);
                          }}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="deleteSevice"
                        >
                          Delete
                        </UncontrolledTooltip>
                        <i
                          className="bx bx-edit-alt font-size-18 text-success ps-3"
                          id="editSevice"
                          onClick={() => {
                            setActiveEditService(service);
                            setActiveEditServiceIndx(index);
                          }}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="editSevice"
                        >
                          Edit
                        </UncontrolledTooltip>
                      </>
                    </td>
                  </tr>
                );
              })}
            {!selectedList ||
              (selectedList?.length === 0 && (
                <tr>
                  <td>No Data Found</td>
                </tr>
              ))}
          </tbody>
        </Table>

        {activeEditServiceIndx != null && (
          <EditService
            service={activeEditService}
            saveEditService={saveEditService}
            cancelEditService={cancelEditService}
          />
        )}
      </Card>
    );
  };

  const RenderAllList = () => {
    return (
      <Card className="mb-2 p-3 pb-0 mt-3">
        <div className="d-flex justify-content-between mb-2">
          <h6 className="text-primary">Available Services</h6>
        </div>
        <Table>
          <thead>
            <tr>
              <th>Service Id</th>
              <th>Service Name</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {list &&
              list.map((service, index) => {
                return (
                  <tr key={service._id}>
                    <th scope="row">{service.serviceId}</th>
                    <td>{service.name}</td>

                    <td>
                      <>
                        <i
                          className="mdi mdi-check-bold font-size-18 text-success"
                          id="editServices"
                          type="pointer"
                          onClick={() => {
                            handleAdd(service);
                          }}
                        />
                        <UncontrolledTooltip
                          placement="top"
                          target="editServices"
                        >
                          ADD Service
                        </UncontrolledTooltip>
                      </>
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </Table>
      </Card>
    );
  };

  return (
    <>
      {RenderSelectedList()}
      {RenderAllList()}
    </>
  );
};
export default ServiceTable;
