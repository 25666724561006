export const getSettings=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/setting/all`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at settings ${e}`))  
  }
  export const createSettings = body => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/setting/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at settings ${e}`));
  };
  
  export const updateSettings = (body,id) => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/setting/update/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at settings ${e}`));
  };
  export const getSettingById=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/setting/get/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at settings ${e}`))  
  }
  export const deleteSetting=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/setting/delete/${id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at settings ${e}`))  
  }