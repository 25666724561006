import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  UncontrolledCollapse,
} from "reactstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import moment from "moment";
import CkEditor from "./CkEditor";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  createNewProduct,
  empltyProductById,
  getProductById,
} from "store/actions";

const Specifications = ({
  handleChangeProperties,
  defaultValues,
  handleSubmit,
  stepperActiveTab,
}) => {
  const product = useSelector(state => state?.EcomProducts?.product);
  const [values, setValues] = useState(product);
  const [shortDescription, setShortDescription] = useState([]);
  const [selectedContent, setSelectedContent] = useState("");
  const route = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (route?.id) {
      setValues(product);
      setShortDescription(product?.description);
      setSelectedContent(product?.content);
    } else {
      setShortDescription(product?.description);
      setSelectedContent(product?.content);
      setValues(product);
    }
  }, [product]);

  useEffect(() => {
    setShortDescription([
      {
        name: "",
        value: "",
      },
    ]);
    setSelectedContent("");
  }, []);

  const addShortDescription = (type, event) => {
    event.preventDefault();
    let newValues = [...shortDescription];
    newValues.push({
      name: "",
      value: "",
    });
    setShortDescription(newValues);
    handleChangeProperties(type, newValues);
  };

  let removeFields = (i, type) => {
    let newValues = [...shortDescription];
    newValues.splice(i, 1);
    setShortDescription(newValues);
    handleChangeProperties("properties", newValues);
  };

  let handleOnChange = (i, e, type) => {
    let newValues = [...shortDescription];
    if (type == "description") {
      newValues[i]["name"] = e.target.value;
      handleChangeProperties(type, newValues);
    } else newValues[i][e.target.name] = e.target.value;
    handleChangeProperties(type, newValues);
  };

  let handleOnEditorChange = (i, e, type, name) => {
    let newValues = [...shortDescription];
    newValues[i][name] = e;
    handleChangeProperties(type, newValues);
  };

  const saveValues = () => {
    let body = {
      content: selectedContent,
      description: shortDescription,
    };

    let basicNewValues = Object.assign(values, body);
    if (route?.catId) {
      dispatch(createNewProduct(basicNewValues));
      stepperActiveTab("4");
    } else handleSubmit(basicNewValues);
  };

  return (
    <>
      <h6 className="text-primary mt-3">Description(Short description)</h6>
      <Card>
        <CardBody>
          {" "}
          <div>
            <div>
              {shortDescription?.map((data, index) => {
                return (
                  <div key={index} className="mt-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="col-md-6 d-flex mb-2">
                        <div className="border border-1 border-secondary rounded px-3 py-2 me-2">
                          <p className="p-0 m-0 fw-bold fs-6">{index + 1}</p>
                        </div>

                        <input
                          name="name"
                          id="name"
                          className="form-control"
                          type="text"
                          placeholder="Description Title"
                          value={data.name || ""}
                          onChange={e =>
                            handleOnChange(index, e, "description")
                          }
                        />
                      </div>
                      <div className="mb-3 float-end d-flex">
                        <Button
                          color="danger"
                          className="btn-sm  me-2 "
                          onClick={() => removeFields(index, "description")}
                        >
                          <i className="bx bx-trash-alt fs-5"></i>
                        </Button>
                        <Button
                          color="primary"
                          id={`toggler${index}`}
                          className="btn-sm w-100 "
                        >
                          Show/Hide Editor
                        </Button>
                      </div>
                    </div>
                    <UncontrolledCollapse
                      toggler={`#toggler${index}`}
                      defaultOpen="false"
                    >
                      <CKEditor
                        name="value"
                        id="value"
                        editor={ClassicEditor}
                        data={data?.value || ""}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          handleOnEditorChange(
                            index,
                            data,
                            "description",
                            "value"
                          );
                        }}
                      />
                    </UncontrolledCollapse>
                  </div>
                );
              })}
              <button
                className="btn text-white bg-primary w-sm btn-sm mt-2"
                onClick={e => {
                  addShortDescription("description", e);
                }}
              >
                Add
              </button>
            </div>
          </div>
        </CardBody>
      </Card>

      <div className="d-flex justify-content-between align-items-center mb-2">
        <h6 className="text-primary mb-0">Content(Long Description)</h6>
      </div>

      <Card>
        <CardBody>
          <div>
            <div className="mb-3 ">
              <Button color="primary" id="toggler" className="btn-sm w-sm ">
                Show/Hide Editor
              </Button>
            </div>
            <div>
              <UncontrolledCollapse toggler="#toggler" defaultOpen="true">
                <Card>
                  <CardBody className="p-0">
                    <CKEditor
                      editor={ClassicEditor}
                      data={selectedContent ? selectedContent : ""}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChangeProperties("content", data);
                        setSelectedContent(data);
                      }}
                    />
                  </CardBody>
                </Card>
              </UncontrolledCollapse>
            </div>
          </div>

          <hr className="mt-5" />
          <div className="d-flex mt-2 justify-content-end float-end">
            {route?.catId ? (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => saveValues()}
                >
                  Next <i className="bx bx-right-arrow-alt mx-2"></i> Filters
                </Button>
              </>
            ) : (
              <>
                <Button
                  type="button"
                  color="primary"
                  className="w-md btn-sm ms-2"
                  onClick={() => saveValues()}
                >
                  Save Changes
                </Button>
              </>
            )}
          </div>
        </CardBody>
      </Card>
    </>
  );
};
export default Specifications;
