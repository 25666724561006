import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import Breadcrumbs from "components/Common/Breadcrumb";

import {
  Container,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  CardTitle,
  CardImg,
} from "reactstrap";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import * as moment from "moment";

import { connect, useSelector, useDispatch } from "react-redux";

import Breadcrumb from "../../components/Common/Breadcrumb";
import avatar4 from "assets/images/users/avatar-4.jpg";
import "../Customers/datatables.scss";
import "../Customers/index.styles.scss";

import { Link } from "react-router-dom";
// import { getProviders } from "store/actions";
import { getProviders } from "services/user.service";

const providerList = props => {
  const [customerData, setlist] = useState([]);
  useEffect(() => {
    getProviderList();
  }, []);
  const getProviderList = () => {
    getProviders()
      .then(res => {
        setlist(res);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const badgeColor = "font-size-12 badge-soft-";

  // Table Data
  const customer = () => [
    {
      dataField: "firstname",
      text: "Name",
    },
    {
      dataField: "email",
      text: "Email",
    },
    {
      dataField: "createdAt",
      text: "updatedAt",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) =>
        handleValidDate(moment(row?.updatedAt).format("MMM Do YYYY")),
    },

    {
      dataField: "status",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={
            row.status === "pending"
              ? badgeColor + "warning"
              : badgeColor + "success"
          }
          color={"bg-secondary"}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
        <div className="d-flex gap-3">
          <Link
            className="text-success"
            to="#"
            onClick={() => alert("Work in progress")}
          >
            <i
              className="mdi mdi-file-document font-size-18"
              id="edittooltip"
            ></i>
          </Link>
          <Link
            className="text-danger"
            to="#"
            onClick={() => alert("Work in progress")}
          >
            <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
          </Link>
        </div>
      ),
    },
  ];
  const handleOrderClicks = () => {};

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD-MMM-Y HH:mm");
    return date1;
  };

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 5,
    totalSize: customerData.length, // replace later with size(customers),
    custom: true,
  };

  // Custom Pagination Toggle
  const sizePerPageList = [
    { text: "5", value: 5 },
    { text: "10", value: 10 },
    { text: "15", value: 15 },
    { text: "20", value: 20 },
    { text: "25", value: 25 },
    { text: "All", value: customerData.length },
  ];

  // Select All Button operation
  const selectRow = {
    mode: "checkbox",
  };

  //Exapnd Row
  const expandRow = {
    onlyOneExpanding: true,
    renderer: row => (
      <Row className="view-details py-2">
        <Col lg={2}>
          <img
            src={avatar4}
            alt="stylepro"
            className="rounded-circle avatar-md"
          />
        </Col>
        <Col lg={10}>
          <Row>
            <Col md={4}>
              <small className="text-muted">NAME</small>
              <h6 className="mb-2">{row.name}</h6>
            </Col>
            <Col md={4}>
              <small className="text-muted">EMAIL</small>
              <h6>sparrow@gmail.com</h6>
            </Col>
            <Col md={4}>
              <small className="text-muted">REGISTERED</small>
              <h6 className="mb-2">9-12-2021</h6>
            </Col>
            <Col md={4}>
              <small className="text-muted">LAST UPDATED</small>
              <h6>9-12-2021</h6>
            </Col>
          </Row>
        </Col>
      </Row>
    ),
  };
  const { SearchBar } = Search;

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Users" breadcrumbItem="Customers" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="username"
                    columns={customer()}
                    data={customerData}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="username"
                        data={customerData}
                        columns={customer()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8"></Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(providerList);
