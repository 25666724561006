import React, { useState, useEffect } from "react";
import { Button, Card, CardBody, Col, Input, Row } from "reactstrap";
import { Link } from "react-router-dom";
import avatar from "assets/images/avatar.jpg";
import noImage from "assets/images/PicUpload.jpg";
import EditInfoModal from "./EditService";
import { deleteServiceProduct } from "services/product.service";
import SweetAlert from "react-bootstrap-sweetalert";
import EditServiceProducts from "./EditService/EditServiceProducts";

const defaultValues = {
  name: "",
  categories: "",
  parentCategory: "",
  type: "",
  price: "",
  discountPrice: "",
  priceUnit: "",
  customQuantityUnit: "",
  duration: "",
  description: "",
  featured: false,
  enableBooking: "false",
  available: false,
  banner: {},
  images: [],
  brands: [],
  serviceProducts: [],
  relatedProducts: [],
  createdAt: null,
  updatedAt: null,
  relatedService: [],
  keyWords: [],
  serviceExtra: 0,
  productExtra: 0,
};

const View = ({
  serviceValues,
  handleDelete,
  parentCategory,
  subCatList,
  reloadService,
}) => {
  const [values, setValues] = useState(defaultValues);
  const [editModal, setEditModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [activeData, setActiveData] = useState();
  const [Variant, setVariant] = useState();
  console.log(Variant, "varrr variants");
  let serId = values?._id;
  const editTooggle = () => {
    setEditModal(!editModal);
  };
  const [delete_modal, setDelete_modal] = useState(false);
  const [activeTab, setActiveTab] = useState(null);
  const [prodId, setProdId] = useState(null);
  let { brands, serviceProducts, relatedProducts } = values;
  const isServiceProducts = serviceProducts?.filter(
    ele => ele?.product?.isServiceProduct === true
  );
  const textColor = "text-";
  console.log(serviceProducts, "serviceprod");
  useEffect(() => {
    if (serviceValues) {
      let {
        name,
        price,
        country,
        order,
        categories,
        parentCategory,
        type,
        discountPrice,
        priceUnit,
        customQuantityUnit,
        duration,
        description,
        featured,
        enableBooking,
        available,
        banner,
        images,
        tips,
        tagline,
        benfits,
        precautions,
        brands,
        serviceProducts,
        relatedProducts,
        serviceInfo,
        optionsDetails,
        createdAt,
        updatedAt,
        status,
        _id,
        relatedService,
        keyWords,
        articles,
        serviceExtra,
        productExtra,
      } = serviceValues;

      let obj = {
        name,
        price,
        country,
        order,
        categories,
        parentCategory,
        type,
        discountPrice,
        priceUnit,
        customQuantityUnit,
        duration,
        description,
        featured,
        enableBooking,
        available,
        banner,
        images,
        tips,
        tagline,
        benfits,
        precautions,
        brands,
        serviceProducts,
        serviceInfo,
        optionsDetails,
        createdAt,
        updatedAt,
        _id,
        status,
        relatedService,
        keyWords,
        articles,
        serviceExtra,
        productExtra,
        relatedProducts,
      };
      setValues({ ...obj });
    }
  }, [serviceValues]);

  // useEffect(() => {
  //   if (isServiceProducts?.length) {
  //     debugger
  //     const defaultVariants = isServiceProducts.map(product => {
  //       const comboVariants =
  //         product?.product?.attributes[0]?.combos[0] || [];
  //       return {
  //         ...comboVariants,
  //         prodId: product?.product?._id,
  //       };
  //     });
  //     console.log(defaultVariants,"defaultVariants");
  //     setVariant(defaultVariants);
  //   }
  // }, []);
  const handleEdit = tabName => {
    setActiveTab(tabName);
    editTooggle();
  };
  const toggle = () => {
    setModal(!modal);
  };
  const removeProduct = (serId, prodId) => {
    deleteServiceProduct(serId, prodId)
      .then(res => {
        setDelete_modal(false);
        reloadService();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleProductDelete = () => {
    removeProduct(serId, prodId);
  };
  const onClickDelete = id => {
    setProdId(id);
    setDelete_modal(true);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleProductDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleProductDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  const renderBasicInfo = () => {
    return (
      <div className="m-3 services_basic_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>BASIC & IMAGES INFO</h6>
            <div className="d-flex ">
              <Link
                className="btn btn-sm btn-primary btn-bg-primary me-3 "
                to="#"
                onClick={() => handleEdit("basic")}
              >
                <i className="bx bx-edit-alt pe-1"> </i>
                <span className="py-3"> Edit</span>
              </Link>
              <Link
                className="btn btn-sm btn-danger btn-bg-danger "
                to="#"
                onClick={() => handleDelete()}
              >
                <i className="bx bxs-trash-alt pe-1"></i>
                <span> Delete</span>
              </Link>
            </div>
          </div>
        </Row>
        <Row className="m-0">
          <Col md={4} className="services_images_info ps-0 pe-2">
            <Card className="mb-2">
              <CardBody>
                <Row className="mb-2">
                  {" "}
                  <Col md={6}>
                    <p className="fw-bold mb-0">Banner</p>{" "}
                  </Col>
                  <Col md={6}>
                    {" "}
                    <div className="edit_btn_div">
                      <Link
                        className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
                        to="#"
                        onClick={() => handleEdit("banner")}
                      >
                        <i className="bx bx-edit-alt bx-xs pe-1"> </i>
                        <span> Edit</span>
                      </Link>{" "}
                    </div>
                  </Col>
                </Row>
                <div className="banner">
                  <img
                    alt="banner"
                    src={values?.banner ? values?.banner : avatar}
                  />
                </div>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <Row className="mb-2">
                  {" "}
                  <Col md={6}>
                    <p className="fw-bold mb-0">Images</p>{" "}
                  </Col>
                  <Col md={6}>
                    {" "}
                    <div className="edit_btn_div">
                      <Link
                        className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
                        to="#"
                        onClick={() => handleEdit("images")}
                      >
                        <i className="bx bx-edit-alt bx-xs pe-1"> </i>
                        <span> Edit</span>
                      </Link>{" "}
                    </div>
                  </Col>
                </Row>
                <div className="images_container">
                  {values?.images?.map(ele => {
                    return (
                      <div className="img_card" key={ele}>
                        <img src={ele} />
                      </div>
                    );
                  })}
                  {values?.images?.length === 0 && (
                    <>
                      <div className="img_card">
                        <img src={avatar} />
                      </div>
                      <div className="img_card">
                        <img src={avatar} />
                      </div>
                    </>
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col md={8} className="p-0">
            <Card>
              <CardBody>
                <Row className="m-0">
                  <Col md={12} className="mb-1 px-0 view_detail">
                    <Row className="m-0 mb-2">
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Name</p>
                        <p className="text-muted">
                          {values.name ? values.name : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Country</p>
                        <p className="text-muted">
                          {values.country ? values.country?.label : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Status</p>
                        <p
                          className={
                            values.status === "Active"
                              ? textColor + "success"
                              : textColor + "danger"
                          }
                        >
                          {values?.status ? values.status : "_"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={4} className="">
                        <p className="fw-bold mb-1">Price Range</p>
                        <p className="text-muted">
                          {values?.price ? values.price : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="">
                        <p className="fw-bold mb-1">Duration</p>
                        <p className="text-muted">
                          {values?.duration ? values.duration : "_"}
                        </p>
                      </Col>
                      <Col md={4} className="">
                        <p className="fw-bold mb-1">Order</p>
                        <p className="text-muted">
                          {values?.order ? values.order : "_"}
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Type</p>
                        <p className="text-muted">
                          {values.type ? values.type : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Parent Category</p>
                        <p className="text-muted">
                          {values.parentCategory
                            ? values.parentCategory.name
                            : "_"}{" "}
                        </p>
                      </Col>
                      <Col md={4} className="mt-1">
                        <p className="fw-bold mb-1">Sub Category</p>
                        <p className="text-muted">
                          {values.categories ? values.categories.name : "_"}{" "}
                        </p>
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={12} className="mt-1">
                        <p className="fw-bold mb-1">Tagline</p>
                        {values.tagline ? (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: values.tagline,
                            }}
                          />
                        ) : (
                          "_"
                        )}{" "}
                      </Col>
                    </Row>
                    <Row className="m-0 mb-2">
                      <Col md={12} className="mt-1">
                        <p className="fw-bold mb-1">Description</p>
                        {values.description ? (
                          <div
                            className="text-muted"
                            dangerouslySetInnerHTML={{
                              __html: values.description,
                            }}
                          />
                        ) : (
                          "_"
                        )}{" "}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  };
  const renderImageInfo = () => {
    return (
      <div className="m-3 services_images_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h5>IMAGES INFO</h5>
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("images")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row>
              <Col md={4}>
                <p className="fw-bold mb-1">Banner</p>
                <div className="banner">
                  <img alt="banner" src={values?.banner} />
                </div>
              </Col>
              <Col md={8} className="ps-4">
                <p className="fw-bold mb-1">Images</p>
                <div className="images_container">
                  {values?.images?.map(ele => {
                    return (
                      <div className="img_card" key={ele}>
                        <img src={ele} />
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  const ServiceInfo = () => {
    return (
      <div className="m-3 services_service_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>ADDITIONAL INFORMATION</h6>
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("service")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row>
              {values.serviceInfo && values.serviceInfo.length === 0 && (
                <div className="empty_list p-3 pt-0">
                  <div className="text-center">
                    <i
                      className="mdi mdi-circle-off-outline"
                      id="EmptyCatList"
                    ></i>
                  </div>
                  <h4>Empty List</h4>
                  <p className="text-center">No Information Found</p>
                </div>
              )}
              {values.serviceInfo &&
                values.serviceInfo.map((ele, ind) => {
                  return (
                    <Col md={3} className="mb-3" key={ind}>
                      <div className="service_img_container">
                        <div className="img_div">
                          <img alt="" src={ele.image} />
                        </div>
                        <p>{ele.key}</p>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };
  const renderRealtedServiceInfo = () => {
    return (
      <div>
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>RELATED SERVICES INFO</h6>
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("relatedService")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className=" m-0 mb-2 p-3 option_div me-4">
              <Col md={12} className="">
                {values?.relatedService &&
                  values?.relatedService.length === 0 && (
                    <div className="empty_list p-3 pt-0">
                      <div className="text-center">
                        <i
                          className="mdi mdi-circle-off-outline"
                          id="EmptyCatList"
                        ></i>
                      </div>
                      <h4>Empty List</h4>
                      <p className="text-center">No related services Found</p>
                    </div>
                  )}
                <div className="service_img_container ">
                  {values?.relatedService?.map((rel, index) => (
                    <div
                      className="img_card"
                      style={{ width: "100px" }}
                      key={index}
                    >
                      <img src={avatar} />
                      <h6 className="mt-2 mb-3 text-center">{rel?.name} </h6>
                    </div>
                  ))}
                  <hr />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };
  const renderServiceBrandsInfo = () => {
    return (
      <div>
        <div>
          <Row>
            <div className="info_conetent_header d-flex mb-2">
              <h6>BRANDS INFO</h6>
            </div>
          </Row>
          <Card className="services_brands">
            <CardBody>
              <Row className="m-0 mb-2 p-2 option_div ">
                <Col md={12} className="text-center">
                  {brands && brands.length === 0 && (
                    <div className="empty_list p-3 pt-0">
                      <div className="text-center">
                        <i
                          className="mdi mdi-circle-off-outline"
                          id="EmptyCatList"
                        ></i>
                      </div>
                      <h4>Empty List</h4>
                      <p className="text-center">No Brands Found</p>
                    </div>
                  )}
                  <div className="service_img_container mb-2">
                    {brands?.map((brand, index) => (
                      <div
                        className="img_card"
                        // style={{ width: "100px" }}
                        key={index}
                      >
                        <img src={brand.logo ? brand.logo : avatar} />
                        <h6 className="mt-2 mb-0 text-center">
                          {brand?.name}{" "}
                        </h6>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };

  const [defaultVariants, setDefaultVariants] = useState([]);
  const [selectedVariants, setSelectedVariants] = useState([]);

  useEffect(() => {
    if (isServiceProducts?.length) {
      const defaultVariants = isServiceProducts.map(product => {
        const comboVariants = product?.product?.attributes[0]?.combos[0] || [];
        return {
          ...comboVariants,
          prodId: product?.product?._id,
        };
      });
      setDefaultVariants(defaultVariants);
      setSelectedVariants(defaultVariants);
    }
  }, [isServiceProducts?.length]);

  const handleSelectVariant = (name, prod, prodId) => {
    const variant = prod?.attributes[0]?.combos?.find(
      ele => ele?.variants[0] === name && prod?._id === prodId
    );

    const updatedSelectedVariants = selectedVariants.map(selectedVariant => {
      if (selectedVariant.prodId === prodId) {
        return {
          ...variant,
          prodId: prodId,
        };
      }
      return selectedVariant;
    });

    setSelectedVariants(updatedSelectedVariants);
  };
  const renderServiceProductOption = prod => {
    console.log(prod, "proddd");
    const optionsDetails = values?.optionsDetails;
    const options = prod.options || [];
    const VariantSelected = selectedVariants?.find(
      ele => ele?.prodId === prod?.product?._id
    );
    const variantPrice = VariantSelected ? VariantSelected?.price : "";
    console.log(VariantSelected, "varselected");
    // const percentageselect =
    const amount = parseFloat(
      (prod?.generalPricePercentage / 100) * variantPrice
    );
    console.log(amount, "amount");
    const Simpleprodamount = parseFloat(
      (prod?.generalPricePercentage / 100) * prod?.product?.price
    );
    const simpleProdPrice =
      prod?.product?.type === "Variant" ? amount : Simpleprodamount;
    return (
      <Card>
        <CardBody className="p-0">
          {options?.length === 0 && optionsDetails?.length === 0 && (
            <div className="mt-2">
              {/* <h5>Given Extra Percentage</h5> */}

              <table className="table" style={{ width: "500px" }}>
                <thead>
                  <tr>
                    <th className="ps-1" style={{ width: "200px" }}>
                      Percentage(%)
                    </th>
                    <th className="ps-1" style={{ width: "200px" }}>
                      Percentage Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="options_table">
                  <tr>
                    <td style={{ width: "70px" }}>
                      <div className="d-flex align-items-center">
                        <Input
                          type="number"
                          className="form-control"
                          id="percentage"
                          placeholder=""
                          name="percentage"
                          value={prod?.generalPricePercentage || 0}
                          disabled={true}
                          style={{ width: "200px" }}
                        />
                        <p className="d-inline mb-0 ms-1 font-size-16 fw-bold">
                          %
                        </p>
                      </div>
                    </td>
                    <td style={{ width: "200px" }}>
                      <Input
                        type="number"
                        className="form-control"
                        id="extraAmount"
                        placeholder=""
                        name="extraAmount"
                        disabled
                        value={simpleProdPrice?.toFixed(2) || 0}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          )}
          {options?.length > 0 ? (
            <div>
              {options.map((ele, index) => {
                return (
                  <div className="" key={index}>
                    <table className="table" style={{ width: "500px" }}>
                      <thead>
                        <tr>
                          <th className="ps-1" style={{ width: "200px" }}>
                            {ele?.title}
                          </th>
                          <th className="ps-1" style={{ width: "200px" }}>
                            Percentage(%)
                          </th>
                          <th className="ps-1" style={{ width: "200px" }}>
                            Percentage Amount
                          </th>
                        </tr>
                      </thead>
                      {ele?.optionDetails?.map((option, ind) => {
                        // const VariantSelected = selectedVariants?.find(
                        //   ele => ele?.prodId === prod?.product?._id
                        // );
                        // const variantPrice = VariantSelected
                        //   ? Variant?.price
                        //   : "";

                        // const amount = parseFloat(
                        //   (option?.extra / 100) * variantPrice
                        // );
                        // const Simpleprodamount = parseFloat(
                        //   (option?.extra / 100) * prod?.product?.price
                        // );
                        // // const price = (
                        // //   parseFloat(amount) + parseFloat(VariantSelected?.price)
                        // // ).toFixed(2);
                        // const simpleProdPrice =
                        //   prod?.product?.type === "Variant"
                        //     ? amount
                        //     : Simpleprodamount;
                        const VariantSelected = selectedVariants?.find(
                          ele => ele?.prodId === prod?.product?._id
                        );
                        const variantPrice = VariantSelected
                          ? VariantSelected?.price
                          : "";
                        console.log(VariantSelected, "varselected");
                        // const percentageselect =
                        const amount = parseFloat(
                          (option?.extra / 100) * variantPrice
                        );
                        console.log(amount, "amount");
                        const Simpleprodamount = parseFloat(
                          (option?.extra / 100) * prod?.product?.price
                        );
                        const simpleProdPrice =
                          prod?.product?.type === "Variant"
                            ? amount
                            : Simpleprodamount;
                        return (
                          <tbody key={ind} className="options_table">
                            <tr>
                              <td style={{ width: "150px" }}>{option?.name}</td>
                              <td style={{ width: "70px" }}>
                                <div className="d-flex align-items-center">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="percentage"
                                    placeholder=""
                                    name="percentage"
                                    value={option?.extra || 0}
                                    disabled={true}
                                  />
                                  <p className="d-inline mb-0 ms-1 font-size-16 fw-bold">
                                    %
                                  </p>
                                </div>
                              </td>
                              <td style={{ width: "200px" }}>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="extraAmount"
                                  placeholder=""
                                  name="extraAmount"
                                  disabled
                                  value={simpleProdPrice?.toFixed(2) || 0}
                                />
                              </td>
                              {/* <td style={{ width: "200px" }}>
                              <Input
                                type="number"
                                className="form-control"
                                id="totalAmount"
                                placeholder=""
                                name="totalAmount"
                                disabled
                                value={price || ""}
                              />
                            </td> */}
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                );
              })}
            </div>
          ) : (
            <div>
              {optionsDetails?.map((ele, index) => {
                return (
                  <div className="" key={index}>
                    <table className="table" style={{ width: "430px" }}>
                      <thead>
                        <tr>
                          <th className="ps-1" style={{ width: "200px" }}>
                            {ele?.optionTitle}
                          </th>
                        </tr>
                      </thead>
                      {ele?.values?.map((option, ind) => {
                        return (
                          <tbody key={ind} className="options_table">
                            <tr>
                              <td style={{ width: "150px" }}>{option?.name}</td>
                              <td style={{ width: "100px" }}>
                                <div className="d-flex align-items-center">
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id="percentage"
                                    placeholder=""
                                    name="percentage"
                                    value={0}
                                    disabled={true}
                                  />
                                  <p className="d-inline mb-0 ms-1 font-size-16 fw-bold">
                                    %
                                  </p>
                                </div>
                              </td>
                              <td style={{ width: "100px" }}>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id="extraAmount"
                                  placeholder=""
                                  name="extraAmount"
                                  disabled
                                  value={0}
                                />
                              </td>
                            </tr>
                          </tbody>
                        );
                      })}
                    </table>
                  </div>
                );
              })}
            </div>
          )}
        </CardBody>
      </Card>
    );
  };
  const renderServiceProductsInfo = () => {
    return (
      <div className="service">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>PRODUCTS INFO</h6>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className=" m-0 mb-2 p-2 option_div me-4">
              <Col md={12} className="px-0">
                {serviceProducts && serviceProducts?.length === 0 && (
                  <div className="empty_list p-3 pt-0">
                    <div className="text-center">
                      <i
                        className="mdi mdi-circle-off-outline"
                        id="EmptyCatList"
                      ></i>
                    </div>
                    <h4>Empty List</h4>
                    <p className="text-center">No Products Found</p>
                  </div>
                )}
                <div className="service_img_container ">
                  {isServiceProducts?.length &&
                    isServiceProducts?.map((product, index) => {
                      let variants =
                        product &&
                        product?.product["attributes"] &&
                        product?.product["attributes"][0]
                          ? product?.product?.attributes[0]["variants"]
                          : [];
                      const VariantSelected = selectedVariants.find(
                        ele => ele?.prodId === product?.product?._id
                      );
                      return (
                        <Col
                          md={12}
                          className="border border-2 rounded mb-2"
                          key={index}
                        >
                          {product?.product?.type === "Variant" ? (
                            <div className="d-flex -justify-content-between p-2">
                              <div className="w-100">
                                <div className=" d-flex flex-wrap w-100">
                                  <div
                                    className="product-name me-3"
                                    style={{ width: "250px" }}
                                  >
                                    <span className="fw-bold">
                                      Product Id :{" "}
                                    </span>
                                    <span>{VariantSelected?.sku}</span>
                                    <div
                                      className="mt-2"
                                      style={{ width: "200px" }}
                                    >
                                      <span className="fw-bold">
                                        {product?.product?.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="me-3">
                                    <span className="fw-bold">Category : </span>
                                    <span>{product?.parentCategory?.name}</span>
                                  </div>
                                  <div className="me-3">
                                    <span className="fw-bold">Brand : </span>
                                    <span>{product?.brand?.name}</span>
                                  </div>
                                  <div className="">
                                    <span className="fw-bold">Price : </span>
                                    <span>
                                      {VariantSelected?.prodId ===
                                      product?.product?._id
                                        ? VariantSelected?.price
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div>
                                  {variants?.length &&
                                    variants?.map((variant, index) => {
                                      return (
                                        <div
                                          key={index}
                                          className="variants mt-3"
                                        >
                                          <div className="variant_header pt-2">
                                            <span className="text-gray variant_name font-size-14">
                                              {variant?.variant} :
                                            </span>{" "}
                                            <span className="h6 fw-bold font-size-14 text-uppercase variant_selected">
                                              {VariantSelected?.prodId ===
                                              product?.product?._id
                                                ? VariantSelected?.variants[0]
                                                : ""}
                                            </span>
                                          </div>
                                          <div className="variant_body d-flex">
                                            {variant?.selectedOptions?.map(
                                              (option, optInd) => {
                                                return (
                                                  <p
                                                    key={optInd}
                                                    onClick={() => {
                                                      handleSelectVariant(
                                                        option?.name,
                                                        product?.product,
                                                        product?.product?._id
                                                      );
                                                    }}
                                                  >
                                                    <div className="product-color-shades mt-2 me-2">
                                                      <div
                                                        className={
                                                          VariantSelected
                                                            ?.variants[0] ===
                                                            option?.name &&
                                                          VariantSelected?.prodId ===
                                                            product?.product
                                                              ?._id
                                                            ? "Shades-div active"
                                                            : "Shades-div"
                                                        }
                                                      >
                                                        <img
                                                          src={
                                                            option?.image &&
                                                            option?.image
                                                              ? option?.image
                                                              : "/images/noImage.jpg"
                                                          }
                                                          alt=""
                                                          width={60}
                                                          height={60}
                                                        />
                                                      </div>
                                                    </div>
                                                  </p>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                                <div>{renderServiceProductOption(product)}</div>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <Button
                                    className="btn btn-primary btn-sm me-2"
                                    color="primary"
                                    onClick={() => {
                                      toggle();
                                      setActiveData(product);
                                    }}
                                  >
                                    <i className="bx bx-edit-alt bx-xs"></i>
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    className="btn btn-primary btn-sm"
                                    color="danger"
                                    onClick={() => {
                                      onClickDelete(product?._id);
                                    }}
                                  >
                                    <i className="bx bxs-trash-alt"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div className="d-flex -justify-content-between p-2">
                              <div className="w-100">
                                <div className=" d-flex flex-wrap w-100">
                                  <div
                                    className="product-name"
                                    style={{ width: "250px" }}
                                  >
                                    <span className="fw-bold">
                                      Product Id :{" "}
                                    </span>
                                    <span>{product?.product?.sku}</span>
                                    <div
                                      className="mt-2"
                                      style={{ width: "200px" }}
                                    >
                                      <span className="fw-bold">
                                        {product?.product?.name}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="me-3">
                                    <span className="fw-bold">Category : </span>
                                    <span>{product?.parentCategory?.name}</span>
                                  </div>
                                  <div className="me-3">
                                    <span className="fw-bold">Brand : </span>
                                    <span>{product?.brand?.name}</span>
                                  </div>
                                  <div className="">
                                    <span className="fw-bold">Price : </span>
                                    <span>
                                      ₹
                                      {product?.product?.price
                                        ? parseFloat(
                                            product?.product?.price
                                          )?.toLocaleString("en-IN", {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                          })
                                        : ""}
                                    </span>
                                  </div>
                                </div>
                                <div>{renderServiceProductOption(product)}</div>
                              </div>
                              <div className="d-flex">
                                <div>
                                  <Button
                                    className="btn btn-primary btn-sm me-2"
                                    color="primary"
                                    onClick={() => {
                                      toggle();
                                      setActiveData(product);
                                    }}
                                  >
                                    <i className="bx bx-edit-alt bx-xs"> </i>
                                  </Button>
                                </div>
                                <div>
                                  <Button
                                    className="btn btn-primary btn-sm"
                                    color="danger"
                                    onClick={() => {
                                      onClickDelete(product?._id);
                                    }}
                                  >
                                    <i className="bx bxs-trash-alt"></i>
                                  </Button>
                                </div>
                              </div>
                            </div>
                          )}
                        </Col>
                      );
                    })}
                  <hr />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  const renderBrandsInfo = () => {
    return (
      <div>
        <div>
          <Row>
            <div className="info_conetent_header d-flex mb-2">
              <h6>BRANDS INFO</h6>
            </div>
          </Row>
          <Card>
            <CardBody>
              <Row className="m-0 mb-2 p-3 option_div ">
                <Col md={12} className="text-center">
                  {brands && brands.length === 0 && (
                    <div className="empty_list p-3 pt-0">
                      <div className="text-center">
                        <i
                          className="mdi mdi-circle-off-outline"
                          id="EmptyCatList"
                        ></i>
                      </div>
                      <h4>Empty List</h4>
                      <p className="text-center">No Brands Found</p>
                    </div>
                  )}
                  <div className="service_img_container ">
                    {brands?.map((brand, index) => (
                      <div
                        className="img_card"
                        style={{ width: "100px" }}
                        key={index}
                      >
                        <img src={brand.logo ? brand.logo : avatar} />
                        <h6 className="mt-2 mb-3 text-center">
                          {brand?.name}{" "}
                        </h6>
                      </div>
                    ))}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </div>
      </div>
    );
  };

  const renderProductsInfo = () => {
    return (
      <div>
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>PRODUCTS INFO</h6>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className=" m-0 mb-2 p-2 option_div me-4">
              <Col md={12} className="px-0">
                {relatedProducts && relatedProducts.length === 0 && (
                  <div className="empty_list p-3 pt-0">
                    <div className="text-center">
                      <i
                        className="mdi mdi-circle-off-outline"
                        id="EmptyCatList"
                      ></i>
                    </div>
                    <h4>Empty List</h4>
                    <p className="text-center">No Products Found</p>
                  </div>
                )}
                <div className="service_img_container ">
                  {relatedProducts?.map((product, index) => {
                    return (
                      <Col
                        md={12}
                        className="border border-2 rounded mb-2"
                        key={index}
                      >
                        <div className="d-flex ">
                          <div
                            className="img_card p-2  "
                            style={{ width: "120px" }}
                            key={index}
                          >
                            <h6>
                              {product?.brand ? product?.brand?.name : "_"}
                            </h6>
                            <img
                              src={
                                product?.images[0]
                                  ? product?.images[0]
                                  : noImage
                              }
                            />
                            <h6 className=" mt-2 fw-bold text-center">
                              Price : ₹ {product?.price ? product?.price : "_"}
                            </h6>{" "}
                            <div className="text-center ">
                              <h6
                                className={
                                  product?.stockStatus === "In stock"
                                    ? textColor + "danger"
                                    : textColor + "success"
                                }
                              >
                                {product?.stockStatus
                                  ? product?.stockStatus
                                  : "_"}
                              </h6>{" "}
                            </div>
                          </div>
                          <div>
                            <h6 className="mt-3">{product?.name} </h6>
                            <div className="mt-3">
                              <p className="h6 mb-1">Description</p>
                              {product?.description &&
                              product?.description[0] !== "" ? (
                                <>
                                  {product?.description?.map((ele, index) => {
                                    return (
                                      <div key={index}>
                                        <div className="mb-2">
                                          <span className="fw-bold font-size-16">
                                            {ele?.name}
                                          </span>
                                        </div>
                                        <div className="product-info">
                                          <div
                                            className=""
                                            dangerouslySetInnerHTML={{
                                              __html: ele?.value,
                                            }}
                                          />
                                        </div>
                                      </div>
                                    );
                                  })}
                                </>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </Col>
                    );
                  })}
                  <hr />
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };
  const renderOptionsInfo = () => {
    return (
      <div className="m-3 services_options_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>OPTIONS INFO</h6>
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("options")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>
        <Card>
          <CardBody>
            {values?.optionsDetails?.length === 0 && (
              <div className="empty_list p-3 pt-0">
                <div className="text-center">
                  <i
                    className="mdi mdi-circle-off-outline"
                    id="EmptyCatList"
                  ></i>
                </div>
                <h4>Empty List</h4>
                <p className="text-center">No Options Found</p>
              </div>
            )}
            {values?.optionsDetails?.map((ele, ind) => {
              console.log(ele, "ele");
              return (
                <Row key={ind} className="m-0 mb-2 p-2 option_div">
                  <Col md={12} className="d-flex">
                    <p className="fw-bold mb-1">Option Title :</p>
                    <p className="text-muted ms-2">
                      {ele.optionTitle ? ele.optionTitle : "_"}
                    </p>
                  </Col>
                  <Col md={12} className="">
                    <div className="service_img_container">
                      {ele?.values?.map((e, eind) => (
                        <div
                          className="img_card text-center me-3"
                          key={ind + " " + eind}
                        >
                          <img src={e.image ? e.image : avatar} />
                          <div className="d-flex justify-content-center align-items-center">
                            <p className="ms-0 mb-0">
                              {e?.status === "Active" ? (
                                <i className="bx bxs-circle text-success font-size-8 me-1"></i>
                              ) : (
                                <i className="bx bxs-circle text-danger font-size-8 me-1"></i>
                              )}
                            </p>
                            <p className="mt-1 mb-1 text-center">{e?.name} </p>
                          </div>
                          <p className="text-success">
                            {e?.serviceExtra ? e?.serviceExtra : 0}% Extra
                          </p>
                        </div>
                      ))}
                    </div>
                  </Col>
                </Row>
              );
            })}
          </CardBody>
        </Card>
      </div>
    );
  };
  const renderKeywordsInfo = () => {
    return (
      <div className="m-3 services_options_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h6>KEYWORDS INFO</h6>
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
              to="#"
              onClick={() => handleEdit("keywords")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className="m-0 p-3 option_div ">
              <Col md={12} className="">
                <div className="service_img_container ">
                  {values?.keyWords?.map((ele, index) => (
                    <div key={index}>
                      <h6 className="me-2 text-center bg-light px-2 p-1">
                        {ele}{" "}
                      </h6>
                    </div>
                  ))}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };
  const renderRelatedArticlesInfo = () => {
    return (
      <div className="m-3 services_options_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2 align-items-center ">
            <h6>RELATED ARTICLES INFO</h6>
            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3 "
              to="#"
              onClick={() => handleEdit("Articles")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>
        <Card>
          <CardBody>
            <Row className="m-0 option_div ">
              <Col md={12} className="">
                <div className="service_img_container ">
                  {values?.articles?.length > 0 &&
                    values?.articles?.map((ele, index) => (
                      <div key={index} className="m-2">
                        <div className="d-flex align-items-center ">
                          <img
                            src={ele?.featuredImage}
                            width={40}
                            height={40}
                          />
                          <h6 className="me-2 text-center bg-light px-2 p-1 m-2">
                            {ele?.title}{" "}
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    );
  };

  return (
    <React.Fragment>
      {renderBasicInfo()}
      {renderOptionsInfo()}
      <div className="m-3 services_options_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2 ">
            <h5>SERVICES BRANDS & PRODUCTS INFO</h5>

            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3 "
              to="#"
              onClick={() => handleEdit("ServiceProducts")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Add</span>
            </Link>
          </div>
        </Row>

        <Row>
          {/* <Col md={3}> {renderServiceBrandsInfo()}</Col> */}
          <Col md={12}>{renderServiceProductsInfo()}</Col>
        </Row>
      </div>
      <div className="m-3 services_options_info">
        <Row>
          <div className="info_conetent_header d-flex mb-2 ">
            <h5>RELATED BRANDS & PRODUCTS INFO</h5>

            <Link
              className="btn btn-sm btn-primary btn-bg-primary d-flex px-3 "
              to="#"
              onClick={() => handleEdit("brandsProducts")}
            >
              <i className="bx bx-edit-alt bx-xs pe-1"> </i>
              <span> Edit</span>
            </Link>
          </div>
        </Row>

        <Row>
          <Col md={3}> {renderBrandsInfo()}</Col>
          <Col md={9}>{renderProductsInfo()}</Col>
        </Row>
        <Row>{renderRealtedServiceInfo()}</Row>
      </div>

      {renderRelatedArticlesInfo()}
      {ServiceInfo()}
      {renderKeywordsInfo()}
      <EditInfoModal
        initValues={serviceValues}
        toggle={editTooggle}
        modal={editModal}
        tabName={activeTab}
        parentCategory={parentCategory}
        subCategoryList={subCatList}
        reloadService={reloadService}
      />
      <EditServiceProducts
        initValues={serviceValues}
        toggle={toggle}
        modal={modal}
        activedata={activeData}
        reloadService={reloadService}
      />
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};

export default View;
