import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { isEmpty } from "lodash";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import * as Yup from "yup";
import { useFormik } from "formik";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";

import { Button, Card, CardBody, Col, Container, Row, UncontrolledTooltip } from "reactstrap";
 
import {getGallery,deleteGallerybyId} from "services/gallery.service"
//redux

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";
import SweetAlert from "react-bootstrap-sweetalert";



const GalleryList = props => {
  
  const [galleryList,setgallery] = useState([])

  const [deleteId, setDeleteid] = useState(null);
  const [delete_modal, setDelete_modal] = useState(false);

  useEffect(()=>{
    getAllList()
},[])
const handleOrderClicks = () => {};

  // validation
const getAllList=()=>{
    getGallery().then(res=>{
        console.log(res)
        setgallery(res.data)
        console.log(res)
    }).catch(err=>{
        console.log(err)
    })
}
const handleOnDelete = () => {
    removeProviderType(deleteId)
  };
const onClickDelete=(id)=>{
    setDeleteid(id)
    setDelete_modal(true);
}
const deleteTheRecord=(id)=>{
    deleteGallerybyId(id).then(res=>{
        console.log(res)
        setgallery(res.data)
        console.log(res)
    }).catch(err=>{
        console.log(err)
    })
}
const stayHere = () => {
    setDelete_modal(false);
  };
  //pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: galleryList?.length, // replace later with size(galleryList),
    custom: true,
  };
  const { SearchBar } = Search;

  const column = () => [
    {
      dataField: "description",
      text: "Description",
      formatter: (col, row) => {
        return (
          <span
            style={{
              display: "block",
              width: "300px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            dangerouslySetInnerHTML={{__html: row.description}}
          />
          
        );
      },
    },
    {
        dataField:"provider.name",
        text:"Provider"
    },
    {
        dataField: "image",
        text: "Image",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <img
            src={
              row.image?.file?.data
                ? `data:${row.image.mimetype};base64,${Buffer.from(
                    row.image.file.data
                  ).toString("base64")}`
                : logo
            }
            alt=""
            className="avatar-sm"
          />
        ),
      },
    
    {
      dataField: "updatedAt",
      text: "Updated At",
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, user) => (
       <>
        <div className="d-flex gap-3">
            <Link
              to={`/edit-gallery/${user._id}`}
              className="text-success"
            >
              <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Edit
              </UncontrolledTooltip>
            </Link>
            <Link
              to="#"
              className="text-danger"
              onClick={() => onClickDelete(user._id)}
            >
              <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>
            </Link>
          </div>
       </>
      ),
    },
  ];

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  

  const defaultSorted = [
    {
      dataField: "orderId",
      order: "desc",
    },
  ];

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro - -</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Gallery" breadcrumbItem="Gallery List" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={column()}
                    data={galleryList}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        data={galleryList}
                        columns={column()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                              <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Link
                                    to="/create-gallery"
                                    className="has-arrow "
                                  >
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded  mb-2 me-2"
                                      onClick={handleOrderClicks}
                                    >
                                      <i className="bx bx-user-plus me-1" />
                                      Create Gallery
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mb-2">
                                                            <Col sm="8">
                                <div className="text-sm-end">
                                 {/* Button */}
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {delete_modal ? deletePopup() : null}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(GalleryList);
