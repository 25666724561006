export const getProducts = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Products ${e}`));
};
export const getProduct = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/get/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Product ${e}`));
};
export const deleteProduct = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Product ${e}`));
};
export const createProduct = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/create`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Product create ${e}`));
};
export const updateProduct = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Product create ${e}`));
};
export const getProductByCatId = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/query`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Product By Category ${e}`));
};
export const getProductByBrands = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/brands`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at category query ${e}`));
};
export const deleteProductByParent = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/parentcategory/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Product ${e}`));
};
export const deleteProductBySub = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      console.log(res, "res");
      return res.json();
    })
    .catch(e => console.log(`error at get Product ${e}`));
};
export const updateProductFeilds = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/updatefeild/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Product create ${e}`));
};
export const getSkuCode = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/sku`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Sku code ${e}`));
};
export const getProductsByMultiCategory = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/bymulticategory`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at service query ${e}`));
};
