import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button, FormGroup } from "reactstrap";
import Select, { components } from "react-select";
import { getBrands } from "services/ecommerce/brand.service";
import {
  getProducts,
  getProductByBrands,
} from "services/ecommerce/product.service";
import { updatefeildService } from "services/services.service";

export const BrandProductInfoModal = ({
  toggle,
  initValues,
  reloadService,
}) => {
  const [values, setValues] = useState();
  const [brandsList, setBrandslist] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState(null);
  const [productsList, setProductslist] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState(null);

  useEffect(() => {
    getBrandsList();
    const { brands } = initValues;
    setSelectedBrands(buildOptions(brands));
    getProductsList();
    const { relatedProducts } = initValues;
    setSelectedProducts(buildOptions(relatedProducts));
  }, []);
  const getBrandsList = () => {
    getBrands()
      .then(res => {
        if (res.data) {
          const result = buildOptions(res?.data);
          setBrandslist(result);
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  const getProductsList = body => {
    getProductByBrands(body)
      .then(res => {
        // console.log(res.data);
        if (res?.data) {
          const result = _(res.data)
            .groupBy(x => x?.brand?.name)
            .map((value, key) => ({ label: key, options: value }))
            .filter(ele => {
              ele.options = ele.options.filter(opt => {
                opt["label"] = opt.name;
                opt["value"] = opt._id;
                return opt;
              });
              return ele;
            })
            .value();
          setProductslist(result);
        } else {
          console.error(res);
        }
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  function handleBrands(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["brands"]: data });
    setSelectedBrands(selectedMulti);
    // console.log(data);
    if (selectedBrands) {
      let obj = {
        brands: data,
      };
      console.log(obj);
      getProductsList(obj);
    }
  }
  //   //Build {label,value}array
  const buildOptions = list => {
    const result = list?.map(ele => {
      let obj = {
        label: ele.name,
        value: ele._id,
      };
      return obj;
    });
    return result;
  };

  function handleProducts(selectedMulti) {
    const data = [];
    selectedMulti.map(e => {
      data.push(e.value);
    });
    setValues({ ...values, ["relatedProducts"]: data });
    setSelectedProducts(selectedMulti);
  }
  const onSubmit = () => {
    let body = { ...values };
    updatefeildService(initValues._id, body)
      .then(res => {
        // console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };
  return (
    <>
      <Row className="mt-3 mx-0">
        <Col md={12} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="brands">Brands</Label>
            <Select
              id="brands"
              value={selectedBrands}
              isMulti={true}
              onChange={event => {
                handleBrands(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={brandsList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <Row className="mt-3 mx-0">
        <Col md={12} className="px-3">
          <FormGroup className="mb-3">
            <Label htmlFor="relatedProducts">Products</Label>
            <Select
              id="relatedProducts"
              value={selectedProducts}
              isMulti={true}
              onChange={event => {
                handleProducts(event);
                const data = [];
                event.map(e => {
                  data.push(e.value);
                });
              }}
              options={productsList}
              classNamePrefix="select2-selection"
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default BrandProductInfoModal;
