import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button } from "reactstrap";
import * as _ from "lodash";

import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import { updatefilefeildService } from "services/services.service";

const ImageInfoModal = ({ toggle, initValues, reloadService }) => {
  let initImgVal = initValues?.images ? _.cloneDeep(initValues?.images) : [];
  const [values, setValues] = useState({
    images: initImgVal,
  });
  const [selectedImages, setSelectedImages] = useState([]);
  function removeImage(index) {
    let imgArray = values["images"];
    imgArray.splice(index, 1);
    setValues({ ...values, ["images"]: imgArray });
  }

  function handleAcceptedFiles(files, field) {
    let imgArray = values["images"];
    if (imgArray.length < 7) {
      files.map(file =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
          formattedSize: formatBytes(file.size),
        })
      );

      imgArray.push(files[0]);
      setValues({ ...values, [field]: imgArray });

      selectedImages.push(files[0]);
      setSelectedImages(_.cloneDeep(selectedImages));
    } else {
      alert("Maximum 7 Images are allowed");
    }
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  let { images } = values;

  const onSubmit = () => {
    let formData = new FormData();
    let imgArr = [];
    images.forEach(ele => {
      if (typeof ele === "string") {
        imgArr.push(ele);
      }
    });
    formData.set("images", JSON.stringify(imgArr));

    for (let key in selectedImages) {
      formData.set("image" + key, selectedImages[key]);
    }
    // let body = [...values];
    updatefilefeildService(initValues._id, formData)
      .then(res => {
        console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => {
        console.log(e);
      });
  };
  return (
    <>
      <Row>
          <Label htmlFor="firstname-Input" className="col-form-label">
            Images
          </Label>
        <Col md={6} className="">
          <Dropzone
            onDrop={acceptedFiles => {
              handleAcceptedFiles(acceptedFiles, "images");
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div className="dropzone image_drop mt-1">
                <div className="dz-message needsclick" {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div className="">
                    <i className="display-6 text-muted bx bxs-cloud-upload" />
                  </div>
                  <h6>Drop files here or click to upload.</h6>
                </div>
              </div>
            )}
          </Dropzone>
          </Col>
          <Col md={6}>
          <div className="dropzone-previews" id="file-previews">
            {images &&
              images.length > 0 &&
              images.map((img, index) => {
                return (
                  <div
                    key={index}
                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                    style={{
                      position: "relative",
                    }}
                  >
                    <div className="p-2">
                      <Row className="align-items-center">
                        <Col className="col-auto">
                          <ProfileImage
                            image={img}
                            height="80"
                            classNames="avatar-sm rounded bg-light"
                          />
                        </Col>
                        <Col>
                          <Link to="#" className="text-muted font-weight-bold">
                            {img.name || img.filename}
                          </Link>
                          <p className="mb-0">
                            <strong>{img.formattedSize}</strong>
                          </p>
                        </Col>
                      </Row>
                    </div>
                    {/* <div> */}
                    <i
                      style={{
                        position: "absolute",
                        right: "3px",
                        cursor: "pointer",
                        top: 0,
                      }}
                      className="mdi mdi-close-thick font-size-18 text-danger"
                      id="removeImgtooltip"
                      onClick={() => removeImage(index)}
                    />
                    {/* </div> */}
                  </div>
                );
              })}
          </div>
        </Col>
      </Row>
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Save
        </Button>
      </div>
    </>
  );
};
export default ImageInfoModal;
