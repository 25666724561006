import React, { useState } from "react";
import {
  Button,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import Select from "react-select";
import Spinner from "components/spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import { AssignNote } from "services/AdminNotes";

const Sendmail = ({
  toggle,
  modal,
  data,
  userslist,
  ImportanceList,
  stayHere,
}) => {
  const [selectedUser, setselectedUser] = useState();
  const [selectedPriority, setSelectedPriority] = useState();
  const [subject, setsubject] = useState("");
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const OnSubmit = () => {
    setLoading(true);
    const body = {
      id: data?._id,
      action: selectedUser?.value,
      subject: subject,
    };
    AssignNote(body)
      .then(res => {
        if (res.success === true) {
          setsuccess_msg(true);
          setLoading(false);
        }
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };
  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Note Assigned
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
    setSelectedPriority();
    setselectedUser();
    setsubject("");
  };
  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>Admin Notes</ModalHeader>
        <ModalBody>
          <Container fluid={false}>
            <Row>
              <Col md={5}>
                <div className="">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Importance</Label>
                    <Select
                      id="importance"
                      value={selectedPriority}
                      onChange={event => {
                        setSelectedPriority(event);
                      }}
                      name="importance"
                      options={ImportanceList}
                      classNamePrefix="select2-selection"
                    />{" "}
                  </FormGroup>
                </div>
              </Col>
              <Col md={5}>
                <div className="">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Action</Label>
                    <Select
                      id="action"
                      value={selectedUser}
                      onChange={event => {
                        setselectedUser(event);
                      }}
                      name="action"
                      options={userslist}
                      classNamePrefix="select2-selection"
                    />{" "}
                  </FormGroup>
                </div>
              </Col>
              <Col md={6}>
                <div className="">
                  <FormGroup className="mb-3">
                    <Label htmlFor="validationCustom01">Subject</Label>
                    <Input
                      name="subject"
                      placeholder="subject"
                      type="text"
                      className="form-control"
                      id="subject"
                      // value={values.subject}
                      onChange={event => setsubject(event.target.value)}
                    />{" "}
                  </FormGroup>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={leaveToList}
              >
                cancel
              </Button>
              <Button
                color="primary"
                type="submit"
                className="w-md btn-sm float-end"
                onClick={OnSubmit}
              >
                Assign
              </Button>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
      <Spinner loading={loading} />
    </>
  );
};

export default Sendmail;
