import React, { useEffect, useState, useRef } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import SweetAlert from "react-bootstrap-sweetalert";

import Switch from "react-switch";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import * as moment from "moment";

import Spinner from "components/spinner";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";

import { deleteSetting, getSettings } from "services/settings";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import logo from "assets/images/logo.svg";

const SettingsList = props => {
  const [list, setList] = useState([]);

  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getAllList();
  }, []);
  // validation
  const getAllList = () => {
    setLoading(true);
    getSettings()
      .then(res => {
        let result = res.data;
        setList(result);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };
  const removeSetting = id => {
    setLoading(true);
    deleteSetting(id)
      .then(res => {
        setDelete_modal(false);
        getAllList();
      })
      .catch(err => {
        console.log(err);
      });
    setLoading(false);
  };

  //pagination customization
  const pageOptions = {
    sizePerPage: 5,
    totalSize: list.length, // replace later with size(list),
    custom: true,
  };
  const { SearchBar } = Search;
  const badgeColor = "font-size-12 badge-soft-";

  const SettingsColumn = () => [
    {
      dataField: "applicationLogo",
      text: "Logo",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <img
          src={
            row?.applicationLogo
              ? `data:${row.applicationLogo.mimetype};base64,${Buffer.from(
                  row.applicationLogo.file.data
                ).toString("base64")}`
              : logo
          }
          alt=""
          className="rounded-circle avatar-sm"
        />
      ),
    },
    {
      dataField: "applicationName",
      text: "Application Name",
    },
    {
      dataField: "providerAppName",
      text: "Provider App Name",
    },
    {
      dataField: "themeContract",
      text: "Theme Contract",
    },
    {
      dataField: "navbarColor",
      text: "Navbar Color",
    },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              {/* <Link className="text-success" to={`/setting-view/${row._id}`}>
                <i className="mdi mdi-file-document font-size-18" id="view"></i>
              </Link> */}
              <Link
                to={`/edit-settings/${row._id}`}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <div
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(row._id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeSetting(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Global Settings | stylepro - -
          </title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid>
          <Breadcrumbs title="List" breadcrumbItem="Global Settings" />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={SettingsColumn()}
                    data={list}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={list}
                        columns={SettingsColumn()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="8">
                                <div className="text-sm-end">
                                  <Link
                                    to="/create-settings"
                                    className="has-arrow "
                                  >
                                    <Button
                                      type="button"
                                      color="primary"
                                      className="btn-rounded  mb-2 me-2"
                                    >
                                      <i className="bx bx-user-plus me-1" />
                                      Create Settings
                                    </Button>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  { list.length == 0 &&<p className="text-center"> No Data Found</p> }
                  {delete_modal ? deletePopup() : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SettingsList;
