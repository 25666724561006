import { useState } from "react"
import BootstrapTable from "react-bootstrap-table-next"
import cellEditFactory from "react-bootstrap-table2-editor"
import { useEffect } from "react"
import { CardBody, UncontrolledTooltip } from "reactstrap"
import paginationFactory from "react-bootstrap-table2-paginator"

import React from "react"

export const AdditionalServiceCost = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    const getData = () => {
      fetch("data.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          console.log(response)
          return response.json()
        })
        .then(function (myJson) {
          console.log(myJson)
          setData(myJson)
        })
    }
    getData()
  }, [])
  const columns = [
    {
      dataField: "id",
      text: "Option ID",
    },
    {
      dataField: "heading",
      text: "Option Heading",
    },
    {
      dataField: "name",
      text: "Option Name",
    },
    {
      dataField: "price",
      text: "Additonal Price",
    },
    {
      dataField: "action",
      text: "Action",
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <i
                className="mdi mdi-delete font-size-18 text-danger"
                id="deletetooltip"
              />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>

              <i
                className="mdi mdi-check-bold font-size-18 text-success"
                id="edittooltip"
              />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Save
              </UncontrolledTooltip>
            </div>
          </>
        )
      },
    },
  ]

  return (
    <CardBody>
      <h6 className="text-primary">
        Additional Service Cost(Add-on to Basic Service Cost)
      </h6>
      <BootstrapTable
        className="ps-3 pe-3"
        keyField="id"
        data={data}
        columns={columns}
        cellEdit={cellEditFactory({ mode: "click" })}
      />
    </CardBody>
  )
}
