import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import BasicInfoModal from "./basicInfoModel";
import ImageInfoModal from "./imageInfoModal";
import ServiceInfoModal from "./serviceInfoModal";
import OptionInfoModal from "./optionsInfoModal";
import BannerInfoModal from "./bannerInfoModal";
import ProductBrandInfo from "./productInfoModal";
import BrandInfoModel from "./brandInfoModel";
import ProductInfoModal from "./productInfoModal";
import BrandProductInfoModal from "./brandsProductsInfoModal";
import RelatedServiceInfo from "./relatedServiceInfo";
import SearchKeyInfoModal from "./searchKeyInfoModal";
import RelatedArticleModal from "./relatedArticleModal";
import ServiceProductsinfo from "./serviceProductsinfo";
import EditServiceProducts from "./EditServiceProducts";

const EditInfoModal = ({
  toggle,
  modal,
  tabName,
  initValues,
  parentCategory,
  subCategoryList,
  reloadService,
}) => {
  const renderChildModal = () => {
    if (tabName === "basic") {
      return (
        <BasicInfoModal
          initValues={initValues}
          parentCategory={parentCategory}
          subCategoryList={subCategoryList}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "images") {
      return (
        <ImageInfoModal
          initValues={initValues}
          parentCategory={parentCategory}
          subCategoryList={subCategoryList}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "service") {
      return (
        <ServiceInfoModal
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "options") {
      return (
        <OptionInfoModal
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "banner") {
      return (
        <BannerInfoModal
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "brands") {
      return (
        <BrandInfoModel
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "products") {
      return (
        <ProductInfoModal
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "brandsProducts") {
      return (
        <BrandProductInfoModal
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "relatedService") {
      return (
        <RelatedServiceInfo
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "keywords") {
      return (
        <SearchKeyInfoModal
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "Articles") {
      return (
        <RelatedArticleModal
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    } else if (tabName === "ServiceProducts") {
      return (
        <ServiceProductsinfo
          initValues={initValues}
          toggle={toggle}
          reloadService={reloadService}
        />
      );
    }
    //  else if (tabName === "EditServicesinfo") {
    //   return (
    //     <EditServiceProducts
    //       initValues={initValues}
    //       toggle={toggle}
    //       reloadService={reloadService}
    //     />
    //   );
    // }
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>{tabName?.toUpperCase()} INFO</ModalHeader>
        <ModalBody className="px-4">{renderChildModal()}</ModalBody>
      </Modal>
    </>
  );
};

export default EditInfoModal;
