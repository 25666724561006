import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button, Input } from "reactstrap";
import * as _ from "lodash";

import { Link } from "react-router-dom";
import Dropzone from "react-dropzone";
import ProfileImage from "components/profileImage";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { updatefilefeildService } from "services/services.service";

const ServiceInfoModal = ({ toggle, initValues, reloadService }) => {
  console.log(initValues, "init values");
  const [values, setValues] = useState([]);
  const [isDelete, setDelete] = useState(false);
  const [images, setimages] = useState({});
  useEffect(() => {
    if (initValues) {
      const service = _.cloneDeep(initValues);
      const serInfo = service.serviceInfo ? service.serviceInfo : [];
      setValues([...serInfo]);
    }
  }, []);

  const handleChange = (field, value, index) => {
    setDelete(true);
    const data = [...values];
    data[index][field] = value;
    setValues(data);
  };

  const addInfo = () => {
    setDelete(true);
    const data = [...values];
    data.push({
      key: "",
      value: "",
    });
    setValues(data);
  };

  const remove = i => {
    const data = [...values];
    data.splice(i, 1);
    setValues(data);
  };

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleAcceptedFiles(index, files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    const imagesdata = { ...images };
    imagesdata[`info${index}`] = files[0];
    setimages(imagesdata);
    handleChange("image", files[0], index);
  }
  const onSubmit = () => {
    setDelete(false);
    let formData = new FormData();
    formData.set("serviceInfo", JSON.stringify(values));
    for (let key in images) {
      formData.set(key, images[key]);
    }
    // let body = [...values];
    updatefilefeildService(initValues._id, formData)
      .then(res => {
        console.log(res);
        reloadService();
        toggle();
      })
      .catch(e => {
        console.log(e);
      });
  };

  return (
    <>
      <Row>
        <div className="d-flex mb-2 justify-content-end">
          <Link
            className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
            to="#"
            onClick={() => addInfo()}
          >
            <i className="bx bx-edit-alt bx-xs pe-1"> </i>
            <span> Add Info</span>
          </Link>
        </div>
      </Row>
      <Row className="serviceInfoModal">
        <Col md={12} className="px-3">
          {values
            ? values?.map((e, i) => (
                <Row className="m-0 mb-3 row p-2 pt-3 service_info_li" key={i}>
                  <Col sm={11} className="mb-3">
                    <Row>
                      <div>
                        <Label>Title</Label>
                      </div>

                      <Col sm={12} className="custom_ck_editor">
                        <Input
                          name="customQuantityUnit"
                          type="text"
                          className="form-control mb-3"
                          id="customQuantityUnit-Input"
                          onChange={event =>
                            handleChange("key", event.target.value, i)
                          }
                          value={e.key}
                        />
                      </Col>
                      <div>
                        <Label>Author Name</Label>
                      </div>

                      <Col sm={12} className="custom_ck_editor">
                        <Input
                          name="authorName"
                          type="text"
                          className="form-control mb-3"
                          id="authorName"
                          onChange={event =>
                            handleChange("authorname", event.target.value, i)
                          }
                          value={e.authorname}
                        />
                      </Col>
                      <div>
                        <Label>Details</Label>
                      </div>
                      <Col sm={12} className="custom_ck_editor">
                        <CKEditor
                          id="description"
                          editor={ClassicEditor}
                          data={e.value ? e.value : ""}
                          //   onReady={editor => {
                          //     if (e.value) {
                          //       editor.setData(e.value);
                          //     }
                          //   }}
                          onChange={(event, editor) => {
                            const data = editor.getData();
                            handleChange("value", data, i);
                          }}
                        />
                      </Col>
                      <Col sm={6} className="">
                        <Dropzone
                          onDrop={acceptedFiles => {
                            handleAcceptedFiles(i, acceptedFiles);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="dropzone">
                              <div
                                className="dz-message needsclick"
                                {...getRootProps()}
                              >
                                <input {...getInputProps()} />
                                <div className="">
                                  <i className="display-6 text-muted bx bxs-cloud-upload" />
                                </div>
                                <h6>Drop file here or click to upload</h6>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </Col>
                      {e.image && (
                        <Col sm={6} className="custom_ck_editor">
                          <div className="mt-4 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                            <div className="p-2">
                              <Row className="align-items-center">
                                <Col className="col-auto">
                                  <ProfileImage
                                    image={e.image}
                                    height="80"
                                    classNames="avatar-sm rounded bg-light"
                                  />
                                </Col>
                                <Col>
                                  <Link
                                    to="#"
                                    className="text-muted font-weight-bold"
                                  >
                                    {e.image?.name || e.image?.filename}
                                  </Link>
                                  <p className="mb-0">
                                    <strong>{e.image?.formattedSize}</strong>
                                  </p>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      )}
                    </Row>
                  </Col>
                  <Col md={1} className="">
                    <div className="serInfo_del">
                      <i
                        className="mdi mdi-delete font-size-18 text-danger"
                        onClick={() => remove(i)}
                      ></i>
                    </div>
                  </Col>
                  {/* <Col md={1} className="">
                    {isDelete && (
                      <div className="serInfo_del">
                        <i
                          className="mdi mdi-delete font-size-18 text-danger"
                          onClick={() => remove(i)}
                        ></i>
                      </div>
                    )}
                  </Col> */}
                </Row>
              ))
            : ""}
        </Col>
      </Row>
      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Save
        </Button>
      </div>
    </>
  );
};

export default ServiceInfoModal;
