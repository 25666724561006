export const getFilters = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/filters/get`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Getting filters ${e}`));
};

export const updateFilters = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/filters/update`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Updating filters ${e}`));
};