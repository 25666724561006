import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import { Link } from "react-router-dom";
import editIcon from "assets/icons-image/edit-icon.svg";
import ZipcodeList from "pages/ZipCode/list";

const ZipcodeInfo = ({ zipInitialValues, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [zipcodeList, setZipcodeList] = useState(zipInitialValues);
  const [zipList, setZipList] = useState([]);

  useEffect(() => {
    setZipcodeList(zipInitialValues);
    setIsEdit(false);
  }, [zipInitialValues]);

  const toggleEdit = () => {
    let edit = isEdit;
    setIsEdit(!isEdit);
  };

  const handleChange = (value, index, type) => {
    let list = [...zipcodeList];
    list[index][type] = value;
    setZipcodeList(list);
  };
  const addZipcode = () => {
    let newFields = {
      zipcode: "",
      address: "",
    };
    setZipcodeList([...zipcodeList, newFields]);
  };
  const deleteZipCode = index => {
    let zipList = zipcodeList;
    zipList.splice(index, 1);
    setZipcodeList(zipList);
  };
  const save = () => {
    const zipcodes = zipcodeList;
    handleSubmit({ zipcodes });
  };

  return (
    <Card className="border-light">
      <CardBody className="service_locations">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h5>ZIPCODE INFO</h5>
            {isEdit ? (
              <Button
                color="primary"
                className=" btn-sm d-flex align-items-center px-3"
                onClick={() => addZipcode()}
              >
                <i className="bx bx-plus font-size-16 fw-bold me-2"></i>
                <span>ADD</span>
              </Button>
            ) : (
              <Link
                className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
                to="#"
                onClick={() => toggleEdit()}
              >
                <i className="bx bx-edit-alt bx-xs pe-1"> </i>
                <span> Edit</span>
              </Link>
            )}
          </div>
        </Row>
        <Row>
          <Col md={2}>
            <h5 className="font-size-14 zipcode_header">Sl. no </h5>
          </Col>
          <Col md={4}>
            <h5 className="font-size-14 zipcode_header">
              ZIP Code / PIN Code{" "}
            </h5>
          </Col>
          <Col md={5}>
            <h5 className="font-size-14 zipcode_header"> Location Name </h5>
          </Col>
        </Row>
        {zipcodeList?.map((ele, ind) => {
          return (
            <Row key={ind} className="my-3">
              <Col md={2}>
                <h5 className="font-size-14 zipcode_td sl_no">{ind + 1}</h5>
              </Col>
              <Col md={3}>
                <Input
                  className="font-size-14 zipcode_td"
                  value={ele.zipcode}
                  disabled={!isEdit}
                  onChange={event =>
                    handleChange(event.target.value, ind, "zipcode")
                  }
                />
              </Col>
              <Col md={5}>
                <Input
                  className="font-size-14 zipcode_td"
                  value={ele.address}
                  disabled={!isEdit}
                  onChange={event =>
                    handleChange(event.target.value, ind, "address")
                  }
                />
              </Col>

              <Col md={1}>
                <Button
                  color="danger"
                  className={
                    isEdit
                      ? "btn-sm me-2 py-1 service_remove_btn"
                      : "btn-sm me-2 py-1 service_remove_btn disabled"
                  }
                  // className=" btn-sm me-2 py-2 service_remove_btn"
                  onClick={() => {
                    deleteZipCode(ind);
                  }}
                >
                  {" "}
                  <i className="bx bxs-trash-alt font-size-16"></i>
                </Button>
              </Col>
              {/* {ind == ZipcodeList.length - 1 ? (
                <Col md={1}>
                  <Button
                    color="primary"
                    className=" btn-sm py-2"
                    onClick={() => addZipcode()}
                  >
                    <i className="bx bx-plus font-size-16 fw-bold"></i>
                  </Button>
                </Col>
              ) : (
                ""
              )} */}
            </Row>
          );
        })}
        {isEdit && (
          <Row>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={() => toggleEdit()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="w-md btn-sm ms-2"
                type="button"
                onClick={() => save()}
              >
                Save
              </Button>
            </div>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default ZipcodeInfo;
