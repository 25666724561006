import React, { useState, useEffect } from "react";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { Link } from "react-router-dom";
import BootstrapTable from "react-bootstrap-table-next";

import facemassage from "assets/images/services/facemassage.jpg";
import plantroom from "assets/images/services/plantroom.jpg";
import therapy from "assets/images/services/therapy.jpeg";

import {getGallerybyId} from "services/gallery.service"

const Gallery = props => {
    const{id}=props
    const[galleryList,setlist]= useState([])
  useEffect(() => {
    getGallerybyId(id).then(res=>{
        console.log(res)
        setlist(res?.data)
    }).catch(e=>{
        console.log(e)
    })
  }, [id]);

//   let galleryList = [
//     {
//       description:
//         "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
//       profile: therapy,
//       provider: "Roofing Jones,Bechtelar and Bosco",
//       updatedAt: "10 months ago",
//     },
//     {
//       description:
//         "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto",
//       profile: facemassage,
//       provider: "Digital Action Ltd",
//       updatedAt: "10 months ago",
//     },
//     {
//       description:
//         "Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem",
//       profile: plantroom,
//       provider: "Concrete Gusikowski Ltd",
//       updatedAt: "10 months ago",
//     },
//   ];
  const Column = () => [
    {
      dataField: "description",
      text: "Description",
      formatter: (col, row) => {
        return (
          <span
            style={{
              display: "block",
              width: "300px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }} dangerouslySetInnerHTML={{__html: row.description}}/>        );
      },
    },
    {
        dataField: "image",
        text: "Image",
        // eslint-disable-next-line react/display-name
        formatter: (cellContent, row) => (
          <img src={row?.image ? `data:${row.image.mimetype};base64,${Buffer.from(row.image.file.data).toString('base64')}`: ""}
          alt="" className="rounded-circle avatar-sm" />
        ),
      },
    
    {
      dataField: "provider.legalname",
      text: "Provider",
    },
    {
      dataField: "updatedAt",
      text: "Updated At",
    },
    // {
    //   dataField: "action",
    //   isDummyField: true,
    //   text: "Action",
    //   // eslint-disable-next-line react/display-name
    //   formatter: (cellContent, user) => (
    //     <div className="d-flex gap-3">
    //       <Link className="text-success" to="#">
    //         <i className="mdi mdi-pencil font-size-18" id="edittooltip"></i>
    //       </Link>
    //       <Link className="text-danger" to="#">
    //         <i className="mdi mdi-delete font-size-18" id="deletetooltip"></i>
    //       </Link>
    //     </div>
    //   ),
    // },
  ];

  const handleClick = () => {};

  return (
    <React.Fragment>
      <div className="text-end">
        {/* <Button
          className="btn-sm px-3"
          color="primary"
          //   onClick={() => handleUpdate()}
        >
          Add Gallery
        </Button> */}
      </div>
      <Row className="mt-3">
        <Col xl="12">
          <div className="table-responsive">
           {galleryList? <BootstrapTable
              data={galleryList}
              columns={Column()}
              keyField="id"
              responsive
              bordered={false}
              striped={false}
              classes={"table align-middle table-nowrap table-check"}
              headerWrapperClasses={"table-light"}
            />:''}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default Gallery;
