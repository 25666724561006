import React, { useState, useEffect } from "react";
import { useParams } from "react-router";

import MetaTags from "react-meta-tags";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";

import { createSlide, getslide, updateSlide } from "services/slide.service";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const defaultValues = {
  order: "",
  image: {},
  text: "",
  buttonText: "",
  bgColor: "",
  buttonColor: "",
  textColor: "",
};

const CreateSlide = ({ toggle, modal, id = false, reload,props }) => {
  // const { id } = useParams();
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const initialValues = defaultValues;
  const validationSchema = Yup.object().shape({
    order: Yup.string().required("Please Enter order"),
    text: Yup.string().required("Please Enter text"),
    // image: Yup.object().shape({}).required("Please Select Image"),
  });
  const onSubmit = values => {
    if (id) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };
  const { image } = values;

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    createSlide(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        reload();
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    updateSlide(id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        reload();
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    // props.history.push("/slide-list");
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Slide created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <>
      {/* <div className="page-content">
        <MetaTags>
          <title>
            Form Layouts | stylepro - -
          </title>
        </MetaTags>
        <Spinner loading={loading} /> */}
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}> {id ? "Edit Slide" : "Create Slide"}</ModalHeader>
        <ModalBody className="bg-light">
        <Container fluid={false}>
          {/* <Breadcrumb
            title="Slide"
            breadcrumbItem={id ? "Edit Slide" : "Create Slide"}
          /> */}
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  setFieldTouched,
                  values,
                }) => {
                  useEffect(() => {
                    if (id) {
                      setLoading(true);
                      getslide(id)
                        .then(res => {
                          const data = { ...res.data };
                          const fields = [
                            "order",
                            "text",
                            "buttonText",
                            "bgColor",
                            "buttonColor",
                            "textColor",
                            "image",
                          ];
                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          setLoading(false);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-4">
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">Image</Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("image", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="statusValidation">
                                {" "}
                                {errors.image ? errors.image : ""}
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.image &&
                                  Object.keys(values.image).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.image}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.image.name ||
                                                values.image.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.image.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Order</Label>
                              <Input
                                name="order"
                                placeholder="order"
                                type="order"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.order}
                                invalid={
                                  touched.order && errors.order ? true : false
                                }
                              />
                              {touched.order && errors.order ? (
                                <FormFeedback type="invalid">
                                  {errors.order}
                                  {touched.order}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">Text</Label>
                              <Input
                                name="text"
                                placeholder="Text"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.text}
                                invalid={
                                  touched.text && errors.text ? true : false
                                }
                              />
                              {touched.text && errors.text ? (
                                <FormFeedback type="invalid">
                                  {errors.text}
                                  {touched.text}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Button Text
                              </Label>
                              <Input
                                name="buttonText"
                                placeholder="ButtonText"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.buttonText}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                TextColor
                              </Label>
                              <Input
                                name="textColor"
                                placeholder="Textcolor"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.textColor}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Button Color
                              </Label>
                              <Input
                                name="buttonColor"
                                placeholder="Button Color"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.buttonColor}
                              />
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                Background Color
                              </Label>
                              <Input
                                name="bgColor"
                                placeholder="Bg Color"
                                type="color"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.bgColor}
                              />
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="d-flex justify-content-end">
                      <Button
                          color="light"
                          className="w-md btn-sm me-3"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                        {!id ? (
                          <Button
                            color="primary"
                            type="submit"
                            className="w-md btn-sm me-4"
                            // onClick={saveChanges}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="w-md btn-sm me-4"
                            type="submit"
                            // onClick={editChanges}
                          >
                            Save Changes
                          </Button>
                        )}
                       
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
        {/* </div> */}
        </ModalBody>
      </Modal>
    </>
  );
};

export default CreateSlide;
