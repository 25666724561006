import React, { useState, useEffect, useMemo } from "react";
import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Link } from "react-router-dom";

import Select from "react-select";
import ProfileImage from "components/profileImage";
import Dropzone from "react-dropzone";
import ModalImage from "react-modal-image";

const modelInitialValues = {
  name: "",
  docType: null,
  docs: [],
};

const Certificates = ({ certificateInitialValues, handleSubmit }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [certificateInfo, setcertificateInfo] = useState([
    ...certificateInitialValues,
  ]);
  const [modelProps, setModelProps] = useState(modelInitialValues);
  const [modal, setModal] = useState(false);
  const [statusModal, setStatusModal] = useState(false);
  const [selectedType, setSelectedType] = useState(null);
  const [selectedStatus, setSelectedStaus] = useState(null);
  const [activeDocIndex, setActiveDocIndex] = useState(null);
  const [activeDoc, setActiveDoc] = useState(null);
  const [activeCertificate, setActiveCertificate] = useState(null);
  const [certificateModal, setCertificateModal] = useState(false);

  const typeList = [
    { label: "Aadhar Card", value: "Aadhar Card" },
    { label: "PAN Card", value: "PAN Card" },
    { label: "Electricity Bill", value: "Electricity Bill" },
    { label: "GST Registration", value: "GST Registration" },
    {
      label: "Certificate of Incorporation",
      value: "Certificate of Incorporation",
    },
    { label: "Drivers License", value: "Drivers License" },
    { label: "Passport", value: "Passport" },
    { label: "College Certificate", value: "College Certificate" },
  ];

  const statusType = [
    { label: "Verified", value: "Verified" },
    { label: "Rejected", value: "Rejected" },
    { label: "Pending", value: "Pending" },
  ];

  useEffect(() => {
    setcertificateInfo([...certificateInitialValues]);
    setIsEdit(false);
  }, [certificateInitialValues]);

  const toggleModel = () => setModal(!modal);
  const toggleStatusModel = () => setStatusModal(!statusModal);
  const toggleEdit = () => {
    setIsEdit(!isEdit);
  };

  function handleModelPropsChange(name, value) {
    let obj = { ...modelProps };
    obj[name] = value;
    setModelProps(obj);
  }
  function handleSelectType(event) {
    handleModelPropsChange("docType", event.value);
    setSelectedType(event);
  }

  function handleStatusPropsChange(name, value) {
    let obj = { ...activeDoc };
    obj[name] = value;
    setActiveDoc(obj);
  }

  function handleSelectStatus(event) {
    handleStatusPropsChange("status", event.value);
    setSelectedStaus(event);
  }

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const handleDelete = index => {
    let docs = [...certificateInfo];
    docs.splice(index, 1);
    setcertificateInfo(docs);
  };

  const handleIdProofView = index => {
    let docs = [...certificateInfo];
    let doc = docs[index];

    setActiveCertificate(doc);
    setCertificateModal(!certificateModal);
  };

  const onSubmit = () => {
    handleSubmit({ certificates: certificateInfo });
  };

  const cancelChanges = () => {
    let initVal = [...certificateInitialValues];
    setcertificateInfo(initVal);
    toggleEdit();
  };

  const saveModelProps = () => {
    let newDoc = { ...modelProps };
    newDoc["status"] = "Pending";
    newDoc["comments"] = "";
    certificateInfo.push(newDoc);
    setcertificateInfo(certificateInfo);
    cancelModelProps();
  };

  const cancelModelProps = () => {
    setModelProps(modelInitialValues);
    setSelectedType(null);
    toggleModel();
  };

  const saveStatusModalProps = () => {
    let docs = [...certificateInfo];
    docs[activeDocIndex] = activeDoc;
    setcertificateInfo(docs);
    toggleStatusModel();
  };
  const cancelStatusModalProps = () => {
    toggleStatusModel();
  };

  const handleStatusClick = index => {
    setActiveDocIndex(index);
    setActiveDoc(certificateInfo[index]);
    toggleStatusModel();
  };

  const togglecertificateModal = () => {
    setCertificateModal(!certificateModal);
    setActiveCertificate(null);
  };

  const renderIdProofViewModal = () => {
    return (
      <Modal
        isOpen={certificateModal}
        toggle={togglecertificateModal}
        size="lg"
        centered
      >
        <ModalHeader toggle={togglecertificateModal}>
          {activeCertificate?.name}
        </ModalHeader>
        <ModalBody>
          <Row className="mt-1">
            <div className="text-center">
              <ModalImage
                small={
                  activeCertificate?.docs?.preview
                    ? activeCertificate?.docs?.preview
                    : activeCertificate?.docs
                }
                large={
                  activeCertificate?.docs?.preview
                    ? activeCertificate?.docs?.preview
                    : activeCertificate?.docs
                }
              />
            </div>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className="w-md btn-sm me-2"
            onClick={togglecertificateModal}
          >
            Close
          </Button>
          <a
            href={
              activeCertificate?.docs?.preview
                ? activeCertificate?.docs?.preview
                : activeCertificate?.docs
            }
            download
          >
            <Button color="primary" className="w-md btn-sm me-2">
              Download
            </Button>
          </a>
        </ModalFooter>
      </Modal>
    );
  };

  const renderModel = () => {
    let docInfo = modelProps.docs.path ? modelProps.docs : modelProps.docs[0];
    return (
      <Modal isOpen={modal} toggle={toggleModel} size="lg" centered>
        <ModalHeader toggle={toggleModel}>Upload Document</ModalHeader>
        <ModalBody>
          <Row className="mt-1">
            <Col md={6}>
              <Input
                type="text"
                className="form-control"
                id="name"
                placeholder="Title"
                name="name"
                onChange={event =>
                  handleModelPropsChange("name", event.target.value)
                }
                value={modelProps.name}
              />
            </Col>
            <Col md={4}>
              {" "}
              <Select
                name="type"
                id="type"
                placeholder="Select Document Type"
                value={selectedType}
                onChange={event => {
                  handleSelectType(event);
                }}
                options={typeList}
                classNamePrefix={
                  "select2-selection " + (isEdit && "dropdown-disabled")
                }
              />
            </Col>
            <Col md={2}>
              <div className="fileUpload">
                <Dropzone
                  id="validationCustom05"
                  onDrop={acceptedFiles => {
                    acceptedFiles.map(file =>
                      Object.assign(file, {
                        preview: URL.createObjectURL(file),
                        formattedSize: formatBytes(file.size),
                      })
                    );

                    let obj = { ...modelProps };
                    obj["docs"] = acceptedFiles[0];
                    setModelProps(obj);
                  }}
                >
                  {({ getRootProps, getInputProps }) => (
                    <div className="">
                      <div className="" {...getRootProps()}>
                        <input {...getInputProps()} />
                        <Button color="primary" className="btn btn-primary">
                          Browse
                        </Button>
                      </div>
                    </div>
                  )}
                </Dropzone>
              </div>
            </Col>
          </Row>
          {docInfo && (
            <Row className="mt-3 justify-content-center mb-1">
              <Col md={6}>
                <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                  <div className="p-2">
                    <Row className="align-items-center">
                      <Col className="col-auto">
                        <ProfileImage
                          image={docInfo}
                          height="80"
                          classNames="avatar-sm rounded bg-light"
                        />
                      </Col>
                      <Col>
                        <Link to="#" className="text-muted font-weight-bold">
                          {docInfo.name || docInfo.filename}
                        </Link>
                        <p className="mb-0">
                          <strong>{docInfo.formattedSize}</strong>
                        </p>
                      </Col>
                    </Row>
                  </div>
                </Card>
              </Col>
            </Row>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className="w-md btn-sm me-2"
            onClick={() => cancelModelProps()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-md btn-sm me-2"
            onClick={() => saveModelProps()}
          >
            Upload
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const renderStatusModal = () => {
    return (
      <Modal isOpen={statusModal} toggle={toggleStatusModel} size="md" centered>
        <ModalHeader toggle={toggleStatusModel}>Verify Document</ModalHeader>
        <ModalBody>
          <Row className="mt-1">
            <Col md={4}>
              <Select
                name="staus"
                id="stausType"
                value={selectedStatus}
                onChange={event => {
                  handleSelectStatus(event);
                }}
                options={statusType}
                classNamePrefix={
                  "select2-selection " + (isEdit && "dropdown-disabled")
                }
              />
            </Col>

            <Col md={8}>
              <textarea
                type="text"
                className="form-control"
                id="state"
                placeholder="Comment"
                name="state"
                rows="1"
                onChange={event =>
                  handleStatusPropsChange("comments", event.target.value)
                }
                value={activeDoc?.comments}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            color="light"
            className="w-md btn-sm me-2"
            onClick={() => cancelStatusModalProps()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            className="w-md btn-sm me-2"
            onClick={() => saveStatusModalProps()}
          >
            Add
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <Card className="border-light">
      <CardBody className="">
        <Row>
          <div className="info_conetent_header d-flex mb-2">
            <h5>CERTIFICATES INFO</h5>
            {!isEdit && (
              <Link
                className="btn btn-sm btn-primary btn-bg-primary d-flex px-3"
                to="#"
                onClick={() => toggleEdit()}
              >
                <i className="bx bx-edit-alt bx-xs pe-1"> </i>{" "}
                <span> Edit</span>
              </Link>
            )}
          </div>
        </Row>
        <Row className="px-3">
          <div className="document_container py-2">
            <div className="d-flex justify-content-end align-items-center mb-3">
              {isEdit && (
                <Button
                  color="primary"
                  className="btn btn-sm"
                  onClick={() => toggleModel()}
                >
                  + Add
                </Button>
              )}
            </div>
            {certificateInfo.length == 0 && (
              <p className="text-center mb-3 pb-3">No Documents found</p>
            )}
            {certificateInfo.map((doc, ind) => {
              return (
                <Row key={ind} className="align-items-center my-2">
                  <Col md={3}>
                    <div className="fw-bold">
                      <span className="pe-1">{ind + 1}.</span>{" "}
                      <span>{doc.name}</span>
                    </div>
                  </Col>

                  <Col md={3}>
                    <div className="fw-bold">
                      <Link
                        to="#"
                        onClick={() => handleStatusClick(ind)}
                        className={
                          doc.status == "Pending"
                            ? "text-warning"
                            : doc.status == "Verified"
                            ? "text-success"
                            : "text-danger"
                        }
                      >
                        {doc.status}
                      </Link>
                    </div>
                  </Col>
                  <Col md={3}>
                    <div className="modal_image mb-3" style={{ width: "50px" }}>
                      <ModalImage small={doc?.docs} large={doc?.docs} />
                    </div>
                  </Col>
                  <Col md={2}>
                    <Button
                      color="danger"
                      disabled={!isEdit}
                      className="w-xs btn-sm ms-3"
                      onClick={() => handleDelete(ind)}
                    >
                      {" "}
                      Delete
                    </Button>
                  </Col>
                </Row>
              );
            })}
          </div>
          {renderModel()}
          {isEdit && renderStatusModal()}
          {renderIdProofViewModal()}
        </Row>

        {isEdit && (
          <Row className="mt-3">
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm me-2"
                onClick={() => cancelChanges()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                className="w-md btn-sm ms-2"
                type="button"
                onClick={() => {
                  onSubmit();
                }}
              >
                Save
              </Button>
            </div>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default Certificates;
