import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { Form, Formik } from "formik";
import Select from "react-select";
import * as Yup from "yup";
import SweetAlert from "react-bootstrap-sweetalert";
import { updateExceptionStatus } from "services/appExceptions.service";
import Spinner from "components/spinner";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const defaultValues = {
  reason: "",
  priority: "",
  status: "",
};
const UpdateExceptionStatus = ({
  modal,
  toggle,
  activeObj,
  reload,
  viewtoggle,
  setModal
}) => {
  console.log(activeObj, "activeobjjj");
  const [selectedpriority, setSelectedpriority] = useState({});
  const [selectedStatus, setSelectedstatus] = useState({});
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [Notes, setNotes] = useState([]);

  useEffect(() => {
    if (activeObj) {
      setNotes(activeObj?.reason || []);
    }
  }, [activeObj]);
  let UserId = JSON.parse(localStorage.getItem("authUser"))["_doc"]["_id"];
  const initialValues = defaultValues;
  const priorityList = [
    {
      label: "Low",
      value: "Low",
    },
    {
      label: "Medium",
      value: "Medium",
    },
    {
      label: "High",
      value: "High",
    },
    {
      label: "Critical",
      value: "Critical",
    },
  ];

  const statusList = [
    {
      label: "Pending",
      value: "Pending",
    },
    {
      label: "Resolved",
      value: "Resolved",
    },
  ];
  const validationSchema = Yup.object().shape({
    reason: Yup.string().required("Please Enter title"),
    // type: Yup.string().required("Please Select status"),
    // code: Yup.string().required("Please Upload Image"),
  });
  function handleSelectpriority(event) {
    setValues({ ...values, ["priority"]: event.value });
    setSelectedpriority(event);
  }
  function handleSelectedStatus(event) {
    setValues({ ...values, ["status"]: event.value });
    setSelectedstatus(event);
  }
  const onSubmit = values => {
    saveChanges(values);
  };
  const saveChanges = (values) => {
    setLoading(true);
    const currentDate = new Date();
    const formattedDate = `${currentDate.getFullYear()}-${(
      currentDate.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}-${currentDate.getDate().toString().padStart(2, "0")}`;
  
    const hours = currentDate.getHours();
    const amOrPm = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
  
    const formattedTime = `${formattedHours
      .toString()
      .padStart(2, "0")}:${currentDate
      .getMinutes()
      .toString()
      .padStart(2, "0")} ${amOrPm}`;
  
    // Create a new reason object
    const newReason = {
      reason: values?.reason,
      adminid: UserId,
      date: formattedDate,
      time: formattedTime,
      timeZone: "Asia/Kolkata",
    };
  
    // Update the state to include the new reason along with the previous reasons
    setNotes((prevNotes) => [...prevNotes, newReason]);
  
    const body = {
      priority: selectedpriority?.value,
      status: selectedStatus?.value,
      reason: [...Notes, newReason], // Use the array of reasons from state
    };
  
    const ObjBody = JSON.stringify(body);
  
    updateExceptionStatus(activeObj?._id, ObjBody)
      .then((res) => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reload();
      })
      .catch((e) => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };
  

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Status Updated
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  const leaveToList = () => {
    toggle();
    setModal(false)
    setsuccess_msg(false);
    seterror_msg(false);
    setValues(defaultValues);
    setSelectedstatus({});
    setSelectedpriority({});
    // viewtoggle();
  };
  const onCancel = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    setValues(defaultValues);
    setSelectedstatus({});
    setSelectedpriority({});
  };
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        size="lg"
        onClosed={() => {
          onCancel();
        }}
      >
        <ModalHeader toggle={toggle}>Update Status</ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <div className="mt-3">
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize
                    initialValues={values}
                    validationSchema={validationSchema}
                    // onSubmit={onSubmit}
                  >
                    {({
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                      handleChange,
                      setFieldTouched,
                      values,
                    }) => {
                      useEffect(() => {
                        if (activeObj) {
                          setSelectedpriority({
                            value: activeObj.priority,
                            label: activeObj.priority,
                          });
                          setSelectedstatus({
                            value: activeObj.status,
                            label: activeObj.status,
                          });
                          setValues(activeObj);
                        }
                      }, [activeObj]);

                      return (
                        <Form>
                          <Row>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="priority">Priority</Label>
                                <Select
                                  id="priority"
                                  options={priorityList || []}
                                  placeholder="Select..."
                                  value={selectedpriority}
                                  onChange={event => {
                                    handleSelectpriority(event);
                                    handleChange("priority")(event.value);
                                  }}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup>
                                <Label for="Status">Status</Label>
                                <Select
                                  name="Status"
                                  id="Status"
                                  value={selectedStatus}
                                  placeholder="Select..."
                                  onChange={event => {
                                    handleSelectedStatus(event);
                                    handleChange("status")(event.value);
                                  }}
                                  options={statusList}
                                />
                              </FormGroup>
                            </Col>
                            <Col md={12}>
                              {/* <FormGroup> */}
                              <Label for="reason">Reason</Label>
                              {/* <Input
                                  type="text"
                                  name="reason"
                                  id="reason"
                                  placeholder="Reason"
                                  value={values?.reason}
                                  onChange={event => {
                                    handleChange("reason")(event);
                                  }}
                                /> */}
                              <Card>
                                <CKEditor
                                  editor={ClassicEditor}
                                  // data={values?.reason || ""}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    handleChange("reason")(data);
                                  }}
                                />
                              </Card>
                              {/* </FormGroup> */}
                            </Col>
                          </Row>
                          <hr></hr>
                          <div className="d-flex justify-content-end">
                            <Button
                              color="light"
                              className="w-md btn-sm me-3"
                              onClick={leaveToList}
                            >
                              Cancel
                            </Button>

                            <Button
                              color="primary"
                              type="submit"
                              className="w-md btn-sm"
                              onClick={() => {
                                onSubmit(values);
                              }}
                            >
                              Submit
                            </Button>
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>
                </CardBody>
              </Card>
            </div>
          </Container>
        </ModalBody>
      </Modal>
      <Spinner loading={loading} />
      {success_msg ? successPopup() : null}
      {error_msg ? errorPopup() : null}
    </>
  );
};

export default UpdateExceptionStatus;
