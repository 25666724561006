export const getCategories = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/category/all?status=all`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Categorires ${e}`));
};
export const getCategory = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/category/get/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get category ${e}`));
};
export const deleteCategory = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/category/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get category ${e}`));
};
export const createCategory = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/category/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at category create ${e}`));
};
export const updateCategory = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/category/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at category create ${e}`));
};

export const getCategorybyType = type => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/category/type?type=${type}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get category by Type ${e}`));
};

export const getCategoryTypeandParent = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/category/query`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at category query ${e}`));
};
export const updateCategoryStatus = (Id, status) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/category/status/${Id}/${status}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Category Status ${e}`));
};

export const multiCategory = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/category/multicategory`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Servce Update ${e}`));
};
