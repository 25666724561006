import axios from "axios";

export const createPaymentMethod = body => {
  return axios
    .post(
      `${process.env.REACT_APP_DATABASEURL}/api/v1/paymentMethods/create`,
      body
    )
    .then(res => {
      return res.data;
    })
    .catch(e => console.log(`error at creating Payment Method ${e}`));
};

export const getPaymentMethods = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/paymentMethods/get/all`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists ${e}`));
};

export const updatePaymentMethodById = (id, body) => {
  return axios
    .put(
      `${process.env.REACT_APP_DATABASEURL}/api/v1/paymentMethods/update/${id}`,
      body
    )
    .then(res => {
      return res.data;
    })
    .catch(e => console.log(`error at updating Payment Method ${e}`));
};

export const deletePaymentMethodById = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/paymentMethods/delete/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at getting Artists ${e}`));
};
