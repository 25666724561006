export const getQuickServices=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/all`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        console.log(res)
        return res.json()
    }).catch(e=>console.log(`error at get all Quick services ${e}`))  
}
export const getQuickServicesById=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/get/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        console.log(res)
        return res.json()
    }).catch(e=>console.log(`error at get Quick service by id ${e}`))  
}

export const createQuickService = body => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at Quick Service Create ${e}`));
  };

  export const updateQuickService =(id,body) => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/update/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at Quick Servce Update ${e}`));
  };

  export const multiQuickService =(body) => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/multiservices`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(body),
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at Quick Servce Update ${e}`));
  };
  export const deleteQuickService=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/delete/${id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        return res.json()
    }).catch(e=>console.log(`error at get Quick service ${e}`))  
  }
  
  export const getQuickServicesByCountry=(country)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/country/${country}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=> res.json()).catch(e=>console.log(`error at get Quick service by country ${e}`))  
}

export const getQuickServicesByCountryType=(country,type)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/expertise/${country}/${type}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=> res.json()).catch(e=>console.log(`error at get Quick service by type ${e}`))  
}
export const getQuickServicesByQuery=(body)=>{
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/quickServices/query`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body)
  })
  .then(res => {
    return res.json();
  })
  .catch(e => console.log(`error at Quick service query ${e}`));
}