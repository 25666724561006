export const getQuestions = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ratingQuestions/get/all`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get questions ${e}`));
};

export const getQuestion = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ratingQuestions/get/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get question bt id ${e}`));
};
export const createQuestion = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ratingQuestions/create`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at question create ${e}`));
};
export const deleteQuestion = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ratingQuestions/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at delete question ${e}`));
};
export const updateQuestion = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ratingQuestions/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body)
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at question update ${e}`));
};
