import * as _ from "lodash";

function getVariantObj(variants, variantsList) {
  let existingValues;
  variantsList.forEach(ele => {
    if (_.isEqual(variants, ele.variants)) {
      existingValues = ele;
      return;
    }
  });
  if (existingValues) return existingValues;
  return {
    variants: variants,
    price: "",
    beforePrice: "",
    quantity: "",
    visible: "active",
  };
}

function createCombinations(fields, currentCombinations, variantsList) {
  //prevent side-effects
  let tempFields = fields.slice();

  //recursively build a list combinations
  let delimiter = "|";
  if (!tempFields || tempFields.length == 0) {
    return currentCombinations;
  } else {
    let combinations = [];
    let field = tempFields.pop();

    for (let valueIndex = 0; valueIndex < field.length; valueIndex++) {
      let valueName = field[valueIndex]["name"];

      if (!currentCombinations || currentCombinations.length == 0) {
        let combinationName = valueName;
        combinations.push(getVariantObj([combinationName], variantsList));
      } else {
        for (
          let combinationIndex = 0;
          combinationIndex < currentCombinations.length;
          combinationIndex++
        ) {
          let currentCombination = currentCombinations[combinationIndex];
          let combinationName = [valueName, ...currentCombination?.variants];
          combinations.push(getVariantObj(combinationName, variantsList));
        }
      }
    }
    return createCombinations(tempFields, combinations, variantsList);
  }
}

export default createCombinations;
