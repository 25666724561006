export const getGallery=()=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/gallery/all`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        console.log(res)
        return res.json()
    }).catch(e=>console.log(`error at provider ${e}`))  
  }

  export const getGallerybyId=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/gallery/get/${id}`,{
        method:"GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        
        return res.json()
    }).catch(e=>console.log(`error at provider ${e}`))  
  }

  
  export const deleteGallerybyId=(id)=>{
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/gallery/delete/${id}`,{
        method:"DELETE",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            // Authorization: `Bearer ${token}`
          },
    }).then(res=>{
        
        return res.json()
    }).catch(e=>console.log(`error at provider ${e}`))  
  }

  export const createGallery = body => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/gallery/create`, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at provider ${e}`));
  };

  export const updateGallery = (body,id) => {
    return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/gallery/update/${id}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    })
      .then(res => {
        return res.json();
      })
      .catch(e => console.log(`error at provider ${e}`));
  };
  