export const getProducts = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/product/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Products ${e}`));
};
export const getProduct = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/product/product/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Product ${e}`));
};
export const deleteProduct = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/product/product/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Product ${e}`));
};
export const createProduct = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/product/add`, {
    method: "POST",
    headers: {
      Accept: "application/json",
    },
    body: body,
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Product create ${e}`));
};
export const updateProduct = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/product/product/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Product create ${e}`));
};
export const getProductByCatId = id => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/product/all`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Product By Category ${e}`));
};

export const getServiceProducts = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/serviceProducts/get/ecom`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get service Products ${e}`));
};

export const deleteServiceProduct = (serviceId, serviceProdId) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/serviceProducts/delete/${serviceId}/${serviceProdId}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at deleting service product ${e}`));
};
