import { ARTICLE_REQUEST, GET_ARTICLES, GET_ARTICLE_FAIL } from "./action-type";
const initialState = {
  error: "",
  loading: false,
  articles: {},
};
export const Articles = (state = initialState, action) => {
  switch (action.type) {
    case ARTICLE_REQUEST:
      return { ...state, loading: true };

    case GET_ARTICLES:
      return { ...state, loading: false, articles: action.payload };

    case GET_ARTICLE_FAIL:
      return { ...state, loading: false, error: action.payload };

    default:
      return state;
  }
};
export default Articles;
