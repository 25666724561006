import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import { Link } from "react-router-dom";

import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  List,
  UncontrolledAccordion,
} from "reactstrap";

import * as moment from "moment";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from "components/Common/Breadcrumb";

import { deleteService } from "services/services.service";
import { getParentCategories } from "services/parentcategory.service";
import { getCategoryTypeandParent } from "services/category.service";
import { getServicesByQuery, getServicesById } from "services/services.service";

import Spinner from "components/spinner";

import "./list.scss";
import View from "./View";
import CreateStepperService from "./createService";
import Info from "./info";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const ProviderList = () => {
  const [parentCatList, setParentCatList] = useState([]);
  const [activeParentCatInd, setActiveParentInd] = useState(null);
  const [subCatList, setSubCatList] = useState(null);
  const [activeSubCatInd, setActiveSubCatInd] = useState(null);
  const [activeSubCat, setActiveSubCat] = useState(null);
  const [serviceList, setServiceList] = useState(null);
  const [activeServiceInd, setActiveServiceInd] = useState(null);
  const [service, setService] = useState(null);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(true);

  const [modal, setModal] = useState(false);
  const toggle = () => {
    console.log("CLIKCED MODAL", !modal);
    setModal(!modal);
  };

  useEffect(() => {
    getParentCatList();
  }, []);

  const getParentCatList = () => {
    getParentCategories()
      .then(res => {
        let { data } = res;
        setParentCatList(data);
        // setTempList(data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const getCataegories = body => {
    getCategoryTypeandParent(body)
      .then(res => {
        if (res.data) {
          setSubCatList(res.data);
        }
        setServiceList(null);
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getServicesList = body => {
    getServicesByQuery(body)
      .then(res => {
        if (res.data) {
          setServiceList([...res.data]);
        }
        setLoading(false);
        setActiveServiceInd(null);
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  const getServiceById = id => {
    setLoading(true);
    getServicesById(id)
      .then(res => {
        setService(res.data);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = () => {
    let selectedId = service._id;
    setDeleteid(selectedId);
    setDelete_modal(true);
  };

  const removeService = id => {
    setLoading(true);
    deleteService(id)
      .then(res => {
        setDelete_modal(false);
        const selectedCategory = subCatList[activeSubCatInd];
        getServicesList({
          categories: selectedCategory._id,
          type: selectedCategory.type,
        });
      })
      .catch(err => {
        setLoading(false);
      });
  };
  const handleOnDelete = () => {
    removeService(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };

  const handleValidDate = date => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  const handleParentCat = (ele, index) => {
    setActiveParentInd(index);
    setActiveSubCatInd(null);
    setActiveServiceInd(null);
    const body = {
      type: ele.type,
      parentCategory: ele._id,
    };
    getCataegories(body);
  };

  const handleSubCat = (ele, index) => {
    setActiveSubCatInd(index);
    setActiveSubCat(subCatList[index]);
    getServicesList({ categories: ele._id, type: ele.type });
  };

  const handleService = (ele, index) => {
    setActiveServiceInd(index);
    getServiceById(ele._id);
  };

  const handleReloadService = () => {
    getServiceById(service._id);
  };

  const reloadServicesList = () => {
    const selectedCategory = subCatList[activeSubCatInd];
    getServicesList({
      categories: selectedCategory._id,
      type: selectedCategory.type,
    });
    toggle();
  };

  const renderParentCatList = type => {
    return (
      <List type="unstyled" className="service-ul">
        {parentCatList.map((ele, indx) => {
          if (ele.type === type)
            return (
              <li
                key={indx}
                className={
                  activeParentCatInd === indx ? "ps-3 py-1 active" : "ps-3 py-1"
                }
                onClick={() => {
                  handleParentCat(ele, indx);
                }}
              >
                {ele.name}
              </li>
            );
        })}
      </List>
    );
  };

  const renderSubCatList = () => {
    return (
      <Card className="border-dark">
        <CardBody className="p-0">
          {!subCatList && (
            <div className="empty_list p-3 pt-0">
              <div className="text-center">
                <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
              </div>
              <p className="text-center">Please Select Parent Category</p>
            </div>
          )}
          {subCatList && subCatList.length === 0 && (
            <div className="empty_list p-3 pt-0">
              <div className="text-center">
                <i className="mdi mdi-circle-off-outline" id="EmptyCatList"></i>
              </div>
              <h4>Empty List</h4>
              <p className="text-center">No Categories Found</p>
            </div>
          )}

          {subCatList &&
            subCatList.map((ele, indx) => {
              return (
                <Card
                  key={indx}
                  className={
                    activeSubCatInd === indx
                      ? "mx-3 my-4 border-light active"
                      : "mx-3 my-4 border-light"
                  }
                  onClick={() => {
                    handleSubCat(ele, indx);
                  }}
                >
                  <img alt={ele.name} className="header_img" src={ele.image} />
                  <CardBody className="p-2">
                    {/* <div className="services_available">
                      <span className="count">12</span> services available
                    </div> */}
                    <div className="card_title"> {ele.name}</div>
                    <div
                      className="mt-1 card_tagline"
                      dangerouslySetInnerHTML={{ __html: ele.tagline }}
                    />
                  </CardBody>
                </Card>
              );
            })}
        </CardBody>
      </Card>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro</title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid id="service_list">
          <Breadcrumbs title="Services" breadcrumbItem="Services List" />
          <BreadcrumbSub subtile="Services List" SubBreadcrumbItem="Services" />
          <Row>
            <Col className="tab_1 px-0 me-2">
              <Card className="border-light">
                <CardBody className="px-3">
                  <div className="custom_accordion">
                    <UncontrolledAccordion defaultOpen={["1", "2"]} stayOpen>
                      <AccordionItem className="border-0">
                        <AccordionHeader targetId="1">
                          {" "}
                          <i className="mdi mdi-folder font-size-16 text-warning me-2">
                            {" "}
                          </i>{" "}
                          Women
                        </AccordionHeader>
                        <AccordionBody accordionId="1">
                          {renderParentCatList("Female")}
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem className="border-0">
                        <AccordionHeader targetId="2">
                          {" "}
                          <i className="mdi mdi-folder font-size-16 text-warning me-2">
                            {" "}
                          </i>{" "}
                          Men
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                          {" "}
                          {renderParentCatList("Male")}
                        </AccordionBody>
                      </AccordionItem>
                      <AccordionItem className="border-0">
                        <AccordionHeader targetId="2">
                          {" "}
                          <i className="mdi mdi-folder font-size-16 text-warning me-2">
                            {" "}
                          </i>{" "}
                          Kids
                        </AccordionHeader>
                        <AccordionBody accordionId="2">
                          {" "}
                          {renderParentCatList("Kids")}
                        </AccordionBody>
                      </AccordionItem>
                    </UncontrolledAccordion>
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col className=" tab_2 px-0 me-2">{renderSubCatList()}</Col>
            <Col className="tab_3">
              {activeSubCatInd !== null && (
                <div className="text-end">
                  <Button
                    type="button"
                    color="primary"
                    className="btn-sm mb-2 px-4"
                    onClick={toggle}
                  >
                    <i className="bx bx-user-plus me-1" />
                    Create New
                  </Button>
                </div>
              )}
              <Card className="border-light">
                <div>
                  {activeSubCatInd !== null &&
                    subCatList &&
                    subCatList.length > 0 && (
                      <div className="service_title">
                        {subCatList[activeSubCatInd]["name"]}
                      </div>
                    )}

                  <div className="services_tabs p-3 pb-0">
                    {!serviceList && (
                      <div className="empty_list p-3 pt-0">
                        <div className="text-center">
                          <i
                            className="mdi mdi-arrow-left-circle"
                            id="nullCatList"
                          ></i>
                        </div>
                        <p className="text-center">
                          Please Select Sub Category
                        </p>
                      </div>
                    )}
                    {serviceList && serviceList.length === 0 && (
                      <div className="empty_list p-3 pt-0">
                        <div className="text-center">
                          <i
                            className="mdi mdi-circle-off-outline"
                            id="EmptyCatList"
                          ></i>
                        </div>
                        <h4>Empty List</h4>
                        <p className="text-center">No Services Found</p>
                      </div>
                    )}
                    {serviceList &&
                      serviceList.map((ele, indx) => {
                        return (
                          <button
                            key={indx}
                            className={
                              activeServiceInd === indx
                                ? "me-3 mb-3 px-3 py-1 active"
                                : "me-3 mb-3 px-3 py-1"
                            }
                            onClick={() => {
                              handleService(ele, indx);
                            }}
                          >
                            {ele.name}
                          </button>
                        );
                      })}
                  </div>
                  {activeServiceInd !== null && service && (
                    <div className="service_view_container">
                      <View
                        serviceValues={service}
                        handleDelete={onClickDelete}
                        parentCategory={parentCatList}
                        subCatList={subCatList}
                        reloadService={handleReloadService}
                      />
                      {/* <Info
                        serviceValues={service}
                        parentCategory={parentCatList}
                        subCatList={subCatList}
                        handleDelete={onClickDelete}
                        reloadService={handleReloadService}
                      /> */}
                    </div>
                  )}

                  {serviceList?.length > 0 && activeServiceInd === null && (
                    <div className="empty_list p-3 pt-0 mt-2 border-light">
                      <div className="text-center">
                        <i
                          className="mdi mdi-arrow-up-circle"
                          id="nullCatList"
                        ></i>
                      </div>
                      <p className="text-center">Please Select Service</p>
                    </div>
                  )}
                  {/* </div> */}
                  {delete_modal ? deletePopup() : null}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      {/* Create Service Modal  */}
      <CreateStepperService
        reloadServicesList={reloadServicesList}
        toggle={toggle}
        modal={modal}
        parentCat={
          parentCatList[activeParentCatInd]
            ? parentCatList[activeParentCatInd]
            : {}
        }
        subCat={activeSubCat}
      />
    </React.Fragment>
  );
};

export default ProviderList;
