import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import Spinner from "components/spinner";
//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Container,
  Button,
  Card,
  Row,
  Col,
  CardBody,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import Breadcrumbs from "components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { getZipCodes, deleteZipcode } from "services/zipcode.service";
import CreateZipcode from "./create";
import moment from "moment";

const ZipcodeList = props => {
  const { SearchBar } = Search;
  const [zipcodeList, setZipcodeList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(false);
  const [activeId, setActiveId] = useState(null);
  useEffect(() => {
    getZipcodeList();
  }, []);

  const getZipcodeList = () => {
    getZipCodes()
      .then(res => {
        setZipcodeList(res);
        let obj = {};
        res.forEach(catg => {
          let id = catg._id;
          obj[id] = catg["status"];
        });
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const removeZipcode = id => {
    setLoading(true);
    deleteZipcode(id)
      .then(res => {
        setDelete_modal(false);
        getZipcodeList();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const toggle = () => {
    console.log("CLIKCED MODAL", !modal);
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };
  const badgeColor = "font-size-12 badge-soft-";

  // Table Data
  const customer = () => [
    {
      dataField: "zipcode",
      text: "Zip Code",
      formatter: (cell, row, amount) => {
        return <div className="font-size-16">{row?.zipcode}</div>;
      },
    },
    {
      dataField: "country.label",
      text: "Country",
      formatter: (cell, row, amount) => {
        return (
          <div className="">
            {row?.country?.label === "United States"
              ? "USA"
              : row?.country?.label}
          </div>
        );
      },
    },
    {
      dataField: "city.label",
      text: "City",
    },
    {
      dataField: "area",
      text: "Service Location",
      formatter: (cell, row, amount) => {
        return (
          <div className="font-size-16">{row?.area ? row?.area : "_"}</div>
        );
      },
    },
    {
      dataField: "X1",
      text: "Enabled for Services",
      formatter: (cell, row, amount) => {
        return (
          <div className="">
            {row?.X1 === true ? "True" : "False"}
            {row?.X1 === true ? (
              <i className="bx bxs-check-circle text-secondary font-size-16 ms-2"></i>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      dataField: "X2",
      text: "Enabled for products",
      formatter: (cell, row, amount) => {
        return (
          <div className="">
            {row?.X2 === true ? "True" : "False"}
            {row?.X2 === true ? (
              <i className="bx bxs-check-circle text-secondary font-size-16 ms-2"></i>
            ) : (
              ""
            )}
          </div>
        );
      },
    },

    {
      dataField: "status",
      text: "Status",
      formatter: (cellContent, row) => (
        <Badge
          className={
            row.status === "active"
              ? badgeColor + "success"
              : badgeColor + "warning"
          }
          color={"bg-secondary"}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    // {
    //   dataField: "createdAt",
    //   text: "Created At",
    //   formatter: created => {
    //     return (
    //       <>
    //         <div className="">{moment(created).format("MMMM-Do-YYYY")}</div>
    //       </>
    //     );
    //   },
    // },
    // {
    //   dataField: "updatedAt",
    //   text: "Last Updated",
    //   formatter: updated => {
    //     return (
    //       <>
    //         <div className="">{moment(updated).format("MMMM-Do-YYYY")}</div>
    //       </>
    //     );
    //   },
    // },
    {
      dataField: "action",
      isDummyField: true,
      text: "Action",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => {
        let editUrl = `/zipcode-update/${row._id}`;
        return (
          <>
            <div className="d-flex gap-3">
              <div
                onClick={() => {
                  setActiveId(row._id);
                  toggle();
                }}
              >
                {/* <Link to={editUrl} className="text-success"> */}
                <i
                  className="mdi mdi-pencil font-size-18 text-success"
                  id="edittooltip"
                />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
                {/* </Link> */}
              </div>
              <div
                to="#"
                className="text-danger"
                onClick={() => onClickDelete(row._id)}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </div>
            </div>
          </>
        );
      },
    },
  ];

  const pageOptions = {
    sizePerPage: 25,
    totalSize: zipcodeList?.length, // replace later with size(customers),
    custom: true,
  };

  const addZipcode = () => {
    props.history.push("/zipcode-create");
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeZipcode(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="page-content">
        <MetaTags>
          <title>ZipCode | Zipcode Enabler</title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs
            title="Zip Code Enabler"
            breadcrumbItem="Zip Code List"
          />
          <Row>
            <Col xs="12">
              <Card>
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="_id"
                    columns={customer()}
                    data={zipcodeList ? zipcodeList : []}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="_id"
                        data={zipcodeList ? zipcodeList : []}
                        columns={customer()}
                        bootstrap4
                        search
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col sm="6" className="d-flex">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                              <Col sm="6">
                                <div className="text-sm-end">
                                  <Button
                                    type="button"
                                    color="primary"
                                    className="btn-rounded  mb-2 me-2"
                                    onClick={() => {
                                      toggle();
                                    }}
                                  >
                                    <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                    Add Zip Code
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    keyField="_id"
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    classes={
                                      "table align-middle table-nowrap table-check"
                                    }
                                    headerWrapperClasses={"table-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="align-items-md-center mt-30">
                              <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                  {zipcodeList?.length == 0 && (
                    <p className="text-center"> No Data Found</p>
                  )}

                  {delete_modal ? deletePopup() : null}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        <CreateZipcode
          toggle={toggle}
          modal={modal}
          id={activeId}
          reload={getZipcodeList}
        />
      </div>
    </React.Fragment>
  );
};

export default ZipcodeList;
