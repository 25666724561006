import MetaTags from "react-meta-tags";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import {
  Container,
  Table,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Input,
  Card,
  Row,
  Col,
  CardBody,
  Form,
  Label,
  Alert,
  FormFeedback,
  Badge
} from "reactstrap";
import * as moment from "moment";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
  } from "react-bootstrap-table2-paginator";
  import Breadcrumbs from "components/Common/Breadcrumb";

import { connect, useSelector, useDispatch } from "react-redux";

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import Breadcrumb from "../../components/Common/Breadcrumb";
import { Link } from "react-router-dom";
import { getProviders } from "store/actions";

import profileImg from "assets/images/profile-img.png";
import logoImg from "assets/images/logo.svg";

// action
import { registerUser, apiError } from "store/actions";

const Providers = props => {
  const [modal_standard, setmodal_standard] = useState(false);
  const dispatch = useDispatch();
  const { error, providers } = useSelector(state => ({
    error: state.Providers.error,
    providers: state.Providers.providers,
  }));
  useEffect(() => {
    dispatch(getProviders());
  }, []);
  const { SearchBar } = Search; 
   const badgeColor = "font-size-12 badge-soft-"
   const customerData = [
    {
     "id":1,
     "name":"John Ran" ,
     "email":"John@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":2,
     "name":"Meher San" ,
     "email":"meher@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":3,
     "name":"Ali Baljis" ,
     "email":"ali@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":4,
     "name":"Ali Baljis" ,
     "email":"ali@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":5,
     "name":"Ali Baljis" ,
     "email":"ali@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":6,
     "name":"Ali Baljis" ,
     "email":"ali@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":7,
     "name":"Ali Baljis" ,
     "email":"ali@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":8,
     "name":"Ali Baljis" ,
     "email":"ali@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
    {
     "id":9,
     "name":"Ali Baljis" ,
     "email":"ali@gmail.com",
     "status":"confirmed",
     "registerDate": new Date()
    },
  ];
  // Table Data
  const customer =()=> [
    {
      dataField: "id",
      text: "No",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Link to="#" className="text-body fw-bold">
          {row.id}
        </Link>
      ),
    },
    {
      dataField: "name",
      text: "Name"
    },
    {
      dataField: "email",
      text: "Email"
    },
    {
      dataField: "registerDate",
      text: "Registered Date",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => handleValidDate(row.registerDate),
    },
   
    {
      dataField: "status",
      text: "Status",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent, row) => (
        <Badge
          className={row.status ==="pending" ? badgeColor+"warning": badgeColor+"success"}
          color={"bg-secondary"}
          pill
        >
          {row.status}
        </Badge>
      ),
    },
    {
      dataField: "view",
      isDummyField: true,
      text: "View Details",
      sort: true,
      // eslint-disable-next-line react/display-name
      formatter: () => (
        <Button
          type="button"
          color="bg-secondary"
          className="btn-sm btn-rounded btn-outline-primary"
        >
          View Details
        </Button>
      ),
    },

  ];
  const handleOrderClicks = () => {

};
const pageOptions = {
    sizePerPage: 5,
    totalSize: customerData.length, // replace later with size(customers),
    custom: true,
  };

const handleValidDate = date => {
  const date1 = moment(new Date(date)).format("DD MMM Y");
  return date1;
};

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      username: Yup.string().required("Please Enter Your Username"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: values => {
      dispatch(registerUser(values));
    },
  });
  const { user, registrationError, loading } = useSelector(state => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  useEffect(() => {
    dispatch(apiError(""));
  }, []);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }

  let modal = () => {
    return (
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-body p-0">
          <Card className="overflow-hidden mb-0">
            <div className="bg-primary bg-soft">
              <Row>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
                <Col className="col-7">
                  <div className="text-primary p-4">
                    <h5 className="text-primary">Register Provider</h5>
                    <p>Welcomes to new Provider to our system.</p>
                  </div>
                </Col>
                <Col className="col-5 align-self-end">
                  <img src={profileImg} alt="" className="img-fluid" />
                </Col>
              </Row>
            </div>
            <CardBody className="pt-0">
              <div>
                <Link to="/">
                  <div className="avatar-md profile-user-wid mb-4">
                    <span className="avatar-title rounded-circle bg-light">
                      <img
                        src={logoImg}
                        alt=""
                        className="rounded-circle"
                        height="34"
                      />
                    </span>
                  </div>
                </Link>
              </div>
              <div className="p-2">
                <Form
                  className="form-horizontal"
                  onSubmit={e => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  {user && user ? (
                    <Alert color="success">Register User Successfully</Alert>
                  ) : null}

                  {registrationError && registrationError ? (
                    <Alert color="danger">{registrationError}</Alert>
                  ) : null}

                  <div className="mb-3">
                    <Label className="form-label">Email</Label>
                    <Input
                      id="email"
                      name="email"
                      className="form-control"
                      placeholder="Enter email"
                      type="email"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.email || ""}
                      invalid={
                        validation.touched.email && validation.errors.email
                          ? true
                          : false
                      }
                    />
                    {validation.touched.email && validation.errors.email ? (
                      <FormFeedback type="invalid">
                        {validation.errors.email}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mb-3">
                    <Label className="form-label">Username</Label>
                    <Input
                      name="username"
                      type="text"
                      placeholder="Enter username"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.username || ""}
                      invalid={
                        validation.touched.username &&
                        validation.errors.username
                          ? true
                          : false
                      }
                    />
                    {validation.touched.username &&
                    validation.errors.username ? (
                      <FormFeedback type="invalid">
                        {validation.errors.username}
                      </FormFeedback>
                    ) : null}
                  </div>
                  <div className="mb-3">
                    <Label className="form-label">Password</Label>
                    <Input
                      name="password"
                      type="password"
                      placeholder="Enter Password"
                      onChange={validation.handleChange}
                      onBlur={validation.handleBlur}
                      value={validation.values.password || ""}
                      invalid={
                        validation.touched.password &&
                        validation.errors.password
                          ? true
                          : false
                      }
                    />
                    {validation.touched.password &&
                    validation.errors.password ? (
                      <FormFeedback type="invalid">
                        {validation.errors.password}
                      </FormFeedback>
                    ) : null}
                  </div>

                  <div className="mt-4 d-flex justify-content-end">
                    <Button
                      color="primary"
                      className="w-md me-4"
                      onClick={() => {
                        tog_standard();
                      }}
                    >
                      Save
                    </Button>
                    <Button
                      color="secondary"
                      className="w-md "
                      outline
                      onClick={() => {
                        tog_standard();
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </Form>
              </div>
            </CardBody>
          </Card>
        </div>
      </Modal>
    );
  };
  return (
  
          <React.Fragment>
            <div className="page-content">
              <MetaTags>
                <title>
                  Providers | stylepro - -
                </title>
              </MetaTags>
              <Container fluid>
                <Breadcrumbs title="Users" breadcrumbItem="Providers" />
                <Row>
                  <Col xs="12">
                    <Card>
                      <CardBody>
                        <PaginationProvider
                          pagination={paginationFactory(pageOptions)}
                          keyField="id"
                          columns={customer()}
                          data={customerData}
                        >
                          {({ paginationProps, paginationTableProps }) => (
                            <ToolkitProvider
                              keyField="id"
                              data={customerData}
                              columns={customer()}
                              bootstrap4
                              search
                            >
                              {toolkitProps => (
                                <React.Fragment>
                                  <Row className="mb-2">
                                    <Col sm="4">
                                      <div className="search-box me-2 mb-2 d-inline-block">
                                        <div className="position-relative">
                                          <SearchBar
                                            {...toolkitProps.searchProps}
                                          />
                                          <i className="bx bx-search-alt search-icon" />
                                        </div>
                                      </div>
                                    </Col>
                                    <Col sm="8">
                                    <div className="text-sm-end">
                                      <Button
                                        type="button"
                                        color="primary"
                                        className="btn-rounded  mb-2 me-2"
                                        onClick={() => {
                                          tog_standard();
                                        }}
                                      >
                                        <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
                                        Add Provider
                                      </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col xl="12">
                                      <div className="table-responsive">
                                        <BootstrapTable
                                          keyField="id"
                                          responsive
                                          bordered={false}
                                          striped={false}
                                          classes={
                                            "table align-middle table-nowrap table-check"
                                          }
                                          headerWrapperClasses={"table-light"}
                                          {...toolkitProps.baseProps}
                                          {...paginationTableProps}
                                        />
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="align-items-md-center mt-30">
                                    <Col className="pagination pagination-rounded justify-content-end mb-2 inner-custom-pagination">
                                      <PaginationListStandalone
                                        {...paginationProps}
                                      />
                                    </Col>
                                  </Row>
                                </React.Fragment>
                              )}
                            </ToolkitProvider>
                          )}
                        </PaginationProvider>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          {modal()}

          </React.Fragment>
       
  );
};

const mapStatetoProps = state => {
  const { error, providers } = state.Providers;
  return { error: error, providers: providers };
};

export default withRouter(
  connect(mapStatetoProps, { getProviders })(Providers)
);
