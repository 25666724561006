import React, { useState, useEffect, useMemo } from "react";
import { useParams } from "react-router";

import MetaTags from "react-meta-tags";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//SweetAlert
import SweetAlert from "react-bootstrap-sweetalert";

import Select from "react-select";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";

import ProfileImage from "./../../components/profileImage";
import Spinner from "components/spinner";
import countryList from "react-select-country-list";

// import "./create.styles.scss";
import {
  createPackage,
  getPackage,
  updatePackage,
} from "services/package.service";
import { multiService, getServices } from "services/services.service";
import {
  getParentCategories,
  getParentCategoriesByQuery,
} from "services/parentcategory.service";
import { multiCategory, getCategories } from "services/category.service";
import { Formik, Form } from "formik";
import * as Yup from "yup";

const defaultValues = {
  packageName: "",
  image: {},
  gender: "",
  parentCategory: [],
  category: [],
  services: [],
  price: "",
  discount: "",
  status: "",
};

const CreatePackage = props => {
  const { id } = useParams();
  const [country, setCountry] = useState("");
  const options = useMemo(() => countryList().getData(), []);

  const [categoryList, setCategoryList] = useState([]);
  const [serviceList, setServicelist] = useState([]);
  const [parentCatList, setParentCatList] = useState([]);
  const [parentCategorySelect, selectparent] = useState(null);
  const [categorySelect, selectCategory] = useState(null);
  const [selectService, setselectService] = useState(null);

  const [selectedStatus, setSelectedStatus] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [values, setValues] = useState(defaultValues);
  const [loading, setLoading] = useState(false);

  const initialValues = defaultValues;
  const validationSchema = Yup.object().shape({
    packageName: Yup.string().required("Please Enter packageName"),
    gender: Yup.string().required("Please Select gender"),
    parentCategory: Yup.array()
      .min(1)
      .required("Please select atleast one Category"),
    category: Yup.array()
      .min(1)
      .required("Please select atleast one Sub Category"),
    services: Yup.array().min(1).required("Please select atleast one Service"),
    status: Yup.string().required("Please Select Status"),
  });
  const onSubmit = values => {
    if (id) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };

  const statusList = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
  ];
  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
  ];

  

  function handleSelectStatus(event) {
    setValues({ ...values, ["status"]: event.value });
    setSelectedStatus(event);
  }
  function handleSelectType(event) {
    setSelectedType(event);
    getParentCategoriesByQuery({ type: event.value })
      .then(res => {
        if (res.data) {
          let list = res?.data;
          let resArr = [];
          list.map(cat => {
            resArr.push({
              value: cat._id,
              label: cat.name,
            });
          });
          setParentCatList(resArr);
          
        }
      })
      .catch(e => {
        console.log(e);
      });
  }

  /**
   * Formats the size
   */
  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  const saveChanges = values => {
    setLoading(true);
    let formData = new FormData();
    console.log(values);
    for (let key in values) {
      formData.set(key, values[key]);
    }
    formData.set("country", JSON.stringify(country));

    createPackage(formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    let formData = new FormData();
    for (let key in values) {
      formData.set(key, values[key]);
    }
    formData.set("country", JSON.stringify(country));

    updatePackage(id, formData)
      .then(res => {
        if (res.success) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    props.history.push("/packages-list");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    // props.history.push("/category-list");
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Package created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  const changeHandler = value => {
    setCountry(value);
  };
  const getCategoriesbyParenttype = value => {
    multiCategory({ parentCategory: value }).then(res => {
      if (res.data) {
        let list = res?.data;
        let resArr = [];
        list.map(cat => {
          resArr.push({
            value: cat._id,
            label: cat.name,
          });
        });
        setCategoryList(resArr);
      }
    });
  };
  const getServicesbyCategory = (value,country) => {
    console.log(country)
    multiService({ categories: value, country }).then(res => {
      if (res.data) {
        let list = res?.data;
        let resArr = [];
        list.map(cat => {
          resArr.push({
            value: cat._id,
            label: cat.name,
          });
        });
        setServicelist(resArr);
      }
    });
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Form Layouts | stylepro - -
          </title>
        </MetaTags>
        <Spinner loading={loading} />
        <Container fluid={false}>
          <Breadcrumb
            title="Package"
            breadcrumbItem={id ? "Edit Package" : "Create Package"}
          />
          <Card>
            <CardBody>
              <Formik
                enableReinitialize
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({
                  errors,
                  touched,
                  isSubmitting,
                  setFieldValue,
                  handleChange,
                  setFieldTouched,
                  values,
                }) => {
                  useEffect(() => {
                    if (id) {
                      setLoading(true);
                      getPackage(id)
                        .then(res => {
                          const data = { ...res.data };
                          const fields = [
                            "packageName",
                            "discount",
                            "gender",
                            "price",
                            "image",
                            "status",
                          ];
                          fields.forEach(field =>
                            setFieldValue(field, data[field], false)
                          );
                          setCountry(data.country);
                          const serviceOptions = [];
                          const categoryOptions = [];
                          const parentOptions = [];
                          const servicesId = [];
                          const parentId = [];
                          const categoryId = [];
                          data.services.map(e => {
                            servicesId.push(e._id);
                            serviceOptions.push({ label: e.name, value: e._id })
                          });
                          data.category.map(e => {
                            categoryId.push(e._id);
                            categoryOptions.push({ label: e.name, value: e._id })

                          });
                          data.parentCategory.map(e => {
                            parentId.push(e._id);
                            parentOptions.push({ label: e.name, value: e._id })

                          });
                          setFieldValue(
                            "parentCategory",
                            JSON.stringify(parentId)
                          );
                          setFieldValue("category", JSON.stringify(categoryId));
                          setFieldValue("services", JSON.stringify(servicesId));
                          selectparent(parentOptions),
                          selectCategory(categoryOptions)
                          setselectService(serviceOptions)
                          setSelectedStatus({
                            label: data.status,
                            value: data.status,
                          });
                          setSelectedType({
                            label: data.gender,
                            value: data.gender,
                          });
                          handleSelectType({
                            label: data.gender,
                            value: data.gender,
                          })
                          getCategoriesbyParenttype(parentId)
                          getServicesbyCategory(categoryId,data.country)
                          setLoading(false);
                        })
                        .catch(e => {
                          console.log(e);
                        });
                    }
                  }, []);

                  return (
                    <Form>
                      <Row className="mt-4">
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom01">
                                PackageName
                              </Label>
                              <Input
                                name="packageName"
                                placeholder="packageName"
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                onChange={handleChange}
                                // onBlur={validation.handleBlur}
                                value={values.packageName}
                                invalid={
                                  touched.packageName && errors.packageName
                                    ? true
                                    : false
                                }
                              />

                              {touched.packageName && errors.packageName ? (
                                <FormFeedback type="invalid">
                                  {errors.name}
                                  {touched.name}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom04">Gender</Label>
                              <Select
                                // className="form-control"
                                id="validationCustom04"
                                value={selectedType}
                                onChange={event => {
                                  handleSelectType(event);
                                  handleChange("gender")(event.value);
                                }}
                                name="gender"
                                options={typeList}
                                classNamePrefix="select2-selection"
                              />
                              {touched.gender && errors.gender ? (
                                <FormFeedback type="invalid">
                                  {errors.gender}
                                  {touched.gender}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <FormGroup className="mb-3">
                            <Label className="col-form-label">Country</Label>
                            <Select
                              name="type"
                              id="type"
                              options={options}
                              value={country}
                              onChange={event => {
                                changeHandler(event);
                              }}
                              classNamePrefix="select2-selection"
                            />
                          </FormGroup>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom5">
                                ParentCategory
                              </Label>
                              <Select
                                id="validationCustom5"
                                value={parentCategorySelect}
                                isMulti={true}
                                onChange={event => {
                                  selectparent(event);
                                  const data = [];
                                  event.map(e => {
                                    data.push(e.value);
                                  });
                                  handleChange("parentCategory")(
                                    JSON.stringify(data)
                                  );
                                  getCategoriesbyParenttype(data);
                                }}
                                options={parentCatList}
                                classNamePrefix="select2-selection"
                              />
                              {touched.parentCategory &&
                              errors.parentCategory ? (
                                <FormFeedback type="invalid">
                                  {errors.parentCategory}
                                  {touched.parentCategory}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom5">
                                SubCategory
                              </Label>
                              <Select
                                id="validationCustom5"
                                value={categorySelect}
                                isMulti={true}
                                onChange={event => {
                                  selectCategory(event);
                                  const data = [];
                                  event.map(e => {
                                    data.push(e.value);
                                  });
                                  handleChange("category")(
                                    JSON.stringify(data)
                                  );

                                  getServicesbyCategory(data,country);
                                }}
                                options={categoryList}
                                classNamePrefix="select2-selection"
                              />
                              {touched.category && errors.category ? (
                                <FormFeedback type="invalid">
                                  {errors.category}
                                  {touched.category}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>

                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom5">Service</Label>
                              <Select
                                id="validationCustom5"
                                value={selectService}
                                isMulti={true}
                                onChange={event => {
                                  setselectService(event);
                                  const data = [];
                                  event.map(e => {
                                    data.push(e.value);
                                  });
                                  handleChange("services")(
                                    JSON.stringify(data)
                                  );
                                }}
                                options={serviceList}
                                classNamePrefix="select2-selection"
                              />
                              {touched.services && errors.services ? (
                                <FormFeedback type="invalid">
                                  {errors.services}
                                  {touched.services}
                                </FormFeedback>
                              ) : null}
                            </FormGroup>
                          </Row>
                        </Col>
                        <Col md={6} className="px-4">
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom06">Image</Label>
                              <Dropzone
                                id="validationCustom06"
                                onDrop={acceptedFiles => {
                                  acceptedFiles.map(file =>
                                    Object.assign(file, {
                                      preview: URL.createObjectURL(file),
                                      formattedSize: formatBytes(file.size),
                                    })
                                  );
                                  setFieldValue("image", acceptedFiles[0]);
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div className="dropzone image_drop">
                                    <div
                                      className="dz-message needsclick "
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <div className="">
                                        <i className="display-6 text-muted bx bxs-cloud-upload" />
                                      </div>
                                      <h6>
                                        Drop files here or click to upload.
                                      </h6>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <Label className="statusValidation">
                                {" "}
                                {errors.image ? errors.image : ""}
                              </Label>
                              <div
                                className="dropzone-previews mt-3"
                                id="file-previews"
                              >
                                {values.image &&
                                  Object.keys(values.image).length > 0 && (
                                    <Card className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete">
                                      <div className="p-2">
                                        <Row className="align-items-center">
                                          <Col className="col-auto">
                                            <ProfileImage
                                              image={values?.image}
                                              height="80"
                                              classNames="avatar-sm rounded bg-light"
                                            />
                                          </Col>
                                          <Col>
                                            <Link
                                              to="#"
                                              className="text-muted font-weight-bold"
                                            >
                                              {values.image.name ||
                                                values.image.filename}
                                            </Link>
                                            <p className="mb-0">
                                              <strong>
                                                {values.image.formattedSize}
                                              </strong>
                                            </p>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Card>
                                  )}
                              </div>
                            </FormGroup>
                          </Row>
                          <FormGroup className="mb-3">
                            <Label className="col-form-label">Price</Label>
                            <InputGroup>
                              <Input
                                name="price"
                                placeholder=""
                                type="number"
                                id="price"
                                value={values.price}
                                onChange={handleChange}
                              />
                              <InputGroupText>$</InputGroupText>
                            </InputGroup>
                          </FormGroup>

                          <FormGroup className="mb-3">
                            <Label className="col-form-label">
                              Discount Price
                            </Label>
                            <InputGroup>
                              <Input
                                type="number"
                                placeholder=""
                                name="discount"
                                value={values.discount}
                                onChange={handleChange}
                              />
                              <InputGroupText>$</InputGroupText>
                            </InputGroup>
                          </FormGroup>
                          <Row className="mb-3">
                            <FormGroup className="mb-3">
                              <Label htmlFor="validationCustom08">Status</Label>
                              <Select
                                // className="form-control"
                                id="validationCustom08"
                                value={selectedStatus}
                                onChange={event => {
                                  handleSelectStatus(event);
                                  handleChange("status")(event.value);
                                }}
                                name="type"
                                options={statusList}
                                classNamePrefix="select2-selection"
                              />
                              <Label className="statusValidation">
                                {" "}
                                {errors.status ? errors.status : ""}
                              </Label>
                            </FormGroup>
                          </Row>
                        </Col>
                      </Row>
                      <hr></hr>
                      <div className="d-flex justify-content-end">
                        {!id ? (
                          <Button
                            color="primary"
                            type="submit"
                            className="w-md btn-sm me-4"
                            // onClick={saveChanges}
                          >
                            Submit
                          </Button>
                        ) : (
                          <Button
                            color="primary"
                            className="w-md btn-sm me-4"
                            type="submit"
                            // onClick={editChanges}
                          >
                            Save Changes
                          </Button>
                        )}
                        <Button
                          color="light"
                          className="w-md btn-sm"
                          onClick={leaveToList}
                        >
                          cancel
                        </Button>
                      </div>
                    </Form>
                  );
                }}
              </Formik>

              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreatePackage;
