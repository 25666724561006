import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import "react-dual-listbox/lib/react-dual-listbox.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import csc from "countries-states-cities";
const defaultStateList = [
  {
    label: "please Select Country",
    value: "please Select Country",
    isDisabled: true,
  },
];
const defaultCityList = [
  {
    label: "please Select State",
    value: "please Select State",
    isDisabled: true,
  },
];
const step1 = props => {
  const { handleSubmitStepper, step1Values } = props;
  const [selectedType, setSelectedType] = useState(null);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState(defaultStateList);
  const [citiesList, setCitiesList] = useState(defaultCityList);
  const [values, setValues] = useState(step1Values);
  const [country, setCountry] = useState();
  const [state, setState] = useState();
  const [city, setCity] = useState();
  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    { label: "Other", value: "Other" },
  ];

  const initialValues = step1Values;

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Please Enter Company"),
    address: Yup.string().required("Please Enter Address"),
    zipcode: Yup.string().required("Please Enter Zipcode"),
    city: Yup.string().required("Please Enter City"),
    state: Yup.string().required("Please Enter State"),
    country: Yup.string().required("Please Enter Country"),
    mobileNumber: Yup.string().required("Please Enter Mobile Number"),
    about: Yup.string(),
  });

  useEffect(() => {
    //Get All Countries List
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
  }, []);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleSelectType(event) {
    setSelectedType(event);
  }

  function handleSelectType(event) {
    setSelectedType(event);
  }

  function handleSelectCountry(event) {
    //Get All States Under the Selected Country
    let stateArray = [];
    csc.getStatesOfCountry(event.id).forEach(ele => {
      let stateObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      stateArray.push(stateObj);
    });
    setStatesList(stateArray);
  }

  function handleSelectState(event) {
    let citiesArr = [];
    csc.getCitiesOfState(event.id).forEach(ele => {
      let cityObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      citiesArr.push(cityObj);
    });
    setCitiesList(citiesArr);
  }

  const onSubmit = values => {
    handleSubmitStepper(values, "stepper1");
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          values,
        }) => {
          return (
            <Form>
              <Row className="justify-content-center mt-2">
                <Col md={12}>
                  <Row className="mb-3">
                    <Col sm={4}>
                      <Label htmlFor="CompanyName" className=" col-form-label">
                        Company Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="CompanyName"
                        placeholder="Enter Your Company Name"
                        name="companyName"
                        onChange={handleChange}
                        value={values?.companyName}
                        invalid={
                          touched.companyName && errors.companyName
                            ? true
                            : false
                        }
                      />

                      {touched.companyName && errors.companyName ? (
                        <FormFeedback type="invalid">
                          {errors.companyName}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm="4">
                      <Label htmlFor="zipcode" className=" col-form-label">
                        Authorized Person
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="person"
                        placeholder="Enter Your person"
                        name="person"
                        onChange={handleChange}
                        value={values?.person}
                        invalid={touched.person && errors.person ? true : false}
                      />
                      {touched.person && errors.person ? (
                        <FormFeedback type="invalid">
                          {errors.person}
                        </FormFeedback>
                      ) : null}
                    </Col>{" "}
                    <Col sm="4">
                      <Label htmlFor="email" className=" col-form-label">
                        Email
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Enter Your Email"
                        name="email"
                        onChange={handleChange}
                        value={values?.email}
                        invalid={touched.email && errors.email ? true : false}
                      />
                      {touched.email && errors.email ? (
                        <FormFeedback type="invalid">
                          {errors.email}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm="4" className="mt-3">
                      <Label htmlFor="mobileNumber" className=" col-form-label">
                        Mobile Number
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="mobileNumber"
                        placeholder="Enter Your Mobile Number"
                        name="mobileNumber"
                        onChange={handleChange}
                        value={values?.mobileNumber}
                        invalid={
                          touched.mobileNumber && errors.mobileNumber
                            ? true
                            : false
                        }
                      />
                      {touched.mobileNumber && errors.mobileNumber ? (
                        <FormFeedback type="invalid">
                          {errors.mobileNumber}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm={4} className="mt-3">
                      <Label htmlFor="country" className="col-form-label">
                        Country
                        <span className="text-danger">*</span>
                      </Label>

                      <Select
                        name="country"
                        id="country"
                        onChange={event => {
                          setCountry(event);
                          handleSelectCountry(event);
                          handleChange("country")(event.label);
                        }}
                        options={countriesList}
                        value={country}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col className="mt-3" sm={4}>
                      <Label htmlFor="state" className="col-form-label">
                        State
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="state"
                        id="state"
                        onChange={event => {
                          if (event) {
                            setState(event);
                            handleSelectState(event);

                            handleChange("state")(event.value);
                          }
                        }}
                        options={statesList}
                        value={state}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col className="mt-3" sm={4}>
                      <Label htmlFor="city" className="col-form-label">
                        City
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="city"
                        id="city"
                        onChange={event => {
                          setCity(event);
                          handleChange("city")(event.value);
                        }}
                        options={citiesList}
                        value={city}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col sm="4" className="mt-3">
                      <Label htmlFor="zipcode" className=" col-form-label">
                        Zipcode
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="zipcode"
                        placeholder="Enter Your Zipcode"
                        name="zipcode"
                        onChange={handleChange}
                        value={values?.zipcode}
                      />
                    </Col>
                    <Col sm="8" className="mt-3">
                      <Label htmlFor="address" className=" col-form-label">
                        Address
                      </Label>

                      <textarea
                        name="address"
                        className="form-control"
                        id="address"
                        rows={3}
                        onChange={handleChange}
                        value={values?.address}
                      ></textarea>
                      {touched.address && errors.address ? (
                        <FormFeedback type="invalid">
                          {errors?.address}
                        </FormFeedback>
                      ) : null}
                    </Col>
                    <Col sm="12" className="mt-3">
                      <Label htmlFor="note" className="col-form-label">
                        About the Company
                      </Label>
                      <div className="">
                        <textarea
                          name="note"
                          className="form-control"
                          id="note"
                          rows={7}
                          onChange={handleChange}
                          value={values?.note}
                        ></textarea>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="mt-3">
                <div className="d-flex justify-content-end">
                  <Button
                    color="primary"
                    className="w-md btn-sm ms-2"
                    type="submit"
                    onClick={() => {
                      onSubmit(values);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default step1;
