import { GET_SUB_CATEGORY, GET_SUB_CATEGORY_FAIL } from "./actionTypes";

const INIT_STATE = {
  category: [],
  error: {},
  loading: false,
};

const EcomSubCategory = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SUB_CATEGORY:
      return {
        ...state,
        category: action.payload,
      };

    case GET_SUB_CATEGORY_FAIL:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default EcomSubCategory;
