import ProfileImage from "components/profileImage";
import React, { useEffect, useState } from "react";
import Dropzone from "react-dropzone";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Variants from "./variants";
import banner from "./PicUpload.jpg";
import { useDispatch, useSelector } from "react-redux";
import formatBytes from "utils/formatBytes";
import Select from "react-select";
import {
  createNewProduct,
  empltyProductById,
  getProductById,
} from "store/actions";
import VariantIndex from "./Variants/index";
import { VariantAttributes } from "./Variants/VariantAttributes";
import { getSkuCode } from "services/ecommerce/product.service";

export const VariantTab = ({
  defaultValues,
  handleUpdateMulti,
  handleSubmit,
  stepperActiveTab,
}) => {
  const route = useParams();
  const product = useSelector(state => state?.EcomProducts?.product);
  const dispatch = useDispatch();
  const [values, setValues] = useState(JSON.parse(JSON.stringify(product)));
  const [shippingToggle, setShippingToggle] = useState(false);
  const [skuCode, setSkuCode] = useState(values?.sku);
  const unitList = [
    {
      label: "Ml",
      value: "ml",
    },
    {
      label: "Pc",
      value: "pc",
    },
    {
      label: "Gms",
      value: "gms",
    },
    {
      label: "kg",
      value: "kg",
    },
    {
      label: "Ltrs",
      value: "ltrs",
    },
  ];

  const productTypelist = [
    {
      label: "simple",
      value: "simple",
    },
    {
      label: "Variant",
      value: "Variant",
    },
  ];

  useEffect(() => {
    if (product) {
      let ProductData = JSON.parse(JSON.stringify(product));
      setValues(ProductData);
      setShippingToggle(ProductData?.shippingMultiplyWithQuantity);
    }
  }, [product]);

  function removeImage(index) {
    let imgArray = [...values?.images];
    imgArray.splice(index, 1);
    handleChangeProperties("images", imgArray);
  }

  function handleAcceptedFiles(files) {
    files.map(file =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );
    const images = values?.images || [];
    const imgArray = [...images];
    imgArray.push(files[0]);
    handleChangeProperties("images", imgArray);
  }
  const calculateDiscount = (discountType, value) => {
    const newValues = {};
    const mrpVal = values?.mrp;
    if (discountType === "percentageDiscount") {
      newValues["percentageDiscount"] = value;
      // calculate Flat Discount, Our price
      const ourPrice = (mrpVal - mrpVal * (value / 100)).toFixed(2);
      newValues["price"] = ourPrice;
      newValues["flatDiscount"] = mrpVal - newValues.price;
    } else {
      newValues["flatDiscount"] = value;
      // calculate Percentage Discount, Our price
      newValues["price"] = (mrpVal - value).toFixed(2);
      newValues["percentageDiscount"] =
        ((mrpVal - newValues.price) / mrpVal) * 100;
    }

    let obj = { ...values, ...newValues };
    setValues(obj);
  };

  const handleMRP = val => {
    const newValues = {
      flatDiscount: 0,
      percentageDiscount: 0,
      price: val,
      mrp: val,
    };

    setValues({ ...values, ...newValues });
  };

  const handleChangeProperties = (property, value) => {
    const tempValues = { ...values };
    tempValues[property] = value;
    setValues(tempValues);
  };
  const addFields = (type, event) => {
    let newValues = [...values?.properties];
    newValues.push({
      name: "",
      value: "",
    });
    handleChangeProperties(type, newValues);
  };
  let removeFields = (i, type) => {
    let newValues = [...values?.properties];
    newValues.splice(i, 1);
    handleChangeProperties("properties", newValues);
  };

  let handleOnChange = (i, e, type, name) => {
    let newValues = [...values?.properties];

    newValues[i][name] = e.target.value;
    handleChangeProperties(type, newValues);
  };

  const saveValues = () => {
    let body = {
      shippingMultiplyWithQuantity: shippingToggle,
      type: values?.type,
    };
    let basicNewValues = Object.assign(values, body);

    if (route?.catId) {
      dispatch(createNewProduct(basicNewValues));
      stepperActiveTab("3");
    } else {
      handleSubmit(basicNewValues);
    }
  };

  const handleVideoChange = (name, value) => {
    let newVal = [...values?.video];
    newVal[0][name] = value;
    handleChangeProperties(name, newVal);
  };

  const generateRandomCode = () => {
    getSkuCode()
      .then(res => {
        setSkuCode(res?.data);
      })
      .catch(err => console.log(err));
  };

  return (
    <div>
      <h6 className="mt-3 tect-primary ">Product Type</h6>
      <Card>
        <CardBody>
          <FormGroup>
            <Label for="product_type">Product Type</Label>
            <Select
              id="productType"
              value={
                values?.type ? { label: values?.type, value: values?.type } : {}
              }
              options={productTypelist || []}
              onChange={event => {
                handleChangeProperties("type", event.value);
              }}
            />
          </FormGroup>
        </CardBody>
      </Card>

      {values?.type === "simple" ? (
        <>
          <h6 className="mt-3 ">Images</h6>
          <Card>
            <CardBody className="">
              <Row>
                <Col md={3}>
                  <div className="border-dashed1 ">
                    <Dropzone
                      onDrop={acceptedFiles => {
                        handleAcceptedFiles(acceptedFiles);
                      }}
                    >
                      {({ getRootProps, getInputProps }) => (
                        <div className="dropzone ser_img_drop">
                          <div
                            className="dz-message needsclick "
                            {...getRootProps()}
                          >
                            <input {...getInputProps()} />
                            <div className="">
                              <img
                                src={banner}
                                className=" mx-auto d-block mb-4"
                                alt=""
                                height={100}
                                width={100}
                              />
                            </div>
                            <h6>upload image</h6>
                          </div>
                        </div>
                      )}
                    </Dropzone>
                  </div>{" "}
                </Col>
                <Col>
                  <div className="dropzone-previews row" id="file-previews">
                    {values?.images &&
                      values?.images?.length > 0 &&
                      values?.images?.map((img, index) => {
                        return (
                          <div
                            key={index}
                            className="col-md-4 mt-1"
                            style={{
                              position: "relative",
                            }}
                          >
                            <div className="border">
                              <div className="p-2">
                                <Row className="align-items-center">
                                  <Col className="col-auto">
                                    <ProfileImage
                                      image={img}
                                      height="40"
                                      width="40"
                                      classNames="avatar-sm rounded bg-light"
                                    />
                                  </Col>
                                  <Col>
                                    <Link
                                      to="#"
                                      className="text-muted font-weight-bold"
                                    >
                                      {img.name || img.filename}
                                    </Link>
                                    <p className="mb-0">
                                      <strong>{img.formattedSize}</strong>
                                    </p>
                                  </Col>
                                </Row>
                              </div>
                              <i
                                style={{
                                  position: "absolute",
                                  right: "16px",
                                  cursor: "pointer",
                                  top: 0,
                                }}
                                className="mdi mdi-close-thick font-size-18 text-danger"
                                id="removeImgtooltip"
                                onClick={() => removeImage(index)}
                              />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
          {/* <h6 className="mt-3 ">Videos</h6>
          <Card>
            <CardBody>
              {values?.video &&
                values.video?.map((ele, index) => {
                  return (
                    <Row key={index}>
                      <Col md={9}>
                        <div className="">
                          <label>Video URL</label>
                          <Input
                            type="textarea"
                            placeholder="Enter URL"
                            value={ele?.url}
                            onChange={event =>
                              handleVideoChange("url", event.target.value)
                            }
                          />
                        </div>
                        <div className="mt-3">
                          <label>Video placement</label>
                          <Input
                            type="number"
                            placeholder="Position"
                            value={ele?.position}
                            onChange={event =>
                              handleVideoChange("position", event.target.value)
                            }
                          />
                        </div>
                      </Col>
                      <Col>
                        <Row>
                          <Col md={12}>
                            <label>video Thumbnail</label>
                            <div className="border-dashed1 ">
                              <Dropzone
                                onDrop={acceptedFiles => {
                                  handleVideoChange(
                                    "thumbnail",
                                    acceptedFiles[0]
                                  );
                                }}
                              >
                                {({ getRootProps, getInputProps }) => (
                                  <div
                                    className="dropzone ser_img_drop"
                                    style={{
                                      height: "80px",
                                      minHeight: "0px",
                                    }}
                                  >
                                    <div
                                      className="dz-message needsclick p-0 mt-2"
                                      {...getRootProps()}
                                    >
                                      <input {...getInputProps()} />
                                      <h6>upload image</h6>
                                      <div className="">
                                        <img
                                          src={banner}
                                          className=" mx-auto d-block mb-4 mt-0"
                                          alt=""
                                          height={35}
                                          width={35}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <ProfileImage
                                image={ele?.thumbnail}
                                height="80"
                                classNames="avatar-sm rounded bg-light mt-2"
                              />
                            </div>{" "}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  );
                })}
            </CardBody>
          </Card> */}
        </>
      ) : null}

      {values?.type === "simple" ? (
        <div>
          <h6 className=" mt-3 tect-primary ">Overview</h6>
          <Card>
            <CardBody>
              <Form>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label
                        for="sku"
                        className="d-flex justify-content-between"
                      >
                        <div>Our SKU </div>
                      </Label>
                      <Input
                        id="sku"
                        name="sku"
                        placeholder=""
                        type="text"
                        value={product?.sku}
                        disabled={true}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="sku">
                        <div>Manufacturer SKU </div>
                      </Label>
                      <Input
                        id="manfsku"
                        name="manfsku"
                        placeholder=""
                        type="text"
                        value={values?.manfsku}
                        onChange={event => {
                          handleChangeProperties("manfsku", event.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label for="mrp">MRP</Label>
                      <Input
                        id="mrp"
                        name="mrp"
                        placeholder="₹"
                        type="text"
                        defaultValue={values?.mrp}
                        onChange={event => {
                          handleMRP(event.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <Label for="price" className="">
                      Our Price
                    </Label>
                    <Input
                      type="number"
                      name="price"
                      id="price"
                      placeholder="₹"
                      value={values?.price}
                      disabled
                      onChange={event => {
                        handleChangeProperties("price", event.target.value);
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="tax">Tax (%)</Label>
                      <Input
                        id="tax"
                        name="tax"
                        type="text"
                        placeholder="%"
                        value={values?.tax}
                        onChange={event => {
                          handleChangeProperties("tax", event.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="discount">Flat Discount</Label>
                      <Input
                        id="flatDiscount"
                        name="flatDiscount"
                        type="text"
                        placeholder="₹"
                        value={values?.flatDiscount}
                        onChange={event => {
                          calculateDiscount("flatDiscount", event.target.value);
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col md={4}>
                    <FormGroup>
                      <Label for="discount">Percentage Discount (%)</Label>
                      <Input
                        id="percentageDiscount"
                        name="percentagediscount"
                        type="text"
                        placeholder="%"
                        value={values?.percentageDiscount}
                        onChange={event => {
                          calculateDiscount(
                            "percentageDiscount",
                            event.target.value
                          );
                        }}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Form>
              <div>
                <FormGroup>
                  <Label for="exampleSelect">Unit Type</Label>
                  <Select
                    id="UnitType"
                    placeholder="Please"
                    value={{ label: values?.unit, value: values?.unit }}
                    options={unitList || []}
                    onChange={event => {
                      handleChangeProperties("unit", event.value);
                    }}
                  />
                </FormGroup>
              </div>
              <div className="mt-4">
                <Form>
                  <Row>
                    <Col md="4">
                      <FormGroup>
                        <Label for="TotalQuantity">Total Quantity</Label>
                        <Input
                          id="totalQuantity"
                          name="totalQuantity"
                          type="text"
                          placeholder="₹"
                          value={values?.totalQuantity}
                          onChange={event => {
                            handleChangeProperties(
                              "totalQuantity",
                              event.target.value
                            );
                          }}
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label for="minimumOrderQuantity">
                          Minimum Order Quantity
                        </Label>
                        <Input
                          id="minimumOrderQuantity"
                          name="minimumOrderQuantity"
                          type="number"
                          placeholder="0"
                          min={1}
                          value={values?.minimumOrderQuantity}
                          onChange={event =>
                            handleChangeProperties(
                              "minimumOrderQuantity",
                              event.target.value
                            )
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col md="4">
                      <FormGroup>
                        <Label for="shippingCost">Shipping Cost</Label>
                        <Input
                          id="shippingCost"
                          name="shippingCost"
                          type="number"
                          placeholder="0"
                          value={
                            shippingToggle === true
                              ? values?.shippingCost * values?.totalQuantity
                              : values?.shippingCost
                          }
                          onChange={event =>
                            handleChangeProperties(
                              "shippingCost",
                              event.target.value
                            )
                          }
                        />
                      </FormGroup>
                    </Col>

                    <Col md="12" className="mt-3 ">
                      <FormGroup switch>
                        <Label for="" className="ms-2 float-end">
                          Shipping Cost Multiply With Quantity
                        </Label>
                        <Input
                          className="float-end"
                          type="switch"
                          role="switch"
                          value={shippingToggle}
                          onChange={event => {
                            setShippingToggle(event.target.checked);
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </div>
            </CardBody>
          </Card>
          <div>
            <div className="d-flex justify-content-between">
              <h6 className="mt-3 ">Product Properties</h6>
              <button
                type=""
                className="addNew-btn btn btn-md me-2 mt-2 text-info"
                onClick={event => {
                  addFields("properties", event);
                }}
              >
                Add Property
              </button>
            </div>
            <Card>
              {" "}
              <CardBody>
                {values?.properties?.map((prop, index) => (
                  <Row className="" key={index}>
                    <Col md={5}>
                      <Form>
                        <FormGroup>
                          <Label for="" className="">
                            Property Name<span>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="name"
                            id="name"
                            rows="1"
                            value={prop.name || ""}
                            onChange={e =>
                              handleOnChange(index, e, "properties", "name")
                            }
                          />
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col md={5}>
                      <Form>
                        <FormGroup>
                          <Label for="">
                            value<span>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="value"
                            id="value"
                            rows="1"
                            value={prop.value || ""}
                            onChange={e =>
                              handleOnChange(index, e, "properties", "value")
                            }
                          />
                        </FormGroup>
                      </Form>
                    </Col>
                    <Col md={1} className="mt-4">
                      {" "}
                      <i
                        style={{
                          cursor: "pointer",
                        }}
                        className="mdi mdi-close-thick font-size-18 text-danger float-end"
                        id="removeImgtooltip"
                        onClick={() => removeFields(index, "properties")}
                      />
                    </Col>
                  </Row>
                ))}
                <hr className="mt-5" />
                <div className="d-flex mt-2 justify-content-end float-end mb-3">
                  {route?.catId ? (
                    <>
                      <Button
                        type="button"
                        color="primary"
                        className="w-md btn-sm ms-2"
                        onClick={() => saveValues()}
                      >
                        Next <i className="bx bx-right-arrow-alt mx-2"></i>{" "}
                        Specifications
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        type="button"
                        color="primary"
                        className="w-md btn-sm ms-2"
                        onClick={() => saveValues()}
                      >
                        Save Changes
                      </Button>
                    </>
                  )}
                </div>{" "}
              </CardBody>
            </Card>{" "}
          </div>
        </div>
      ) : (
        <>
          <VariantAttributes
            productType={values?.type}
            handleChangeProperties={handleChangeProperties}
            handleUpdateMulti={handleUpdateMulti}
            handleSubmit={handleSubmit}
            stepperActiveTab={stepperActiveTab}
          />
        </>
      )}
    </div>
  );
};
