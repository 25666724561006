import React from "react";
import MetaTags from "react-meta-tags";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  CardTitle,
} from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { UncontrolledCollapse } from "reactstrap";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { useRef } from "react";
import { Form, FormGroup, Label, Input } from "reactstrap";
import "../../Ecommerce/Products/Styles.scss";
const ProductCategoryCreate = () => {
  const hiddenFileInput = useRef(null);
  const handleClick = event => {
    hiddenFileInput.current.click();
  };
  return (
    <div>
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title></title>
          </MetaTags>
          <Container fluid>
            <Breadcrumbs
              title="Product Category"
              breadcrumbItem=" Create Product Category "
            />
            <Row>
              <Col md="9">
                <Card>
                  <CardBody>
                    <div className="mb-3">
                      <Form>
                        <FormGroup>
                          <Label for="exampleName">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="name"
                            name="name"
                            id=""
                            placeholder="Name"
                          />
                        </FormGroup>
                      </Form>
                    </div>
                    <div>
                      <h6 className="text-dark">Description</h6>
                      <div className="mb-3 mt-3">
                        <Button id="toggler" color="primary" className="me-3">
                          Show/Hide Editor
                        </Button>
                        <Button onClick={handleClick} className="bg-primary">
                          <i className="bx bxs-image me-2"></i> Add Media
                        </Button>
                        <input
                          type={"file"}
                          ref={hiddenFileInput}
                          style={{ display: "none" }}
                        />
                      </div>
                      <UncontrolledCollapse
                        toggler="#toggler"
                        defaultOpen="true"
                      >
                        <Card>
                          <CardBody className="p-0">
                            <CKEditor
                              editor={ClassicEditor}
                              data=""
                              onReady={editor => {
                                console.log("Editor is ready to use!", editor);
                              }}
                              onChange={(event, editor) => {
                                const data = editor.getData();
                                console.log({ event, editor, data });
                              }}
                              onBlur={(event, editor) => {
                                console.log("Blur.", editor);
                              }}
                              onFocus={(event, editor) => {
                                console.log("Focus.", editor);
                              }}
                            />
                          </CardBody>
                        </Card>
                      </UncontrolledCollapse>
                    </div>
                    <div>
                      <FormGroup>
                        <Label for="exampleText">Order</Label>
                        <Input
                          id=""
                          name="email"
                          placeholder="$"
                          type="number"
                          defaultValue="0"
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <h6 className="text-dark">
                        Status<span className="text-danger">*</span>
                      </h6>
                    </div>
                    <div>
                      <Form>
                        <FormGroup>
                          <Input type="select" name="select" id="">
                            <option>Published</option>
                            <option>2</option>
                            <option>3</option>
                            <option>4</option>
                            <option>5</option>
                          </Input>
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="mt-4">
                      <h6 className="text-dark">image</h6>
                      <div>
                        <img
                          src={banner}
                          alt=""
                          height={150}
                          width={150}
                          className="border border-2"
                        />
                        <div className="mt-2">
                          <a
                            href=""
                            className=" text-info"
                            onClick={handleClick}
                          >
                            Choose Image
                          </a>
                          <input
                            type={"file"}
                            ref={hiddenFileInput}
                            style={{ display: "none" }}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-3">
                      <Form>
                        <FormGroup switch>
                          <Input type="switch" role="switch" />
                          <h6 className="text-dark">Is Featured?</h6>
                        </FormGroup>
                      </Form>
                    </div>
                    <div className="mt-4 p-3 border border-1 border-light">
                      <div className="hr">
                        <h6>Additional Information</h6>
                      </div>
                      <div className="mt-4">
                        <h6 className="text-dark">
                          Icon Image (It will replace Font Icon if it is
                          present)
                        </h6>
                        <div>
                          <img
                            src={banner}
                            alt=""
                            height={150}
                            width={150}
                            className="border border-2"
                          />
                          <div className="mt-2">
                            <a
                              href=""
                              className=" text-info"
                              onClick={handleClick}
                            >
                              Choose Image
                            </a>
                            <input
                              type={"file"}
                              ref={hiddenFileInput}
                              style={{ display: "none" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md="3">
                <Card className="">
                  <CardTitle className=" border border-bottom-3">
                    <h6 className="ms-3 mt-3 text-primary">Parent Category</h6>
                  </CardTitle>
                  <CardBody>
                    <Button color="info" className="w-sm me-4">
                      <i className="bx bx-save me-2"></i>Save
                    </Button>
                    <Button color="success">
                      <i className="bx bxs-check-circle me-2"></i>Save & Edit
                    </Button>
                  </CardBody>
                  <CardBody className="border border-top-1">
                    <ul>
                      <li value="1">
                        <label className="mb-2">
                          <input
                            type="checkbox"
                            value="1"
                            name="categories[]"
                          />
                          Fruits &amp; Vegetables
                        </label>
                        <ul>
                          <li value="2">
                            <label className="mb-2">
                              <input
                                type="checkbox"
                                value="2"
                                name="categories[]"
                              />
                              Fruits
                            </label>
                            <ul>
                              <li value="3">
                                <label className="mb-2">
                                  <input
                                    type="checkbox"
                                    value="3"
                                    name="categories[]"
                                  />
                                  Apples
                                </label>
                              </li>
                            </ul>
                          </li>
                          <li value="4">
                            <label className="mb-2">
                              <input
                                type="checkbox"
                                value="14"
                                name="categories[]"
                              />
                              Vegetables
                            </label>
                            <ul>
                              <li value="5">
                                <label className="mb-2">
                                  <input
                                    type="checkbox"
                                    value="15"
                                    name="categories[]"
                                  />
                                  Potatoes
                                </label>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li value="6">
                        <label className="mb-2">
                          <input
                            type="checkbox"
                            value="24"
                            name="categories[]"
                          />
                          Breads Sweets
                        </label>
                        <ul>
                          <li value="7">
                            <label className="mb-2">
                              <input
                                type="checkbox"
                                value="25"
                                name="categories[]"
                              />
                              Crisps, Snacks &amp; Nuts
                            </label>
                            <ul>
                              <li value="8">
                                <label className="mb-2">
                                  <input
                                    type="checkbox"
                                    value="26"
                                    name="categories[]"
                                  />
                                  Crisps &amp; Popcorn
                                </label>
                              </li>
                            </ul>
                          </li>
                          <li value="9">
                            <label className="mb-2">
                              <input
                                type="checkbox"
                                value="39"
                                name="categories[]"
                              />
                              Tins &amp; Cans
                            </label>
                            <ul>
                              <li value="10">
                                <label className="mb-2">
                                  <input
                                    type="checkbox"
                                    value="40"
                                    name="categories[]"
                                  />
                                  Tomatoes
                                </label>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li value="11">
                        <label className="mb-2">
                          <input
                            type="checkbox"
                            value="52"
                            name="categories[]"
                          />
                          Frozen Seafoods
                        </label>
                      </li>
                      <li value="12">
                        <label className="mb-2">
                          <input
                            type="checkbox"
                            value="54"
                            name="categories[]"
                          />
                          Wines &amp; Alcohol Drinks
                        </label>
                        <ul>
                          <li value="13">
                            <label className="mb-2">
                              <input
                                type="checkbox"
                                value="55"
                                name="categories[]"
                              />
                              Ready Meals
                            </label>
                            <ul>
                              <li value="14">
                                <label className="mb-2">
                                  <input
                                    type="checkbox"
                                    value="56"
                                    name="categories[]"
                                  />
                                  Meals for 1
                                </label>
                              </li>
                            </ul>
                          </li>
                          <li value="15">
                            <label className="mb-2">
                              <input
                                type="checkbox"
                                value="67"
                                name="categories[]"
                              />
                              Salad &amp; Herbs
                            </label>
                            <ul>
                              <li value="16">
                                <label className="mb-2">
                                  <input
                                    type="checkbox"
                                    value="68"
                                    name="categories[]"
                                  />
                                  Salad Bags
                                </label>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li value="18">
                        <label className="mb-2">
                          <input
                            type="checkbox"
                            value="80"
                            name="categories[]"
                          />
                          Tea &amp; Coffee
                        </label>
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </React.Fragment>
    </div>
  );
};

export default ProductCategoryCreate;
