import React, { useEffect, useState, useRef, useCallback } from "react";
import MetaTags from "react-meta-tags";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { OrderHistory } from "./OrderHistory";
import { OrderDetails } from "./OrderDetails";
import { OrderList } from "./OrderList";
import Spinner from "components/spinner";
import "./list.scss";
import {
  getOrderbyId,
  getOrdersCountsAndList,
  getAdminOrdersList,
  getAdminSearchOrdersList,
  getPaginatedOrders,
} from "services/orders.service";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import _ from "lodash";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

export const OrdersList = () => {
  const [loading, setLoading] = useState(null);
  const [searchValue, setSearchValue] = useState("");
  const [searchInput, setSearchInput] = useState("");
  const [counts, setCounts] = useState([]);
  const [activeTab, setActiveTab] = useState("1");
  const [defaultTab, setDefaultTab] = useState("1");
  const [defaultOrders, setDefaultOrders] = useState([]);
  const [displayOrders, setDisplayOrders] = useState([]);
  const [activeOrder, setActiveOrder] = useState(null);
  const [completedStatusCount, setCompletedStatusCount] = useState(0);
  const [paidStatusCount, setPaidStatusCount] = useState(0);
  const [acceptedStatusCount, setAcceptedStatusCount] = useState(0);
  const [pendingStatusCount, setPendingStatusCount] = useState(0);
  const [cancelledStatusCount, setCancelledStatusCount] = useState(0);
  const [inProgressStatusCount, setInProgressStatusCount] = useState(0);
  const [notAvailableStatusCount, setNotAvailableStatusCount] = useState(0);
  const [todaysOrdersCount, setTodaysOrdersCount] = useState(0);
  const [allOrdersCount, setAllOrdersCount] = useState(0);
  const [nextUrl, setNextUrl] = useState("");
  const [loadMore, setLoadMore] = useState(false);

  useEffect(() => {
    getOrdersList();
  }, []);

  useEffect(() => {
    FilterByStatus();
  }, [counts]);

  const getOrdersList = () => {
    setLoading(true);
    getOrdersCountsAndList()
      .then(res => {
        setDisplayOrders(res?.data?.orders);
        setDefaultOrders(res?.data?.orders);
        setSearchInput("");
        setSearchValue("");
        setActiveTab("1");
        setDefaultTab("1");
        setCounts(res?.data?.counts);
        setTodaysOrdersCount(res?.meta?.count);
        if (res?.data.length < 10) {
          setLoadMore(false);
        } else {
          setNextUrl(res?.meta?.nextUrl);
          setLoadMore(true);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };

  /*      Start Active Order      */
  const handleActiveTab = (tab, status) => {
    setActiveTab(tab);
    setDefaultTab(tab);
    setSearchInput("");
    setSearchValue("");
    GetOrdersByTab(status);
    setActiveOrder(null);
  };

  const GetOrdersByTab = status => {
    setLoading(true);
    getAdminOrdersList(status)
      .then(res => {
        setDisplayOrders(res?.data);
        setDefaultOrders(res?.data);
        if (res?.data.length < 10) {
          setLoadMore(false);
        } else {
          setNextUrl(res?.meta?.nextUrl);
          setLoadMore(true);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  /*      End Active Order        */

  /*      Start Active Order      */
  const handleActiveOrder = order => {
    getOrderByID(order._id);
  };

  const getOrderByID = id => {
    setLoading(true);
    getOrderbyId(id)
      .then(res => {
        let resp = res?.data || [];
        setActiveOrder(resp);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  };
  /*      End Active Order        */

  /*      Start Search Order      */
  useEffect(() => {
    handleSearch(searchValue);
  }, [searchValue]);

  const handleSearch = _.debounce(input => {
    setLoading(true);
    if (input.length !== 0) {
      getAdminSearchOrdersList(input)
        .then(res => {
          setDisplayOrders(res?.data);
          setActiveTab(null);
          setActiveOrder(null);
          setSearchInput(input);
          if (res?.data.length < 10) {
            setLoadMore(false);
          } else {
            setNextUrl(res?.meta?.nextUrl);
            setLoadMore(true);
          }
          setLoading(false);
        })
        .catch(err => {
          setLoading(false);
        });
    } else {
      setTimeout(() => {
        setSearchInput(input);
        setActiveTab(defaultTab);
        setDisplayOrders(defaultOrders);
        setActiveOrder(null);
        setLoading(false);
      }, 500);
    }
  }, 2000);
  /*      End Search Order        */

  /*      Start Order Pagination      */
  const handleScroll = _.debounce(() => {
    setLoading(true);
    getPaginatedOrders(nextUrl)
      .then(res => {
        setDisplayOrders([...displayOrders, ...res?.data]);
        setDefaultOrders([...defaultOrders, ...res?.data]);
        if (res?.data.length < 10) {
          setLoadMore(false);
        } else {
          setNextUrl(res?.meta?.nextUrl);
          setLoadMore(true);
        }
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
      });
  }, 500);
  /*      End Order Pagination        */

  const FilterByStatus = () => {
    if (counts?.length !== 0) {
      // Accept Status
      let AcceptedStatus = counts?.filter(ele => ele?._id === "Accept");
      let TotalAcceptedStatusCount = AcceptedStatus[0] // total count
        ? AcceptedStatus[0]?.statusCount
        : 0;

      let AcceptedStatusCount = AcceptedStatus[0] // order confirmed count
        ? AcceptedStatus[0]?.confirmedByStylistCount
        : 0;

      let NotAvailableStatusCount = AcceptedStatus[0] // stylist not available count
        ? AcceptedStatus[0]?.notAvailableCount
        : 0;

      // Pending status - "Accept" status but not yet confirmed by stylist
      let PendingStatusCount =
        TotalAcceptedStatusCount -
        AcceptedStatusCount -
        NotAvailableStatusCount;

      // In Progress Status - Orders in progress
      let InprogressStatus = counts?.filter(ele => ele?._id === "In Progress");
      let InProgressStatusCount = InprogressStatus[0]
        ? InprogressStatus[0]?.statusCount
        : 0;

      // Completed Status - Order Service Completed, but no payment yet
      let CompletedStatus = counts?.filter(ele => ele?._id === "Completed");
      let CompletedStatusCount = CompletedStatus[0]
        ? CompletedStatus[0]?.statusCount
        : 0;

      // Paid Status - Order officially completed with payment confirmed
      let PaidStatus = counts?.filter(ele => ele?._id === "Paid");
      let PaidStatusCount = PaidStatus[0] ? PaidStatus[0]?.statusCount : 0;

      // Cancelled Status - Order cancelled, mostly from user side
      let CancelledStatus = counts?.filter(ele => ele?._id === "cancel");
      let CancelledStatusCount = CancelledStatus[0]
        ? CancelledStatus[0]?.statusCount
        : 0;

      // Total Order Count
      let allOrders =
        TotalAcceptedStatusCount +
        InProgressStatusCount +
        CompletedStatusCount +
        PaidStatusCount +
        CancelledStatusCount;

      setAcceptedStatusCount(AcceptedStatusCount);
      setNotAvailableStatusCount(NotAvailableStatusCount);
      setPendingStatusCount(PendingStatusCount);
      setInProgressStatusCount(InProgressStatusCount);
      setCompletedStatusCount(CompletedStatusCount);
      setPaidStatusCount(PaidStatusCount);
      setCancelledStatusCount(CancelledStatusCount);
      setAllOrdersCount(allOrders);
    }
  };

  return (
    <React.Fragment>
      <Spinner loading={loading} />
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Orders" breadcrumbItem="Orders List" />
          <BreadcrumbSub subtile="Service orders" SubBreadcrumbItem="Orders" />
          <div className="tabs mt-2 order-dashboard">
            <Nav tabs>
              <NavItem className="d-flex justify-content-space-between flex-wrap">
                <NavLink>
                  <CardBody
                    className="bg-today shadow-sm rounded"
                    onClick={() => handleActiveTab("1", "today")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">Today</h6>
                      <h2 className="">{todaysOrdersCount}</h2>
                      {activeTab === "1" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>

                <NavLink>
                  <CardBody
                    className="bg-pending shadow-sm rounded"
                    onClick={() => handleActiveTab("2", "pending")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">Pending</h6>
                      <h2 className="">{pendingStatusCount}</h2>
                      {activeTab === "2" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
                <NavLink>
                  <CardBody
                    className="bg-secondary  shadow-sm rounded"
                    onClick={() => handleActiveTab("3", "accept")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">Accept</h6>
                      <h2 className="">{acceptedStatusCount}</h2>
                      {activeTab === "3" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
                <NavLink>
                  <CardBody
                    className="bg-warning shadow-sm rounded"
                    onClick={() => handleActiveTab("4", "inProgress")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">In Progress</h6>
                      <h2 className=""> {inProgressStatusCount}</h2>
                      {activeTab === "4" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
                <NavLink>
                  <CardBody
                    className="bg-success shadow-sm rounded"
                    onClick={() => handleActiveTab("5", "completed")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">Completed</h6>
                      <h2 className=""> {completedStatusCount}</h2>
                      {activeTab === "5" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
                <NavLink>
                  <CardBody
                    className="bg-info shadow-sm rounded"
                    onClick={() => handleActiveTab("6", "paid")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">Paid</h6>
                      <h2 className=""> {paidStatusCount}</h2>
                      {activeTab === "6" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
                <NavLink>
                  <CardBody
                    className="bg-danger shadow-sm rounded"
                    onClick={() => handleActiveTab("7", "cancelled")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">Cancelled</h6>
                      <h2 className="">{cancelledStatusCount}</h2>
                      {activeTab === "7" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
                <NavLink>
                  <CardBody
                    className="bg-primary shadow-sm rounded"
                    onClick={() => handleActiveTab("8", "all")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">All Orders</h6>
                      <h2 className="">{allOrdersCount}</h2>
                      {activeTab === "8" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
                <NavLink className="stylist_not_available">
                  <CardBody
                    className="bg-red shadow-sm rounded"
                    onClick={() => handleActiveTab("9", "notAvailable")}
                  >
                    <div className="text-center">
                      <h6 className="pt-2">Stylist not available</h6>
                      <h2 className="">{notAvailableStatusCount}</h2>
                      {activeTab === "9" ? (
                        <div className="check_mark">
                          <i className="bx bx-check-circle"></i>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </CardBody>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
          <Row className="mt-3">
            <Col md="4">
              <Card>
                <CardBody className="p-2">
                  <div className="search-box  position-relative w-100 ">
                    <i className="bx bx-search-alt search-icon" />
                    <input
                      className="form-control  rounded-pill bg-white "
                      type="search"
                      id="example-search-input"
                      placeholder=" Search..."
                      // value={searchValue}
                      onChange={e =>
                        // setSearchValue(e.target.value)
                        handleSearch(e.target.value)
                      }
                    />
                  </div>
                  {searchInput.length !== 0 ? (
                    <span
                      role="button"
                      className="font-size-12 badge-soft-info py-1 px-2 me-1 fw-bold badge bg-bg-info search-input"
                    >
                      <span className="font-size-12">Search: </span>
                      <span className="font-size-12 fw-bold text-dark">
                        <span className="ps-1 text-dark pt-1">
                          {searchInput}
                        </span>
                      </span>
                    </span>
                  ) : (
                    ""
                  )}
                </CardBody>
                <OrderList
                  handleActiveOrder={handleActiveOrder}
                  handleScroll={handleScroll}
                  displayOrders={displayOrders}
                  loadMore={loadMore}
                />
              </Card>
            </Col>
            <Col md="8">
              <OrderDetails
                activeOrder={activeOrder}
                reload={handleActiveOrder}
                getallOrders={getOrdersList}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default OrdersList;
