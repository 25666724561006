import React from "react";

import { Label, Input } from "reactstrap";
const MultipleCheckbox = ({ text, handleOnChange }) => {
  return (
    <div className="d-flex me-2  px-0">
      <Input
        key={text}
        id={text}
        type="checkbox"
        className="form-check-Inpu me-1 cursor-pointer"
        onChange={event => handleOnChange(text)}
      />
      <Label
        className="form-check-Label fw-bold mb-0 cursor-pointer"
        htmlFor={text}
      >
        {text}
      </Label>
    </div>
  );
};

export default MultipleCheckbox;
