export const getAllSettlements = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/settlements/getAll`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get settlements orders ${e}`));
};

export const getSettlementsByStylist = stylistId => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/settlements/getByStylist/${stylistId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get settlements orders ${e}`));
};
export const getSettlementsByCompanyId = ComId => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/settlements/getByCompany/${ComId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get settlements orders ${e}`));
};
export const getSettlementsSummaryByStylistId = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/settlements/summary/stylist`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get settlements summary ${e}`));
};

export const getSettlementsSummaryByCompanyId = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/settlements/summary/company`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get settlements summary ${e}`));
};
export const UpdateOrderSettlement = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/settlements/settleOrder`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at update coupon ${e}`));
};
