import React, { useEffect, useState } from "react";
import { Col, Row, Label, Button, FormGroup, Input } from "reactstrap";
import { updatefeildService } from "services/services.service";

export const SearchKeyInfoModal = ({ toggle, initValues, reloadService }) => {
  const initKeywords = initValues?.keyWords || [];
  const [input, setInput] = useState("");
  const [keys, setKeys] = useState([...initKeywords]);

  const onSubmit = () => {
    updatefeildService(initValues._id, {
      keyWords: keys,
    })
      .then(res => {
        reloadService();
        toggle();
      })
      .catch(e => console.log("====", e));
  };

  const handleClick = () => {
    setKeys(prev => [...prev, input]);
    setInput("");
  };

  const handleDelete = index => {
    const newKeys = [...keys];
    newKeys.splice(index, 1);
    setKeys(newKeys);
  };
  return (
    <>
      <Row className="mt-3 mx-0 mb-4">
        <Col md={12}>
          <div className="d-flex mb-3">
            <Input value={input} onInput={e => setInput(e.target.value)} />
            <Button
              color="primary"
              className="btn btn-sm"
              onClick={() => handleClick()}
            >
              Add
            </Button>
          </div>

          <div>
            <ul className="d-flex flex-wrap mx-0 px-0">
              {keys.map((ele, ind) => {
                return (
                  <div className="my-3" key={ind}>
                    <span className="me-3 ">
                      <span className=" h6 border border-1 bg-light py-1 p-1 ">
                        {ele}
                      </span>
                      <span
                        onClick={() => handleDelete(ind)}
                        className="key-del-btn h6 border border-1  text-danger py-1 px-2 "
                      >
                        X
                      </span>
                    </span>
                  </div>
                );
              })}
            </ul>
          </div>
        </Col>
      </Row>

      <div className="d-flex mt-4 justify-content-end">
        <Button
          color="light"
          className="w-md btn-sm me-2"
          onClick={() => toggle()}
        >
          Back
        </Button>
        <Button
          color="primary"
          className="w-md btn-sm ms-2"
          onClick={() => onSubmit()}
        >
          Submit
        </Button>
      </div>
    </>
  );
};
export default SearchKeyInfoModal;
