import { GET_BRANDS, GET_BRAND_FAIL } from "./actionTypes";

import { getBrands } from "services/ecommerce/brand.service";

export const getEcomBrands = dispatch => {
  return getBrands()
    .then(res => {
      if (res.data) {
        const result = res.data.map(brand => {
          let obj = {
            label: brand.name,
            value: brand._id,
          };
          return obj;
        });
        dispatch({
          type: GET_BRANDS,
          payload: result,
        });
      } else {
        dispatch({
          type: GET_BRAND_FAIL,
          payload: "Something went wrong",
        });
      }
    })
    .catch(err => {
      dispatch({
        type: GET_BRAND_FAIL,
        payload: err.message,
      });
    });
};
