import React, { useState, useEffect, useMemo } from "react";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  //   Form,
  FormFeedback,
  Input,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import Dropzone from "react-dropzone";
import "react-dual-listbox/lib/react-dual-listbox.css";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import ProfileImage from "components/profileImage";

//Country Flag with Phone number
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

//Country-State-Cities
import csc from "countries-states-cities";

const defaultStateList = [
  {
    label: "please Select Country",
    value: "please Select Country",
    isDisabled: true,
  },
];
const defaultCityList = [
  {
    label: "please Select State",
    value: "please Select State",
    isDisabled: true,
  },
];

const step1 = props => {
  const { handleSubmitStepper, step1Values, leaveToList } = props;
  const [selectedType, setSelectedType] = useState(null);
  const [countriesList, setCountriesList] = useState([]);
  const [statesList, setStatesList] = useState(defaultStateList);
  const [citiesList, setCitiesList] = useState(defaultCityList);

  const typeList = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" },
    // { label: "Unisex", value: "Unisex" },
    // { label: "Other", value: "Other" },
  ];

  const initialValues = step1Values;
  const validationSchema = Yup.object().shape({
    legalname: Yup.string().required("Please Enter First Name"),
    shortname: Yup.string().required("Please Enter Last Name"),
    gender: Yup.string().required("Please Select gender"),
    address: Yup.string().required("Please Enter Address"),
    zipcode: Yup.string().required("Please Enter Zipcode"),
    city: Yup.object().required("Please Enter City"),
    state: Yup.object().required("Please Enter State"),
    country: Yup.object().required("Please Select Country"),
    mobileNumber: Yup.string().required("Please Enter Mobile Number"),
    // since: Yup.string().required("Please Enter Styling Profession Since"),
  });

  useEffect(() => {
    //Get All Countries List
    let list = [];
    csc.getAllCountries().forEach(ele => {
      let obj = {
        value: ele.iso2,
        id: ele.id,
        label: ele.name,
      };
      list.push(obj);
    });
    setCountriesList(list);
  }, []);

  function formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  function handleSelectType(event) {
    setSelectedType(event);
  }

  function handleSelectCountry(event) {
    //Get All States Under the Selected Country
    let stateArray = [];
    csc.getStatesOfCountry(event.id).forEach(ele => {
      let stateObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      stateArray.push(stateObj);
    });
    setStatesList(stateArray);
  }

  function handleSelectState(event) {
    let citiesArr = [];
    csc.getCitiesOfState(event.id).forEach(ele => {
      let cityObj = {
        value: ele.name,
        id: ele.id,
        label: ele.name,
      };
      citiesArr.push(cityObj);
    });
    setCitiesList(citiesArr);
  }

  const onSubmit = values => {
    handleSubmitStepper(values, "stepper1");
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setFieldValue,
          handleChange,
          values,
        }) => {
          // console.log(errors);
          // console.log(values);
          return (
            <Form>
              <Row>
                <Col md={12}>
                  <Row>
                    <Col sm={4}>
                      <Label htmlFor="legalname" className=" col-form-label">
                        First Name
                        <span className="text-danger">*</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="legalname"
                        placeholder="Enter Your First Name"
                        name="legalname"
                        onChange={handleChange}
                        value={values.legalname}
                        invalid={
                          touched.legalname && errors.legalname ? true : false
                        }
                      />
                      {touched.legalname && errors.legalname ? (
                        <FormFeedback type="invalid">
                          {errors.legalname}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col sm={4}>
                      <Label htmlFor="shortname" className="col-form-label">
                        Last Name
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="shortname"
                        placeholder="Enter Your Last Name"
                        name="shortname"
                        value={values.shortname}
                        onChange={handleChange}
                        invalid={
                          touched.shortname && errors.shortname ? true : false
                        }
                      />
                      {touched.shortname && errors.shortname ? (
                        <FormFeedback type="invalid">
                          {errors.shortname}
                        </FormFeedback>
                      ) : null}
                    </Col>

                    <Col sm={4}>
                      <Label
                        htmlFor="Gender"
                        className="col-sm-3 col-form-label"
                      >
                        Gender
                        <span className="text-danger">*</span>
                      </Label>

                      <Select
                        name="gender"
                        id="gender"
                        value={selectedType}
                        onChange={event => {
                          handleSelectType(event);
                          handleChange("gender")(event.value);
                        }}
                        options={typeList}
                        classNamePrefix="select2-selection"
                      />
                    </Col>

                    <Col sm={4} className="mt-3">
                      <Label htmlFor="country" className="col-form-label">
                        Country
                        <span className="text-danger">*</span>
                      </Label>

                      {/* <Input
                        type="text"
                        className="form-control"
                        id="country"
                        placeholder="Enter Your Country"
                        name="country"
                        onChange={handleChange}
                        value= {values.country}
                      /> */}
                      <Select
                        name="country"
                        id="country"
                        onChange={event => {
                          // console.log(event);
                          setFieldValue("country", event);
                          setFieldValue("state", null);
                          setFieldValue("city", null);
                          handleSelectCountry(event);
                        }}
                        options={countriesList}
                        value={values.country}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col className="mt-3" sm={4}>
                      <Label htmlFor="state" className="col-form-label">
                        State
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="state"
                        id="state"
                        onChange={event => {
                          // console.log(event);
                          if (event) {
                            handleSelectState(event);
                            setFieldValue("state", event);
                            setFieldValue("city", null);
                          }
                        }}
                        options={statesList}
                        value={values.state}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col className="mt-3" sm={4}>
                      <Label htmlFor="city" className="col-form-label">
                        City
                        <span className="text-danger">*</span>
                      </Label>
                      <Select
                        name="city"
                        id="city"
                        onChange={event => {
                          // console.log(event);
                          setFieldValue("city", event);
                        }}
                        options={citiesList}
                        value={values.city}
                        classNamePrefix="select2-selection"
                      />
                    </Col>
                    <Col className="mt-3" sm={4}>
                      <Label htmlFor="zipcode" className="col-form-label">
                        Zipcode
                        <span className="text-danger">*</span>
                      </Label>

                      <Input
                        type="text"
                        className="form-control"
                        id="zipcode"
                        placeholder="Enter Your Zipcode"
                        name="zipcode"
                        onChange={handleChange}
                        value={values.zipcode}
                      />
                    </Col>

                    <Col sm={4} className="mt-3">
                      <Label htmlFor="mobileNumber" className="col-form-label">
                        Mobile Number
                        <span className="text-danger">*</span>
                      </Label>

                      {/* <Input
                        type="text"
                        className="form-control"
                        id="mobileNumber"
                        placeholder="Enter Your Mobile Number"
                        name="mobileNumber"
                        onChange={handleChange}
                        value= {values.mobileNumber}
                      /> */}
                      <PhoneInput
                        className="custom_country_phoneNumber"
                        id="mobileNumber"
                        placeholder="Enter Your Mobile Number"
                        name="mobileNumber"
                        // defaultCountry="RU"
                        international={false}
                        defaultCountry={
                          values.country ? values.country.value : null
                        }
                        value={values.mobileNumber}
                        onChange={value => {
                          // console.log(value);
                          setFieldValue("mobileNumber", value);
                        }}
                        error={
                          values.mobileNumber
                            ? isValidPhoneNumber(values.mobileNumber)
                              ? undefined
                              : "Invalid phone number"
                            : "Phone number required"
                        }
                      />
                      {/* Phone Number Validation */}
                      {values.mobileNumber &&
                      !isValidPhoneNumber(values.mobileNumber) ? (
                        <span className="text-danger">
                          {"Please Eneter Valid Phone Number"}
                        </span>
                      ) : null}
                    </Col>
                    <Col className="mt-3" sm={4}>
                      <Label
                        htmlFor="address"
                        className="col-sm-3 col-form-label"
                      >
                        Address
                        <span className="text-danger">*</span>
                      </Label>

                      <textarea
                        name="address"
                        className="form-control"
                        id="address"
                        rows={3}
                        onChange={handleChange}
                        value={values.address}
                      ></textarea>
                      {touched.address && errors.address ? (
                        <FormFeedback type="invalid">
                          {errors.address}
                        </FormFeedback>
                      ) : null}
                    </Col>
                  </Row>
                </Col>
                {/* <Col md={2}>
                  <Row className="mb-4">
                    <div className="text-end">
                      <div className="profile-div text-end d-inline-flex justify-content-end flex-column">
                        <ProfileImage
                          classNames={"border p-3"}
                          image={values.image}
                        />
                        <Dropzone
                          id="validationCustom05"
                          onDrop={acceptedFiles => {
                            acceptedFiles.map(file =>
                              Object.assign(file, {
                                preview: URL.createObjectURL(file),
                                formattedSize: formatBytes(file.size),
                              })
                            );
                            setFieldValue("image", acceptedFiles[0]);
                          }}
                        >
                          {({ getRootProps, getInputProps }) => (
                            <div className="">
                              <div className="" {...getRootProps()}>
                                <input {...getInputProps()} />
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mt-2"
                                >
                                  {" "}
                                  Upload Profile
                                </button>
                              </div>
                            </div>
                          )}
                        </Dropzone>
                      </div>
                    </div>
                  </Row>

                  <Row className="mb-3">
                    <div className="d-flex justify-content-between">
                      <Label
                        htmlFor="since"
                        className="col-sm-3 col-form-label flex-grow-1"
                      >
                        Experince
                        <span className="text-danger">*</span>
                      </Label>
                      <Input
                        type="text"
                        className="form-control max-w-40per"
                        id="since"
                        placeholder=""
                        name="since"
                        onChange={handleChange}
                        value={values.since}
                      />
                    </div>
                  </Row>
                  <Col md={2}>
                    <div className="form-check mt-3">
                      <Input
                        type="checkbox"
                        className="form-check-input"
                        id="idVerification"
                        value={values?.idVerification}
                        onChange={handleChange}
                      />
                      <Label
                        className="form-check-label"
                        htmlFor="idVerification"
                      >
                        Certified
                      </Label>
                    </div>
                  </Col>
                </Col> */}
              </Row>
              {/* <Row>
                <FormGroup className="mb-3 px-1">
                  <Label htmlFor="description">
                    Description <span className="text-danger">*</span>
                  </Label>
                  <div className="custom_ck_editor">
                    <CKEditor
                      id="description"
                      name="since"
                      editor={ClassicEditor}
                      data={values.description ? values.description : ""}
                      onReady={editor => {
                        console.log("Editor is ready to use!", editor);
                        if (values.description) {
                          editor.setData(values.description);
                        }
                      }}
                      onChange={(event, editor) => {
                        const data = editor.getData();
                        handleChange("description")(data);
                      }}
                      // onBlur={(event, editor) => {
                      //   console.log("Blur.", editor);
                      // }}
                      // onFocus={(event, editor) => {
                      //   console.log("Focus.", editor);
                      // }}
                    />
                  </div>
                  <Label className="statusValidation">
                    {" "}
                    {errors.description ? errors.description : ""}
                  </Label>
                </FormGroup>
              </Row> */}
              <hr />
              <div className="d-flex mt-4 justify-content-end">
                <Button
                  color="light"
                  className="w-md btn-sm me-2"
                  onClick={leaveToList}
                >
                  Cancel
                </Button>
                <Button
                  color="primary"
                  className="w-md btn-sm ms-2"
                  type="submit"
                  // onClick={leaveToList}
                >
                  Create
                </Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
};

export default step1;
