// Import the functions you need from the SDKs you need
import { getMessaging, onMessage } from "@firebase/messaging";
import ToastMessage from "components/Toast";
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBnhrN7QlyGukoey_jIxHOUH12toLtrsm0",
  authDomain: "stylify-e7e35.firebaseapp.com",
  projectId: "stylify-e7e35",
  storageBucket: "stylify-e7e35.appspot.com",
  messagingSenderId: "659465273540",
  appId: "1:659465273540:web:07a2dcf8adeb142b4bc991",
  measurementId: "G-F5ETRZ7Q08",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const firebaseMessaging = getMessaging(app);
onMessage(firebaseMessaging, payload => {
  console.log("firebase.js Message received. ", payload);

  ToastMessage(
    "warning",
    payload.notification.title,
    payload.notification.body,
    payload.data
  );
  // window.location.href = '/orders';
  // ...
});

export { messaging };
