import React, { useState } from "react";
import Step1 from "./step1";
import { withRouter } from "react-router-dom";

import { Container, Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import { createArtist } from "services/artist.service";
import { createImageUrl } from "services/imageUrl.service";
import SweetAlert from "react-bootstrap-sweetalert";
import "./artist.scss";

const ProviderRegisterArtist = ({ toggle, modal, reload, props }) => {
  const [step1Values, setStep1Values] = useState({
    title: "",
    name: "",
    area: "",
    city: null,
    state: null,
    country: null,
    mobileNumber: "",
    email: "",
    artistImage: "",
    category: "64ec466597bf89821429abcd",
    status: "Inactive",
  });

  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);

  const leaveToList = () => {
    toggle();
    setsuccess_msg(false);
    seterror_msg(false);
    setStep1Values({});
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Indvidual created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button color="danger" className="w-md btn-sm mx-1">
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const onSubmit = async values => {
    const data = { ...step1Values, ...values };
    console.log(data, "ddddddd");
    try {
      if (data.artistImage) {
        let imageFormData = new FormData();
        imageFormData.set("image", data.artistImage);

        const { success, image } = await createImageUrl(imageFormData);

        if (success) data["artistImage"] = image;
      }

      let obj = { ...data };

      obj.city = data.city.label;
      obj.state = data.state.label;
      obj.country = data.country.label;

      createArtist(obj)
        .then(res => {
          setsuccess_msg(true);
        })
        .catch(err => {
          console.log(err);
        });
    } catch (err) {
      console.log(err);
    }
  };

  const handleSubminSteper = (values, stepperName) => {
    onSubmit(values);
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="lg">
        <ModalHeader toggle={toggle}>
          Provider Artist Register | stylepro
        </ModalHeader>

        <ModalBody>
          <Container fluid={false}>
            <Step1
              step1Values={step1Values}
              handleSubmitStepper={handleSubminSteper}
              leaveToList={leaveToList}
            />
            {success_msg ? successPopup() : null}
            {error_msg ? errorPopup() : null}
          </Container>
        </ModalBody>
      </Modal>
    </>
  );
};

export default withRouter(ProviderRegisterArtist);
