import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";

import {
  Card,
  Col,
  Container,
  Row,
  CardBody,
  Label,
  Button,
  FormFeedback,
  Input,
  FormGroup,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
//SweetAlert
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
  createQuestion,
  getQuestion,
  updateQuestion,
} from "services/ratingQuestion.service";

const defaultValues = {
  name: "",
  active: false,
  isText: false,
};

const CreateQuestion = ({ modal, toggle, id = false, reloadService }) => {
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [loading, setLoading] = useState(false);
  const [active, setActive] = useState(false);
  const [isText, setIsText] = useState(false);
  const [selectedType, setSelectedType] = useState(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Please Enter Question"),
  });

  const typeList = [
    { label: "indvidual", value: "indvidual" },
    { label: "app", value: "app" },
  ];
  const onSubmit = values => {
    if (id) {
      editChanges(values);
    } else {
      saveChanges(values);
    }
  };

  const saveChanges = values => {
    createQuestion(values)
      .then(res => {
        if (res.message === "Successfully added the Rating Questions") {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reloadService();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const editChanges = values => {
    setLoading(true);
    updateQuestion(id, values)
      .then(res => {
        if (res?.data?.success === true) {
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
        }
        setLoading(false);
        reloadService();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    toggle();
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };
  function handleSelectType(event) {
    setSelectedType(event);
  }

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Question created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Create question</title>
        </MetaTags>
        {/* <Spinner loading={loading} /> */}
        <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
          <ModalHeader toggle={toggle}>
            {id ? "Edit Question" : "Create Question"}
          </ModalHeader>
          <ModalBody className="bg-light">
            <Container fluid={false}>
              <Card>
                <CardBody>
                  <Formik
                    enableReinitialize
                    initialValues={defaultValues}
                    validationSchema={validationSchema}
                    onSubmit={onSubmit}
                  >
                    {({
                      errors,
                      touched,
                      setFieldValue,
                      handleChange,
                      values,
                    }) => {
                      useEffect(() => {
                        if (id) {
                          setLoading(true);
                          getQuestion(id)
                            .then(res => {
                              const data = { ...res.data };
                              const fields = ["name", "active", "isText"];
                              fields.forEach(field =>
                                setFieldValue(field, data[field], false)
                              );
                              setSelectedType({
                                label: data.type,
                                value: data.type,
                              });
                              setLoading(false);
                            })
                            .catch(e => {
                              console.log(e);
                            });
                        }
                      }, []);

                      return (
                        <Form>
                          <Row className="mt-4">
                            <Col md={12} className="px-4 mb-3">
                              <FormGroup className="mb-3">
                                <Label htmlFor="validationCustom01">
                                  Question
                                </Label>
                                <Input
                                  name="name"
                                  placeholder="Name"
                                  type="text"
                                  className="form-control"
                                  id="name"
                                  onChange={handleChange}
                                  value={values?.name}
                                  invalid={
                                    touched.name && errors.name ? true : false
                                  }
                                />
                                {touched.name && errors.name ? (
                                  <FormFeedback type="invalid">
                                    {errors.name}
                                    {touched.name}
                                  </FormFeedback>
                                ) : null}
                              </FormGroup>
                            </Col>
                          </Row>
                          <Row className="align-items-center px-3">
                            <Col md="3">
                              {" "}
                              <Select
                                name="type"
                                id="type"
                                onChange={event => {
                                  handleChange("type")(event.value);
                                  setSelectedType({
                                    label: event.value,
                                    value: event.value,
                                  });
                                }}
                                options={typeList}
                                value={selectedType}
                                // classNamePrefix="select2-selection"
                              />
                            </Col>
                            <Col md="2" className="px-4">
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  role="switch"
                                  name="active"
                                  // value={values?.active}
                                  // checked={values?.active}
                                  defaultChecked={values?.active}
                                  onChange={event => {
                                    handleChange(event);
                                  }}
                                />{" "}
                                <Label>Active</Label>
                              </FormGroup>
                            </Col>
                            <Col md="2">
                              <FormGroup switch>
                                <Input
                                  type="switch"
                                  role="switch"
                                  name="isText"
                                  // checked={values?.isText}
                                  defaultChecked={values?.isText}
                                  // value={values.isText}
                                  onChange={event => {
                                    handleChange(event);
                                  }}
                                />{" "}
                                <Label>IsText</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                          <hr></hr>
                          <div className="d-flex justify-content-end">
                            <Button
                              color="light"
                              className="w-md btn-sm me-3"
                              onClick={leaveToList}
                            >
                              cancel
                            </Button>
                            {!id ? (
                              <Button
                                color="primary"
                                type="submit"
                                className="w-md btn-sm me-4"
                              >
                                Submit
                              </Button>
                            ) : (
                              <Button
                                color="primary"
                                className="w-md btn-sm me-4"
                                type="submit"
                              >
                                Save Changes
                              </Button>
                            )}
                          </div>
                        </Form>
                      );
                    }}
                  </Formik>

                  {success_msg ? successPopup() : null}
                  {error_msg ? errorPopup() : null}
                </CardBody>
              </Card>
            </Container>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default CreateQuestion;
