import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import { withRouter } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import * as _ from "lodash";
import "./info.scss";
import { Card, Col, Container, Row, CardBody, Button, Input } from "reactstrap";
import {
  getIndvidualById,
  updateIndvidualFields,
  updateIndvidual,
} from "services/indvidual.service";

import ServiceInfo from "./service/servicesInfo";

//Import Breadcrumb
// import Breadcrumb from "../../../components/Common/Breadcrumb";x`
import Breadcrumb from "components/Common/Breadcrumb";
import BasicInfo from "./basicInfo";
import IdProofs from "./idproof";
import ZipcodeInfo from "./zipcodeInfo";
import ExpertiseInfo from "./expertiseInfo";
import MyCompanies from "./myCompanies";
import StatusBar from "./statusBar";
import Certificates from "./certificates";

const basicInitialValues = {
  firstName: "",
  lastName: "",
  gender: "",
  address: "",
  zipcode: "",
  city: "",
  state: "",
  country: "",
  mobileNumber: "",
  image: {},
  since: "",
  about: "",
  description: "",
  compaines: [],
};
const zipInitialValues = [
  {
    zipcode: "",
    address: "",
  },
];

const initStatusInfo = {};

const IndividualInfo = props => {
  const { id } = useParams();
  //   const [isExpnded, setIsExpanded] = useState(false);
  const [basicInfo, setBasicInfo] = useState(basicInitialValues);
  // const[companyInfo,setCompanyInfo]=useState([])
  const [proofInfo, setProofInfo] = useState([]);
  const [certificateInfo, setCertificateInfo] = useState([]);
  const [zipInfo, setZipInfo] = useState(zipInitialValues);
  const [expInfo, setExpInfo] = useState({});
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [statusInfo, setStatsInfo] = useState(initStatusInfo);

  useEffect(() => {
    getCurrentProvider();
  }, []);

  const getCurrentProvider = () => {
    getIndvidualById(id)
      .then(res => {
        if (res.data) {
          let {
            legalname,
            shortname,
            gender,
            address,
            zipcode,
            city,
            state,
            country,
            mobileNumber,
            image,
            since,
            note,
            idProofs,
            zipcodes,
            expertise,
            compaines,
            description,
            status,
            updatedAt,
            createdAt,
            comments,
            certificates,
          } = res.data;
          let basicObj = {
            legalname,
            shortname,
            gender,
            address,
            zipcode,
            city,
            state,
            country,
            mobileNumber,
            image,
            since,
            note,
            idProofs,
            zipcodes,
            expertise,
            compaines,
            description,
            comments,
            certificates,
          };
          setStatsInfo({ status, updatedAt, createdAt, comments });

          setBasicInfo(basicObj);
          if (idProofs && idProofs.length > 0) {
            setProofInfo(idProofs);
          }
          if (certificates && certificates.length > 0) {
            setCertificateInfo(certificates);
          }
          if (zipcodes.length > 0) {
            setZipInfo(zipcodes);
          }
          if (expertise && Object.keys(expertise).length > 0) {
            setExpInfo(expertise);
          }
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const updateProperties = body => {
    updateIndvidualFields(id, body)
      .then(res => {
        setsuccess_msg(true);
        getCurrentProvider();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  const updateForm = fieldValues => {
    console.log(fieldValues, "field values");
    let formData = new FormData();
    let { country, state, city } = basicInfo;
    const values = { ...basicInfo, ...fieldValues };

    if (country.label) {
      values["country"] = country.label;
    }
    if (state.label) {
      values["state"] = state.label;
    }
    if (city.label) {
      values["city"] = city.label;
    }

    for (const key in values) {
      if (key === "expertise") {
        formData.set(
          key,
          values[key] ? JSON.stringify(values[key]) : JSON.stringify([])
        );
      } else if (key === "zipcodes") {
        formData.set(
          key,
          values[key]
            ? JSON.stringify(values[key])
            : JSON.stringify(zipInitialValues)
        );
      } else {
        formData.set(key, values[key]);
      }
    }

    values.idProofs?.map((ele, idx) => {
      if (ele.docs && typeof ele.docs == "object") {
        formData.set("idProofs" + idx, ele.docs);
      }
    });
    formData.set("idProofs", JSON.stringify(values.idProofs));

    values.certificates?.map((ele, idx) => {
      if (ele.docs && typeof ele.docs == "object") {
        formData.set("certificate" + idx, ele.docs);
      }
    });
    formData.set("certificates", JSON.stringify(values.certificates));

    updateIndvidual(id, formData)
      .then(res => {
        console.log(res, "RESPONSE");
        setsuccess_msg(true);
        getCurrentProvider();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    // props.history.push("/Provider-list-individul");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
    // props.history.push("/Provider-list-individul");
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Indvidual created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              // onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Provider Individual Info | stylepro</title>
        </MetaTags>
        <Container fluid={true}>
          <Breadcrumb title="Individual Provider" breadcrumbItem="Info" />
          <div className="individual_info">
            <Col md={12}>
              <StatusBar
                statusInfo={statusInfo}
                handleSubmit={updateProperties}
                reload={getCurrentProvider}
              />
            </Col>
            <Row>
              <Col md={12}>
                {" "}
                <BasicInfo
                  basicInitialValues={basicInfo}
                  handleSubmit={updateForm}
                />
              </Col>
              <Col md={12}>
                {" "}
                <Certificates
                  certificateInitialValues={certificateInfo}
                  handleSubmit={updateForm}
                />
              </Col>
              <Col md={12}>
                {" "}
                <IdProofs
                  proofInitialValues={proofInfo}
                  handleSubmit={updateForm}
                />
              </Col>
              <Col md={12}>
                {" "}
                <MyCompanies
                  joinedComapnies={basicInfo.compaines}
                  handleSubmit={updateProperties}
                />
              </Col>
              {/* <Col md={6}>
                <ExpertiseInfo
                  expInitialValues={expInfo}
                  handleSubmit={updateProperties}
                />
              </Col> */}
              <Col md={6}>
                <ZipcodeInfo
                  zipInitialValues={zipInfo}
                  handleSubmit={updateProperties}
                />
              </Col>
              <Col md={12}>
                <ServiceInfo
                  expInitialValues={expInfo}
                  handleSubmit={updateProperties}
                />
              </Col>
            </Row>
            {success_msg ? successPopup() : null}
            {error_msg ? errorPopup() : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(IndividualInfo);
