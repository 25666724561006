import React, { useState, useEffect, useMemo } from "react";
import MetaTags from "react-meta-tags";
import { useParams } from "react-router";
import { withRouter } from "react-router-dom";
import Checkbox from "react-custom-checkbox";
import checkMark from "assets/icons-image/check-mark.png";
import * as _ from "lodash";

import { getServicesByCountry } from "services/services.service";
import { getPackages } from "services/package.service";
import { getParentCategories } from "services/parentcategory.service";
import {
  getIndvidualById,
  updateIndvidualFields,
} from "services/indvidual.service";

import { Card, Container, CardBody, Button } from "reactstrap";

import SweetAlert from "react-bootstrap-sweetalert";

//Import Breadcrumb
import Breadcrumb from "../../../components/Common/Breadcrumb";
import "./individual.scss";
const IndividualExpertise = props => {
  const { id } = useParams();
  let currentProvider;
  let currentServices = [];
  let currentPackages = [];
  const [womenServiceList, setWomenServiceList] = useState([]);
  const [menServiceList, setMenServiceList] = useState([]);
  const [kidsServiceList, setKidsServiceList] = useState([]);
  const [womenPackageList, setWomenPackageList] = useState([]);
  const [menPackageList, setMenPackageList] = useState([]);
  const [kidsPackageList, setKidsPackageList] = useState([]);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const leaveToList = () => {
    props.history.push("/Provider-list-individul");
  };
  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    //remove below one
    props.history.push("/Provider-list-individul");
  };
  useEffect(() => {
    getCurrentProvider();
  }, []);

  const getCurrentProvider = () => {
    getIndvidualById(id)
      .then(res => {
        if (res.data) {
          currentProvider = res.data;
          let expertise = currentProvider?.expertise;
          if (expertise) {
            currentServices = [
              expertise?.female?.service,
              ...expertise?.male?.service,
              ...expertise?.kids?.service,
            ];
            currentPackages = [
              ...expertise?.female?.package,
              ...expertise?.male?.package,
              ...expertise?.kids?.package,
            ];
          }
          getServicesList();
          packageList();
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const getServicesList = () => {
    getParentCategories()
      .then(res => {
        if (res.data) {
          let mens = [];
          let womens = [];
          let kids = [];
          res.data.forEach(ele => {
            let isExisted = false;
            if (_.indexOf(currentServices, ele._id) > -1) {
              isExisted = true;
            }
            let newObj = {
              checked: isExisted,
              name: ele.name,
              serviceId: ele._id,
            };
            if (ele.type == "Male") {
              mens.push(newObj);
            } else if (ele.type == "Female") {
              womens.push(newObj);
            } else {
              kids.push(newObj);
            }
          });
          setMenServiceList(mens);
          setWomenServiceList(womens);
          setKidsServiceList(kids);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const packageList = () => {
    getPackages()
      .then(res => {
        if (res.data) {
          let mens = [];
          let womens = [];
          let kids = [];
          res.data.forEach(ele => {
            let isExisted = false;
            if (_.indexOf(currentPackages, ele._id) > -1) {
              isExisted = true;
            }
            let newObj = {
              checked: isExisted,
              name: ele.packageName,
              packageId: ele._id,
            };
            if (ele.gender == "Male") {
              mens.push(newObj);
            } else if (ele.gender == "Female") {
              womens.push(newObj);
            } else {
              kids.push(newObj);
            }
          });
          setMenPackageList(mens);
          setWomenPackageList(womens);
          setKidsPackageList(kids);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };
  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            {/* <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button> */}
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Ok
            </Button>
          </React.Fragment>
        }
      >
        Successfully Added
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        // onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              // onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };

  const handleServiceChecked = (value, list, type, index) => {
    let data = [...list];
    data[index]["checked"] = value;
    if (type == "Men") {
      setMenServiceList(data);
    } else if (type == "Women") {
      setWomenServiceList(data);
    } else {
      setKidsServiceList(data);
    }
  };

  const handlePackageChecked = (value, list, type, index) => {
    let data = [...list];
    data[index]["checked"] = value;
    if (type == "Men") {
      setMenPackageList(data);
    } else if (type == "Women") {
      setWomenPackageList(data);
    } else {
      setKidsPackageList(data);
    }
  };

  //   service: [{
  //     type: ObjectId,
  //     ref: 'Service',
  //   }],
  //   package: [{
  //     type: ObjectId,
  //     ref: 'Package',
  //   }]

  // expertise:{
  //     male:expertiseSchema,
  //     female:expertiseSchema,
  //     kids:expertiseSchema,
  //   _id: false
  // }

  const save = () => {
    let maleService = _.map(
      menServiceList.filter(ele => ele.checked),
      "serviceId"
    );
    let femaleService = _.map(
      womenServiceList.filter(ele => ele.checked),
      "serviceId"
    );
    let kidServices = _.map(
      kidsServiceList.filter(ele => ele.checked),
      "serviceId"
    );
    let malePackage = _.map(
      menPackageList.filter(ele => ele.checked),
      "packageId"
    );
    let femalePackage = _.map(
      womenPackageList.filter(ele => ele.checked),
      "packageId"
    );
    let kidPackage = _.map(
      kidsPackageList.filter(ele => ele.checked),
      "packageId"
    );

    let expertise = {
      male: { service: maleService, package: malePackage },
      female: { service: femaleService, package: femalePackage },
      kids: { service: kidServices, package: kidPackage },
    };

    let body = {
      expertise,
    };

    updateIndvidualFields(id, body)
      .then(res => {
        setsuccess_msg(true);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };
  const checkBoxContainer = (type, serviceList, packageList) => {
    return (
      <>
        <div className="expertise-card p-0">
          <div className="expertise-header text-center p-1">{type}</div>
          <div className="expertise-body p-2">
            <div className="serviceList">
              <span></span>
              {serviceList.map((ele, ind) => {
                return (
                  <Checkbox
                    key={ind}
                    className=""
                    checked={ele.checked}
                    onChange={(value, event) => {
                      handleServiceChecked(value, serviceList, type, ind);
                    }}
                    icon={<img src={checkMark} style={{ width: 14 }} alt="" />}
                    borderColor="#000"
                    borderRadius={0}
                    borderWidth={1}
                    size={20}
                    label={ele.name}
                  />
                );
              })}
            </div>
            <div className="packageList mt-5">
              <p className="">Special Packages</p>
              {packageList.map((ele, ind) => {
                return (
                  <Checkbox
                    key={ind}
                    className=""
                    checked={ele.checked}
                    onChange={(value, event) => {
                      handlePackageChecked(value, packageList, type, ind);
                    }}
                    icon={<img src={checkMark} style={{ width: 14 }} alt="" />}
                    borderColor="#000"
                    borderRadius={0}
                    borderWidth={1}
                    size={20}
                    label={ele.name}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Provider Individual Expertise| stylepro</title>
        </MetaTags>
        <Container fluid={false}>
          <Breadcrumb title="Individual Provider" breadcrumbItem="Expertise" />
          <Card>
            <CardBody>
              <div>
                <p className="text-muted font-size-16">
                  Expertise (By Category)
                </p>
              </div>
              <div className="d-flex">
                {checkBoxContainer("Women", womenServiceList, womenPackageList)}
                {checkBoxContainer("Men", menServiceList, menPackageList)}
                {checkBoxContainer("Kids", kidsServiceList, kidsPackageList)}
              </div>
              <hr />
              <div className="d-flex justify-content-end">
                <Button
                  color="primary"
                  className="w-md btn-sm me-4"
                  type="submit"
                  onClick={save}
                >
                  Save Changes
                </Button>
                <Button
                  color="light"
                  className="w-md btn-sm"
                  onClick={leaveToList}
                >
                  cancel
                </Button>
              </div>
              {success_msg ? successPopup() : null}
              {error_msg ? errorPopup() : null}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(IndividualExpertise);
