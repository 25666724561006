import React, { useEffect } from "react";
import {
  Container,
  Card,
  Row,
  Col,
  CardBody,
  CardTitle,
  Button,
} from "reactstrap";

import {
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { useState } from "react";
import Select from "react-select";
import {
  createVariants,
  updateVariant,
} from "services/ecommerce/variant.service";
import SweetAlert from "react-bootstrap-sweetalert";

const defaultvalues = {
  name: "",
  description: "",
  variants: [],
  status: "Active",
};

const options = [
  { label: "Active", value: "Active" },
  { label: "Inactive", value: "Inactive" },
];
export const CreateAttributes = ({ toggle, modal, reload, active = null }) => {
  const [formValues, setFormValues] = useState(active || defaultvalues);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setFormValues(active || defaultvalues);
  }, [active]);
  let addFormFields = () => {
    const data = [...formValues.variants];
    data.push({ name: "", value: "" });
    setFormValues({ ...formValues, variants: data });
  };

  const handleChange = (i, e) => {
    // console.log(e.target.value);
    let newFormValues = [...formValues.variants];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues({ ...formValues, variants: newFormValues });
  };

  const removeFormFields = i => {
    const data = [...formValues.variants];
    data.splice(i, 1);
    setFormValues({ ...formValues, variants: data });
  };

  const leaveToList = () => {
    toggle();
  };
  const saveChanges = () => {
    createVariants(formValues)
      .then(res => {
        if (res.message) {
          console.log(res);
          setsuccess_msg(true);
        } else {
          seterror_msg(true);
          console.log(res);
        }
        reload();
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
        setLoading(false);
      });
  };
  const editChanges = () => {
    console.log(formValues, "formmmmm");
    updateVariant(active._id, formValues)
      .then(res => {
        console.log(res);
        if (res.data) {
          setsuccess_msg(true);
          console.log(res);
        } else {
          seterror_msg(true);
          console.log(res);
        }

        reload();
      })
      .catch(e => {
        console.log(e);
        seterror_msg(true);
      });
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
  };

  const successPopup = () => {
    return (
      <SweetAlert
        title="Success"
        success
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Successfully Variant created
      </SweetAlert>
    );
  };

  const errorPopup = () => {
    return (
      <SweetAlert
        title="Failure"
        danger
        onConfirm={leaveToList}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={leaveToList}
            >
              Leave
            </Button>
            <Button
              color="danger"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              Continue
            </Button>
          </React.Fragment>
        }
      >
        Something went wrong
      </SweetAlert>
    );
  };
  const handleInput = e => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  return (
    <>
      <Modal isOpen={modal} toggle={toggle} backdrop="static" size="xl">
        <ModalHeader toggle={toggle}>
          {active ? "Edit Variant" : "Create Variant"}
        </ModalHeader>
        <ModalBody className="bg-light">
          <Container fluid>
            <Row>
              <Col xs="8">
                <Card>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Label for="exampleEmail">
                          Name <span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="name"
                          id="name"
                          placeholder="Name"
                          value={formValues?.name}
                          onChange={handleInput}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label for="examplePassword">
                          Description<span className="text-danger">*</span>
                        </Label>
                        <Input
                          type="text"
                          name="description"
                          id="description"
                          placeholder="description"
                          value={formValues?.description}
                          onChange={handleInput}
                        />
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
                <Card>
                  <CardBody>
                    {formValues.variants.map((element, index) => (
                      <div className="d-flex " key={index}>
                        <FormGroup>
                          {/* <Label>Default</Label> */}
                          <div className="d-flex">
                            <Input
                              type="text"
                              name="name"
                              value={element.name || ""}
                              onChange={e => handleChange(index, e)}
                              size="40"
                              className="me-1"
                              placeholder="Name"
                            />
                          </div>
                        </FormGroup>
                        <FormGroup>
                          {/* <Label>Title</Label> */}
                          <div className="d-flex">
                            <Input
                              type="text"
                              name="value"
                              value={element.value || ""}
                              onChange={e => handleChange(index, e)}
                              size="40"
                              className="me-3"
                              placeholder="value"
                            />
                          </div>
                        </FormGroup>

                        <i
                          style={{
                            cursor: "pointer",
                          }}
                          className="mdi mdi-close-thick font-size-18 text-danger mt-2 float-end"
                          id="removeImgtooltip"
                          onClick={() => removeFormFields(index)}
                        />
                      </div>
                    ))}
                    <button
                      className="btn btn-md btn-primary"
                      onClick={addFormFields}
                    >
                      Add Variant
                    </button>
                  </CardBody>
                </Card>
              </Col>
              <Col xs="4">
                <Card>
                  <CardTitle className="p-2 border border-bottom-3">
                    Status
                  </CardTitle>
                  <CardBody>
                    <Form>
                      <FormGroup>
                        <Select
                          name="country"
                          id="country"
                          options={options}
                          value={{
                            label: formValues.status,
                            value: formValues.status,
                          }}
                          onChange={event => {
                            setFormValues({
                              ...formValues,
                              ["status"]: event.value,
                            });
                          }}
                          classNamePrefix="select2-selection"
                        />
                      </FormGroup>
                    </Form>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* <div className="d-flex justify-content-end">
              <Button
                color="primary"
                type="submit"
                className="w-md btn-sm me-4"
                onClick={saveChanges}
              >
                Submit
              </Button>

              <Button
                color="light"
                className="w-md btn-sm bg-white"
                onClick={leaveToList}
              >
                cancel
              </Button>
            </div> */}
            <div className="d-flex justify-content-end">
              <Button
                color="light"
                className="w-md btn-sm"
                onClick={leaveToList}
              >
                cancel
              </Button>
              {!active ? (
                <Button
                  color="primary"
                  type="submit"
                  className="w-md btn-sm me-4"
                  onClick={saveChanges}
                >
                  Submit
                </Button>
              ) : (
                <Button
                  color="primary"
                  className="w-md btn-sm me-4"
                  type="submit"
                  onClick={editChanges}
                >
                  Save Changes
                </Button>
              )}
            </div>
          </Container>
          {success_msg ? successPopup() : null}
          {error_msg ? errorPopup() : null}
        </ModalBody>
      </Modal>
    </>
  );
};
export default CreateAttributes;
