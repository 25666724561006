import * as _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  Button,
  Col,
  Row,
  Input,
  UncontrolledTooltip,
} from "reactstrap";
import { getFilters, updateFilters } from "services/filters.service";
import { ReactSortable } from "react-sortablejs";

const initvalues = {
  filterName: "",
  options: [], //Array contains Objects, each object should be `{ optionName: "", count: "" }`
  isVisible: false,
  order: "",
  isNew: true,
};
const Filters = ({
  setsuccess_msg,
  seterror_msg,
  leaveToList,
  parentCatId,
  subCatId,
}) => {
  const [filtersList, setFiltersList] = useState([initvalues]);
  const [loading, setLoading] = useState(false);
  const route = useParams();

  useEffect(() => {
    getFiltersById();
  }, [parentCatId, subCatId]);

  const handleAddFilter = () => {
    let tempFiltersList = _.cloneDeep(filtersList);
    tempFiltersList.push(initvalues);
    setFiltersList(tempFiltersList);
  };

  const handleDeleteFilter = filterIndex => {
    let tempFiltersList = _.cloneDeep(filtersList);
    tempFiltersList.splice(filterIndex, 1);
    setFiltersList(tempFiltersList);
  };

  const handleAddOption = filterIndex => {
    let tempFiltersList = _.cloneDeep(filtersList);
    tempFiltersList[filterIndex]["options"].push({
      optionName: tempFiltersList[filterIndex]["input"],
      count: 0,
    });
    delete tempFiltersList[filterIndex]["input"];
    setFiltersList(tempFiltersList);
  };

  const handleDeleteOption = (optionIndex, filterIndex) => {
    let tempFiltersList = _.cloneDeep(filtersList);
    tempFiltersList[filterIndex]["options"].splice(optionIndex, 1);
    setFiltersList(tempFiltersList);
  };

  const updateFilterValues = (filterIndex, fieldName, value) => {
    let tempFiltersList = _.cloneDeep(filtersList);
    tempFiltersList[filterIndex][fieldName] = value;
    setFiltersList(tempFiltersList);
  };

  const getFiltersById = () => {
    let body = {};
    if (parentCatId) {
      body["parentCategory"] = parentCatId;
    }
    if (subCatId) {
      body["category"] = subCatId;
    }
    setLoading(true);
    getFilters(body)
      .then(res => {
        setLoading(false);
        if (res.success) {
          if (res.data && res.data[0]["filters"])
            setFiltersList(res.data[0]["filters"]);
        }
      })
      .catch(e => {
        setLoading(false);
        console.log(e);
      });
  };

  const updateSort = filterIndex => list => {
    let tempFiltersList = _.cloneDeep(filtersList);
    tempFiltersList[filterIndex]["options"] = list;
    setFiltersList(tempFiltersList);
  };

  const onSubmit = () => {
    let tempFiltersList = _.cloneDeep(filtersList);
    tempFiltersList = tempFiltersList.filter(ele => {
      delete ele["isNew"];
      return ele;
    });

    let body = {
      filters: tempFiltersList,
    };
    if (parentCatId) {
      body["parentCategory"] = parentCatId;
    }
    if (subCatId) {
      body["category"] = subCatId;
    }
    setLoading(true);
    updateFilters(body)
      .then(res => {
        setLoading(false);
        setsuccess_msg(true);
      })
      .catch(ele => {
        setLoading(false);
        seterror_msg(true);
        console.log(ele);
      });
  };

  return (
    <Card className="" id="category_filter">
      <div className="d-flex   bg-light px-2 py-2 align-items-center justify-content-between ">
        <h5 className="mb-0 text-dark">Filters </h5>
        <div className=" d-flex justify-content-between align-items-center">
          <Button
            type="button"
            color="primary"
            size="sm"
            className="ms-2 px-4"
            onClick={() => handleAddFilter()}
          >
            Add Filter
          </Button>
        </div>
      </div>
      <CardBody>
        <Row>
          <Col className="p-0">
            {/* <div className="d-flex justify-content-between">
              <h5>Filtes</h5>
              <Button
                type="button"
                color="primary"
                size="sm"
                className="ms-2 px-4"
                onClick={() => handleAddFilter()}
              >
                Add Filter
              </Button>
            </div>
            <hr /> */}
            {filtersList?.length === 0 ? (
              <div className="px-3 pt-2">
                <h6>{loading ? "Loading...." : "No filters Available"}</h6>
              </div>
            ) : (
              <div>
                <div>
                  {filtersList?.map((filter, ind) => {
                    return (
                      <div key={ind} className="bg-light p-1 my-2">
                        <Card className="p-3 pb-0 mb-0">
                          {/* For delete only newly created Filter  uncomment below line and comment next line*/}
                          {/* {filter.isNew && ( */}
                          {true && (
                            <div
                              className="text-danger delete_filter p-1"
                              onClick={() => handleDeleteFilter(ind)}
                            >
                              <i
                                className="mdi mdi-delete font-size-18"
                                id="deletetooltip"
                              />
                              <UncontrolledTooltip
                                placement="top"
                                target="deletetooltip"
                              >
                                Delete Filter
                              </UncontrolledTooltip>
                            </div>
                          )}
                          <Row className="mt-2">
                            <Col md={4}>
                              <label htmlFor="">Filter Name</label>
                              <Input
                                className="form-control-sm"
                                value={filter?.filterName || ""}
                                disabled={!filter?.isNew}
                                onInput={ele =>
                                  updateFilterValues(
                                    ind,
                                    "filterName",
                                    ele.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col md={1}>
                              <label htmlFor="">Order</label>
                              <Input
                                type="number"
                                className="form-control-sm"
                                value={filter?.order || ""}
                                onInput={ele =>
                                  updateFilterValues(
                                    ind,
                                    "order",
                                    ele.target.value
                                  )
                                }
                              />
                            </Col>
                            <Col md={2}>
                              <div>
                                <label htmlFor="">Visible</label>
                                <select
                                  className="form-select form-select-sm"
                                  value={filter.isVisible}
                                  onChange={ele =>
                                    updateFilterValues(
                                      ind,
                                      "isVisible",
                                      ele.target.value
                                    )
                                  }
                                >
                                  <option value={true}>Active</option>
                                  <option value={false}>In Active</option>
                                </select>
                              </div>
                            </Col>
                            <Col md={2}></Col>
                            <Col md={3}>
                              <div className="">
                                <label htmlFor="">Options</label>
                                <div className="d-flex ">
                                  <Input
                                    className="form-control-sm me-2"
                                    value={filter.input || ""}
                                    onInput={ele =>
                                      updateFilterValues(
                                        ind,
                                        "input",
                                        ele.target.value
                                      )
                                    }
                                  />
                                  <Button
                                    color="primary"
                                    size={"sm"}
                                    onClick={() => handleAddOption(ind)}
                                    disabled={!filter?.input}
                                  >
                                    Add
                                  </Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                          <Row>
                            <div className="d-flex flex-wrap justify-content-end m-0 mt-3 p-2 border-top">
                              {(!filter?.options ||
                                !filter?.options?.length) && (
                                <h6 className="my-1">No Options available</h6>
                              )}
                              <ReactSortable
                                filter=".addImageButtonContainer"
                                dragClass="sortableDrag"
                                list={filter?.options || []}
                                setList={updateSort(ind)}
                                animation="200"
                                easing="ease-out"
                                className="d-flex flex-wrap justify-content-end"
                              >
                                {filter?.options?.map((ele, optInd) => {
                                  return (
                                    <div className="my-2" key={optInd}>
                                      <span className="me-3 ">
                                        <span className=" h6 border border-1 bg-light py-1 px-2 ">
                                          {ele?.optionName}
                                        </span>
                                        {/* 
                                        {ele?.count ? (
                                          <span className=" h6 border border-1 py-1 px-2 ">
                                            {" "}
                                            {ele.count}
                                          </span>
                                        ) : ( */}
                                        <span
                                          role="button"
                                          onClick={() =>
                                            handleDeleteOption(optInd, ind)
                                          }
                                          className="key-del-btn h6 border border-1  text-danger  py-1 px-2 "
                                        >
                                          X
                                        </span>
                                        {/* )} */}
                                      </span>
                                    </div>
                                  );
                                })}
                              </ReactSortable>
                            </div>
                          </Row>
                        </Card>
                      </div>
                    );
                  })}
                </div>
                <hr className="mt-5" />
              </div>
            )}
            <div className="d-flex mt-2 justify-content-end float-end">
              <Button
                type="submit"
                color="light"
                className="w-md btn-sm ms-2"
                onClick={() => leaveToList()}
              >
                Cancel
              </Button>
              {route?.catId ? (
                <>
                  {" "}
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md btn-sm ms-2"
                    onClick={() => onSubmit()}
                  >
                    Save
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    color="primary"
                    className="w-md btn-sm ms-2"
                    onClick={() => onSubmit()}
                  >
                    Save Changes
                  </Button>
                </>
              )}
            </div>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};
export default Filters;
