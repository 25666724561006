import React from "react"
// import { Button, Card, Col, CardBody, UncontrolledTooltip } from "reactstrap"

import BootstrapTable from "react-bootstrap-table-next"
import { useState } from "react"
import { useEffect } from "react"
import cellEditFactory from "react-bootstrap-table2-editor"
import { CardBody, UncontrolledTooltip } from "reactstrap"

export const BasicServiceCost = () => {
  const [data, setData] = useState([])
  useEffect(() => {
    const getData = () => {
      fetch("data1.json", {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then(function (response) {
          console.log(response)
          return response.json()
        })
        .then(function (myJson) {
          console.log(myJson)
          setData(myJson)
        })
    }
    getData()
  }, [])
  const columns = [
    {
      dataField: "id",

      text: "ServiceId",
    },
    {
      dataField: "service",
      text: "Service",
    },
    {
      dataField: "priceType",
      text: "PriceType",
    },
    {
      dataField: "price",
      text: " Price",
    },
    {
      dataField: "currency",
      text: "Currency",
    },
    {
      dataField: "action",
      text: "Action",
      editable: false,
      formatter: (cellContent, row) => {
        return (
          <>
            <div className="d-flex gap-3">
              <i
                className="mdi mdi-delete font-size-18 text-danger"
                id="deletetooltip"
              />
              <UncontrolledTooltip placement="top" target="deletetooltip">
                Delete
              </UncontrolledTooltip>

              <i
                className="mdi mdi-check-bold font-size-18 text-success"
                id="edittooltip"
              />
              <UncontrolledTooltip placement="top" target="edittooltip">
                Save
              </UncontrolledTooltip>
            </div>
          </>
        )
      },
    },
  ]
  const selectRow = {
    mode: "checkbox",
    clickToSelect: true,
    clickToEdit: true,
  }

  return (
    <React.Fragment>
      <CardBody>
        <h6 className="text-primary">BasicService Cost</h6>
        <BootstrapTable
          keyField="id"
          data={data}
          columns={columns}
          selectRow={selectRow}
          cellEdit={cellEditFactory({ mode: "click" })}
        />
      </CardBody>
    </React.Fragment>
  )
}
