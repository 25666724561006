import React from "react";
import { Badge } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import banner from "../Users2/avatar.jpg";
import MetaTags from "react-meta-tags";
import { useState, useEffect } from "react";
import moment from "moment";
import {
  Container,
  Button,
  Card,
  Row,
  Col,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import {
  getCategories,
  updateCategoryStatus,
  deleteCategory,
} from "services/category.service";
import Switch from "react-switch";
const defaultValues = {
  name: "",
  color: "",
  order: "",
  tagline: "",
  type: "",
  parentCategory: "",
  image: {},
  createdAt: null,
  updatedAt: null,
};
// import Spinner from "components/spinner"
import { getCategory } from "services/category.service";
import { Link } from "react-router-dom";
import ProfileImage from "./../../components/profileImage";
import { useParams } from "react-router";
import { getServicesByQuery } from "services/services.service";
import MultipleCheckbox from "components/MutlpleCheckbox";
import CreateCategory from "pages/Categories/create";
import SweetAlert from "react-bootstrap-sweetalert";
import CreateService from "pages/Services/create";
import ViewService from "pages/Services/ViewSevice";
import BreadcrumbSub from "components/Common/BreadcrumbSub";

const SubCategorieIndex = props => {
  const badgeColor = " badge-soft-";
  const [loading, setLoading] = useState(true);
  const [categoryList, setCategoryList] = useState([]);
  const [idList, setIdList] = useState({});
  const [tempList, setTempList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const { id } = useParams();
  const [values, setValues] = useState(defaultValues);
  const typeFilterOptions = ["Male", "Female", "Kids"];
  const [typeFilter, setTypeFilter] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalView, setModalView] = useState(false);
  const [modalService, setModalService] = useState(false);
  const [allList, setAllList] = useState([]);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const [activeId, setActiveId] = useState(false);
  const [activeViewId, setActiveViewId] = useState(false);
  const [activeServiceId, setActiveServiceId] = useState(false);

  const handleSearch = search => {
    if (search) {
      let tempList = _.cloneDeep(allList);
      let filterList = tempList.filter(ele =>
        ele?.name?.toLowerCase().includes(search.toLowerCase())
      );
      setCategoryList(filterList);
    } else {
      setCategoryList(allList);
    }
  };

  const toggle = () => {
    console.log("CLIKCED MODAL", !modal);
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };
  const toggleService = () => {
    console.log("CLIKCED MODAL", !modalService);
    if (modalService) {
      setActiveServiceId(null);
    }
    setModalService(!modalService);
  };
  const toggleView = () => {
    console.log("CLIKCED MODAL", !modalView);
    if (modalView) {
      setActiveViewId(null);
    }
    setModalView(!modalView);
  };

  let { image } = values;
  const addCategory = () => {
    props.history.push("/category-create");
  };
  const handleService = (ele, index) => {
    getCategorieById(ele._id);
  };
  useEffect(() => {
    getCategoriesList();
    // getCategorieById();
  }, []);

  const getCategoriesList = () => {
    getCategories()
      .then(res => {
        setCategoryList(res);
        setTempList(res);
        setAllList(res);
        let obj = {};
        getCategorieById(res[0]["_id"]);
        res.forEach(catg => {
          let id = catg._id;
          obj[id] = catg["status"];
        });
        setIdList(obj);
        setLoading(false);
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const handleSwitch = id => {
    setLoading(true);
    let prevState = idList[id];
    let switch_toggle = prevState === "Active" ? "Inactive" : "Active";
    setIdList({ ...idList, [id]: switch_toggle });
    updateCategoryStatus(id, switch_toggle)
      .then(res => {
        setLoading(false);
        if (res.data.success) {
        } else {
          alert("something went wrong");
          setIdList({ ...idList, [id]: prevState });
        }
      })
      .catch(e => {
        alert("something went wrong");
        setLoading(false);
        setIdList({ ...idList, [id]: prevState });
        console.log(e);
      });
  };

  const getServices = body => {
    getServicesByQuery(body)
      .then(res => {
        if (res.data) {
          setServiceList([...res.data]);
          console.log(res);
        }
      })
      .catch(e => {
        console.log(e);
      });
  };

  const getCategorieById = id => {
    getCategory(id)
      .then(res => {
        let {
          color,
          tagline,
          image,
          name,
          order,
          type,
          parentCategory,
          createdAt,
          updatedAt,
          _id,
        } = res.data;
        let obj = {
          name,
          color,
          order,
          type,
          parentCategory,
          tagline,
          image,
          createdAt,
          updatedAt,
          _id,
        };
        setValues({ ...obj });
        getServices({ categories: id, type: type });
        setLoading(false);
      })
      .catch(e => {
        console.log(e);
        setLoading(false);
      });
  };

  const checkboxItems = () => {
    return (
      <React.Fragment>
        {typeFilterOptions.map(option => (
          <MultipleCheckbox
            key={option}
            text={option}
            handleOnChange={text => handleCheckboxChange(text)}
          ></MultipleCheckbox>
        ))}
      </React.Fragment>
    );
  };
  const handleCheckboxChange = text => {
    setLoading(true);
    let indx = typeFilter.indexOf(text);
    if (indx >= 0) {
      typeFilter.splice(indx, 1);
    } else {
      typeFilter.push(text);
    }
    if (typeFilter.length > 0) {
      let newList = _.filter(tempList, function (obj) {
        if (typeFilter.includes(obj.type)) {
          return obj;
        }
      });
      setCategoryList(newList);
    } else {
      setCategoryList(tempList);
    }
    setTypeFilter(typeFilter);
    setLoading(false);
  };

  //delete
  const removeCategory = id => {
    setLoading(true);
    deleteCategory(id)
      .then(res => {
        setDelete_modal(false);
        getCategoriesList();
      })
      .catch(err => {
        console.log(err);
        setLoading(false);
      });
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    removeCategory(deleteId);
  };

  const stayHere = () => {
    setDelete_modal(false);
  };
  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  const renderSubCategorieList = () => {
    return (
      <div>
        <div className="text-end mb-3">
          <Button
            type="button"
            color="primary"
            className=""
            onClick={toggle}
            size=""
          >
            <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
            Add Sub-Category
          </Button>
        </div>
        <Card className="view-users parentCat_view">
          <CardBody>
            {values && (
              <Card className="view-users">
                <CardBody>
                  {/* <div className="float-end ">
                    <Link className="text-end" to={`/category-update/${id}`}>
                      <Badge
                        className="font-size-12 badge-soft-warning py-1 px-3"
                        color={"bg-warning"}
                        pill
                      >
                        <i className="mdi mdi-pencil font-size-14" /> Edit
                      </Badge>
                    </Link>
                  </div> */}
                  <div className="d-flex float-end">
                    <div className="">
                      <Badge
                        className="font-size-12 badge-soft-warning py-1 px-3 me-2"
                        color={"bg-warning"}
                        pill
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setActiveId(values._id);
                          toggle();
                        }}
                      >
                        <i className="mdi mdi-pencil font-size-14" /> Edit
                      </Badge>
                    </div>
                    <div>
                      <Badge
                        className="font-size-12 badge-soft-danger py-1 px-3"
                        color={"bg-warning"}
                        onClick={() => onClickDelete(values._id)}
                        style={{ cursor: "pointer" }}
                        pill
                      >
                        <i className="mdi mdi-delete font-size-14" />
                        Delete
                      </Badge>
                    </div>
                  </div>
                  <Row>
                    <Col md={4} className="">
                      <div className="text-center my-4">
                        <ProfileImage
                          image={image}
                          classNames="img-thumbnail profile rounded-circle"
                        />
                        <h5 className="mt-4 text-muted">{values.name}</h5>
                      </div>
                    </Col>
                    <Col md={8} className=" pt-4 mb-4 px-0 view_detail">
                      <Row className="mx-3 ps-2">
                        <p className="ps-0">
                          <span className="pe-3 fw-bold">
                            Sub Category Name{" "}
                          </span>{" "}
                          <span className="text-muted">{values.name} </span>
                        </p>
                        <p className="ps-0 ">
                          <span className="pe-3 fw-bold">Color </span>{" "}
                          <span className="text-muted">{values.color} </span>
                        </p>
                      </Row>
                      <div className=" mt-2 me-3 hr"> </div>
                      <Row className="mx-3 my-2">
                        <Col md={4} className="mt-4">
                          <p className="fw-bold mb-1">Order</p>
                          <p className="text-muted">{values.order}</p>
                        </Col>
                        <Col md={4} className="mt-4">
                          <p className="fw-bold mb-1">Parent Category</p>
                          <p className="text-muted">
                            {values.parentCategory.name}
                          </p>
                        </Col>

                        <Col md={12} className="mt-4">
                          <p className="fw-bold mb-1">Tagline</p>
                          {values.tagline ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: values.tagline,
                              }}
                            />
                          ) : (
                            "_"
                          )}
                        </Col>
                      </Row>
                      <div className=" mt-2 me-3 hr"> </div>
                      <Row className="mx-3 my-2">
                        <Col md={5} className="mt-4">
                          <p className="fw-bold mb-1">Created</p>
                          <p className="text-muted">
                            {moment(
                              values.createdAt ? values.createdAt : "_"
                            ).format("MMM Do YYYY")}
                          </p>
                        </Col>
                        <Col md={5} className="mt-4">
                          <p className="fw-bold mb-1">Last Updated</p>
                          <p className="text-muted">
                            {moment(
                              values.updatedAt ? values.updatedAt : "_"
                            ).format("MMM Do YYYY")}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            )}
          </CardBody>
        </Card>
        <Card>{renderServiceList()}</Card>
      </div>
    );
  };
  const renderServiceList = () => {
    return (
      <Card className="view-users parentCat_view">
        <CardBody>
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">SERVICES</h5>
            <Button
              type="button"
              color="primary"
              className="btn-rounded btn-md"
              onClick={toggleService}
            >
              <i className="bx bx-user-plus font-size-16 align-middle me-2"></i>
              Add Service
            </Button>
          </div>
          <div className=" my-3 me-3 hr"> </div>
          {serviceList && serviceList.length > 0 ? (
            <Row className="mt-2">
              {serviceList.map((ser, index) => {
                return (
                  <Col key={index} md="3">
                    <Card className="shadow-sm mt-3">
                      <CardBody>
                        <div className="text-center">
                          <ProfileImage
                            image={ser.banner ? ser.banner : null}
                            classNames="rounded-circle avatar-md"
                          />
                          <h5 className="my-2">{ser.name}</h5>
                          <p className="category_status">
                            <span
                              className={
                                "status_dot " +
                                (ser.status == "Active" ? "active" : "inactive")
                              }
                            ></span>
                            <span className="ms-3">{ser.status}</span>
                          </p>
                        </div>
                        <div className=" my-2 hr"> </div>
                        <div className="d-flex justify-content-between pt-1">
                          <input
                            type="button"
                            value="Edit"
                            className="w-100 btn btn-sm btn-outline-light me-3"
                            onClick={() => {
                              setActiveServiceId(ser._id);
                              toggleService();
                            }}
                          />

                          <input
                            type="button"
                            value="View"
                            className="w-100 btn btn-sm btn-outline-primary"
                            onClick={() => {
                              setActiveViewId(ser._id);
                              toggleView();
                            }}
                          />
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                );
              })}
            </Row>
          ) : (
            <div className="text-center">
              <p className="mt-4 mb-2"> No data found</p>
            </div>
          )}
        </CardBody>
      </Card>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Providers | stylepro</title>
        </MetaTags>
        {/* <Spinner loading={loading}/> */}
        <Container fluid>
          <Breadcrumbs
            title="Sub Category"
            breadcrumbItem="Sub Categories List"
          />
          <BreadcrumbSub
            subtile="Sub Categories"
            SubBreadcrumbItem="Sub category"
          />

          <Row>
            <Col md="3">
              <Card>
                <CardBody>
                  <div className="search-box me-2 ">
                    <div className="position-relative">
                      <i className="bx bx-search-alt search-icon" />
                      <input
                        className="form-control py-2  rounded-pill bg-white "
                        type="search"
                        id="example-search-input"
                        placeholder=" Search "
                        onChange={event => handleSearch(event.target.value)}
                      />
                    </div>
                  </div>
                  <div className="d-flex  justify-content-between ps-3 pe-3 pt-3">
                    {checkboxItems()}
                  </div>
                </CardBody>
                {categoryList &&
                  categoryList.map((ele, index) => (
                    <CardBody
                      className="border border-bottom-3"
                      key={ele._id}
                      onClick={() => {
                        handleService(ele, index);
                      }}
                      role="button"
                    >
                      <Row>
                        <Col md="2">
                          <div>
                            <img
                              src={ele.image}
                              height="80"
                              alt=""
                              className="fit-content rounded-circle avatar-sm "
                            />
                          </div>
                        </Col>
                        <Col md="8">
                          <div className="ms-2">
                            <small className="h6">{ele.name}</small>
                            <br />
                            <small className="text-secondary">
                              <b>Type : </b>
                              {ele.type}
                            </small>
                            <br />
                            <small className="text-secondary">
                              <b>Updated : </b>
                              <>
                                {moment(
                                  ele.updatedAt ? ele.updatedAt : "_"
                                ).format("MMM Do YYYY")}
                                {/* {new Date(ele.updatedAt).toDateString()} */}
                              </>
                            </small>
                            <br />
                          </div>
                        </Col>
                        <Col md="2">
                          <div className="text-secondary float-end ">
                            <div className="mt-2">
                              <Switch
                                onChange={() => handleSwitch(ele._id)}
                                checked={
                                  idList[ele._id] === "Active" ? true : false
                                }
                                onColor="#86d3ff"
                                onHandleColor="#2693e6"
                                handleDiameter={25}
                                uncheckedIcon={false}
                                checkedIcon={false}
                                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                height={15}
                                width={40}
                                className="react-switch"
                                id="material-switch"
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  ))}
              </Card>
            </Col>
            <Col md="9">{renderSubCategorieList()}</Col>
          </Row>
        </Container>
      </div>
      <div>
        {modal && (
          <CreateCategory toggle={toggle} modal={modal} id={activeId} />
        )}
        {modalService && (
          <CreateService
            toggle={toggleService}
            modal={modalService}
            id={activeServiceId}
          />
        )}
        {modalView && (
          <ViewService
            toggle={toggleView}
            modal={modalView}
            id={activeViewId}
          />
        )}
      </div>
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};
export default SubCategorieIndex;
