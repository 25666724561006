export const getMainCategory = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/mainCategory/all`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Main Category ${e}`));
};
export const getParentCategory = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/parentcategory/all`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Parent Category ${e}`));
};
export const getParentCategoryByMain = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/parentcategory/get/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Parent Category by Id ${e}`));
};
export const getSubCategory = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/all`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get Sub Category ${e}`));
};
export const getSubCategoryByParent = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/getbyparent/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get ParentCategory ${e}`));
};

export const getCategoryRoot = () => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/getroots`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at get ParentCategory ${e}`));
};
export const getViewById = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/get/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(error => console.log(`error at get View ${error}`));
};
export const createParentCategory = async body => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/parentcategory/create`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
        },

        body: body,
      }
    );
    return await res.json();
  } catch (e) {
    return console.log(`error at category create ${e}`);
  }
};

export const createSubCategory = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/create`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Sub Category create ${e}`));
};
export const updateParentCategory = async (id, body) => {
  try {
    const res = await fetch(
      `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/parentcategory/update/${id}`,
      {
        method: "PUT",
        headers: {
          Accept: "application/json",
          // "Content-Type": "application/json",
        },
        body: body,
      }
    );
    return await res.json();
  } catch (e) {
    return console.log(`error at get category ${e}`);
  }
};
export const updateSubCategory = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
      },
      body: body,
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at ParentCategory create ${e}`));
};

export const deleteSubCategory = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/delete/${id}`,

    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at delete cate ${e}`));
};
export const deleteParentCategory = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/parentcategory/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at delete cate ${e}`));
};

export const getCategoriesByMultiParent = body => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/ecom/category/getbyparent`,
    {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at service query ${e}`));
};
