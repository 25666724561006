import React, { useEffect, useState } from "react";
import Breadcrumbs from "components/Common/Breadcrumb";
import { MetaTags } from "react-meta-tags";
import {
  Badge,
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import CreateArticle from "./create";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteArticle,
  getArticles,
  updateArticleById,
} from "services/article.service";
import SuccessAlert from "components/Alerts/succes";
import ErrorAlert from "components/Alerts/error";
import SweetAlert from "react-bootstrap-sweetalert";
import Switch from "react-switch";
import BreadcrumbSub from "components/Common/BreadcrumbSub";
const initialValues = {
  authorname: "",
  title: "",
  description: "",
  shortDescription: "",
  order: "",
  images: [],
  featuredImage: "",
  articleLink: "",
  relatedProducts: [],
  relatedServices: [],
  relatedArticles: [],
  service: {
    categories: [],
  },
  product: {
    mainCategory: "",
    parentCategory: "",
    categories: [],
  },
};
export const ArticleIndex = () => {
  const [modal, setModal] = useState(false);
  const storeArticles = useSelector(state => state.Articles.articles);
  const [articleList, setArticleList] = useState();
  const [activeId, setActiveId] = useState();
  const [loading, setLoading] = useState(false);
  const [success_msg, setsuccess_msg] = useState(false);
  const [error_msg, seterror_msg] = useState(false);
  const [delete_modal, setDelete_modal] = useState(false);
  const [deleteId, setDeleteid] = useState(null);
  const AriclesCount = articleList?.filter(ele => ele?.type === "Article");
  const BannersCount = articleList?.filter(ele => ele.type === "Banner");
  // const handleSwitch = (articleId, toggledOn) => {
  //   const articlePages =
  //     articleList.find(article => article._id === articleId).pages || [];
  //   const updatedPages = toggledOn
  //     ? [...articlePages, "/"]
  //     : articlePages.filter(page => page !== "/");

  //   setArticleList(prevArticleList =>
  //     prevArticleList.map(article =>
  //       article._id === articleId
  //         ? { ...article, pages: updatedPages }
  //         : article
  //     )
  //   );

  //   updateArticle(articleId, updatedPages);
  // };

  // const updateArticle = (articleId, updatedPages) => {
  //   setLoading(true);
  //   const articleData = {
  //     id: articleId,
  //     pages: updatedPages,
  //   };

  //   updateArticleById(articleData.id, articleData)
  //     .then(res => {})
  //     .catch(err => {
  //       console.log(err);
  //     });
  // };

  const dispatch = useDispatch();
  const toggle = () => {
    if (modal) {
      setActiveId(null);
    }
    setModal(!modal);
  };

  useEffect(() => {
    getAllArticles();
  }, [modal]);

  const getAllArticles = () => {
    getArticles()
      .then(res => {
        setArticleList(res?.data);
      })
      .catch(err => {});
  };

  const onClickDelete = id => {
    setDeleteid(id);
    setDelete_modal(true);
  };

  const handleOnDelete = () => {
    handleDeleteArticle(deleteId);
  };
  const handleDeleteArticle = id => {
    setLoading(true);
    deleteArticle(id)
      .then(res => {
        if (res?.data) {
          setDelete_modal(false);
          setsuccess_msg(true);
          setLoading(false);
          getAllArticles();
        }
      })
      .catch(err => {
        console.log(err);
        seterror_msg(true);
      });
  };

  const leaveToList = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    setDelete_modal(false);
  };

  const stayHere = () => {
    setsuccess_msg(false);
    seterror_msg(false);
    setDelete_modal(false);
  };

  const deletePopup = () => {
    return (
      <SweetAlert
        title="Are you sure ?"
        warning
        onConfirm={handleOnDelete}
        onCancel={stayHere}
        customButtons={
          <React.Fragment>
            <Button
              color="primary"
              className="w-md btn-sm mx-1"
              onClick={handleOnDelete}
            >
              Delete
            </Button>
            <Button
              color="light"
              className="w-md btn-sm mx-1"
              onClick={stayHere}
            >
              cancel
            </Button>
          </React.Fragment>
        }
      >
        You will not be able to recover this record
      </SweetAlert>
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title></title>
        </MetaTags>
        <Container fluid>
          <Breadcrumbs title="Blog" breadcrumbItem="Articles" />
          <BreadcrumbSub
            subtile="Articles List"
            SubBreadcrumbItem="Articles"
          />

          <div className="d-flex justify-content-between">
            <div className="d-flex">
              <h5 className="me-3">Articles({AriclesCount?.length})</h5>
              <h5>Banners({BannersCount?.length})</h5>
            </div>
            <div className="">
              <Button
                type="button"
                color="primary"
                size="sm"
                className="ms-2 px-4"
                onClick={() => toggle()}
              >
                Add Article
              </Button>
            </div>
          </div>
          <Row className="mt-3">
            <Col>
              <div className="">
                <div className="p-0 m-0 pb-0 mb-0">
                  {articleList?.map((article, index) => {
                    const fixedClass = "font-size-12 py-1 px-3 me-2";
                    const color =
                      article?.status === "Active"
                        ? " badge-soft-success"
                        : " badge-soft-danger";

                    return (
                      <Card
                        key={index}
                        className="border border-bttom-3 mb-2 p-3"
                        role="button"
                      >
                        <CardBody className="p-0 m-0">
                          <Row key={index} className=" ">
                            <Col className="" md={9}>
                              <div className="d-flex ">
                                <div>
                                  <img
                                    src={
                                      article?.featuredImage
                                        ? article?.featuredImage
                                        : article?.images[0]
                                    }
                                    alt=""
                                    className="avatar-sm me-3"
                                  />
                                </div>
                                <div>
                                  <h6 className="lh-0">{article?.title}</h6>
                                  {article?.authorname ? (
                                    <div>
                                      <i> By - {article?.authorname}</i>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {article?.pages?.length > 0 ? (
                                    <div className="mt-2 d-flex">
                                      <div
                                        className=""
                                        style={{ minWidth: "110px" }}
                                      >
                                        <h6 className="fw-bold mb-0 me-2">
                                          Displayed on -{" "}
                                        </h6>
                                      </div>
                                      <div className="d-flex flex-wrap">
                                        {article?.pages.map((pages, index) => {
                                          return (
                                            <div key={index} className="">
                                              <Badge
                                                className={
                                                  pages === "/"
                                                    ? "font-size-12 py-1 px-3 me-2 mb-2 badge-soft-warning"
                                                    : "font-size-12 py-1 px-3 me-2 mb-2 badge-soft-secondary"
                                                }
                                                color={
                                                  pages === "/"
                                                    ? "bg-secondary"
                                                    : "bg-warning"
                                                }
                                                pill
                                              >
                                                {pages === "/"
                                                  ? "HomePage"
                                                  : pages}
                                              </Badge>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  <div>
                                    <Badge
                                      className={fixedClass + color}
                                      color={"bg-success"}
                                      pill
                                    >
                                      {article?.status}
                                    </Badge>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col>
                              {" "}
                              <div className="d-flex justify-content-end align-items-center">
                                {/* <div>
                                  <Switch
                                    onChange={checked =>
                                      handleSwitch(article._id, checked)
                                    }
                                    checked={(article.pages || []).includes(
                                      "/"
                                    )}
                                    onColor="#86d3ff"
                                    onHandleColor="#2693e6"
                                    handleDiameter={25}
                                    uncheckedIcon={false}
                                    checkedIcon={false}
                                    boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                                    activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                                    height={15}
                                    width={40}
                                    className="react-switch mt-2 me-3"
                                    id="material-switch"
                                  />
                                </div> */}
                                <div>
                                  <Badge
                                    className="font-size-12 badge-soft-info py-1 px-3 me-2"
                                    color={"bg-warning"}
                                    pill
                                    onClick={() => {
                                      setActiveId(article);
                                      toggle();
                                    }}
                                  >
                                    <i
                                      className="mdi mdi-pencil font-size-14"
                                      id="edittooltip"
                                    />{" "}
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="edittooltip"
                                    >
                                      Edit
                                    </UncontrolledTooltip>
                                  </Badge>
                                </div>
                                <div>
                                  <Badge
                                    className="font-size-12 badge-soft-danger py-1 px-3 me-2"
                                    color={"bg-warning"}
                                    pill
                                    onClick={() => {
                                      onClickDelete(article?._id);
                                    }}
                                    id="deletetooltip"
                                  >
                                    <i className="mdi mdi-delete font-size-14" />{" "}
                                    <UncontrolledTooltip
                                      placement="top"
                                      target="deletetooltip"
                                    >
                                      Delete
                                    </UncontrolledTooltip>
                                  </Badge>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    );
                  })}
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <CreateArticle
          toggle={toggle}
          modal={modal}
          defaultValues={initialValues}
          id={activeId?._id}
          idValues={activeId}
        />
      </div>
      {success_msg ? (
        <SuccessAlert
          msg={"Successfully Article Updated"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
      {error_msg ? (
        <ErrorAlert
          msg={"error"}
          leaveToList={leaveToList}
          stayHere={stayHere}
        />
      ) : null}
      {delete_modal ? deletePopup() : null}
    </React.Fragment>
  );
};
export default ArticleIndex;
