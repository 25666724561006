export const createZipcode = body => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/zipcode/create`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      //   Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(body),
  })
    .then(response => {
      return response.json();
    })
    .catch(err => console.log(err));
};

export const getZipCodes = () => {
  return fetch(`${process.env.REACT_APP_DATABASEURL}/api/v1/zipcode/zipcodes`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      // Authorization: `Bearer ${token}`
    },
  })
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Zipcodes ${e}`));
};
export const getZipcode = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/zipcode/zipcode/${id}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Zipcode ${e}`));
};

export const updateZipcode = (id, body) => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/zipcode/update/${id}`,
    {
      method: "PUT",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(body),
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Zipcode ${e}`));
};
export const deleteZipcode = id => {
  return fetch(
    `${process.env.REACT_APP_DATABASEURL}/api/v1/zipcode/delete/${id}`,
    {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        // Authorization: `Bearer ${token}`
      },
    }
  )
    .then(res => {
      return res.json();
    })
    .catch(e => console.log(`error at Zipcode ${e}`));
};
