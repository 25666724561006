import React from "react";
import { MetaTags } from "react-meta-tags";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import "./Styles.scss";
import moment from "moment";
import AdminNotes from "./AdminNotes";

const ArtistQuoteView = ({ activeOrder }) => {
  return (
    <React.Fragment>
      <MetaTags>
        <title>Artist Forms View | stylepro - -</title>
      </MetaTags>
      <Container fluid>
        <React.Fragment>
          {activeOrder?.length &&
            activeOrder?.map((order, index) => {
              return (
                <Card className="" key={index}>
                  {" "}
                  <CardBody className=" border-3 border-bottom pb-1">
                    <Row>
                      <Col md="6" className="">
                        <div className="">
                          <div className="d-flex justify-content-between mb-2 align-items-center">
                            <div className="">
                              <h6 className="font-size-18 fw-bold mb-0">
                                Quotation / Order ID #{order?.quoteid}
                              </h6>
                            </div>
                          </div>
                          <h6 className="fw-bold">
                            Submitted On :
                            <span className="fw-bold">
                              {" "}
                              {moment(order?.createdAt).format(
                                "DD-MMMM-YYYY @ hh:mm a"
                              )}
                            </span>
                          </h6>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                  <CardBody className=" border-3 border-bottom">
                    <div>
                      <div className="bg-light p-1 mb-3">
                        <p className="mb-0 fw-bold text-center font-size-14">
                          Client Details
                        </p>
                      </div>
                      <div className="d-flex mb-1 ms-3">
                        <h6 className="customer_name">Client Name</h6>
                        <h6>:</h6>
                        <h6 className="fw-bold ms-1">
                          {order?.userid?.firstname
                            ? order?.userid?.firstname
                            : ""}{" "}
                          {order?.userid?.lastname
                            ? order?.userid?.lastname
                            : ""}
                        </h6>
                      </div>
                      <div className="d-flex mb-1 ms-3">
                        <h6 className="customer_name">Client Mobile Number</h6>
                        <h6>:</h6>
                        <h6 className="fw-bold ms-1">
                          {order?.userid?.mobilenumber
                            ? order?.userid?.mobilenumber
                            : ""}
                        </h6>
                      </div>
                      <div className="d-flex mb-1 ms-3">
                        <h6 className="customer_name mb-0">Client E-mail</h6>
                        <h6 className="mb-0">:</h6>
                        <h6 className="fw-bold ms-1 mb-0">
                          {order?.userid?.email ? order?.userid?.email : ""}
                        </h6>
                      </div>
                    </div>
                    <div className="mt-3 events_information">
                      <div className="bg-light p-1 mb-3">
                        <p className="mb-0 fw-bold text-center font-size-14">
                          Event Details
                        </p>
                      </div>
                      <div className="d-flex mb-1 artist_name">
                        <h6 className="customer_name">Makeup Artist Name</h6>
                        <h6>:</h6>
                        <h6 className="fw-bold ms-1">
                          {order?.artistid?.name ? order?.artistid?.name : ""}
                        </h6>
                      </div>
                      {order?.events?.length &&
                        order?.events?.map((event, indx) => {
                          return (
                            <div key={indx} className="mb-3 mt-3">
                              <div className="d-flex events_information">
                                <div className="sl_No fw-bold rounded">
                                  {indx + 1}
                                </div>
                                <div className="ms-2 w-100">
                                  <div className="border border-1 p-2">
                                    <div className="d-flex mb-1">
                                      <h6 className="customer_name">Event</h6>
                                      <h6>:</h6>
                                      <h6 className="fw-bold ms-1">
                                        {event?.type ? event?.type : "-"}
                                      </h6>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <h6 className="customer_name">
                                        Event Date
                                      </h6>
                                      <h6>:</h6>
                                      <h6 className="fw-bold ms-1">
                                        {event?.date != "" ? (
                                          <>
                                            {moment(
                                              event?.date,
                                              "MM-DD-YYYY"
                                            ).format("MMMM-DD-YYYY")}
                                          </>
                                        ) : (
                                          "-"
                                        )}
                                      </h6>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <h6 className="customer_name">
                                        Event Location
                                      </h6>
                                      <h6>:</h6>
                                      <h6 className="fw-bold ms-1">
                                        {event?.location
                                          ? event?.location
                                          : "-"}
                                      </h6>
                                    </div>
                                    <div className="d-flex mb-1">
                                      <h6 className="customer_name">
                                        Makeup Speciality
                                      </h6>
                                      <h6>:</h6>
                                      <h6 className="fw-bold ms-1">
                                        {event?.speciality
                                          ? event?.speciality
                                          : "-"}
                                      </h6>
                                    </div>
                                    <div className="d-flex">
                                      <h6 className="customer_name mb-0">
                                        Number of services
                                      </h6>
                                      <h6 className="mb-0">:</h6>
                                      <h6 className="fw-bold ms-1 mb-0">
                                        {event?.qty ? event?.qty : "-"}
                                      </h6>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      <div className="border border-1 p-2 my-3  other_information">
                        <div className="d-flex">
                          <h6 className="customer_name">Trial</h6>
                          <h6>:</h6>
                          <h6 className="fw-bold ms-1">
                            {order?.trial === true ? "Yes" : "No"}
                          </h6>
                        </div>
                        {order?.likeustoknow !== "" ? (
                          <div className="my-2">
                            <div className="d-flex">
                              <h6 className="">Other Important Information</h6>
                              <h6 className="ms-1">:</h6>
                            </div>
                            <div className="border border-1 p-2">
                              <h6 className="fw-bold ms-1 mb-0">
                                {order?.likeustoknow
                                  ? order?.likeustoknow
                                  : "-"}
                              </h6>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        <div className="d-flex mb-1">
                          <h6 className="customer_name mb-0">
                            Contact by Mobile
                          </h6>
                          <h6 className="mb-0">:</h6>
                          <h6 className="fw-bold ms-1 mb-0">
                            {order?.contactonmobile === true ? "Yes" : "No"}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              );
            })}
          {activeOrder && (
            <div>
              <AdminNotes activeOrder={activeOrder} />
            </div>
          )}
          {!activeOrder ? (
            <Card className="p-3 pt-0 empty_order">
              <CardBody className="border-3">
                <div className="text-center">
                  <i className="mdi mdi-arrow-left-circle" id="nullCatList"></i>
                </div>
                <p className="text-center">Please Select Order</p>
              </CardBody>
            </Card>
          ) : (
            ""
          )}
        </React.Fragment>
      </Container>
    </React.Fragment>
  );
};

export default ArtistQuoteView;
